import { PopupSection, Fieldset, Radio, Button } from "@flixbus/honeycomb-react";
import { Popup, getCookie, setCookie } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  COOKIE_KEY_TIME_FORMAT,
  COOKIE_VALUE_TIME_FORMAT_12H,
  COOKIE_VALUE_TIME_FORMAT_24H,
} from "../../helpers/handleCookies/cookies";
import * as css from "./TimeFormatPopup.scss";

const TimeFormatPopup: React.FC = () => {
  const { formatMessage } = useIntl();
  const [popupActive, setPopupActive] = React.useState(false);
  const [timeFormatSelected, setTimeFormatSelected] = React.useState(
    COOKIE_VALUE_TIME_FORMAT_24H
  );

  React.useEffect(() => {
    const timeFormatCookie = getCookie(COOKIE_KEY_TIME_FORMAT);

    if (
      timeFormatCookie === COOKIE_VALUE_TIME_FORMAT_12H ||
      timeFormatCookie === COOKIE_VALUE_TIME_FORMAT_24H
    ) {
      setPopupActive(false);
    } else {
      setPopupActive(true);
    }
  }, []);

  return (
    <Popup
      active={popupActive}
      onClose={() => setPopupActive(false)}
      data-id="time-format-popup"
    >
      <PopupSection type="title">
        <FormattedMessage id="timetable.timeFormat.popup.title" />
      </PopupSection>
      <PopupSection type="content" extraClasses={css.popupContent}>
        <Fieldset>
          <Radio
            label={formatMessage({ id: "timetable.timeFormat.popup.radio.option24h" })}
            id="twentyFourHourTimeFormat"
            name="timeFormat"
            value={COOKIE_VALUE_TIME_FORMAT_24H}
            key="twentyFourHourTimeFormat"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setTimeFormatSelected(event.target.value)
            }
            defaultChecked
          />
          <Radio
            label={formatMessage({ id: "timetable.timeFormat.popup.radio.option12h" })}
            id="twelveHourTimeFormat"
            name="timeFormat"
            value={COOKIE_VALUE_TIME_FORMAT_12H}
            key="twelveHourTimeFormat"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setTimeFormatSelected(event.target.value)
            }
          />
        </Fieldset>
      </PopupSection>
      <PopupSection type="actions">
        <Button
          data-id="time-format-popup-save-button"
          appearance="primary"
          onClick={() => {
            setCookie(COOKIE_KEY_TIME_FORMAT, timeFormatSelected, 365);
            setPopupActive(false);
            window.location.reload();
          }}
        >
          <FormattedMessage id="general.save" />
        </Button>
        <Button
          data-id="time-format-popup-cancel-button"
          appearance="tertiary"
          onClick={() => setPopupActive(false)}
        >
          <FormattedMessage id="general.cancel" />
        </Button>
      </PopupSection>
    </Popup>
  );
};

export default TimeFormatPopup;
