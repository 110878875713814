import { Checkbox as HCCheckbox } from "@flixbus/honeycomb-react";
import cx from "classnames";
import * as React from "react";
import * as css from "./Checkbox.scss";

type Props = {
  isChecked: boolean;
  onClicked: () => void;
  label: string;
  readOnly?: boolean;
};

const Checkbox: React.FC<Props> = ({ isChecked, onClicked, label, readOnly }) => {
  return (
    <div>
      <div className={css.checkboxWrapper}>
        <HCCheckbox
          id="checkbox"
          label={label}
          value="ruleActive"
          checked={isChecked}
          onChange={onClicked}
          extraClasses={cx(css.checkbox, readOnly ? css.checkboxReadonly : "")}
          disabled={readOnly}
        />
      </div>
    </div>
  );
};

export default Checkbox;
