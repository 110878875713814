import { useField } from "formik2";
import * as React from "react";
import { useIntl } from "react-intl";
import createCirculationOption from "../../helpers/reactSelect/createCirculationOption/createCirculationOption";
import { Circulation, Partner } from "../../types/schema";
import FormikSelect from "../formikSelect/FormikSelect";
import InfoTooltipLabel from "../infoTooltipLabel/InfoTooltipLabel";
import InputSkeleton from "../inputSkeleton/InputSkeleton";
import SelectApiError from "../selectApiError/SelectApiError";
import { useFindPartnerDetailsForCirculationSelectQuery } from "./api/operations.generated";

type Props = {
  partnerId: Partner["id"];
  selectedCirculationId?: string;
  extraClasses?: string;
};

const CirculationSelect: React.FC<Props> = ({
  partnerId,
  selectedCirculationId,
  extraClasses,
}) => {
  const { formatMessage } = useIntl();
  const [, , helper] = useField<Circulation["id"]>("defaultCirculationId");

  const {
    data: queryData,
    loading: queryLoading,
    error: queryError,
  } = useFindPartnerDetailsForCirculationSelectQuery({
    variables: {
      id: partnerId,
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (
        selectedCirculationId &&
        data.findPartner.circulations.find(
          (circulation) => circulation.uuid === selectedCirculationId
        )
      ) {
        helper.setValue(selectedCirculationId);
      }
    },
  });

  if (queryLoading) {
    return <InputSkeleton />;
  }

  if (queryError) {
    return (
      <SelectApiError
        label={formatMessage({ id: "partnerDetail.defaultCapacity.label" })}
        info={formatMessage({ id: "error.errorOccured" })}
      />
    );
  }

  if (queryData?.findPartner) {
    return (
      <div className={extraClasses}>
        <InfoTooltipLabel
          labelText={formatMessage({ id: "partnerDetail.defaultCapacity.label" })}
          tooltipContent={formatMessage({ id: "scheduleDetail.circulationId.info" })}
        />
        <FormikSelect
          name="defaultCirculationId"
          placeholder={formatMessage({ id: "partnerDetail.defaultCapacity.placeholder" })}
          options={
            queryData?.findPartner?.circulations
              ? queryData.findPartner.circulations.map((circulation) =>
                  createCirculationOption(circulation)
                )
              : []
          }
          isClearable
        />
      </div>
    );
  }

  return <></>;
};

export default CirculationSelect;
