import { contactEmail } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { useIntl } from "react-intl";
import * as css from "./NoMobilityPartnerText.scss";

const NoMobilityPartnerText: React.FC = () => {
  const { formatMessage } = useIntl();

  const createMarkup = (content: string) => {
    return { __html: content };
  };

  return (
    <p
      className={css.text}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={createMarkup(
        formatMessage(
          { id: "partnerForm.interconnectionRules.noMobilityPartner" },
          {
            contactEmail,
          },
          { ignoreTag: true }
        )
      )}
    />
  );
};

export default NoMobilityPartnerText;
