import { makeVar } from "@apollo/client";
import { BusinessRegion, MeansOfTransport } from "../../shared/types/schema";

export const defaultLineFilterValues = {
  searchTerm: null,
  businessRegions: null,
  meansOfTransport: null,
};

export type LineFilterVar = {
  searchTerm: string | null;
  businessRegions: Array<BusinessRegion["id"]> | null;
  meansOfTransport: Array<MeansOfTransport> | null;
};

const lineFilterVar = makeVar<LineFilterVar>(defaultLineFilterValues);

export default lineFilterVar;
