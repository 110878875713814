import { ForbiddenRelationsFormValues } from "../../types";

export default (
  existingRelations: Array<ForbiddenRelationsFormValues>,
  newRelations: Array<ForbiddenRelationsFormValues>
) => {
  const nonDuplicateRelations: Array<ForbiddenRelationsFormValues> = [];

  newRelations.forEach((newRelation) => {
    const relationIsDuplicate = existingRelations.some(
      (existingRelation) =>
        `${newRelation.from.id}-${newRelation.to.id}` ===
        `${existingRelation.from.id}-${existingRelation.to.id}`
    );

    if (!relationIsDuplicate) {
      nonDuplicateRelations.push(newRelation);
    }
  });

  return nonDuplicateRelations;
};
