@import "variables";
@import "../../helpers/styles/vars.scss";

$station-cell-base-z-index: 6;

.cell {
  height: $cell-height;
  width: $cell-width;
  border: $border;
}

.removeBorderBottom {
  border-bottom: none;
}

.removeBorderLeft {
  border-left: none;
}

.greyCell {
  background: $grayscale-10-color;
}

.whiteCell {
  background: $bg-primary-color;
}

.horizontalSticky {
  position: sticky;
  left: 0;
  z-index: $station-cell-base-z-index + 1;
  box-shadow: 7px 0px 15px -6px rgba(0, 0, 0, 0.2);
  &:hover {
    z-index: $station-cell-base-z-index + 3;
  }
}

.verticalSticky {
  position: sticky;
  top: 0;
  z-index: $station-cell-base-z-index;
  box-shadow: 0px 7px 15px -6px rgba(0, 0, 0, 0.2);
  &:hover {
    z-index: $station-cell-base-z-index + 3;
  }
}

.horizontalSticky.verticalSticky {
  box-shadow: 7px 0px 15px -6px rgba(0, 0, 0, 0.2);
  z-index: $station-cell-base-z-index + 2;
}
