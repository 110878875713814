import { Switch, Tooltip } from "@flixbus/honeycomb-react";
import * as React from "react";
import * as css from "./StatusFilter.scss";

export type StatusFilterProps = {
  label: string;
  checked: boolean;
  onChange: () => void;
  disabled: boolean;
  tooltipText: string;
};

const StatusFilter: React.FC<StatusFilterProps> = ({
  label,
  checked,
  onChange,
  disabled,
  tooltipText,
}) => {
  if (disabled) {
    return (
      <Tooltip
        id="disabled-status-filter-tooltip"
        content={tooltipText}
        openOnHover
        position="bottom"
        extraClasses={css.tooltip}
      >
        <div>
          <Switch
            id="disabled-status-filter"
            label={label}
            checked={false}
            extraClasses={css.switch}
            disabled
          />
        </div>
      </Tooltip>
    );
  }

  return (
    <Switch
      id="status-filter"
      label={label}
      checked={checked}
      onChange={onChange}
      extraClasses={css.switch}
    />
  );
};

export default StatusFilter;
