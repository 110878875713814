import { Checkbox, Heading } from "@flixbus/honeycomb-react";
import { useField } from "formik2";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ProofOfIdentity } from "../../../../shared/types/schema";
import * as css from "./IdentityProofInput.scss";

type Props = {
  readOnly?: boolean;
};

const IdentityProofInput: React.FC<Props> = ({ readOnly }) => {
  const { formatMessage } = useIntl();

  const [, meta, helper] = useField<Array<ProofOfIdentity>>(
    "checkInRequirements.proofOfIdentityRequired"
  );

  return (
    <>
      <Heading size={4}>
        <FormattedMessage id="partnerForm.checkInRequirements.identityProofRequirements.heading" />
      </Heading>

      {Object.values(ProofOfIdentity).map((field) => {
        const isChecked = meta.value.includes(field);

        return (
          <Checkbox
            id={field}
            key={field}
            value={field}
            label={formatMessage({
              id: `partnerForm.checkInRequirements.identityProofRequirements.option.${field}`,
            })}
            extraClasses={css.checkbox}
            checked={isChecked}
            onChange={() => {
              if (isChecked) {
                helper.setValue(meta.value.filter((value) => value !== field));
              } else {
                helper.setValue([...meta.value, field]);
              }
            }}
            disabled={readOnly}
          />
        );
      })}
    </>
  );
};

export default IdentityProofInput;
