import { PriceDirection } from "../../../../shared/types/schema";
import { ForbiddenRelationsFormValues, ForbiddenRelationStation } from "../../types";

export default (
  direction: PriceDirection,
  fromStation?: ForbiddenRelationStation,
  toStation?: ForbiddenRelationStation
): Array<ForbiddenRelationsFormValues> => {
  if (fromStation && toStation) {
    if (direction === PriceDirection.UniDirectional) {
      return [{ from: fromStation, to: toStation }];
    }

    return [
      { from: fromStation, to: toStation },
      { from: toStation, to: fromStation },
    ];
  }

  return [];
};
