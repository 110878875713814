import { formatMessage } from "@flixbus-phx/marketplace-common";
import * as Yup from "yup";
import { ScheduleStatus } from "../../../../shared/types/schema";

export default () => {
  return Yup.object().shape({
    newStatus: Yup.mixed()
      .oneOf(Object.values(ScheduleStatus))
      .required(formatMessage("error.validation.required")),
    comment: Yup.string().trim().required(formatMessage("error.validation.required")),
  });
};
