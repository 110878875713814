import { SelectGroup } from "@flixbus/honeycomb-react";
import * as React from "react";
import { useIntl } from "react-intl";
import { CalendarIntervallType } from "../../types";

export type ScheduleCalendarPeriodSelectProps = {
  onIntervallTypeChange: (selectedIntervallType: CalendarIntervallType) => void;
  selectedIntervalType: CalendarIntervallType;
};

const ScheduleCalendarPeriodSelect: React.FC<ScheduleCalendarPeriodSelectProps> = ({
  onIntervallTypeChange,
  selectedIntervalType,
}) => {
  const { formatMessage } = useIntl();
  const options = [
    {
      id: "year",
      value: "year",
      name: "period",
      label: formatMessage({ id: "general.year" }),
      checked: selectedIntervalType === CalendarIntervallType.YEAR,
      onChange: () => {
        onIntervallTypeChange(CalendarIntervallType.YEAR);
      },
    },
    {
      id: "month",
      value: "month",
      name: "period",
      label: formatMessage({ id: "general.month" }),
      checked: selectedIntervalType === CalendarIntervallType.MONTH,
      onChange: () => {
        onIntervallTypeChange(CalendarIntervallType.MONTH);
      },
    },
  ];

  return (
    <div>
      <SelectGroup aria-label="period" options={options} />
    </div>
  );
};

export default ScheduleCalendarPeriodSelect;
