import { Infobox, Spinner } from "@flixbus/honeycomb-react";
import * as React from "react";
import * as css from "./InfoAlert.scss";

export type InfoAlertProps = {
  type: "warning" | "danger" | "info";
  loading?: boolean;
};

const InfoAlert: React.FC<InfoAlertProps> = ({ type, loading = false, children }) => {
  return (
    <Infobox appearance={type === "info" ? undefined : type} extraClasses={css.infobox}>
      <div>
        {children}
        {loading && <Spinner size="sm" extraClasses={css.spinner} />}
      </div>
    </Infobox>
  );
};

export default InfoAlert;
