enum Breakpoint {
  XXS = 0,
  XS = 390,
  SM = 600,
  MD = 768,
  LG = 1024,
  XL = 1200,
}

export default Breakpoint;
