import { Breadcrumbs as HCBreadcrumbs, BreadcrumbsItem } from "@flixbus/honeycomb-react";
import * as React from "react";
import { NavLink } from "react-router-dom";
import * as css from "./Breadcrumbs.scss";
import { useFindLineQuery, useFindScheduleQuery } from "./api/operations.generated";
import {
  getLineBreadcrumbContent,
  getScheduleBreadcrumbContent,
} from "./helpers/getBreadcrumbContent/getBreadcrumbContent";
import { BreadcrumbContent } from "./types";
import BreadcrumbsSkeleton from "./ui/breadcrumbsSkeleton/BreadcrumbsSkeleton";

export type BreadcrumbsProps = {
  lineId?: string;
  scheduleId?: string;
};

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ lineId, scheduleId }) => {
  const [breadcrumbs, setBreadcrumbs] = React.useState<BreadcrumbContent>([]);
  const [loading, setLoading] = React.useState<boolean>(true);

  if (lineId) {
    useFindLineQuery({
      variables: { lineId },
      onCompleted: (data) => {
        setBreadcrumbs(getLineBreadcrumbContent(data?.findLine));
        setLoading(false);
      },
    });
  } else if (scheduleId) {
    useFindScheduleQuery({
      variables: { scheduleId },
      onCompleted: (data) => {
        setBreadcrumbs(getScheduleBreadcrumbContent(data?.findSchedule));
        setLoading(false);
      },
    });
  }

  if (loading) {
    return <BreadcrumbsSkeleton />;
  }

  if (breadcrumbs.length > 0) {
    return (
      <HCBreadcrumbs aria-label="breadcrumbs" extraClasses={css.wrapper}>
        {breadcrumbs.map((breadcrumb) => (
          <BreadcrumbsItem
            key={breadcrumb.path}
            RouterLink={NavLink}
            to={breadcrumb.path}
          >
            {breadcrumb.content}
          </BreadcrumbsItem>
        ))}
      </HCBreadcrumbs>
    );
  }

  return <></>;
};

export default Breadcrumbs;
