import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  TypePolicies,
} from "@apollo/client";
import { WatchQueryFetchPolicy } from "@apollo/client/core/watchQueryOptions";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { datadogRum } from "@datadog/browser-rum";
import * as React from "react";
import {
  AUTH_ISSUER_CYPRESS_COOKIE_NAME,
  buildAuthorizationHeader,
  getToken,
  isTestEnv,
  refreshToken,
} from "../../auth/utils/utils";
import { getCookie } from "../../helper/handleCookies/handleCookies";
import { getUserPreferredLanguage } from "../../helper/userPreferredLanguage/userPreferredLanguage";
import { ErrorMessages } from "../errors/ErrorTypes";

type StateProviderProps = {
  fetchPolicy: WatchQueryFetchPolicy;
  typePolicies?: TypePolicies;
};

const StateProvider: React.FC<StateProviderProps> = ({
  fetchPolicy,
  typePolicies,
  children,
}) => {
  const [hasNetworkError, setHasNetworkError] = React.useState(false);
  const [hasNotFoundError, setHasNotFoundError] = React.useState(false);
  const [hasAuthError, setHasAuthError] = React.useState(false);

  const language = getUserPreferredLanguage();

  const authLink = setContext(async () => {
    if (isTestEnv()) {
      // authToken is only set for e2e tests (Basic auth)
      // Otherwise get the id token from the authProvider
      return {
        headers: {
          authorization: getCookie(AUTH_ISSUER_CYPRESS_COOKIE_NAME),
          language,
        },
      };
    }

    const token = await getToken();

    return {
      headers: {
        ...buildAuthorizationHeader(token ? token.idToken : "").headers,
        language,
      },
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    const errors: string[] = [];
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        errors.push(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
        if (extensions?.errorCode === 404) {
          setHasNotFoundError(true);
        }
        if (extensions?.errorCode === 401) {
          refreshToken(operation, forward);
        }
        if (extensions?.errorCode === 403) {
          setHasAuthError(true);
        }
      });
    }

    if (networkError) {
      errors.push(`[Network error]: ${networkError}`);
      setHasNetworkError(true);
    }

    errors.forEach((error) => {
      datadogRum.addError(new Error(error));
      // eslint-disable-next-line no-console
      console.error(error);
    });
  });

  const httpLink = new HttpLink({
    uri: process.env.GQL_API || "/graphql",
    credentials: "same-origin",
  });

  const client = new ApolloClient({
    link: ApolloLink.from([authLink, errorLink, httpLink]),
    cache: new InMemoryCache({
      typePolicies: {
        ...typePolicies,
      },
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy,
      },
    },
  });

  if (hasNetworkError) {
    throw new Error(ErrorMessages.e500);
  }

  if (hasNotFoundError) {
    throw new Error(ErrorMessages.e404);
  }

  if (hasAuthError) {
    throw new Error(ErrorMessages.e403);
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default StateProvider;
