import * as React from "react";
import SearchPage from "../../searchPage/SearchPage";
import Layout from "../../ui/Layout";

const ShowSearch: React.FC = () => (
  <Layout>
    <SearchPage />
  </Layout>
);

export default ShowSearch;
