import { DefaultInfoAlert, legacyTranslate } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import LoadingAnimation from "../../shared/components/loadingAnimation/LoadingAnimation";
import { Line } from "../../shared/types/schema";
import { useOriginalSchedulesOfLineQuery } from "./api/operations.generated";
import ScheduleCalendarShell from "./ui/scheduleCalendarShell/ScheduleCalendarShell";

export type ScheduleCalendarProps = {
  lineId: Line["id"];
};

const ScheduleCalendar: React.FC<ScheduleCalendarProps> = ({ lineId }) => {
  const { loading, error, data } = useOriginalSchedulesOfLineQuery({
    variables: { lineId, excludeInactive: true },
    fetchPolicy: "cache-and-network",
  });

  if (loading) {
    return <LoadingAnimation />;
  }
  if (error) {
    return <DefaultInfoAlert message={error.message} translate={legacyTranslate} />;
  }

  if (data) {
    return <ScheduleCalendarShell schedules={data.findOriginalSchedules} />;
  }

  return <></>;
};

export default ScheduleCalendar;
