import { Icon, IconArrowRight, IconComponent } from "@flixbus/honeycomb-icons-react";
import { NavItem, NavSide } from "@flixbus/honeycomb-react";
import * as React from "react";
import * as css from "./NavSideForm.scss";

export type NavSideFormItems = Array<{
  label: string | React.JSX.Element;
  icon: IconComponent;
  content: React.JSX.Element;
}>;

type Props = {
  items: NavSideFormItems;
};

const NavSideForm: React.FC<Props> = ({ items }) => {
  const [selectedItem, setSelectedItem] = React.useState(0);

  return (
    <div className={css.wrapper}>
      <div className={css.nav}>
        <NavSide aria-label="form">
          {items.map(({ icon, label }, index) => {
            const isActive = selectedItem === index;
            return (
              <NavItem
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                InlineIcon={icon}
                active={isActive}
                onClick={() => setSelectedItem(index)}
                extraClasses={!isActive ? css.inactiveNavItem : undefined}
              >
                {label}
                {isActive && (
                  <Icon
                    InlineIcon={IconArrowRight}
                    extraClasses={css.arrowIcon}
                    appearance="secondary"
                  />
                )}
              </NavItem>
            );
          })}
        </NavSide>
      </div>
      <div className={css.content}>{items[selectedItem].content}</div>
    </div>
  );
};

export default NavSideForm;
