import { Icon, IconDelete, IconPlus } from "@flixbus/honeycomb-icons-react";
import { Button, Divider, Fineprint, Heading } from "@flixbus/honeycomb-react";
import cx from "classnames";
import { FieldArray, FieldArrayRenderProps, useField } from "formik2";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import FormikInput from "../../../../shared/components/formikInput/FormikInput";
import * as css from "./ContactEmailsInput.scss";

type Props = {
  readOnly: boolean;
};

const ContactEmailsInput: React.FC<Props> = ({ readOnly }) => {
  const { formatMessage } = useIntl();
  const [, meta] = useField<Array<string>>("contactEmails");

  if (readOnly && meta.value.length === 0) {
    return null;
  }

  const addButton = (arrayHelpers: FieldArrayRenderProps) =>
    !readOnly && (
      <div>
        <Button data-id="addEmail" onClick={() => arrayHelpers.push("")}>
          <Icon InlineIcon={IconPlus} />
          <FormattedMessage id="partnerForm.bookingInfo.contactEmails.add" />
        </Button>
      </div>
    );

  return (
    <>
      <Divider extraClasses={css.divider} />
      <Heading size={4} extraClasses={css.heading}>
        <FormattedMessage id="partnerForm.bookingInfo.contactEmails.headline" />
        {!readOnly && (
          <>
            <br />
            <Fineprint extraClasses={css.fineprint}>
              <FormattedMessage id="partnerForm.bookingInfo.info.contactEmails" />
            </Fineprint>
          </>
        )}
      </Heading>
      <div className={css.container}>
        <FieldArray
          name="contactEmails"
          render={(arrayHelpers) => (
            <>
              {meta.value.map((_, index) => (
                <div
                  className={cx(css.emailRow, !readOnly && css.emailRowEditable)}
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                >
                  <div className={css.emailInput}>
                    <FormikInput
                      placeholder={formatMessage({
                        id: "partnerForm.bookingInfo.contactEmails.input.placeholder",
                      })}
                      label={formatMessage({
                        id: "partnerForm.bookingInfo.contactEmails.input.placeholder",
                      })}
                      hideLabel
                      name={`contactEmails[${index}]`}
                      type={readOnly ? "text" : "email"}
                      readOnly={readOnly}
                    />
                  </div>
                  <div className={css.removeEmail}>
                    {!readOnly && (
                      <Button
                        aria-label={formatMessage({ id: "general.delete" })}
                        data-id={`removeEmail-${index}`}
                        display="square"
                        onClick={() => arrayHelpers.remove(index)}
                      >
                        <Icon InlineIcon={IconDelete} />
                      </Button>
                    )}
                  </div>
                  {(meta.value.length - 1 === index && addButton(arrayHelpers)) || (
                    <div className={css.clearfix} />
                  )}
                </div>
              ))}
              {meta.value.length === 0 && addButton(arrayHelpers)}
            </>
          )}
        />
      </div>
    </>
  );
};

export default ContactEmailsInput;
