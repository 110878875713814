import { PriceDirection, ScheduleDirection } from "../../../../shared/types/schema";
import isInLowerTriangle from "../isInLowerTriangle/isInLowerTriangle";

const isPriceCellReadOnly = (
  rowIndex: number,
  colIndex: number,
  readOnly: boolean,
  priceDirection: PriceDirection,
  scheduleDirection: ScheduleDirection
): boolean => {
  if (readOnly) {
    return true;
  }

  if (
    priceDirection === PriceDirection.BiDirectional &&
    scheduleDirection === ScheduleDirection.TwoDirectional
  ) {
    return isInLowerTriangle(rowIndex, colIndex);
  }

  return false;
};

export default isPriceCellReadOnly;
