import React__default__default from 'react';

/**
 * Creates a controllable state. AKA: when a state is provided, only allows setting the state value
 * from outside of the component. Otherwise, creates a new state with working setState function.
 * E.g.: ChoiceWrapper selected state, Tooltip active state, and other components that have internal
 * state management that could be overwritten by external props.
 *
 * @param object optional state value (if is controllable) and a default value.
 * @returns `[value, setValue]` an array with value and value setter function.
 */
function useControllableState(_a) {
  var state = _a.state,
    defaultState = _a.defaultState;
  var _b = React__default__default.useState(defaultState),
    controlledState = _b[0],
    setControlledState = _b[1];
  var isControlled = React__default__default.useRef(state !== undefined).current;
  var value = isControlled ? state : controlledState;
  var setValue = React__default__default.useCallback(function (nextValue) {
    if (!isControlled) {
      setControlledState(nextValue);
    }
  }, []);
  return [value, setValue];
}

export { useControllableState as default };
