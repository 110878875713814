// Hack to make the lang switcher inside the widget occupy the full width
// https://jira.flix.tech/projects/HNY/queues/issue/HNY-152
[class^="hcr-header-burger-menu-subnav__text"],
nav[class^="hcr-header-user-widget"] [class^="hcr-header-nav-subnav__text"] {
  width: 100%;

  .toggle {
    width: 100%;
    &:hover {
      background: none;
    }
  }
}
