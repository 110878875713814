// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// This file was automatically generated using npm run codegen
// If you want to alter this file please do so via the above mentioned command
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
import * as Types from "../../../types/schema";

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
const defaultOptions = {} as const;
export type FindPartnerDetailsForCirculationSelectQueryVariables = Types.Exact<{
  id: Types.Scalars["ID"]["input"];
}>;

export type FindPartnerDetailsForCirculationSelectQuery = {
  __typename?: "Query";
  findPartner: {
    __typename?: "Partner";
    id: string;
    circulations: Array<{
      __typename?: "Circulation";
      id: string;
      uuid: string;
      name: string;
      activeConfiguration?: {
        __typename?: "CirculationConfiguration";
        seatCapacity: number;
        bikeCapacity: number;
        wheelchairCapacity: number;
      } | null;
    }>;
  };
};

export const FindPartnerDetailsForCirculationSelectDocument = gql`
  query findPartnerDetailsForCirculationSelect($id: ID!) {
    findPartner(id: $id) {
      id
      circulations {
        id
        uuid
        name
        activeConfiguration {
          seatCapacity
          bikeCapacity
          wheelchairCapacity
        }
      }
    }
  }
`;

/**
 * __useFindPartnerDetailsForCirculationSelectQuery__
 *
 * To run a query within a React component, call `useFindPartnerDetailsForCirculationSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindPartnerDetailsForCirculationSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindPartnerDetailsForCirculationSelectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindPartnerDetailsForCirculationSelectQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FindPartnerDetailsForCirculationSelectQuery,
    FindPartnerDetailsForCirculationSelectQueryVariables
  > &
    (
      | {
          variables: FindPartnerDetailsForCirculationSelectQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FindPartnerDetailsForCirculationSelectQuery,
    FindPartnerDetailsForCirculationSelectQueryVariables
  >(FindPartnerDetailsForCirculationSelectDocument, options);
}
export function useFindPartnerDetailsForCirculationSelectLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FindPartnerDetailsForCirculationSelectQuery,
    FindPartnerDetailsForCirculationSelectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FindPartnerDetailsForCirculationSelectQuery,
    FindPartnerDetailsForCirculationSelectQueryVariables
  >(FindPartnerDetailsForCirculationSelectDocument, options);
}
export function useFindPartnerDetailsForCirculationSelectSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    FindPartnerDetailsForCirculationSelectQuery,
    FindPartnerDetailsForCirculationSelectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    FindPartnerDetailsForCirculationSelectQuery,
    FindPartnerDetailsForCirculationSelectQueryVariables
  >(FindPartnerDetailsForCirculationSelectDocument, options);
}
export type FindPartnerDetailsForCirculationSelectQueryHookResult = ReturnType<
  typeof useFindPartnerDetailsForCirculationSelectQuery
>;
export type FindPartnerDetailsForCirculationSelectLazyQueryHookResult = ReturnType<
  typeof useFindPartnerDetailsForCirculationSelectLazyQuery
>;
export type FindPartnerDetailsForCirculationSelectSuspenseQueryHookResult = ReturnType<
  typeof useFindPartnerDetailsForCirculationSelectSuspenseQuery
>;
