import { Locale, format } from "date-fns";
import { de, enUS, es, fr, hr, it, pl, pt, ptBR, tr, uk } from "date-fns/locale";
import { parseDate } from "../parseDate/parseDate";
import { getUserPreferredLanguage } from "../userPreferredLanguage/userPreferredLanguage";

export const getLocale = (): Locale => {
  switch (getUserPreferredLanguage()) {
    case "de":
      return de;
    case "en_US":
      return enUS;
    case "es":
      return es;
    case "fr":
      return fr;
    case "hr":
      return hr;
    case "it":
      return it;
    case "pl":
      return pl;
    case "pt_PT":
      return pt;
    case "pt_BR":
      return ptBR;
    case "tr":
      return tr;
    case "uk":
      return uk;
    default:
      return enUS;
  }
};

// Apr 29, 1453
export const localizeDate = (date: Date | string): string => {
  if (typeof date === "string") {
    return format(parseDate(date), "PP", { locale: getLocale() });
  }
  return format(date, "PP", { locale: getLocale() });
};

// Apr 29, 1453, 12:00 AM
export const localizeDateTime = (date: Date | string): string => {
  if (typeof date === "string") {
    return format(new Date(date), "PPp", { locale: getLocale() });
  }
  return format(date, "PPp", { locale: getLocale() });
};

// Jan, Feb, ..., Dec
export const localizeMonth = (date: Date): string => {
  return format(date, "MMM", { locale: getLocale() });
};

// Apr 29
export const localizeMonthWithDay = (date: Date): string => {
  return format(date, "MMM d", { locale: getLocale() });
};

// April 1453
export const localizeMonthWithYear = (date: Date): string => {
  return format(date, "MMMM yyyy", { locale: getLocale() });
};

// Mon, Apr 29, 1453
export const localizeDateWithWeekday = (date: Date): string => {
  return format(date, "iii, PP", { locale: getLocale() });
};

// Mon, Tue, Wed, ..., Sun
export const localizeWeekday = (date: Date): string => {
  return format(date, "iii", { locale: getLocale() });
};

// 1, 2, 3, ..., 31
export const localizeDayOfMonth = (date: Date): string => {
  return format(date, "d", { locale: getLocale() });
};
