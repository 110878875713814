@import "src/shared/styles/variables";

.container {
  min-height: 60px;
  width: 98px;
  padding: 0 $spacing-2;
  border-top: $border;
  box-sizing: border-box;
}

.fullHeightContainer {
  height: $timetable-station-box-height;
}

.arrText {
  padding-top: 19px;
  height: $timetable-station-box-height * 0.5;
}

.depText {
  padding-top: $spacing-2;
  height: $timetable-station-box-height * 0.5;
}

.depTextWithoutArrival {
  padding-top: 19px;
  height: $timetable-station-box-height * 0.5;
}
