import { TypePolicies } from "@apollo/client";

const typePolicies: TypePolicies = {
  Query: {
    fields: {
      filterCities: {
        // https://www.apollographql.com/docs/react/pagination/core-api/#merging-paginated-results
        // Don't cache separate results based on any of this queries variables.
        keyArgs: false,

        // Replace the existing items with the incoming items
        merge(_, incoming) {
          return incoming;
        },
      },
      filterStations: {
        // https://www.apollographql.com/docs/react/pagination/core-api/#merging-paginated-results
        // Don't cache separate results based on any of this queries variables.
        keyArgs: false,

        // Replace the existing items with the incoming items
        merge(_, incoming) {
          return incoming;
        },
      },
      filterLines: {
        // https://www.apollographql.com/docs/react/pagination/core-api/#merging-paginated-results
        // Don't cache separate results based on any of this queries variables.
        keyArgs: false,

        // Replace the existing items with the incoming items
        merge(_, incoming) {
          return incoming;
        },
      },
    },
  },
  MobilityPartner: {
    keyFields: ["uuid"],
  },
  Operator: {
    keyFields: ["uuid"],
  },
};

export default typePolicies;
