import { Icon, IconClose, IconMagnifier } from "@flixbus/honeycomb-icons-react";
import { Button, Checkbox, Input, Skeleton } from "@flixbus/honeycomb-react";
import { normalizeText } from "@flixbus-phx/marketplace-common";
import { useField } from "formik2";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { BusinessRegion } from "../../../shared/types/schema";
import {
  FindAllBusinessRegionsForFiltersQuery,
  useFindAllBusinessRegionsForFiltersQuery,
} from "../../api/operations.generated";
import * as checkBoxCss from "../form_field_styles.scss";
import * as css from "./BusinessRegionChecklistFilter.scss";

const BusinessRegionChecklistFilter: React.FC = () => {
  const [searchValue, setSearchValue] = React.useState<string>("");
  const { data, loading, error } = useFindAllBusinessRegionsForFiltersQuery();
  const [, meta, helper] = useField<Array<BusinessRegion["id"]>>("businessRegions");

  const { formatMessage } = useIntl();

  const filterAndSortCheckList = (
    bizRegions: FindAllBusinessRegionsForFiltersQuery["findAllBusinessRegions"]
  ) => {
    const sortedBizRegions = [...bizRegions]
      .sort((a, b) => a.name.localeCompare(b.name))
      .filter((bizRegion) => {
        if (!searchValue) return true;
        return normalizeText(bizRegion.name).includes(normalizeText(searchValue));
      });

    if (sortedBizRegions.length) {
      return sortedBizRegions.map((bizRegion) => (
        <Checkbox
          extraClasses={checkBoxCss.checkbox}
          key={bizRegion.id}
          label={bizRegion.name}
          id={bizRegion.id}
          value="tiny"
          small
          defaultChecked={meta.value.includes(bizRegion.id)}
          onChange={(e) => {
            if (e.target.checked) {
              helper.setValue([...meta.value, bizRegion.id]);
            } else {
              helper.setValue(meta.value.filter((id) => id !== bizRegion.id));
            }
          }}
        />
      ));
    }

    return (
      <div
        className={css.textMessage}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: formatMessage({ id: "search.noResults.extended" }, { ignoreTag: true }),
        }}
      />
    );
  };

  return (
    <div>
      <Input
        extraClasses={css.input}
        id="biz-region-search"
        aria-label={formatMessage({ id: "bizRegion.search.placeholder" })}
        type="text"
        placeholder={formatMessage({ id: "bizRegion.search.placeholder" })}
        iconLeft={<Icon InlineIcon={IconMagnifier} />}
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        iconRight={
          searchValue.length ? (
            <Button appearance="link" onClick={() => setSearchValue("")}>
              <Icon InlineIcon={IconClose} />
            </Button>
          ) : undefined
        }
      />
      <div className={css.checklist}>
        {error && (
          <div className={css.textMessage}>
            <FormattedMessage id="error.500.description.top" />
          </div>
        )}
        {loading &&
          Array.from({ length: 8 }).map((_, index) => {
            // eslint-disable-next-line react/no-array-index-key
            return <Skeleton key={index} extraClasses={css.skeleton} />;
          })}
        {data?.findAllBusinessRegions &&
          filterAndSortCheckList(data.findAllBusinessRegions)}
      </div>
    </div>
  );
};

export default BusinessRegionChecklistFilter;
