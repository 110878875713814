import { IconArchive, IconCheckmarkStrong } from "@flixbus/honeycomb-icons-react";
import {
  addNotification,
  formatErrorMessage,
  NotificationType,
} from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import buildPath from "../../../../routes/buildPath/buildPath";
import ConfirmationPopup from "../../../../shared/components/confirmationPopup/ConfirmationPopup";
import { DisplayedScheduleStatus, ScheduleStatus } from "../../../../shared/types/schema";
import {
  FindScheduleStatusHistoryQuery,
  useCopyScheduleMutation,
  useDeleteScheduleMutation,
  useFindScheduleStatusHistoryLazyQuery,
  useCreateConceptChangeMutation,
  useChangeScheduleStatusMutation,
  useActivateOnSaleMutation,
  useArchiveScheduleMutation,
} from "../../api/operations.generated";
import ScheduleActionsButton from "../../ui/scheduleActionsButton/ScheduleActionsButton";
import ScheduleStatusChangePopup from "../../ui/scheduleStatusChangePopup/ScheduleStatusChangePopup";
import ScheduleStatusHistoryPopup from "../../ui/scheduleStatusHistoryPopup/ScheduleStatusHistoryPopup";

type Props = {
  scheduleId: string;
  scheduleName: string;
  scheduleStatus: ScheduleStatus;
  displayedScheduleStatus: DisplayedScheduleStatus;
  originalScheduleStatus?: ScheduleStatus;
  lineId: string;
  readOnly: boolean;
};

const ScheduleActions: React.FC<Props> = ({
  scheduleId,
  scheduleName,
  scheduleStatus,
  displayedScheduleStatus,
  originalScheduleStatus,
  lineId,
  readOnly,
}) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  // Schedule Copy
  const [handleScheduleCopy] = useCopyScheduleMutation({
    onCompleted: (data) => {
      navigate(buildPath.showScheduleDetail(data.copySchedule.id));
    },
    onError: (error) =>
      addNotification({
        message: `Copy of Schedule failed: ${formatErrorMessage(error.message)}`,
        type: NotificationType.Danger,
      }),
  });

  // Create Concept Change
  const [handleCreateConceptChange] = useCreateConceptChangeMutation({
    onCompleted: (data) => {
      navigate(buildPath.showScheduleDetail(data.createConceptChange.id));
    },
    onError: (error) =>
      addNotification({
        message: `Schedule concept change creation failed: ${formatErrorMessage(
          error.message
        )}`,
        type: NotificationType.Danger,
      }),
  });

  // Schedule Status History
  const [statusHistory, setStatusHistory] = React.useState<
    FindScheduleStatusHistoryQuery["findSchedule"]["statusHistory"]
  >([]);
  const [statusHistoryPopupActive, setStatusHistoryPopupActive] = React.useState(false);
  const [deleteConfirmationPopupActive, setDeleteConfirmationPopupActive] =
    React.useState(false);
  const [onSaleConfirmationPopupActive, setOnSaleConfirmationPopupActive] =
    React.useState(false);
  const [archiveConfirmationPopupActive, setArchiveConfirmationPopupActive] =
    React.useState(false);

  const [findStatusHistory] = useFindScheduleStatusHistoryLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data) {
        setStatusHistory(data.findSchedule.statusHistory);
        setStatusHistoryPopupActive(true);
      }
    },
    onError: (error) =>
      addNotification({
        message: `Could not load status history: ${formatErrorMessage(error.message)}`,
        type: NotificationType.Danger,
      }),
  });

  // Schedule Delete
  const [handleScheduleDelete] = useDeleteScheduleMutation({
    onCompleted: () => {
      navigate(buildPath.showLineDetail(lineId));
    },
    onError: (error) =>
      addNotification({
        message: `Deleting schedule failed: ${formatErrorMessage(error.message)}`,
        type: NotificationType.Danger,
      }),
  });

  const handleDelete = () => {
    handleScheduleDelete({ variables: { id: scheduleId } });
    setDeleteConfirmationPopupActive(false);
  };

  const [handleActivateScheduleOnSale] = useActivateOnSaleMutation({
    onCompleted: () => {
      addNotification({
        message: "Action was successful. Rides will be put on sale soon",
        type: NotificationType.Success,
      });
    },
    onError: (error) =>
      addNotification({
        message: `Schedule could not be activated on sale: ${formatErrorMessage(
          error.message
        )}`,
        type: NotificationType.Danger,
      }),
  });

  const handleActivateOnSale = () => {
    handleActivateScheduleOnSale({ variables: { scheduleId } });
    setOnSaleConfirmationPopupActive(false);
  };

  const [handleArchiveSchedule] = useArchiveScheduleMutation({
    onCompleted: () => {
      addNotification({
        message: "Action was successful. Rides will be archived soon",
        type: NotificationType.Success,
      });
    },
    onError: (error) =>
      addNotification({
        message: `Schedule could not be archived: ${formatErrorMessage(error.message)}`,
        type: NotificationType.Danger,
      }),
  });

  const handleArchive = () => {
    handleArchiveSchedule({ variables: { scheduleId } });
    setArchiveConfirmationPopupActive(false);
  };

  const [changeScheduleStatus] = useChangeScheduleStatusMutation({
    onError: (error) =>
      addNotification({
        message: `An error occurred, please try again. ${formatErrorMessage(
          error.message
        )}`,
        type: NotificationType.Danger,
      }),
    onCompleted: () => {
      addNotification({ message: "Schedule status successfully changed" });
    },
  });

  const [scheduleStatusChangePopupActive, setScheduleStatusChangePopupActive] =
    React.useState(false);

  const handleStatusChange = (newStatus: ScheduleStatus, comment: string): void => {
    changeScheduleStatus({
      variables: { scheduleId, newStatus, comment },
    });
    setScheduleStatusChangePopupActive(false);
  };

  return (
    <>
      <ScheduleActionsButton
        onActivateOnSale={() => setOnSaleConfirmationPopupActive(true)}
        onArchiveSchedule={() => setArchiveConfirmationPopupActive(true)}
        onCopy={() => handleScheduleCopy({ variables: { id: scheduleId } })}
        onCreateConceptChange={() =>
          handleCreateConceptChange({ variables: { id: scheduleId } })
        }
        onStatusHistory={() => findStatusHistory({ variables: { scheduleId } })}
        onDelete={() => setDeleteConfirmationPopupActive(true)}
        scheduleStatus={scheduleStatus}
        displayedScheduleStatus={displayedScheduleStatus}
        originalScheduleStatus={originalScheduleStatus}
        onChangeStatus={() => setScheduleStatusChangePopupActive(true)}
        readOnly={readOnly}
      />
      <ScheduleStatusHistoryPopup
        statusHistoryData={statusHistory}
        active={statusHistoryPopupActive}
        onPopupClose={() => setStatusHistoryPopupActive(false)}
      />
      <ConfirmationPopup
        data-id="delete-popup"
        active={deleteConfirmationPopupActive}
        titleText={formatMessage({ id: "schedule.delete" })}
        confirmButtonText={formatMessage({ id: "general.delete" })}
        onConfirm={handleDelete}
        onCancel={() => setDeleteConfirmationPopupActive(false)}
      >
        <div>
          <FormattedMessage
            id="scheduleActions.delete.popup.text"
            values={{ scheduleName }}
          />
        </div>
      </ConfirmationPopup>
      <ConfirmationPopup
        data-id="put-on-sale-popup"
        active={onSaleConfirmationPopupActive}
        titleIcon={IconCheckmarkStrong}
        titleText={formatMessage({ id: "scheduleActions.activateOnSale.popup.title" })}
        confirmButtonText={formatMessage({ id: "general.yes" })}
        onConfirm={handleActivateOnSale}
        onCancel={() => setOnSaleConfirmationPopupActive(false)}
      />
      <ConfirmationPopup
        data-id="archive-popup"
        active={archiveConfirmationPopupActive}
        titleIcon={IconArchive}
        titleText={formatMessage({ id: "scheduleActions.archiveSchedule.popup.title" })}
        confirmButtonText={formatMessage({ id: "general.yes" })}
        onConfirm={handleArchive}
        onCancel={() => setArchiveConfirmationPopupActive(false)}
      />
      <ScheduleStatusChangePopup
        active={scheduleStatusChangePopupActive}
        onSubmit={handleStatusChange}
        onPopupClose={() => setScheduleStatusChangePopupActive(false)}
      />
    </>
  );
};

export default ScheduleActions;
