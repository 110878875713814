@import "variables";

.wrapper {
  position: relative;
  z-index: 3;
  margin-bottom: $spacing-2;

  [class^="hcr-breadcrumbs__link"] {
    max-width: 350px;
  }
}
