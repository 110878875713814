import { Currency, LengthUnit, WeightUnit } from "../../../../shared/types/schema";
import { PoliciesFormValues, PoliciesDefaultValues } from "../../types";
import getInitialValuesForAllPolicies from "../getInitialValuesForAllPolicies/getInitialValuesForAllPolicies";

const getInitialValues = (defaultValues?: PoliciesDefaultValues): PoliciesFormValues => ({
  units: {
    weightUnit: defaultValues?.units.weightUnit || WeightUnit.Kilograms,
    lengthUnit: defaultValues?.units.lengthUnit || LengthUnit.Centimeters,
    currency: defaultValues?.units.currency || Currency.Eur,
  },
  policies: getInitialValuesForAllPolicies(defaultValues?.policies),
});

export default getInitialValues;
