import { DayOfWeek } from "../../../../shared/types/schema";
import weekdaysMap from "../weekdaysMap/weekdaysMap";

const sortWorkingdays = (workingdays: Array<DayOfWeek>) => {
  workingdays.sort((a, b) => {
    return weekdaysMap[a] - weekdaysMap[b];
  });

  return workingdays;
};

export default (workingDays?: Array<DayOfWeek> | null) => {
  const initialValues: Array<DayOfWeek | undefined> = Array.from({ length: 7 });

  if (workingDays) {
    const weekdayValues = Object.values(DayOfWeek);

    sortWorkingdays(workingDays).forEach((value) => {
      const index = weekdayValues.indexOf(value);

      initialValues[index] = value;
    });
  }

  return initialValues;
};
