import * as React from "react";
import { Link } from "react-router-dom";
import buildPath from "../../../../routes/buildPath/buildPath";
import { SchedulesOfLineQuery } from "../../api/operations.generated";
import * as css from "./ScheduleLink.scss";

type Props = {
  scheduleId: SchedulesOfLineQuery["findLine"]["schedules"][number]["id"];
};

const ScheduleLink: React.FC<Props> = ({ scheduleId, children }) => {
  return (
    <Link to={buildPath.showScheduleDetail(scheduleId)} className={css.link}>
      {children}
    </Link>
  );
};

export default ScheduleLink;
