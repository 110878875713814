import { FormikErrors, FormikTouched } from "formik";

export type validateFormFieldParams = {
  touched: boolean | FormikTouched<any> | undefined;
  error: string | FormikErrors<any> | undefined;
};

export const isBlankString = (value?: string | number): boolean => {
  return typeof value === "string" && value.trim().length === 0;
};

export const isFormFieldValid = (
  touched: validateFormFieldParams["touched"],
  error: validateFormFieldParams["error"],
  value?: string | number
): boolean | undefined => {
  if (!touched) {
    return undefined;
  }

  if (error) {
    return false;
  }

  if (isBlankString(value)) {
    return undefined;
  }

  return true;
};

export const getFormFieldInfo = (
  touched: validateFormFieldParams["touched"],
  error: validateFormFieldParams["error"],
  info?: string
): string | undefined => {
  if (touched) {
    if (error && typeof error === "string") {
      return error;
    }
    return info;
  }
  return info;
};
