@import "variables";

$textarea-height: 95px;

.textarea {
  min-height: $textarea-height;
  resize: none;
}

.wrapper {
  position: relative;
}

.counterWrapper {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: calc(#{$textarea-height} + 27px);
  width: 100%;
  margin-top: $spacing-half;
}

.error {
  color: $danger-dark-color;
}
