import { Icon, IconArrowBigRight, IconSwitch } from "@flixbus/honeycomb-icons-react";
import { formatMessage } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { SelectOption } from "../../helpers/reactSelect/types";
import { PriceDirection } from "../../types/schema";
import Select from "../select/Select";
import * as css from "./RelationDirectionSelect.scss";

export type RelationDirectionSelectProps = {
  label: string;
  value: string;
  onSelect: (priceDirection: PriceDirection) => void;
};

export const relationDirectionSelectOptions = (): Array<SelectOption> => [
  {
    label: formatMessage("prices.priceDirection.select.option.uniDirectional"),
    value: PriceDirection.UniDirectional,
    icon: <Icon InlineIcon={IconArrowBigRight} />,
  },
  {
    label: formatMessage("prices.priceDirection.select.option.biDirectional"),
    value: PriceDirection.BiDirectional,
    icon: <Icon InlineIcon={IconSwitch} />,
  },
];

const RelationDirectionSelect: React.FC<RelationDirectionSelectProps> = ({
  label,
  value,
  onSelect,
}) => {
  const options = relationDirectionSelectOptions();

  return (
    <div className={css.wrapper}>
      <Select
        label={label}
        initialValue={{
          value,
          label:
            value === PriceDirection.UniDirectional ? options[0].label : options[1].label,
          icon:
            value === PriceDirection.UniDirectional ? options[0].icon : options[1].icon,
        }}
        options={options}
        onSelect={(option) => {
          if (option.value !== value) {
            onSelect(option.value as PriceDirection);
          }
        }}
      />
    </div>
  );
};

export default RelationDirectionSelect;
