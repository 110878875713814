@import "../../styles/variables";

.grid {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 52px auto;
  column-gap: $spacing-4;
}

.topLeft {
  grid-area: 1 / 1 / 1 / 1;
  align-self: center;
  display: flex;
}

.bottom {
  grid-area: 2 / 1 / 2 / 3;
  padding-top: 28px;
}

.topRight {
  grid-area: 1 / 2 / 1 / 2;
  justify-self: end;
  align-self: center;
}
