import { PageContainer } from "@flixbus/honeycomb-react";
import cx from "classnames";
import * as React from "react";
import * as css from "./Layout.scss";

type LayoutProps = {
  children: React.ReactNode;
  useFullscreen?: boolean;
  smallTopPadding?: boolean;
  extraSmallTopPadding?: boolean;
  noTopPadding?: boolean;
  clearRight?: boolean;
  clearLeft?: boolean;
};

const Layout: React.FC<LayoutProps> = ({
  children,
  useFullscreen,
  smallTopPadding,
  extraSmallTopPadding,
  noTopPadding,
  clearRight,
  clearLeft,
}) => (
  <PageContainer
    extraClasses={cx(
      css.defaultContainer,
      smallTopPadding && css.smallTopPadding,
      extraSmallTopPadding && css.extraSmallTopPadding,
      noTopPadding && css.noTopPadding,
      useFullscreen && css.fullscreen,
      clearRight && css.clearRight,
      clearLeft && css.clearLeft
    )}
  >
    {children}
  </PageContainer>
);

export default Layout;
