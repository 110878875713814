import { Input } from "@flixbus/honeycomb-react";
import cx from "classnames";
import * as React from "react";
import {
  formatPriceInput,
  formatPriceNumberInput,
  priceRegex,
} from "../../../../shared/helpers/priceInputFormatter/priceInputFormatter";
import { Currency, RelationMatrixEntry } from "../../../../shared/types/schema";
import isPriceInputValid from "../../helpers/isPriceInputValid/isPriceInputValid";
import * as css from "./PriceInput.scss";

export type PriceInputProps = {
  value?: RelationMatrixEntry["price"];
  onValueChanged: (value: number | null) => void;
  currency: Currency;
  isPriceInvalid: boolean;
  onFocus: () => void;
  onBlur: () => void;
};

const PriceInput: React.FC<PriceInputProps> = ({
  value,
  onValueChanged,
  currency,
  isPriceInvalid,
  onFocus,
  onBlur,
}) => {
  const [currentValue, setCurrentValue] = React.useState(formatPriceNumberInput(value));

  React.useEffect(() => {
    setCurrentValue(formatPriceNumberInput(value));
  }, [value]);

  const handleOnBlur = () => {
    const formattedValue = formatPriceInput(currentValue);

    if (formattedValue === "") {
      onValueChanged(null);
    } else {
      onValueChanged(Number.parseFloat(formattedValue.replace(",", ".")));
    }

    setCurrentValue(formattedValue);
    onBlur();
  };

  const handleOnChange = (changedValue: string) => {
    if (priceRegex.test(changedValue)) {
      setCurrentValue(changedValue);
    }
  };

  const isInputError = isPriceInvalid && !isPriceInputValid(currentValue);

  return (
    <Input
      type="text"
      name="price-input"
      id="price-input"
      aria-label="price input"
      value={currentValue}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        handleOnChange(event.target.value)
      }
      extraClasses={cx(css.priceInput, isInputError ? css.error : "")}
      placeholder="--,--"
      onBlur={handleOnBlur}
      inlineLabelLeft={currency}
      onFocus={onFocus}
    />
  );
};

export default PriceInput;
