@import "variables";
@import "../../styles/inline-edit.scss";

$border-width: 1px;
$headingHeight: 46px;

.flexBox {
  display: inline-flex;
  width: 100%;
}

.inputFlex {
  height: 36px;
  @include input-flex;
}

.inputFlexHeading {
  height: $headingHeight;
  margin-bottom: $spacing-4;

  > div:before {
    top: 10px;
  }
}

.notEditableWrapper {
  white-space: nowrap;
  @include not-editable-wrapper;
  > .notEditableText,
  .readOnlyText {
    padding: 0 $spacing-2;
    line-height: 36px;
  }
}

.notEditableWrapperLoading {
  @include not-editable-wrapper-loading;
}

.notEditableText {
  @include not-editable-text;
}

.readOnlyEditIcon {
  @include readOnly-edit-icon;
}

.readOnlyText {
  overflow: hidden;
  text-overflow: ellipsis;
  @include readOnly-text;
}

.notEditableEditIcon {
  @include not-editable-edit-icon;
}

.input {
  @include input;
}

.cancelButton {
  @include cancel-button;
  align-self: flex-start;
}

.cancelButton + .submitButton {
  @include cancel-submit-button;
}

.submitButton {
  @include submit-button;
  align-self: flex-start;
}

.notEditableWrapperHeading {
  height: $headingHeight;
  align-items: center;
  border: none;

  &:hover {
    border: $border;
    border-radius: $border-radius;

    > .notEditableText,
    .readOnlyText {
      padding: 5px $spacing-2;
    }

    > .notEditableEditIcon,
    .readOnlyEditIcon {
      height: $headingHeight - $border-width - $border-width;
      width: $headingHeight - $border-width - $border-width;
      display: flex;
    }
  }

  > .notEditableText,
  .readOnlyText {
    height: unset;
    padding: 5px 0;
  }
}

.inputHeading {
  > div {
    height: $headingHeight;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  input {
    font-size: 28px;
    font-weight: 700;
  }
}

.buttonHeading {
  width: $headingHeight;
  height: $headingHeight;
}

.infoText {
  color: #444;
  font-size: 14px;
  font-weight: 300;
  line-height: 17px;
  display: block;
  padding-top: 3px;
}

.inputInfoText {
  margin-bottom: 17px;
}
