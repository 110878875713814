@import "variables";

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.switchWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  position: sticky;
  bottom: 0;
  padding-top: $spacing-4;
  padding-bottom: $spacing-4;
  background-color: $box-bg-color;
}
