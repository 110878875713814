import { PolicyType } from "../../../../shared/types/schema";
import { PoliciesDefaultValues, PoliciesFormValues } from "../../types";
import getInitialValuesForPolicy from "../getInitialValuesForPolicy/getInitialValuesForPolicy";

const getInitialValuesForAllPolicies = (
  defaultValues?: PoliciesDefaultValues["policies"]
): PoliciesFormValues["policies"] => {
  if (defaultValues?.length === 1) {
    return [
      {
        type: PolicyType.Both,
        policy: getInitialValuesForPolicy(defaultValues[0].policy),
        additionalInformation: defaultValues[0].additionalInformation || "",
      },
    ];
  }

  if (defaultValues?.length === 2) {
    const indexDomestic = defaultValues.findIndex(
      (val) => val.type === PolicyType.Domestic
    );
    const indexInternational = defaultValues.findIndex(
      (val) => val.type === PolicyType.International
    );

    if (indexDomestic >= 0 && indexInternational >= 0) {
      return [
        {
          type: PolicyType.Domestic,
          policy: getInitialValuesForPolicy(defaultValues[indexDomestic].policy),
          additionalInformation: defaultValues[indexDomestic].additionalInformation || "",
        },
        {
          type: PolicyType.International,
          policy: getInitialValuesForPolicy(defaultValues[indexInternational].policy),
          additionalInformation:
            defaultValues[indexInternational].additionalInformation || "",
        },
      ];
    }
  }

  return [
    {
      type: PolicyType.Both,
      policy: getInitialValuesForPolicy(),
      additionalInformation: "",
    },
  ];
};

export default getInitialValuesForAllPolicies;
