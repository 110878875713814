@import "variables";

.container {
  display: flex;
  column-gap: $spacing-1;
  align-items: center;
  cursor: pointer;
  border-color: $warning-color;
  box-shadow:
    inset 0 0 0 100vh $bg-primary-transparent-color,
    inset 0 0 0 100vh $warning-color;
}

.text {
  flex-grow: 2;
}

.icon {
  fill: $warning-color !important;
}
