// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// This file was automatically generated using npm run codegen
// If you want to alter this file please do so via the above mentioned command
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
import * as Types from "../../../shared/types/schema";

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
const defaultOptions = {} as const;
export type NewCityMutationVariables = Types.Exact<{
  name: Types.Scalars["String"]["input"];
  countryCode: Types.IsoCountry;
  importanceClass: Types.ImportanceClass;
}>;

export type NewCityMutation = {
  __typename?: "Mutation";
  newCity: { __typename?: "City"; id: string };
};

export const NewCityDocument = gql`
  mutation NewCity(
    $name: String!
    $countryCode: ISOCountry!
    $importanceClass: ImportanceClass!
  ) {
    newCity(name: $name, countryCode: $countryCode, importanceClass: $importanceClass) {
      id
    }
  }
`;

/**
 * __useNewCityMutation__
 *
 * To run a mutation, you first call `useNewCityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewCityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newCityMutation, { data, loading, error }] = useNewCityMutation({
 *   variables: {
 *      name: // value for 'name'
 *      countryCode: // value for 'countryCode'
 *      importanceClass: // value for 'importanceClass'
 *   },
 * });
 */
export function useNewCityMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    NewCityMutation,
    NewCityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<NewCityMutation, NewCityMutationVariables>(
    NewCityDocument,
    options
  );
}
export type NewCityMutationHookResult = ReturnType<typeof useNewCityMutation>;
