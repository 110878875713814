import {
  Icon,
  IconArrowLeft,
  IconArrowLeftDouble,
  IconArrowRight,
  IconArrowRightDouble,
} from "@flixbus/honeycomb-icons-react";
import { Button, ButtonGroup } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage } from "react-intl";

export type ScrollNavigationProps = {
  onScroll: (steps: number) => void;
  scrollToInitialOffset: () => void;
  initialOffsetIndex: number;
  currentStartIndex: number;
  currentEndIndex: number;
  lastItemIndex: number;
  fastScrollSpeed: number;
};

const ScrollNavigation: React.FC<ScrollNavigationProps> = ({
  onScroll,
  scrollToInitialOffset,
  initialOffsetIndex,
  currentStartIndex,
  currentEndIndex,
  lastItemIndex,
  fastScrollSpeed,
}) => {
  const timeout = React.useRef<NodeJS.Timeout>();
  // Grows as the user holds the button
  let smoothScrollSpeed = 1;

  const handleScroll = (speed: number) => {
    onScroll(speed);
  };

  /**
   * Increments the scroll speed exponentially
   * maxes out at 20
   * @returns {number} The incremented scroll speed
   * */
  const getIncrementalScrollSpeed = () => {
    smoothScrollSpeed *= 1.2;

    return Math.min(Math.round(smoothScrollSpeed), 20);
  };

  const startSmoothSlide = (direction: "left" | "right") => {
    if (direction === "right") {
      handleScroll(getIncrementalScrollSpeed());
    } else {
      handleScroll(getIncrementalScrollSpeed() * -1);
    }

    timeout.current = setTimeout(() => {
      startSmoothSlide(direction);
    }, 100);
  };

  const stopSmoothScroll = () => {
    clearTimeout(timeout.current);
    smoothScrollSpeed = 1;
  };

  return (
    <ButtonGroup alignment="end">
      <Button
        display="square"
        aria-label="Slide Left Fast"
        disabled={currentStartIndex === 0}
        onClick={() => {
          handleScroll(-1 * fastScrollSpeed);
        }}
      >
        <Icon InlineIcon={IconArrowLeftDouble} aria-hidden="true" />
      </Button>
      <Button
        display="square"
        aria-label="Slide Left"
        disabled={currentStartIndex === 0}
        onClick={() => {
          handleScroll(-1);
        }}
        onMouseDown={() => {
          startSmoothSlide("left");
        }}
        onMouseUp={() => {
          stopSmoothScroll();
        }}
        onMouseLeave={() => {
          stopSmoothScroll();
        }}
      >
        <Icon InlineIcon={IconArrowLeft} aria-hidden="true" />
      </Button>
      <Button
        appearance="link"
        onClick={scrollToInitialOffset}
        disabled={currentStartIndex === initialOffsetIndex}
      >
        <FormattedMessage id="general.today" />
      </Button>
      <Button
        display="square"
        aria-label="Slide Right"
        disabled={currentEndIndex === lastItemIndex}
        onClick={() => {
          handleScroll(1);
        }}
        onMouseDown={() => {
          startSmoothSlide("right");
        }}
        onMouseUp={() => {
          stopSmoothScroll();
        }}
        onMouseLeave={() => {
          stopSmoothScroll();
        }}
      >
        <Icon InlineIcon={IconArrowRight} aria-hidden="true" />
      </Button>
      <Button
        display="square"
        aria-label="Slide Right Fast"
        disabled={currentEndIndex === lastItemIndex}
        onClick={() => {
          handleScroll(fastScrollSpeed);
        }}
      >
        <Icon InlineIcon={IconArrowRightDouble} aria-hidden="true" />
      </Button>
    </ButtonGroup>
  );
};

export default ScrollNavigation;
