/*
 * Function that removes __typename properties from any given object
 */

function stripTypename<T>(input: T): Omit<T, "__typename"> {
  if ((typeof input !== "object" && !Array.isArray(input)) || input === null) {
    return input;
  }

  const newish: any = { ...input };

  Object.keys(newish).forEach((prop) => {
    if (prop === "__typename") delete newish[prop];
    else if (Array.isArray(newish[prop])) {
      newish[prop] = newish[prop].map((next: any) => {
        return stripTypename(next);
      });
    } else if (typeof newish[prop] === "object") {
      newish[prop] = stripTypename(newish[prop]);
    }
  });

  return newish;
}

export default stripTypename;
