import { IconArrowRight, IconArrowLeft } from "@flixbus/honeycomb-icons-react";
import { Button } from "@flixbus/honeycomb-react";
import {
  DefaultInfoAlert,
  legacyTranslate,
  mpcPathnames as pathnames,
} from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import buildPath from "../../routes/buildPath/buildPath";
import FormWizzard from "../../shared/components/formWizzard/FormWizzard";
import FormikButtons from "../../shared/components/formikButtons/FormikButtons";
import getEditablePartnerFields from "../../shared/helpers/getEditableFormFields/getEditablePartnerFields/getEditablePartnerFields";
import { PartnerEditableFields } from "../../shared/helpers/getEditableFormFields/types";
import { MutationNewPartnerArgs } from "../../shared/types/schema";
import PartnerAccountForm from "../partnerAccountForm/PartnerAccountForm";
import PartnerBrandsAndPoliciesForm from "../partnerBrandsAndPoliciesForm/PartnerBrandsAndPoliciesForm";
import PartnerCapacityAndReportsForm from "../partnerCapacityAndReportsForm/PartnerCapacityAndReportsForm";
import PartnerCustomerServiceAndPoliciesForm from "../partnerCustomerServiceAndPoliciesForm/PartnerCustomerServiceAndPoliciesForm";
import PartnerSettingsForm from "../partnerSettingsForm/PartnerSettingsForm";
import { useNewPartnerMutation } from "./api/operations.generated";

const CreatePartner: React.FC = () => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const [addPartner, { error, loading }] = useNewPartnerMutation({
    onError: () => {}, // https://github.com/apollographql/apollo-client/issues/7167
    onCompleted: (data) => {
      navigate(buildPath.createLine(data.newPartner.id));
    },
  });

  const handleCreatePartner = (values: MutationNewPartnerArgs) => {
    addPartner({
      variables: {
        accountInput: values.accountInput,
        capacityAndReportsInput: values.capacityAndReportsInput,
        customerServiceAndPoliciesInput: values.customerServiceAndPoliciesInput,
        brandsInput: values.brandsInput,
        partnerSettingsInput: values.partnerSettingsInput,
      },
    });
  };

  const editableFields = getEditablePartnerFields(false, true);

  return (
    <>
      {error && <DefaultInfoAlert message={error.message} translate={legacyTranslate} />}

      <FormWizzard<MutationNewPartnerArgs, PartnerEditableFields>
        steps={[
          {
            title: formatMessage({ id: "partnerForm.tab.account" }),
            Form: PartnerAccountForm,
            key: "accountInput",
            children: (
              <FormikButtons
                submitButtonText={formatMessage({ id: "general.next" })}
                submitButtonAppearance="tertiary"
                submitButtonIcon={IconArrowRight}
              >
                <Button onClick={() => navigate(pathnames.userHome)}>
                  <FormattedMessage id="general.cancel" />
                </Button>
              </FormikButtons>
            ),
            editableFields: editableFields.accountInput,
            isCreate: true,
          },
          {
            title: formatMessage({ id: "partnerForm.tab.capacityAndReports.v2" }),
            Form: PartnerCapacityAndReportsForm,
            key: "capacityAndReportsInput",
            children: (
              <FormikButtons
                submitButtonText={formatMessage({ id: "general.next" })}
                submitButtonAppearance="tertiary"
                submitButtonIcon={IconArrowRight}
                resetButtonText={formatMessage({ id: "general.back" })}
                resetButtonAppearance={undefined}
                resetButtonIcon={IconArrowLeft}
              >
                <Button onClick={() => navigate(pathnames.userHome)}>
                  <FormattedMessage id="general.cancel" />
                </Button>
              </FormikButtons>
            ),
            editableFields: editableFields.capacityAndReportsInput,
          },
          {
            title: formatMessage({ id: "partnerForm.tab.customerServiceAndPolicies" }),
            Form: PartnerCustomerServiceAndPoliciesForm,
            key: "customerServiceAndPoliciesInput",
            children: (
              <FormikButtons
                submitButtonText={formatMessage({ id: "general.next" })}
                submitButtonAppearance="tertiary"
                submitButtonIcon={IconArrowRight}
                resetButtonText={formatMessage({ id: "general.back" })}
                resetButtonAppearance={undefined}
                resetButtonIcon={IconArrowLeft}
              >
                <Button onClick={() => navigate(pathnames.userHome)}>
                  <FormattedMessage id="general.cancel" />
                </Button>
              </FormikButtons>
            ),
            editableFields: editableFields.customerServiceAndPoliciesInput,
          },
          {
            title: formatMessage({ id: "partnerForm.tab.brandsAndPolicies" }),
            Form: PartnerBrandsAndPoliciesForm,
            key: "brandsInput",
            children: (
              <FormikButtons
                submitButtonText={formatMessage({ id: "general.next" })}
                submitButtonAppearance="tertiary"
                submitButtonIcon={IconArrowRight}
                resetButtonText={formatMessage({ id: "general.back" })}
                resetButtonAppearance={undefined}
                resetButtonIcon={IconArrowLeft}
              >
                <Button onClick={() => navigate(pathnames.userHome)}>
                  <FormattedMessage id="general.cancel" />
                </Button>
              </FormikButtons>
            ),
            editableFields: editableFields.brandsInput,
          },
          {
            title: formatMessage({ id: "general.settings" }),
            Form: PartnerSettingsForm,
            key: "partnerSettingsInput",
            children: (
              <FormikButtons
                submitButtonText={formatMessage({ id: "partner.create" })}
                submitButtonAppearance="primary"
                submitButtonLoading={loading}
                resetButtonText={formatMessage({ id: "general.back" })}
                resetButtonAppearance={undefined}
                resetButtonIcon={IconArrowLeft}
              >
                <Button onClick={() => navigate(pathnames.userHome)}>
                  <FormattedMessage id="general.cancel" />
                </Button>
              </FormikButtons>
            ),
            editableFields: editableFields.partnerSettingsInput,
          },
        ]}
        onSubmit={(values) => handleCreatePartner(values)}
      />
    </>
  );
};

export default CreatePartner;
