@import "variables";

.buttonWrapper {
  margin-top: $spacing-8;
  margin-bottom: $spacing-4;
}

.rightButtonsWrapper {
  text-align: right;
}
