// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// This file was automatically generated using npm run codegen
// If you want to alter this file please do so via the above mentioned command
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
import * as Types from "../../../shared/types/schema";

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
const defaultOptions = {} as const;
export type CalendarScheduleFragment = {
  __typename?: "Schedule";
  id: string;
  name: string;
  displayedScheduleStatus: Types.DisplayedScheduleStatus;
  onSalePeriods: Array<{ __typename?: "Period"; start: any; end: any }>;
  period?: { __typename?: "SchedulePeriod"; start: any; end: any } | null;
};

export type OriginalSchedulesOfLineQueryVariables = Types.Exact<{
  lineId: Types.Scalars["ID"]["input"];
  excludeInactive?: Types.InputMaybe<Types.Scalars["Boolean"]["input"]>;
}>;

export type OriginalSchedulesOfLineQuery = {
  __typename?: "Query";
  findOriginalSchedules: Array<{
    __typename?: "Schedule";
    id: string;
    name: string;
    displayedScheduleStatus: Types.DisplayedScheduleStatus;
    conceptChanges: Array<{
      __typename?: "Schedule";
      id: string;
      name: string;
      displayedScheduleStatus: Types.DisplayedScheduleStatus;
      onSalePeriods: Array<{ __typename?: "Period"; start: any; end: any }>;
      period?: { __typename?: "SchedulePeriod"; start: any; end: any } | null;
    }>;
    onSalePeriods: Array<{ __typename?: "Period"; start: any; end: any }>;
    period?: { __typename?: "SchedulePeriod"; start: any; end: any } | null;
  }>;
};

export const CalendarScheduleFragmentDoc = gql`
  fragment CalendarSchedule on Schedule {
    id
    name
    onSalePeriods {
      start
      end
    }
    period {
      start
      end
    }
    displayedScheduleStatus
  }
`;
export const OriginalSchedulesOfLineDocument = gql`
  query OriginalSchedulesOfLine($lineId: ID!, $excludeInactive: Boolean) {
    findOriginalSchedules(lineId: $lineId, excludeInactive: $excludeInactive) {
      id
      ...CalendarSchedule
      conceptChanges {
        ...CalendarSchedule
      }
    }
  }
  ${CalendarScheduleFragmentDoc}
`;

/**
 * __useOriginalSchedulesOfLineQuery__
 *
 * To run a query within a React component, call `useOriginalSchedulesOfLineQuery` and pass it any options that fit your needs.
 * When your component renders, `useOriginalSchedulesOfLineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOriginalSchedulesOfLineQuery({
 *   variables: {
 *      lineId: // value for 'lineId'
 *      excludeInactive: // value for 'excludeInactive'
 *   },
 * });
 */
export function useOriginalSchedulesOfLineQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OriginalSchedulesOfLineQuery,
    OriginalSchedulesOfLineQueryVariables
  > &
    (
      | { variables: OriginalSchedulesOfLineQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OriginalSchedulesOfLineQuery,
    OriginalSchedulesOfLineQueryVariables
  >(OriginalSchedulesOfLineDocument, options);
}
export function useOriginalSchedulesOfLineLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OriginalSchedulesOfLineQuery,
    OriginalSchedulesOfLineQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OriginalSchedulesOfLineQuery,
    OriginalSchedulesOfLineQueryVariables
  >(OriginalSchedulesOfLineDocument, options);
}
export function useOriginalSchedulesOfLineSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OriginalSchedulesOfLineQuery,
    OriginalSchedulesOfLineQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OriginalSchedulesOfLineQuery,
    OriginalSchedulesOfLineQueryVariables
  >(OriginalSchedulesOfLineDocument, options);
}
export type OriginalSchedulesOfLineQueryHookResult = ReturnType<
  typeof useOriginalSchedulesOfLineQuery
>;
export type OriginalSchedulesOfLineLazyQueryHookResult = ReturnType<
  typeof useOriginalSchedulesOfLineLazyQuery
>;
export type OriginalSchedulesOfLineSuspenseQueryHookResult = ReturnType<
  typeof useOriginalSchedulesOfLineSuspenseQuery
>;
