import {
  addNotification,
  formatErrorMessage,
  NotificationType,
} from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { selectedStationVar } from "../../state/reactiveVariables/reactiveVariables";
import useSelectedStation from "../../state/useSelectedStation/useSelectedStation";
import { useExchangeStationMutation } from "./api/operations.generated";
import ExchangeStationButton from "./ui/exchangeStationButton/ExchangeStationButton";
import ExchangeStationPopup from "./ui/exchangeStationPopup/ExchangeStationPopup";

type Props = {
  scheduleId: string;
  originalStationId: string;
  originalStationName: string;
  exchangeStationId: string;
  exchangeStationName: string;
  scheduleStationIds: Array<string>;
  scheduleReadOnly: boolean;
};

const ExchangeStation: React.FC<Props> = ({
  scheduleId,
  originalStationId,
  originalStationName,
  exchangeStationId,
  exchangeStationName,
  scheduleStationIds,
  scheduleReadOnly,
}) => {
  const [popupActive, setPopupActive] = React.useState(false);

  const [exchangeStationInSchedule] = useExchangeStationMutation({
    onError: (error) =>
      addNotification({
        message: `Station exchange failed: ${formatErrorMessage(error.message)}`,
        type: NotificationType.Danger,
      }),
  });

  const [, changeSelectedStation] = useSelectedStation(selectedStationVar);

  const handleExchangeStation = () => {
    setPopupActive(false);
    exchangeStationInSchedule({
      variables: { scheduleId, originalStationId, exchangeStationId },
    });
    changeSelectedStation("");
  };

  return (
    <>
      <ExchangeStationButton
        originalStationId={originalStationId}
        exchangeStationId={exchangeStationId}
        scheduleStationIds={scheduleStationIds}
        scheduleReadOnly={scheduleReadOnly}
        onOpenPopup={() => setPopupActive(true)}
      />
      <ExchangeStationPopup
        originalStationName={originalStationName}
        exchangeStationName={exchangeStationName}
        active={popupActive}
        onClosePopup={() => setPopupActive(false)}
        onExchangeStation={handleExchangeStation}
      />
    </>
  );
};

export default ExchangeStation;
