import * as Yup from "yup";

export default () => {
  return Yup.object().shape({
    brands: Yup.array().of(
      Yup.object().shape({
        id: Yup.string(),
        name: Yup.string(),
        updated: Yup.string(),
        created: Yup.string(),
      })
    ),
  });
};
