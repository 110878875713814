import { IconRefresh } from "@flixbus/honeycomb-icons-react";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ConfirmationPopup from "../../../../shared/components/confirmationPopup/ConfirmationPopup";
import RelationDirectionSelect from "../../../../shared/components/relationDirectionSelect/RelationDirectionSelect";
import UnitChangePopup from "../../../../shared/components/unitChangePopup/UnitChangePopup";
import {
  Currency,
  PriceDirection,
  ScheduleDirection,
} from "../../../../shared/types/schema";
import { ScheduleWithPricesFragment } from "../../api/operations.generated";
import { RelationMatrixFromQuery } from "../../types";
import CurrencySelect from "../../ui/currencySelect/CurrencySelect";
import ImportPricingButton from "../../ui/importPricingButton/ImportPricingButton";
import * as css from "./PriceActionBar.scss";

export type PriceActionBarProps = {
  schedule: ScheduleWithPricesFragment;
  currency: Currency;
  priceDirection: PriceDirection;
  onCurrencyChange: (currency: Currency, resetPrices: boolean) => void;
  onPriceDirectionChange: (priceDirection: PriceDirection) => void;
  onRelationMatrixImport: (relationMatrix: RelationMatrixFromQuery) => void;
};

const PriceActionBar: React.FC<PriceActionBarProps> = ({
  schedule,
  currency,
  priceDirection,
  onCurrencyChange,
  onPriceDirectionChange,
  onRelationMatrixImport,
}) => {
  const { formatMessage } = useIntl();
  const [selectedCurrency, setSelectedCurrency] = React.useState<Currency>();
  const [selectedPriceDirection, setSelectedPriceDirection] =
    React.useState<PriceDirection>();

  return (
    <div className={css.wrapper}>
      <div className={css.item}>
        <CurrencySelect
          value={currency}
          onSelect={(newCurrency) => {
            if (newCurrency !== currency) {
              setSelectedCurrency(newCurrency);
            }
          }}
        />
        {selectedCurrency && (
          <UnitChangePopup
            currentUnit={currency}
            newUnit={selectedCurrency}
            onCancel={() => {
              setSelectedCurrency(undefined);
            }}
            onConfirm={(resetPrices) => {
              onCurrencyChange(selectedCurrency, resetPrices);
              setSelectedCurrency(undefined);
            }}
            infoText={formatMessage({ id: "unitChange.popup.currency.infoText" })}
          />
        )}
      </div>
      {schedule.scheduleDirection === ScheduleDirection.TwoDirectional && (
        <div className={css.item}>
          <>
            <RelationDirectionSelect
              label={formatMessage({ id: "general.prices" })}
              value={priceDirection}
              onSelect={(newPriceDirection) => {
                setSelectedPriceDirection(newPriceDirection);
                if (newPriceDirection !== PriceDirection.BiDirectional) {
                  onPriceDirectionChange(newPriceDirection);
                }
              }}
            />
            {selectedPriceDirection === PriceDirection.BiDirectional && (
              <ConfirmationPopup
                data-id="price-direction-change-popup"
                active
                onCancel={() => {
                  setSelectedPriceDirection(priceDirection);
                }}
                onConfirm={() => {
                  onPriceDirectionChange(selectedPriceDirection);
                  setSelectedPriceDirection(undefined);
                }}
                titleIcon={IconRefresh}
                titleText={formatMessage({ id: "prices.priceDirection.popup.title" })}
              >
                <div>
                  <FormattedMessage id="prices.priceDirection.popup.text" />
                </div>
              </ConfirmationPopup>
            )}
          </>
        </div>
      )}

      <div>
        <ImportPricingButton
          scheduleId={schedule.id}
          scheduleHasPrices={schedule.hasPrices}
          onPricingImport={(
            importedRelationMatrix,
            importedPriceDirection,
            importedCurrency
          ) => {
            onCurrencyChange(importedCurrency, true);
            onPriceDirectionChange(importedPriceDirection);
            onRelationMatrixImport(importedRelationMatrix);
          }}
        />
      </div>
    </div>
  );
};

export default PriceActionBar;
