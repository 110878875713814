// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// This file was automatically generated using npm run codegen
// If you want to alter this file please do so via the above mentioned command
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
import * as Types from "../../../shared/types/schema";

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
const defaultOptions = {} as const;
export type FindAllCountriesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FindAllCountriesQuery = {
  __typename?: "Query";
  findAllCountries: Array<{
    __typename?: "Country";
    id: string;
    code: Types.IsoCountry;
    approver: Array<string>;
  }>;
};

export type UpdateCountryMutationVariables = Types.Exact<{
  id: Types.Scalars["ID"]["input"];
  approver: Array<Types.Scalars["String"]["input"]> | Types.Scalars["String"]["input"];
}>;

export type UpdateCountryMutation = {
  __typename?: "Mutation";
  updateCountry: { __typename?: "Country"; id: string; approver: Array<string> };
};

export const FindAllCountriesDocument = gql`
  query FindAllCountries {
    findAllCountries {
      id
      code
      approver
    }
  }
`;

/**
 * __useFindAllCountriesQuery__
 *
 * To run a query within a React component, call `useFindAllCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindAllCountriesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FindAllCountriesQuery,
    FindAllCountriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<FindAllCountriesQuery, FindAllCountriesQueryVariables>(
    FindAllCountriesDocument,
    options
  );
}
export function useFindAllCountriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FindAllCountriesQuery,
    FindAllCountriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FindAllCountriesQuery,
    FindAllCountriesQueryVariables
  >(FindAllCountriesDocument, options);
}
export function useFindAllCountriesSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    FindAllCountriesQuery,
    FindAllCountriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    FindAllCountriesQuery,
    FindAllCountriesQueryVariables
  >(FindAllCountriesDocument, options);
}
export type FindAllCountriesQueryHookResult = ReturnType<typeof useFindAllCountriesQuery>;
export type FindAllCountriesLazyQueryHookResult = ReturnType<
  typeof useFindAllCountriesLazyQuery
>;
export type FindAllCountriesSuspenseQueryHookResult = ReturnType<
  typeof useFindAllCountriesSuspenseQuery
>;
export const UpdateCountryDocument = gql`
  mutation UpdateCountry($id: ID!, $approver: [String!]!) {
    updateCountry(id: $id, approver: $approver) {
      id
      approver
    }
  }
`;

/**
 * __useUpdateCountryMutation__
 *
 * To run a mutation, you first call `useUpdateCountryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCountryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCountryMutation, { data, loading, error }] = useUpdateCountryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      approver: // value for 'approver'
 *   },
 * });
 */
export function useUpdateCountryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCountryMutation,
    UpdateCountryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateCountryMutation,
    UpdateCountryMutationVariables
  >(UpdateCountryDocument, options);
}
export type UpdateCountryMutationHookResult = ReturnType<typeof useUpdateCountryMutation>;
