import { PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: "aadb7256-36f2-48c5-80f0-c50ea4ccc759",
    authority: "https://login.microsoftonline.com/d8d0ad3e-8bcf-48e9-9bb2-aee17c6c4fd5", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

export const msalInstance: PublicClientApplication = new PublicClientApplication(
  msalConfig
);

export const loginRequest = {
  scopes: ["openid", "profile"],
  account: msalInstance.getActiveAccount() || undefined,
};
