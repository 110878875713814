import { getDate, getMonth } from "date-fns";

export const getCalendarPositionForDailyView = (start: Date, end: Date) => {
  return { gridColumn: `${getDate(start)} / ${getDate(end) + 1}` };
};

export const getCalendarPositionForMonthlyView = (
  start: number | Date,
  end: number | Date
) => {
  if (typeof start === "number" && typeof end === "number") {
    return { gridColumn: `${start + 1} / ${end + 2}` };
  }
  return { gridColumn: `${getMonth(start) + 1} / ${getMonth(end) + 2}` };
};
