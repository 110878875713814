import { Icon, IconInfo } from "@flixbus/honeycomb-icons-react";
import { Fineprint, Grid, GridCol, Heading } from "@flixbus/honeycomb-react";
import { Formik, Form } from "formik2";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import CirculationSelect from "../../shared/components/circulationSelect/CirculationSelect";
import CustomFormRow from "../../shared/components/customFormRow/CustomFormRow";
import FormikInput from "../../shared/components/formikInput/FormikInput";
import { LineEditableFields } from "../../shared/helpers/getEditableFormFields/types";
import { CapacityAndAmenitiesInput, Partner } from "../../shared/types/schema";
import * as css from "./LineCapacityAndAmenitiesForm.scss";
import validationSchema from "./helpers/validationSchema/validationSchema";
import AmenitiesSelector from "./ui/amenitiesSelector/AmenitiesSelector";

export type LineCapacityAndAmenitiesFormProps = {
  onSubmit: (values: CapacityAndAmenitiesInput) => void;
  onCancel: () => void;
  defaultValues?: Partial<CapacityAndAmenitiesInput>;
  editableFields?: LineEditableFields["capacityAndAmenities"];
  // Fields that are not required in each use-case
  additionallyRequiredFields?: {
    minSeatCapacity?: boolean;
  };
  partnerId: Partner["id"];
};

const LineCapacityAndAmenitiesForm: React.FC<LineCapacityAndAmenitiesFormProps> = ({
  onSubmit,
  onCancel,
  defaultValues,
  editableFields,
  additionallyRequiredFields,
  partnerId,
  children,
}) => {
  const { formatMessage } = useIntl();

  const initialValues: CapacityAndAmenitiesInput = {
    amenities: [],
    ...defaultValues,
    minSeatCapacity:
      defaultValues && defaultValues.minSeatCapacity !== null
        ? defaultValues.minSeatCapacity
        : ("" as unknown as number),
    defaultCirculationId: defaultValues?.defaultCirculationId
      ? defaultValues.defaultCirculationId
      : "",
  };

  return (
    <Formik
      validationSchema={validationSchema(additionallyRequiredFields?.minSeatCapacity)}
      initialValues={initialValues}
      onSubmit={(values) => {
        onSubmit({
          ...values,
          amenities: values.amenities.map((amenity) => {
            return { amenityType: amenity.amenityType, highlighted: amenity.highlighted };
          }),
          minSeatCapacity:
            // @ts-ignore even though TS claims that this can never be a string, it actually can because of the initial value of it
            values.minSeatCapacity !== "" ? values.minSeatCapacity : undefined,
        });
      }}
      onReset={onCancel}
    >
      <Form noValidate>
        <Grid>
          <GridCol size={5}>
            <Heading size={3} sectionHeader>
              <FormattedMessage id="general.amenities" />
            </Heading>
            <AmenitiesSelector readOnly={!editableFields?.amenities} />
          </GridCol>
          <GridCol size={6} push={1}>
            {(editableFields?.defaultCirculationId ||
              editableFields?.minSeatCapacity) && (
              <Heading data-id="capacity-headline" size={3} sectionHeader>
                <FormattedMessage id="lineForm.capacity.headline" />
              </Heading>
            )}
            {editableFields?.defaultCirculationId && (
              <CustomFormRow data-id="circulationCapacity-wrapper">
                <CirculationSelect
                  partnerId={partnerId}
                  selectedCirculationId={initialValues.defaultCirculationId || undefined}
                  extraClasses={css.circulation}
                />
              </CustomFormRow>
            )}
            {editableFields?.minSeatCapacity && (
              <CustomFormRow data-id="min-seat-capacity">
                <div className={css.minSeatCapacity}>
                  <FormikInput
                    name="minSeatCapacity"
                    type="number"
                    label={formatMessage({ id: "lineForm.minSeatCapacity.input.label" })}
                    placeholder="5"
                    required={additionallyRequiredFields?.minSeatCapacity}
                  />
                </div>
                <div className={css.fineprint}>
                  <Fineprint>
                    <Icon InlineIcon={IconInfo} />
                    <FormattedMessage id="lineForm.minSeatCapacity.info" />
                  </Fineprint>
                </div>
              </CustomFormRow>
            )}
          </GridCol>
        </Grid>
        {children}
      </Form>
    </Formik>
  );
};

export default LineCapacityAndAmenitiesForm;
