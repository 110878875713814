import { SelectOption } from "../../../../shared/helpers/reactSelect/types";
import { Brand } from "../../types";

const filterAllBrandsBySelectedBrands = <T extends Pick<Brand, "id" | "name">>(
  allBrands: Array<T>,
  selectedBrands: Array<T>
): Array<SelectOption> => {
  return allBrands
    .map((brand) => ({ label: brand.name, value: brand.id }))
    .filter((option) => selectedBrands.every((selected) => selected.id !== option.value));
};

export default filterAllBrandsBySelectedBrands;
