@import "variables";

.wrapper {
  display: inline-grid;
  grid-template-columns: 1fr auto;
  width: 100%;
}

.unitInputLabel {
  color: $content-secondary-color;
  padding-bottom: 2px;
}

.input {
  z-index: 1;

  > div {
    border-radius: $border-radius 0 0 $border-radius;
  }
}

.errorText {
  color: $danger-dark-color;
}

.finePrint {
  font-size: $font-size-small;
}

.selectGroup {
  > ul {
    :first-child {
      border-radius: 0;

      > label {
        border-radius: 0;
      }
    }
  }
}

.selectGroupOutlineActive {
  > ul {
    :first-child {
      border-color: $input-border-color;
    }

    :nth-child(2) {
      border-color: $input-border-color;
    }
  }
}

.selectGroupOutlineError {
  > ul {
    :first-child {
      border-color: $input-error-border-color $input-border-color;
    }

    :nth-child(2) {
      border-color: $input-error-border-color $input-error-border-color
        $input-error-border-color $input-border-color;
    }
  }
}
