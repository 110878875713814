import { Grid, GridCol, Input } from "@flixbus/honeycomb-react";
import { useField } from "formik2";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Checkbox from "../../../../shared/components/checkbox/Checkbox";
import ReadOnlyFormField from "../../../../shared/components/readOnlyFormField/ReadOnlyFormField";
import isValid from "../../../../shared/helpers/validateFormikInput/validateFormikInput";
import * as css from "./CapacityDecreaseSelector.scss";

type Props = {
  readOnly?: boolean;
};

const CapacityDecreaseSelector: React.FC<Props> = ({ readOnly }) => {
  const { formatMessage } = useIntl();
  const [, meta, helper] = useField<number | undefined>(
    "capacityDecreaseTimeFrameInHours"
  );
  const [capacityDecreaseChecked, setCapacityDecreaseChecked] = React.useState<boolean>(
    !!meta.value
  );

  const handleCheckboxClick = () => {
    if (capacityDecreaseChecked) {
      helper.setValue(undefined);
    }

    setCapacityDecreaseChecked((prevState) => !prevState);
  };

  return (
    <div className={css.selectorWrapper}>
      <Grid>
        <GridCol size={6} lg={6}>
          {readOnly ? (
            <ReadOnlyFormField
              label={formatMessage({
                id: "partnerForm.capacityDecreaseRule.readOnlyLabel",
              })}
            >
              {capacityDecreaseChecked
                ? formatMessage(
                    { id: "partnerForm.capacityDecreaseRule.readOnlyValue.ruleIsSet" },
                    {
                      capacityDecreaseTimeFrameInHours: meta.value,
                    }
                  )
                : formatMessage({
                    id: "partnerForm.capacityDecreaseRule.readOnlyValue.ruleIsNotSet",
                  })}
            </ReadOnlyFormField>
          ) : (
            <Checkbox
              isChecked={capacityDecreaseChecked}
              onClicked={handleCheckboxClick}
              label={formatMessage({
                id: "partnerForm.capacityDecreaseRule.checkbox.label",
              })}
            />
          )}
        </GridCol>
      </Grid>
      {capacityDecreaseChecked && !readOnly && (
        <div className={css.infoTextWrapper}>
          <div className={css.infoText}>
            <FormattedMessage id="partnerForm.capacityDecreaseRule.info" />
          </div>
          <div className={css.inputWrapper}>
            <Input
              data-id="capacity-decrease-input"
              aria-label="Hours"
              id="capacity-decrease-select"
              type="number"
              value={Number.isNaN(meta.value) ? "" : meta.value}
              onChange={(event) =>
                helper.setValue(parseInt(event.currentTarget.value, 10))
              }
              onBlur={() => helper.setTouched(true)}
              valid={isValid(meta)}
              infoError={meta.error}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CapacityDecreaseSelector;
