import { useReactiveVar } from "@apollo/client";
import { Button } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import lineFilterVar, {
  defaultLineFilterValues,
  LineFilterVar,
} from "../../../state/lineFilter/lineFilter";
import partnerFilterVar, {
  defaultPartnerFilterValues,
  PartnerFilterVar,
} from "../../../state/partnerFilter/partnerFilter";
import { SearchType } from "../../types";
import * as css from "./ClearFilterButton.scss";

const isPartnerFilterEnabled = (filters: PartnerFilterVar): boolean => {
  return (Object.keys(filters) as Array<keyof PartnerFilterVar>).some((key) => {
    if (key === "autoOnSaleConfig") {
      return (
        filters.autoOnSaleConfig.normalSchedule !== null ||
        filters.autoOnSaleConfig.conceptChangeSchedule !== null
      );
    }
    return filters[key] !== null && filters[key] !== "";
  });
};

const isLineFilterEnabled = (filters: LineFilterVar): boolean => {
  return (Object.keys(filters) as Array<keyof LineFilterVar>).some(
    (key) => filters[key] !== null && filters[key] !== ""
  );
};

type Props = {
  type: SearchType;
};

const ClearFilterButton: React.FC<Props> = ({ type }) => {
  const partnerFilters = useReactiveVar(partnerFilterVar);
  const lineFilters = useReactiveVar(lineFilterVar);

  const isEnabled =
    type === SearchType.PARTNERS
      ? isPartnerFilterEnabled(partnerFilters)
      : isLineFilterEnabled(lineFilters);

  return (
    <Button
      appearance="link"
      onClick={() => {
        if (type === SearchType.PARTNERS) {
          partnerFilterVar(defaultPartnerFilterValues);
        }
        if (type === SearchType.LINES) {
          lineFilterVar(defaultLineFilterValues);
        }
      }}
      extraClasses={css.button}
      disabled={!isEnabled}
    >
      <FormattedMessage id="filter.resetButton.text" />
    </Button>
  );
};

export default ClearFilterButton;
