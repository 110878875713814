import { Icon, IconArrowBigRight, IconForbidden } from "@flixbus/honeycomb-icons-react";
import cx from "classnames";
import * as React from "react";
import * as css from "./InterconnectionOperatorIcon.scss";

type Props = {
  isWhitelist: boolean;
};

const InterconnectionOperatorIcon: React.FC<Props> = ({ isWhitelist }) => (
  <>
    {isWhitelist ? (
      <Icon extraClasses={cx(css.icon, css.iconArrow)} InlineIcon={IconArrowBigRight} />
    ) : (
      <Icon extraClasses={cx(css.icon, css.iconForbidden)} InlineIcon={IconForbidden} />
    )}
  </>
);

export default InterconnectionOperatorIcon;
