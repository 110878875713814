import { PurposeOfContactField } from "../../../../shared/types/schema";
import { PartnerCustomerServiceAndPoliciesFormValues } from "../../types";

const emptyCustomerServiceContactFormRow: PartnerCustomerServiceAndPoliciesFormValues["customerServiceInformation"]["customerServiceContact"][number] =
  {
    purposeOfContact: "" as PurposeOfContactField,
    hotlineNumber: "",
    email: "",
    languagesSpoken: [],
    timeZoneId: "",
    workingHours: [
      {
        workingDays: Array.from({ length: 7 }),
        from: "",
        to: "",
      },
    ],
    holidayWorkingHours: {
      enabled: false,
      workingHours: [
        {
          from: "",
          to: "",
        },
      ],
    },
  };

export default emptyCustomerServiceContactFormRow;
