import { useReactiveVar } from "@apollo/client";
import {
  IconBusTrainSolid,
  IconCalendarSolid,
  IconLockSolid,
  IconPersonBusinessSolid,
  IconSettingsSolid,
  IconTicketShinySolid,
} from "@flixbus/honeycomb-icons-react";
import * as React from "react";
import { useIntl } from "react-intl";
import translatePartnerTags from "../../../shared/helpers/translatePartnerTags/translatePartnerTags";
import lineFilterVar, {
  defaultLineFilterValues,
} from "../../../state/lineFilter/lineFilter";
import partnerFilterVar, {
  defaultPartnerFilterValues,
} from "../../../state/partnerFilter/partnerFilter";
import BusinessRegionFilterTag from "../../filterTags/businessRegionFilterTag/BusinessRegionFilterTag";
import FilterTag from "../../filterTags/filterTag/FilterTag";
import { SearchType } from "../../types";
import * as css from "./FilterTagsWrapper.scss";

type Props = {
  type: SearchType;
};

const FilterTagsWrapper: React.FC<Props> = ({ type }) => {
  const { formatMessage } = useIntl();
  const partnerFilters = useReactiveVar(partnerFilterVar);
  const lineFilters = useReactiveVar(lineFilterVar);

  const tags = [];

  if (type === SearchType.PARTNERS) {
    if (partnerFilters.businessRegions) {
      tags.push(
        <BusinessRegionFilterTag
          key="businessRegions"
          bizRegions={partnerFilters.businessRegions}
          onClose={() =>
            partnerFilterVar({
              ...partnerFilters,
              businessRegions: defaultPartnerFilterValues.businessRegions,
            })
          }
        />
      );
    }

    if (partnerFilters.apiPartner) {
      const labels = [];
      if (partnerFilters.apiPartner.includes(true)) {
        labels.push(formatMessage({ id: "general.integrationType.api" }));
      }
      if (partnerFilters.apiPartner.includes(false)) {
        labels.push(formatMessage({ id: "general.integrationType.manual" }));
      }

      tags.push(
        <FilterTag
          data-id="apiPartner"
          key="apiPartner"
          LabelIcon={IconSettingsSolid}
          label={labels.join(", ")}
          onClose={() =>
            partnerFilterVar({
              ...partnerFilters,
              apiPartner: defaultPartnerFilterValues.apiPartner,
            })
          }
        />
      );
    }

    if (partnerFilters.meansOfTransport) {
      const labels = partnerFilters.meansOfTransport.map((mOt) => {
        if (mOt === null) {
          return formatMessage({ id: "general.unset" });
        }
        return formatMessage({ id: `meansOfTransport.${mOt}` });
      });

      tags.push(
        <FilterTag
          data-id="meansOfTransport"
          key="meansOfTransport"
          LabelIcon={IconBusTrainSolid}
          label={labels.join(", ")}
          onClose={() =>
            partnerFilterVar({
              ...partnerFilters,
              meansOfTransport: defaultPartnerFilterValues.meansOfTransport,
            })
          }
        />
      );
    }

    if (partnerFilters.partnerTags?.length) {
      tags.push(
        <FilterTag
          data-id="partnerTags"
          key="partnerTags"
          LabelIcon={IconTicketShinySolid}
          label={partnerFilters.partnerTags
            .map((tag) => translatePartnerTags(tag))
            .join(", ")}
          onClose={() =>
            partnerFilterVar({
              ...partnerFilters,
              partnerTags: defaultPartnerFilterValues.partnerTags,
            })
          }
        />
      );
    }

    if (partnerFilters.accessPackage) {
      tags.push(
        <FilterTag
          data-id="accessPackage"
          key="accessPackage"
          LabelIcon={IconLockSolid}
          label={partnerFilters.accessPackage
            .map((item) => item.charAt(0) + item.substring(1).toLowerCase())
            .join(", ")}
          onClose={() =>
            partnerFilterVar({
              ...partnerFilters,
              accessPackage: defaultPartnerFilterValues.accessPackage,
            })
          }
        />
      );
    }

    if (partnerFilters.businessDeveloper) {
      tags.push(
        <FilterTag
          data-id="businessDeveloper"
          key="businessDeveloper"
          LabelIcon={IconPersonBusinessSolid}
          label={partnerFilters.businessDeveloper}
          onClose={() =>
            partnerFilterVar({
              ...partnerFilters,
              businessDeveloper: defaultPartnerFilterValues.businessDeveloper,
            })
          }
        />
      );
    }

    if (
      partnerFilters.autoOnSaleConfig.normalSchedule ||
      partnerFilters.autoOnSaleConfig.conceptChangeSchedule
    ) {
      const normalScheduleLabels = [];
      if (partnerFilters.autoOnSaleConfig.normalSchedule?.includes(true)) {
        normalScheduleLabels.push(formatMessage({ id: "general.activated" }));
      }
      if (partnerFilters.autoOnSaleConfig.normalSchedule?.includes(false)) {
        normalScheduleLabels.push(formatMessage({ id: "general.deactivated" }));
      }

      const conceptChangeScheduleLabels = [];
      if (partnerFilters.autoOnSaleConfig.conceptChangeSchedule?.includes(true)) {
        conceptChangeScheduleLabels.push(formatMessage({ id: "general.activated" }));
      }
      if (partnerFilters.autoOnSaleConfig.conceptChangeSchedule?.includes(false)) {
        conceptChangeScheduleLabels.push(formatMessage({ id: "general.deactivated" }));
      }

      const labels = [];
      if (normalScheduleLabels.length > 0) {
        labels.push(
          `${formatMessage({
            id: "partnerForm.autoOnSaleConfig.normalSchedule",
          })}: ${normalScheduleLabels.join(", ")}`
        );
      }
      if (conceptChangeScheduleLabels.length > 0) {
        labels.push(
          `${formatMessage({
            id: "partnerForm.autoOnSaleConfig.conceptChangeSchedule",
          })}: ${conceptChangeScheduleLabels.join(", ")}`
        );
      }

      tags.push(
        <FilterTag
          data-id="autoOnSaleConfig"
          key="autoOnSaleConfig"
          LabelIcon={IconCalendarSolid}
          label={labels.join(", ")}
          onClose={() =>
            partnerFilterVar({
              ...partnerFilters,
              autoOnSaleConfig: defaultPartnerFilterValues.autoOnSaleConfig,
            })
          }
        />
      );
    }
  }

  if (type === SearchType.LINES) {
    if (lineFilters.businessRegions) {
      tags.push(
        <BusinessRegionFilterTag
          key="businessRegions"
          bizRegions={lineFilters.businessRegions}
          onClose={() =>
            lineFilterVar({
              ...lineFilters,
              businessRegions: defaultLineFilterValues.businessRegions,
            })
          }
        />
      );
    }

    if (lineFilters.meansOfTransport) {
      const labels = lineFilters.meansOfTransport.map((mOt) => {
        return formatMessage({ id: `meansOfTransport.${mOt}` });
      });

      tags.push(
        <FilterTag
          data-id="meansOfTransport"
          key="meansOfTransport"
          LabelIcon={IconBusTrainSolid}
          label={labels.join(", ")}
          onClose={() =>
            lineFilterVar({
              ...lineFilters,
              meansOfTransport: defaultLineFilterValues.meansOfTransport,
            })
          }
        />
      );
    }
  }

  return <div className={css.wrapper}>{tags}</div>;
};

export default FilterTagsWrapper;
