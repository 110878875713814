@import "variables";

.wrapper {
  margin-left: auto;
  margin-right: auto;
  width: 380px;
}

.header {
  text-align: center;
  margin-bottom: $spacing-4;
}

.button {
  text-align: center;
  margin-bottom: $spacing-6;
}
