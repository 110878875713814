import { formatMessage } from "@flixbus-phx/marketplace-common";
import * as Yup from "yup";

export default () => {
  return Yup.object().shape({
    approver: Yup.array().of(
      Yup.string().email(formatMessage("error.validation.mustBeEmail"))
    ),
  });
};
