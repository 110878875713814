import { Station, StationTime } from "../../../../shared/types/schema";
import {
  DeleteTripStationTimeMutation,
  FindScheduleWithTripsAndStationsQuery,
  TripFragment,
  UpdateTripStationArrivalTimeMutation,
} from "../../api/operations.generated";

export const optimisticResponseForDeleteStationTime = (
  stationId: FindScheduleWithTripsAndStationsQuery["findSchedule"]["id"],
  trip: TripFragment
): DeleteTripStationTimeMutation => {
  const getNextStationTimeWithArrivalIndex = (stationTimeIndex: number) => {
    let nextStationTimeWithArrivalIndex = stationTimeIndex + 1;

    while (!trip.stationTimes[nextStationTimeWithArrivalIndex].arrivalTime) {
      nextStationTimeWithArrivalIndex++;

      if (nextStationTimeWithArrivalIndex === trip.stationTimes.length) return -1;
    }

    return nextStationTimeWithArrivalIndex;
  };

  const stationTimeIndex = trip.stationTimes.findIndex(
    (stationTime) => stationTime.station.id === stationId
  );

  const newStationTimes = [...trip.stationTimes];

  if (trip.stationTimes.length > 1 && !trip.stationTimes[stationTimeIndex].arrivalTime) {
    newStationTimes[stationTimeIndex] = {
      ...trip.stationTimes[stationTimeIndex],
      departureTime: "",
    };

    const nextStationTimeWithArrivalIndex =
      getNextStationTimeWithArrivalIndex(stationTimeIndex);

    if (nextStationTimeWithArrivalIndex > -1) {
      newStationTimes[nextStationTimeWithArrivalIndex] = {
        ...trip.stationTimes[nextStationTimeWithArrivalIndex],
        arrivalTime: "",
        timeToStation: "",
      };
    }

    return {
      deleteTripStationTime: { ...trip, stationTimes: newStationTimes },
      __typename: "Mutation",
    };
  }

  newStationTimes[stationTimeIndex] = {
    ...trip.stationTimes[stationTimeIndex],
    arrivalTime: "",
    departureTime: "",
    timeToStation: "",
  };

  return {
    deleteTripStationTime: { ...trip, stationTimes: newStationTimes },
    __typename: "Mutation",
  };
};

export const optimisticResponseForUpdateTripStationArrivalTime = (
  trip: TripFragment,
  stationId: Station["id"],
  arrivalTime: StationTime["arrivalTime"]
): UpdateTripStationArrivalTimeMutation => {
  return {
    updateTripStationArrivalTime: {
      ...trip,
      stationTimes: trip.stationTimes.map((stationTime, i, arr) => {
        if (i === 0 || i === arr.length - 1) {
          return stationTime;
        }

        if (stationTime.station.id === stationId && !stationTime.departureTime) {
          return { ...stationTime, departureTime: arrivalTime };
        }

        return stationTime;
      }),
    },
    __typename: "Mutation",
  };
};
