export const FILE_SELECTOR = "STATIONS_UUID_FILE";

export const createFileDownload = (blob: Blob, fileName: string) => {
  const a = document.createElement("a");
  a.href = URL.createObjectURL(blob);
  a.download = fileName;
  a.id = FILE_SELECTOR;
  document.body.appendChild(a);
};

export const downloadFile = () => {
  const ele = document.getElementById(FILE_SELECTOR);
  ele?.click();
};

export const removeFile = () => {
  const ele = document.getElementById(FILE_SELECTOR);
  ele?.remove();
};
