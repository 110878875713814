@import "variables";
@import "../../helpers/styles/grid-vars.scss";

$icon-width: 10px;
$text-width: 170px;

.scheduleName {
  font-weight: $font-weight-bold;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.tooltip {
  [class*="hcr-tooltip__content"] {
    line-height: $spacing-6;
  }
}

.wrapper {
  display: grid;
  grid-template-columns: $text-width auto;
  column-gap: $spacing-2;
  margin-left: $spacing-2;
}

.wrapperConceptChange {
  grid-template-columns: $icon-width calc($text-width - $spacing-2 - $icon-width) auto;
}

.cell {
  @include schedule-list-grid-cell;
}

.link {
  line-height: $row-height;
}

.arrow {
  margin-top: $spacing-2;
}
