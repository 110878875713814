@import "variables";

.ticketSendoutWrapper,
.apiDisabledFeaturesWrapper {
  margin-left: calc(30px + #{$spacing-2});
}

.ticketSendoutWrapper {
  margin-bottom: $spacing-3;
}

.apiSubHeader {
  color: $content-primary-color;
  font-weight: $font-weight-bold;
  font-size: $font-size-small;
}

.heading {
  padding-top: 0;
  font-size: $font-size-small;
  color: $content-secondary-color;
}

.checkbox:not(:last-child) {
  margin-bottom: $spacing-2;
}
