import { Button } from "@flixbus/honeycomb-react";
import * as React from "react";
import { useIntl } from "react-intl";
import CreateEntityPopup from "../../../../shared/components/createEntityPopup/CreateEntityPopup";
import DropdownButton from "../../../../shared/components/dropdownButton/DropdownButton";
import ImportPopup from "../../container/importPopup/ImportPopup";

export type CreateEntityButtonProps = {
  isImportCreationPermitted: boolean;
  isManualCreationPermitted: boolean;
  onSubmit: () => void;
  type: "station" | "city";
};
const CreateEntityButton: React.FC<CreateEntityButtonProps> = ({
  isImportCreationPermitted,
  isManualCreationPermitted,
  onSubmit,
  type,
}) => {
  const { formatMessage } = useIntl();
  const [isManualCreationActive, setIsManualCreationActive] = React.useState<
    boolean | undefined
  >();

  const closePopup = () => {
    setIsManualCreationActive(undefined);
  };

  const links = [
    ...(isImportCreationPermitted
      ? [
          {
            text: formatMessage({ id: "station.create.upload" }),
            onMouseDown: () => {
              setIsManualCreationActive(false);
            },
          },
        ]
      : []),
    ...(isManualCreationPermitted
      ? [
          {
            text: formatMessage({ id: "station.create.manual" }),
            onMouseDown: () => {
              setIsManualCreationActive(true);
            },
          },
        ]
      : []),
  ];

  const buttonText =
    type === "station"
      ? formatMessage({ id: "station.create" })
      : formatMessage({ id: "navigation.createNewCity" });

  return (
    <>
      {links.length > 1 ? (
        <DropdownButton links={links}>{buttonText}</DropdownButton>
      ) : links.length === 1 ? (
        <Button appearance="primary" onClick={links.at(0)?.onMouseDown}>
          {buttonText}
        </Button>
      ) : (
        <></>
      )}

      {isManualCreationActive ? (
        <CreateEntityPopup
          onEntityCreated={() => {
            closePopup();
            onSubmit();
          }}
          onClose={closePopup}
          type={type}
        />
      ) : (
        <>
          {isManualCreationActive === false ? (
            <ImportPopup
              type={type}
              onClose={() => {
                closePopup();
                onSubmit();
              }}
            />
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};

export default CreateEntityButton;
