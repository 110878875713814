import {
  DataTable,
  DataTableHeadComp,
  DataTableRow,
  Skeleton,
} from "@flixbus/honeycomb-react";
import * as React from "react";
import * as css from "./DataTableLoading.scss";

type Props = {
  header: DataTableHeadComp;
  colCount: number;
  extraClasses?: string;
};

const DataTableLoading: React.FC<Props> = ({ header, colCount, extraClasses }) => {
  return (
    <DataTable extraClasses={extraClasses} headers={header}>
      {Array.from({ length: 25 }, (_, i) => (
        <DataTableRow key={i}>
          {Array.from({ length: colCount }, (__, ind) => (
            <td key={ind}>
              <Skeleton flushBottom extraClasses={css.skeleton} />
            </td>
          ))}
        </DataTableRow>
      ))}
    </DataTable>
  );
};

export default DataTableLoading;
