import * as React from "react";
import { formatPriceInput } from "../../../../shared/helpers/priceInputFormatter/priceInputFormatter";
import { Currency } from "../../../../shared/types/schema";
import { RelationMatrixEntryFromQuery } from "../../types";
import * as css from "./ReadOnlyPriceText.scss";

type props = {
  price?: RelationMatrixEntryFromQuery["price"];
  currency: Currency;
};

const ReadOnlyPriceText: React.FC<props> = ({ price, currency }) => {
  return (
    <div className={css.wrapper}>
      <span data-id="currency" className={css.currency}>
        {currency}
      </span>
      &nbsp;
      {typeof price === "number" ? (
        <span data-id="price">
          {formatPriceInput(price.toString().replace(".", ","))}
        </span>
      ) : (
        <span data-id="price">--,--</span>
      )}
    </div>
  );
};

export default ReadOnlyPriceText;
