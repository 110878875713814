// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// This file was automatically generated using npm run codegen
// If you want to alter this file please do so via the above mentioned command
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
import * as Types from "../../../shared/types/schema";

import { gql } from "@apollo/client";
import { PolicyDetailFragmentDoc } from "../../../shared/helpers/fragments/policies.generated";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
const defaultOptions = {} as const;
export type FindBrandQueryVariables = Types.Exact<{
  brandId: Types.Scalars["ID"]["input"];
}>;

export type FindBrandQuery = {
  __typename?: "Query";
  findBrand: {
    __typename?: "Brand";
    id: string;
    name: string;
    policyUnits: {
      __typename?: "PolicyUnits";
      weightUnit: Types.WeightUnit;
      lengthUnit: Types.LengthUnit;
      currency: Types.Currency;
    };
    policies: Array<{
      __typename?: "Policy";
      type: Types.PolicyType;
      additionalInformation?: string | null;
      luggagePolicy?: {
        __typename?: "LuggagePolicy";
        handLuggagePolicy?: {
          __typename?: "IncludedLuggagePolicy";
          inTicketIncluded: boolean;
          luggageSpecification?: {
            __typename?: "IncludedLuggageSpecification";
            maxPieces?: number | null;
            maxWeight?: { __typename?: "WeightEntry"; value: number } | null;
            maxDimension?: { __typename?: "DimensionEntry"; value: string } | null;
          } | null;
        } | null;
        checkInLuggagePolicy?: {
          __typename?: "IncludedLuggagePolicy";
          inTicketIncluded: boolean;
          luggageSpecification?: {
            __typename?: "IncludedLuggageSpecification";
            maxPieces?: number | null;
            maxWeight?: { __typename?: "WeightEntry"; value: number } | null;
            maxDimension?: { __typename?: "DimensionEntry"; value: string } | null;
          } | null;
        } | null;
        additionalLuggagePolicy?: {
          __typename?: "BookableLuggagePolicy";
          bookable: boolean;
          luggageSpecification?: {
            __typename?: "BookableLuggageSpecification";
            feeType?: Types.LuggageFeeType | null;
            pointOfSale?: Types.PointOfSaleType | null;
            maxWeight?: { __typename?: "WeightEntry"; value: number } | null;
            maxDimension?: { __typename?: "DimensionEntry"; value: string } | null;
            feePrice?: { __typename?: "MoneyEntry"; value: number } | null;
          } | null;
        } | null;
        specialLuggagePolicy?: {
          __typename?: "BookableLuggagePolicy";
          bookable: boolean;
          luggageSpecification?: {
            __typename?: "BookableLuggageSpecification";
            feeType?: Types.LuggageFeeType | null;
            pointOfSale?: Types.PointOfSaleType | null;
            maxWeight?: { __typename?: "WeightEntry"; value: number } | null;
            maxDimension?: { __typename?: "DimensionEntry"; value: string } | null;
            feePrice?: { __typename?: "MoneyEntry"; value: number } | null;
            bookableUntil?: {
              __typename?: "TimeEntry";
              value: number;
              unit: Types.TimeUnit;
            } | null;
          } | null;
        } | null;
        pushchairPolicy?: {
          __typename?: "BookableLuggagePolicy";
          bookable: boolean;
          luggageSpecification?: {
            __typename?: "BookableLuggageSpecification";
            feeType?: Types.LuggageFeeType | null;
            pointOfSale?: Types.PointOfSaleType | null;
            feePrice?: { __typename?: "MoneyEntry"; value: number } | null;
            bookableUntil?: {
              __typename?: "TimeEntry";
              value: number;
              unit: Types.TimeUnit;
            } | null;
          } | null;
        } | null;
        bikePolicy?: {
          __typename?: "BookableLuggagePolicy";
          bookable: boolean;
          luggageSpecification?: {
            __typename?: "BookableLuggageSpecification";
            pointOfSale?: Types.PointOfSaleType | null;
            feePrice?: { __typename?: "MoneyEntry"; value: number } | null;
            bookableUntil?: {
              __typename?: "TimeEntry";
              value: number;
              unit: Types.TimeUnit;
            } | null;
          } | null;
        } | null;
        surfboardPolicy?: {
          __typename?: "BookableLuggagePolicy";
          bookable: boolean;
          luggageSpecification?: {
            __typename?: "BookableLuggageSpecification";
            pointOfSale?: Types.PointOfSaleType | null;
            feePrice?: { __typename?: "MoneyEntry"; value: number } | null;
            bookableUntil?: {
              __typename?: "TimeEntry";
              value: number;
              unit: Types.TimeUnit;
            } | null;
          } | null;
        } | null;
        skiEquipmentPolicy?: {
          __typename?: "BookableLuggagePolicy";
          bookable: boolean;
          luggageSpecification?: {
            __typename?: "BookableLuggageSpecification";
            pointOfSale?: Types.PointOfSaleType | null;
            feePrice?: { __typename?: "MoneyEntry"; value: number } | null;
            bookableUntil?: {
              __typename?: "TimeEntry";
              value: number;
              unit: Types.TimeUnit;
            } | null;
          } | null;
        } | null;
        petPolicy?: {
          __typename?: "BookableLuggagePolicy";
          bookable: boolean;
          luggageSpecification?: {
            __typename?: "BookableLuggageSpecification";
            pointOfSale?: Types.PointOfSaleType | null;
            feePrice?: { __typename?: "MoneyEntry"; value: number } | null;
            bookableUntil?: {
              __typename?: "TimeEntry";
              value: number;
              unit: Types.TimeUnit;
            } | null;
          } | null;
        } | null;
      } | null;
      passengerPolicy?: {
        __typename?: "PassengerPolicy";
        unaccompaniedMinorPolicy?: {
          __typename?: "UnaccompaniedMinorPolicy";
          allowed: boolean;
          unaccompaniedMinorSpecification?: {
            __typename?: "UnaccompaniedMinorSpecification";
            minAgeYears?: number | null;
            internationalTravelAllowed: boolean;
            nightTravelAllowed: boolean;
            interconnectionTravelAllowed: boolean;
            internationalTravel?: {
              __typename?: "UnaccompaniedMinorAllowed";
              parentalConsentRequired?: boolean | null;
            } | null;
            nightTravel?: {
              __typename?: "UnaccompaniedMinorAllowed";
              parentalConsentRequired?: boolean | null;
            } | null;
            interconnectionTravel?: {
              __typename?: "UnaccompaniedMinorAllowed";
              parentalConsentRequired?: boolean | null;
            } | null;
          } | null;
        } | null;
        passengerWithDisabilityPolicy?: {
          __typename?: "PassengerWithDisabilityPolicy";
          supported: boolean;
          passengerWithDisabilitySpecification?: {
            __typename?: "PassengerWithDisabilitySpecification";
            proofOfDisabilityRequired?: boolean | null;
            registrationInstruction?: Types.DisabilitySupportRegistrationType | null;
            supportingServices: Array<Types.DisabilitySupportType>;
            registrationUntil?: {
              __typename?: "TimeEntry";
              value: number;
              unit: Types.TimeUnit;
            } | null;
          } | null;
        } | null;
      } | null;
    }>;
  };
};

export type UpdateBrandMutationVariables = Types.Exact<{
  id: Types.Scalars["ID"]["input"];
  policyUnits: Types.PolicyUnitsInput;
  policies: Array<Types.PolicyInput> | Types.PolicyInput;
}>;

export type UpdateBrandMutation = {
  __typename?: "Mutation";
  updateBrand: {
    __typename?: "Brand";
    id: string;
    policyUnits: {
      __typename?: "PolicyUnits";
      weightUnit: Types.WeightUnit;
      lengthUnit: Types.LengthUnit;
      currency: Types.Currency;
    };
    policies: Array<{
      __typename?: "Policy";
      type: Types.PolicyType;
      additionalInformation?: string | null;
      luggagePolicy?: {
        __typename?: "LuggagePolicy";
        handLuggagePolicy?: {
          __typename?: "IncludedLuggagePolicy";
          inTicketIncluded: boolean;
          luggageSpecification?: {
            __typename?: "IncludedLuggageSpecification";
            maxPieces?: number | null;
            maxWeight?: { __typename?: "WeightEntry"; value: number } | null;
            maxDimension?: { __typename?: "DimensionEntry"; value: string } | null;
          } | null;
        } | null;
        checkInLuggagePolicy?: {
          __typename?: "IncludedLuggagePolicy";
          inTicketIncluded: boolean;
          luggageSpecification?: {
            __typename?: "IncludedLuggageSpecification";
            maxPieces?: number | null;
            maxWeight?: { __typename?: "WeightEntry"; value: number } | null;
            maxDimension?: { __typename?: "DimensionEntry"; value: string } | null;
          } | null;
        } | null;
        additionalLuggagePolicy?: {
          __typename?: "BookableLuggagePolicy";
          bookable: boolean;
          luggageSpecification?: {
            __typename?: "BookableLuggageSpecification";
            feeType?: Types.LuggageFeeType | null;
            pointOfSale?: Types.PointOfSaleType | null;
            maxWeight?: { __typename?: "WeightEntry"; value: number } | null;
            maxDimension?: { __typename?: "DimensionEntry"; value: string } | null;
            feePrice?: { __typename?: "MoneyEntry"; value: number } | null;
          } | null;
        } | null;
        specialLuggagePolicy?: {
          __typename?: "BookableLuggagePolicy";
          bookable: boolean;
          luggageSpecification?: {
            __typename?: "BookableLuggageSpecification";
            feeType?: Types.LuggageFeeType | null;
            pointOfSale?: Types.PointOfSaleType | null;
            maxWeight?: { __typename?: "WeightEntry"; value: number } | null;
            maxDimension?: { __typename?: "DimensionEntry"; value: string } | null;
            feePrice?: { __typename?: "MoneyEntry"; value: number } | null;
            bookableUntil?: {
              __typename?: "TimeEntry";
              value: number;
              unit: Types.TimeUnit;
            } | null;
          } | null;
        } | null;
        pushchairPolicy?: {
          __typename?: "BookableLuggagePolicy";
          bookable: boolean;
          luggageSpecification?: {
            __typename?: "BookableLuggageSpecification";
            feeType?: Types.LuggageFeeType | null;
            pointOfSale?: Types.PointOfSaleType | null;
            feePrice?: { __typename?: "MoneyEntry"; value: number } | null;
            bookableUntil?: {
              __typename?: "TimeEntry";
              value: number;
              unit: Types.TimeUnit;
            } | null;
          } | null;
        } | null;
        bikePolicy?: {
          __typename?: "BookableLuggagePolicy";
          bookable: boolean;
          luggageSpecification?: {
            __typename?: "BookableLuggageSpecification";
            pointOfSale?: Types.PointOfSaleType | null;
            feePrice?: { __typename?: "MoneyEntry"; value: number } | null;
            bookableUntil?: {
              __typename?: "TimeEntry";
              value: number;
              unit: Types.TimeUnit;
            } | null;
          } | null;
        } | null;
        surfboardPolicy?: {
          __typename?: "BookableLuggagePolicy";
          bookable: boolean;
          luggageSpecification?: {
            __typename?: "BookableLuggageSpecification";
            pointOfSale?: Types.PointOfSaleType | null;
            feePrice?: { __typename?: "MoneyEntry"; value: number } | null;
            bookableUntil?: {
              __typename?: "TimeEntry";
              value: number;
              unit: Types.TimeUnit;
            } | null;
          } | null;
        } | null;
        skiEquipmentPolicy?: {
          __typename?: "BookableLuggagePolicy";
          bookable: boolean;
          luggageSpecification?: {
            __typename?: "BookableLuggageSpecification";
            pointOfSale?: Types.PointOfSaleType | null;
            feePrice?: { __typename?: "MoneyEntry"; value: number } | null;
            bookableUntil?: {
              __typename?: "TimeEntry";
              value: number;
              unit: Types.TimeUnit;
            } | null;
          } | null;
        } | null;
        petPolicy?: {
          __typename?: "BookableLuggagePolicy";
          bookable: boolean;
          luggageSpecification?: {
            __typename?: "BookableLuggageSpecification";
            pointOfSale?: Types.PointOfSaleType | null;
            feePrice?: { __typename?: "MoneyEntry"; value: number } | null;
            bookableUntil?: {
              __typename?: "TimeEntry";
              value: number;
              unit: Types.TimeUnit;
            } | null;
          } | null;
        } | null;
      } | null;
      passengerPolicy?: {
        __typename?: "PassengerPolicy";
        unaccompaniedMinorPolicy?: {
          __typename?: "UnaccompaniedMinorPolicy";
          allowed: boolean;
          unaccompaniedMinorSpecification?: {
            __typename?: "UnaccompaniedMinorSpecification";
            minAgeYears?: number | null;
            internationalTravelAllowed: boolean;
            nightTravelAllowed: boolean;
            interconnectionTravelAllowed: boolean;
            internationalTravel?: {
              __typename?: "UnaccompaniedMinorAllowed";
              parentalConsentRequired?: boolean | null;
            } | null;
            nightTravel?: {
              __typename?: "UnaccompaniedMinorAllowed";
              parentalConsentRequired?: boolean | null;
            } | null;
            interconnectionTravel?: {
              __typename?: "UnaccompaniedMinorAllowed";
              parentalConsentRequired?: boolean | null;
            } | null;
          } | null;
        } | null;
        passengerWithDisabilityPolicy?: {
          __typename?: "PassengerWithDisabilityPolicy";
          supported: boolean;
          passengerWithDisabilitySpecification?: {
            __typename?: "PassengerWithDisabilitySpecification";
            proofOfDisabilityRequired?: boolean | null;
            registrationInstruction?: Types.DisabilitySupportRegistrationType | null;
            supportingServices: Array<Types.DisabilitySupportType>;
            registrationUntil?: {
              __typename?: "TimeEntry";
              value: number;
              unit: Types.TimeUnit;
            } | null;
          } | null;
        } | null;
      } | null;
    }>;
  };
};

export const FindBrandDocument = gql`
  query findBrand($brandId: ID!) {
    findBrand(id: $brandId) {
      id
      name
      policyUnits {
        weightUnit
        lengthUnit
        currency
      }
      policies {
        type
        additionalInformation
        ...PolicyDetail
      }
    }
  }
  ${PolicyDetailFragmentDoc}
`;

/**
 * __useFindBrandQuery__
 *
 * To run a query within a React component, call `useFindBrandQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindBrandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindBrandQuery({
 *   variables: {
 *      brandId: // value for 'brandId'
 *   },
 * });
 */
export function useFindBrandQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FindBrandQuery,
    FindBrandQueryVariables
  > &
    ({ variables: FindBrandQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<FindBrandQuery, FindBrandQueryVariables>(
    FindBrandDocument,
    options
  );
}
export function useFindBrandLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FindBrandQuery,
    FindBrandQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<FindBrandQuery, FindBrandQueryVariables>(
    FindBrandDocument,
    options
  );
}
export function useFindBrandSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    FindBrandQuery,
    FindBrandQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<FindBrandQuery, FindBrandQueryVariables>(
    FindBrandDocument,
    options
  );
}
export type FindBrandQueryHookResult = ReturnType<typeof useFindBrandQuery>;
export type FindBrandLazyQueryHookResult = ReturnType<typeof useFindBrandLazyQuery>;
export type FindBrandSuspenseQueryHookResult = ReturnType<
  typeof useFindBrandSuspenseQuery
>;
export const UpdateBrandDocument = gql`
  mutation updateBrand(
    $id: ID!
    $policyUnits: PolicyUnitsInput!
    $policies: [PolicyInput!]!
  ) {
    updateBrand(id: $id, policyUnits: $policyUnits, policies: $policies) {
      id
      policyUnits {
        weightUnit
        lengthUnit
        currency
      }
      policies {
        type
        additionalInformation
        ...PolicyDetail
      }
    }
  }
  ${PolicyDetailFragmentDoc}
`;

/**
 * __useUpdateBrandMutation__
 *
 * To run a mutation, you first call `useUpdateBrandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBrandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBrandMutation, { data, loading, error }] = useUpdateBrandMutation({
 *   variables: {
 *      id: // value for 'id'
 *      policyUnits: // value for 'policyUnits'
 *      policies: // value for 'policies'
 *   },
 * });
 */
export function useUpdateBrandMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateBrandMutation,
    UpdateBrandMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateBrandMutation, UpdateBrandMutationVariables>(
    UpdateBrandDocument,
    options
  );
}
export type UpdateBrandMutationHookResult = ReturnType<typeof useUpdateBrandMutation>;
