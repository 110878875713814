import { Icon, IconMeatball } from "@flixbus/honeycomb-icons-react";
import * as React from "react";
import * as css from "./MeatballMenu.scss";

export type MeatballMenuProps = {
  content: Array<{
    action: () => void;
    text: string;
  }>;
};

const MeatballMenu: React.FC<MeatballMenuProps> = ({ content }) => (
  <div className={css.iconContainer} tabIndex={-1}>
    <div className={css.meatballIconContainer} data-id="meatball-menu-hoverable-area">
      <Icon InlineIcon={IconMeatball} extraClasses={css.meatballIcon} />
      <div className={css.menuContainer} data-id="meatball-menu-container">
        {content.map((item) => (
          <div
            data-id={item.text}
            key={item.text}
            className={css.menuItem}
            onClick={() => item.action()}
          >
            {item.text}
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default MeatballMenu;
