import { IconComponent, Icon } from "@flixbus/honeycomb-icons-react";
import { Button, PopupSection } from "@flixbus/honeycomb-react";
import { Popup } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import * as css from "./ConfirmationPopup.scss";

export type ConfirmationPopupProps = {
  active: boolean;
  titleIcon?: IconComponent;
  titleIconColor?: "warning"; // Can be expanded in the future
  titleText: string;
  confirmButtonText?: string;
  confirmButtonLoading?: boolean;
  confirmButtonDisabled?: boolean;
  onConfirm?: () => void;
  onCancel: () => void;
  cancelButtonText?: string;
  allowOverflow?: boolean;
  isWide?: boolean;
};

const ConfirmationPopup: React.FC<ConfirmationPopupProps> = ({
  active,
  titleIcon,
  titleIconColor,
  titleText,
  children,
  confirmButtonText,
  confirmButtonLoading,
  confirmButtonDisabled,
  onConfirm,
  onCancel,
  cancelButtonText,
  allowOverflow,
  isWide,
}) => {
  return (
    <Popup
      active={active}
      onClose={onCancel}
      allowOverflow={allowOverflow}
      isWide={isWide}
    >
      <PopupSection type="title">
        {titleIcon && (
          <div className={css.iconWrapper}>
            <Icon
              InlineIcon={titleIcon}
              size={8}
              extraClasses={css[`iconColor-${titleIconColor}`]}
            />
          </div>
        )}
        <div>{titleText}</div>
      </PopupSection>
      {children && <PopupSection type="content">{children}</PopupSection>}
      <PopupSection type="actions">
        <Button data-id="cancel-button-confirmation-popup" onClick={onCancel}>
          {cancelButtonText || <FormattedMessage id="general.cancel" />}
        </Button>
        {onConfirm && (
          <Button
            id="confirmation-popup-confirm-button"
            data-id="confirm-button"
            appearance="primary"
            onClick={onConfirm}
            loading={confirmButtonLoading}
            disabled={confirmButtonDisabled}
          >
            {confirmButtonText || <FormattedMessage id="general.confirm" />}
          </Button>
        )}
      </PopupSection>
    </Popup>
  );
};

export default ConfirmationPopup;
