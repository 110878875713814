import { CalendarIntervallType, Interval, OverlapPosition } from "../../types";
import calculatePeriodPosition from "../calculatePeriodPosition/calculatePeriodPosition";

export default (
  currentInterval: Date,
  overlapIntervals: Array<Interval>,
  selectedIntervalType: CalendarIntervallType
) => {
  return overlapIntervals.reduce((total: Array<OverlapPosition>, currentValue) => {
    const [overlapColStart, overlapStartDateInPast, overlapStartDateInFuture] =
      calculatePeriodPosition(currentValue.start, currentInterval, selectedIntervalType);

    const [overlapColEnd, overlapEndDateInPast, overlapEndDateInFuture] =
      calculatePeriodPosition(currentValue.end, currentInterval, selectedIntervalType);

    if (
      (overlapStartDateInPast && overlapEndDateInPast) ||
      (overlapStartDateInFuture && overlapEndDateInFuture)
    ) {
      return [...total];
    }

    const position = {
      colStart: overlapColStart,
      colEnd: overlapColEnd,
      startDateInPast: overlapStartDateInPast && !overlapEndDateInPast,
      endDateInFuture: !overlapStartDateInFuture && overlapEndDateInFuture,
    };

    return [...total, position];
  }, []);
};
