import cx from "classnames";
import * as React from "react";
import * as css from "./TimetableColumn.scss";

export type TimetableColumnProps = {
  content: JSX.Element[];
  colPosition: number;
  rowPosition: number;
  extraClasses?: string;
  childClasses?: string;
};

const TimetableColumn: React.FC<TimetableColumnProps> = ({
  content,
  colPosition,
  rowPosition,
  extraClasses,
  childClasses,
}) => {
  return (
    <div
      className={cx(extraClasses, content.length > 0 ? css.col : "")}
      data-id="grid-col"
      style={{
        gridColumn: `${colPosition} / ${colPosition + 1}`,
        gridRow: `${rowPosition} / ${rowPosition + 1}`,
      }}
    >
      {content.map(
        (value: JSX.Element, index: number): JSX.Element => (
          <div
            className={`${css.cell} ${childClasses}`}
            data-id="grid-cell"
            key={value.key ? value.key : Math.random()}
            style={{
              gridRow: `${index + 1} / ${index + 2}`,
            }}
          >
            {value}
          </div>
        )
      )}
    </div>
  );
};

export default TimetableColumn;
