import { Checkbox } from "@flixbus/honeycomb-react";
import { useField } from "formik2";
import * as React from "react";
import { useIntl } from "react-intl";
import FormikSwitch from "../../../../shared/components/formikSwitch/FormikSwitch";
import * as css from "./UnaccompaniedMinorsCheckbox.scss";

type Props = {
  label: string;
  name: string;
  readOnly?: boolean;
};

const UnaccompaniedMinorsCheckbox: React.FC<Props> = ({ label, name, readOnly }) => {
  const { formatMessage } = useIntl();

  const [field, meta] = useField({
    name: `${name}Allowed`,
    type: "checkbox",
    value: `${name}Allowed`,
  });

  const [, metaParentalConsent, helperParentalConsent] = useField({
    name: `${name}`,
  });

  React.useEffect(() => {
    if (meta.value) {
      helperParentalConsent.setValue({
        parentalConsentRequired:
          metaParentalConsent.value?.parentalConsentRequired || false,
      });
    } else {
      helperParentalConsent.setValue(undefined);
    }
  }, [meta.value]);

  return (
    <div>
      <Checkbox
        label={label}
        id={name}
        {...field}
        checked={meta.value}
        disabled={readOnly}
      />
      {meta.value && (
        <div className={css.switchContainer}>
          <FormikSwitch
            label={formatMessage({
              id: "policies.unaccompaniedMinorSpecification.allowedOptions.parentalConsent.label",
            })}
            name={`${name}.parentalConsentRequired`}
            small
            readOnly={readOnly}
          />
        </div>
      )}
    </div>
  );
};

export default UnaccompaniedMinorsCheckbox;
