import {
  addNotification,
  NotificationType,
  formatMessage,
} from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage } from "react-intl";

export const BrandAddedNotification = (
  <>
    <div>
      <FormattedMessage id="partnerForm.brandsAndPolicies.addedBrand.notification.topText" />
    </div>
    <div style={{ fontWeight: "normal" }}>
      <FormattedMessage id="partnerForm.brandsAndPolicies.addedBrand.notification.bottomText" />
    </div>
  </>
);

const createNotificationForBrandUpdate = (
  isUpdateFlow: boolean,
  brandRemoved: boolean
) => {
  if (isUpdateFlow) {
    addNotification({
      message: formatMessage("partnerForm.brandsAndPolicies.updated.notification"),
    });
  } else if (brandRemoved) {
    addNotification({
      message: formatMessage("partnerForm.brandsAndPolicies.removedBrand.notification"),
      type: NotificationType.Success,
    });
  } else if (!brandRemoved) {
    addNotification({
      message: BrandAddedNotification,
      type: NotificationType.Success,
    });
  }
};

export default createNotificationForBrandUpdate;
