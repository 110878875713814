import { parse } from "date-fns";

const parseFromAndToTime = (from: string, to: string, formatString: string) => {
  const fromDate = parse(from, formatString, new Date(Date.now()));
  const toDate = parse(to, formatString, new Date(Date.now()));

  return [fromDate, toDate];
};

export default parseFromAndToTime;
