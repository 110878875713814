import getUserPreferredTimeFormat from "../../../../shared/helpers/userPreferredTimeFormat/userPreferredTimeFormat";
import {
  CustomerServiceContactInput,
  DayOfWeek,
  PurposeOfContactField,
} from "../../../../shared/types/schema";
import { CustomerServiceContactFormValue } from "../../types";
import getSubmittableHolidayWorkingHours from "../getSubmittableHolidayWorkingHours/getSubmittableHolidayWorkingHours";
import getSubmittableTimeValue from "../getSubmittableTimeValue/getSubmittableTimeValue";

/**
 * Function that sets empty fields to undefined.
 * The user could for example add new rows but not fill them.
 * As we dont want to sent empty strings or even empty rows to the backend,
 * the form data is run through this function in order to clean up the data.
 */
export default (
  values: Array<CustomerServiceContactFormValue>
): Array<CustomerServiceContactInput> => {
  const timeFormat = getUserPreferredTimeFormat();

  const transformedValues: Array<CustomerServiceContactInput> = [];

  values.forEach((value) => {
    const transformedWorkingHours: CustomerServiceContactInput["workingHours"] = [];

    // BUG in eslint. We would need to update first: https://github.com/eslint/eslint/issues/12822
    // eslint-disable-next-line no-unused-expressions
    value.workingHours?.forEach((workingHour) => {
      const workingDays = workingHour.workingDays.filter(
        (day): day is DayOfWeek => !!day
      );

      if (workingDays.length || workingHour.from || workingHour.to) {
        transformedWorkingHours.push({
          workingDays: workingDays.length ? workingDays : undefined,
          from: getSubmittableTimeValue(timeFormat, workingHour.from),
          to: getSubmittableTimeValue(timeFormat, workingHour.to),
        });
      }
    });

    const isHolidayWorkingHourEntered = value.holidayWorkingHours.workingHours.some(
      (workingHour) => workingHour.from || workingHour.to
    );

    if (
      value.purposeOfContact ||
      value.hotlineNumber ||
      value.email ||
      value.languagesSpoken?.length ||
      transformedWorkingHours.length ||
      (value.holidayWorkingHours.enabled && isHolidayWorkingHourEntered)
    ) {
      transformedValues.push({
        purposeOfContact: (value.purposeOfContact as PurposeOfContactField) || undefined,
        languagesSpoken: value.languagesSpoken?.length ? value.languagesSpoken : [],
        hotlineNumber: value.hotlineNumber || undefined,
        email: value.email || undefined,
        timeZoneId: value.timeZoneId || undefined,
        workingHours: transformedWorkingHours,
        holidayWorkingHours: getSubmittableHolidayWorkingHours(
          value.holidayWorkingHours,
          timeFormat
        ),
      });
    }
  });

  return transformedValues;
};
