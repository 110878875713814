import { Icon, IconAttentionSolid } from "@flixbus/honeycomb-icons-react";
import { Button, List, PopupSection } from "@flixbus/honeycomb-react";
import { Popup } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { ValidationInfo } from "../../../../shared/types/schema";
import * as css from "./ScheduleValidationPopup.scss";

export type ScheduleValidationPopupProps = {
  active: boolean;
  onClose: () => void;
  validationInfos: Array<Omit<ValidationInfo, "key">>;
};

const ScheduleValidationPopup: React.FC<ScheduleValidationPopupProps> = ({
  active,
  onClose,
  validationInfos,
}) => {
  return (
    <Popup active={active} onClose={onClose} isLeft>
      <PopupSection type="title">
        <FormattedMessage id="schedule.validation.popup.title" />
      </PopupSection>
      <PopupSection type="content">
        <FormattedMessage id="schedule.validation.popup.text" />
      </PopupSection>
      <PopupSection type="content">
        <div>
          {validationInfos
            .filter((validation) => !validation.valid)
            .map((validation) => {
              return (
                <div
                  data-id="validation-entry"
                  key={validation.infoText}
                  className={css.validationEntry}
                >
                  <div>
                    <Icon
                      InlineIcon={IconAttentionSolid}
                      extraClasses={css.iconWarning}
                      data-id="icon-warning"
                    />
                    <span className={css.infoText}>{validation.infoText}</span>
                  </div>
                  {validation.failedValidationInfos.length > 0 && (
                    <List extraClasses={css.list}>
                      {validation.failedValidationInfos.map((item) => (
                        <li key={item}>{item}</li>
                      ))}
                    </List>
                  )}
                </div>
              );
            })}
        </div>
      </PopupSection>
      <PopupSection type="actions">
        <Button
          type="button"
          appearance="secondary"
          data-id="cancel-button"
          onClick={onClose}
        >
          <FormattedMessage id="general.close" />
        </Button>
      </PopupSection>
    </Popup>
  );
};

export default ScheduleValidationPopup;
