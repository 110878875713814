import { MeansOfTransport } from "../../../shared/types/schema";
import { LineFilterFormSubmittableValues, LineFilterFormValues } from "../../types";

export const createInitialValues = (
  defaultValues?: LineFilterFormSubmittableValues
): LineFilterFormValues => {
  return {
    businessRegions: defaultValues?.businessRegions || [],
    meansOfTransport: {
      bus: !!defaultValues?.meansOfTransport?.includes(MeansOfTransport.Bus),
      train: !!defaultValues?.meansOfTransport?.includes(MeansOfTransport.Train),
      ferry: !!defaultValues?.meansOfTransport?.includes(MeansOfTransport.Ferry),
      minibus: !!defaultValues?.meansOfTransport?.includes(MeansOfTransport.MiniBus),
    },
  };
};

export const createSubmittableValues = (
  values: LineFilterFormValues
): LineFilterFormSubmittableValues => {
  const meansOfTransport = [
    values.meansOfTransport.bus ? MeansOfTransport.Bus : undefined,
    values.meansOfTransport.train ? MeansOfTransport.Train : undefined,
    values.meansOfTransport.ferry ? MeansOfTransport.Ferry : undefined,
    values.meansOfTransport.minibus ? MeansOfTransport.MiniBus : undefined,
  ].filter(
    (val) => val !== undefined
  ) as LineFilterFormSubmittableValues["meansOfTransport"];

  return {
    businessRegions: values.businessRegions.length ? values.businessRegions : null,
    meansOfTransport: meansOfTransport?.length ? meansOfTransport : null,
  };
};
