export default (ref: React.RefObject<HTMLDivElement>): boolean => {
  if (ref.current) {
    const elements = ref.current.children;

    return Array.from(elements).some(
      (element) => element.scrollWidth > element.clientWidth
    );
  }

  return false;
};
