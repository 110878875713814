export const isValid12hFormat = (value: string): boolean => {
  return /(0[1-9]|1[0-2]):[0-5][0-9] (A|P|a|p)(M|m)/.test(value);
};

/**
 * Valid time values are all inputs that user needs to do so that he can type a time in the 12 hour clock format.
 * Example flow of valid inputs: "", "1", "11", "11:", "11:5", "11:59", "11:59 AM", "11:59 PM"
 */
export const isPreValid12hFormat = (value: string): boolean => {
  switch (value.length) {
    case 0:
      return true;
    case 1:
      return /[0-1]/.test(value);
    case 2:
      return /(0[1-9]|1[0-2])/.test(value);
    case 3:
      return /(0[1-9]|1[0-2]):/.test(value);
    case 4:
      return /(0[1-9]|1[0-2]):[0-5]/.test(value);
    case 5:
      return /(0[1-9]|1[0-2]):[0-5][0-9]/.test(value);
    case 6:
      return (
        /(0[1-9]|1[0-2]):[0-5][0-9] /.test(value) ||
        /:[0-5][0-9] (A|P|a|p)/.test(value) || // allow deleted hours
        /(0[1-9]|1[0-2]): (A|P|a|p)(M|m)/.test(value)
      ); // allow deleted minutes
    case 7:
      return (
        /(0[1-9]|1[0-2]):[0-5][0-9] (A|P|a|p)/.test(value) ||
        /([1-9]|[0-2]):[0-5][0-9] (A|P|a|p)/.test(value) || // allow deleted tens position hours
        /(0[1-9]|1[0-2]):[0-9] (A|P|a|p)(M|m)/.test(value) || // allow deleted tens position minutes
        /(0[1-9]|1[0-2]):[0-5][0-9] (M|m)/.test(value)
      ); // allow deleted first suffix character
    case 8:
      return isValid12hFormat(value);
    default:
      return false;
  }
};
