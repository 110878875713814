import notificationsVar from "./reactiveVariable";
import { DeleteNotificationsArgs } from "./types";

export const deleteNotification = (notificationId: DeleteNotificationsArgs["id"]) => {
  const notifications = notificationsVar();
  const notificationsArr = [...notifications];

  const findNotificationById = (id: DeleteNotificationsArgs["id"]) =>
    notificationsArr.findIndex((ele) => ele.id === id);

  const removeItemFromNotifications = (index: number) => {
    notificationsArr.splice(index, 1);
  };

  removeItemFromNotifications(findNotificationById(notificationId));

  notificationsVar(notificationsArr);
};

export const deleteAllNotifications = () => {
  const notifications = notificationsVar();

  if (notifications.length) {
    notificationsVar([]);
  }
};
