import { useState, useLayoutEffect } from 'react';
import { SubMenuInitialFocus } from '../../contexts/sub-menu-context.js';

function useSubMenuInitialFocusManager(_a) {
  var isExpanded = _a.isExpanded,
    lastEvent = _a.lastEvent;
  var _b = useState(SubMenuInitialFocus.None),
    focusAt = _b[0],
    setFocusAt = _b[1];
  useLayoutEffect(function () {
    if ((lastEvent === null || lastEvent === void 0 ? void 0 : lastEvent.nativeEvent) instanceof KeyboardEvent) {
      var lastKeyboardEvent = lastEvent;
      if (isExpanded) {
        if (lastKeyboardEvent.key === 'ArrowDown') {
          setFocusAt(SubMenuInitialFocus.First);
        } else if (lastKeyboardEvent.key === 'ArrowUp') {
          setFocusAt(SubMenuInitialFocus.Last);
        } else {
          setFocusAt(SubMenuInitialFocus.None);
        }
      } else {
        if (lastKeyboardEvent.key === 'Escape') {
          setFocusAt(SubMenuInitialFocus.Target);
          return;
        }
        setFocusAt(SubMenuInitialFocus.None);
      }
    }
  }, [isExpanded, lastEvent]);
  return focusAt;
}

export { useSubMenuInitialFocusManager };
