import { Skeleton } from "@flixbus/honeycomb-react";
import React from "react";
import * as css from "./InputSkeleton.scss";

const InputSkeleton: React.FC = () => {
  return (
    <>
      <Skeleton height="sm" width="sm" extraClasses={css.label} />
      <Skeleton height="lg" flushBottom />
    </>
  );
};

export default InputSkeleton;
