import { SelectGroup } from "@flixbus/honeycomb-react";
import { useField } from "formik2";
import * as React from "react";
import { useIntl } from "react-intl";
import getWeekdayText from "../../../../shared/helpers/getWeekdayText/getWeekdayText";
import {
  DayOfWeek,
  StationTransferInformationOpeningHours,
} from "../../../../shared/types/schema";
import convertOpeningHour from "../../helpers/convertOpeningHour/convertOpeningHour";
import * as css from "./TransferOpeningHoursDaySelect.scss";

const TransferOpeningHoursDaySelect: React.FC = () => {
  const { formatMessage } = useIntl();

  const [, meta, helper] = useField<Array<Array<StationTransferInformationOpeningHours>>>(
    "transferInformation.transferOpeningHours"
  );

  const error = typeof meta.error === "string" ? meta.error : undefined;

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as unknown as number;
    const updatedOpeningHours = [...meta.value];

    if (event.target.checked) {
      const selectedDay = Object.values(DayOfWeek)[value];

      updatedOpeningHours[value] = [
        convertOpeningHour({
          weekday: selectedDay,
          start: "00:00",
          end: "23:59",
        }),
      ];

      return helper.setValue(updatedOpeningHours);
    }

    updatedOpeningHours[value] = [];
    return helper.setValue(updatedOpeningHours);
  };

  const options = Object.values(DayOfWeek).map((day, index) => {
    return {
      id: index,
      value: index,
      name: "weekday-select-option",
      label: getWeekdayText(day),
      defaultChecked: !!meta.value[index].length,
      onChange,
    };
  });

  return (
    <SelectGroup
      id="formik-weekday-select-group"
      aria-label="Select day of week"
      label={`${formatMessage({ id: "station.transferOpeningHours" })} *`}
      multi
      options={options}
      extraClasses={css.selectGroup}
      error={!!error}
      infoError={error}
    />
  );
};

export default TransferOpeningHoursDaySelect;
