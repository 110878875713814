import { Spinner } from "@flixbus/honeycomb-react";
import {
  addNotification,
  DefaultInfoAlert,
  Feature,
  formatErrorMessage,
  hasUserPermission,
  legacyTranslate,
  NotificationType,
} from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { useIntl } from "react-intl";
import { TripDirection } from "../../shared/types/schema";
import {
  FindScheduleWithTripsAndStationsQuery,
  useAddTripToScheduleMutation,
  useFindScheduleWithTripsAndStationsQuery,
} from "./api/operations.generated";
import { addTripToCache } from "./helpers/cacheHandler/cacheHandler";
import TimetableOverview from "./ui/timetableOverview/TimetableOverview";

type Props = {
  scheduleId: FindScheduleWithTripsAndStationsQuery["findSchedule"]["id"];
};

const Timetable: React.FC<Props> = ({ scheduleId }) => {
  const { formatMessage } = useIntl();

  const {
    loading,
    data,
    error: queryError,
  } = useFindScheduleWithTripsAndStationsQuery({
    variables: { scheduleId },
  });

  const [addReturnTrip] = useAddTripToScheduleMutation({
    update: (cache, { data: newTrip }) => {
      addTripToCache(cache, newTrip?.newTrip, scheduleId);
    },
    variables: { scheduleId, direction: TripDirection.Return },
    onError: (error) => {
      addNotification({
        message: `Sorry, we are unable to add a Return Trip: ${formatErrorMessage(
          error.message
        )}`,
        type: NotificationType.Danger,
      });
    },
  });

  if (loading) {
    return <Spinner size="xl" />;
  }

  if (queryError) {
    return (
      <DefaultInfoAlert
        message={formatMessage(
          { id: "timetable.load.notification.failure" },
          { scheduleId }
        )}
        translate={legacyTranslate}
      />
    );
  }

  if (data) {
    const schedule = data.findSchedule;

    return (
      <TimetableOverview
        schedule={schedule}
        onAddReturnSchedule={() => addReturnTrip()}
        readOnly={
          !hasUserPermission(Feature.EDIT_SCHEDULE_TIMETABLE_UNPUBLISHED) ||
          schedule.readOnly
        }
      />
    );
  }

  return <></>;
};

export default Timetable;
