@import "src/shared/styles/variables";

.stations {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.headline {
  height: 144px;
  line-height: 144px;
  text-align: center;
  background: $bg-primary-color;
  width: 300px;
}

.cells {
  &:nth-child(even) {
    background: $grayscale-10-color;
  }
}

.addNewStation {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-top: none;
}

.addNewStationChildren {
  overflow: visible !important;
}

.container {
  padding: $spacing-2;
}
