import { Icon, IconClose, IconComponent } from "@flixbus/honeycomb-icons-react";
import { ALLOWED_INPUT_TYPES, Button, Input, Link } from "@flixbus/honeycomb-react";
import { useField } from "formik2";
import * as React from "react";
import { formatPriceInput } from "../../helpers/priceInputFormatter/priceInputFormatter";
import isValid from "../../helpers/validateFormikInput/validateFormikInput";
import ReadOnlyFormField from "../readOnlyFormField/ReadOnlyFormField";
import TooltipLabel from "../tooltipLabel/TooltipLabel";

export type FormikInputProps = {
  label: string;
  name: string;
  type: (typeof ALLOWED_INPUT_TYPES)[number];
  hideLabel?: boolean;
  placeholder?: string;
  required?: boolean;
  readOnly?: boolean;
  readOnlyValueWhenEmpty?: string;
  isPriceInput?: boolean;
  isFocused?: boolean;
  icon?: IconComponent;
  tooltipContent?: JSX.Element;
  isClearable?: boolean;
  hideValidation?: boolean;
};

const FormikInput: React.FC<FormikInputProps> = ({
  label,
  hideLabel = false,
  placeholder,
  name,
  type,
  required,
  readOnly,
  readOnlyValueWhenEmpty,
  isPriceInput = false,
  isFocused = false,
  icon,
  tooltipContent,
  isClearable = false,
  hideValidation = false,
}) => {
  const [field, meta, helper] = useField({ name, type });

  if (readOnly) {
    const value =
      readOnlyValueWhenEmpty && meta.value === "" ? readOnlyValueWhenEmpty : meta.value;

    const getOutput = (formValue: string | React.ReactElement) => (
      <ReadOnlyFormField label={!hideLabel ? label : ""}>{formValue}</ReadOnlyFormField>
    );
    if (meta.value !== "") {
      if (type === "email") {
        return getOutput(<Link href={`mailto:${meta.value}`}>{value}</Link>);
      }
      if (type === "url") {
        return getOutput(<Link href={meta.value}>{value}</Link>);
      }
    }
    return getOutput(value);
  }

  const LABEL = tooltipContent ? (
    <TooltipLabel
      id={name}
      labelText={label}
      content={tooltipContent}
      required={required}
    />
  ) : (
    `${label}${required ? " *" : ""}`
  );

  return (
    <Input
      {...(hideLabel ? { "aria-label": label } : { label: LABEL })}
      id={name}
      placeholder={placeholder}
      valid={!hideValidation ? isValid(meta) : undefined}
      infoError={meta.error}
      type={type}
      required={required}
      {...field}
      onBlur={(event) => {
        if (isPriceInput) {
          const formattedValue = formatPriceInput(meta.value);
          helper.setValue(formattedValue);
        }
        field.onBlur(event);
      }}
      onChange={(event) => {
        if (type === "number" && event.target.value !== "") {
          helper.setValue(event.target.valueAsNumber);
        } else {
          helper.setValue(event.target.value);
        }
      }}
      autoFocus={isFocused}
      iconLeft={icon ? <Icon InlineIcon={icon} /> : undefined}
      iconRight={
        meta.value?.length && isClearable && type === "text" ? (
          <Button appearance="link" onClick={() => helper.setValue("")}>
            <Icon InlineIcon={IconClose} />
          </Button>
        ) : undefined
      }
    />
  );
};

export default FormikInput;
