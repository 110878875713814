const createRegex = (value: string): RegExp => {
  const valueWithspecialCharReplaced = value.replace(
    /\(|\)|\[|\]|\?|\+|\*/g,
    (match) => `\\${match}`
  );

  return new RegExp(valueWithspecialCharReplaced);
};

export default createRegex;
