import { Tooltip, Input } from "@flixbus/honeycomb-react";
import * as React from "react";
import { useIntl } from "react-intl";
import * as css from "./TripNumberInput.scss";

export type TripNumberInputProps = {
  value: string | null;
  onValueChanged: (value: string) => void;
  disabled: boolean;
  placeholder?: string;
};

const TripNumberInput: React.FC<TripNumberInputProps> = ({
  value,
  onValueChanged,
  disabled,
  placeholder,
}) => {
  const { formatMessage } = useIntl();
  const [tripInputValue, setTripInputValue] = React.useState(value || "");

  const handleTripInputChange = () => {
    if (value !== tripInputValue) {
      onValueChanged(tripInputValue);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleTripInputChange();
    }
  };

  return (
    <Tooltip
      openOnHover
      extraClasses={css.tooltip}
      id={`trip-number-tooltip-${Math.random()}`}
      content={formatMessage({ id: "trip.number.label" })}
      size="content-fit"
    >
      <Input
        type="text"
        id={`trip-input-${Math.random()}`}
        name="trip-input"
        aria-label="trip-input"
        maxLength={255}
        value={tripInputValue}
        onBlur={handleTripInputChange}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setTripInputValue(event.target.value)
        }
        onKeyDown={handleKeyDown}
        extraClasses={css.tripInput}
        placeholder={placeholder}
        disabled={disabled}
        required
      />
    </Tooltip>
  );
};

export default TripNumberInput;
