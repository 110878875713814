import { __spreadArray, __rest, __assign } from '../../node_modules/tslib/tslib.es6.js';
import { trimTimeFromDate } from './calendar-utils.js';

// helper function to create a 'day' object based on a given date
var dayFactory = function (_a) {
  var date = _a.date,
    disabled = _a.disabled,
    prev = _a.prev,
    next = _a.next,
    rest = __rest(_a, ["date", "disabled", "prev", "next"]);
  return __assign({
    weekday: date.getDay(),
    number: date.getDate(),
    month: date.getMonth(),
    year: date.getFullYear(),
    date: date,
    disabled: disabled,
    prev: prev,
    next: next
  }, rest);
};
// creates an array of arrays of length 7
// representing the smallest required amount of full weeks to display a month
var monthFactory = function (year, month, startDateRaw, endDateRaw, offset) {
  if (offset === void 0) {
    offset = 0;
  }
  // passing `new Date()` as prop value in here adds current time that breaks date comparisons,
  // making sure all the received dates from props have default time 00:00:00
  var startDate = trimTimeFromDate(startDateRaw);
  var endDate = trimTimeFromDate(endDateRaw);
  // first day of week of this month
  var startWeekDay = new Date(year, month, 1).getDay();
  // calculate how many days of the previous month are required to match layout
  var padStartLength = 0;
  if (offset > startWeekDay) padStartLength = 7 - startWeekDay - offset;
  if (startWeekDay > offset) padStartLength = startWeekDay - offset;
  // if days from previous month are needed
  var startPad = padStartLength && new Array(padStartLength).fill(null).map(function (_, i) {
    // time travel in JS (negative days simply go back in time)
    var date = new Date(year, month, 0 - i);
    return dayFactory({
      date: date,
      disabled: date < startDate || date > endDate,
      prev: true
    });
  }).reverse() || [];
  // add all days in this current month, no check required
  var days = new Array(new Date(year, month + 1, 0).getDate()).fill(null).map(function (_, i) {
    var date = new Date(year, month, i + 1);
    return dayFactory({
      date: date,
      disabled: date < startDate || date > endDate
    });
  });
  // calculate how many days at end of the month are required to reach a week of length 7
  var right = (startPad.length + days.length) % 7;
  var padEndLength = right === 0 || right === 7 ? 0 : 7 - right;
  // if days are required
  var endPad = padEndLength && new Array(Math.abs(padEndLength)).fill(null).map(function (_, i) {
    // ensure correct next month/year depending on current month
    var nextOrCurrentMonth = month <= 10 ? month + 1 : 0;
    var nextOrCurrentYear = month <= 10 ? year : year + 1;
    var date = new Date(nextOrCurrentYear, nextOrCurrentMonth, 1 + i);
    return dayFactory({
      date: date,
      disabled: date < startDate || date > endDate,
      next: true
    });
  }) || [];
  // concat all days (previous, current and next month)
  var merge = __spreadArray(__spreadArray(__spreadArray([], startPad, true), days, true), endPad, true);
  // split in weeks
  var weeks = [];
  for (var i = 0; i < merge.length; i += 7) {
    weeks.push(merge.slice(i, i + 7));
  }
  return weeks;
};
// generates an object which key 'months'
// contains all month required by the calendar
var calendarFactory = function (start, end, offset) {
  if (offset === void 0) {
    offset = 1;
  }
  var months = [];
  var years = [];
  // maximum amount of required months by provided years
  var n = (end.getFullYear() - start.getFullYear() + 1) * 12;
  var _loop_1 = function (i) {
    // check if month is actually needed, if not, skip
    if (i % 12 < start.getMonth() && i < 11 || i % 12 > end.getMonth() && i >= n - 11) {
      return "continue";
    }
    // current year
    var year = start.getFullYear() + Math.floor(i / 12);
    // if year is in index, add it
    if (years.findIndex(function (y) {
      return y === year;
    }) === -1) years.push(year);
    // for each month add an entry
    months.push({
      year: year,
      month: i % 12,
      // weeks are generated by month factory
      weeks: monthFactory(year, i % 12, start, end, offset)
    });
  };
  // for each month
  for (var i = 0; i < n; i += 1) {
    _loop_1(i);
  }
  return {
    months: months,
    years: years
  };
};

export { calendarFactory, monthFactory };
