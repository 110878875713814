@import "variables";

.wrapper {
  display: flex;
  align-items: center;
}

.icon {
  margin-left: $spacing-half;
  height: 16px !important;
  width: 16px !important;
}
