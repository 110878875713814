import { Icon, IconDelete, IconOffer } from "@flixbus/honeycomb-icons-react";
import { FormRow } from "@flixbus/honeycomb-react";
import cx from "classnames";
import { useField } from "formik2";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import FormikTimeInput from "../../../../shared/components/formikTimeInput/FormikTimeInput";
import getWeekdayText from "../../../../shared/helpers/getWeekdayText/getWeekdayText";
import { StationTransferInformationOpeningHours } from "../../../../shared/types/schema";
import convertOpeningHour from "../../helpers/convertOpeningHour/convertOpeningHour";
import * as css from "./TransferOpeningHoursFormRow.scss";

export type Props = {
  indexOfDay: number;
  indexWithinDay: number;
  isLastWithinDay: boolean;
  readOnly?: boolean;
};

const TransferOpeningHoursFormRow: React.FC<Props> = ({
  indexOfDay,
  indexWithinDay,
  isLastWithinDay,
  readOnly,
}) => {
  const [, meta, helper] = useField<Array<StationTransferInformationOpeningHours>>(
    `transferInformation.transferOpeningHours[${indexOfDay}]`
  );

  const weekdayOfRow = meta.value[0].weekday;

  const handleAddRow = () => {
    helper.setValue([
      ...meta.value,
      convertOpeningHour({
        weekday: weekdayOfRow,
        start: "00:00",
        end: "23:59",
      }),
    ]);
  };

  const handleDeleteRow = () => {
    helper.setValue([
      ...meta.value.slice(0, indexWithinDay),
      ...meta.value.slice(indexWithinDay + 1),
    ]);
  };

  if (readOnly) {
    return (
      <div className={css.readOnlyValue}>
        {getWeekdayText(weekdayOfRow)}: {meta.value[indexWithinDay].start} -{" "}
        {meta.value[indexWithinDay].end}
      </div>
    );
  }

  return (
    <FormRow extraClasses={css.formRow}>
      <div className={css.weekdayWrapper}>
        {indexWithinDay === 0 ? (
          <div className={css.weekday}>{getWeekdayText(weekdayOfRow)}</div>
        ) : (
          <div />
        )}
      </div>
      <FormikTimeInput
        name={`transferInformation.transferOpeningHours[${indexOfDay}][${indexWithinDay}].start`}
      />
      <div className={css.separator}>
        <FormattedMessage id="general.toTime.lowerCase" />
      </div>
      <FormikTimeInput
        name={`transferInformation.transferOpeningHours[${indexOfDay}][${indexWithinDay}].end`}
      />
      <div className={css.iconsWrapper}>
        {indexWithinDay !== 0 && (
          <div className={css.iconWrapper}>
            <Icon
              data-id="delete-icon"
              InlineIcon={IconDelete}
              extraClasses={cx(css.icon, css.iconDelete)}
              onClick={handleDeleteRow}
            />
          </div>
        )}
        {isLastWithinDay && (
          <div className={css.iconWrapper}>
            <Icon
              data-id="add-icon"
              InlineIcon={IconOffer}
              extraClasses={css.icon}
              onClick={handleAddRow}
              appearance="primary"
            />
          </div>
        )}
      </div>
    </FormRow>
  );
};

export default TransferOpeningHoursFormRow;
