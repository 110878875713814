import { Icon, IconDeleteSolid } from "@flixbus/honeycomb-icons-react";
import { Button } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ConfirmationPopup from "../../../../shared/components/confirmationPopup/ConfirmationPopup";

type Props = {
  disabled: boolean;
  onDelete: () => void;
};

const TripDeleteButton: React.FC<Props> = ({ disabled, onDelete }) => {
  const { formatMessage } = useIntl();
  const [popupActive, setPopupActive] = React.useState(false);

  const handleDelete = () => {
    onDelete();
    setPopupActive(false);
  };

  return (
    <>
      <Button
        title={formatMessage({ id: "trip.delete" })}
        appearance="danger"
        display="square"
        onClick={() => setPopupActive(true)}
        disabled={disabled}
        mouse-out-visibility="hidden"
        aria-label="delete"
      >
        <Icon InlineIcon={IconDeleteSolid} />
      </Button>
      {!disabled && (
        <ConfirmationPopup
          active={popupActive}
          titleText={formatMessage({ id: "trip.delete" })}
          confirmButtonText={formatMessage({ id: "general.delete" })}
          onConfirm={handleDelete}
          onCancel={() => setPopupActive(false)}
        >
          <div>
            <FormattedMessage id="trip.delete.popup.text" />
          </div>
        </ConfirmationPopup>
      )}
    </>
  );
};

export default TripDeleteButton;
