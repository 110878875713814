export type FOOTER_MENU_ITEM = {
  title: string;
  url: {
    path: string;
  };
};

export type FOOTER_MENU_QUERY_RESULT = {
  data: {
    menu: {
      items: Array<FOOTER_MENU_ITEM>;
    };
    domains: Array<{
      name: string;
    }>;
  };
};

export const queryFooterMenu = async (
  langCode: string
): Promise<FOOTER_MENU_QUERY_RESULT> => {
  // For more info on this endpoint see: https://wiki.flix.tech/display/D8/Navigation+Menus
  const res = await fetch("https://api.cms.flixbus.com/gql", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      query: `
      query {
        menu(name: "footer-legal-react-fe") {
          items (language: "${langCode}") {
            title
            url {
              path
            }
          }
        }
        domains(language: "${langCode}", project: "${
          langCode === "es-mx" ? "greyhound" : "flixbus"
        }") {
          name
        }
      }`,
    }),
  });

  if (!res.ok) {
    const message = `An error has occured: ${res.status}`;
    throw new Error(message);
  }

  const items = await res.json();
  return items;
};
