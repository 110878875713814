@import "variables";
@import "../../helper/styles/styles.scss";

.scrollableColumn {
  @include scrollableColumn;
  top: $topHeaderHeight;
  z-index: 2;
}

.cell {
  // TODO: SHare the upper block in a mixin
  height: $bottomHeaderHeight;
  line-height: calc($bottomHeaderHeight / 2);
  border-right: $border-light;
  border-bottom: $border;
  background-color: $bg-primary-color;
  text-align: center;

  font-size: $font-size-small;
  line-height: $bottomHeaderHeight;

  &.thisMonth {
    border-bottom: 3px solid $ui-primary-color;
    color: $ui-primary-color;
  }
}
