import { CustomerServiceContactFormValue } from "../../types";

const getRowsWithSameDaySelected = (
  workingHours: CustomerServiceContactFormValue["workingHours"],
  currentIndex: number
) => {
  const rowsWithSameDaySelected: Array<number> = [];

  workingHours[currentIndex].workingDays.forEach((day) => {
    if (day && currentIndex < workingHours.length - 1) {
      let nextIndex = currentIndex + 1;

      while (nextIndex < workingHours.length) {
        const nextEntryFrom = workingHours[nextIndex].from;
        const nextEntryTo = workingHours[nextIndex].to;

        if (
          workingHours[nextIndex].workingDays.includes(day) &&
          nextEntryFrom &&
          nextEntryTo
        ) {
          rowsWithSameDaySelected.push(nextIndex);
        }
        nextIndex++;
      }
    }
  });

  return rowsWithSameDaySelected;
};

export default getRowsWithSameDaySelected;
