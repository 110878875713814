import { formatMessage } from "@flixbus-phx/marketplace-common";
import { CutOffSalesRule } from "../../../../../../shared/types/schema";

export enum InputType {
  HOUR,
  TIME,
}

export const cutOffSalesRuleOptions = () => [
  {
    value: CutOffSalesRule.BeforeDeparture,
    label: formatMessage("lineForm.cutOffSalesRule.beforeDeparture.option"),
  },
  {
    value: CutOffSalesRule.BeforeDayOfDeparture,
    label: formatMessage("lineForm.cutOffSalesRule.beforeDayOfDeparture.option"),
  },
];

export const getCutOffSalesRule = (rule: string): CutOffSalesRule => {
  if (rule === "BEFORE_DEPARTURE") {
    return CutOffSalesRule.BeforeDeparture;
  }

  if (rule === "BEFORE_DAY_OF_DEPARTURE") {
    return CutOffSalesRule.BeforeDayOfDeparture;
  }

  return CutOffSalesRule.None;
};

export const cutOffSalesTimeOptions = Array.from(Array(24), (_, i) => i).map((value) => {
  return {
    value: (24 - value).toString(),
    label: value.toString().padStart(2, "0").concat(":00"),
  };
});
