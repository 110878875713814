@import "../../helpers/styles/common-styles.scss";
@import "variables";

.textWrapper {
  display: flex;
  align-items: center;
}

.text {
  padding: 0 $spacing-2;
}

.textColorWhite {
  color: $grayscale-0-color;
}
