import { areIntervalsOverlapping, max, min } from "date-fns";
import { Interval } from "../../types";
import { mergeIntervals } from "../mergeIntervals/mergeIntervals";

export const getOverlapsForInterval = (
  potentialOverlappingIntervals: Array<Interval>,
  interval: Interval
) => {
  const overlaps: Array<Interval> = [];

  potentialOverlappingIntervals.forEach((potentialOverlap) => {
    if (areIntervalsOverlapping(interval, potentialOverlap)) {
      overlaps.push({
        start: max([interval.start, potentialOverlap.start]),
        end: min([interval.end, potentialOverlap.end]),
      });
    }
  });

  return overlaps;
};

export const getOverlapsForConceptChange = (
  potentialOverlappingIntervals: Array<Interval>,
  interval: Interval
) => {
  const overlappingIntervals = getOverlapsForInterval(
    potentialOverlappingIntervals,
    interval
  );

  return mergeIntervals(overlappingIntervals);
};
