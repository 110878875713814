@import "variables";

.wrapper {
  display: flex;
  flex-direction: row;
}

.labelWrapper {
  display: flex;
  align-items: center;
  margin-right: 20px;
  color: $content-secondary-color;
}

.isCategoryTitle {
  color: $content-primary-color;
  font-weight: $font-weight-semibold;
}
