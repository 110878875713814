import { formatMessage } from "@flixbus-phx/marketplace-common";
import { parse } from "date-fns";
import * as Yup from "yup";
import getTimeFormatString from "../../../../shared/helpers/timeFormatter/getTimeFormatString";
import getUserPreferredTimeFormat from "../../../../shared/helpers/userPreferredTimeFormat/userPreferredTimeFormat";
import { DayOfWeek } from "../../../../shared/types/schema";
import getIndexOfOverlappings from "../getIndexOfOverlappings/getIndexOfOverlappings";
import validateTransferOpeningHour from "../validateTransferOpeningHour/validateTransferOpeningHour";

export const openingHoursSchema = () =>
  Yup.array()
    .test({
      test(value) {
        if (value) {
          const hasEntries = value.some((val) => val.length > 0);

          if (!hasEntries) {
            return this.createError({
              message: formatMessage("error.validation.selectWeekday"),
            });
          }
        }

        return true;
      },
    })
    .of(
      Yup.array()
        .of(
          Yup.object().shape({
            weekday: Yup.string().oneOf(Object.values(DayOfWeek)),
            start: Yup.string(),
            end: Yup.string(),
          })
        )
        .test({
          test(value) {
            if (value && value.length > 0) {
              const timeFormat = getUserPreferredTimeFormat();

              let errors: Array<{ pathSuffix: string; message: string }> = [];

              value.forEach((openingHour, i) => {
                const validationErrors = validateTransferOpeningHour(
                  openingHour,
                  i,
                  timeFormat
                );

                if (validationErrors.length > 0) {
                  errors = errors.concat(validationErrors);
                }
              });

              if (!errors.length) {
                value.forEach((openingHour, i, arr) => {
                  const { start, end } = openingHour;

                  const formatString = getTimeFormatString(timeFormat);
                  const startDate = parse(start!, formatString, new Date(Date.now()));
                  const endDate = parse(end!, formatString, new Date(Date.now()));

                  getIndexOfOverlappings(i, startDate, endDate, arr, timeFormat).forEach(
                    (index) => {
                      errors.push({
                        pathSuffix: `[${index}].start`,
                        message: formatMessage("error.validation.timesOverlapping"),
                      });

                      errors.push({
                        pathSuffix: `[${index}].end`,
                        message: formatMessage("error.validation.timesOverlapping"),
                      });
                    }
                  );
                });
              }

              if (errors.length) {
                const formattedErrors = errors.map((error) => {
                  return this.createError({
                    path: `${this.path}${error.pathSuffix}`,
                    message: error.message,
                  });
                });

                return new Yup.ValidationError(formattedErrors);
              }
            }

            return true;
          },
        })
    );

export default () =>
  Yup.object().shape({
    name: Yup.string().required(formatMessage("error.validation.required")),
    timezone: Yup.string().required(formatMessage("station.timeZone.info")),
    code: Yup.string()
      .max(
        4,
        formatMessage("error.validation.stationCode.mustBeMoreThanX", { maxNumber: "4" })
      )
      .matches(
        /^[a-z0-9]+$/i,
        formatMessage("error.validation.stationCode.mustBeAlphanumeric")
      ),
    shortName: Yup.string(),
    latitude: Yup.string()
      .required(formatMessage("error.validation.required"))
      .test(
        "latitude test",
        formatMessage("error.validation.noValidLatitude"),
        (value) =>
          (value && !Number.isNaN(value) && Math.abs(parseInt(value, 10)) <= 90) ||
          value === undefined
      ),
    longitude: Yup.string()
      .required(formatMessage("error.validation.required"))
      .test(
        "longitude test",
        formatMessage("error.validation.noValidLongitude"),
        (value) =>
          (value && !Number.isNaN(value) && Math.abs(parseInt(value, 10)) <= 180) ||
          value === undefined
      ),
    countryCode: Yup.string()
      .required(formatMessage("error.validation.required"))
      .max(2, formatMessage("error.validation.noValidCountry")),
    cityId: Yup.string().required(formatMessage("error.validation.required")),
    addressLines: Yup.array().of(
      Yup.string().required(formatMessage("error.validation.required"))
    ),
    zipCode: Yup.string().required(formatMessage("error.validation.required")),
    addressCity: Yup.string().required(formatMessage("error.validation.required")),
    transferInformation: Yup.object().shape({
      isTransferStation: Yup.boolean().required(
        formatMessage("error.validation.required")
      ),
      minimumTransferMinutes: Yup.number().when("isTransferStation", {
        is: true,
        then: () =>
          Yup.number()
            .typeError(formatMessage("error.validation.mustBeNumber"))
            .integer(formatMessage("error.validation.numberNoDecimals"))
            .positive(formatMessage("error.validation.equalOrGreaterThanOne"))
            .max(
              360,
              formatMessage("error.validation.equalOrLessThanX", { value: "360" })
            )
            .required(formatMessage("error.validation.required")),
        otherwise: () =>
          Yup.lazy((value) => (value === "" ? Yup.string() : Yup.number())),
      }),
      transferOpeningHours: Yup.array().when("isTransferStation", {
        is: true,
        then: () => openingHoursSchema(),
        otherwise: () => Yup.array(),
      }),
    }),
  });
