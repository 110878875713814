// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// This file was automatically generated using npm run codegen
// If you want to alter this file please do so via the above mentioned command
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
import * as Types from "../../../shared/types/schema";

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
const defaultOptions = {} as const;
export type ScheduleDetailFragment = {
  __typename?: "Schedule";
  id: string;
  readOnly: boolean;
  period?: { __typename?: "SchedulePeriod"; start: any; end: any } | null;
  nonOperatingPeriods: Array<{ __typename?: "NonOperatingPeriod"; start: any; end: any }>;
  circulation?: { __typename?: "Circulation"; uuid: string } | null;
  line: {
    __typename?: "Line";
    id: string;
    partner: {
      __typename?: "Partner";
      id: string;
      circulations: Array<{
        __typename?: "Circulation";
        id: string;
        uuid: string;
        name: string;
        activeConfiguration?: {
          __typename?: "CirculationConfiguration";
          seatCapacity: number;
          bikeCapacity: number;
          wheelchairCapacity: number;
        } | null;
      }>;
    };
  };
  conceptChangeOf?: { __typename?: "Schedule"; id: string; name: string } | null;
  conceptChanges: Array<{
    __typename?: "Schedule";
    id: string;
    name: string;
    period?: { __typename?: "SchedulePeriod"; start: any; end: any } | null;
  }>;
};

export type ScheduleDetailQueryVariables = Types.Exact<{
  scheduleId: Types.Scalars["ID"]["input"];
}>;

export type ScheduleDetailQuery = {
  __typename?: "Query";
  findSchedule: {
    __typename?: "Schedule";
    id: string;
    readOnly: boolean;
    period?: { __typename?: "SchedulePeriod"; start: any; end: any } | null;
    nonOperatingPeriods: Array<{
      __typename?: "NonOperatingPeriod";
      start: any;
      end: any;
    }>;
    circulation?: { __typename?: "Circulation"; uuid: string } | null;
    line: {
      __typename?: "Line";
      id: string;
      partner: {
        __typename?: "Partner";
        id: string;
        circulations: Array<{
          __typename?: "Circulation";
          id: string;
          uuid: string;
          name: string;
          activeConfiguration?: {
            __typename?: "CirculationConfiguration";
            seatCapacity: number;
            bikeCapacity: number;
            wheelchairCapacity: number;
          } | null;
        }>;
      };
    };
    conceptChangeOf?: { __typename?: "Schedule"; id: string; name: string } | null;
    conceptChanges: Array<{
      __typename?: "Schedule";
      id: string;
      name: string;
      period?: { __typename?: "SchedulePeriod"; start: any; end: any } | null;
    }>;
  };
};

export type UpdateScheduleMutationVariables = Types.Exact<{
  id: Types.Scalars["ID"]["input"];
  period?: Types.InputMaybe<Types.SchedulePeriodInput>;
  circulationId?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  nonOperatingPeriods?: Types.InputMaybe<
    Array<Types.NonOperatingPeriodInput> | Types.NonOperatingPeriodInput
  >;
}>;

export type UpdateScheduleMutation = {
  __typename?: "Mutation";
  updateSchedule: {
    __typename?: "Schedule";
    id: string;
    readOnly: boolean;
    period?: { __typename?: "SchedulePeriod"; start: any; end: any } | null;
    nonOperatingPeriods: Array<{
      __typename?: "NonOperatingPeriod";
      start: any;
      end: any;
    }>;
    circulation?: { __typename?: "Circulation"; uuid: string } | null;
    line: {
      __typename?: "Line";
      id: string;
      partner: {
        __typename?: "Partner";
        id: string;
        circulations: Array<{
          __typename?: "Circulation";
          id: string;
          uuid: string;
          name: string;
          activeConfiguration?: {
            __typename?: "CirculationConfiguration";
            seatCapacity: number;
            bikeCapacity: number;
            wheelchairCapacity: number;
          } | null;
        }>;
      };
    };
    conceptChangeOf?: { __typename?: "Schedule"; id: string; name: string } | null;
    conceptChanges: Array<{
      __typename?: "Schedule";
      id: string;
      name: string;
      period?: { __typename?: "SchedulePeriod"; start: any; end: any } | null;
    }>;
  };
};

export const ScheduleDetailFragmentDoc = gql`
  fragment ScheduleDetail on Schedule {
    id
    period {
      start
      end
    }
    nonOperatingPeriods {
      start
      end
    }
    circulation {
      uuid
    }
    line {
      id
      partner {
        id
        circulations {
          id
          uuid
          name
          activeConfiguration {
            seatCapacity
            bikeCapacity
            wheelchairCapacity
          }
        }
      }
    }
    readOnly
    conceptChangeOf {
      id
      name
    }
    conceptChanges {
      id
      name
      period {
        start
        end
      }
    }
  }
`;
export const ScheduleDetailDocument = gql`
  query ScheduleDetail($scheduleId: ID!) {
    findSchedule(id: $scheduleId) {
      ...ScheduleDetail
    }
  }
  ${ScheduleDetailFragmentDoc}
`;

/**
 * __useScheduleDetailQuery__
 *
 * To run a query within a React component, call `useScheduleDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduleDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduleDetailQuery({
 *   variables: {
 *      scheduleId: // value for 'scheduleId'
 *   },
 * });
 */
export function useScheduleDetailQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ScheduleDetailQuery,
    ScheduleDetailQueryVariables
  > &
    ({ variables: ScheduleDetailQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ScheduleDetailQuery, ScheduleDetailQueryVariables>(
    ScheduleDetailDocument,
    options
  );
}
export function useScheduleDetailLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ScheduleDetailQuery,
    ScheduleDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ScheduleDetailQuery, ScheduleDetailQueryVariables>(
    ScheduleDetailDocument,
    options
  );
}
export function useScheduleDetailSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    ScheduleDetailQuery,
    ScheduleDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    ScheduleDetailQuery,
    ScheduleDetailQueryVariables
  >(ScheduleDetailDocument, options);
}
export type ScheduleDetailQueryHookResult = ReturnType<typeof useScheduleDetailQuery>;
export type ScheduleDetailLazyQueryHookResult = ReturnType<
  typeof useScheduleDetailLazyQuery
>;
export type ScheduleDetailSuspenseQueryHookResult = ReturnType<
  typeof useScheduleDetailSuspenseQuery
>;
export const UpdateScheduleDocument = gql`
  mutation UpdateSchedule(
    $id: ID!
    $period: SchedulePeriodInput
    $circulationId: String
    $nonOperatingPeriods: [NonOperatingPeriodInput!]
  ) {
    updateSchedule(
      id: $id
      period: $period
      circulationId: $circulationId
      nonOperatingPeriods: $nonOperatingPeriods
    ) {
      ...ScheduleDetail
    }
  }
  ${ScheduleDetailFragmentDoc}
`;

/**
 * __useUpdateScheduleMutation__
 *
 * To run a mutation, you first call `useUpdateScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScheduleMutation, { data, loading, error }] = useUpdateScheduleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      period: // value for 'period'
 *      circulationId: // value for 'circulationId'
 *      nonOperatingPeriods: // value for 'nonOperatingPeriods'
 *   },
 * });
 */
export function useUpdateScheduleMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateScheduleMutation,
    UpdateScheduleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateScheduleMutation,
    UpdateScheduleMutationVariables
  >(UpdateScheduleDocument, options);
}
export type UpdateScheduleMutationHookResult = ReturnType<
  typeof useUpdateScheduleMutation
>;
