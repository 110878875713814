@import "variables";

.formRow {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-bottom: $spacing-2;
}

.weekdayWrapper {
  width: 50px;
}

.weekday {
  color: $content-secondary-color;
}

.separator {
  margin-right: $spacing-2;
  margin-left: $spacing-2;
}

.iconsWrapper {
  display: flex;
  flex-direction: row;
}

.iconWrapper {
  margin-left: $spacing-2;
}

.icon {
  cursor: pointer;
}

.iconDelete {
  color: $danger-color;
}

.readOnlyValue {
  line-height: $spacing-6;
}
