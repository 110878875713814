import { DataTableHead } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage } from "react-intl";

export const lineTableHeaders = (
  <DataTableHead>
    <th>
      <FormattedMessage id="lineForm.lineCode.select.label" />
    </th>
    <th>
      <FormattedMessage id="general.description" />
    </th>
    <th>
      <FormattedMessage id="general.partner" />
    </th>
    <th>
      <FormattedMessage id="general.bizRegion" />
    </th>
    <th>
      <FormattedMessage id="lineForm.meansOfTransport.label" />
    </th>
  </DataTableHead>
);

export const partnerTableHeaders = (tagsPermission: Boolean) => (
  <DataTableHead>
    <th>
      <FormattedMessage id="partner.name" />
    </th>
    <th>
      <FormattedMessage id="general.bizRegion" />
    </th>
    <th>
      <FormattedMessage id="general.integrationType" />
    </th>
    {tagsPermission ? (
      <th>
        <FormattedMessage id="general.partnerTags.label" />
      </th>
    ) : (
      <></>
    )}
  </DataTableHead>
);
