import { Spinner } from "@flixbus/honeycomb-react";
import * as React from "react";
import * as css from "./StationDetailLoading.scss";

const StationDetailLoading: React.FC = () => {
  return (
    <div className={css.spinner}>
      <Spinner />
    </div>
  );
};

export default StationDetailLoading;
