@import "variables";

.text {
  margin-bottom: $spacing-3;
}

.box {
  max-height: 320px;
  overflow: scroll;
}

.errorRow {
  display: flex;
  column-gap: $spacing-4;
  text-align: left;
  margin-bottom: $spacing-2;
}

.tag {
  margin-top: $spacing-half;
}
