import * as React from "react";
import { useIntl } from "react-intl";
import FormikCheckbox from "../../../shared/components/formikCheckbox/FormikCheckbox";
import * as css from "../form_field_styles.scss";

const ApiPartnerFilter: React.FC = () => {
  const { formatMessage } = useIntl();
  return (
    <div>
      <div className={css.checkbox}>
        <FormikCheckbox
          label={formatMessage({ id: "general.integrationType.api" })}
          small
          name="apiPartner.api"
        />
      </div>
      <div className={css.checkbox}>
        <FormikCheckbox
          label={formatMessage({ id: "general.integrationType.manual" })}
          small
          name="apiPartner.manual"
        />
      </div>
    </div>
  );
};

export default ApiPartnerFilter;
