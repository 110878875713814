import { Tag } from "@flixbus/honeycomb-react";
import * as React from "react";
import { useIntl } from "react-intl";
import { StationStatus } from "../../../../shared/types/schema";

type Props = {
  status: StationStatus;
};

const StationStatusTag: React.FC<Props> = ({ status }) => {
  const { formatMessage } = useIntl();
  const stationStatusText = formatMessage({ id: `stationStatus.${status}` });

  switch (status) {
    case StationStatus.InReview:
      return (
        <Tag appearance="success" display="outlined" small>
          {stationStatusText}
        </Tag>
      );
    case StationStatus.Rejected:
      return (
        <Tag appearance="danger" small>
          {stationStatusText}
        </Tag>
      );
    case StationStatus.Approved:
      return (
        <Tag appearance="success" small>
          {stationStatusText}
        </Tag>
      );
    case StationStatus.Published:
      return (
        <Tag display="outlined" small>
          {stationStatusText}
        </Tag>
      );
    default:
      return <Tag small>{stationStatusText}</Tag>;
  }
};

export default StationStatusTag;
