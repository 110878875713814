import { Grid, GridCol, Fineprint, Tag, Tooltip } from "@flixbus/honeycomb-react";
import cx from "classnames";
import * as React from "react";
import { useIntl } from "react-intl";
import MeatballMenu from "../../../../shared/components/meatballMenu/MeatballMenu";
import TimeInput from "../../../../shared/components/timeInput/TimeInput";
import { TimeFormat } from "../../../../shared/components/timeInput/types";
import {
  convert12hTo24hFormat,
  convert24hTo12hFormat,
} from "../../../../shared/helpers/timeFormatter/timeConverter";
import { TripFragment } from "../../api/operations.generated";
import createTimezoneHint from "../../helpers/createTimezoneHint/createTimezoneHint";
import isValidTimeInput from "../../helpers/isValidTimeInput/isValidTimeInput";
import {
  HandleTripStationTimeDelete,
  HandleUpdateTripStationArrivalOffsetDays,
  HandleUpdateTripStationArrivalTime,
  HandleUpdateTripStationDepartureTime,
} from "../../types";
import OffsetWeekdaysLabel from "../offsetWeekdaysLabel/OffsetWeekdaysLabel";
import TimeToStationTag from "../timeToStationTag/TimeToStationTag";
import * as css from "./StationTimeCell.scss";

export type StationTimeCellProps = {
  stationTime: TripFragment["stationTimes"][number];
  disabled: boolean;
  onStationDepartureTimeChanged: HandleUpdateTripStationDepartureTime;
  onStationArrivalTimeChanged: HandleUpdateTripStationArrivalTime;
  onStationTimeDelete: HandleTripStationTimeDelete;
  onStationArrivalOffsetDaysChanged: HandleUpdateTripStationArrivalOffsetDays;
  showUtcOffset: boolean;
  timeFormat: TimeFormat;
  isCountryChange: boolean;
  isFirst: boolean;
  isLast: boolean;
  showArrivalWeekdaysOffset: boolean;
  showDepartureWeekdaysOffset: boolean;
};

const StationTimeCell: React.FC<StationTimeCellProps> = ({
  stationTime,
  disabled,
  onStationDepartureTimeChanged,
  onStationArrivalTimeChanged,
  onStationTimeDelete,
  onStationArrivalOffsetDaysChanged,
  showUtcOffset,
  timeFormat,
  isCountryChange,
  isFirst,
  isLast,
  showArrivalWeekdaysOffset,
  showDepartureWeekdaysOffset,
}) => {
  const { formatMessage } = useIntl();
  const handleArrivalTimeChanged = (time: string) => {
    if (stationTime.arrivalTime && time === "") {
      onStationTimeDelete(stationTime.station.id);
    } else if (isValidTimeInput(time, timeFormat, stationTime.arrivalTime)) {
      const convertedTime =
        timeFormat === TimeFormat.TWELVE_HOUR_CLOCK ? convert12hTo24hFormat(time) : time;

      onStationArrivalTimeChanged(stationTime.station.id, convertedTime);
    }
  };

  const handleArrivalOffsetDaysChanged = () => {
    if (stationTime.arrivalOffsetDays > 0) {
      onStationArrivalOffsetDaysChanged(stationTime.station.id, 0);
    } else {
      onStationArrivalOffsetDaysChanged(stationTime.station.id, 1);
    }
  };

  const handleDepartureTimeChanged = (time: string) => {
    if (time === "") {
      onStationTimeDelete(stationTime.station.id);
    } else if (isValidTimeInput(time, timeFormat, stationTime.departureTime)) {
      const convertedTime =
        timeFormat === TimeFormat.TWELVE_HOUR_CLOCK ? convert12hTo24hFormat(time) : time;

      onStationDepartureTimeChanged(stationTime.station.id, convertedTime);
    }
  };

  const getMeatballMenuContent = () => {
    if (stationTime.arrivalOffsetDays > 0) {
      return [
        {
          text: formatMessage({ id: "trip.time.menu.moveToPrevDay" }),
          action: () => handleArrivalOffsetDaysChanged(),
        },
      ];
    }

    return [
      {
        text: formatMessage({ id: "trip.time.menu.moveToNextDay" }),
        action: () => handleArrivalOffsetDaysChanged(),
      },
    ];
  };

  return (
    <>
      {stationTime.timeToStation && (
        <TimeToStationTag timeToStation={stationTime.timeToStation} />
      )}
      {!disabled && !isFirst && stationTime.arrivalTime && (
        <MeatballMenu content={getMeatballMenuContent()} />
      )}

      <div
        className={cx(
          css.container,
          isFirst || isLast ? css.stationIsFirstOrLast : "",
          isCountryChange ? css.stationIsCountryChange : ""
        )}
        data-id="station-times"
      >
        {!isFirst && (
          <Grid data-id="arrival">
            <GridCol size={4}>
              {showUtcOffset && (
                <div className={css.arrivalTimezone}>
                  <Tooltip
                    id="station-arrival-timezone-hint"
                    data-id="station-arrival-timezone-hint"
                    content={createTimezoneHint(stationTime.station.timeZone)}
                    extraClasses={css.tooltip}
                    position="top"
                    openOnHover
                  >
                    <Tag>{stationTime.station.timeZone.utcOffset}</Tag>
                  </Tooltip>
                </div>
              )}
            </GridCol>
            <GridCol size={5}>
              <div
                className={!isLast ? css.arrivalTime : ""}
                data-id="station-arrival-time"
              >
                <TimeInput
                  value={
                    stationTime.arrivalTime
                      ? timeFormat === TimeFormat.TWELVE_HOUR_CLOCK
                        ? convert24hTo12hFormat(stationTime.arrivalTime)
                        : stationTime.arrivalTime
                      : ""
                  }
                  onValueChanged={handleArrivalTimeChanged}
                  disabled={disabled}
                  timeFormat={timeFormat}
                />
              </div>
            </GridCol>
            <GridCol size={3}>
              {showArrivalWeekdaysOffset && (
                <OffsetWeekdaysLabel
                  offsetWeekdays={stationTime.arrivalOffsetWeekdays}
                  data-id="arrival-offset-weekdays"
                />
              )}
            </GridCol>
          </Grid>
        )}
        {(!isLast || isFirst) && (
          <Grid data-id="departure">
            <GridCol size={4}>
              {showUtcOffset && isFirst && (
                <div className={css.departureTimezone}>
                  <Tooltip
                    id="station-departure-timezone-hint"
                    data-id="station-departure-timezone-hint"
                    content={createTimezoneHint(stationTime.station.timeZone)}
                    extraClasses={cx(css.tooltip, css.tooltipOfFirst)}
                    position="bottom"
                    openOnHover
                  >
                    <Fineprint extraClasses={css.departureTimezoneText}>
                      {stationTime.station.timeZone.utcOffset}
                    </Fineprint>
                  </Tooltip>
                </div>
              )}
            </GridCol>
            <GridCol size={5}>
              <div data-id="station-departure-time">
                <TimeInput
                  value={
                    stationTime.departureTime
                      ? timeFormat === TimeFormat.TWELVE_HOUR_CLOCK
                        ? convert24hTo12hFormat(stationTime.departureTime)
                        : stationTime.departureTime
                      : ""
                  }
                  onValueChanged={handleDepartureTimeChanged}
                  disabled={disabled}
                  timeFormat={timeFormat}
                />
              </div>
            </GridCol>
            <GridCol size={3}>
              {showDepartureWeekdaysOffset && (
                <OffsetWeekdaysLabel
                  offsetWeekdays={stationTime.departureOffsetWeekdays}
                  data-id="departure-offset-weekdays"
                />
              )}
            </GridCol>
          </Grid>
        )}
      </div>
    </>
  );
};

export default StationTimeCell;
