import * as React from "react";
import { components, MultiValueProps as ReactSelectMultiValueProps } from "react-select";
import { SelectOption } from "../../../../helpers/reactSelect/types";
import MoreSelectedTag from "../moreSelectedTag/MoreSelectedTag";

export type MultiValueProps = ReactSelectMultiValueProps<SelectOption> & {
  maxToShow: number;
};

const MultiValue: React.FC<MultiValueProps> = (props) => {
  const { data, getValue, maxToShow } = props;

  const optionsSelected = getValue();

  const index = optionsSelected.findIndex((option) => option.value === data.value);

  const overflow = optionsSelected.length - maxToShow;

  return index < maxToShow ? (
    <components.MultiValue {...props} />
  ) : index === maxToShow ? (
    <MoreSelectedTag numberOfItems={overflow} />
  ) : null;
};

export default MultiValue;
