@import "variables";
@import "./helpers/styles/vars.scss";

.inputLabel {
  color: $content-secondary-color;
}

.icon {
  padding-right: $spacing-2;
  display: flex;
}

.info {
  position: relative;
  font-size: 14px;
  font-weight: 300;
  line-height: 17px;
  display: block;
  padding-top: 6px;
  color: $content-primary-color;
}

.error {
  color: $danger-dark-color;
}

.optionWithDescriptionWrapper {
  grid-template-columns: $label-column-width auto;
  @include option-with-description;
}

.optionWrapper {
  display: flex;
  align-items: center;
}

.singleValueWithDescriptionWrapper {
  grid-template-columns: $label-column-width 205px;
  @include option-with-description;
}

.singleValueLabel,
.optionLabel {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.multiWrapper {
  color: $content-secondary-color;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.multiIconsWrapper {
  display: flex;
  column-gap: $spacing-2;
}

.multiIcon {
  margin-right: $spacing-half;
}

.multiInfoWrapper {
  display: flex;
  column-gap: $spacing-half;
  font-size: 14px;
}
