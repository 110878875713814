import React__default__default from 'react';
import { useBrowser } from '../use-browser/use-browser.js';

/**
 * Adds a delay between toggling the `hidden` attribute and the `--active` modifier.
 * 250ms is the Panel transition time.
 * If they are set at the same time, the CSS transition doesn't happen.
 *
 * @param {boolean} active - indicates if the panel is active or not
 * @return {Array<boolean>} an array containing the `hidden` attribute and the `--active` modifier
 */
function useDelayedHidden(active) {
  var win = useBrowser()[0];
  var _a = React__default__default.useState(!active),
    hiddenAttr = _a[0],
    setHiddenAttr = _a[1];
  var _b = React__default__default.useState(active),
    activeModifier = _b[0],
    setActiveModifier = _b[1];
  React__default__default.useEffect(function () {
    if (active) {
      setHiddenAttr(false);
      win === null || win === void 0 ? void 0 : win.setTimeout(function () {
        setActiveModifier(true);
      }, 50);
    }
    if (!active) {
      win === null || win === void 0 ? void 0 : win.setTimeout(function () {
        setHiddenAttr(true);
      }, 250);
      setActiveModifier(false);
    }
  }, [active]);
  return [hiddenAttr, activeModifier];
}

export { useDelayedHidden };
