import { Button, PopupSection, Table } from "@flixbus/honeycomb-react";
import { localizeDateTime, Popup } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { FindScheduleStatusHistoryQuery } from "../../api/operations.generated";
import * as css from "./ScheduleStatusHistoryPopup.scss";

export type ScheduleStatusHistoryPopupProps = {
  statusHistoryData: FindScheduleStatusHistoryQuery["findSchedule"]["statusHistory"];
  active: boolean;
  onPopupClose: () => void;
};

const ScheduleStatusHistoryPopup: React.FC<ScheduleStatusHistoryPopupProps> = ({
  statusHistoryData,
  active,
  onPopupClose,
}) => {
  const { formatMessage } = useIntl();
  const tableHeaders = [
    formatMessage({ id: "general.status" }),
    formatMessage({ id: "general.date" }),
    formatMessage({ id: "general.description" }),
    formatMessage({ id: "general.user" }),
  ];
  const tableRows = statusHistoryData.map((data) => {
    const rowData = [];
    // Replace all occurences of '_' with a whitespace
    rowData.push(data.status.replace(/_/g, " "));
    rowData.push(localizeDateTime(data.created));
    rowData.push(data.description);
    rowData.push(data.createdBy);

    return rowData;
  });

  return (
    <Popup active={active} onClose={onPopupClose} isWide>
      <PopupSection type="title">
        <FormattedMessage id="scheduleActions.statusHistory.popup.title" />
      </PopupSection>
      <PopupSection type="content">
        <Table headers={tableHeaders} rows={tableRows} extraClasses={css.table} />
      </PopupSection>
      <PopupSection type="actions">
        <Button
          type="button"
          appearance="secondary"
          data-id="cancel-button"
          onClick={onPopupClose}
        >
          <FormattedMessage id="general.close" />
        </Button>
      </PopupSection>
    </Popup>
  );
};

export default ScheduleStatusHistoryPopup;
