import { Icon, IconArrowLeft, IconArrowRight } from "@flixbus/honeycomb-icons-react";
import { Button } from "@flixbus/honeycomb-react";
import { localizeMonthWithYear } from "@flixbus-phx/marketplace-common";
import { addMonths, addYears, getYear, subMonths, subYears } from "date-fns";
import * as React from "react";
import { CalendarIntervallType } from "../../types";
import * as css from "./ScheduleCalendarControl.scss";

export type ScheduleCalendarControlProps = {
  onPeriodChange: (date: Date) => void;
  selectedPeriod: Date;
  selectedIntervalType: CalendarIntervallType;
};

const ScheduleCalendarControl: React.FC<ScheduleCalendarControlProps> = ({
  onPeriodChange,
  selectedPeriod,
  selectedIntervalType,
}) => {
  return (
    <div className={css.buttonWrapper}>
      <Button
        extraClasses={css.buttonLeft}
        onClick={() => {
          if (selectedIntervalType === CalendarIntervallType.YEAR) {
            onPeriodChange(subYears(selectedPeriod, 1));
          } else {
            onPeriodChange(subMonths(selectedPeriod, 1));
          }
        }}
        display="square"
        aria-label="go back"
      >
        <Icon InlineIcon={IconArrowLeft} />
      </Button>
      {selectedIntervalType === CalendarIntervallType.YEAR
        ? getYear(selectedPeriod)
        : localizeMonthWithYear(selectedPeriod)}
      <Button
        extraClasses={css.buttonRight}
        onClick={() => {
          if (selectedIntervalType === CalendarIntervallType.YEAR) {
            onPeriodChange(addYears(selectedPeriod, 1));
          } else {
            onPeriodChange(addMonths(selectedPeriod, 1));
          }
        }}
        display="square"
        aria-label="go forward"
      >
        <Icon InlineIcon={IconArrowRight} />
      </Button>
    </div>
  );
};

export default ScheduleCalendarControl;
