@import "variables";

.headerWrapper {
  display: flex;
  justify-content: space-between;
}

.searchAddWrapper {
  display: flex;
  column-gap: $spacing-2;
}

.box {
  height: 144px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $content-secondary-color;
}
