import { useViewportContext } from "../../components/viewportProvider/ViewportProvider";
import Breakpoint from "../../helper/breakpoint/Breakpoint";

const useIsInRange = (range: Array<Breakpoint>) => {
  const width = useViewportContext();

  const getCurrentRange = () => {
    if (width !== undefined) {
      if (width < Breakpoint.XS) {
        return Breakpoint.XXS;
      }
      if (width < Breakpoint.SM) {
        return Breakpoint.XS;
      }
      if (width < Breakpoint.MD) {
        return Breakpoint.SM;
      }
      if (width < Breakpoint.LG) {
        return Breakpoint.MD;
      }
      if (width < Breakpoint.XL) {
        return Breakpoint.LG;
      }
      return Breakpoint.XL;
    }
    return undefined;
  };

  const currentRange = getCurrentRange();

  if (currentRange !== undefined) {
    return range.includes(currentRange);
  }

  return false;
};

export default useIsInRange;
