// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// This file was automatically generated using npm run codegen
// If you want to alter this file please do so via the above mentioned command
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
import * as Types from "../../shared/types/schema";

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
const defaultOptions = {} as const;
export type FilterPartnersQueryVariables = Types.Exact<{
  partnerFilterInput: Types.PartnerFilterInput;
}>;

export type FilterPartnersQuery = {
  __typename?: "Query";
  filterPartners: Array<{
    __typename?: "Partner";
    id: string;
    name: string;
    tags: Array<Types.PartnerTag>;
    businessRegion: { __typename?: "BusinessRegion"; id: string; name: string };
    apiSettings?: { __typename?: "ApiSettings"; apiPartner: boolean } | null;
  }>;
};

export type FilterLinesQueryVariables = Types.Exact<{
  filter: Types.LineFilterInput;
  pageSize: Types.PageSize;
  page: Types.Scalars["Int"]["input"];
}>;

export type FilterLinesQuery = {
  __typename?: "Query";
  filterLines: {
    __typename?: "PagedLineResult";
    total: number;
    items: Array<{
      __typename?: "Line";
      id: string;
      networkCode: string;
      networkDescription: string;
      meansOfTransport: Types.MeansOfTransport;
      partner: {
        __typename?: "Partner";
        name: string;
        businessRegion: { __typename?: "BusinessRegion"; name: string };
      };
    }>;
    pageInfo: {
      __typename?: "PageInfo";
      totalPages: number;
      currentPage: number;
      hasNext: boolean;
      hasPrevious: boolean;
    };
  };
};

export type FindAllBusinessRegionsForFiltersQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type FindAllBusinessRegionsForFiltersQuery = {
  __typename?: "Query";
  findAllBusinessRegions: Array<{
    __typename?: "BusinessRegion";
    id: string;
    name: string;
  }>;
};

export const FilterPartnersDocument = gql`
  query filterPartners($partnerFilterInput: PartnerFilterInput!) {
    filterPartners(partnerFilterInput: $partnerFilterInput) {
      id
      name
      businessRegion {
        id
        name
      }
      apiSettings {
        apiPartner
      }
      tags
    }
  }
`;

/**
 * __useFilterPartnersQuery__
 *
 * To run a query within a React component, call `useFilterPartnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilterPartnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilterPartnersQuery({
 *   variables: {
 *      partnerFilterInput: // value for 'partnerFilterInput'
 *   },
 * });
 */
export function useFilterPartnersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FilterPartnersQuery,
    FilterPartnersQueryVariables
  > &
    ({ variables: FilterPartnersQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<FilterPartnersQuery, FilterPartnersQueryVariables>(
    FilterPartnersDocument,
    options
  );
}
export function useFilterPartnersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FilterPartnersQuery,
    FilterPartnersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<FilterPartnersQuery, FilterPartnersQueryVariables>(
    FilterPartnersDocument,
    options
  );
}
export function useFilterPartnersSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    FilterPartnersQuery,
    FilterPartnersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    FilterPartnersQuery,
    FilterPartnersQueryVariables
  >(FilterPartnersDocument, options);
}
export type FilterPartnersQueryHookResult = ReturnType<typeof useFilterPartnersQuery>;
export type FilterPartnersLazyQueryHookResult = ReturnType<
  typeof useFilterPartnersLazyQuery
>;
export type FilterPartnersSuspenseQueryHookResult = ReturnType<
  typeof useFilterPartnersSuspenseQuery
>;
export const FilterLinesDocument = gql`
  query filterLines($filter: LineFilterInput!, $pageSize: PageSize!, $page: Int!) {
    filterLines(filter: $filter, pageRequest: { pageSize: $pageSize, page: $page }) {
      total
      items {
        id
        networkCode
        networkDescription
        partner {
          name
          businessRegion {
            name
          }
        }
        meansOfTransport
      }
      pageInfo {
        totalPages
        currentPage
        hasNext
        hasPrevious
      }
    }
  }
`;

/**
 * __useFilterLinesQuery__
 *
 * To run a query within a React component, call `useFilterLinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilterLinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilterLinesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pageSize: // value for 'pageSize'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useFilterLinesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FilterLinesQuery,
    FilterLinesQueryVariables
  > &
    ({ variables: FilterLinesQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<FilterLinesQuery, FilterLinesQueryVariables>(
    FilterLinesDocument,
    options
  );
}
export function useFilterLinesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FilterLinesQuery,
    FilterLinesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<FilterLinesQuery, FilterLinesQueryVariables>(
    FilterLinesDocument,
    options
  );
}
export function useFilterLinesSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    FilterLinesQuery,
    FilterLinesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<FilterLinesQuery, FilterLinesQueryVariables>(
    FilterLinesDocument,
    options
  );
}
export type FilterLinesQueryHookResult = ReturnType<typeof useFilterLinesQuery>;
export type FilterLinesLazyQueryHookResult = ReturnType<typeof useFilterLinesLazyQuery>;
export type FilterLinesSuspenseQueryHookResult = ReturnType<
  typeof useFilterLinesSuspenseQuery
>;
export const FindAllBusinessRegionsForFiltersDocument = gql`
  query FindAllBusinessRegionsForFilters {
    findAllBusinessRegions {
      id
      name
    }
  }
`;

/**
 * __useFindAllBusinessRegionsForFiltersQuery__
 *
 * To run a query within a React component, call `useFindAllBusinessRegionsForFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllBusinessRegionsForFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllBusinessRegionsForFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindAllBusinessRegionsForFiltersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FindAllBusinessRegionsForFiltersQuery,
    FindAllBusinessRegionsForFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FindAllBusinessRegionsForFiltersQuery,
    FindAllBusinessRegionsForFiltersQueryVariables
  >(FindAllBusinessRegionsForFiltersDocument, options);
}
export function useFindAllBusinessRegionsForFiltersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FindAllBusinessRegionsForFiltersQuery,
    FindAllBusinessRegionsForFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FindAllBusinessRegionsForFiltersQuery,
    FindAllBusinessRegionsForFiltersQueryVariables
  >(FindAllBusinessRegionsForFiltersDocument, options);
}
export function useFindAllBusinessRegionsForFiltersSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    FindAllBusinessRegionsForFiltersQuery,
    FindAllBusinessRegionsForFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    FindAllBusinessRegionsForFiltersQuery,
    FindAllBusinessRegionsForFiltersQueryVariables
  >(FindAllBusinessRegionsForFiltersDocument, options);
}
export type FindAllBusinessRegionsForFiltersQueryHookResult = ReturnType<
  typeof useFindAllBusinessRegionsForFiltersQuery
>;
export type FindAllBusinessRegionsForFiltersLazyQueryHookResult = ReturnType<
  typeof useFindAllBusinessRegionsForFiltersLazyQuery
>;
export type FindAllBusinessRegionsForFiltersSuspenseQueryHookResult = ReturnType<
  typeof useFindAllBusinessRegionsForFiltersSuspenseQuery
>;
