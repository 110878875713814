import { formatMessage } from "@flixbus-phx/marketplace-common";
import * as Yup from "yup";
import {
  CheckInInformation,
  InterconnectionRuleType,
  InventoryRestriction,
} from "../../../../shared/types/schema";

const operators = Yup.array().of(
  Yup.object().shape({
    uuid: Yup.string(),
    name: Yup.string(),
  })
);

export default () => {
  return Yup.object().shape({
    priceChangeAllowed: Yup.boolean(),
    priceDistribution: Yup.string(),
    externalInterconnectionPricingAllowed: Yup.boolean(),
    apiSettings: Yup.object().shape({
      apiPartner: Yup.boolean(),
      ticketSendout: Yup.object().shape({
        enabled: Yup.boolean(),
        checkInInformation: Yup.array().of(
          Yup.string().oneOf(Object.values(CheckInInformation))
        ),
      }),
    }),
    inventoryRule: Yup.object().shape({
      inventoryRestriction: Yup.string().oneOf(Object.values(InventoryRestriction)),
      interconnectionRule: Yup.object().shape({
        type: Yup.string().oneOf(Object.values(InterconnectionRuleType)),
        operators: Yup.array().when("type", {
          is: (rule: InterconnectionRuleType) =>
            [
              InterconnectionRuleType.Whitelist,
              InterconnectionRuleType.Blacklist,
            ].includes(rule),
          then: () =>
            operators.min(
              1,
              formatMessage("partnerForm.interconnectionRules.operator.add.error")
            ),
          otherwise: () => operators,
        }),
      }),
    }),
  });
};
