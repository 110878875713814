import { Button, List, Tooltip } from "@flixbus/honeycomb-react";
import * as React from "react";
import { Link } from "react-router-dom";
import buildPath from "../../../../routes/buildPath/buildPath";
import { Schedule } from "../../../../shared/types/schema";
import * as css from "./ScheduleCellTooltip.scss";

export type ScheduleCellTooltipProps = {
  schedules: Array<Pick<Schedule, "id" | "name">>;
  onToggle: (isOpen: boolean) => void;
};

/*
How its currently designed I dont see a way to make this work yet. This needs some more work.  
The problems are:
- The tooltip is cutoff by the box of the scroll container. I also havent looked into how to fix this yet.
  -> We could maybe solve this by creating a portal and render the tooltip outside of the scroll container. But then we would need to make sure that the tooltip is still positioned correctly

...Maybe with a clearer mind after my vacations I can find a better solution by making this whole hack with showind the schedule name for every chunk length cleaner
*/

const ScheduleCellTooltip: React.FC<ScheduleCellTooltipProps> = ({
  schedules,
  onToggle,
}) => {
  const tooltipContent = (
    <List>
      {schedules.map((schedule) => (
        <li key={schedule.id}>
          <Link to={buildPath.showScheduleDetail(schedules[0].id)}>{schedule.name}</Link>
        </li>
      ))}
    </List>
  );

  return (
    <div className={css.wrapper}>
      <Tooltip
        id="schedule-cell-tooltip"
        content={tooltipContent}
        hasClose
        closeBtn={{ "aria-label": "Close Tooltip" }}
        onToggle={onToggle}
      >
        <Button display="square" extraClasses={css.button}>
          +{schedules.length - 1}
        </Button>
      </Tooltip>
    </div>
  );
};

export default ScheduleCellTooltip;
