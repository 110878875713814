@import "variables";

.popupBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
}

.relation {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: $spacing-2;
}

.station {
  max-width: 45%;
}

.icon {
  padding: 0 $spacing-2;
}

.infoBox {
  margin-top: $spacing-4;
  margin-bottom: 0;
}
