import { parseDate } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { SchedulesOfLineQuery } from "../../api/operations.generated";
import getGridTemplateColumns from "../../helpers/getGridTemplateColumns/getGridTemplateColumns";
import getIntervalForPeriod from "../../helpers/getIntervalForPeriod/getIntervalForPeriod";
import getOverlapIntervals from "../../helpers/getOverlapIntervals/getOverlapIntervals";
import { getOverlapsForConceptChange } from "../../helpers/getOverlapsForConceptChange/getOverlapsForConceptChange";
import { mergeIntervals } from "../../helpers/mergeIntervals/mergeIntervals";
import { CalendarIntervallType } from "../../types";
import ScheduleCalendarScheduleRow from "../scheduleCalendarScheduleRow/ScheduleCalendarScheduleRow";
import * as css from "./ScheduleCalendarScheduleList.scss";

type Props = {
  originalSchedules: SchedulesOfLineQuery["findLine"]["schedules"];
  selectedIntervalType: CalendarIntervallType;
  selectedPeriod: Date;
};

const ScheduleCalendarScheduleList: React.FC<Props> = ({
  originalSchedules,
  selectedIntervalType,
  selectedPeriod,
}) => {
  const gridTemplateColumns = getGridTemplateColumns(
    getIntervalForPeriod(selectedPeriod, selectedIntervalType)
  );

  return (
    <>
      {originalSchedules.map((schedule) => {
        const overlapIntervals = getOverlapIntervals(schedule.conceptChanges);

        return (
          <React.Fragment key={schedule.id}>
            <div className={css.cell} style={gridTemplateColumns}>
              <ScheduleCalendarScheduleRow
                selectedPeriod={selectedPeriod}
                period={schedule.period}
                displayedScheduleStatus={schedule.displayedScheduleStatus}
                isOutdated={schedule.isOutdated}
                scheduleId={schedule.id}
                selectedIntervalType={selectedIntervalType}
                overlapIntervals={mergeIntervals(overlapIntervals)}
              />
            </div>
            {schedule.conceptChanges
              .sort((conceptChange1, conceptChange2) => {
                const conceptChange1PublishDate =
                  conceptChange1.publishedDate || "2999-12-31";
                const conceptChange2PublishDate =
                  conceptChange2.publishedDate || "2999-12-31";

                return (
                  Date.parse(conceptChange1PublishDate) -
                  Date.parse(conceptChange2PublishDate)
                );
              })
              .map((conceptChange, i, arr) => {
                const relevantConceptChanges = [...arr].slice(i + 1);
                const relevantOverlapIntervals =
                  getOverlapIntervals(relevantConceptChanges);

                return (
                  <div
                    key={conceptChange.id}
                    className={css.cell}
                    style={gridTemplateColumns}
                  >
                    <ScheduleCalendarScheduleRow
                      selectedPeriod={selectedPeriod}
                      period={conceptChange.period}
                      displayedScheduleStatus={conceptChange.displayedScheduleStatus}
                      isOutdated={conceptChange.isOutdated}
                      scheduleId={conceptChange.id}
                      selectedIntervalType={selectedIntervalType}
                      overlapIntervals={
                        conceptChange.period
                          ? getOverlapsForConceptChange(relevantOverlapIntervals, {
                              start: parseDate(conceptChange.period.start),
                              end: parseDate(conceptChange.period.end),
                            })
                          : []
                      }
                    />
                  </div>
                );
              })}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default ScheduleCalendarScheduleList;
