import { Icon, IconArrowRight, IconAttentionSolid } from "@flixbus/honeycomb-icons-react";
import { Box, Heading } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import useNearbyStations from "../../../../shared/hooks/useNearbyStations/useNearbyStations";
import NearbyStationPopup from "../../../nearbyStationPopup/NearbyStationPopup";
import * as css from "./NearbyStationsWarningBox.scss";

type Props = {
  latitude: string;
  longitude: string;
};

const NearbyStationsWarningBox: React.FC<Props> = ({ latitude, longitude }) => {
  const { formatMessage } = useIntl();

  const [nearbyStations, showNearbyStations, findNearbyStations, setShowNearbyStations] =
    useNearbyStations();

  return (
    <>
      <Box
        small
        appearance="danger"
        onClick={() => {
          findNearbyStations(latitude, longitude);
        }}
        extraClasses={css.container}
      >
        <Icon InlineIcon={IconAttentionSolid} extraClasses={css.icon} />
        <Heading flushSpace size={4} extraClasses={css.text}>
          <FormattedMessage id="nearbyStations.popup.title" />
        </Heading>
        <Icon InlineIcon={IconArrowRight} />
      </Box>

      {showNearbyStations && nearbyStations?.length && (
        <NearbyStationPopup
          stations={nearbyStations}
          onCancel={() => setShowNearbyStations(false)}
          confirmButtonText={formatMessage({
            id: "nearbyStations.popup.confirmButtonText.variationOne",
          })}
          confirmButtonLoading={false}
          latitude={latitude}
          longitude={longitude}
        />
      )}
    </>
  );
};

export default NearbyStationsWarningBox;
