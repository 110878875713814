import { Icon, IconUpload } from "@flixbus/honeycomb-icons-react";
import { Spinner } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import * as css from "./UploadButton.scss";

export type UploadButtonProps = {
  onUpload: (files: FileList | null) => void;
  fileIsUploading: boolean;
};

const UploadButton: React.FC<UploadButtonProps> = ({ onUpload, fileIsUploading }) => {
  const { formatMessage } = useIntl();

  if (fileIsUploading) {
    return (
      <div className={css.spinner}>
        <Spinner size="sm" />
        <FormattedMessage id="importPopup.uploadFile.spinner.text" />
      </div>
    );
  }
  return (
    <>
      <label className={css.button} htmlFor="fileToUpload">
        <Icon InlineIcon={IconUpload} />{" "}
        <FormattedMessage id="importPopup.uploadFile.button.text" />
      </label>
      <input
        className={css.input}
        type="file"
        name="fileToUpload"
        id="fileToUpload"
        accept="text/csv"
        onChange={({ target }) => onUpload(target.files)}
      />
      <div
        className={css.uploadInfo}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: formatMessage(
            { id: "importPopup.uploadFile.button.info" },
            {},
            { ignoreTag: true }
          ),
        }}
      />
    </>
  );
};

export default UploadButton;
