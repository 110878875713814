import { Icon, IconClose } from "@flixbus/honeycomb-icons-react";
import cx from "classnames";
import * as React from "react";
import * as css from "./Chip.scss";

export type ChipProps = {
  isHighlighted: boolean;
  onClick: () => void;
  onDelete?: () => void;
};

const Chip: React.FC<ChipProps> = ({ isHighlighted, onClick, onDelete, children }) => {
  return (
    <div className={cx(isHighlighted && css.highlighted, css.chip)} onClick={onClick}>
      {children}
      {onDelete && (
        <Icon
          InlineIcon={IconClose}
          size={3}
          onClick={onDelete}
          extraClasses={css.icon}
        />
      )}
    </div>
  );
};

export default Chip;
