import createRegex from "../../../../shared/helpers/autocompleteOptions/createRegex";
import sortOptionsByLabel from "../../../../shared/helpers/autocompleteOptions/sortOptionsByLabel";
import { SelectOption } from "../../../../shared/helpers/reactSelect/types";
import { LegalCompany } from "../../../../shared/types/schema";

export default (
  data: Array<Partial<LegalCompany>>,
  preFilterValue: string,
  selectedOption?: SelectOption
) => {
  const preFilterValueLowerCase = preFilterValue.toLocaleLowerCase();
  const regex = createRegex(preFilterValueLowerCase);

  const options: Array<SelectOption> = data.reduce(
    (result: Array<SelectOption>, partner) => {
      if (
        partner.concessionOwnerId &&
        (partner.name?.toLocaleLowerCase().match(regex) || []).length > 0
      ) {
        result.push({
          value: partner.concessionOwnerId,
          label: `${partner.name} - ${partner.concessionOwnerId}`,
        });
      }
      return result;
    },
    []
  );

  if (
    selectedOption &&
    options.find((option) => option.value === selectedOption.value) === undefined &&
    selectedOption.label.toLocaleLowerCase().includes(preFilterValue.toLocaleLowerCase())
  ) {
    options.push(selectedOption);
  }

  return sortOptionsByLabel(options);
};
