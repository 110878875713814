import { formatMessage } from "@flixbus-phx/marketplace-common";

const getErrorsForOverlappingTimes = (rowsWithError: Array<number>) => {
  const errors: Array<{ pathSuffix: string; message: string }> = [];

  rowsWithError.forEach((row) => {
    errors.push({
      pathSuffix: `[${row}].from`,
      message: formatMessage("error.validation.timesOverlapping"),
    });

    errors.push({
      pathSuffix: `[${row}].to`,
      message: formatMessage("error.validation.timesOverlapping"),
    });
  });

  return errors;
};

export default getErrorsForOverlappingTimes;
