import { formatMessage } from "@flixbus-phx/marketplace-common";
import * as Yup from "yup";

export default (minSeatCapacityRequired = false) => {
  let minSeatCapacitySchema: Yup.NumberSchema<number | undefined | null> = Yup.number()
    .integer(formatMessage("error.validation.numberNoDecimals"))
    .min(0, formatMessage("error.validation.equalOrGreaterThanZero"))
    .typeError(formatMessage("error.validation.mustBeNumber"));

  if (minSeatCapacityRequired) {
    minSeatCapacitySchema = minSeatCapacitySchema.required(
      formatMessage("error.validation.required")
    );
  } else {
    minSeatCapacitySchema = minSeatCapacitySchema
      .transform((value, originalValue) => (originalValue === "" ? null : value))
      .nullable();
  }

  return Yup.object({
    minSeatCapacity: minSeatCapacitySchema,
  });
};
