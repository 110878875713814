import { Input as HCInput } from "@flixbus/honeycomb-react";
import { FieldProps, FormikState } from "formik";
import * as React from "react";
import * as css from "./Input.scss";
import {
  isFormFieldValid,
  getFormFieldInfo,
} from "./helper/validateFormField/validateFormField";

type Props = {
  id?: string;
  field: FieldProps["field"];
  form: {
    touched: FormikState<any>["touched"];
    errors: FormikState<any>["errors"];
  };
  label?: string;
  ariaLabel?: string;
  type: "text" | "email";
  placeholder: string;
  required?: boolean;
  info?: string;
  extraClasses?: string;
  maxLength?: number;
  disabled?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
};

const randomNumber = Math.floor(Math.random() * 99999) + 1;
const defaultId = (name: string) => `${name}${randomNumber}`;

const Input: React.FC<Props> = ({
  id,
  field,
  form: { touched, errors },
  label,
  ariaLabel,
  type,
  placeholder,
  required,
  info,
  extraClasses,
  maxLength,
  onFocus,
  onBlur,
}) => {
  return (
    <HCInput
      {...field}
      id={`${id || defaultId(field.name)}`}
      name={field.name}
      valid={isFormFieldValid(touched[field.name], errors[field.name], field.value)}
      aria-label={ariaLabel || ""}
      type={type}
      placeholder={placeholder}
      required={required}
      info={getFormFieldInfo(touched[field.name], errors[field.name], info)}
      extraClasses={`${css.input} ${extraClasses}`}
      maxLength={maxLength}
      onFocus={onFocus}
      onBlur={onBlur}
      {...(label && { label: `${label}${required ? " *" : ""}` })}
    />
  );
};

export default Input;
