import { localizeMonthWithDay } from "@flixbus-phx/marketplace-common";
import { isFirstDayOfMonth, isLastDayOfMonth, isSameDay } from "date-fns";
import * as React from "react";
import calculatePeriodPosition from "../../helpers/calculatePeriodPosition/calculatePeriodPosition";
import { CalendarIntervallType } from "../../types";
import * as css from "./TagText.scss";

type Props = {
  startDate: Date;
  endDate: Date;
  selectedPeriod: Date;
  selectedIntervalType: CalendarIntervallType;
};

const TagText: React.FC<Props> = ({
  startDate,
  endDate,
  selectedPeriod,
  selectedIntervalType,
}) => {
  const [, startDateInPast, startDateInFuture] = calculatePeriodPosition(
    startDate,
    selectedPeriod,
    selectedIntervalType
  );

  const [, endDateInPast, endDateInFuture] = calculatePeriodPosition(
    endDate,
    selectedPeriod,
    selectedIntervalType
  );

  if (selectedIntervalType === CalendarIntervallType.YEAR) {
    if ((startDateInPast && endDateInPast) || (startDateInFuture && endDateInFuture)) {
      return <span>&hellip;</span>;
    }
    return (
      <span data-id="schedule-period">
        {`${localizeMonthWithDay(startDate)} - ${localizeMonthWithDay(endDate)}`}
      </span>
    );
  }

  if ((startDateInPast && endDateInPast) || (startDateInFuture && endDateInFuture)) {
    return <></>;
  }

  if (isSameDay(startDate, endDate)) {
    return <span className={css.oneDayTag}>&hellip;</span>;
  }

  if (
    !isSameDay(startDate, endDate) &&
    (isLastDayOfMonth(startDate) || isFirstDayOfMonth(endDate))
  ) {
    return <span>&hellip;</span>;
  }

  return (
    <span>{`${localizeMonthWithDay(startDate)} - ${localizeMonthWithDay(endDate)}`}</span>
  );
};

export default TagText;
