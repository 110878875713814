@import "variables";

.wrapper {
  padding-top: $spacing-1;
  padding-bottom: $spacing-2;
  height: 42px;
  color: $content-secondary-color;
}

.skeleton {
  width: 120px;
  height: 19px;
}
