import { __assign } from '../../node_modules/tslib/tslib.es6.js';
import { useState, useEffect } from 'react';

var TOGGLE_DELAY = 300;
/**
 * custom visibility toggling hook,
 * triggers active state with click, hover and arrow keys
 * @param options { initialActive, onToggle, content, hoverEvents, arrowEvents, delay }
 * @returns {[boolean, *, function(): void]}
 */
var useVisibilityToggler = function (_a) {
  var _b = _a.initialActive,
    initialActive = _b === void 0 ? false : _b,
    _c = _a.onToggle,
    onToggle = _c === void 0 ? undefined : _c,
    _d = _a.content,
    content = _d === void 0 ? undefined : _d,
    _e = _a.hoverEvents,
    hoverEvents = _e === void 0 ? false : _e,
    _f = _a.arrowEvents,
    arrowEvents = _f === void 0 ? false : _f,
    _g = _a.delay,
    delay = _g === void 0 ? TOGGLE_DELAY : _g;
  var currentTimeOut;
  var _h = useState(initialActive),
    isActive = _h[0],
    setIsActive = _h[1];
  useEffect(function () {
    setIsActive(initialActive);
  }, [initialActive]);
  var activate = function (event) {
    setIsActive(true);
    if (onToggle) {
      event === null || event === void 0 ? void 0 : event.persist();
      onToggle(true, event);
    }
  };
  var deactivate = function (event) {
    setIsActive(false);
    if (onToggle) {
      event === null || event === void 0 ? void 0 : event.persist();
      onToggle(false, event);
    }
  };
  var handleClick = function (event) {
    setIsActive(!isActive);
    if (onToggle) {
      event.persist();
      onToggle(!isActive, event);
    }
  };
  var handleKeyDown = function (event) {
    if (event.key === 'Enter' || event.key === ' ') {
      // avoids unwanted page scrolls and double call of pointers events on space and enter
      event.preventDefault();
      handleClick(event);
    }
    if (event.key === 'Escape') {
      deactivate(event);
    }
    if (arrowEvents && (event.key === 'ArrowDown' || event.key === 'ArrowUp')) {
      // avoids unwanted page scrolls
      event.preventDefault();
      activate(event);
    }
  };
  var handleMouseEnter = function (event) {
    event.persist();
    clearTimeout(currentTimeOut);
    currentTimeOut = setTimeout(function () {
      return activate(event);
    }, delay);
  };
  var handleMouseOut = function (event) {
    event.persist();
    var targetElement = event.relatedTarget && event.relatedTarget.nodeType ? event.relatedTarget : null;
    clearTimeout(currentTimeOut);
    if (!(content === null || content === void 0 ? void 0 : content.contains(targetElement))) {
      currentTimeOut = setTimeout(function () {
        return deactivate(event);
      }, delay);
    }
  };
  var BASIC_EVENTS = {
    onKeyDown: handleKeyDown,
    onClick: handleClick
  };
  var HOVER_EVENTS = {
    onMouseEnter: handleMouseEnter,
    // @todo this won't work with submenus if placed outside of the target link, rethink it
    // the mouse leaves the target when moving towards a sub menu
    onMouseLeave: handleMouseOut
  };
  var getHandlers = hoverEvents ? __assign(__assign({}, BASIC_EVENTS), HOVER_EVENTS) : BASIC_EVENTS;
  return [isActive, getHandlers, deactivate];
};

export { TOGGLE_DELAY, useVisibilityToggler };
