// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// This file was automatically generated using npm run codegen
// If you want to alter this file please do so via the above mentioned command
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
import * as Types from "../../../shared/types/schema";

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
const defaultOptions = {} as const;
export type FindAllBrandsForPartnerQueryVariables = Types.Exact<{
  id: Types.Scalars["ID"]["input"];
}>;

export type FindAllBrandsForPartnerQuery = {
  __typename?: "Query";
  findPartner: {
    __typename?: "Partner";
    id: string;
    brands: Array<{ __typename?: "Brand"; value: string; label: string }>;
  };
};

export const FindAllBrandsForPartnerDocument = gql`
  query findAllBrandsForPartner($id: ID!) {
    findPartner(id: $id) {
      id
      brands {
        value: id
        label: name
      }
    }
  }
`;

/**
 * __useFindAllBrandsForPartnerQuery__
 *
 * To run a query within a React component, call `useFindAllBrandsForPartnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllBrandsForPartnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllBrandsForPartnerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindAllBrandsForPartnerQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FindAllBrandsForPartnerQuery,
    FindAllBrandsForPartnerQueryVariables
  > &
    (
      | { variables: FindAllBrandsForPartnerQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FindAllBrandsForPartnerQuery,
    FindAllBrandsForPartnerQueryVariables
  >(FindAllBrandsForPartnerDocument, options);
}
export function useFindAllBrandsForPartnerLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FindAllBrandsForPartnerQuery,
    FindAllBrandsForPartnerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FindAllBrandsForPartnerQuery,
    FindAllBrandsForPartnerQueryVariables
  >(FindAllBrandsForPartnerDocument, options);
}
export function useFindAllBrandsForPartnerSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    FindAllBrandsForPartnerQuery,
    FindAllBrandsForPartnerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    FindAllBrandsForPartnerQuery,
    FindAllBrandsForPartnerQueryVariables
  >(FindAllBrandsForPartnerDocument, options);
}
export type FindAllBrandsForPartnerQueryHookResult = ReturnType<
  typeof useFindAllBrandsForPartnerQuery
>;
export type FindAllBrandsForPartnerLazyQueryHookResult = ReturnType<
  typeof useFindAllBrandsForPartnerLazyQuery
>;
export type FindAllBrandsForPartnerSuspenseQueryHookResult = ReturnType<
  typeof useFindAllBrandsForPartnerSuspenseQuery
>;
