import { getCookie } from "@flixbus-phx/marketplace-common";
import { TimeFormat } from "../../components/timeInput/types";
import {
  COOKIE_KEY_TIME_FORMAT,
  COOKIE_VALUE_TIME_FORMAT_12H,
} from "../handleCookies/cookies";

const getUserPreferredTimeFormat = () => {
  const cookie = getCookie(COOKIE_KEY_TIME_FORMAT);
  if (cookie === COOKIE_VALUE_TIME_FORMAT_12H) {
    return TimeFormat.TWELVE_HOUR_CLOCK;
  }
  return TimeFormat.TWENTY_FOUR_HOUR_CLOCK;
};

export default getUserPreferredTimeFormat;
