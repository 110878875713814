import sortOptionsByLabel from "../../../../shared/helpers/autocompleteOptions/sortOptionsByLabel";
import { SelectOption } from "../../../../shared/helpers/reactSelect/types";
import { Operator } from "../../../../shared/types/schema";

const getSelectableOperatorOptions = (
  allOperatorOptions: Array<SelectOption>,
  selectedOperators: Array<Operator>
) => {
  const filteredOptions = allOperatorOptions.filter(
    (option) =>
      selectedOperators.findIndex(
        (selectedOperator) => selectedOperator.uuid === option.value
      ) === -1
  );

  return sortOptionsByLabel(filteredOptions);
};

export default getSelectableOperatorOptions;
