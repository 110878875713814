import {
  debounce,
  DefaultInfoAlert,
  legacyTranslate,
} from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { createOptions } from "../../helpers/autocompleteOptions/createOptions";
import { SelectOption } from "../../helpers/reactSelect/types";
import { StationStatus } from "../../types/schema";
import Autocomplete from "../autocomplete/Autocomplete";
import CreateEntityPopup from "../createEntityPopup/CreateEntityPopup";
import * as css from "./StationSelect.scss";
import {
  SearchStationsAsOptionsQuery,
  useSearchStationsAsOptionsLazyQuery,
} from "./api/operations.generated";

const MIN_SEARCH_LENGTH = 3;
const TRIGGER_DEBOUNCE_MS = 200;

export type StationSelectProps = {
  label: string;
  onStationSelected: (
    id: SearchStationsAsOptionsQuery["searchStations"][number]["value"],
    data?: SearchStationsAsOptionsQuery["searchStations"][number]
  ) => void;
  onFocus?: () => void;
  disallowedValues?: Array<
    SearchStationsAsOptionsQuery["searchStations"][number]["value"]
  >;
  disallowedStates?: Array<StationStatus>;
  createStationPossible?: boolean;
  error?: boolean;
  errorMessage?: string;
  onStationCreateWithinPopup?: (inputValue?: string) => void;
};

const debounced = debounce((f: Function) => f(), TRIGGER_DEBOUNCE_MS);

const StationSelect: React.FC<StationSelectProps> = ({
  label,
  onStationSelected,
  onFocus = () => {},
  disallowedValues = [],
  disallowedStates = [],
  createStationPossible = true,
  error = false,
  errorMessage,
  onStationCreateWithinPopup,
}) => {
  const [stationFormActive, setStationFormActive] = React.useState(false);
  const [menuIsOpen, setMenuIsOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState<Array<SelectOption>>([]);
  const [searchLoading, setSearchLoading] = React.useState(false);
  const { formatMessage } = useIntl();

  const [triggerSearch, { data: queryData, error: queryError }] =
    useSearchStationsAsOptionsLazyQuery({
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        if (data) {
          let stations = data.searchStations;

          if (disallowedStates.length) {
            stations = data.searchStations.filter(
              (station) => !disallowedStates.includes(station.status)
            );
          }

          const newOptions = createOptions(stations, false).filter(
            (option) => !disallowedValues.includes(option.value)
          );
          setOptions(newOptions);
          setSearchLoading(false);
        }
      },
    });

  React.useEffect(() => {
    if (inputValue.length === 0) {
      setOptions([]);
    }
    const input = inputValue.trim();
    if (input.length >= MIN_SEARCH_LENGTH) {
      debounced(() => triggerSearch({ variables: { searchterm: input } }));
    }
  }, [inputValue]);

  if (queryError) {
    return (
      <DefaultInfoAlert message="Unable to load stations" translate={legacyTranslate} />
    );
  }

  return (
    <div className={css.container}>
      <Autocomplete
        label={label}
        options={options}
        placeholder={formatMessage({ id: "timetable.addStation.input.placeholder" })}
        onValueSelected={(selectedValue) => {
          const selectedStation = queryData?.searchStations.find(
            (station) => station.value === selectedValue.value
          );

          return onStationSelected(selectedValue.value, selectedStation);
        }}
        noOptionsAction={
          createStationPossible
            ? {
                action: () => {
                  if (onStationCreateWithinPopup) {
                    onStationCreateWithinPopup(inputValue);
                  } else {
                    setStationFormActive(true);
                  }
                },
                message: formatMessage({ id: "station.create" }),
              }
            : undefined
        }
        noOptionsInfo={
          createStationPossible ? undefined : (
            <div className={css.noOptionInfo}>
              <FormattedMessage id="stationSelect.info.noStationFound" />
            </div>
          )
        }
        optionsLoading={searchLoading}
        onInputChange={(value: string) => {
          if (value.length >= MIN_SEARCH_LENGTH) {
            setSearchLoading(true);
          }

          setInputValue(value);
        }}
        startsOnInputValueLength={createStationPossible ? 0 : 3}
        onFocus={() => {
          onFocus();

          if (createStationPossible) {
            setMenuIsOpen(true);
          }
        }}
        onBlur={() => {
          setMenuIsOpen(false);
        }}
        blurInputOnSelect
        menuIsOpen={menuIsOpen}
        clearInputOnFocus
        error={error}
        errorMessage={errorMessage}
      />
      {stationFormActive && (
        <CreateEntityPopup
          onEntityCreated={(stationId) => {
            onStationSelected(stationId);
          }}
          onClose={() => {
            setStationFormActive(false);
          }}
          inputValue={inputValue}
          type="station"
        />
      )}
    </div>
  );
};

export default StationSelect;
