@import "variables";

.checklist {
  height: 192px;
  overflow: scroll;
}

.input {
  margin-bottom: 9px;
}

.textMessage {
  font-size: $font-size-small;
  text-align: center;
  display: flex;
  align-items: center;
  height: 100%;
  color: $content-secondary-color;
}
