import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import FormikCheckbox from "../../../shared/components/formikCheckbox/FormikCheckbox";
import * as checkBoxCss from "../form_field_styles.scss";
import * as css from "./AutoOnSaleConfigFilter.scss";

const AutoOnSaleConfigFilter: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <div>
      <div data-id="headline">
        <FormattedMessage id="partnerForm.autoOnSaleConfig.normalSchedule" />
      </div>
      <div className={checkBoxCss.checkbox}>
        <FormikCheckbox
          label={formatMessage({ id: "general.activated" })}
          small
          name="autoOnSaleConfig.normalSchedule.activated"
        />
      </div>
      <div className={checkBoxCss.checkbox}>
        <FormikCheckbox
          label={formatMessage({ id: "general.deactivated" })}
          small
          name="autoOnSaleConfig.normalSchedule.deactivated"
        />
      </div>
      <div data-id="headline" className={css.secondHeadline}>
        <FormattedMessage id="partnerForm.autoOnSaleConfig.conceptChangeSchedule" />
      </div>
      <div className={checkBoxCss.checkbox}>
        <FormikCheckbox
          label={formatMessage({ id: "general.activated" })}
          small
          name="autoOnSaleConfig.conceptChangeSchedule.activated"
        />
      </div>
      <div className={checkBoxCss.checkbox}>
        <FormikCheckbox
          label={formatMessage({ id: "general.deactivated" })}
          small
          name="autoOnSaleConfig.conceptChangeSchedule.deactivated"
        />
      </div>
    </div>
  );
};

export default AutoOnSaleConfigFilter;
