import { DataTableHead } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage } from "react-intl";

export const stationsTableHeader = (
  <DataTableHead>
    <th>
      <FormattedMessage id="general.name" />
    </th>
    <th>
      <FormattedMessage id="station.code" />
    </th>
    <th>
      <FormattedMessage id="general.city" />
    </th>
    <th>
      <FormattedMessage id="general.country" />
    </th>
    <th>
      <FormattedMessage id="general.updated" />
    </th>
    <th>
      <FormattedMessage id="general.status" />
    </th>
    <th>
      <FormattedMessage id="general.warning" />
    </th>
    <th>
      <FormattedMessage id="general.action" />
    </th>
  </DataTableHead>
);

export const citiesTableHeaders = (
  <DataTableHead>
    <th>
      <FormattedMessage id="general.name" />
    </th>
    <th>
      <FormattedMessage id="general.country" />
    </th>
    <th>
      <FormattedMessage id="city.importanceClass" />
    </th>
    <th>
      <FormattedMessage id="general.published" />
    </th>
  </DataTableHead>
);
