import { Skeleton } from "@flixbus/honeycomb-react";
import * as React from "react";
import Layout from "../../../../ui/Layout";
import * as css from "./ScheduleTabSkeleton.scss";

const ScheduleTabSkeleton: React.FC = () => {
  return (
    <Layout smallTopPadding>
      <div className={css.outerWrapper}>
        <div className={css.skeletonOuterWrapper}>
          <div className={css.skeletonInnerWrapperLeft}>
            <Skeleton height="sm" flushBottom />
          </div>
        </div>
        <div className={css.skeletonOuterWrapper}>
          <div className={css.skeletonInnerWrapperRight}>
            <Skeleton height="sm" flushBottom />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ScheduleTabSkeleton;
