import { getToken, buildAuthorizationHeader } from "@flixbus-phx/marketplace-common";
import importUrl from "../importUrl/importUrl";
import {
  Validation200,
  Validation400,
  Validation400ErrorReasons,
  Validation422,
  ValidationResponseTypes,
} from "../typeguards/typeguards";

export const validationPostRequest = async (
  file: File,
  isStation: boolean
): Promise<ValidationResponseTypes> => {
  const token = await getToken();

  const data = new FormData();
  data.append("file", file);

  return fetch(`${importUrl(isStation)}/validate`, {
    method: "POST",
    headers: {
      ...buildAuthorizationHeader(token ? token.idToken : "").headers,
    },
    body: data,
  }).then((res) => {
    if (res.ok) {
      return res.json() as unknown as Validation200;
    }

    if (res.status === 422) {
      return res.json() as unknown as Validation422;
    }

    return [Validation400ErrorReasons.FAILED_ON_SERVER] as Validation400;
  });
};

export const importPostRequest = async (
  file: File,
  isStation: boolean
): Promise<Validation422 | Validation400 | Response> => {
  const token = await getToken();

  const data = new FormData();
  data.append("file", file);

  return fetch(`${importUrl(isStation)}/import`, {
    method: "POST",
    headers: {
      ...buildAuthorizationHeader(token ? token.idToken : "").headers,
    },
    body: data,
  }).then((res) => {
    if (res.ok) {
      return res;
    }

    if (res.status === 422) {
      return res.json() as unknown as Validation422;
    }

    return [Validation400ErrorReasons.FAILED_ON_SERVER] as Validation400;
  });
};
