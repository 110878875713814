import { ReactiveVar, useReactiveVar } from "@apollo/client";

const usePriceInvalidForSchedule = (
  priceInvalidForScheduleVar: ReactiveVar<string>
): [string, (scheduleId: string) => void] => {
  const priceInvalidForSchedule = useReactiveVar(priceInvalidForScheduleVar);

  const setPriceInvalidForSchedule = (scheduleId: string) => {
    priceInvalidForScheduleVar(scheduleId);
  };

  return [priceInvalidForSchedule, setPriceInvalidForSchedule];
};

export default usePriceInvalidForSchedule;
