@import "variables";

.wrapper {
  display: flex;
  flex-wrap: wrap;
  column-gap: 9px;
  row-gap: 9px;
  align-items: center;
}

.addPeriodButton {
  color: $success-dark-color;
  font-weight: 500;
}
