const getCookieDomain = () => {
  if (process.env.NODE_ENV === "production") {
    return ".marketplace.flixbus.com";
  }

  if (process.env.NODE_ENV === "staging") {
    return ".marketplace-dev.flixbus.com";
  }

  if (process.env.NODE_ENV === "test") {
    return "www.example.com";
  }

  return "localhost";
};

export const setCookie = (cname: string, cvalue: string, exdays?: number): void => {
  const expires = exdays
    ? `expires=${new Date(Date.now() + exdays * 24 * 60 * 60 * 1000).toUTCString()}`
    : "";

  document.cookie = `${cname}=${cvalue};${expires};path=/;domain=${getCookieDomain()}`;
};

export const getCookie = (cname: string): string => {
  const name = `${cname}=`;
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};
