import * as React from "react";
import CountryList from "../../country/countryList/CountryList";
import Layout from "../../ui/Layout";

const ShowCountries: React.FC = () => {
  return (
    <Layout>
      <CountryList />
    </Layout>
  );
};

export default ShowCountries;
