import { Icon, IconArrowLeft, IconArrowRight } from "@flixbus/honeycomb-icons-react";
import { Button, Input } from "@flixbus/honeycomb-react";
import { debounce } from "@flixbus-phx/marketplace-common";
import cx from "classnames";
import * as React from "react";
import isValidPagerInput from "../../helpers/isValidPagerInput/isValidPagerInput";
import * as css from "./PagerQuantity.scss";

export type PagerProps = {
  totalPages?: number;
  currentPage?: number;
  hasPrevious?: boolean;
  hasNext?: boolean;
  setPage: (page: number) => void;
};

const debounced = debounce((f: Function) => f(), 200);

const PagerQuantity: React.FC<PagerProps> = ({
  totalPages = 1,
  currentPage = 0,
  hasPrevious = false,
  hasNext = false,
  setPage = () => {},
}) => {
  const [value, setQuantityValue] = React.useState<number | undefined>(currentPage + 1);

  const setPageValue = (val: number) => {
    if (val > totalPages) {
      setPage(totalPages - 1);
    } else if (val !== currentPage + 1 && val > 0) {
      setPage(val - 1);
    }
  };

  const setPageValueOnClick = (val?: number) => {
    const newValue = val || currentPage + 1;

    setQuantityValue(newValue);

    if (newValue !== currentPage + 1 && newValue > 0) {
      debounced(() => setPage(newValue - 1));
    }
  };

  const setValue = (val: string) => {
    const parsedVal = parseInt(val, 10);

    if (Number.isNaN(parsedVal)) {
      setQuantityValue(currentPage + 1);
    }

    if (parsedVal > totalPages) {
      setQuantityValue(totalPages);
    }

    setPageValue(parsedVal);
  };

  return (
    <div className={css.pagerNavigation}>
      <Button
        data-id="minus-button"
        display="square"
        extraClasses={cx(css.button, css.buttonLeft)}
        onClick={() => setPageValueOnClick(value && value - 1)}
        disabled={value === 1 || !hasPrevious}
      >
        <Icon InlineIcon={IconArrowLeft} />
      </Button>
      <Input
        id="paginated-table-quantity"
        aria-label="page"
        extraClasses={css.input}
        value={value || ""}
        onChange={(event) => {
          const { value: val } = event.target;

          if (isValidPagerInput(val)) {
            if (val === "") {
              setQuantityValue(undefined);
            } else {
              setQuantityValue(parseInt(val, 10));
            }
          }
        }}
        onBlur={({ currentTarget }) => setValue(currentTarget.value)}
        onKeyDown={({ currentTarget, key }: React.KeyboardEvent<HTMLInputElement>) => {
          if (key === "Enter") {
            setValue(currentTarget.value);
          }
        }}
      />
      <Button
        data-id="plus-button"
        display="square"
        extraClasses={cx(css.button, css.buttonRight)}
        onClick={() => setPageValueOnClick(value && value + 1)}
        disabled={value === totalPages || !hasNext}
      >
        <Icon InlineIcon={IconArrowRight} />
      </Button>
    </div>
  );
};

export default PagerQuantity;
