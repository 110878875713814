@import "variables";
@import "../../styles/inline-edit.scss";

.flexBox {
  align-items: center;
  @include flex-box;
}

.inputFlex {
  @include input-flex;
}

.notEditableWrapper {
  @include not-editable-wrapper;

  > .notEditableEditIcon,
  .readOnlyEditIcon {
    line-height: 26px;
  }
}

.notEditableWrapperLoading {
  @include not-editable-wrapper-loading;
}

.notEditableText {
  @include not-editable-text;
}

.notEditableEditIcon {
  @include not-editable-edit-icon;
}

.readOnlyEditIcon {
  @include readOnly-edit-icon;
}

.readOnlyText {
  @include readOnly-text;
}

.input {
  @include input;
}

.cancelButton {
  @include cancel-button;
}

.cancelButton + .submitButton {
  @include cancel-submit-button;
}

.submitButton {
  @include submit-button;
}

.buttonPosition {
  position: relative;
  top: 26px;
  align-self: flex-start;

  @media (max-width: 1200px) {
    right: 0;
  }
}
