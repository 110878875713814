import { Button, Heading } from "@flixbus/honeycomb-react";
import img from "@flixbus-phx/marketplace-common/assets/illustrations/error.svg";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import * as css from "./DataTableError.scss";

const DataTableError: React.FC = () => {
  return (
    <div className={css.errorPageContainer}>
      <img src={img} alt="" height="200px" />
      <Heading extraClasses={css.errorPageText} size={3}>
        <FormattedMessage id="error.500.description.top" />
      </Heading>
      <Button extraClasses={css.errorPageButton} onClick={window.location.reload}>
        <FormattedMessage id="retry.button.text" />
      </Button>
    </div>
  );
};

export default DataTableError;
