import { Fieldset, Grid, GridCol, Heading, Radio } from "@flixbus/honeycomb-react";
import { useField } from "formik2";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import CustomFormRow from "../../../../shared/components/customFormRow/CustomFormRow";
import { InterconnectionRuleType, Operator } from "../../../../shared/types/schema";
import NoMobilityPartnerText from "../noMobilityPartnerText/NoMobilityPartnerText";
import OperatorSelect from "../operatorSelect/OperatorSelect";
import * as css from "./InterconnectionRuleSelect.scss";

type Props = {
  hasMobilityPartner?: boolean;
};

const InterconnectionRuleSelect: React.FC<Props> = ({ hasMobilityPartner }) => {
  const { formatMessage } = useIntl();
  const [, metaRule, helperRule] = useField<InterconnectionRuleType>(
    "inventoryRule.interconnectionRule.type"
  );
  const [, , helperOperator] = useField<Array<Operator>>(
    "inventoryRule.interconnectionRule.operators"
  );

  const handleRadioButtonSelect = (option: InterconnectionRuleType) => {
    if (metaRule.value !== option) {
      helperRule.setValue(option);
      helperOperator.setValue([]);
    }
  };

  const radioOptions = [
    {
      label: formatMessage({ id: "partnerForm.interconnectionRules.option.none" }),
      value: InterconnectionRuleType.None,
    },
    {
      label: formatMessage({
        id: "partnerForm.interconnectionRules.option.allowWithSpecific",
      }),
      value: InterconnectionRuleType.Whitelist,
    },
    {
      label: formatMessage({ id: "partnerForm.interconnectionRules.forbidWithSpecific" }),
      value: InterconnectionRuleType.Blacklist,
    },
  ];

  return (
    <>
      <Heading size={4}>
        <FormattedMessage id="partnerForm.interconnectionRules.label" />
      </Heading>
      {hasMobilityPartner ? (
        <CustomFormRow>
          <Fieldset itemSpacing="2">
            {radioOptions.map((option) => {
              return (
                <div key={`interconnection-rule-radio-${option.value}`}>
                  <Radio
                    id={`interconnection-rule-${option.value}`}
                    name="interconnection-rule"
                    label={option.label}
                    value={option.value}
                    key={`interconnection-rule-${option.value}`}
                    onClick={() => handleRadioButtonSelect(option.value)}
                    defaultChecked={metaRule.value === option.value}
                  />
                  {[
                    InterconnectionRuleType.Whitelist,
                    InterconnectionRuleType.Blacklist,
                  ].includes(option.value) &&
                    metaRule.value === option.value && (
                      <Grid>
                        <GridCol size={5}>
                          <div className={css.wrapper}>
                            <OperatorSelect
                              isWhitelist={
                                option.value === InterconnectionRuleType.Whitelist
                              }
                            />
                          </div>
                        </GridCol>
                      </Grid>
                    )}
                </div>
              );
            })}
          </Fieldset>
        </CustomFormRow>
      ) : (
        <NoMobilityPartnerText />
      )}
    </>
  );
};

export default InterconnectionRuleSelect;
