// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// This file was automatically generated using npm run codegen
// If you want to alter this file please do so via the above mentioned command
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
import * as Types from "../../../shared/types/schema";

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
const defaultOptions = {} as const;
export type ForbiddenRelationDetailFragment = {
  __typename?: "ForbiddenRelation";
  from: { __typename?: "Station"; id: string; code?: string | null; name: string };
  to: { __typename?: "Station"; id: string; code?: string | null; name: string };
};

export type LineDetailFragment = {
  __typename?: "Line";
  id: string;
  networkCode: string;
  networkDescription: string;
  minSeatCapacity: number;
  readOnly: boolean;
  cooperationType: Types.CooperationType;
  deletable: boolean;
  meansOfTransport: Types.MeansOfTransport;
  cutOffSales: {
    __typename?: "CutOffSales";
    cutOffSalesDurationInMinutes: number;
    cutOffSalesRule: Types.CutOffSalesRule;
  };
  defaultCirculation?: { __typename?: "Circulation"; uuid: string } | null;
  partner: {
    __typename?: "Partner";
    id: string;
    brands: Array<{ __typename?: "Brand"; id: string; canBeRemovedFromPartner: boolean }>;
    circulations: Array<{
      __typename?: "Circulation";
      id: string;
      uuid: string;
      name: string;
      activeConfiguration?: {
        __typename?: "CirculationConfiguration";
        seatCapacity: number;
        bikeCapacity: number;
        wheelchairCapacity: number;
      } | null;
    }>;
  };
  schedules: Array<{ __typename?: "Schedule"; id: string; status: Types.ScheduleStatus }>;
  brand?: { __typename?: "Brand"; id: string; name: string } | null;
  amenities: Array<{
    __typename?: "Amenity";
    amenityType: Types.AmenityType;
    highlighted: boolean;
  }>;
  forbiddenRelations: Array<{
    __typename?: "ForbiddenRelation";
    from: { __typename?: "Station"; id: string; code?: string | null; name: string };
    to: { __typename?: "Station"; id: string; code?: string | null; name: string };
  }>;
};

export type LineDetailsQueryVariables = Types.Exact<{
  lineId: Types.Scalars["ID"]["input"];
}>;

export type LineDetailsQuery = {
  __typename?: "Query";
  findLine: {
    __typename?: "Line";
    id: string;
    networkCode: string;
    networkDescription: string;
    minSeatCapacity: number;
    readOnly: boolean;
    cooperationType: Types.CooperationType;
    deletable: boolean;
    meansOfTransport: Types.MeansOfTransport;
    cutOffSales: {
      __typename?: "CutOffSales";
      cutOffSalesDurationInMinutes: number;
      cutOffSalesRule: Types.CutOffSalesRule;
    };
    defaultCirculation?: { __typename?: "Circulation"; uuid: string } | null;
    partner: {
      __typename?: "Partner";
      id: string;
      brands: Array<{
        __typename?: "Brand";
        id: string;
        canBeRemovedFromPartner: boolean;
      }>;
      circulations: Array<{
        __typename?: "Circulation";
        id: string;
        uuid: string;
        name: string;
        activeConfiguration?: {
          __typename?: "CirculationConfiguration";
          seatCapacity: number;
          bikeCapacity: number;
          wheelchairCapacity: number;
        } | null;
      }>;
    };
    schedules: Array<{
      __typename?: "Schedule";
      id: string;
      status: Types.ScheduleStatus;
    }>;
    brand?: { __typename?: "Brand"; id: string; name: string } | null;
    amenities: Array<{
      __typename?: "Amenity";
      amenityType: Types.AmenityType;
      highlighted: boolean;
    }>;
    forbiddenRelations: Array<{
      __typename?: "ForbiddenRelation";
      from: { __typename?: "Station"; id: string; code?: string | null; name: string };
      to: { __typename?: "Station"; id: string; code?: string | null; name: string };
    }>;
  };
};

export type UpdateLineMutationVariables = Types.Exact<{
  id: Types.Scalars["ID"]["input"];
  generalSettings?: Types.InputMaybe<Types.GeneralSettingsInput>;
  capacityAndAmenities?: Types.InputMaybe<Types.CapacityAndAmenitiesInput>;
  forbiddenRelations?: Types.InputMaybe<
    Array<Types.ForbiddenRelationInput> | Types.ForbiddenRelationInput
  >;
}>;

export type UpdateLineMutation = {
  __typename?: "Mutation";
  updateLine: {
    __typename?: "Line";
    id: string;
    networkCode: string;
    networkDescription: string;
    minSeatCapacity: number;
    readOnly: boolean;
    cooperationType: Types.CooperationType;
    deletable: boolean;
    meansOfTransport: Types.MeansOfTransport;
    cutOffSales: {
      __typename?: "CutOffSales";
      cutOffSalesDurationInMinutes: number;
      cutOffSalesRule: Types.CutOffSalesRule;
    };
    defaultCirculation?: { __typename?: "Circulation"; uuid: string } | null;
    partner: {
      __typename?: "Partner";
      id: string;
      brands: Array<{
        __typename?: "Brand";
        id: string;
        canBeRemovedFromPartner: boolean;
      }>;
      circulations: Array<{
        __typename?: "Circulation";
        id: string;
        uuid: string;
        name: string;
        activeConfiguration?: {
          __typename?: "CirculationConfiguration";
          seatCapacity: number;
          bikeCapacity: number;
          wheelchairCapacity: number;
        } | null;
      }>;
    };
    schedules: Array<{
      __typename?: "Schedule";
      id: string;
      status: Types.ScheduleStatus;
    }>;
    brand?: { __typename?: "Brand"; id: string; name: string } | null;
    amenities: Array<{
      __typename?: "Amenity";
      amenityType: Types.AmenityType;
      highlighted: boolean;
    }>;
    forbiddenRelations: Array<{
      __typename?: "ForbiddenRelation";
      from: { __typename?: "Station"; id: string; code?: string | null; name: string };
      to: { __typename?: "Station"; id: string; code?: string | null; name: string };
    }>;
  };
};

export type DeleteLineMutationVariables = Types.Exact<{
  id: Types.Scalars["ID"]["input"];
}>;

export type DeleteLineMutation = { __typename?: "Mutation"; deleteLine: boolean };

export const ForbiddenRelationDetailFragmentDoc = gql`
  fragment ForbiddenRelationDetail on ForbiddenRelation {
    from {
      id
      code
      name
    }
    to {
      id
      code
      name
    }
  }
`;
export const LineDetailFragmentDoc = gql`
  fragment LineDetail on Line {
    id
    networkCode
    networkDescription
    minSeatCapacity
    readOnly
    cutOffSales {
      cutOffSalesDurationInMinutes
      cutOffSalesRule
    }
    cooperationType
    defaultCirculation {
      uuid
    }
    partner {
      id
      brands {
        id
        canBeRemovedFromPartner
      }
      circulations {
        id
        uuid
        name
        activeConfiguration {
          seatCapacity
          bikeCapacity
          wheelchairCapacity
        }
      }
    }
    schedules {
      id
      status
    }
    brand {
      id
      name
    }
    amenities {
      amenityType
      highlighted
    }
    forbiddenRelations {
      ...ForbiddenRelationDetail
    }
    deletable
    meansOfTransport
  }
  ${ForbiddenRelationDetailFragmentDoc}
`;
export const LineDetailsDocument = gql`
  query LineDetails($lineId: ID!) {
    findLine(id: $lineId) {
      ...LineDetail
    }
  }
  ${LineDetailFragmentDoc}
`;

/**
 * __useLineDetailsQuery__
 *
 * To run a query within a React component, call `useLineDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLineDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLineDetailsQuery({
 *   variables: {
 *      lineId: // value for 'lineId'
 *   },
 * });
 */
export function useLineDetailsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    LineDetailsQuery,
    LineDetailsQueryVariables
  > &
    ({ variables: LineDetailsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<LineDetailsQuery, LineDetailsQueryVariables>(
    LineDetailsDocument,
    options
  );
}
export function useLineDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LineDetailsQuery,
    LineDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<LineDetailsQuery, LineDetailsQueryVariables>(
    LineDetailsDocument,
    options
  );
}
export function useLineDetailsSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    LineDetailsQuery,
    LineDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<LineDetailsQuery, LineDetailsQueryVariables>(
    LineDetailsDocument,
    options
  );
}
export type LineDetailsQueryHookResult = ReturnType<typeof useLineDetailsQuery>;
export type LineDetailsLazyQueryHookResult = ReturnType<typeof useLineDetailsLazyQuery>;
export type LineDetailsSuspenseQueryHookResult = ReturnType<
  typeof useLineDetailsSuspenseQuery
>;
export const UpdateLineDocument = gql`
  mutation UpdateLine(
    $id: ID!
    $generalSettings: GeneralSettingsInput
    $capacityAndAmenities: CapacityAndAmenitiesInput
    $forbiddenRelations: [ForbiddenRelationInput!]
  ) {
    updateLine(
      id: $id
      generalSettings: $generalSettings
      capacityAndAmenities: $capacityAndAmenities
      forbiddenRelations: $forbiddenRelations
    ) {
      ...LineDetail
    }
  }
  ${LineDetailFragmentDoc}
`;

/**
 * __useUpdateLineMutation__
 *
 * To run a mutation, you first call `useUpdateLineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLineMutation, { data, loading, error }] = useUpdateLineMutation({
 *   variables: {
 *      id: // value for 'id'
 *      generalSettings: // value for 'generalSettings'
 *      capacityAndAmenities: // value for 'capacityAndAmenities'
 *      forbiddenRelations: // value for 'forbiddenRelations'
 *   },
 * });
 */
export function useUpdateLineMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateLineMutation,
    UpdateLineMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateLineMutation, UpdateLineMutationVariables>(
    UpdateLineDocument,
    options
  );
}
export type UpdateLineMutationHookResult = ReturnType<typeof useUpdateLineMutation>;
export const DeleteLineDocument = gql`
  mutation DeleteLine($id: ID!) {
    deleteLine(id: $id)
  }
`;

/**
 * __useDeleteLineMutation__
 *
 * To run a mutation, you first call `useDeleteLineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLineMutation, { data, loading, error }] = useDeleteLineMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLineMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteLineMutation,
    DeleteLineMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteLineMutation, DeleteLineMutationVariables>(
    DeleteLineDocument,
    options
  );
}
export type DeleteLineMutationHookResult = ReturnType<typeof useDeleteLineMutation>;
