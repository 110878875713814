@import "variables";

.background {
  background-color: $bg-secondary-color;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  @media (min-width: 1000px) {
    flex-direction: row;
  }
}

.headline {
  font-size: 35px;
  padding-bottom: $spacing-4;
  @media (min-width: 1000px) {
    font-size: 60px;
    padding-bottom: $spacing-6;
  }
}

.imageContainer {
  margin-bottom: $spacing-6;

  @media (min-width: 1000px) {
    margin-right: $spacing-6;
    margin-bottom: 0px;
  }

  > img {
    width: 300px;
    @media (min-width: 1000px) {
      width: 100%;
    }
  }
}

.textContainer {
  @media (min-width: 1000px) {
    margin-left: $spacing-6;
  }
}

.descriptionText {
  color: $content-secondary-color;

  li {
    color: $content-secondary-color !important;
  }

  @media (min-width: 1000px) {
    margin-bottom: $spacing-4;
  }
}
