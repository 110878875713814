import { makeVar } from "@apollo/client";
import {
  AccessPackage,
  ApiSettings,
  BusinessRegion,
  MeansOfTransport,
  AutoOnSaleConfig,
  PartnerTag,
} from "../../shared/types/schema";

export const defaultPartnerFilterValues = {
  searchTerm: null,
  businessRegions: null,
  apiPartner: null,
  meansOfTransport: null,
  accessPackage: null,
  businessDeveloper: null,
  autoOnSaleConfig: {
    normalSchedule: null,
    conceptChangeSchedule: null,
  },
  partnerTags: null,
};

export type PartnerFilterVar = {
  searchTerm: string | null;
  businessRegions: Array<BusinessRegion["id"]> | null;
  apiPartner: Array<ApiSettings["apiPartner"]> | null;
  meansOfTransport: Array<MeansOfTransport | null> | null;
  partnerTags: Array<PartnerTag> | null;
  accessPackage: Array<AccessPackage> | null;
  businessDeveloper: string | null;
  autoOnSaleConfig: {
    normalSchedule: Array<AutoOnSaleConfig["normalSchedule"]> | null;
    conceptChangeSchedule: Array<AutoOnSaleConfig["conceptChangeSchedule"]> | null;
  };
};

const partnerFilterVar = makeVar<PartnerFilterVar>(defaultPartnerFilterValues);

export default partnerFilterVar;
