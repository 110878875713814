import { Icon, IconDelete } from "@flixbus/honeycomb-icons-react";
import { Button } from "@flixbus/honeycomb-react";
import cx from "classnames";
import * as React from "react";
import { useIntl } from "react-intl";
import FormikInput from "../../../../shared/components/formikInput/FormikInput";
import * as css from "./BDInputRow.scss";

export type BDInputRowProps = {
  hasLabel: boolean;
  index: 0 | 1;
  onRemoveRow: () => void;
  showRemoveRow: boolean;
  readOnly: boolean;
};

const BDInputRow: React.FC<BDInputRowProps> = ({
  hasLabel,
  index,
  onRemoveRow,
  showRemoveRow,
  readOnly,
}) => {
  const { formatMessage } = useIntl();
  return (
    <li className={css.listItem}>
      <div className={css.grid}>
        <div>
          <FormikInput
            label={formatMessage({ id: "general.name" })}
            hideLabel={!hasLabel}
            name={`businessDevelopers[${index}].name`}
            type="text"
            required
            readOnly={readOnly}
          />
        </div>
        <div>
          <FormikInput
            label={formatMessage({ id: "general.email" })}
            hideLabel={!hasLabel}
            name={`businessDevelopers[${index}].email`}
            type="email"
            required
            readOnly={readOnly}
          />
        </div>
        <div className={cx(index === 0 ? css.buttonWrapperFirstRow : "")}>
          {showRemoveRow && !readOnly && (
            <Button display="square" onClick={onRemoveRow}>
              <Icon InlineIcon={IconDelete} />
            </Button>
          )}
        </div>
      </div>
    </li>
  );
};

export default BDInputRow;
