import { Icon, IconAttentionSolid } from "@flixbus/honeycomb-icons-react";
import * as React from "react";
import { useIntl } from "react-intl";
import useNearbyStations from "../../../../shared/hooks/useNearbyStations/useNearbyStations";
import { StationStatus, Station } from "../../../../shared/types/schema";
import { selectedStationVar } from "../../../../state/reactiveVariables/reactiveVariables";
import useSelectedStation from "../../../../state/useSelectedStation/useSelectedStation";
import NearbyStationPopup from "../../../nearbyStationPopup/NearbyStationPopup";
import * as css from "./WarningIcon.scss";

type Props = {
  stationId: Station["id"];
  status: StationStatus;
  hasNearbyStations?: Station["hasNearbyStation"];
  latitude?: string | null;
  longitude?: string | null;
};

const WarningIcon: React.FC<Props> = ({
  stationId,
  status,
  hasNearbyStations,
  latitude,
  longitude,
}) => {
  const { formatMessage } = useIntl();

  const [nearbyStations, showNearbyStations, findNearbyStations, setShowNearbyStations] =
    useNearbyStations();

  const [, changeSelectedStation] = useSelectedStation(selectedStationVar);

  if (
    [StationStatus.InReview, StationStatus.Rejected].includes(status) &&
    hasNearbyStations &&
    latitude &&
    longitude
  ) {
    return (
      <>
        <Icon
          appearance="primary"
          InlineIcon={IconAttentionSolid}
          size={4}
          extraClasses={css.icon}
          onClick={() => findNearbyStations(latitude, longitude)}
        />
        {showNearbyStations && nearbyStations?.length && (
          <NearbyStationPopup
            stations={nearbyStations}
            onSubmit={() => {
              setShowNearbyStations(false);
              changeSelectedStation(stationId);
            }}
            onCancel={() => setShowNearbyStations(false)}
            confirmButtonText={formatMessage({
              id: "nearbyStations.popup.confirmButtonText.variationOne",
            })}
            confirmButtonLoading={false}
            latitude={latitude}
            longitude={longitude}
          />
        )}
      </>
    );
  }

  return <></>;
};

export default WarningIcon;
