import * as React from "react";
import * as css from "./WarningTagText.scss";

type WarningTagTextProps = {
  text: string;
};

const WarningTagText: React.FC<WarningTagTextProps> = ({ text }) => {
  return <span className={css.textColor}>{text}</span>;
};

export default WarningTagText;
