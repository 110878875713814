import { ReactiveVar, useReactiveVar } from "@apollo/client";

const usePricesInEditMode = (
  pricesInEditModeVar: ReactiveVar<boolean>
): [boolean, (isPriceInEditMode: boolean) => void] => {
  const pricesInEditMode = useReactiveVar(pricesInEditModeVar);

  const setPricesInEditMode = (isPriceInEditMode: boolean) => {
    pricesInEditModeVar(isPriceInEditMode);
  };

  return [pricesInEditMode, setPricesInEditMode];
};

export default usePricesInEditMode;
