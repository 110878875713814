export enum NotificationType {
  Danger = "danger",
  Warning = "warning",
  Success = "success",
}

export type NotificationObject = {
  id: string;
  type?: NotificationType;
  message: string | JSX.Element;
  age: number;
  isPermanent?: boolean;
};

export type UpdateNotificationsArgs = {
  type?: NotificationType;
  message: string | JSX.Element;
  isPermanent?: boolean;
};

export type DeleteNotificationsArgs = {
  id: string;
};
