import * as React from "react";
import { TranslateFunctionProp } from "../../helper";
import formatErrorMessage from "../../helper/formatErrorMessage/formatErrorMessage";
import ContactUsLink from "../contactUsLink/ContactUsLink";
import InfoAlert from "../infoAlert/InfoAlert";

type Props = {
  message: string;
  type?: "warning" | "danger" | "info";
};

const DefaultInfoAlert: React.FC<Props & TranslateFunctionProp> = ({
  message,
  type = "danger",
  translate,
}) => {
  return (
    <InfoAlert type={type}>
      <>
        {`${formatErrorMessage(message)}. ${translate("general.info.tryAgain")} `}
        <ContactUsLink translate={translate} />
      </>
    </InfoAlert>
  );
};

export default DefaultInfoAlert;
