import { Heading, Spinner } from "@flixbus/honeycomb-react";
import * as React from "react";
import { useFindAllCountriesQuery } from "./api/operations.generated";
import CountryListBreadcrumbs from "./ui/countryListBreadcrumbs/CountryListBreadcrumbs";
import CountryListTable from "./ui/countryListTable/CountryListTable";

const CountryList: React.FC = () => {
  const { data, loading } = useFindAllCountriesQuery();

  const headSection = (
    <>
      <CountryListBreadcrumbs />
      <Heading size={1}>Cities/Stations Approver</Heading>
    </>
  );

  if (loading) {
    return (
      <>
        {headSection}
        <Spinner />
      </>
    );
  }

  if (data?.findAllCountries) {
    return (
      <>
        {headSection}
        <CountryListTable countries={data.findAllCountries} />
      </>
    );
  }

  return <></>;
};

export default CountryList;
