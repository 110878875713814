import { PolicyInput, PolicyType } from "../../../../shared/types/schema";
import { PoliciesFormValues } from "../../types";
import getSubmittableValuesForPolicy from "../getSubmittableValuesForPolicy/getSubmittableValuesForPolicy";

const getSubmittableValues = (
  values: PoliciesFormValues["policies"]
): Array<PolicyInput> => {
  if (values.length === 1 && values[0].type === PolicyType.Both) {
    return [getSubmittableValuesForPolicy(values[0])];
  }

  if (
    values.length === 2 &&
    values[0].type === PolicyType.Domestic &&
    values[1].type === PolicyType.International
  ) {
    return values.map((value) => getSubmittableValuesForPolicy(value));
  }

  return [];
};

export default getSubmittableValues;
