import { parseDate } from "@flixbus-phx/marketplace-common";
import { ConceptChangeFromLineQuery } from "../../types";

export default (conceptChanges: Array<ConceptChangeFromLineQuery>) => {
  return conceptChanges.reduce(
    (total: Array<{ start: Date; end: Date }>, currentValue) => {
      if (!currentValue.publishedDate) {
        return [...total];
      }

      if (!currentValue.period) {
        return [...total];
      }

      return [
        ...total,
        {
          start: parseDate(currentValue.period.start),
          end: parseDate(currentValue.period.end),
        },
      ];
    },
    []
  );
};
