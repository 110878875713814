import { Icon, IconInfo } from "@flixbus/honeycomb-icons-react";
import * as React from "react";
import * as css from "./InfoHint.scss";

type Props = {
  text: string;
};

const InfoHint: React.FC<Props> = ({ text }) => {
  return (
    <>
      <Icon extraClasses={css.infoIcon} InlineIcon={IconInfo} size={6} />
      <p className={css.infoText}>{text}</p>
    </>
  );
};

export default InfoHint;
