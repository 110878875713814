import {
  Icon,
  IconCheckmark,
  IconStar,
  IconStarSolid,
} from "@flixbus/honeycomb-icons-react";
import { Checkbox } from "@flixbus/honeycomb-react";
import cx from "classnames";
import * as React from "react";
import {
  getLabelText,
  getLabelIcon,
} from "../../../../shared/helpers/getLabelForAmenity/getLabelForAmenity";
import { AmenityType } from "../../../../shared/types/schema";
import * as css from "./AmenitiesSelectorRow.scss";

export type AmenitiesSelectorRowProps = {
  amenity: AmenityType;
  isSelected: boolean;
  isHighlighted: boolean;
  hideHighlightIcon: boolean;
  onSelect: (select: boolean) => void;
  onHighlight: (highlight: boolean) => void;
  readOnly?: boolean;
};

const AmenitiesSelectorRow: React.FC<AmenitiesSelectorRowProps> = ({
  amenity,
  isSelected,
  isHighlighted,
  hideHighlightIcon,
  onSelect,
  onHighlight,
  readOnly,
}) => {
  const handleSelect = () => {
    if (!readOnly) {
      onSelect(!isSelected);
    }
  };

  const handleHighlight = () => {
    if (!readOnly) {
      onHighlight(!isHighlighted);
    }
  };

  return (
    <div className={cx(css.row, readOnly && css.disabled)}>
      {readOnly ? (
        <div className={css.readOnlyCheckBoxWrapper}>
          {isSelected && (
            <Icon
              InlineIcon={IconCheckmark}
              size={4}
              appearance="secondary"
              data-id="chechmark-icon"
            />
          )}
        </div>
      ) : (
        <Checkbox
          aria-label={amenity}
          id={amenity}
          value={amenity}
          extraClasses={css.checkbox}
          checked={isSelected}
          onChange={handleSelect}
        />
      )}
      <div className={css.label} onClick={handleSelect}>
        <Icon InlineIcon={getLabelIcon(amenity)} size={4} extraClasses={css.icon} />
        &nbsp;
        <span className={css.labelText}>{getLabelText(amenity)}</span>
      </div>
      <div className={css.iconWrapper} onClick={handleHighlight}>
        {isHighlighted ? (
          <Icon
            InlineIcon={IconStarSolid}
            size={4}
            extraClasses={cx(css.icon, css.highlightedIcon)}
          />
        ) : (
          <>
            {!hideHighlightIcon && !readOnly && (
              <Icon InlineIcon={IconStar} size={4} extraClasses={css.icon} />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AmenitiesSelectorRow;
