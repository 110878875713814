import { Grid, GridCol, Skeleton } from "@flixbus/honeycomb-react";
import * as React from "react";
import * as css from "./ScheduleHeaderSkeleton.scss";

const ScheduleHeaderSkeleton: React.FC = () => {
  return (
    <Grid>
      <GridCol size={11}>
        <Skeleton height="lg" extraClasses={css.nameSkeleton} />
      </GridCol>
      <GridCol size={1}>
        <Skeleton height="lg" extraClasses={css.buttonSkeleton} />
      </GridCol>
    </Grid>
  );
};

export default ScheduleHeaderSkeleton;
