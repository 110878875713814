import generateNotificationId from "./generateNotificationId";
import notificationsVar from "./reactiveVariable";
import { NotificationObject, UpdateNotificationsArgs } from "./types";

const addNotification = (variables: UpdateNotificationsArgs) => {
  const notifications = notificationsVar();

  const newNotification: NotificationObject = {
    id: generateNotificationId(),
    type: variables.type,
    message: variables.message,
    age: Date.now(),
    isPermanent: variables.isPermanent,
  };

  notificationsVar([...notifications, newNotification]);
};

export default addNotification;
