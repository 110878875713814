import * as React from "react";
import { useIntl } from "react-intl";
import Select from "../../../../shared/components/select/Select";
import getCurrenciesAsSelectOptions from "../../../../shared/helpers/getCurrenciesAsSelectOptions/getCurrenciesAsSelectOptions";
import { Currency } from "../../../../shared/types/schema";
import * as css from "./CurrencySelect.scss";

export type CurrencySelectProps = {
  value: Currency;
  onSelect: (currency: Currency) => void;
};

const CurrencySelect: React.FC<CurrencySelectProps> = ({ onSelect, value }) => {
  const { formatMessage } = useIntl();
  const getCurrency = (currency: string): Currency => {
    // @ts-ignore
    const key: [string, Currency] = Object.entries(Currency).find(
      ([, v]) => v === currency
    );
    return key[1];
  };

  return (
    <div className={css.wrapper}>
      <Select
        label={formatMessage({ id: "prices.currency.select.label" })}
        initialValue={{ value, label: value }}
        options={getCurrenciesAsSelectOptions()}
        onSelect={(selectedValue) => {
          onSelect(getCurrency(selectedValue.value));
        }}
      />
    </div>
  );
};

export default CurrencySelect;
