@import "variables";

.tagContainer {
  margin-bottom: $spacing-3;
}

.stationActions {
  margin-top: $spacing-4;
}

.rejectionReason {
  margin-top: $spacing-2;
  margin-bottom: $spacing-3;
}

.alternativeStationWrapper {
  display: flex;
  flex-wrap: wrap;
}

.alternativeStationLabel {
  margin-left: $spacing-half;
}

.linkWrapper {
  display: flex;
  align-items: center;
}

.newTabIcon {
  margin-left: $spacing-half;
}
