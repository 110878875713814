@import "src/shared/styles/variables";

.col {
  border-radius: $border-radius;
  border: $border;
  background-color: $bg-primary-color;
}

.cell {
  &:first-child {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    overflow: hidden;
  }
  &:last-child {
    border-bottom: none;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
