import { Button, Grid, GridCol, NavHorizontal, NavItem } from "@flixbus/honeycomb-react";
import { Form, Formik } from "formik2";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import {
  PolicyInput as PolicyInputType,
  PolicyUnitsInput,
} from "../../shared/types/schema";
import * as css from "./PoliciesForm.scss";
import getInitialValues from "./helpers/getInitialValues/getInitialValues";
import getSubmittableValues from "./helpers/getSubmittableValues/getSubmittableValues";
import validationSchema from "./helpers/validationSchema/validationSchema";
import { PoliciesDefaultValues } from "./types";
import PoliciesDifferentiationSwitch from "./ui/policiesDifferentiationSwitch/PoliciesDifferentiationSwitch";
import PolicyInput from "./ui/policyInput/PolicyInput";
import UnitsForm from "./ui/unitsForm/UnitsForm";

export type PoliciesFormProps = {
  defaultValues?: PoliciesDefaultValues;
  onSubmit: (values: {
    policyUnits: PolicyUnitsInput;
    policies: Array<PolicyInputType>;
  }) => void;
  onClose: () => void;
  isSubmitLoading?: boolean;
  readOnly: boolean;
};

const PoliciesForm: React.FC<PoliciesFormProps> = ({
  defaultValues,
  onSubmit,
  onClose,
  isSubmitLoading,
  readOnly,
}) => {
  const [showDomestic, setShowDomestic] = React.useState(true);

  const initialValues = getInitialValues(defaultValues);

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={(values) => {
        onSubmit({
          policyUnits: { ...values.units },
          policies: getSubmittableValues(values.policies),
        });
      }}
      onReset={onClose}
    >
      {({ dirty, values }) => {
        return (
          <Form noValidate className={css.wrapper}>
            <div>
              <Grid>
                <GridCol size={6}>
                  <UnitsForm readOnly={readOnly} />
                </GridCol>
                <GridCol size={6} extraClasses={css.switchWrapper}>
                  <PoliciesDifferentiationSwitch readOnly={readOnly} />
                </GridCol>
              </Grid>

              {values.policies.length === 2 ? (
                <>
                  <NavHorizontal aria-label="switch between policies for domestic and international lines">
                    <NavItem active={showDomestic} onClick={() => setShowDomestic(true)}>
                      <FormattedMessage id="general.policy.domestic" />
                    </NavItem>
                    <NavItem
                      active={!showDomestic}
                      onClick={() => setShowDomestic(false)}
                    >
                      <FormattedMessage id="general.policy.international" />
                    </NavItem>
                  </NavHorizontal>
                  {showDomestic ? (
                    <PolicyInput policyIndex={0} readOnly={readOnly} />
                  ) : (
                    <PolicyInput policyIndex={1} readOnly={readOnly} />
                  )}
                </>
              ) : (
                <PolicyInput policyIndex={0} readOnly={readOnly} />
              )}
            </div>

            <div className={css.buttonWrapper}>
              {readOnly ? (
                <Button type="reset">
                  <FormattedMessage id="general.close" />
                </Button>
              ) : (
                <>
                  <Button type="reset">
                    <FormattedMessage id="general.cancel" />
                  </Button>
                  <Button
                    type="submit"
                    appearance="primary"
                    loading={isSubmitLoading}
                    disabled={!dirty}
                  >
                    <FormattedMessage id="general.save" />
                  </Button>
                </>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PoliciesForm;
