import * as React from "react";
import { useIntl } from "react-intl";
import CustomFormRow from "../../../../shared/components/customFormRow/CustomFormRow";
import FormikSelect from "../../../../shared/components/formikSelect/FormikSelect";
import FormikSelectGroup from "../../../../shared/components/formikSelectGroup/FormikSelectGroup";
import getCurrenciesAsSelectOptions from "../../../../shared/helpers/getCurrenciesAsSelectOptions/getCurrenciesAsSelectOptions";
import getUnitText from "../../../../shared/helpers/getUnitText/getUnitText";
import { Currency, LengthUnit, WeightUnit } from "../../../../shared/types/schema";
import UnitChangePopupWrapper from "../../containers/unitChangePopupWrapper/UnitChangePopupWrapper";
import * as css from "./UnitsForm.scss";

type Props = {
  readOnly?: boolean;
};

const UnitsForm: React.FC<Props> = ({ readOnly }) => {
  const { formatMessage } = useIntl();
  return (
    <CustomFormRow>
      <div className={css.wrapper}>
        <div className={css.unit}>
          <UnitChangePopupWrapper<WeightUnit> name="units.weightUnit" unitType="weight">
            <FormikSelectGroup
              name="units.weightUnit"
              label={formatMessage({ id: "unit.weight" })}
              defaultOption={WeightUnit.Kilograms}
              defaultOptionLabel={getUnitText(WeightUnit.Kilograms)}
              alternativeOption={WeightUnit.Pounds}
              alternativeOptionLabel={getUnitText(WeightUnit.Pounds)}
              readOnly={readOnly}
              required
            />
          </UnitChangePopupWrapper>
        </div>
        <div className={css.unit}>
          <UnitChangePopupWrapper<LengthUnit> name="units.lengthUnit" unitType="length">
            <FormikSelectGroup
              name="units.lengthUnit"
              label={formatMessage({ id: "unit.length" })}
              defaultOption={LengthUnit.Centimeters}
              defaultOptionLabel={getUnitText(LengthUnit.Centimeters)}
              alternativeOption={LengthUnit.Inches}
              alternativeOptionLabel={getUnitText(LengthUnit.Inches)}
              readOnly={readOnly}
              required
            />
          </UnitChangePopupWrapper>
        </div>
        <div className={css.currencySelector}>
          <UnitChangePopupWrapper<Currency> name="units.currency" unitType="currency">
            <FormikSelect
              name="units.currency"
              label={formatMessage({ id: "prices.currency.select.label" })}
              options={getCurrenciesAsSelectOptions()}
              readOnly={readOnly}
              required
            />
          </UnitChangePopupWrapper>
        </div>
      </div>
    </CustomFormRow>
  );
};

export default UnitsForm;
