import { Switch } from "@flixbus/honeycomb-react";
import { NotificationType, addNotification } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ConfirmationPopup from "../../../../shared/components/confirmationPopup/ConfirmationPopup";
import {
  AccessPackage,
  SupportedExternalRole,
  User,
} from "../../../../shared/types/schema";
import { useUpdateRolesMutation } from "../../api/operations.generated";
import * as css from "./UserRoleAssignmentForm.scss";

export type UserRoleAssignmentFormProps = {
  closePopup: () => void;
  userId: User["id"];
  userName: User["displayName"];
  userMail: User["mail"];
  defaultValues: Array<SupportedExternalRole>;
  accessPackage: AccessPackage;
};

const UserRoleAssignmentForm: React.FC<UserRoleAssignmentFormProps> = ({
  closePopup,
  userId,
  userName,
  userMail,
  defaultValues,
  accessPackage,
}) => {
  const { formatMessage } = useIntl();
  const [selection, setSelection] =
    React.useState<Array<SupportedExternalRole>>(defaultValues);

  const [updateRoles] = useUpdateRolesMutation({
    onError: () =>
      addNotification({
        message: formatMessage({ id: "general.somethingWentWrong" }),
        type: NotificationType.Danger,
      }),
  });

  let rows = Object.values(SupportedExternalRole);

  if (accessPackage === AccessPackage.Light) {
    rows = rows.filter((row) => row !== SupportedExternalRole.RoleScheduleManager);
  }

  if (accessPackage !== AccessPackage.View) {
    return (
      <ConfirmationPopup
        active
        titleText={formatMessage({ id: "accessManagement.form.headline" })}
        confirmButtonText={formatMessage({ id: "general.save" })}
        onConfirm={() => {
          updateRoles({
            variables: {
              id: userId,
              roles: selection.filter((value, index, array) => {
                return array.indexOf(value) === index;
              }),
            },
          });
          closePopup();
        }}
        onCancel={closePopup}
      >
        <div className={css.finePrint} data-id="user-info">
          {userName} &#124; {userMail}
        </div>
        <div className={css.table}>
          {rows.map((role) => (
            <label htmlFor={role} className={css.tableRow} key={role}>
              <div className={css.textWrapper}>
                <div>
                  <FormattedMessage id={`accessManagement.role.${role}`} />
                </div>

                <div className={css.finePrint}>
                  <FormattedMessage id={`accessManagement.role.${role}.description`} />
                </div>
              </div>
              <Switch
                id={role}
                aria-label={formatMessage({ id: `accessManagement.role.${role}` })}
                defaultChecked={selection.includes(role)}
                onChange={({ currentTarget }) => {
                  if (currentTarget.checked) {
                    setSelection((prev) => [...prev, role]);
                  } else {
                    setSelection((prev) => [...prev.filter((item) => item !== role)]);
                  }
                }}
              />
            </label>
          ))}
        </div>
      </ConfirmationPopup>
    );
  }

  return <></>;
};

export default UserRoleAssignmentForm;
