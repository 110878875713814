@import "variables";
@import "../../helper/styles/styles.scss";

.scrollableColumn {
  @include scrollableColumn;
  top: $topHeaderHeight;
  z-index: 2;
}

.cell {
  height: $bottomHeaderHeight;
  line-height: calc($bottomHeaderHeight / 2);
  border-right: $border-light;
  border-bottom: $border;
  background-color: $bg-primary-color;
  text-align: center;

  &.today {
    border-bottom: 3px solid $ui-primary-color;
  }
}

.weekend {
  @include weekendCell;
}

.dayOfMonth {
  font-size: $font-size-small;
  height: 15px;
  line-height: 15px;

  &.today {
    color: $ui-primary-color;
  }
}
