import { Switch } from "@flixbus/honeycomb-react";
import { useField } from "formik2";
import * as React from "react";

export type FormikSwitchProps = {
  label: string | JSX.Element;
  name: string;
  readOnly?: boolean;
  small?: boolean;
  info?: string;
};

const FormikSwitch: React.FC<FormikSwitchProps> = ({
  label,
  name,
  readOnly,
  small,
  info,
}) => {
  const [field] = useField({ name, type: "checkbox" });

  return (
    <Switch
      label={label}
      id={name}
      disabled={readOnly}
      checked={field.value}
      small={small}
      info={info}
      {...field}
    />
  );
};

export default FormikSwitch;
