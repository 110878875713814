@import "./form-elements.scss";
@import "variables";

h1,
h2,
h3,
h4,
h5,
h6 {
  &.heading {
    & > small.fineprint {
      display: inline-flex;
      align-items: center;
      font-size: $font-size-small;
      color: $content-secondary-color;
      font-weight: normal;
    }

    .icon {
      margin-right: 3px;
    }
  }
}
