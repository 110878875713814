import { ConceptChangeFromLineQuery } from "../../types";
import scheduleComparator from "../scheduleComparator/scheduleComparator";

export default (
  conceptChange1: ConceptChangeFromLineQuery,
  conceptChange2: ConceptChangeFromLineQuery
): 1 | 0 | -1 => {
  if (!!conceptChange1.publishedDate && !conceptChange2.publishedDate) {
    return -1;
  }

  if (!conceptChange1.publishedDate && !!conceptChange2.publishedDate) {
    return 1;
  }

  if (!!conceptChange1.publishedDate && !!conceptChange2.publishedDate) {
    if (conceptChange1.publishedDate > conceptChange2.publishedDate) {
      return 1;
    }

    if (conceptChange1.publishedDate < conceptChange2.publishedDate) {
      return -1;
    }
  } else {
    return scheduleComparator(conceptChange1, conceptChange2);
  }

  return 0;
};
