import { ForbiddenRelationsFormValues } from "../../types";

export default (
  allForbiddenRelations: Array<ForbiddenRelationsFormValues>,
  filterValue?: string
) => {
  if (!filterValue?.length) {
    return allForbiddenRelations;
  }

  const transformedFilterValue = filterValue.toLowerCase();

  return allForbiddenRelations.filter((relation) => {
    const { code: codeFrom, name: nameFrom } = relation.from;
    const { code: codeTo, name: nameTo } = relation.to;

    return [codeFrom, nameFrom, codeTo, nameTo]
      .map((rel) => rel?.toLowerCase())
      .some((r) => r?.includes(transformedFilterValue));
  });
};
