@import "variables";

.tooltip {
  z-index: 100;
  width: 255px;
  white-space: break-spaces;
  padding-left: $spacing-2;
}

.tooltipTop {
  left: -14px !important;
  bottom: 25px;
}

.tooltipBottom {
  left: -14px !important;
  top: 25px;
}

.icon {
  color: $danger-color;
  margin-right: $spacing-1;
  line-height: 36px;
  cursor: pointer;
}

.newTabIcon {
  color: currentColor;
}
