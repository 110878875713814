import { IconAttention } from "@flixbus/honeycomb-icons-react";
import { useField } from "formik2";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ConfirmationPopup from "../../../../shared/components/confirmationPopup/ConfirmationPopup";
import ReadOnlyFormField from "../../../../shared/components/readOnlyFormField/ReadOnlyFormField";
import Select from "../../../../shared/components/select/Select";
import getCurrenciesAsSelectOptions from "../../../../shared/helpers/getCurrenciesAsSelectOptions/getCurrenciesAsSelectOptions";
import { SelectOption } from "../../../../shared/helpers/reactSelect/types";
import { Currency } from "../../../../shared/types/schema";

export type ReportingCurrencySelectProps = {
  required?: boolean;
  readOnly?: boolean;
  isCreate?: boolean;
};

const ReportingCurrencySelect: React.FC<ReportingCurrencySelectProps> = ({
  required,
  readOnly,
  isCreate = false,
}) => {
  const { formatMessage } = useIntl();
  const [currentSelectedValue, setCurrentSelectedValue] = React.useState<Currency>();
  const [, meta, helper] = useField("currency");
  const label = formatMessage({ id: "prices.currency.select.label" });
  const options = getCurrenciesAsSelectOptions();

  if (readOnly) {
    return (
      <ReadOnlyFormField label={label}>
        {options.find((option) => option.value === meta.value)?.label ||
          formatMessage({ id: "general.notAvailable" })}
      </ReadOnlyFormField>
    );
  }

  return (
    <>
      <Select
        label={label && `${label}${required ? " *" : ""}`}
        initialValue={options.find((option) => option.value === meta.value)}
        placeholder={formatMessage({ id: "partner.currency.placeholder" })}
        options={options}
        onSelect={(selectedValue: SelectOption) => {
          if (!isCreate && meta.value !== selectedValue.value) {
            setCurrentSelectedValue(selectedValue.value as Currency);
          } else {
            helper.setTouched(true);
            helper.setValue(selectedValue?.value);
          }
        }}
        onBlur={() => helper.setTouched(true)}
        errorText={meta.error && meta.touched ? meta.error : undefined}
        infoText={formatMessage({ id: "partner.currency.info" })}
      />
      {!!currentSelectedValue && (
        <ConfirmationPopup
          active
          titleIcon={IconAttention}
          titleText={formatMessage({ id: "partner.currency.popup.title" })}
          confirmButtonText={formatMessage({ id: "general.yes" })}
          onCancel={() => {
            setCurrentSelectedValue(undefined);
          }}
          onConfirm={() => {
            helper.setTouched(true);
            helper.setValue(currentSelectedValue);
            setCurrentSelectedValue(undefined);
          }}
        >
          <div>
            <FormattedMessage id="partner.currency.popup.content" />
          </div>
        </ConfirmationPopup>
      )}
    </>
  );
};

export default ReportingCurrencySelect;
