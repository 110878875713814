import { VirtualItem } from "@tanstack/react-virtual";
import {
  isSameDay,
  differenceInCalendarDays,
  isWeekend,
  isSameMonth,
  differenceInCalendarMonths,
} from "date-fns";
import * as React from "react";
import { Schedule } from "../../../../shared/types/schema";
import {
  contentCellWidthDaily,
  contentCellWidthMonthly,
  headerHeight,
} from "../../helper/styles/sizes";
import { CalendarIntervalType, CalendarSchedule, OnSaleChunk } from "../../helper/types";
import ScheduleCell from "../scheduleCell/ScheduleCell";

export type CalendarContentCellSchedule = CalendarSchedule &
  Pick<Schedule, "id" | "name">;

export type CalendarContentCellProps = {
  intervalType: CalendarIntervalType;
  currentDate: Date;
  chunk: OnSaleChunk<CalendarContentCellSchedule> | undefined;
  virtualRowItem: VirtualItem;
  virtualColItem: VirtualItem;
  hoveredSchedule: Schedule["id"] | undefined;
  setHoveredSchedule: (schduleId: Schedule["id"] | undefined) => void;
};

const CalendarContentCell: React.FC<CalendarContentCellProps> = ({
  intervalType,
  currentDate,
  chunk,
  virtualRowItem,
  virtualColItem,
  hoveredSchedule,
  setHoveredSchedule,
}) => {
  const isDaily = intervalType === CalendarIntervalType.DAILY;

  let isStart = false;
  let isEnd = false;
  let textBoxWidth = 0;

  if (chunk) {
    if (isDaily) {
      isStart = isSameDay(currentDate, chunk.uiPeriod.start);
      isEnd = isSameDay(currentDate, chunk.uiPeriod.end);
      textBoxWidth =
        46 +
        contentCellWidthDaily *
          differenceInCalendarDays(
            new Date(chunk.uiPeriod.end),
            new Date(chunk.uiPeriod.start)
          );
    } else {
      isStart = isSameMonth(currentDate, chunk.uiPeriod.start);
      isEnd = isSameMonth(currentDate, chunk.uiPeriod.end);
      textBoxWidth =
        78 +
        contentCellWidthMonthly *
          differenceInCalendarMonths(
            new Date(chunk.uiPeriod.end),
            new Date(chunk.uiPeriod.start)
          );
    }
  }

  return (
    <ScheduleCell
      rowStart={virtualRowItem.start + headerHeight}
      colStart={virtualColItem.start}
      highlight={
        chunk
          ? chunk.schedules.some((schedule) => schedule.id === hoveredSchedule)
          : false
      }
      setHighlight={(highlight) =>
        setHoveredSchedule(highlight && chunk ? chunk.schedules[0].id : undefined)
      }
      schedules={chunk ? chunk.schedules : []}
      isFirstOccurrence={chunk ? chunk.isFirstOccurrence : false}
      cellWidth={virtualColItem.size}
      isWeekend={isDaily && isWeekend(currentDate)}
      isStart={isStart}
      isEnd={isEnd}
      textBoxWidth={textBoxWidth}
      // For explanation see comment in ScheduleCell.tsx
      // onItemResize={(lines) => {
      //   rowVirtualizer.resizeItem(virtualRowItem.index, lines * contentCellHeight);
      // }}
    />
  );
};

export default CalendarContentCell;
