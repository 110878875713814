import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import cx from "classnames";
import * as React from "react";
import Breadcrumbs from "../../../../shared/components/breadcrumbs/Breadcrumbs";
import * as css from "./ScheduleHeaderWrapper.scss";

type Props = {
  scheduleId: string;
};

const ScheduleHeaderWrapper: React.FC<Props> = ({ scheduleId, children }) => {
  const [isScrolled, setIsScrolled] = React.useState(false);

  useScrollPosition(
    ({ currPos }) => {
      setIsScrolled(currPos.y <= -60);
    },
    [isScrolled]
  );

  return (
    <div className={cx(isScrolled && css.outerWrapperScrolled, css.outerWrapper)}>
      <div className={css.innerWrapper}>
        <Breadcrumbs scheduleId={scheduleId} />
        {children}
        {/* 
        We need to render this style element in order to overwrite the default css for the header.
        On all other pages, except this one, the header navigation is position:fixed.
        Only when this Element is rendered the header should be static. 
        We cant do it inside the Navigation Component because that one does not rerender when visiting another page
        due to our SPA-architecture. 
        */}
        <style>{"header {position: static !important}"}</style>
      </div>
    </div>
  );
};

export default ScheduleHeaderWrapper;
