@import "variables";

.tableHeaderWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.flag {
  display: block;
}

.tooltip {
  height: 25px;
}

.stationsTable {
  // Name
  th:nth-child(1),
  td:nth-child(1) {
    width: 30%;
  }

  // Code
  th:nth-child(2),
  td:nth-child(2) {
    width: 6%;
  }

  // City
  th:nth-child(3),
  td:nth-child(3) {
    width: 15%;
  }

  // Country
  th:nth-child(4),
  td:nth-child(4) {
    width: 8%;
  }

  // Updated
  th:nth-child(5),
  td:nth-child(5) {
    width: 10%;
  }

  // Status
  th:nth-child(6),
  td:nth-child(6) {
    width: 10%;
  }

  // Action
  th:nth-child(7),
  td:nth-child(7) {
    width: 10%;
  }
}

.citiesTable {
  // Name
  th:nth-child(1),
  td:nth-child(1) {
    width: 45%;
  }

  // Country
  th:nth-child(2),
  td:nth-child(2) {
    width: 10%;
  }

  // Importance Class
  th:nth-child(2),
  td:nth-child(2) {
    width: 15%;
  }
}

.filterContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: $spacing-3;
  column-gap: $spacing-2;
}

.filterWrapper {
  width: 187px;
}

.filterButtonWrapper {
  padding-top: 26px;
  width: 185px;
}
