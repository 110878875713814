import { Checkbox, FormRow, Heading, Text } from "@flixbus/honeycomb-react";
import { useField } from "formik2";
import * as React from "react";
import { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import FormikCheckbox from "../../../../shared/components/formikCheckbox/FormikCheckbox";
import FormikSwitch from "../../../../shared/components/formikSwitch/FormikSwitch";
import InfoText from "../../../../shared/components/infoText/InfoText";
import { CheckInInformation } from "../../../../shared/types/schema";
import isApiDisabledCapacityFeatureChangeDisabled from "../../helpers/isApiDisabledCapacityFeatureChangeDisabled/isApiDisabledCapacityFeatureChangeDisabled";
import { PartnerSettingsFormValues } from "../../types";
import * as css from "./ApiSettingsInput.scss";

type Props = {
  readOnly?: boolean;
  apiPartnerReadOnly?: boolean;
  isCreation: boolean;
};

const ApiSettingsInput: React.FC<Props> = ({
  readOnly,
  apiPartnerReadOnly,
  isCreation,
}) => {
  const { formatMessage } = useIntl();
  const [, meta, helper] =
    useField<PartnerSettingsFormValues["apiSettings"]>("apiSettings");

  useEffect(() => {
    if (isCreation) {
      helper.setValue({
        ...meta.value,
        apiDisabledFeatures: {
          manageCapacity: !meta.value.apiPartner,
        },
      });
    }
  }, [meta.value.apiPartner]);

  return (
    <>
      <FormRow>
        <FormikCheckbox
          label={formatMessage({ id: "partner.apiPartner.label" })}
          name="apiSettings.apiPartner"
          readOnly={apiPartnerReadOnly}
        />
      </FormRow>

      {meta.value.apiPartner && (
        <div className={css.ticketSendoutWrapper}>
          <Text extraClasses={css.apiSubHeader}>
            <FormattedMessage id="partnerForm.apiSettings.ticketSendout" />
          </Text>
          <FormRow>
            <FormikSwitch
              label={formatMessage({
                id: "partnerForm.settings.ticketSendout.switch.label",
              })}
              name="apiSettings.ticketSendout.enabled"
              small
              readOnly={readOnly}
            />
          </FormRow>

          {meta.value.ticketSendout.enabled && (
            <div data-id="check-in-information-section">
              <Heading size={4} extraClasses={css.heading}>
                <FormattedMessage id="partnerForm.settings.checkInInformation.headline" />
              </Heading>

              {Object.values(CheckInInformation).map((field) => {
                const isChecked =
                  meta.value.ticketSendout.checkInInformation.includes(field);

                return (
                  <Checkbox
                    id={field}
                    key={field}
                    value={field}
                    label={formatMessage({
                      id: `partnerForm.settings.checkInInformation.option.${field}`,
                    })}
                    extraClasses={css.checkbox}
                    checked={isChecked}
                    onChange={() => {
                      if (isChecked) {
                        helper.setValue({
                          ...meta.value,
                          ticketSendout: {
                            ...meta.value.ticketSendout,
                            checkInInformation:
                              meta.value.ticketSendout.checkInInformation.filter(
                                (value) => value !== field
                              ),
                          },
                        });
                      } else {
                        helper.setValue({
                          ...meta.value,
                          ticketSendout: {
                            ...meta.value.ticketSendout,
                            checkInInformation: [
                              ...meta.value.ticketSendout.checkInInformation,
                              field,
                            ],
                          },
                        });
                      }
                    }}
                    disabled={readOnly}
                  />
                );
              })}
            </div>
          )}
        </div>
      )}

      {meta.value.apiPartner && (
        <div className={css.apiDisabledFeaturesWrapper}>
          <Text extraClasses={css.apiSubHeader} data-id="apiDisabledFeaturesHeader">
            <FormattedMessage id="partnerForm.apiSettings.manualFunctionality" />
          </Text>
          <FormRow>
            <InfoText
              text={formatMessage({
                id: "partnerForm.apiSettings.manualFunctionality.info",
              })}
              data-id="apiDisabledFeaturesInfo"
            />
            <FormikSwitch
              name="apiSettings.apiDisabledFeatures.manageCapacity"
              label={formatMessage({ id: "navigation.capacityManager" })}
              info={formatMessage({
                id: "partnerForm.apiSettings.manualFunctionality.manageCapacity.info",
              })}
              small
              readOnly={
                readOnly ||
                isApiDisabledCapacityFeatureChangeDisabled(
                  isCreation,
                  meta.value.apiDisabledFeatures.manageCapacity,
                  meta.initialValue?.apiDisabledFeatures.manageCapacity
                )
              }
            />
          </FormRow>
        </div>
      )}
    </>
  );
};

export default ApiSettingsInput;
