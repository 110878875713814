import { useState, useEffect } from 'react';

function useBrowser() {
  var _a = useState(null),
    win = _a[0],
    setWin = _a[1];
  var _b = useState(null),
    doc = _b[0],
    setDoc = _b[1];
  useEffect(function () {
    if (typeof window !== 'undefined') {
      setWin(window);
    }
  }, [window]);
  useEffect(function () {
    if (typeof document !== 'undefined') {
      setDoc(document);
    }
  }, [document]);
  return [win, doc];
}

export { useBrowser };
