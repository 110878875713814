import { Icon, IconForbidden, IconNewTab } from "@flixbus/honeycomb-icons-react";
import { Heading, Link, Text, Tooltip } from "@flixbus/honeycomb-react";
import { mpcPathnames as pathnames } from "@flixbus-phx/marketplace-common";
import cx from "classnames";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import * as css from "./ForbiddenRelationIcon.scss";

type props = {
  lineId: string;
  extraClasses?: string;
  row: number;
};

const ForbiddenRelationIcon: React.FC<props> = ({ lineId, extraClasses, row }) => {
  const isFirstRow = row < 3;

  const tooltipContent = () => (
    <>
      <Heading size={4}>
        <FormattedMessage id="forbiddenRelation.tooltip.header" />
      </Heading>
      <Text>
        {<FormattedMessage id="forbiddenRelation.tooltip.text" />}{" "}
        <Link
          target="_blank"
          href={`${pathnames.showLineDetail.replace(
            ":lineId",
            lineId
          )}#forbidden_relations=true`}
        >
          <FormattedMessage id="forbiddenRelation.tooltip.link" />
          &nbsp;
          <Icon
            extraClasses={css.newTabIcon}
            InlineIcon={IconNewTab}
            aria-label="Opens in a New Tab"
          />
        </Link>
      </Text>
    </>
  );

  return (
    <Tooltip
      id="Forbidden relation tooltip"
      content={tooltipContent()}
      alignment="start"
      position={isFirstRow ? "bottom" : "top"}
      extraClasses={cx(css.tooltip, isFirstRow ? css.tooltipBottom : css.tooltipTop)}
      hasClose
      closeBtn={{ "aria-label": "Close me" }}
    >
      <div className={css.icon}>
        <Icon InlineIcon={IconForbidden} extraClasses={extraClasses} />
      </div>
    </Tooltip>
  );
};

export default ForbiddenRelationIcon;
