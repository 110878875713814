import { Icon, IconBusTimeSolid } from "@flixbus/honeycomb-icons-react";
import { Button, Input, PopupSection } from "@flixbus/honeycomb-react";
import { Popup } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { TripFragment } from "../../api/operations.generated";
import * as css from "./TripShiftButton.scss";

type Props = {
  tripDepartureTime: string;
  tripNumber: TripFragment["number"];
  onShift: (departureTime: string) => void;
  disabled: boolean;
};

const TripShiftButton: React.FC<Props> = ({
  tripDepartureTime,
  tripNumber,
  onShift,
  disabled,
}) => {
  const { formatMessage } = useIntl();
  const [active, setActive] = React.useState(false);
  const [departureTime, setDepartureTime] = React.useState(tripDepartureTime);

  const handleShiftClick = () => {
    onShift(departureTime);
    setActive(false);
  };

  return (
    <>
      <Button
        data-id="open-shift-popup-button"
        appearance="tertiary"
        display="square"
        onClick={() => setActive(true)}
        extraClasses={css.shiftButton}
        mouse-out-visibility="hidden"
        title={formatMessage({ id: "trip.shift.button.title" })}
        disabled={disabled}
        aria-label="shift trip"
      >
        <Icon InlineIcon={IconBusTimeSolid} />
      </Button>

      <Popup active={active} onClose={() => setActive(false)}>
        <PopupSection type="title">
          <FormattedMessage id="trip.shift.popup.title" values={{ tripNumber }} />
        </PopupSection>
        <PopupSection type="content">
          <FormattedMessage id="trip.shift.popup.text" />
          <div className={css.timeInput}>
            <Input
              type="time"
              id={`trip-shift-${Math.random()}`}
              name="trip-shift"
              aria-label="trip-shift"
              value={departureTime}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setDepartureTime(event.target.value)
              }
              required
              extraClasses={css.timeInput}
            />
          </div>
        </PopupSection>
        <PopupSection type="actions">
          <Button
            type="button"
            appearance="tertiary"
            data-id="cancel-button"
            onClick={() => setActive(false)}
          >
            <FormattedMessage id="general.cancel" />
          </Button>
          <Button
            type="button"
            appearance="primary"
            data-id="shift-button"
            onClick={handleShiftClick}
          >
            {formatMessage({ id: "trip.shift.popup.confirm.button" })}
          </Button>
        </PopupSection>
      </Popup>
    </>
  );
};

export default TripShiftButton;
