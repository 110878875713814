import { DataProxy } from "@apollo/client";
import { Schedule, Trip } from "../../../../shared/types/schema";
import {
  FindScheduleWithTripsAndStationsQuery,
  FindScheduleWithTripsAndStationsDocument,
  DeleteTripMutation,
  TripFragment,
} from "../../api/operations.generated";

export const getScheduleData = (
  cache: DataProxy,
  scheduleId: Schedule["id"]
): FindScheduleWithTripsAndStationsQuery | null => {
  return cache.readQuery({
    query: FindScheduleWithTripsAndStationsDocument,
    variables: { scheduleId },
  });
};

export const deleteTripFromCache = (
  cache: DataProxy,
  data: DeleteTripMutation | undefined | null,
  scheduleId: Schedule["id"],
  tripId: Trip["id"]
): void => {
  const oldData = getScheduleData(cache, scheduleId);
  if (oldData && data && data.deleteTrip) {
    const currentTrips = [...oldData.findSchedule.trips];
    const deleteIndex = currentTrips.findIndex((oldTrip) => oldTrip.id === tripId);
    currentTrips.splice(deleteIndex, 1);

    cache.writeQuery({
      query: FindScheduleWithTripsAndStationsDocument,
      data: {
        findSchedule: {
          ...oldData.findSchedule,
          trips: currentTrips,
        },
      },
    });
  }
};

export const addTripToCache = (
  cache: DataProxy,
  data: TripFragment | undefined,
  scheduleId: Schedule["id"]
): void => {
  const oldData = getScheduleData(cache, scheduleId);
  if (oldData && data) {
    cache.writeQuery({
      query: FindScheduleWithTripsAndStationsDocument,
      data: {
        findSchedule: {
          ...oldData.findSchedule,
          trips: oldData.findSchedule.trips.concat(data),
        },
      },
    });
  }
};
