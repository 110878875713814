import { datadogRum } from "@datadog/browser-rum";

const env =
  process.env.NODE_ENV === "production"
    ? "prod"
    : process.env.NODE_ENV === "staging"
      ? "stg"
      : "dev";

datadogRum.init({
  applicationId: "1b360ad2-c335-42ee-8207-df3f5be3d73e",
  clientToken: "pub74829fe940001479fc85f8d83f7f110c",
  site: "datadoghq.eu",
  env,
  version: process.env.CI_CI_COMMIT_SHA ? process.env.CI_COMMIT_SHA : "unknown",
  service: "plabu-phx-marketplace-connect",
  sessionSampleRate: env === "prod" ? 100 : 10,
  sessionReplaySampleRate: 0,
  trackSessionAcrossSubdomains: true,
  useSecureSessionCookie: true,
  useCrossSiteSessionCookie: true,
  allowFallbackToLocalStorage: true,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
});
