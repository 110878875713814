@import "variables";

.fieldset {
  margin: $spacing-1 0;
  > div:not(:first-child) {
    margin-top: $spacing-2;
  }
}

.infoText {
  color: $content-secondary-color;
}