import { Icon, IconPersonSolid, IconTripSolid } from "@flixbus/honeycomb-icons-react";
import { Heading, NavHorizontal, NavItem } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import * as css from "./SearchPage.scss";
import { SearchType } from "./types";
import CreatePartnerButton from "./ui/createPartnerButton/CreatePartnerButton";
import LineSearch from "./ui/lineSearch/LineSearch";
import PartnerSearch from "./ui/partnerSearch/PartnerSearch";

const SearchPage: React.FC = () => {
  const [searchType, setSearchType] = React.useState(SearchType.PARTNERS);

  return (
    <>
      <div className={css.headerWrapper}>
        <Heading size={1}>Flix Marketplace</Heading>
        <CreatePartnerButton />
      </div>

      <NavHorizontal
        aria-label="switch between cities and stations"
        extraClasses={css.nav}
      >
        <NavItem
          key="partners"
          active={searchType === SearchType.PARTNERS}
          onClick={() => setSearchType(SearchType.PARTNERS)}
        >
          <div className={css.navItemWrapper}>
            <Icon InlineIcon={IconPersonSolid} extraClasses={css.icon} />
            <FormattedMessage id="general.partners" />
          </div>
        </NavItem>
        <NavItem
          key="lines"
          active={searchType === SearchType.LINES}
          onClick={() => setSearchType(SearchType.LINES)}
        >
          <div className={css.navItemWrapper}>
            <Icon InlineIcon={IconTripSolid} extraClasses={css.icon} />
            <FormattedMessage id="general.lines" />
          </div>
        </NavItem>
      </NavHorizontal>
      <div className={css.contentWrapper}>
        {searchType === SearchType.PARTNERS && <PartnerSearch />}
        {searchType === SearchType.LINES && <LineSearch />}
      </div>
    </>
  );
};

export default SearchPage;
