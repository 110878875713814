import { __assign } from '../../node_modules/tslib/tslib.es6.js';

// eslint-disable-next-line func-names
var HoneyBem = function () {
  /**
  * convertObjToModifierClassNames
  * @private
  *
  * function to convert objects to modifiers classes,
  * adds block to every modifier class and flattens to one className string, for internal use
  * @param {object} mods - modifiers to be applied to base.
  * @param {string} base - base to be chained
  * @return {string} - Resulting class string
  */
  var convertObjToModifierClassNames = function mapModifiersObj(mods, base) {
    return Object.keys(mods).reduce(function (a, c) {
      return mods[c] ? "".concat(a, " ").concat(base, "--").concat(c) : a;
    }, '').trim();
  };
  /**
  * convertObjToClassNames
  * @private
  *
  * function to map objects to mix classes string, for internal use only
  * @param {object} mixObj - modifiers to be applied to base.
  * @return {string} - Resulting class string
  */
  var convertObjToClassNames = function convertObjectToClassNames(mixObj) {
    return Object.keys(mixObj).filter(function (current) {
      return mixObj[current];
    }).join(' ');
  };
  return {
    block: '',
    module: {
      map: {},
      raw: ''
    },
    /**
     * Exposed entry point.
     * @param {string} blockName - the block css chunk.
     * @param module {object} - CSS class mapping object
     * @param {string} prefix - prefix, defaults to empty strings.
     * @return {
     *    block: string,
     *    module: object,
     *    init: function
     *    b: function,
     *    e: function,
     *    m: function,
     *    mix: function,
     *    convert: function,
     * } - HoneyBem instance.
     */
    init: function constructor(blockName, module, prefix) {
      if (prefix === void 0) {
        prefix = "hcr-" ;
      }
      this.block = "".concat(prefix).concat(blockName);
      if (module) {
        this.module = module;
      }
      // allow properties overload
      return __assign({}, this);
    },
    /**
     * b
     *
     * function to create the block classname
     * @param {array|string|object} mods - allow optional chaining of modifiers in block.
     * @param {array|string|object} mixes - allow optional chaining of mixes to block.
     * @return {string} - either block or block with chained modifier class string
     */
    b: function block(mods, mixes) {
      if (mods === void 0) {
        mods = [];
      }
      if (mixes === void 0) {
        mixes = [];
      }
      var classArr = [this.block];
      // populating array with mods and mixes class strings
      classArr.push(this.m(mods, this.block));
      classArr.push(this.mix(mixes));
      // concatenating after removing empty strings
      // and passing to this convert to map against css mapping, if any
      return this.convert(classArr.filter(function (current) {
        return current;
      }).join(' '));
    },
    /**
     * e
     *
     * function to create element classnames
     * @param {array|string} children - child element to be interpolated with block class.
     * @param {array|string|object} mods - allow optional chaining of modifiers in element.
     * @param {array|string|object} mixes - allow optional chaining of mixes to element.
     * @return {string} - either element or element with chained modifier class string
     */
    e: function elements(children, mods, mixes) {
      if (mods === void 0) {
        mods = [];
      }
      if (mixes === void 0) {
        mixes = [];
      }
      var e = typeof children === 'string' ? "".concat(this.block, "__").concat(children) : "".concat(this.block).concat(children.reduce(function (acc, cur) {
        return "__".concat(acc, "__").concat(cur);
      }));
      var classArr = [e];
      // populating array with mods and mixes class strings
      classArr.push(this.m(mods, e));
      classArr.push(this.mix(mixes));
      // concatenating after removing empty strings
      // and passing to this convert to map against css mapping, if any
      return this.convert(classArr.filter(function (current) {
        return current;
      }).join(' '));
    },
    /**
     * m
     *
     * function to create modifier class names
     * @param {array|string|object} mods - modifiers to be applied to base.
     * @param {string} baseOverride - optionally override the block classname, for internal use.
     * @return {string} - Resulting class string with modifier classes
     */
    m: function modifiers(mods, baseOverride) {
      var base = baseOverride || this.block;
      var chainedClassName = '';
      if (typeof mods === 'string') {
        // if modifier is a string chain it with base
        chainedClassName = "".concat(base, "--").concat(mods);
      } else if (mods instanceof Object && !Array.isArray(mods)) {
        // if object of modifiers given pass them all to the mapping/chaining function
        chainedClassName = convertObjToModifierClassNames(mods, base);
      } else if (Array.isArray(mods)) {
        // remove null, false and empty array
        var cleanMods = mods.filter(function (mod) {
          return mod && (typeof mod === 'string' || mod instanceof Object);
        });
        // map mod (either string or Object)
        var m = cleanMods.map(function (mod) {
          if (mod instanceof Object) {
            return convertObjToModifierClassNames(mod, base);
          }
          return "".concat(base, "--").concat(mod);
        }).join(' '); // join modifier sets for every array item into one modifier string
        chainedClassName = "".concat(m.trim());
      }
      return chainedClassName;
    },
    /**
     * mix
     *
     * function to create mix classnames
     * @param {string|object|array} mix - mix (additional classes) to be added to an element
     * @return {string} - Resulting class string with mix classes
     */
    mix: function mixes(mix) {
      var mixClassName = '';
      if (typeof mix === 'string') {
        // if string provided, pass it through
        mixClassName = mix;
      } else if (Array.isArray(mix)) {
        // if array given, flatten all objects inside and concatenate resulting classes
        mixClassName = mix.map(function (mixItem) {
          if (mixItem instanceof Object) {
            return convertObjToClassNames(mixItem);
          }
          return mixItem;
        }).join(' ');
      } else if (mix instanceof Object) {
        // in case we have object, flatten keys depending of the value
        mixClassName = convertObjToClassNames(mix);
      }
      return mixClassName.trim();
    },
    /**
     * convert
     *
     * maps bem syntax against css module when provided else returns selectors
     * @param {string} selectors - BEM like css selectors
     * @return {string} - either pure BEM selectors or css module selectors
     */
    convert: function convert(selectors) {
      var _this = this;
      if (this.module) {
        // eslint-disable-next-line arrow-body-style
        return selectors.padEnd(1).split(' ').map(function (a) {
          return _this.module.map[a] ? _this.module.map[a] : a;
        }).join(' ');
      }
      return selectors;
    }
  };
}();

export { HoneyBem as default };
