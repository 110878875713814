import { formatPriceNumberInput } from "../../../../shared/helpers/priceInputFormatter/priceInputFormatter";
import getUserPreferredTimeFormat from "../../../../shared/helpers/userPreferredTimeFormat/userPreferredTimeFormat";
import {
  Currency,
  PurposeOfContactField,
  TimeUnit,
} from "../../../../shared/types/schema";
import {
  PartnerCustomerServiceAndPoliciesDefaultValues,
  PartnerCustomerServiceAndPoliciesFormValues,
} from "../../types";
import emptyCustomerServiceContactFormRow from "../emptyCustomerServiceContactFormRow/emptyCustomerServiceContactFormRow";
import getInitialTimeValue from "../getInitialTimeValue/getInitialTimeValue";
import getInitialWorkingDays from "../getInitialWorkingDays/getInitialWorkingDays";

export default (
  defaultValues?: PartnerCustomerServiceAndPoliciesDefaultValues
): PartnerCustomerServiceAndPoliciesFormValues => {
  const timeFormat = getUserPreferredTimeFormat();

  return {
    checkInRequirements: {
      checkInTimeStart: {
        value:
          defaultValues?.checkInRequirements?.checkInTimeStart?.value ||
          ("" as unknown as number),
        unit: TimeUnit.Minutes,
        ...defaultValues?.checkInRequirements?.checkInTimeStart,
      },
      checkInTimeEnd: {
        value:
          defaultValues?.checkInRequirements?.checkInTimeEnd?.value ||
          ("" as unknown as number),
        unit: TimeUnit.Minutes,
        ...defaultValues?.checkInRequirements?.checkInTimeEnd,
      },
      checkInOptions: {
        website: {
          enabled: !!defaultValues?.checkInRequirements?.checkInOptions?.website,
          url: defaultValues?.checkInRequirements?.checkInOptions?.website || "",
        },
        shop: {
          enabled: !!defaultValues?.checkInRequirements?.checkInOptions?.shop,
        },
        desk: {
          enabled:
            !!defaultValues?.checkInRequirements?.checkInOptions?.checkInDesk.enabled,
          currency:
            defaultValues?.checkInRequirements?.checkInOptions?.checkInDesk.currency ||
            Currency.Eur,
          fee: defaultValues?.checkInRequirements?.checkInOptions?.checkInDesk.fee.value
            ? formatPriceNumberInput(
                defaultValues?.checkInRequirements?.checkInOptions?.checkInDesk.fee.value
              )
            : "",
          feeUnit:
            defaultValues?.checkInRequirements?.checkInOptions?.checkInDesk.feeUnit ||
            undefined,
        },
        selfServiceMachine: {
          enabled:
            !!defaultValues?.checkInRequirements?.checkInOptions?.selfServiceMachine,
        },
      },
      proofOfIdentityRequired: defaultValues?.checkInRequirements?.proofOfIdentityRequired
        .length
        ? defaultValues.checkInRequirements.proofOfIdentityRequired
        : [],
    },
    customerServiceInformation: {
      customerServiceContact: defaultValues?.customerServiceContact?.length
        ? defaultValues.customerServiceContact.map((entry) => ({
            purposeOfContact: entry.purposeOfContact || ("" as PurposeOfContactField),
            languagesSpoken: entry.languagesSpoken?.length ? entry.languagesSpoken : [],
            hotlineNumber: entry.hotlineNumber || "",
            email: entry.email || "",
            timeZoneId: entry.timeZoneId || "",
            workingHours: entry.workingHours?.length
              ? entry.workingHours.map((subEntry) => {
                  return {
                    workingDays: getInitialWorkingDays(subEntry.workingDays),
                    from: getInitialTimeValue(timeFormat, subEntry.from),
                    to: getInitialTimeValue(timeFormat, subEntry.to),
                  };
                })
              : emptyCustomerServiceContactFormRow.workingHours,
            holidayWorkingHours: {
              enabled: !!entry.holidayWorkingHours.length,
              workingHours: entry.holidayWorkingHours.length
                ? entry.holidayWorkingHours.map((subEntry) => {
                    return {
                      from: getInitialTimeValue(timeFormat, subEntry.from),
                      to: getInitialTimeValue(timeFormat, subEntry.to),
                    };
                  })
                : emptyCustomerServiceContactFormRow.holidayWorkingHours.workingHours,
            },
          }))
        : [emptyCustomerServiceContactFormRow],
    },
    termsAndConditions: {
      termsAndConditionsUrl: defaultValues?.termsAndConditionsUrl || "",
    },
  };
};
