import { Icon, IconClose } from "@flixbus/honeycomb-icons-react";
import * as React from "react";
import {
  components,
  MultiValueRemoveProps as ReactSelectMultiValueRemoveProps,
} from "react-select";
import { SelectOption } from "../../../../helpers/reactSelect/types";
import * as css from "./MultiValueRemove.scss";

export type MultiValueRemoveProps = ReactSelectMultiValueRemoveProps<SelectOption>;

const MultiValueRemove: React.FC<MultiValueRemoveProps> = (props) => {
  return (
    <components.MultiValueRemove {...props}>
      <Icon
        InlineIcon={IconClose}
        extraClasses={css.icon}
        size={2}
        appearance="primary"
      />
    </components.MultiValueRemove>
  );
};

export default MultiValueRemove;
