import { Spinner } from "@flixbus/honeycomb-react";
import * as React from "react";
import * as css from "./PriceMatrixSpinner.scss";

const PriceMatrixSpinner: React.FC = () => (
  <div className={css.container}>
    <Spinner />
  </div>
);

export default PriceMatrixSpinner;
