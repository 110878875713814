import { getCookie, setCookie } from "../handleCookies/handleCookies";

type theme = "dark" | "default";
export const COOKIE_NAME = "marketplace-theme";

/**
 * Gets stored theme setting from cookie.
 * If no cookie is set it defaults to the users system setting.
 * If the user's system does not support themes it defaults to the light theme.
 */
export const getThemeSetting = (): theme => {
  const cookieValue = getCookie(COOKIE_NAME);

  if (cookieValue !== "dark" && cookieValue !== "default") {
    if (
      window.matchMedia("(prefers-color-scheme)").media !== "not all" &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      return "dark";
    }
    return "default";
  }

  return cookieValue;
};

export const storeThemeSetting = (newSetting: theme) => {
  setCookie(COOKIE_NAME, newSetting, 90);
};

export const toggleThemeSetting = () => {
  const themeWrapper = document.getElementById("theme-wrapper");

  const oldSetting = getThemeSetting();

  if (themeWrapper) {
    const className = themeWrapper.className.split("-");
    const HCVersion = `${className[className.length - 3]}-${className[className.length - 2]}-${className[className.length - 1]}`;

    if (oldSetting === "default") {
      themeWrapper.className = `hcr-theme-dark-${HCVersion}`;

      storeThemeSetting("dark");
    } else {
      themeWrapper.className = `hcr-theme-default-${HCVersion}`;

      storeThemeSetting("default");
    }
  }
};
