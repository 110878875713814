import {
  compareAsc,
  eachMonthOfInterval,
  endOfMonth,
  endOfYear,
  interval,
  isWithinInterval,
  NormalizedInterval,
  startOfMonth,
  startOfYear,
} from "date-fns";
import {
  Calendar,
  CalendarIntervalType,
  CalendarRow,
  CalendarSchedule,
  InputSchedule,
  OnSaleChunk,
} from "../types";
import growInterval from "./buildCalendar";

export const addScheduleToMonthlyBucketsAndGrowInterval = (
  schedule: CalendarSchedule,
  buckets: Map<number, Set<CalendarSchedule>>,
  intervalToGrow: NormalizedInterval
): NormalizedInterval => {
  let grownInterval = intervalToGrow;

  schedule.onSalePeriods.forEach((onSalePeriod) => {
    grownInterval = growInterval(
      grownInterval,
      interval(onSalePeriod.start, onSalePeriod.end)
    );

    eachMonthOfInterval<Date>(onSalePeriod).forEach((month) => {
      const monthTime = month.getTime();
      const bucket = buckets.get(monthTime) || new Set<CalendarSchedule>();
      bucket.add(schedule);
      buckets.set(monthTime, bucket);
    });
  });

  return grownInterval;
};

const newMonthlyOnSaleChunk = (
  period: NormalizedInterval<Date>,
  schedules: Array<CalendarSchedule> = []
): OnSaleChunk<CalendarSchedule> => ({
  uiPeriod: period,
  isFirstOccurrence: false,
  schedules,
});

export const getEarliestIntervalInMonth = (
  intervals: Array<NormalizedInterval>,
  month: Date
): NormalizedInterval => {
  const matchingIntervals = intervals.filter((i) => {
    return isWithinInterval(month, interval(startOfMonth(i.start), endOfMonth(i.end)));
  });

  if (matchingIntervals.length === 0) {
    throw new Error("No matching interval found");
  }

  matchingIntervals.sort((a, b) => compareAsc(a.start, b.start));

  return matchingIntervals[0];
};

export const sortSchedulesByOnSalePeriodInMonthAsc = <S extends CalendarSchedule>(
  month: Date,
  schedules: Array<S>
): Array<S> => {
  const sortedSchedules = schedules.slice();

  sortedSchedules.sort((a, b) => {
    const periodA = getEarliestIntervalInMonth(a.onSalePeriods, month);
    const periodB = getEarliestIntervalInMonth(b.onSalePeriods, month);

    return compareAsc(periodA.start, periodB.start);
  });

  return sortedSchedules;
};

const buildMonthlyCalendar = (
  inputSchedules: Array<InputSchedule>
): Calendar<CalendarSchedule> => {
  const now = new Date();
  let boundary = interval(startOfMonth(now), endOfMonth(now));
  const rows = inputSchedules
    .map((inputSchedule) => {
      // Put all onSale schedules in monthly buckets and calculate the complete interval
      const monthlyBuckets = new Map<number, Set<CalendarSchedule>>();
      let completeInterval = interval(startOfMonth(now), endOfMonth(now));

      completeInterval = addScheduleToMonthlyBucketsAndGrowInterval(
        inputSchedule,
        monthlyBuckets,
        completeInterval
      );
      inputSchedule.conceptChanges.forEach((conceptChange) => {
        completeInterval = addScheduleToMonthlyBucketsAndGrowInterval(
          conceptChange,
          monthlyBuckets,
          completeInterval
        );
      });

      boundary = growInterval(boundary, completeInterval);

      // Iterate over monthly buckets and consolidate the schedules into OnSaleChunks
      const processedSchedules = new Set<CalendarSchedule>();
      const calendarRow: CalendarRow<CalendarSchedule> = {
        onSale: [],
      };
      let currentOnSaleChunk = newMonthlyOnSaleChunk(completeInterval);

      eachMonthOfInterval(completeInterval).forEach((currentMonth) => {
        const schedulesInMonth = monthlyBuckets.get(currentMonth.getTime());
        if (!schedulesInMonth) {
          return;
        }

        // same schedules as in the chunk before, just extend the period
        if (
          currentOnSaleChunk.schedules.length === schedulesInMonth.size &&
          currentOnSaleChunk.schedules.every((schedule) => schedulesInMonth.has(schedule))
        ) {
          currentOnSaleChunk.uiPeriod.end = endOfMonth(currentMonth);
          return;
        }

        // different schedules, put the current chunk in the row ...
        if (currentOnSaleChunk.schedules.length > 0) {
          calendarRow.onSale.push(currentOnSaleChunk);
        }

        // ... and start a new chunk
        currentOnSaleChunk = newMonthlyOnSaleChunk(
          interval(startOfMonth(currentMonth), endOfMonth(currentMonth)),
          sortSchedulesByOnSalePeriodInMonthAsc(
            currentMonth,
            Array.from(schedulesInMonth)
          )
        );

        schedulesInMonth.forEach((schedule) => {
          if (!processedSchedules.has(schedule)) {
            currentOnSaleChunk.isFirstOccurrence = true;
            processedSchedules.add(schedule);
          }
        });
      });

      // after the loop, push the remaining chunk to the row
      if (currentOnSaleChunk.schedules.length > 0) {
        calendarRow.onSale.push(currentOnSaleChunk);
      }

      return calendarRow;
    })
    .filter((row) => row.onSale.length > 0);

  return {
    intervalType: CalendarIntervalType.MONTHLY,
    boundary: interval(startOfYear(boundary.start), endOfYear(boundary.end)),
    rows,
  };
};

export default buildMonthlyCalendar;
