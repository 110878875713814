@import "variables";

.button {
  height: $input-height-desktop;
}

.buttonGroup {
  padding-left: $spacing-3;
  border-left: $border;
  display: flex;
}
