import { DataTable, DataTableRow, Skeleton } from "@flixbus/honeycomb-react";
import * as React from "react";
import tableHeader from "../../helper/tableHeader/tableHeader";

const UserRoleAssignmentLoading: React.FC = () => {
  return (
    <DataTable headers={tableHeader}>
      {Array.from({ length: 5 }, (_, i) => (
        <DataTableRow key={i}>
          {Array.from({ length: 3 }, (__, ind) => (
            <td key={ind}>
              <Skeleton flushBottom />
            </td>
          ))}
        </DataTableRow>
      ))}
    </DataTable>
  );
};

export default UserRoleAssignmentLoading;
