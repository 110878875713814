import { TimeFormat } from "../../../../shared/components/timeInput/types";
import { convert24hTo12hFormat } from "../../../../shared/helpers/timeFormatter/timeConverter";
import getUserPreferredTimeFormat from "../../../../shared/helpers/userPreferredTimeFormat/userPreferredTimeFormat";
import { StationTransferInformationOpeningHoursInput } from "../../../../shared/types/schema";

export default (openingHour: StationTransferInformationOpeningHoursInput) => {
  const { weekday, start, end } = openingHour;
  const timeFormat = getUserPreferredTimeFormat();

  return {
    weekday,
    start:
      timeFormat === TimeFormat.TWELVE_HOUR_CLOCK ? convert24hTo12hFormat(start) : start,
    end: timeFormat === TimeFormat.TWELVE_HOUR_CLOCK ? convert24hTo12hFormat(end) : end,
  };
};
