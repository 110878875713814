@import "variables";

.notificationContainer {
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 0;
  margin-bottom: $spacing-2;
  @include on-bp(xl) {
    margin-bottom: 60px;
  }
}
