import { Skeleton } from "@flixbus/honeycomb-react";
import { DefaultInfoAlert, legacyTranslate } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { useIntl } from "react-intl";
import Select from "../../../../shared/components/select/Select";
import { Schedule } from "../../../../shared/types/schema";
import { useFindAllSchedulesQuery } from "../../api/operations.generated";
import filterSchedulesForImport from "../../helpers/filterSchedulesForPriceImport/filterSchedulesForPriceImport";
import * as css from "./ImportPricingPopupBody.scss";

export type ImportPricingPopupBodyProps = {
  scheduleId: Schedule["id"];
  onScheduleSelected: (schedule: Schedule["id"]) => void;
};

const ImportPricingPopupBody: React.FC<ImportPricingPopupBodyProps> = ({
  scheduleId,
  onScheduleSelected,
}) => {
  const { formatMessage } = useIntl();
  const {
    data: allSchedules,
    loading,
    error,
  } = useFindAllSchedulesQuery({
    variables: { scheduleId },
  });

  if (error) {
    return (
      <DefaultInfoAlert
        data-id="query-error"
        message={formatMessage({ id: "general.somethingWentWrong" })}
        translate={legacyTranslate}
      />
    );
  }

  if (loading) {
    return (
      <>
        <Skeleton Elem="div" width="xs" height="sm" />
        <Skeleton width="md" height="lg" />
      </>
    );
  }

  if (allSchedules?.findSchedule) {
    const schedulesForImport = filterSchedulesForImport(
      allSchedules.findSchedule.line.schedules,
      scheduleId
    );

    if (schedulesForImport.length) {
      return (
        <div className={css.wrapper}>
          <Select
            label={formatMessage({ id: "general.schedule" })}
            placeholder={formatMessage({
              id: "prices.import.selectSchedule.placeholder",
            })}
            options={schedulesForImport}
            onSelect={(selectedValue) => {
              onScheduleSelected(selectedValue.value);
            }}
          />
        </div>
      );
    }
    return (
      <>
        <DefaultInfoAlert
          data-id="no-schedules-found-error"
          type="info"
          message={formatMessage({ id: "prices.import.popup.info.noSchedulesFound" })}
          translate={legacyTranslate}
        />
        <style>
          {
            "#confirmation-popup-confirm-button { display: none } #import-pricing-overwriting-warning { display: none }"
          }
        </style>
      </>
    );
  }

  return <></>;
};

export default ImportPricingPopupBody;
