import { DataTableHead } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage } from "react-intl";

const tableHeader = (
  <DataTableHead>
    <th>
      <FormattedMessage id="general.name" />
    </th>
    <th>
      <FormattedMessage id="general.email" />
    </th>
    <th>
      <FormattedMessage id="general.access" />
    </th>
  </DataTableHead>
);

export default tableHeader;
