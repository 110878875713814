const multiSelectStyles = () => ({
  multiValue: (provided: any) => ({
    ...provided,
    backgroundColor: "var(--hcr-grayscale-0-color)",
    border: "1px solid var(--hcr-grayscale-50-color)",
    height: "28px",
    borderRadius: "28px",
    lineHeight: "22px",
    alignItems: "center",
    padding: "3px 12px",
    maxWidth: "145px",
  }),
  multiValueLabel: (provided: any) => ({
    ...provided,
    fontSize: "var(--hcr-font-size-primary)",
    fontWeight: "400",
    paddingLeft: "0",
    paddingRight: "0",
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    paddingLeft: "6px",
    paddingRight: "0",
    "&:hover": {
      backgroundColor: "var(--hcr-grayscale-0-color)",
    },
  }),
});

export default multiSelectStyles;
