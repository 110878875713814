import { formatMessage } from "@flixbus-phx/marketplace-common";
import { SelectOption } from "../../../../helpers/reactSelect/types";

export default (value: string, options: Array<SelectOption>) => {
  const option = options.find((opt) => opt.value === value);

  if (option) {
    return option.label;
  }

  return formatMessage("general.notAvailable");
};
