@import "../../../../shared/styles/form-elements";
@import "../../../../shared/styles/variables";

.options {
  margin-top: $spacing-2;
}

.fineprint {
  @include fineprint-below-input;
}
