@import "variables";

.wrapper {
  display: flex;
  height: 240px;
}

.nav {
  width: 250px;
}

.content {
  width: 280px;
  padding: 0 $spacing-4;
}

.inactiveNavItem {
  padding-left: 9px;
}

.arrowIcon {
  width: $spacing-4 !important;
  position: absolute;
  right: $spacing-1;
  > svg {
    fill: unset !important;
  }
}
