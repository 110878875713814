.table {
  width: auto;

  tr {
    td:nth-child(1) {
      width: 140px;
    }

    td:nth-child(2) {
      width: 110px;
    }

    td:nth-child(3) {
      width: 150px;
    }

    td:nth-child(4) {
      width: 100px;
    }
  }
}
