import { Grid, GridCol } from "@flixbus/honeycomb-react";
import cx from "classnames";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import * as css from "./StationCellLabel.scss";

type Props = {
  showDeparture: boolean;
  showArrival: boolean;
};

const StationCellLabel: React.FC<Props> = ({ showDeparture, showArrival }) => {
  return (
    <div
      data-id="container"
      className={cx(
        css.container,
        showDeparture && showArrival ? css.fullHeightContainer : ""
      )}
    >
      {showArrival && (
        <Grid>
          <GridCol size={12}>
            <div className={css.arrText} data-id="arr-text">
              <FormattedMessage id="trip.arrival" />
            </div>
          </GridCol>
        </Grid>
      )}
      {showDeparture && (
        <Grid>
          <GridCol size={12}>
            <div
              className={showArrival ? css.depText : css.depTextWithoutArrival}
              data-id="dep-text"
            >
              <FormattedMessage id="trip.departure" />
            </div>
          </GridCol>
        </Grid>
      )}
    </div>
  );
};

export default StationCellLabel;
