import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { PageSize } from "../../types/schema";
import PagerQuantity from "../pagerQuantity/PagerQuantity";
import Select from "../select/Select";
import * as css from "./Pager.scss";

export type PagerProps = {
  totalPages?: number;
  currentPage?: number;
  hasPrevious?: boolean;
  hasNext?: boolean;
  setPage: (page: number) => void;
  total?: number;
  pageSize?: PageSize;
  setPageSize: (size: PageSize) => void;
};

const Pager: React.FC<PagerProps> = ({
  totalPages = 1,
  currentPage = 0,
  hasPrevious = false,
  hasNext = false,
  setPage = () => {},
  total = 0,
  pageSize = PageSize.Twentyfive,
  setPageSize = () => {},
}) => {
  const { formatMessage } = useIntl();

  const pageSizeOptions = [
    { label: "25", value: PageSize.Twentyfive },
    ...(total > 25 ? [{ label: "50", value: PageSize.Fifty }] : []),
    ...(total > 50 ? [{ label: "100", value: PageSize.Hundred }] : []),
  ];

  return (
    <div className={css.pagerWrapper}>
      <div className={css.pagerItem}>
        <div className={css.select}>
          <Select
            options={pageSizeOptions}
            initialValue={pageSizeOptions.find((option) => option.value === pageSize)}
            onSelect={({ value }) => setPageSize(value as PageSize)}
            isDisabled={pageSizeOptions.length <= 1}
            isDropUp
          />
        </div>
        <div className={css.label}>
          <FormattedMessage id="pager.pageSize.label" />
        </div>
      </div>
      <div className={css.pagerItem}>
        <div
          className={css.label}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: formatMessage(
              { id: "pager.page.label" },
              {
                currentPage: currentPage + 1,
                totalPages,
              },
              { ignoreTag: true }
            ),
          }}
        />

        <PagerQuantity
          totalPages={totalPages}
          currentPage={currentPage}
          hasPrevious={hasPrevious}
          hasNext={hasNext}
          setPage={setPage}
        />
      </div>
    </div>
  );
};

export default Pager;
