import { Heading } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import CreatePartner from "../../partner/createPartner/CreatePartner";
import Layout from "../../ui/Layout";

const CreatePartnerView: React.FC = () => (
  <Layout>
    <Heading size={1}>
      <FormattedMessage id="partner.create" />
    </Heading>
    <CreatePartner />
  </Layout>
);

export default CreatePartnerView;
