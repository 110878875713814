import { Box } from "@flixbus/honeycomb-react";
import * as React from "react";
import { LinesOfPartnersQuery } from "../../api/operations.generated";
import lineComparator from "../../helpers/lineComparator/lineComparator";
import LineListAccordion from "../lineListAccordion/LineListAccordion";
import * as css from "./PartnerLineListOverview.scss";

type Props = {
  lines: LinesOfPartnersQuery["findPartner"]["lines"];
};

const PartnerLineListOverview: React.FC<Props> = ({ lines }) => {
  const sortedLines = lines.slice().sort(lineComparator);

  return (
    <Box extraClasses={css.listWrapper} data-id="partner-line-list-overview">
      {sortedLines.map((line) => (
        <LineListAccordion line={line} key={line.id} />
      ))}
    </Box>
  );
};

export default PartnerLineListOverview;
