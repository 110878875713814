@import "variables";

.icon {
  margin-right: $spacing-1;
}

.iconArrow {
  color: $icon-secondary-color;
}

.iconForbidden {
  color: $danger-color;
}
