import * as React from "react";
import * as css from "./ReadOnlyFormField.scss";

type Props = {
  label?: string | JSX.Element;
};

const ReadOnlyFormField: React.FC<Props> = ({ label, children }) => {
  return (
    <div className={css.wrapper}>
      {label && <div className={css.label}>{label}</div>}
      <div className={css.value}>{children}</div>
    </div>
  );
};

export default ReadOnlyFormField;
