.serviceDays {
  ul {
    li {
      label {
        // Fix for languages with longer letters and user who zoom out
        // https://flixtech.atlassian.net/browse/MSSD-97 https://flixtech.atlassian.net/browse/MSSD-111
        font-size: 13px;
        text-align: center;
      }
    }
  }
}
