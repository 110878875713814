import { Skeleton } from "@flixbus/honeycomb-react";
import * as React from "react";
import { useIntl } from "react-intl";
import FormikAutocomplete from "../../shared/components/formikAutocomplete/FormikAutocomplete";
import SelectApiError from "../../shared/components/selectApiError/SelectApiError";
import sortOptionsByLabel from "../../shared/helpers/autocompleteOptions/sortOptionsByLabel";
import { SelectOption } from "../../shared/helpers/reactSelect/types";
import { useFindAllCitiesQuery } from "./api/operations.generated";

export type FormikCitySelectProps = {
  name: string;
  label: string;
  placeholder?: string;
  onCitySelected: (value: SelectOption["value"]) => void;
  onCreateNewCity: () => void;
  readOnly?: boolean;
  onInputChange?: (value: string) => void;
};

const FormikCitySelect: React.FC<FormikCitySelectProps> = ({
  name,
  label,
  placeholder,
  onCitySelected,
  readOnly,
  onCreateNewCity,
  onInputChange,
}) => {
  const {
    loading,
    data: allCitiesData,
    error,
  } = useFindAllCitiesQuery({
    fetchPolicy: "cache-and-network",
  });
  const { formatMessage } = useIntl();

  if (loading) {
    return (
      <>
        <Skeleton height="sm" width="sm" />
        <Skeleton height="lg" flushBottom />
      </>
    );
  }

  if (allCitiesData?.findAllCities) {
    const options = sortOptionsByLabel(allCitiesData.findAllCities);

    return (
      <FormikAutocomplete
        name={name}
        label={label}
        placeholder={placeholder}
        options={options}
        noOptionsAction={{
          action: onCreateNewCity,
          message: formatMessage({ id: "navigation.createNewCity" }),
        }}
        onValueSelected={onCitySelected}
        readOnly={readOnly}
        onInputChange={onInputChange}
        required
      />
    );
  }

  if (error && !loading) {
    return (
      <SelectApiError label={label} info={formatMessage({ id: "error.errorOccured" })} />
    );
  }

  return <></>;
};

export default FormikCitySelect;
