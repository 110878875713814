import { Heading } from "@flixbus/honeycomb-react";
import { Feature, hasUserPermission } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Line } from "../../../../shared/types/schema";
import ScheduleAddButton from "../scheduleAddButton/ScheduleAddButton";
import * as css from "./ScheduleCalendarHeader.scss";

export type ScheduleCalendarHeaderProps = {
  lineId: Line["id"];
};

const ScheduleCalendarHeader: React.FC<ScheduleCalendarHeaderProps> = ({ lineId }) => {
  return (
    <div className={css.wrapper}>
      <div>
        <Heading size={2} flushSpace>
          <FormattedMessage id="lineDetail.schedules.headline" />
        </Heading>
      </div>
      <div>
        {hasUserPermission(Feature.CREATE_SCHEDULE) && (
          <ScheduleAddButton lineId={lineId} />
        )}
      </div>
    </div>
  );
};

export default ScheduleCalendarHeader;
