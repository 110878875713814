import * as React from "react";
import Breakpoint from "../../helper/breakpoint/Breakpoint";
import useIsInRange from "../../hooks/useIsInRange/useIsInRange";

type Props = {
  hidden: Array<Breakpoint>;
};

const ResponsiveRenderer: React.FC<Props> = ({ hidden, children }) => {
  const isInRangeToBeHidden = useIsInRange(hidden);

  if (isInRangeToBeHidden) {
    return <></>;
  }

  return <>{children}</>;
};

export default ResponsiveRenderer;
