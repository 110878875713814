import { Icon, IconEdit } from "@flixbus/honeycomb-icons-react";
import { Button, DataTable, DataTableHead, DataTableRow } from "@flixbus/honeycomb-react";
import * as React from "react";
import { useIntl } from "react-intl";
import {
  FindAllCountriesQuery,
  useUpdateCountryMutation,
} from "../../api/operations.generated";
import CountryApproverForm from "../countryApproverForm/CountryApproverForm";
import * as css from "./CountryListTable.scss";

type Props = {
  countries: FindAllCountriesQuery["findAllCountries"];
};

const CountryListTable: React.FC<Props> = ({ countries }) => {
  const { formatMessage } = useIntl();
  const [acitvatedForm, setActivatedForm] = React.useState<string>();

  const [updateCountryMutation, { loading }] = useUpdateCountryMutation({
    onCompleted: () => setActivatedForm(undefined),
  });

  return (
    <DataTable
      small
      extraClasses={css.table}
      headers={
        <DataTableHead>
          <th>{formatMessage({ id: "general.country" })}</th>
          <th>Approver</th>
          <th>{formatMessage({ id: "general.action" })}</th>
        </DataTableHead>
      }
    >
      {countries.map((country) => {
        const isRowActive = acitvatedForm === country.id;
        return (
          <DataTableRow key={country.id}>
            <td>{formatMessage({ id: `country.${country.code}` })}</td>
            <td>
              <CountryApproverForm
                defaultValues={country.approver}
                readOnly={!isRowActive}
                onSubmit={(values) => {
                  updateCountryMutation({
                    variables: { id: country.id, approver: values },
                  });
                }}
                onCancel={() => setActivatedForm(undefined)}
                submitLoading={loading}
              />
            </td>
            <td>
              {isRowActive ? null : (
                <Button
                  display="square"
                  aria-label="edit country"
                  onClick={() => setActivatedForm(country.id)}
                >
                  <Icon InlineIcon={IconEdit} />
                </Button>
              )}
            </td>
          </DataTableRow>
        );
      })}
    </DataTable>
  );
};

export default CountryListTable;
