import { IconTime } from "@flixbus/honeycomb-icons-react";
import { Box, FormRow } from "@flixbus/honeycomb-react";
import { useField } from "formik2";
import * as React from "react";
import { useIntl } from "react-intl";
import FormikCheckbox from "../../../../shared/components/formikCheckbox/FormikCheckbox";
import FormikInput from "../../../../shared/components/formikInput/FormikInput";
import { StationTransferInformationOpeningHours } from "../../../../shared/types/schema";
import TransferOpeningHoursDaySelect from "../transferOpeningHoursDaySelect/TransferOpeningHoursDaySelect";
import TransferOpeningHoursFormRow from "../transferOpeningHoursFormRow/TransferOpeningHoursFormRow";
import * as css from "./TransferOpeningHoursInput.scss";

type Props = {
  readOnly?: boolean;
};

const TransferOpeningHoursInput: React.FC<Props> = ({ readOnly }) => {
  const { formatMessage } = useIntl();

  const [, metaIsTransferStation] = useField<boolean>(
    "transferInformation.isTransferStation"
  );
  const [, metaOpeningHours] = useField<
    Array<Array<StationTransferInformationOpeningHours>>
  >("transferInformation.transferOpeningHours");

  return (
    <Box extraClasses={css.box}>
      <FormRow extraClasses={css.checkBox}>
        <FormikCheckbox
          label={formatMessage({ id: "station.transferStation" })}
          name="transferInformation.isTransferStation"
          readOnly={readOnly}
        />
      </FormRow>
      {metaIsTransferStation.value && (
        <>
          <FormRow>
            <FormikInput
              label={`${formatMessage({
                id: "station.minTransferTime",
              })} (${formatMessage({ id: "unit.minutes" })})`}
              name="transferInformation.minimumTransferMinutes"
              type="number"
              icon={IconTime}
              required
              readOnly={readOnly}
            />
          </FormRow>

          <FormRow>
            {!readOnly && <TransferOpeningHoursDaySelect />}
            {metaOpeningHours.value.map((openingHoursPerDay, indexOfDay) => {
              return openingHoursPerDay.map((openingHour, indexWithinDay, arr) => {
                return (
                  <TransferOpeningHoursFormRow
                    // Rerendering when the index changes is exactly what we want here, thats why we
                    // eslint-disable-next-line react/no-array-index-key
                    key={indexWithinDay}
                    indexOfDay={indexOfDay}
                    indexWithinDay={indexWithinDay}
                    isLastWithinDay={indexWithinDay === arr.length - 1}
                    readOnly={readOnly}
                  />
                );
              });
            })}
          </FormRow>
        </>
      )}
    </Box>
  );
};

export default TransferOpeningHoursInput;
