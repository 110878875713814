import { Icon, IconComponent } from "@flixbus/honeycomb-icons-react";
import { Button, ButtonProps, Grid, GridCol } from "@flixbus/honeycomb-react";
import { useFormikContext } from "formik2";
import * as React from "react";
import * as css from "./FormikButtons.scss";

export type FormikButtonsProps = {
  submitButtonText: string;
  submitButtonLoading?: boolean;
  submitButtonAppearance: ButtonProps<"button">["appearance"];
  submitButtonIcon?: IconComponent;
  disableSubmitWhenNotDirty?: boolean;
  resetButtonText?: string;
  resetButtonAppearance?: ButtonProps<"button">["appearance"];
  resetButtonIcon?: IconComponent;
};

const FormikButtons: React.FC<FormikButtonsProps> = ({
  submitButtonText,
  submitButtonLoading = false,
  submitButtonAppearance,
  submitButtonIcon,
  disableSubmitWhenNotDirty = false,
  resetButtonText,
  resetButtonAppearance,
  resetButtonIcon,
  children,
}) => {
  const { dirty } = useFormikContext();

  return (
    <div className={css.buttonWrapper}>
      <Grid justify="right">
        <GridCol size={6}>{children || <></>}</GridCol>
        <GridCol size={6}>
          <div className={css.rightButtonsWrapper}>
            {resetButtonText && (
              <Button type="reset" appearance={resetButtonAppearance}>
                {resetButtonIcon && <Icon InlineIcon={resetButtonIcon} />}
                {resetButtonText}
              </Button>
            )}
            <Button
              type="submit"
              appearance={submitButtonAppearance}
              loading={submitButtonLoading}
              disabled={submitButtonLoading || (disableSubmitWhenNotDirty && !dirty)}
            >
              {submitButtonText}
              {submitButtonIcon && <Icon InlineIcon={submitButtonIcon} />}
            </Button>
          </div>
        </GridCol>
      </Grid>
    </div>
  );
};

export default FormikButtons;
