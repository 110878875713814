import { useField } from "formik2";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import getUserPreferredTimeFormat from "../../helpers/userPreferredTimeFormat/userPreferredTimeFormat";
import isValid from "../../helpers/validateFormikInput/validateFormikInput";
import ReadOnlyFormField from "../readOnlyFormField/ReadOnlyFormField";
import TimeInput from "../timeInput/TimeInput";
import * as css from "./FormikTimeInput.scss";

export type Props = {
  name: string;
  label?: string;
  readOnly?: boolean;
  required?: boolean;
};

/**
 * Don't forget to add the <TimeFormatPopup /> at view-level, when using this component
 * It is needed for the <TimeInput /> which is used by this component
 */

const FormikTimeInput: React.FC<Props> = ({
  name,
  label,
  readOnly = false,
  required,
}) => {
  const timeFormat = getUserPreferredTimeFormat();

  const [, meta, helper] = useField(name);

  if (readOnly) {
    return (
      <ReadOnlyFormField label={label}>
        {meta.value || <FormattedMessage id="general.notAvailable" />}
      </ReadOnlyFormField>
    );
  }

  return (
    <div className={css.wrapper}>
      <TimeInput
        value={meta.value}
        onValueChanged={helper.setValue}
        timeFormat={timeFormat}
        label={label}
        required={required}
        valid={isValid(meta)}
        infoError={meta.error}
        onBlur={() => helper.setTouched(true, false)}
      />
    </div>
  );
};

export default FormikTimeInput;
