import { formatMessage } from "@flixbus-phx/marketplace-common";
import getWeekdayText from "../../../../shared/helpers/getWeekdayText/getWeekdayText";
import { DayOfWeek } from "../../../../shared/types/schema";

const getReadonlyDays = (days: Array<DayOfWeek | undefined>) => {
  const text = days
    .filter((day): day is DayOfWeek => !!day)
    .map((d) => getWeekdayText(d))
    .join(", ");

  if (!text) {
    return formatMessage("general.notAvailable");
  }

  return text;
};

export default getReadonlyDays;
