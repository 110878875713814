import citiesFilterVar from "../../../../state/citiesFilter/citiesFilter";
import { stationsFilterVar } from "../../../../state/stationsFilter/stationsFilter";

export const clearCitiesFilter = () => {
  citiesFilterVar({
    name: null,
    country: null,
    importanceClass: null,
    published: null,
  });
};

export const clearStationsFilter = () => {
  stationsFilterVar({
    name: null,
    code: null,
    city: null,
    country: null,
    status: null,
  });
};
