// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// This file was automatically generated using npm run codegen
// If you want to alter this file please do so via the above mentioned command
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
import * as Types from "../../../shared/types/schema";

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
const defaultOptions = {} as const;
export type StationSequenceFragment = {
  __typename?: "Station";
  id: string;
  name: string;
  isPublished: boolean;
};

export type StationTimeFragment = {
  __typename?: "StationTime";
  arrivalTime?: string | null;
  arrivalOffsetWeekdays: number;
  departureTime?: string | null;
  departureOffsetWeekdays: number;
  timeToStation?: string | null;
  station: { __typename?: "Station"; id: string };
};

export type TripItemFragment = {
  __typename?: "Trip";
  id: string;
  number: number;
  direction: Types.TripDirection;
  readOnly: boolean;
  totalTripDuration: string;
  serviceDays: {
    __typename?: "ServiceDays";
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;
  };
  stationTimes: Array<{
    __typename?: "StationTime";
    arrivalTime?: string | null;
    arrivalOffsetWeekdays: number;
    departureTime?: string | null;
    departureOffsetWeekdays: number;
    timeToStation?: string | null;
    station: { __typename?: "Station"; id: string };
  }>;
};

export type ExchangeStationMutationVariables = Types.Exact<{
  scheduleId: Types.Scalars["ID"]["input"];
  originalStationId: Types.Scalars["ID"]["input"];
  exchangeStationId: Types.Scalars["ID"]["input"];
}>;

export type ExchangeStationMutation = {
  __typename?: "Mutation";
  exchangeStation?: {
    __typename?: "Schedule";
    id: string;
    stations: Array<{
      __typename?: "Station";
      id: string;
      name: string;
      isPublished: boolean;
    }>;
    trips: Array<{
      __typename?: "Trip";
      id: string;
      number: number;
      direction: Types.TripDirection;
      readOnly: boolean;
      totalTripDuration: string;
      serviceDays: {
        __typename?: "ServiceDays";
        monday: boolean;
        tuesday: boolean;
        wednesday: boolean;
        thursday: boolean;
        friday: boolean;
        saturday: boolean;
        sunday: boolean;
      };
      stationTimes: Array<{
        __typename?: "StationTime";
        arrivalTime?: string | null;
        arrivalOffsetWeekdays: number;
        departureTime?: string | null;
        departureOffsetWeekdays: number;
        timeToStation?: string | null;
        station: { __typename?: "Station"; id: string };
      }>;
    }>;
  } | null;
};

export const StationSequenceFragmentDoc = gql`
  fragment StationSequence on Station {
    id
    name
    isPublished
  }
`;
export const StationTimeFragmentDoc = gql`
  fragment StationTime on StationTime {
    station {
      id
    }
    arrivalTime
    arrivalOffsetWeekdays
    departureTime
    departureOffsetWeekdays
    timeToStation
  }
`;
export const TripItemFragmentDoc = gql`
  fragment TripItem on Trip {
    id
    number
    direction
    readOnly
    totalTripDuration
    serviceDays {
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      sunday
    }
    stationTimes {
      ...StationTime
    }
  }
  ${StationTimeFragmentDoc}
`;
export const ExchangeStationDocument = gql`
  mutation exchangeStation(
    $scheduleId: ID!
    $originalStationId: ID!
    $exchangeStationId: ID!
  ) {
    exchangeStation(
      scheduleId: $scheduleId
      originalStationId: $originalStationId
      exchangeStationId: $exchangeStationId
    ) {
      id
      stations {
        ...StationSequence
      }
      trips {
        ...TripItem
      }
    }
  }
  ${StationSequenceFragmentDoc}
  ${TripItemFragmentDoc}
`;

/**
 * __useExchangeStationMutation__
 *
 * To run a mutation, you first call `useExchangeStationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExchangeStationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exchangeStationMutation, { data, loading, error }] = useExchangeStationMutation({
 *   variables: {
 *      scheduleId: // value for 'scheduleId'
 *      originalStationId: // value for 'originalStationId'
 *      exchangeStationId: // value for 'exchangeStationId'
 *   },
 * });
 */
export function useExchangeStationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ExchangeStationMutation,
    ExchangeStationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ExchangeStationMutation,
    ExchangeStationMutationVariables
  >(ExchangeStationDocument, options);
}
export type ExchangeStationMutationHookResult = ReturnType<
  typeof useExchangeStationMutation
>;
