import { Icon, IconArrowUp } from "@flixbus/honeycomb-icons-react";
import * as React from "react";
import { components, DropdownIndicatorProps } from "react-select";
import { SelectOption } from "../types";

type IndicatorProps = DropdownIndicatorProps<SelectOption, false>;

const DropupIndicator: React.FC<IndicatorProps> = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon InlineIcon={IconArrowUp} size={4} />
    </components.DropdownIndicator>
  );
};

export default DropupIndicator;
