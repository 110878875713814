import { localizeMonth } from "@flixbus-phx/marketplace-common";
import cx from "classnames";
import { isThisMonth as dateFnsisThisMonth } from "date-fns";
import * as React from "react";
import * as css from "./MonthHeaderCell.scss";

type Props = {
  currentDate: Date;
  cellWidth: number;
  colStart: number;
};

const MonthHeaderCell: React.FC<Props> = ({ currentDate, cellWidth, colStart }) => {
  const isThisMonth = dateFnsisThisMonth(currentDate);

  return (
    <div
      className={cx(css.scrollableColumn)}
      style={{
        width: `${cellWidth}px`,
        transform: `translateX(${colStart}px)`,
      }}
    >
      <div className={cx(css.cell, isThisMonth && css.thisMonth)}>
        {/* <div className={cx(css.month, isThisMonth && css.thisMonth)}> */}
        {localizeMonth(currentDate)}
        {/* </div> */}
      </div>
    </div>
  );
};

export default MonthHeaderCell;
