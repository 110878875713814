export type Validation200 = {
  parsedDataRows: number;
};

export type Validation422 = {
  errorsPerRow: Array<{ row: number; errors: Array<string> }>;
  message: string;
  status: string;
};

export enum Validation400ErrorReasons {
  FAILED_ON_SERVER = "FAILED_ON_SERVER",
  INCORRECT_FILE_SIZE = "INCORRECT_FILE_SIZE",
  INCORRECT_FILE_TYPE = "INCORRECT_FILE_TYPE",
}

export type Validation400 = Array<Validation400ErrorReasons>;

export type ValidationResponseTypes = Validation200 | Validation422 | Validation400;

export const isValidation200 = (res: ValidationResponseTypes): res is Validation200 => {
  return (res as Validation200).parsedDataRows !== undefined;
};

export const isValidation422 = (res: ValidationResponseTypes): res is Validation422 => {
  return (res as Validation422).errorsPerRow !== undefined;
};

export const isValidation400 = (res: ValidationResponseTypes): res is Validation400 => {
  return !isValidation200(res) && !isValidation422(res);
};

export const isImport200 = (
  res: Validation422 | Validation400 | Response
): res is Response => {
  return (res as Response).blob !== undefined;
};
