import hcPackage from '../../package.json.js';

var verSuffix = hcPackage.version.replace(/\./g, '-');
/**
 * Here we basically simulate the output of "sass-unique-classes loader" existing configuration.
 * The output is a CSS string with all the design tokens and theme default styles.
 * @param themes
 */
var generateStylesObject = function (themes) {
  var themeNames = Object.keys(themes);
  var cssString = themeNames.map(function (themeName) {
    var themeTokens = Object.keys(themes[themeName]);
    var themeTokenValues = Object.values(themes[themeName]);
    // @todo find a better way to reduce the style duplication
    return "\n.hcr-theme-".concat(themeName, "-").concat(verSuffix).concat(themeName === 'default' ? ', :root' : '', " {\n  ").concat(themeTokens.map(function (token, tokenIndex) {
      return "--hcr-".concat(token, ": ").concat(themeTokenValues[tokenIndex], ";");
    }).join(' '), "\n}\n.hcr-theme-").concat(themeName, "-").concat(verSuffix).concat(themeName === 'default' ? ', :root' : '', " {\n  color: var(--hcr-content-primary-color);\n  font-family: var(--hcr-font-family-primary);\n  font-size: var(--hcr-font-size-primary);\n  font-weight: var(--hcr-font-weight-normal);\n  line-height: var(--hcr-line-height-primary);\n}");
  });
  return {
    raw: cssString.join(' ')
  };
};

export { generateStylesObject };
