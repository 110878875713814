import {
  ErrorPage,
  ErrorTypes,
  Feature,
  hasUserPermission,
  legacyTranslate,
  mpcPathnames as pathnames,
} from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { Route, Routes } from "react-router-dom";
import CreateLineView from "../../views/createLineView/CreateLineView";
import CreatePartnerView from "../../views/createPartnerView/CreatePartnerView";
import CreateScheduleView from "../../views/createScheduleView/CreateScheduleView";
import ShowCitiesAndStations from "../../views/showCitiesAndStations/ShowCitiesAndStations";
import ShowCountries from "../../views/showCountries/ShowCountries";
import ShowLineDetail from "../../views/showLineDetail/ShowLineDetail";
import ShowLineDetailV2 from "../../views/showLineDetailV2/ShowLineDetailV2";
import ShowPartnerDetail from "../../views/showPartnerDetail/ShowPartnerDetail";
import ShowScheduleDetail from "../../views/showScheduleDetail/ShowScheduleDetail";
import ShowScheduleDetailV2 from "../../views/showScheduleDetailV2/ShowScheduleDetailV2";
import ShowSearch from "../../views/showSearch/ShowSearch";

type RoutesObjectItem = {
  exact: boolean;
  element: React.ReactNode | null;
};

type RoutesObject = {
  userHome: RoutesObjectItem;
  createPartner: RoutesObjectItem;
  showPartnerDetail: RoutesObjectItem;
  createLine: RoutesObjectItem;
  showLineDetail: RoutesObjectItem;
  showLineDetailV2: RoutesObjectItem;
  createSchedule: RoutesObjectItem;
  showScheduleDetail: RoutesObjectItem;
  showScheduleDetailV2: RoutesObjectItem;
  showStations: RoutesObjectItem;
  showCountries: RoutesObjectItem;
};

export const Page404 = () => (
  <ErrorPage type={ErrorTypes.e404} translate={legacyTranslate} />
);

const returnPageOr404 = (
  page: JSX.Element,
  feature = Feature.USER_HAS_VIEW_ACCESS_TO_ALL_PAGES
): RoutesObjectItem => {
  return {
    exact: true,
    element: hasUserPermission(feature) ? page : <Page404 />,
  };
};

const Router = () => {
  const routes: RoutesObject = {
    userHome: {
      exact: true,
      element: hasUserPermission(Feature.USER_HAS_VIEW_ACCESS_TO_ALL_PAGES) ? (
        hasUserPermission(Feature.VIEW_PARTNER_LIST) ? (
          <ShowSearch />
        ) : (
          <ShowPartnerDetail />
        )
      ) : (
        <ShowCitiesAndStations />
      ),
    },
    createPartner: returnPageOr404(<CreatePartnerView />, Feature.CREATE_PARTNER),
    showPartnerDetail: returnPageOr404(<ShowPartnerDetail />),
    createLine: returnPageOr404(<CreateLineView />, Feature.CREATE_LINE),
    showLineDetail: returnPageOr404(<ShowLineDetail />),
    showLineDetailV2: returnPageOr404(
      <ShowLineDetailV2 />,
      Feature.VIEW_NEW_SCHEDULE_VIEW
    ),
    createSchedule: returnPageOr404(<CreateScheduleView />, Feature.CREATE_SCHEDULE),
    showScheduleDetail: returnPageOr404(<ShowScheduleDetail />),
    showScheduleDetailV2: returnPageOr404(
      <ShowScheduleDetailV2 />,
      Feature.VIEW_NEW_SCHEDULE_VIEW
    ),
    showCountries: returnPageOr404(<ShowCountries />, Feature.EDIT_CITY_STATION_APPROVER),
    showStations: {
      exact: true,
      element: <ShowCitiesAndStations />,
    },
  };

  return (
    <Routes>
      {(Object.keys(routes) as Array<keyof RoutesObject>).map((key) => {
        return <Route {...routes[key]} path={pathnames[key]} key={key} />;
      })}
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default Router;
