import * as React from "react";
import {
  FindNearbyStationsQuery,
  useFindNearbyStationsLazyQuery,
} from "./api/operations.generated";

export type NearbyStations = FindNearbyStationsQuery["findNearbyStations"];
export type UseNearbyStations = () => readonly [
  NearbyStations | undefined,
  boolean,
  (lat: string, lon: string, callback?: () => void) => void,
  React.Dispatch<React.SetStateAction<boolean>>,
];

const useNearbyStations = (): ReturnType<UseNearbyStations> => {
  const [findNearby, { data }] = useFindNearbyStationsLazyQuery();
  const [showNearby, setShowNearby] = React.useState(false);

  const triggerFindNearbyStations = (
    latitude: string,
    longitude: string,
    callback?: () => void
  ) => {
    findNearby({
      variables: { latitude, longitude },
      onCompleted: (newData) => {
        if (newData.findNearbyStations.length) {
          setShowNearby(true);
        } else {
          setShowNearby(false);
          if (callback) {
            callback();
          }
        }
      },
    });
  };

  return [
    data?.findNearbyStations,
    showNearby,
    triggerFindNearbyStations,
    setShowNearby,
  ] as const;
};

export default useNearbyStations;
