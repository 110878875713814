@import "variables";

.block {
  top: 0;
  position: sticky;
  z-index: 2000;

  li {
    min-width: 50%;
  }
}

.nav {
  margin-bottom: $spacing-3;
}
