export default async (locale: string, isUsedInternal: boolean) => {
  if (isUsedInternal) {
    switch (locale) {
      case "de":
        return import("../../../../../translationsInternal/de.json");
      case "en":
        return import("../../../../../translationsInternal/en.json");
      case "es":
        return import("../../../../../translationsInternal/es.json");
      case "fr":
        return import("../../../../../translationsInternal/fr.json");
      case "hr":
        return import("../../../../../translationsInternal/hr.json");
      case "it":
        return import("../../../../../translationsInternal/it.json");
      case "pl":
        return import("../../../../../translationsInternal/pl.json");
      case "pt_BR":
        return import("../../../../../translationsInternal/pt_BR.json");
      case "pt":
        return import("../../../../../translationsInternal/pt.json");
      case "tr":
        return import("../../../../../translationsInternal/tr.json");
      case "uk":
        return import("../../../../../translationsInternal/uk.json");
      default:
        return import("../../../../../translationsInternal/en.json");
    }
  } else {
    switch (locale) {
      case "at":
        return import("../../../../../translationsPublic/at.json");
      case "bg":
        return import("../../../../../translationsPublic/bg.json");
      case "bs":
        return import("../../../../../translationsPublic/bs.json");
      case "ca":
        return import("../../../../../translationsPublic/ca.json");
      case "cs":
        return import("../../../../../translationsPublic/cs.json");
      case "da":
        return import("../../../../../translationsPublic/da.json");
      case "de":
        return import("../../../../../translationsPublic/de.json");
      case "de_CH":
        return import("../../../../../translationsPublic/de_CH.json");
      case "en_CA":
        return import("../../../../../translationsPublic/en_CA.json");
      case "en_GB":
        return import("../../../../../translationsPublic/en_GB.json");
      case "en_US":
        return import("../../../../../translationsPublic/en_US.json");
      case "es":
        return import("../../../../../translationsPublic/es.json");
      case "es_MX":
        return import("../../../../../translationsPublic/es_MX.json");
      case "es_US":
        return import("../../../../../translationsPublic/es_US.json");
      case "et_EE":
        return import("../../../../../translationsPublic/et_EE.json");
      case "fr":
        return import("../../../../../translationsPublic/fr.json");
      case "fr_BE":
        return import("../../../../../translationsPublic/fr_BE.json");
      case "fr_CA":
        return import("../../../../../translationsPublic/fr_CA.json");
      case "fr_CH":
        return import("../../../../../translationsPublic/fr_CH.json");
      case "hr":
        return import("../../../../../translationsPublic/hr.json");
      case "hu":
        return import("../../../../../translationsPublic/hu.json");
      case "it":
        return import("../../../../../translationsPublic/it.json");
      case "it_CH":
        return import("../../../../../translationsPublic/it_CH.json");
      case "lt_LT":
        return import("../../../../../translationsPublic/lt-LT.json");
      case "lv_LV":
        return import("../../../../../translationsPublic/lv-LV.json");
      case "mk":
        return import("../../../../../translationsPublic/mk.json");
      case "nl":
        return import("../../../../../translationsPublic/nl.json");
      case "nl_BE":
        return import("../../../../../translationsPublic/nl_BE.json");
      case "no":
        return import("../../../../../translationsPublic/no.json");
      case "pl":
        return import("../../../../../translationsPublic/pl.json");
      case "pt":
        return import("../../../../../translationsPublic/pt.json");
      case "pt_BR":
        return import("../../../../../translationsPublic/pt_BR.json");
      case "ro":
        return import("../../../../../translationsPublic/ro.json");
      case "sk":
        return import("../../../../../translationsPublic/sk.json");
      case "sl":
        return import("../../../../../translationsPublic/sl.json");
      case "sq":
        return import("../../../../../translationsPublic/sq.json");
      case "sr_Latn_RS":
        return import("../../../../../translationsPublic/sr_Latn_RS.json");
      case "sv":
        return import("../../../../../translationsPublic/sv.json");
      case "tr":
        return import("../../../../../translationsPublic/tr.json");
      case "uk":
        return import("../../../../../translationsPublic/uk.json");
      case "zh_US":
        return import("../../../../../translationsPublic/zh_US.json");
      default:
        return import("../../../../../translationsPublic/en.json");
    }
  }
};
