import { formatMessage, uuidRegex } from "@flixbus-phx/marketplace-common";
import * as Yup from "yup";
import {
  AccessPackage,
  Currency,
  MeansOfTransport,
} from "../../../../shared/types/schema";

export default () => {
  return Yup.object().shape({
    name: Yup.string().required(formatMessage("error.validation.required")),
    concessionOwnerId: Yup.string().matches(
      uuidRegex,
      formatMessage("error.validation.mustBeUuid")
    ),
    businessRegionId: Yup.string().required(formatMessage("error.validation.required")),
    meansOfTransport: Yup.mixed()
      .nullable()
      .oneOf([...Object.values(MeansOfTransport), "", null]),
    accessPackage: Yup.mixed<AccessPackage>().oneOf(Object.values(AccessPackage)),
    currency: Yup.mixed<Currency>()
      .oneOf(Object.values(Currency))
      .required(formatMessage("error.validation.required")),
    businessDevelopers: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string()
            .matches(
              /^([^0-9!@#$%^&*(),.?":{}|<>§/=+;_[\]]*)$/,
              formatMessage("error.validation.noNumberOrSpecChar")
            )
            .required(formatMessage("error.validation.required")),
          email: Yup.string()
            .email(formatMessage("error.validation.mustBeEmail"))
            .required(formatMessage("error.validation.required")),
        })
      )
      .test({
        test(value) {
          if (
            value &&
            value[0].email &&
            value[1]?.email &&
            value[0].email === value[1].email
          ) {
            return this.createError({
              path: `businessDevelopers[1].email`,
              message: formatMessage("error.validation.emailInUse"),
            });
          }
          return true;
        },
      }),
  });
};
