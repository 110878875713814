import { useField } from "formik2";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import CustomFormRow from "../../../../shared/components/customFormRow/CustomFormRow";
import FormikCheckbox from "../../../../shared/components/formikCheckbox/FormikCheckbox";
import FormikTimeInput from "../../../../shared/components/formikTimeInput/FormikTimeInput";
import emptyCustomerServiceContactFormRow from "../../helpers/emptyCustomerServiceContactFormRow/emptyCustomerServiceContactFormRow";
import { CustomerServiceContactFormValue } from "../../types";
import RowButtons from "../rowButtons/RowButtons";
import * as css from "./HolidayWorkingHoursInput.scss";

type Props = {
  name: string;
  readOnly?: boolean;
};

const HolidayWorkingHoursInput: React.FC<Props> = ({ name, readOnly }) => {
  const { formatMessage } = useIntl();
  const [, meta, helper] =
    useField<CustomerServiceContactFormValue["holidayWorkingHours"]>(name);

  return (
    <>
      <div className={css.checkboxWrapper}>
        {readOnly ? (
          <div data-id="working-hours-read-only">
            <div className={css.headerReadonly}>
              <FormattedMessage id="partnerForm.customerServiceInformation.holidayWorkingHours.label.readOnly" />
            </div>
            {!meta.value.enabled && (
              <div className={css.timeNotAvailable}>
                <FormattedMessage id="general.notAvailable" />
              </div>
            )}
          </div>
        ) : (
          <FormikCheckbox
            label={formatMessage({
              id: "partnerForm.customerServiceInformation.holidayWorkingHours.label",
            })}
            name={`${name}.enabled`}
            small
          />
        )}
      </div>

      {meta.value.enabled &&
        meta.value.workingHours.map((_, i, arr) => (
          // Rerendering when the index changes is exactly what we want here, thats why we
          // eslint-disable-next-line react/no-array-index-key
          <CustomFormRow key={i}>
            <div data-id="holiday-working-hours-row" className={css.timeInputWrapper}>
              <FormikTimeInput
                label={formatMessage({ id: "general.fromTime" })}
                name={`${name}.workingHours[${i}].from`}
                readOnly={readOnly}
              />

              <FormikTimeInput
                label={formatMessage({ id: "general.toTime" })}
                name={`${name}.workingHours[${i}].to`}
                readOnly={readOnly}
              />

              {!readOnly && (
                <RowButtons
                  onAdd={() => {
                    helper.setValue({
                      ...meta.value,
                      workingHours: [
                        ...meta.value.workingHours,
                        emptyCustomerServiceContactFormRow.holidayWorkingHours
                          .workingHours[0],
                      ],
                    });
                  }}
                  onDelete={() => {
                    helper.setValue({
                      ...meta.value,
                      workingHours: [
                        ...meta.value.workingHours.slice(0, i),
                        ...meta.value.workingHours.slice(i + 1, arr.length),
                      ],
                    });
                  }}
                  indexOfRow={i}
                  totalNumOfRows={arr.length}
                />
              )}
            </div>
          </CustomFormRow>
        ))}
    </>
  );
};

export default HolidayWorkingHoursInput;
