import { isValid12hFormat } from "../timeValidator/twelveHourClockFormatValidator";
import { isValid24hFormat } from "../timeValidator/twentyFourHourClockFormatValidator";

export const convert12hTo24hFormat = (value: string): string => {
  if (!isValid12hFormat(value)) {
    return value;
  }

  const [time, suffix] = value.toUpperCase().split(" ");
  const [hours, minutes] = time.split(":");
  let formattedHours = hours;

  if (hours === "12") {
    formattedHours = "00";
  }

  if (suffix === "PM") {
    formattedHours = `${(parseInt(hours, 10) % 12) + 12}`;
  }

  return `${formattedHours}:${minutes}`;
};

export const convert24hTo12hFormat = (value: string): string => {
  if (!isValid24hFormat(value)) {
    return value;
  }

  const [hours, minutes] = value.split(":");
  const hoursInt = parseInt(hours, 10);
  let formattedHours = `${hoursInt % 12}`;
  let suffix = "AM";

  if (hoursInt === 0 || hoursInt === 12) {
    formattedHours = "12";
  } else if (hoursInt % 12 < 10) {
    formattedHours = `0${formattedHours}`;
  }

  if (hoursInt >= 12) {
    suffix = "PM";
  }

  return `${formattedHours}:${minutes} ${suffix}`;
};
