import { Button } from "@flixbus/honeycomb-react";
import * as React from "react";
import { commonPathnames } from "../../../../../helper";
import { TranslateFunctionProp } from "../../../../../helper/types";

type Props = {
  primary: Boolean;
};

const OpenTicketProblemButton: React.FC<Props & TranslateFunctionProp> = ({
  primary,
  translate,
}) => (
  <Button
    data-id="ticket-problem-button"
    appearance={primary ? "primary" : undefined}
    href={commonPathnames.serviceDeskReportProblem}
    Elem="a"
  >
    {translate("serviceDesk.button.text")}
  </Button>
);

export default OpenTicketProblemButton;
