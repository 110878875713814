/*
 * useEffect which runs on every rerender except the fist one
 * https://stackoverflow.com/a/73856816
 */

import * as React from "react";

const useEffectAfterMount = (
  cb: React.EffectCallback,
  dependencies: React.DependencyList | undefined
) => {
  const mounted = React.useRef(true);

  // eslint-disable-next-line consistent-return
  React.useEffect(() => {
    if (!mounted.current) {
      return cb();
    }
    mounted.current = false;
  }, dependencies);
};

export default useEffectAfterMount;
