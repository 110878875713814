import * as React from "react";
import { ForbiddenRelationStation } from "../../types";
import * as css from "./RelationStation.scss";

export type LineForbiddenRelationsFormProps = {
  station: ForbiddenRelationStation;
};

const RelationStation: React.FC<LineForbiddenRelationsFormProps> = ({ station }) => {
  return (
    <div>
      {station.code && (
        <>
          <span className={css.code}>{station.code}</span>
          <span> - </span>
        </>
      )}
      <span>{station.name}</span>
    </div>
  );
};

export default RelationStation;
