// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// This file was automatically generated using npm run codegen
// If you want to alter this file please do so via the above mentioned command
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
import * as Types from "../../../shared/types/schema";

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
const defaultOptions = {} as const;
export type NewPartnerMutationVariables = Types.Exact<{
  accountInput: Types.AccountInput;
  capacityAndReportsInput: Types.CapacityAndReportsInput;
  customerServiceAndPoliciesInput: Types.CustomerServiceAndPoliciesInput;
  brandsInput: Types.BrandsInput;
  partnerSettingsInput: Types.PartnerSettingsInput;
}>;

export type NewPartnerMutation = {
  __typename?: "Mutation";
  newPartner: { __typename?: "Partner"; id: string };
};

export const NewPartnerDocument = gql`
  mutation NewPartner(
    $accountInput: AccountInput!
    $capacityAndReportsInput: CapacityAndReportsInput!
    $customerServiceAndPoliciesInput: CustomerServiceAndPoliciesInput!
    $brandsInput: BrandsInput!
    $partnerSettingsInput: PartnerSettingsInput!
  ) {
    newPartner(
      accountInput: $accountInput
      capacityAndReportsInput: $capacityAndReportsInput
      customerServiceAndPoliciesInput: $customerServiceAndPoliciesInput
      brandsInput: $brandsInput
      partnerSettingsInput: $partnerSettingsInput
    ) {
      id
    }
  }
`;

/**
 * __useNewPartnerMutation__
 *
 * To run a mutation, you first call `useNewPartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewPartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newPartnerMutation, { data, loading, error }] = useNewPartnerMutation({
 *   variables: {
 *      accountInput: // value for 'accountInput'
 *      capacityAndReportsInput: // value for 'capacityAndReportsInput'
 *      customerServiceAndPoliciesInput: // value for 'customerServiceAndPoliciesInput'
 *      brandsInput: // value for 'brandsInput'
 *      partnerSettingsInput: // value for 'partnerSettingsInput'
 *   },
 * });
 */
export function useNewPartnerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    NewPartnerMutation,
    NewPartnerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<NewPartnerMutation, NewPartnerMutationVariables>(
    NewPartnerDocument,
    options
  );
}
export type NewPartnerMutationHookResult = ReturnType<typeof useNewPartnerMutation>;
