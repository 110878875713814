import { Feature, hasUserPermission } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { useIntl } from "react-intl";
import * as Yup from "yup";
import EditableInput from "../../../../shared/components/editableInput/EditableInput";
import { Schedule } from "../../../../shared/types/schema";

export type ScheduleNameProps = {
  name: Schedule["name"];
  readOnly: Schedule["readOnly"];
  onNameChange: (newName: Schedule["name"]) => void;
};

const ScheduleName: React.FC<ScheduleNameProps> = ({ name, readOnly, onNameChange }) => {
  const { formatMessage } = useIntl();
  const REQUIRED_STRING_VALIDATION = Yup.string().required(
    formatMessage({ id: "error.validation.required" })
  );
  return (
    <div data-id="schedule-name-wrapper">
      <EditableInput
        value={name}
        validationType={REQUIRED_STRING_VALIDATION}
        onValueChanged={(value) => onNameChange(value)}
        data-id="schedule-name"
        label={formatMessage({ id: "scheduleDetail.name.input.label" })}
        readOnly={readOnly || !hasUserPermission(Feature.EDIT_SCHEDULE_NAME_UNPUBLISHED)}
        isHeading
      />
    </div>
  );
};

export default ScheduleName;
