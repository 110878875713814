import { Button, FormRow, Grid, GridCol, PopupSection } from "@flixbus/honeycomb-react";
import { Popup } from "@flixbus-phx/marketplace-common";
import { Form, Formik } from "formik2";
import * as React from "react";
import { useIntl } from "react-intl";
import FormikMultiSelect from "../../../../shared/components/formikMultiSelect/FormikMultiSelect";
import { PartnerTag } from "../../../../shared/types/schema";
import * as css from "./AddCustomPartnerTagPopup.scss";

export type AddCustomPartnerTagPopupProps = {
  active: boolean;
  onPopupClose: () => void;
  onSubmit: (tags: PartnerTag[]) => void;
  options: { value: PartnerTag; label: string }[];
};

const AddCustomPartnerTagPopup: React.FC<AddCustomPartnerTagPopupProps> = ({
  active,
  onPopupClose,
  onSubmit,
  options,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Popup active={active} onClose={onPopupClose} allowOverflow>
      <PopupSection type="title">
        {formatMessage({ id: "addCustomPartnerTagPopup.title" })}
      </PopupSection>
      <PopupSection type="content">
        {active && (
          <Grid justify="center">
            <GridCol size={7}>
              <Formik
                initialValues={{ tags: [] }}
                onSubmit={(values) => {
                  onSubmit(values.tags);
                }}
                onReset={onPopupClose}
              >
                <Form noValidate>
                  <FormRow extraClasses={css.multiselectRow}>
                    <FormikMultiSelect
                      label={formatMessage({ id: "addCustomPartnerTagPopup.tagsLabel" })}
                      name="tags"
                      options={options}
                      placeholder={formatMessage({
                        id: "general.searchAndSelect",
                      })}
                      maxToShow={1}
                    />
                  </FormRow>

                  <div data-testid="form-buttons">
                    <Button type="reset" appearance="tertiary">
                      {formatMessage({ id: "general.cancel" })}
                    </Button>
                    <Button type="submit" appearance="primary">
                      {formatMessage({ id: "general.confirm" })}
                    </Button>
                  </div>
                </Form>
              </Formik>
            </GridCol>
          </Grid>
        )}
      </PopupSection>
    </Popup>
  );
};

export default AddCustomPartnerTagPopup;
