import getSubmittableUnitValues from "../../../../shared/helpers/getSubmittableUnitValues/getSubmittableUnitValues";
import {
  CustomerServiceAndPoliciesInput,
  TimeUnit,
} from "../../../../shared/types/schema";
import { PartnerCustomerServiceAndPoliciesFormValues } from "../../types";
import getSubmittableCustomerServiceContactValues from "../getSubmittableCustomerServiceContactValues/getSubmittableCustomerServiceContactValues";

const getSubmittableValues = (
  values: PartnerCustomerServiceAndPoliciesFormValues
): CustomerServiceAndPoliciesInput => {
  const { checkInRequirements, customerServiceInformation, termsAndConditions } = values;

  return {
    checkInRequirements: {
      checkInTimeStart: getSubmittableUnitValues<number, TimeUnit>(
        checkInRequirements.checkInTimeStart
      ),
      checkInTimeEnd: getSubmittableUnitValues<number, TimeUnit>(
        checkInRequirements.checkInTimeEnd
      ),
      checkInOptions: {
        website: checkInRequirements.checkInOptions.website.enabled
          ? checkInRequirements.checkInOptions.website.url
          : undefined,
        shop: checkInRequirements.checkInOptions.shop.enabled,
        checkInDesk: {
          enabled: checkInRequirements.checkInOptions.desk.enabled,
          currency: checkInRequirements.checkInOptions.desk.currency,
          fee: {
            value: checkInRequirements.checkInOptions.desk.fee
              ? Number.parseFloat(
                  checkInRequirements.checkInOptions.desk.fee.replace(",", ".")
                )
              : 0,
          },
          feeUnit: checkInRequirements.checkInOptions.desk.feeUnit,
        },
        selfServiceMachine: checkInRequirements.checkInOptions.selfServiceMachine.enabled,
      },
      proofOfIdentityRequired: checkInRequirements.proofOfIdentityRequired,
    },
    customerServiceContact: getSubmittableCustomerServiceContactValues(
      customerServiceInformation.customerServiceContact
    ),
    termsAndConditionsUrl: termsAndConditions.termsAndConditionsUrl
      ? termsAndConditions.termsAndConditionsUrl
      : undefined,
  };
};

export default getSubmittableValues;
