import { FindScheduleForFooterQuery } from "../api/operations.generated";

const generateScheduleRejectedMailToLink = (
  schedule: FindScheduleForFooterQuery["findSchedule"]
): string => {
  const scheduleUri = window.location.href;

  let businessDeveloperEmails = "no-bd-email-set-yet-for-the-partner";
  const { businessDevelopers } = schedule.line.partner;
  if (businessDevelopers.length) {
    businessDeveloperEmails = businessDevelopers.map((bd) => bd.email).join(",");
  }

  return `mailto:${businessDeveloperEmails}?subject=Line ${schedule.line.networkCode}, schedule ${schedule.name} has been rejected&body=${scheduleUri}`;
};

export default generateScheduleRejectedMailToLink;
