import { useReactiveVar } from "@apollo/client";
import { DataTable, DataTableRow, Heading } from "@flixbus/honeycomb-react";
import {
  Feature,
  hasUserPermission,
  localizeDate,
  useEffectAfterMount,
} from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import DataTableError from "../../../../shared/components/dataTableError/DataTableError";
import DataTableLoading from "../../../../shared/components/dataTableLoading/DataTableLoading";
import DataTableNoResults from "../../../../shared/components/dataTableNoResult/DataTableNoResults";
import Pager from "../../../../shared/components/pager/Pager";
import { IsoCountry, PageSize } from "../../../../shared/types/schema";
import { selectedStationVar } from "../../../../state/reactiveVariables/reactiveVariables";
import { stationsFilterVar } from "../../../../state/stationsFilter/stationsFilter";
import useSelectedStation from "../../../../state/useSelectedStation/useSelectedStation";
import StationDetailPanel from "../../../stationDetailPanel/StationDetailPanel";
import { useFilterStationsQuery } from "../../api/operations.generated";
import { clearStationsFilter } from "../../helper/clearFilter/clearFilter";
import * as css from "../../helper/styles/styles.scss";
import CreateEntityButton from "../../ui/createEntityButton/CreateEntityButton";
import StationStatusTag from "../../ui/stationStatusTag/StationStatusTag";
import { stationsTableHeader } from "../../ui/tableHeader/TableHeader";
import TooltipFlag from "../../ui/tooltipFlag/TooltipFlag";
import WarningIcon from "../../ui/warningIcon/WarningIcon";
import StationActions from "../stationActions/StationActions";
import StationsFilter from "../stationsFilter/StationsFilter";

const StationsTable: React.FC = () => {
  const filter = useReactiveVar(stationsFilterVar);

  const [pageSize, setPageSize] = React.useState(PageSize.Twentyfive);

  const { data, loading, error, fetchMore } = useFilterStationsQuery({
    variables: {
      pageSize,
      page: 0,
      ...filter,
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffectAfterMount(() => {
    fetchMore({ variables: {} });
  }, [filter.name, filter.code, filter.city, filter.country, filter.status]);

  const [, changeSelectedStation] = useSelectedStation(selectedStationVar);

  return (
    <>
      <div className={css.tableHeaderWrapper}>
        <Heading size={1}>
          <FormattedMessage id="general.stations" />
        </Heading>
        <CreateEntityButton
          type="station"
          onSubmit={() => fetchMore({ variables: {} })}
          isImportCreationPermitted={hasUserPermission(Feature.CREATE_STATION_IMPORT)}
          isManualCreationPermitted={hasUserPermission(Feature.CREATE_STATION)}
        />
      </div>

      <StationsFilter />

      {error && <DataTableError />}

      {loading && (
        <DataTableLoading
          header={stationsTableHeader}
          colCount={8}
          extraClasses={css.stationsTable}
        />
      )}

      {data?.filterStations && !loading && (
        <>
          {data.filterStations.items.length ? (
            <DataTable extraClasses={css.stationsTable} headers={stationsTableHeader}>
              {data.filterStations.items.map((station) => (
                <DataTableRow key={station.id}>
                  <td>{station.name}</td>
                  <td>{station.code}</td>
                  <td>{station.city?.name}</td>
                  <td>
                    <TooltipFlag
                      id={station.id}
                      countryCode={station.countryCode as IsoCountry}
                    />
                  </td>
                  <td>
                    {station.updatedDate
                      ? localizeDate(new Date(station.updatedDate))
                      : ""}
                  </td>
                  <td>
                    <StationStatusTag status={station.status} />
                  </td>
                  <td>
                    <WarningIcon
                      stationId={station.id}
                      status={station.status}
                      hasNearbyStations={station.hasNearbyStation}
                      latitude={station.latitude}
                      longitude={station.longitude}
                    />
                  </td>
                  <td>
                    <StationActions
                      stationId={station.id}
                      status={station.status}
                      onEditStation={() => changeSelectedStation(station.id)}
                      iconButton
                    />
                  </td>
                </DataTableRow>
              ))}
            </DataTable>
          ) : (
            <DataTableNoResults onClearFilterSetting={clearStationsFilter} />
          )}

          <StationDetailPanel hasNavigation={false} />
        </>
      )}

      {data && data.filterStations.pageInfo.totalPages > 0 && (
        <Pager
          totalPages={data?.filterStations.pageInfo.totalPages}
          currentPage={data?.filterStations.pageInfo.currentPage}
          hasPrevious={data?.filterStations.pageInfo.hasPrevious}
          hasNext={data?.filterStations.pageInfo.hasNext}
          setPage={(newPage) => {
            fetchMore({ variables: { page: newPage } });
          }}
          total={data?.filterStations.total}
          pageSize={pageSize}
          setPageSize={(size) => {
            setPageSize(size);
            fetchMore({ variables: { pageSize: size } });
          }}
        />
      )}
    </>
  );
};

export default StationsTable;
