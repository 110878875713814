import { PartnerTag } from "../../types/schema";

const translatePartnerTags = (tag: PartnerTag): string => {
  switch (tag) {
    case PartnerTag.IntegrationTypeSelfService:
      return "Self Service";
    case PartnerTag.IntegrationTypeFullApi:
      return "FULL API";
    case PartnerTag.IntegrationTypeHybridApi:
      return "Hybrid API";
    case PartnerTag.IntegrationTypeCustomApi:
      return "Custom API";
    case PartnerTag.IntegrationMethodApiDirect:
      return "API - Direct";
    case PartnerTag.IntegrationMethodApiIntermediary:
      return "API - Intermediary";
    case PartnerTag.BudgetLongDistance:
      return "Long-distance";
    case PartnerTag.BudgetRegional:
      return "Regional";
    case PartnerTag.BudgetShuttle:
      return "Shuttle";
    case PartnerTag.InventoryYearLongLines:
      return "Year-long Lines";
    case PartnerTag.InventorySeasonalLines:
      return "Seasonal Lines";
    case PartnerTag.InventoryMixedLine:
      return "Mixed Lines";
    case PartnerTag.InventoryAirportLines:
      return "Airport Lines";
    case PartnerTag.UsForeignCarriers:
      return "Foreign Carriers";
    case PartnerTag.UsTransitCarriers:
      return "Transit Carriers";
    case PartnerTag.IntegrationStatusInIntegration:
      return "In Integration";
    case PartnerTag.IntegrationStatusInTesting:
      return "In Testing";
    case PartnerTag.IntegrationStatusNewCarrier:
      return "New Carrier";
    case PartnerTag.WarningToMonitor:
      return "To Monitor";
    case PartnerTag.WarningDontOpenSales:
      return "Don't Open Sales";
    case PartnerTag.WarningTestAccount:
      return "Test Account";
    case PartnerTag.WarningCancelledContract:
      return "Cancelled Contract";
    case PartnerTag.WarningUnderReview:
      return "Under Review";
    case PartnerTag.Dt:
      return "DT";
    case PartnerTag.SalesApi:
      return "Sales acc.";
    case PartnerTag.NoCommissionRateSettlement:
      return "Non-comm sett";
    case PartnerTag.FranchiseAccount:
      return "Franchise acc.";
    case PartnerTag.Inactive:
      return "Inactive";
    default:
      return "Unknown Tag";
  }
};

export default translatePartnerTags;
