@import "variables";

.wrapper {
  border: $border;
  border-radius: $border-radius;
}

.highlightCounter {
  text-align: right;
}
