import {
  Heading,
  Panel,
  PanelContent,
  PanelHeader,
  Skeleton,
} from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Brand } from "../../../../shared/types/schema";
import * as css from "./PoliciesPanelTemplate.scss";

type Props = {
  brandName?: Brand["name"];
  onClose: () => void;
};

const PoliciesPanelTemplate: React.FC<Props> = ({ brandName, onClose, children }) => {
  return (
    <Panel
      id="policies-panel"
      active
      position="bottom"
      fullSize
      hasOverlay
      onOverlayClick={onClose}
    >
      <PanelHeader
        closeButtonProps={{ label: "Close", onClick: onClose }}
        extraClasses={css.panelHeader}
      >
        {brandName ? (
          <div className={css.headerWrapper}>
            <Heading size={3} flushSpace extraClasses={css.heading}>
              <FormattedMessage id="policiesPanel.header" values={{ brandName }} />
            </Heading>
            <small className={css.text}>
              <FormattedMessage id="policiesPanel.subHeader" />
            </small>
          </div>
        ) : (
          <Skeleton width="xs" flushBottom />
        )}
      </PanelHeader>
      <PanelContent extraClasses={css.panelContent}>
        <>{children}</>
      </PanelContent>
    </Panel>
  );
};

export default PoliciesPanelTemplate;
