import { BusinessRegion, PartnerTag } from "../shared/types/schema";
import { LineFilterVar } from "../state/lineFilter/lineFilter";
import { PartnerFilterVar } from "../state/partnerFilter/partnerFilter";

export enum SearchType {
  PARTNERS = "partners",
  LINES = "lines",
}

export type PartnerFilterFormValues = {
  businessRegions: BusinessRegion["id"][];
  apiPartner: {
    api: boolean;
    manual: boolean;
  };
  meansOfTransport: {
    bus: boolean;
    train: boolean;
    ferry: boolean;
    minibus: boolean;
    unset: boolean;
  };
  partnerTags: PartnerTag[];
  accessPackage: {
    pro: boolean;
    light: boolean;
    view: boolean;
  };
  businessDeveloper: string;
  autoOnSaleConfig: {
    normalSchedule: {
      activated: boolean;
      deactivated: boolean;
    };
    conceptChangeSchedule: {
      activated: boolean;
      deactivated: boolean;
    };
  };
};

export type PartnerFilterFormSubmittableValues = Omit<PartnerFilterVar, "searchTerm">;

export type LineFilterFormValues = {
  businessRegions: BusinessRegion["id"][];
  meansOfTransport: {
    bus: boolean;
    train: boolean;
    ferry: boolean;
    minibus: boolean;
  };
};

export type LineFilterFormSubmittableValues = Omit<LineFilterVar, "searchTerm">;
