import {
  Icon,
  IconCitySolid,
  IconSettingsSolid,
  IconStationSolid,
} from "@flixbus/honeycomb-icons-react";
import { Button, NavHorizontal, NavItem } from "@flixbus/honeycomb-react";
import {
  Feature,
  hasUserPermission,
  mpcPathnames as pathnames,
} from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import * as css from "./CitiesAndStationsTab.scss";
import CitiesTable from "./container/citiesTable/CitiesTable";
import StationsTable from "./container/stationsTable/StationsTable";

const CitiesAndStationsTab: React.FC = () => {
  const [showStations, setShowStations] = React.useState(true);
  const navigate = useNavigate();

  return (
    <>
      <NavHorizontal
        aria-label="switch between cities and stations"
        extraClasses={css.nav}
      >
        <NavItem
          key="cities"
          active={!showStations}
          onClick={() => setShowStations(false)}
        >
          <div className={css.navItemWrapper}>
            <Icon InlineIcon={IconCitySolid} extraClasses={css.icon} />
            <FormattedMessage id="general.cities" />
          </div>
        </NavItem>
        <NavItem
          key="stations"
          active={showStations}
          onClick={() => setShowStations(true)}
        >
          <div className={css.navItemWrapper}>
            <Icon InlineIcon={IconStationSolid} extraClasses={css.icon} />
            <FormattedMessage id="general.stations" />
          </div>
        </NavItem>
      </NavHorizontal>
      {hasUserPermission(Feature.EDIT_CITY_STATION_APPROVER) && (
        <Button
          data-id="countries-button"
          extraClasses={css.stationApproverButton}
          onClick={() => navigate(pathnames.showCountries)}
          size="sm"
        >
          <Icon InlineIcon={IconSettingsSolid} aria-hidden="true" />
          Cities/Stations Approver
        </Button>
      )}
      <div className={css.wrapper}>
        {showStations ? <StationsTable /> : <CitiesTable />}
      </div>
    </>
  );
};

export default CitiesAndStationsTab;
