import { Divider, Grid, GridCol } from "@flixbus/honeycomb-react";
import { useField } from "formik2";
import * as React from "react";
import { useIntl } from "react-intl";
import CustomFormRow from "../../../../shared/components/customFormRow/CustomFormRow";
import FormikSelect from "../../../../shared/components/formikSelect/FormikSelect";
import FormikSelectGroup from "../../../../shared/components/formikSelectGroup/FormikSelectGroup";
import FormikTimeUnitInput from "../../../../shared/components/formikTimeUnitInput/FormikTimeUnitInput";
import FormikUnitInput from "../../../../shared/components/formikUnitInput/FormikUnitInput";
import FormikYesNoSelect from "../../../../shared/components/formikYesNoSelect/FormikYesNoSelect";
import { createTranslatedOptionsFromEnum } from "../../../../shared/helpers/autocompleteOptions/createOptions";
import {
  LuggageFeeType,
  PointOfSaleType,
  TimeUnit,
  WeightUnit,
} from "../../../../shared/types/schema";
import { BookableLuggageFormValues } from "../../types";
import * as css from "./BookableLuggageInputs.scss";

type Props = {
  name: string;
  label: string;
  readOnly?: boolean;
  isLastInList?: boolean;
};

const BookableLuggageInputs: React.FC<Props> = ({
  name,
  label,
  readOnly,
  isLastInList,
}) => {
  const { formatMessage } = useIntl();
  const [, meta] = useField<BookableLuggageFormValues>(name);
  const [globalWeightUnit] = useField<WeightUnit>("units.weightUnit");

  const getTopContent = () => {
    const content = [];

    if (meta.value.luggageSpecification?.pointOfSale !== undefined) {
      content.push(
        <GridCol size={5} key={`${name}.luggageSpecification.pointOfSale`}>
          <FormikSelect
            name={`${name}.luggageSpecification.pointOfSale`}
            label={formatMessage({ id: "pointOfSale.select.label" })}
            options={createTranslatedOptionsFromEnum(
              PointOfSaleType,
              "pointOfSale.select.option"
            )}
            readOnly={readOnly}
            placeholder={formatMessage({ id: "pointOfSale.select.placeholder" })}
          />
        </GridCol>
      );
    }

    if (
      meta.value.luggageSpecification?.bookableUntil &&
      meta.value.luggageSpecification.pointOfSale === PointOfSaleType.CustomerService
    ) {
      content.push(
        <GridCol size={3} key={`${name}.luggageSpecification.bookableUntil`}>
          <FormikTimeUnitInput
            name={`${name}.luggageSpecification.bookableUntil`}
            label={formatMessage({ id: "lineForm.policies.bookableUntil.label" })}
            info={formatMessage({ id: "lineForm.policies.bookableUntil.info" })}
            defaultUnit={TimeUnit.Hours}
            optionalUnit={TimeUnit.Days}
            readOnly={readOnly}
          />
        </GridCol>
      );
    }

    return content;
  };

  const getBottomContent = () => {
    const content = [];

    if (meta.value.luggageSpecification?.maxWeight) {
      content.push(
        <div className={css.flexItemMd} key={`${name}.luggageSpecification.maxWeight`}>
          <FormikUnitInput
            name={`${name}.luggageSpecification.maxWeight`}
            label={formatMessage({ id: "policies.maximumWeight.label" })}
            maxLength={3}
            readOnly={readOnly}
          />
        </div>
      );
    }

    if (meta.value.luggageSpecification?.maxDimension) {
      content.push(
        <div className={css.flexItemLg} key={`${name}.luggageSpecification.maxDimension`}>
          <FormikUnitInput
            name={`${name}.luggageSpecification.maxDimension`}
            label={formatMessage({ id: "policies.maxDimension.label" })}
            info={formatMessage({ id: "policies.maxDimension.info" })}
            readOnly={readOnly}
          />
        </div>
      );
    }

    if (meta.value.luggageSpecification?.feePrice !== undefined) {
      content.push(
        <div
          className={css.flexItemSm}
          key={`${name}.luggageSpecification.feePrice.value`}
        >
          <FormikUnitInput
            name={`${name}.luggageSpecification.feePrice`}
            label={formatMessage({ id: "policies.fee.label" })}
            readOnly={readOnly}
            isPriceInput
          />
        </div>
      );
    }

    if (meta.value.luggageSpecification?.feeType !== undefined) {
      content.push(
        <div className={css.flexItemMd} key={`${name}.luggageSpecification.feeType`}>
          <FormikSelectGroup
            name={`${name}.luggageSpecification.feeType`}
            label={formatMessage({ id: "policies.feeType.label" })}
            defaultOption={LuggageFeeType.PricePerPiece}
            defaultOptionLabel={formatMessage({ id: "policies.feeType.perPiece.label" })}
            alternativeOption={LuggageFeeType.PricePerWeight}
            alternativeOptionLabel={
              globalWeightUnit.value === WeightUnit.Kilograms
                ? formatMessage({ id: "policies.feeType.perWeight.kg.label" })
                : formatMessage({ id: "policies.feeType.perWeight.lb.label" })
            }
            readOnly={readOnly}
          />
        </div>
      );
    }

    return content;
  };

  return (
    <>
      <div>
        <CustomFormRow data-id="yesNo-customFormRow">
          <FormikYesNoSelect
            name={`${name}.bookable`}
            label={label}
            readOnly={readOnly}
            isCategoryTitle
          />
        </CustomFormRow>
        {meta.value.bookable && (
          <>
            <div className={css.topRow}>
              <CustomFormRow>
                <Grid data-id="top-content-grid">{getTopContent()}</Grid>
              </CustomFormRow>
            </div>
            <div>
              <CustomFormRow>
                <div className={css.flexWrapper} data-id="bottom-content-grid">
                  {getBottomContent()}
                </div>
              </CustomFormRow>
            </div>
          </>
        )}
        {!isLastInList && <Divider extraClasses={css.inputDivider} />}
      </div>
    </>
  );
};

export default BookableLuggageInputs;
