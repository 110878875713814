@import "variables";
@import "../../../../shared/styles/heading.scss";
@import "../../PartnerCapacityAndReportsForm.scss";

.bookingReportConfiguration {
  padding-left: 43px;
}

.instructionsText {
  margin-top: $spacing-2;
}

.columnHeader {
  font-weight: normal;
  color: $content-secondary-color;
}
