import { IconMagnifier } from "@flixbus/honeycomb-icons-react";
import * as React from "react";
import { useIntl } from "react-intl";
import FormikInput from "../../../shared/components/formikInput/FormikInput";
import * as css from "./BusinessDeveloperFilter.scss";

const BusinessDeveloperFilter: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <div className={css.wrapper}>
      <FormikInput
        type="text"
        label={formatMessage({ id: "general.marketplaceManager" })}
        hideLabel
        name="businessDeveloper"
        icon={IconMagnifier}
        placeholder={formatMessage({ id: "general.placeholder.name" })}
        isClearable
        hideValidation
      />
    </div>
  );
};

export default BusinessDeveloperFilter;
