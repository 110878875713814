import { Currency } from "../../types/schema";

export default () =>
  Object.values(Currency).map((currency) => {
    return {
      value: currency,
      label: currency,
      isDisabled: currency === Currency.Hrk,
    };
  });
