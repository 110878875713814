import * as React from "react";
import FilterTagsWrapper from "../../container/filterTagsWrapper/FilterTagsWrapper";
import PartnerTable from "../../container/partnerTable/PartnerTable";
import { SearchType } from "../../types";
import SearchBar from "../searchBar/SearchBar";
import * as css from "./PartnerSearch.scss";

const PartnerSearch: React.FC = () => {
  return (
    <>
      <div className={css.filterWrapper}>
        <SearchBar type={SearchType.PARTNERS} />
      </div>
      <div className={css.filterTagsWrapper}>
        <FilterTagsWrapper type={SearchType.PARTNERS} />
      </div>
      <div>
        <PartnerTable />
      </div>
    </>
  );
};

export default PartnerSearch;
