import {
  endOfMonth,
  interval,
  isWithinInterval,
  startOfDay,
  startOfMonth,
} from "date-fns";
import { Calendar, CalendarIntervalType, CalendarSchedule, OnSaleChunk } from "../types";

const findOnSaleChunk = <S extends CalendarSchedule>(
  calendar: Calendar<S>,
  date: Date,
  row: number
): OnSaleChunk<S> | undefined =>
  calendar.rows[row].onSale.find((chunk) => {
    const chunkInterval =
      calendar.intervalType === CalendarIntervalType.DAILY
        ? interval(startOfDay(chunk.uiPeriod.start), startOfDay(chunk.uiPeriod.end))
        : interval(startOfMonth(chunk.uiPeriod.start), endOfMonth(chunk.uiPeriod.end));

    return isWithinInterval(date, chunkInterval);
  });

export default findOnSaleChunk;
