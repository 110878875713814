@import "variables";

$amenity-row-height: 50px;

.row {
  display: flex;
  justify-content: space-between;
  height: $amenity-row-height;
  line-height: $amenity-row-height;
  cursor: pointer;
  &:not(:last-child) {
    border-bottom: $border;
  }
}

.checkbox {
  padding-left: $spacing-2;
}
.disabled {
  cursor: default;
}

.readOnlyCheckBoxWrapper {
  display: inline-block;
  min-width: 51px;
  padding-left: $spacing-2;
  padding-right: 9px;
}

.label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  padding-left: $spacing-2;
  height: $amenity-row-height;
}

.icon {
  margin-bottom: 2px;
}

.labelText {
  margin-left: $spacing-1;
}

.iconWrapper {
  float: right;
  padding: 0 $spacing-2;
}

.highlightedIcon {
  color: $button-primary-color;
}
