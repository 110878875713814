import { isThisMonth, isThisYear } from "date-fns";
import * as React from "react";
import {
  getCalendarPositionForDailyView,
  getCalendarPositionForMonthlyView,
} from "../../helpers/getCalendarPosition/getCalendarPosition";
import * as css from "./ScheduleCalendarHighlightedCell.scss";

type Props = {
  selectedPeriod: Date;
  isSelectedIntervalYear: Boolean;
};

const ScheduleCalendarHighlightedCell: React.FC<Props> = ({
  selectedPeriod,
  isSelectedIntervalYear,
}) => {
  if (isSelectedIntervalYear) {
    if (isThisYear(selectedPeriod)) {
      return (
        <div
          className={css.highlightedCell}
          style={getCalendarPositionForMonthlyView(new Date(), new Date())}
        />
      );
    }

    return <></>;
  }

  if (isThisMonth(selectedPeriod)) {
    return (
      <div
        className={css.highlightedCell}
        style={getCalendarPositionForDailyView(new Date(), new Date())}
      />
    );
  }

  return <></>;
};

export default ScheduleCalendarHighlightedCell;
