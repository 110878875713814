import { Icon, IconDelete, IconPlus } from "@flixbus/honeycomb-icons-react";
import { Button, Heading } from "@flixbus/honeycomb-react";
import cx from "classnames";
import { useField } from "formik2";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import emptyCustomerServiceContactFormRow from "../../helpers/emptyCustomerServiceContactFormRow/emptyCustomerServiceContactFormRow";
import { CustomerServiceContactFormValue } from "../../types";
import CustomerServiceContactFormRow from "../customerServiceContactFormRow/CustomerServiceContactFormRow";
import * as css from "./CustomerServiceContactForm.scss";

type Props = {
  readOnly?: boolean;
};

export const CUSTOMER_SERVICE_CONTACT_FORM_ROW_NAME =
  "customerServiceInformation.customerServiceContact";

const CustomerServiceContactForm: React.FC<Props> = ({ readOnly }) => {
  const [, meta, helpers] = useField<Array<CustomerServiceContactFormValue>>(
    CUSTOMER_SERVICE_CONTACT_FORM_ROW_NAME
  );

  return (
    <>
      <Heading size={3}>
        <FormattedMessage id="partnerForm.customerServiceInformation.main.headline" />
      </Heading>
      {meta.value.map((_, i, arr) => (
        // Rerendering when the index changes is exactly what we want here, thats why we
        // eslint-disable-next-line react/no-array-index-key
        <div className={cx(!readOnly && css.formRow)} key={i}>
          {i !== 0 && (
            <>
              <Heading size={3} extraClasses={css.headline}>
                <FormattedMessage id="partnerForm.customerServiceInformation.additional.headline" />
              </Heading>
              {!readOnly && (
                <Button
                  data-id="delete-button"
                  aria-label="remove row"
                  display="square"
                  onClick={() =>
                    helpers.setValue([
                      ...meta.value.slice(0, i),
                      ...meta.value.slice(i + 1, arr.length),
                    ])
                  }
                >
                  <Icon InlineIcon={IconDelete} />
                </Button>
              )}
            </>
          )}
          <CustomerServiceContactFormRow
            indexOfContact={i}
            name={`${CUSTOMER_SERVICE_CONTACT_FORM_ROW_NAME}[${i}]`}
            readOnly={readOnly}
          />
        </div>
      ))}
      {!readOnly && (
        <Button
          data-id="add-button"
          onClick={() =>
            helpers.setValue([...meta.value, emptyCustomerServiceContactFormRow])
          }
          extraClasses={css.button}
        >
          <Icon InlineIcon={IconPlus} />
          <FormattedMessage id="partnerForm.customerServiceInformation.add.button" />
        </Button>
      )}
    </>
  );
};

export default CustomerServiceContactForm;
