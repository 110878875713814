import {
  Icon,
  IconComponent,
  IconEditSolid,
  IconClose,
} from "@flixbus/honeycomb-icons-react";
import { Box, Button, Grid, GridCol, Tag } from "@flixbus/honeycomb-react";
import { OverflownText } from "@flixbus-phx/marketplace-common";
import cx from "classnames";
import * as React from "react";
import { CSSTransition } from "react-transition-group";
import * as css from "./DetailSummary.scss";
import * as cssAnimation from "./DetailSummaryAnimation.scss";

export type DetailSummaryProps = {
  isEditMode: boolean;
  boxIcon: IconComponent;
  boxContent: {
    heading: string;
    firstSubtitle?: string;
    secondSubtitle?: {
      firstPart: string;
      secondPart?: string;
    };
  };
  tags: Array<string | JSX.Element>;
  cutomTags?: JSX.Element;
  onOpen: () => void;
  onClose: () => void;
  addTopMargin?: boolean;
  editIcon?: IconComponent;
};

const DetailSummary: React.FC<DetailSummaryProps> = ({
  isEditMode,
  boxIcon,
  boxContent,
  tags,
  cutomTags = <></>,
  children,
  onOpen,
  onClose,
  addTopMargin = false,
  editIcon = IconEditSolid,
}) => {
  const escFunction = React.useCallback((event) => {
    if (event.key === "Escape") {
      onClose();
    }
  }, []);

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  return (
    <>
      <div
        data-id="detail-summary-panel"
        className={cx(
          css.panel,
          isEditMode && css.noPointer,
          addTopMargin && css.addTopMargin
        )}
        onClick={() => {
          if (isEditMode) return;
          onOpen();
        }}
      >
        <div className={css.container} data-id="detail-summary">
          <Grid>
            <GridCol size={5}>
              <Box highlighted extraClasses={css.box}>
                <Grid>
                  <GridCol size={1}>
                    <Icon InlineIcon={boxIcon} size={6} extraClasses={css.boxIcon} />
                  </GridCol>
                  <GridCol size={11}>
                    <div className={css.boxContent}>
                      <OverflownText
                        tooltipContent={<p>{boxContent.heading}</p>}
                        extraClasses={css.boxHeading}
                      >
                        <p>{boxContent.heading}</p>
                      </OverflownText>
                      {boxContent.firstSubtitle && (
                        <div
                          className={css.boxFirstSubtitle}
                          data-id="detail-summary-box-first-subtitle"
                        >
                          {boxContent.firstSubtitle}
                        </div>
                      )}
                      {boxContent.secondSubtitle && (
                        <div className={css.boxSecondSubTitle}>
                          <span>{boxContent.secondSubtitle.firstPart}</span>
                          {boxContent.secondSubtitle.secondPart && (
                            <>
                              <div className={css.separationDot} />
                              <span data-id="detail-summary-box-second-subtitle-second-part">
                                {boxContent.secondSubtitle.secondPart}
                              </span>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </GridCol>
                </Grid>
              </Box>
            </GridCol>

            <GridCol size={6}>
              <div className={css.tagWrapper}>
                {tags.map((tag, i) => {
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <Tag key={i} display="outlined">
                      {tag}
                    </Tag>
                  );
                })}
                {cutomTags}
              </div>
            </GridCol>

            <GridCol size={1}>
              <div className={css.buttonContainer}>
                {!isEditMode ? (
                  <Button
                    display="square"
                    appearance="secondary"
                    aria-label="edit"
                    data-id="detail-summary-edit-button"
                  >
                    <Icon InlineIcon={editIcon} />
                  </Button>
                ) : (
                  <Button
                    onClick={onClose}
                    display="square"
                    aria-label="close"
                    appearance="tertiary"
                  >
                    <Icon InlineIcon={IconClose} />
                  </Button>
                )}
              </div>
            </GridCol>
          </Grid>
        </div>
      </div>

      <CSSTransition
        in={isEditMode}
        timeout={500}
        classNames={{ ...cssAnimation }}
        unmountOnExit
      >
        <div className={css.formContainer}>
          <div className={css.formInnerContainer}>{children}</div>
        </div>
      </CSSTransition>
    </>
  );
};

export default DetailSummary;
