// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds.
// Code is from:
// https://www.matthewgerstman.com/tech/throttle-and-debounce/
// Usage:
// 1. Wrap to be debounced function and save it into a new Function
// const debouncedFunc = debounce((str) => console.log(str), 200)
// 2. Call the function
// debouncedFunc("Hello World")

export default function debounce(func: Function, timeoutMillis: number) {
  let timer: NodeJS.Timeout;
  return (...args: any) => {
    clearTimeout(timer);
    timer = global.setTimeout(() => {
      func(...args);
    }, timeoutMillis);
  };
}
