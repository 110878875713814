export const isValid24hFormat = (value: string): boolean => {
  return /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/.test(value);
};

/**
 * Valid time values are all inputs that user needs to do so that he can type a time in the 24 hour format.
 * Example flow of valid inputs: "", "2", "23", "23:", "23:5", "23:59"
 */
export const isPreValid24hFormat = (value: string): boolean => {
  switch (value.length) {
    case 0:
      return true;
    case 1:
      return /[0-2]/.test(value);
    case 2:
      return /([0-1][0-9]|2[0-3])/.test(value);
    case 3:
      return /([0-1][0-9]|2[0-3]):/.test(value) || /:[0-5][0-9]/.test(value); // allow deleted hours
    case 4:
      return (
        /([0-1][0-9]|2[0-3]):[0-5]/.test(value) ||
        /([0-9]):[0-5][0-9]/.test(value) || // allow deleted tens position hours
        /([0-1][0-9]|2[0-3]):[0-9]/.test(value)
      ); // allow deleted tens position minutes
    case 5:
      return isValid24hFormat(value);
    default:
      return false;
  }
};
