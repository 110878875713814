import { ConceptChangeFromLineQuery, ScheduleFromLineQuery } from "../../types";

export default (
  schedule1: ScheduleFromLineQuery | ConceptChangeFromLineQuery,
  schedule2: ScheduleFromLineQuery | ConceptChangeFromLineQuery
): 1 | 0 | -1 => {
  if (!!schedule1.period && !schedule2.period) {
    return 1;
  }

  if (!schedule1.period && !!schedule2.period) {
    return -1;
  }

  if (!!schedule1.period && !!schedule2.period) {
    if (schedule1.period.start > schedule2.period.start) {
      return -1;
    }

    if (schedule1.period.start < schedule2.period.start) {
      return 1;
    }
  }
  return 0;
};
