import { Breadcrumbs, BreadcrumbsItem } from "@flixbus/honeycomb-react";
import { mpcPathnames as pathnames } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { NavLink } from "react-router-dom";
import * as css from "./CountryListBreadcrumbs.scss";

const CountryListBreadcrumbs: React.FC = () => {
  return (
    <Breadcrumbs aria-label="Breadcrumbs" extraClasses={css.breadcrumb}>
      <BreadcrumbsItem to={pathnames.showStations} RouterLink={NavLink}>
        Cities / Stations
      </BreadcrumbsItem>
      <BreadcrumbsItem to={pathnames.showCountries} RouterLink={NavLink}>
        Cities / Stations Approver
      </BreadcrumbsItem>
    </Breadcrumbs>
  );
};

export default CountryListBreadcrumbs;
