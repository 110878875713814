import {
  Feature,
  formatMessage,
  hasUserPermission,
} from "@flixbus-phx/marketplace-common";
import { DisplayedScheduleStatus } from "../../types/schema";

const getScheduleStatusText = (status: DisplayedScheduleStatus): string => {
  switch (status) {
    case DisplayedScheduleStatus.WorkInProgress:
      return formatMessage("scheduleStatus.workInProgress");
    case DisplayedScheduleStatus.CreatedViaApi:
      return formatMessage("scheduleStatus.createdViaApi");
    case DisplayedScheduleStatus.InReview:
      return formatMessage("scheduleStatus.inReview");
    case DisplayedScheduleStatus.Approved:
    case DisplayedScheduleStatus.InGeneration:
    case DisplayedScheduleStatus.Published:
    case DisplayedScheduleStatus.Accepted:
      return formatMessage("scheduleStatus.processing");
    case DisplayedScheduleStatus.PricesPublished:
      return hasUserPermission(Feature.VIEW_SCHEDULE_DETAILED_STATUS)
        ? formatMessage("scheduleStatus.hidden")
        : formatMessage("scheduleStatus.processing");
    case DisplayedScheduleStatus.Hidden:
      return formatMessage("scheduleStatus.hidden");
    case DisplayedScheduleStatus.OnSale:
    case DisplayedScheduleStatus.PartiallyOnSale:
      return formatMessage("scheduleStatus.onSale");
    case DisplayedScheduleStatus.Archived:
      return formatMessage("scheduleStatus.archived");
    case DisplayedScheduleStatus.OutOfSale:
      return formatMessage("scheduleStatus.outOfSale");
    default:
      return formatMessage("scheduleStatus.unknown");
  }
};

export default getScheduleStatusText;
