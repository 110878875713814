import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionType,
} from "@azure/msal-browser";
import { MsalAuthenticationTemplate, useAccount } from "@azure/msal-react";
import { datadogRum } from "@datadog/browser-rum";
import { MD5 } from "crypto-js";
import * as React from "react";
import { ErrorTypes } from "../../components/errors/ErrorTypes";
import ErrorPage from "../../components/errors/errorPage/ErrorPage";
import { TranslateFunctionProp } from "../../helper/types";
import AuthErrorPage from "../AuthErrorPage/AuthErrorPage";
import { msalInstance } from "../config/authConfig";
import Feature from "../permissions/Feature";
import { hasUserDataWallAccess, hasUserPermission } from "../permissions/permissions";
import { getRoles } from "../utils/utils";

const AuthTemplate: React.FC<TranslateFunctionProp> = ({ translate, children }) => {
  const accounts = msalInstance.getAllAccounts();
  const activeAccount = useAccount();

  if (activeAccount) {
    datadogRum.setUser({
      id: MD5(activeAccount.username).toString(),
      roles: getRoles(),
    });
  }

  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }

  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const { account } = event.payload as AuthenticationResult;
      msalInstance.setActiveAccount(account);
    }
  });

  const hasUserAccess = (): boolean => {
    let access =
      hasUserDataWallAccess() && hasUserPermission(Feature.USER_HAS_MARKETPLACE_ACCESS);

    if (process.env.APP === "mus") {
      access = access && hasUserPermission(Feature.USER_HAS_MUS_ACCESS);
    }

    return access;
  };

  return (
    <MsalAuthenticationTemplate
      errorComponent={AuthErrorPage}
      interactionType={InteractionType.Redirect}
    >
      {hasUserAccess() && activeAccount ? (
        children
      ) : (
        <ErrorPage translate={translate} type={ErrorTypes.e403} />
      )}
    </MsalAuthenticationTemplate>
  );
};

export default AuthTemplate;
