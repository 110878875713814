import { formatMessage } from "@flixbus-phx/marketplace-common";
import { parse } from "date-fns";
import { TimeFormat } from "../../../../shared/components/timeInput/types";
import areTimesSetCorrectly from "../../../../shared/helpers/timeComparator/areTimesSetCorrectly";
import getTimeFormatString from "../../../../shared/helpers/timeFormatter/getTimeFormatString";
import { isValid12hFormat } from "../../../../shared/helpers/timeValidator/twelveHourClockFormatValidator";
import { isValid24hFormat } from "../../../../shared/helpers/timeValidator/twentyFourHourClockFormatValidator";

const isValidTimeFormat = (value: string, timeFormat: TimeFormat) => {
  if (timeFormat === TimeFormat.TWELVE_HOUR_CLOCK) {
    return isValid12hFormat(value);
  }

  return isValid24hFormat(value);
};

const validateWorkingHours = (
  from: string | undefined,
  to: string | undefined,
  i: number,
  timeFormat: TimeFormat
) => {
  const errors: Array<{ pathSuffix: string; message: string }> = [];

  if (from && !isValidTimeFormat(from, timeFormat)) {
    errors.push({
      pathSuffix: `[${i}].from`,
      message: formatMessage("error.validation.noTimeFormat"),
    });
  }

  if (to && !isValidTimeFormat(to, timeFormat)) {
    errors.push({
      pathSuffix: `[${i}].to`,
      message: formatMessage("error.validation.noTimeFormat"),
    });
  }

  if (errors.length) {
    return errors;
  }

  if (from && to) {
    const formatString = getTimeFormatString(timeFormat);
    const fromDate = parse(from, formatString, new Date(Date.now()));
    const toDate = parse(to, formatString, new Date(Date.now()));

    if (!areTimesSetCorrectly(fromDate, toDate)) {
      errors.push({
        pathSuffix: `[${i}].to`,
        message: formatMessage("error.validation.endLaterThanStart"),
      });
    }
  }

  return errors;
};

export default validateWorkingHours;
