import { formatMessage } from "@flixbus-phx/marketplace-common";

export const getPositiveSignum = (offsetWeekDays: number): string => {
  return offsetWeekDays > 0 ? "+" : "";
};

export const getOffsetWeekDaysSuffix = (offsetWeekDays: number): string => {
  if (offsetWeekDays === 1 || offsetWeekDays === -1) {
    return formatMessage("general.day");
  }

  return formatMessage("general.days");
};
