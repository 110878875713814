import { Icon, IconStationSolid } from "@flixbus/honeycomb-icons-react";
import { Panel, PanelHeader, PanelContent, Heading } from "@flixbus/honeycomb-react";
import { DisableBodyScroll } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import HorizontalNavigation, {
  HorizontalNavigationDisplayedTab,
} from "../../shared/components/horizontalNavigation/HorizontalNavigation";
import { selectedStationVar } from "../../state/reactiveVariables/reactiveVariables";
import useSelectedStation from "../../state/useSelectedStation/useSelectedStation";
import * as css from "./StationDetailPanel.scss";
import CityStationsTab from "./containers/cityStationsTab/CityStationsTab";
import StationTab from "./containers/stationTab/StationTab";

type Props = {
  hasNavigation: boolean;
  scheduleId?: string;
};

const StationDetailPanel: React.FC<Props> = ({ hasNavigation, scheduleId }) => {
  const { formatMessage } = useIntl();

  const [tabDisplayed, setTabDisplayed] = React.useState(
    HorizontalNavigationDisplayedTab.ONE
  );

  const [selectedStation, changeSelectedStation] = useSelectedStation(selectedStationVar);

  const handleStationDetailPanelClosed = () => {
    changeSelectedStation("");
  };

  // When closing the Panel reset the DisplayedTab to Stations
  React.useEffect(() => {
    if (selectedStation === "") {
      setTabDisplayed(HorizontalNavigationDisplayedTab.ONE);
    }
  }, [selectedStation]);

  const isActive = selectedStation !== "";

  return (
    <>
      <DisableBodyScroll active={isActive} />
      <Panel
        id="station-detail-panel"
        hasOverlay
        active={isActive}
        extraClasses={css.panel}
        onOverlayClick={handleStationDetailPanelClosed}
      >
        <PanelHeader
          closeButtonProps={{ label: "close", onClick: handleStationDetailPanelClosed }}
        >
          <div className={css.panelHeaderWrapper}>
            <Icon size={4} InlineIcon={IconStationSolid} extraClasses={css.icon} />
            <Heading size={3}>
              <FormattedMessage id="station.detailPanel.headline" />
            </Heading>
          </div>
        </PanelHeader>
        <PanelContent>
          {hasNavigation
            ? scheduleId && (
                <>
                  <HorizontalNavigation
                    displayedTab={tabDisplayed}
                    onTabClicked={(clickedTab) => setTabDisplayed(clickedTab)}
                    labels={[
                      {
                        text: formatMessage({ id: "general.station" }),
                        position: HorizontalNavigationDisplayedTab.ONE,
                      },
                      {
                        text: formatMessage({
                          id: "station.detailPanel.cityStations.tab",
                        }),
                        position: HorizontalNavigationDisplayedTab.TWO,
                      },
                    ]}
                    blockDisplay
                  />
                  {tabDisplayed === HorizontalNavigationDisplayedTab.ONE &&
                    selectedStation && (
                      <StationTab
                        stationId={selectedStation}
                        onPanelClose={handleStationDetailPanelClosed}
                      />
                    )}
                  {tabDisplayed === HorizontalNavigationDisplayedTab.TWO &&
                    selectedStation && (
                      <CityStationsTab
                        scheduleId={scheduleId}
                        stationId={selectedStation}
                        onPanelClose={handleStationDetailPanelClosed}
                      />
                    )}
                </>
              )
            : selectedStation && (
                <div className={css.stationTabWrapper}>
                  <StationTab
                    stationId={selectedStation}
                    onPanelClose={handleStationDetailPanelClosed}
                  />
                </div>
              )}
        </PanelContent>
      </Panel>
    </>
  );
};

export default StationDetailPanel;
