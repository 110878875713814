import React from "react";
import { useIntl } from "react-intl";
import FormikSelect from "../../../../shared/components/formikSelect/FormikSelect";
import InputSkeleton from "../../../../shared/components/inputSkeleton/InputSkeleton";
import SelectApiError from "../../../../shared/components/selectApiError/SelectApiError";
import meansOfTransPortOptions from "../../../../shared/helpers/meansOfTransPortOptions/meansOfTransportOptions";

interface MeansOfTransportSelectProps {
  readOnly: boolean;
  isLoading: boolean;
  hasError: boolean;
}

const MeansOfTransportSelect: React.FC<MeansOfTransportSelectProps> = ({
  readOnly,
  isLoading,
  hasError,
}) => {
  const { formatMessage } = useIntl();

  if (isLoading) {
    return <InputSkeleton />;
  }

  if (hasError) {
    return (
      <SelectApiError
        label={formatMessage({ id: "lineForm.meansOfTransport.label" })}
        info={formatMessage({ id: "error.errorOccured" })}
      />
    );
  }

  return (
    <FormikSelect
      label={formatMessage({ id: "lineForm.meansOfTransport.label" })}
      readOnly={readOnly}
      name="meansOfTransport"
      options={meansOfTransPortOptions()}
      required
    />
  );
};

export default MeansOfTransportSelect;
