import { Icon, IconRefresh } from "@flixbus/honeycomb-icons-react";
import { Button, PopupSection } from "@flixbus/honeycomb-react";
import { Popup } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";

type Props = {
  originalStationName: string;
  exchangeStationName: string;
  active: boolean;
  onClosePopup: () => void;
  onExchangeStation: () => void;
};

const ExchangeStationPopup: React.FC<Props> = ({
  originalStationName,
  exchangeStationName,
  active,
  onClosePopup,
  onExchangeStation,
}) => {
  const { formatMessage } = useIntl();

  const createMarkup = (content: string) => {
    return { __html: content };
  };

  return (
    <Popup
      active={active}
      onClose={() => onClosePopup()}
      data-id="station-exchange-popup"
    >
      <PopupSection type="icon">
        <Icon InlineIcon={IconRefresh} size={8} />
      </PopupSection>
      <PopupSection type="title">
        <FormattedMessage id="station.detailPanel.cityStations.exchange.popup.title" />
      </PopupSection>
      <PopupSection type="content" data-id="station-exchange-popup-content">
        <p
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={createMarkup(
            formatMessage(
              {
                id: "station.detailPanel.cityStations.exchange.popup.text.currentStation",
              },
              { originalStationName },
              { ignoreTag: true }
            )
          )}
        />
        <p
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={createMarkup(
            formatMessage(
              { id: "station.detailPanel.cityStations.exchange.popup.text.newStation" },
              {
                exchangeStationName,
              },
              { ignoreTag: true }
            )
          )}
        />
      </PopupSection>
      <PopupSection type="actions">
        <Button
          data-id="station-exchange-popup-save-button"
          appearance="primary"
          onClick={() => {
            onExchangeStation();
          }}
        >
          <FormattedMessage id="general.yes" />
        </Button>
        <Button
          data-id="station-exchange-popup-cancel-button"
          appearance="tertiary"
          onClick={() => onClosePopup()}
        >
          <FormattedMessage id="general.no" />
        </Button>
      </PopupSection>
    </Popup>
  );
};

export default ExchangeStationPopup;
