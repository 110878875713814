import {
  differenceInCalendarMonths,
  differenceInCalendarYears,
  getDate,
  getDaysInMonth,
  getMonth,
} from "date-fns";
import { CalendarIntervallType } from "../../types";
/**
 * @param date            start or end Date of a period
 * @param comparisonDate  the currently looked at interval with which date will be compared with
 * @param selectedIntervall the currently selected interval. Can be Month or Year
 *
 * @return [position, isInPast, isInFuture]
 *          position    the css grid position of the period
 *          isInPast    if date is before the currently looked at year
 *          isInFuture  if date is after the currently looked at year
 */
export default (
  date: Date,
  comparisonDate: Date,
  selectedIntervall: CalendarIntervallType
): [number, boolean, boolean] => {
  if (selectedIntervall === CalendarIntervallType.YEAR) {
    // date is in same year as comparisonDate
    if (differenceInCalendarYears(comparisonDate, date) === 0) {
      return [getMonth(date), false, false];
    }
    // date is one or more years before comparisonDate
    if (differenceInCalendarYears(comparisonDate, date) > 0) {
      return [0, true, false];
    }

    // date is one or more years after comparisonDate
    return [11, false, true];
  }

  // date is in same month as comparisonDate
  if (differenceInCalendarMonths(comparisonDate, date) === 0) {
    return [getDate(date) - 1, false, false];
  }

  // date is one or more months before comparisonDate
  if (differenceInCalendarMonths(comparisonDate, date) > 0) {
    return [0, true, false];
  }

  // date is one or more months after comparisonDate
  return [getDaysInMonth(comparisonDate) - 1, false, true];
};
