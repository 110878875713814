import { Icon as HCIcon, IconComponent } from "@flixbus/honeycomb-icons-react";
import { Heading } from "@flixbus/honeycomb-react";
import cx from "classnames";
import * as React from "react";
import * as css from "./ImportPopupHeader.scss";

export type ImportPopupHeaderProps = {
  Icon?: IconComponent;
  color: "danger" | "neutral" | "success";
};

const ImportPopupHeader: React.FC<ImportPopupHeaderProps> = ({
  Icon,
  color,
  children,
}) => {
  return (
    <>
      {Icon && (
        <HCIcon extraClasses={cx(css.icon, css[color])} InlineIcon={Icon} size={8} />
      )}
      <Heading size={1}>{children}</Heading>
    </>
  );
};

export default ImportPopupHeader;
