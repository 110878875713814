import { formatMessage } from "@flixbus-phx/marketplace-common";
import { StationTimeZone } from "../../../../shared/types/schema";

export default (timeZone: StationTimeZone): string => {
  const timeZoneHint = formatMessage("trip.time.hint.timeZone", {
    utcOffset: timeZone.utcOffset,
  });
  const daylightSavingTimeHint = formatMessage("trip.time.hint.daylightSavingTime");

  return timeZone.observesDaylightSavingTime
    ? `${timeZoneHint} ${daylightSavingTimeHint}`
    : timeZoneHint;
};
