import { Icon, IconAttention, IconClose, IconPlus } from "@flixbus/honeycomb-icons-react";
import { Grid, GridCol, Tooltip, Tag } from "@flixbus/honeycomb-react";
import cx from "classnames";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ConfirmationPopup from "../../../../shared/components/confirmationPopup/ConfirmationPopup";
import CreateEntityPopup from "../../../../shared/components/createEntityPopup/CreateEntityPopup";
import { Station } from "../../../../shared/types/schema";
import { StationSequenceItemFragment } from "../../api/operations.generated";
import StationAddPopup from "../stationAddPopup/StationAddPopup";
import * as css from "./StationCell.scss";

export type StationCellProps = {
  station: StationSequenceItemFragment;
  isFirstOrLast: boolean;
  readOnly: boolean;
  onStationClicked: () => void;
  onStationRemove: () => void;
  onStationAdd: (stationId: Station["id"]) => void;
  isCountryChange: boolean;
  disallowedValues: Array<Station["id"]>;
};

const StationCell: React.FC<StationCellProps> = ({
  station,
  isFirstOrLast,
  readOnly,
  onStationClicked,
  onStationRemove,
  onStationAdd,
  isCountryChange,
  disallowedValues,
}) => {
  const { formatMessage } = useIntl();

  const createMarkup = (content: string) => {
    return { __html: content };
  };

  const [stationAddPopupActive, setStationAddPopupActive] = React.useState(false);
  const [stationCreatePopupActive, setStationCreatePopupActive] = React.useState(false);
  const [stationRemovePopupActive, setStationRemovePopupActive] = React.useState(false);

  const [stationCreateInputValue, setStationCreateInputValue] = React.useState<string>();

  return (
    <div
      data-id="station-cell-container"
      className={cx(
        css.container,
        isFirstOrLast ? css.containerFirstLast : "",
        isCountryChange ? css.stationIsCountryChange : ""
      )}
    >
      {!readOnly && (
        <Tag
          data-id="add-station-tag"
          onClick={() => setStationAddPopupActive(true)}
          extraClasses={css.stationAddTag}
          display="outlined"
        >
          <Icon
            InlineIcon={IconPlus}
            title={formatMessage({ id: "timetable.addStation.label" })}
            appearance="primary"
          />
        </Tag>
      )}
      {stationAddPopupActive && (
        <StationAddPopup
          disallowedValues={disallowedValues}
          previousStationName={station.name}
          active
          onClose={() => setStationAddPopupActive(false)}
          onConfirm={(stationId) => {
            onStationAdd(stationId);
            setStationAddPopupActive(false);
          }}
          onStationCreate={(inputValue) => {
            setStationAddPopupActive(false);
            setStationCreateInputValue(inputValue);
            setStationCreatePopupActive(true);
          }}
        />
      )}
      {stationCreatePopupActive && (
        <CreateEntityPopup
          onEntityCreated={(stationId) => {
            onStationAdd(stationId);
          }}
          onClose={() => {
            setStationCreatePopupActive(false);
          }}
          inputValue={stationCreateInputValue}
          type="station"
        />
      )}
      {isCountryChange && (
        <Tag data-id="country-change-tag" extraClasses={css.stationCountryCodeTag}>
          {station.countryCode ? (
            station.countryCode
          ) : (
            <FormattedMessage id="general.notAvailable" />
          )}
        </Tag>
      )}
      <Grid>
        <GridCol size={12}>
          <div className={css.removeIconContainer}>
            {!readOnly && (
              <Icon
                data-id="remove-station-icon"
                InlineIcon={IconClose}
                title={formatMessage({ id: "timetable.removeStation.label" })}
                onClick={() => setStationRemovePopupActive(true)}
                extraClasses={css.removeIcon}
              />
            )}
          </div>
          {!readOnly && (
            <ConfirmationPopup
              active={stationRemovePopupActive}
              titleText={formatMessage(
                { id: "timetable.removeStation.popup.title" },
                {
                  stationName: station.name,
                }
              )}
              confirmButtonText={formatMessage({
                id: "timetable.removeStation.popup.confirm.button.label",
              })}
              onConfirm={onStationRemove}
              onCancel={() => setStationRemovePopupActive(false)}
            >
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={createMarkup(
                  formatMessage(
                    { id: "timetable.removeStation.popup.text" },
                    {
                      stationName: station.name,
                    },
                    { ignoreTag: true }
                  )
                )}
              />
            </ConfirmationPopup>
          )}
        </GridCol>
      </Grid>
      <Grid>
        <GridCol size={12}>
          <div className={css.stationCodeNameCentered}>
            <div
              data-id="station-code-name-wrapper"
              className={cx(
                css.stationCodeName,
                isFirstOrLast ? css.stationCodeNameFirstLast : ""
              )}
              onClick={onStationClicked}
            >
              <span data-id="station-code">
                {station.code ? (
                  station.code
                ) : (
                  <FormattedMessage id="general.notAvailable" />
                )}
                &nbsp;-&nbsp;
              </span>
              <span className={css.stationName} data-id="station-name">
                {station.name}
              </span>
            </div>
          </div>
        </GridCol>
      </Grid>
      <Grid>
        <GridCol>
          <div className={css.infoBox} data-id="info-box">
            {!station.isPublished && (
              <Tooltip
                id="station-published-info"
                data-id="station-published-info"
                content={formatMessage({ id: "timetable.info.stationNotPublished" })}
                openOnHover
                size="content-fit"
              >
                <Icon
                  extraClasses={cx(css.icon, css.publishInfoIcon)}
                  InlineIcon={IconAttention}
                  size={3}
                />
              </Tooltip>
            )}
          </div>
        </GridCol>
      </Grid>
    </div>
  );
};

export default StationCell;
