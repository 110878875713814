@import "variables";

.panel {
  *[class*="hcr-panel__body"] {
    min-width: 500px;
    // unset the transform-rule is necessary because otherwise the popup,
    // which is shown when a station is exchanged, is only displayed
    // within the panel
    transform: unset;
  }
  *[class*="hcr-panel__header"] {
    z-index: 2000;
  }
  *[class*="hcr-panel__content"] {
    padding-top: 0;
  }
}

.panelHeaderWrapper {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
}

.icon {
  margin-right: $spacing-3;
}

.stationTabWrapper {
  padding-top: $spacing-4;
}
