import { NotificationObject } from "./types";

export default (
  oldArray: Array<NotificationObject>,
  newArray: Array<NotificationObject>
): Array<NotificationObject> =>
  newArray.filter(
    (newNotification) =>
      !oldArray.some((oldNotification) => oldNotification?.id === newNotification?.id)
  );
