import {
  Icon,
  IconCheckmarkStrong,
  IconArchive,
  IconComponent,
  IconCopy,
  IconDelete,
  IconEdit,
  IconList,
  IconRefresh,
  IconSettingsSolid,
} from "@flixbus/honeycomb-icons-react";
import { Button, Dropdown } from "@flixbus/honeycomb-react";
import { Feature, hasUserPermission } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { useIntl } from "react-intl";
import { DisplayedScheduleStatus, ScheduleStatus } from "../../../../shared/types/schema";
import getIfCreateConceptChangeIsPossible from "../../helpers/getIfCreateConceptChangeIsPossible/getIfCreateConceptChangeIsPossible";
import * as css from "./ScheduleActionsButton.scss";

export type ScheduleActionsButtonProps = {
  onActivateOnSale: () => void;
  onArchiveSchedule: () => void;
  onCopy: () => void;
  onCreateConceptChange: () => void;
  onStatusHistory: () => void;
  onDelete: () => void;
  onChangeStatus: () => void;
  scheduleStatus: ScheduleStatus;
  displayedScheduleStatus: DisplayedScheduleStatus;
  originalScheduleStatus?: ScheduleStatus;
  readOnly: boolean;
};

const ScheduleActionsButton: React.FC<ScheduleActionsButtonProps> = ({
  onActivateOnSale,
  onArchiveSchedule,
  onCopy,
  onCreateConceptChange,
  onStatusHistory,
  onDelete,
  onChangeStatus,
  scheduleStatus,
  displayedScheduleStatus,
  originalScheduleStatus,
  readOnly,
}) => {
  const { formatMessage } = useIntl();
  const dropdownContent: {
    text: string;
    InlineIcon: IconComponent;
    "data-id": string;
    Elem: string;
    onClick?: () => void;
    disabled?: boolean;
    "data-classname"?: string;
  }[] = [];

  if (
    hasUserPermission(Feature.EDIT_SCHEDULE_ACTIVATE_ON_SALE) &&
    [DisplayedScheduleStatus.Hidden, DisplayedScheduleStatus.PartiallyOnSale].includes(
      displayedScheduleStatus
    )
  ) {
    dropdownContent.push({
      text: formatMessage({ id: "scheduleActions.activateOnSale.label" }),
      InlineIcon: IconCheckmarkStrong,
      "data-id": "schedule-action-activate-on-sale",
      "data-classname": "borderBottom",
      Elem: "button",
      onClick: onActivateOnSale,
    });
  }
  if (hasUserPermission(Feature.CREATE_SCHEDULE_CONCEPT_CHANGE)) {
    const isCreateConceptChangePossible = getIfCreateConceptChangeIsPossible(
      scheduleStatus,
      originalScheduleStatus
    );

    dropdownContent.push({
      text: formatMessage({ id: "scheduleActions.conceptChange.label" }),
      InlineIcon: IconRefresh,
      "data-id": "schedule-action-concept-change",
      disabled: !isCreateConceptChangePossible,
      Elem: "button",
      onClick: isCreateConceptChangePossible ? onCreateConceptChange : undefined,
    });
  }

  if (hasUserPermission(Feature.CREATE_SCHEDULE_COPY)) {
    dropdownContent.push({
      text: formatMessage({ id: "scheduleActions.copy.label" }),
      InlineIcon: IconCopy,
      "data-id": "schedule-action-copy",
      Elem: "button",
      onClick: onCopy,
    });
  }

  if (
    hasUserPermission(Feature.EDIT_SCHEDULE_ARCHIVE) &&
    [
      DisplayedScheduleStatus.Hidden,
      DisplayedScheduleStatus.OnSale,
      DisplayedScheduleStatus.PartiallyOnSale,
    ].includes(displayedScheduleStatus)
  ) {
    dropdownContent.push({
      text: formatMessage({ id: "scheduleActions.archiveSchedule.label" }),
      InlineIcon: IconArchive,
      "data-id": "schedule-action-archive-schedule",
      "data-classname": "borderTop",
      Elem: "button",
      onClick: onArchiveSchedule,
    });
  }

  if (hasUserPermission(Feature.DELETE_SCHEDULE) && !readOnly) {
    dropdownContent.push({
      text: formatMessage({ id: "schedule.delete" }),
      InlineIcon: IconDelete,
      "data-id": "schedule-action-delete",
      "data-classname": "borderTop",
      Elem: "button",
      onClick: onDelete,
    });
  }

  if (hasUserPermission(Feature.VIEW_SCHEDULE_STATUS_HISTORY)) {
    dropdownContent.push({
      text: formatMessage({ id: "scheduleActions.statusHistory.label" }),
      InlineIcon: IconList,
      "data-id": "schedule-action-status-history",
      "data-classname": "borderTop",
      Elem: "button",
      onClick: onStatusHistory,
    });
  }

  if (hasUserPermission(Feature.EDIT_SCHEDULE_STATUS)) {
    dropdownContent.push({
      text: "Change Status",
      InlineIcon: IconEdit,
      "data-id": "schedule-action-change-status",
      disabled: false,
      Elem: "button",
      onClick: onChangeStatus,
    });
  }

  return (
    <div className={css.wrapper}>
      <Dropdown links={dropdownContent} extraClasses={css.dropdown}>
        <Button
          data-id="schedule-actions-button"
          appearance="tertiary"
          display="square"
          aria-label="schedule actions"
        >
          <Icon InlineIcon={IconSettingsSolid} />
        </Button>
      </Dropdown>
    </div>
  );
};

export default ScheduleActionsButton;
