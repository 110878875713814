@import "variables";

.priceInput {
  text-align: center;

  &:focus:not(.error) {
    border: 1.5px solid $ui-primary-light-color;
    border-left: 0;
    ~ span {
      border: 1.5px solid $ui-primary-light-color !important;
    }
  }
}

.error {
  border: 1px solid $input-error-border-color;
  border-radius: $border-radius;

  & + span {
    border: 1px solid $input-error-border-color;
  }
}
