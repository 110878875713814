import { Tag } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { SupportedExternalRole } from "../../../../shared/types/schema";
import * as css from "./UserRoleTags.scss";

type Props = {
  roles: SupportedExternalRole[];
};

const UserRoleTags: React.FC<Props> = ({ roles }) => {
  return (
    <div className={css.wrapper}>
      {roles.map((role) => (
        <Tag key={role} display="outlined">
          <FormattedMessage id={`accessManagement.role.${role}`} />
        </Tag>
      ))}
    </div>
  );
};

export default UserRoleTags;
