@import "variables";

.boxWrapper {
  height: $schedule-footer-height;
  margin-top: $spacing-4;
}

.box {
  position: fixed;
  bottom: 0;
  left: 0;
  height: $schedule-footer-height;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 0;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.149);
  border-radius: 0;
  border-left: none;
  border-right: none;
  border-bottom: none;
  z-index: 20;
  padding: 0 95px 0 0;
}
