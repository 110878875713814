import { Divider, Heading, Text } from "@flixbus/honeycomb-react";
import { useField } from "formik2";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import CustomFormRow from "../../../../shared/components/customFormRow/CustomFormRow";
import FormikSelectGroup from "../../../../shared/components/formikSelectGroup/FormikSelectGroup";
import FormikSwitch from "../../../../shared/components/formikSwitch/FormikSwitch";
import InfoTooltipLabel from "../../../../shared/components/infoTooltipLabel/InfoTooltipLabel";
import { PriceDistribution } from "../../../../shared/types/schema";
import * as css from "./InterlinePricingSelector.scss";

export const priceDistributionTooltipContent = (
  <>
    <Heading size={4} sectionHeader>
      <FormattedMessage id="partnerForm.finance.priceDistribution.perLeg" />
    </Heading>
    <Text>
      <FormattedMessage id="partnerForm.finance.priceDistribution.perLeg.tooltip" />
    </Text>
    <Divider />
    <Heading size={4}>
      <FormattedMessage id="partnerForm.finance.priceDistribution.perDistance" />
    </Heading>
    <Text>
      <FormattedMessage id="partnerForm.finance.priceDistribution.perDistance.tooltip" />
    </Text>
  </>
);

type Props = {
  readOnly?: boolean;
};

const InterlinePricingSelector: React.FC<Props> = ({ readOnly }) => {
  const { formatMessage } = useIntl();
  const [, meta] = useField<boolean>("priceChangeAllowed");

  return (
    <>
      <CustomFormRow>
        <Heading size={4} extraClasses={css.heading}>
          <FormattedMessage id="partnerForm.finance.interconnectionPricing.headline" />
        </Heading>
        <FormikSwitch
          label={
            <InfoTooltipLabel
              labelText={formatMessage({
                id: "partnerForm.finance.priceChangeAllowed.label",
              })}
              tooltipContent={formatMessage({
                id: "partnerForm.finance.priceChangeAllowed.tooltip",
              })}
            />
          }
          name="priceChangeAllowed"
          readOnly={readOnly}
          small
        />
      </CustomFormRow>
      {meta.value && (
        <CustomFormRow>
          <FormikSelectGroup
            name="priceDistribution"
            label={
              <InfoTooltipLabel
                labelText={formatMessage({
                  id: "partnerForm.finance.priceDistribution.label",
                })}
                tooltipContent={priceDistributionTooltipContent}
              />
            }
            defaultOption={PriceDistribution.PerLeg}
            defaultOptionLabel={formatMessage({
              id: "partnerForm.finance.priceDistribution.perLeg",
            })}
            alternativeOption={PriceDistribution.PerDistance}
            alternativeOptionLabel={formatMessage({
              id: "partnerForm.finance.priceDistribution.perDistance",
            })}
            readOnly={readOnly}
          />
        </CustomFormRow>
      )}
    </>
  );
};

export default InterlinePricingSelector;
