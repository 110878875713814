// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// This file was automatically generated using npm run codegen
// If you want to alter this file please do so via the above mentioned command
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
import * as Types from "../../../shared/types/schema";

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
const defaultOptions = {} as const;
export type FindAllCitiesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FindAllCitiesQuery = {
  __typename?: "Query";
  findAllCities: Array<{ __typename?: "City"; value: string; label: string }>;
};

export const FindAllCitiesDocument = gql`
  query findAllCities {
    findAllCities {
      value: id
      label: name
    }
  }
`;

/**
 * __useFindAllCitiesQuery__
 *
 * To run a query within a React component, call `useFindAllCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllCitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindAllCitiesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FindAllCitiesQuery,
    FindAllCitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<FindAllCitiesQuery, FindAllCitiesQueryVariables>(
    FindAllCitiesDocument,
    options
  );
}
export function useFindAllCitiesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FindAllCitiesQuery,
    FindAllCitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<FindAllCitiesQuery, FindAllCitiesQueryVariables>(
    FindAllCitiesDocument,
    options
  );
}
export function useFindAllCitiesSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    FindAllCitiesQuery,
    FindAllCitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    FindAllCitiesQuery,
    FindAllCitiesQueryVariables
  >(FindAllCitiesDocument, options);
}
export type FindAllCitiesQueryHookResult = ReturnType<typeof useFindAllCitiesQuery>;
export type FindAllCitiesLazyQueryHookResult = ReturnType<
  typeof useFindAllCitiesLazyQuery
>;
export type FindAllCitiesSuspenseQueryHookResult = ReturnType<
  typeof useFindAllCitiesSuspenseQuery
>;
