import * as React from "react";
import { SchedulesOfLineQuery } from "../../api/operations.generated";
import { CalendarIntervallType } from "../../types";
import ScheduleCalendarDateRow from "../scheduleCalendarDateRow/ScheduleCalendarDateRow";
import ScheduleCalendarScheduleList from "../scheduleCalendarScheduleList/ScheduleCalendarScheduleList";

type Props = {
  originalSchedules: SchedulesOfLineQuery["findLine"]["schedules"];
  selectedIntervalType: CalendarIntervallType;
  selectedPeriod: Date;
  onPeriodChange: (date: Date) => void;
};

const ScheduleCalendar: React.FC<Props> = ({
  originalSchedules,
  selectedIntervalType,
  selectedPeriod,
  onPeriodChange,
}) => {
  return (
    <>
      <ScheduleCalendarDateRow
        selectedPeriod={selectedPeriod}
        selectedIntervalType={selectedIntervalType}
        onPeriodChange={onPeriodChange}
      />
      <ScheduleCalendarScheduleList
        originalSchedules={originalSchedules}
        selectedIntervalType={selectedIntervalType}
        selectedPeriod={selectedPeriod}
      />
    </>
  );
};

export default ScheduleCalendar;
