import { OverflownText } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { Link } from "react-router-dom";
import buildPath from "../../../../routes/buildPath/buildPath";
import { LinesOfPartnersQuery } from "../../api/operations.generated";
import LineStatusTag from "../lineStatusTag/LineStatusTag";
import * as css from "./LineListItem.scss";

type Props = {
  line: LinesOfPartnersQuery["findPartner"]["lines"][0];
};

const LineListItem: React.FC<Props> = ({ line }) => {
  return (
    <Link
      to={buildPath.showLineDetail(line.id)}
      className={css.clickableBox}
      data-id="line-box"
    >
      <div className={css.lineGrid}>
        <div className={css.lineCode} data-id="line-code">
          {line.networkCode}
        </div>
        <div className={css.lineDescription}>
          <OverflownText
            tooltipContent={
              <p className={css.tooltipContent}>{line.networkDescription}</p>
            }
          >
            <p>{line.networkDescription}</p>
          </OverflownText>
        </div>
        <div className={css.lineTag}>
          <LineStatusTag lineStatus={line.status} />
        </div>
      </div>
    </Link>
  );
};

export default LineListItem;
