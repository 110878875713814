.buttonWrapper {
  display: flex;
  justify-content: center;
}

.panelButtonWrapper {
  justify-content: space-between;
}

.panelButton {
  flex-grow: 1;
}
