import { localizeMonthWithYear } from "@flixbus-phx/marketplace-common";
import { defaultRangeExtractor, useVirtualizer } from "@tanstack/react-virtual";
import cx from "classnames";
import { format } from "date-fns";
import * as React from "react";
import calculateStickyHeaderWidth from "../../helper/calculateStickyHeaderWidth/calculateStickyHeaderWidth";
import { CalendarIntervalType } from "../../helper/types";
import * as css from "./StickyHeaderRow.scss";

type Props = {
  displayDates: Date[];
  parentRef: React.MutableRefObject<HTMLDivElement | null>;
  initialOffset: number;
  intervalType: CalendarIntervalType;
};

const StickyHeaderRow: React.FC<Props> = ({
  displayDates,
  parentRef,
  initialOffset,
  intervalType,
}) => {
  const activeStickyIndexRef = React.useRef(0);

  const virtualizer = useVirtualizer({
    count: displayDates.length,
    getScrollElement: () => parentRef.current,
    estimateSize: (index) =>
      calculateStickyHeaderWidth(index, displayDates, intervalType),
    overscan: 1,
    horizontal: true,
    rangeExtractor: React.useCallback((range) => {
      activeStickyIndexRef.current = range.startIndex;
      return defaultRangeExtractor(range);
    }, []),
    initialOffset,
  });

  return (
    <>
      {virtualizer.getVirtualItems().map((virtualItem) => {
        const isSticky = activeStickyIndexRef.current === virtualItem.index;

        return (
          <div
            key={virtualItem.index}
            className={cx(isSticky ? css.stickyColumn : css.scrollableColumn)}
            style={{
              ...(!isSticky
                ? {
                    transform: `translateX(${virtualItem.start}px)`,
                  }
                : {}),
              width: `${virtualItem.size}px`,
            }}
          >
            <div className={css.textCell}>
              {intervalType === CalendarIntervalType.DAILY
                ? localizeMonthWithYear(displayDates[virtualItem.index])
                : format(displayDates[virtualItem.index], "yyyy")}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default StickyHeaderRow;
