/**
 * Validates label props based on existing children.
 */
var buttonLabelValidator = function (componentName) {
  if (componentName === void 0) {
    componentName = 'Button';
  }
  return function (_a) {
    var ariaLabel = _a["aria-label"],
      children = _a.children;
    if (!(ariaLabel === null || ariaLabel === void 0 ? void 0 : ariaLabel.trim()) && !children) {
      return new Error("".concat(componentName, " component requires either children or a valid aria-label."));
    }
    return null;
  };
};

export { buttonLabelValidator };
