import { formatMessage } from "@flixbus-phx/marketplace-common";
import { parse } from "date-fns";
import { TimeFormat } from "../../../../shared/components/timeInput/types";
import areTimesSetCorrectly from "../../../../shared/helpers/timeComparator/areTimesSetCorrectly";
import getTimeFormatString from "../../../../shared/helpers/timeFormatter/getTimeFormatString";
import { isValid12hFormat } from "../../../../shared/helpers/timeValidator/twelveHourClockFormatValidator";
import { isValid24hFormat } from "../../../../shared/helpers/timeValidator/twentyFourHourClockFormatValidator";

const isValidTimeFormat = (timeFormat: TimeFormat, value: string) => {
  if (timeFormat === TimeFormat.TWELVE_HOUR_CLOCK) {
    return isValid12hFormat(value);
  }

  return isValid24hFormat(value);
};

type OpeningHour = {
  weekday?: string | undefined;
  start?: string | undefined;
  end?: string | undefined;
};

const validateTransferOpeningHour = (
  openingHour: OpeningHour,
  i: number,
  timeFormat: TimeFormat
) => {
  const errors: Array<{ pathSuffix: string; message: string }> = [];
  const { start, end } = openingHour;

  if (!start || !end) {
    if (!start) {
      errors.push({
        pathSuffix: `[${i}].start`,
        message: formatMessage("error.validation.required"),
      });
    }

    if (!end) {
      errors.push({
        pathSuffix: `[${i}].end`,
        message: formatMessage("error.validation.required"),
      });
    }
    return errors;
  }

  const isStartValid = isValidTimeFormat(timeFormat, start);
  const isEndValid = isValidTimeFormat(timeFormat, end);

  if (!isStartValid || !isEndValid) {
    if (!isStartValid) {
      errors.push({
        pathSuffix: `[${i}].start`,
        message: formatMessage("error.validation.noTimeFormat"),
      });
    }

    if (!isEndValid) {
      errors.push({
        pathSuffix: `[${i}].end`,
        message: formatMessage("error.validation.noTimeFormat"),
      });
    }
    return errors;
  }

  const formatString = getTimeFormatString(timeFormat);
  const startDate = parse(start, formatString, new Date(Date.now()));
  const endDate = parse(end, formatString, new Date(Date.now()));

  if (!areTimesSetCorrectly(startDate, endDate)) {
    errors.push({
      pathSuffix: `[${i}].end`,
      message: formatMessage("error.validation.endLaterThanStart"),
    });
    return errors;
  }

  return errors;
};

export default validateTransferOpeningHour;
