import * as React from "react";
import FilterTagsWrapper from "../../container/filterTagsWrapper/FilterTagsWrapper";
import LineTable from "../../container/lineTable/LineTable";
import { SearchType } from "../../types";
import SearchBar from "../searchBar/SearchBar";
import * as css from "./LineSearch.scss";

const LineSearch: React.FC = () => {
  return (
    <>
      <div className={css.filterWrapper}>
        <SearchBar type={SearchType.LINES} />
      </div>
      <div className={css.filterTagsWrapper}>
        <FilterTagsWrapper type={SearchType.LINES} />
      </div>
      <div>
        <LineTable />
      </div>
    </>
  );
};

export default LineSearch;
