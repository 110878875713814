import * as React from "react";
import ScheduleInfo from "./containers/scheduleInfo/ScheduleInfo";

type Props = {
  scheduleId: string;
};

const ScheduleDetail: React.FC<Props> = ({ scheduleId }) => {
  return <ScheduleInfo scheduleId={scheduleId} />;
};

export default ScheduleDetail;
