@import "../../../../shared/styles/variables";

.box {
  padding: 0;
}

.headerSection {
  display: flex;
}

.clickable {
  cursor: pointer;
  display: flex;
}

.buttonArrow {
  display: flex;
  align-items: center;
  width: 200px;
  padding: 0 $spacing-2;
  justify-content: space-between;
}

.children {
  border-top: 1px solid $grayscale-50-color;
  padding: $spacing-2 $spacing-4 $spacing-4 $spacing-4;
}
