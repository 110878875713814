import pinNearby from "@flixbus-phx/marketplace-common/assets/map/green_dot.svg";
import pinNewStation from "@flixbus-phx/marketplace-common/assets/map/red_pin.svg";
import { icon } from "leaflet";
import * as React from "react";
import { Map, TileLayer, Marker, Tooltip } from "react-leaflet";
import getPositionForLeaflet from "../../../../shared/helpers/getPositionForLeaflet/getPositionForLeaflet";
import isValidCoordinate from "../../../../shared/helpers/isValidCoordinate/isValidCoordinate";
import { NearbyStations } from "../../../../shared/hooks/useNearbyStations/useNearbyStations";
import * as css from "./NearbyStationMap.scss";

type Props = {
  stations: NearbyStations;
  latitude: string;
  longitude: string;
};

const NearbyStationMap: React.FC<Props> = ({ stations, latitude, longitude }) => {
  const newStationIcon = icon({
    iconUrl: pinNewStation,
    iconSize: [74, 89],
    iconAnchor: [37, 68],
  });
  const newStationIconPosition = getPositionForLeaflet(latitude, longitude);

  const nearbyStationIcon = icon({
    iconUrl: pinNearby,
    iconSize: [60, 60],
    iconAnchor: [30, 27],
  });

  return (
    <Map
      className={css.map}
      center={
        isValidCoordinate(newStationIconPosition)
          ? newStationIconPosition
          : { lat: 0, lng: 0 }
      }
      zoom={isValidCoordinate(newStationIconPosition) ? 15 : 0}
      scrollWheelZoom={false}
    >
      <TileLayer
        url="https://d1oy2gi6prj6xv.cloudfront.net/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      {isValidCoordinate(newStationIconPosition) && (
        <Marker
          data-id="new-station"
          position={newStationIconPosition}
          icon={newStationIcon}
        />
      )}
      {stations.map((station) => {
        if (station.latitude && station.longitude) {
          const position = getPositionForLeaflet(station.latitude, station.longitude);
          if (isValidCoordinate(position)) {
            return (
              <Marker
                data-id="nearby-station"
                key={station.id}
                position={position}
                icon={nearbyStationIcon}
              >
                <Tooltip direction="bottom" offset={[0, 12]}>
                  {station.name}
                </Tooltip>
              </Marker>
            );
          }
          return <></>;
        }
        return <></>;
      })}
    </Map>
  );
};

export default NearbyStationMap;
