import { areIntervalsOverlapping } from "date-fns";

export default (startA: Date, endA: Date, startB: Date, endB: Date) => {
  const intervalA = {
    start: startA,
    end: endA,
  };

  const intervalB = {
    start: startB,
    end: endB,
  };

  return areIntervalsOverlapping(intervalA, intervalB);
};
