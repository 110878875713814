import {
  AutoOnSaleConfigInput,
  InterconnectionRuleType,
  InventoryRestriction,
  PriceDistribution,
} from "../../../../shared/types/schema";
import { PartnerSettingsFormValues, PartnerSettingsSubmittableValues } from "../../types";

const getSubmittableReasonField = (autoOnSaleConfig: AutoOnSaleConfigInput) => {
  if (autoOnSaleConfig.reason === "") {
    return null;
  }

  if (autoOnSaleConfig.normalSchedule && autoOnSaleConfig.conceptChangeSchedule) {
    return null;
  }

  return autoOnSaleConfig.reason;
};

const getSubmittableValues = (
  values: PartnerSettingsFormValues,
  isCreation: boolean
): PartnerSettingsSubmittableValues => {
  const { priceChangeAllowed } = values;
  const { inventoryRestriction } = values.inventoryRule;
  const { interconnectionRule } = values.inventoryRule;

  return {
    priceChangeAllowed,
    priceDistribution: priceChangeAllowed
      ? values.priceDistribution
      : PriceDistribution.PerLeg,
    externalInterconnectionPricingAllowed: values.externalInterconnectionPricingAllowed,
    apiSettings: values.apiSettings,
    autoOnSaleConfig: {
      ...values.autoOnSaleConfig,
      reason: getSubmittableReasonField(values.autoOnSaleConfig),
    },
    inventoryRule: isCreation
      ? undefined
      : {
          inventoryRestriction,
          interconnectionRule:
            inventoryRestriction === InventoryRestriction.StandaloneOnly
              ? undefined
              : {
                  type: interconnectionRule.type,
                  operators:
                    interconnectionRule.type === InterconnectionRuleType.None
                      ? undefined
                      : interconnectionRule.operators.map((operator) => operator.uuid),
                },
        },
  };
};

export default getSubmittableValues;
