import * as React from "react";

type Props = {
  active: boolean;
};

const DisableBodyScroll: React.FC<Props> = ({ active }) => {
  return <>{active ? <style>{"body { overflow: hidden}"}</style> : <></>}</>;
};

export default DisableBodyScroll;
