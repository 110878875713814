import cx from "classnames";
import * as React from "react";
import { DisplayedScheduleStatus } from "../../../../shared/types/schema";
import { SchedulesOfLineQuery } from "../../api/operations.generated";
import * as css from "./SchedulePeriodOverlapText.scss";

type Props = {
  startDateInPast: boolean;
  endDateInPast: boolean;
  startDateInFuture: boolean;
  endDateInFuture: boolean;
  displayedScheduleStatus: SchedulesOfLineQuery["findLine"]["schedules"][number]["displayedScheduleStatus"];
  schedulePeriodTagPosition: { gridColumn: string };
};

const SchedulePeriodOverlapText: React.FC<Props> = ({
  startDateInPast,
  endDateInPast,
  startDateInFuture,
  endDateInFuture,
  displayedScheduleStatus,
  schedulePeriodTagPosition,
  children,
}) => {
  return (
    <div
      data-id="schedule-period-info"
      style={schedulePeriodTagPosition}
      className={cx(
        css.wrapper,
        css.textWrapper,
        css.tag,
        css.text,
        startDateInPast && endDateInPast && css.periodInPast,
        startDateInFuture && endDateInFuture && css.periodInFuture,
        startDateInPast && css.cutOffLeft,
        endDateInFuture && css.cutOffRight,
        ![DisplayedScheduleStatus.OutOfSale].includes(displayedScheduleStatus) &&
          css.textColorWhite
      )}
    >
      {children}
    </div>
  );
};

export default SchedulePeriodOverlapText;
