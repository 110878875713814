import { useReactiveVar } from "@apollo/client";
import { Icon, IconClose } from "@flixbus/honeycomb-icons-react";
import { Button } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import FilterInput from "../../../../shared/components/filterInput/FilterInput";
import { ImportanceClass } from "../../../../shared/types/schema";
import citiesFilterVar from "../../../../state/citiesFilter/citiesFilter";
import { clearCitiesFilter } from "../../helper/clearFilter/clearFilter";
import * as css from "../../helper/styles/styles.scss";
import FilterCountrySelect from "../../ui/filterCountrySelect/FilterCountrySelect";
import FilterSelect from "../../ui/filterSelect/FilterSelect";

const CitiesFilter: React.FC = () => {
  const { formatMessage } = useIntl();
  const filter = useReactiveVar(citiesFilterVar);

  return (
    <div className={css.filterContainer}>
      <div className={css.filterWrapper}>
        <FilterInput
          label={formatMessage({ id: "general.name" })}
          value={filter.name}
          onChange={(name) => {
            citiesFilterVar({ ...filter, name });
          }}
        />
      </div>
      <div className={css.filterWrapper}>
        <FilterCountrySelect
          selected={filter.country}
          onChange={(country) => {
            citiesFilterVar({ ...filter, country });
          }}
        />
      </div>
      <div className={css.filterWrapper}>
        <FilterSelect
          label={formatMessage({ id: "city.importanceClass" })}
          options={Object.keys(ImportanceClass).map((importanceClass) => {
            return { value: importanceClass, label: importanceClass };
          })}
          selected={filter.importanceClass}
          onChange={(importanceClass) => {
            citiesFilterVar({
              ...filter,
              importanceClass: importanceClass as ImportanceClass | null,
            });
          }}
        />
      </div>
      <div className={css.filterWrapper}>
        <FilterSelect
          label={formatMessage({ id: "general.published" })}
          options={[
            {
              value: "1",
              label: formatMessage({ id: "general.yes" }),
            },
            {
              value: "0",
              label: formatMessage({ id: "general.no" }),
            },
          ]}
          selected={filter.published !== null ? (filter.published ? "1" : "0") : null}
          onChange={(published) => {
            citiesFilterVar({
              ...filter,
              published: published === null ? null : !!parseInt(published, 10),
            });
          }}
        />
      </div>
      <div className={css.filterButtonWrapper}>
        <Button onClick={clearCitiesFilter}>
          <Icon InlineIcon={IconClose} />
          <FormattedMessage id="filter.resetButton.text" />
        </Button>
      </div>
    </div>
  );
};

export default CitiesFilter;
