import {
  DayOfWeek,
  StationTransferInformationOpeningHoursInput,
} from "../../../../shared/types/schema";
import convertOpeningHour from "../convertOpeningHour/convertOpeningHour";

export default (openingHours: Array<StationTransferInformationOpeningHoursInput>) => {
  const initialValues: Array<Array<StationTransferInformationOpeningHoursInput>> =
    Array.from({ length: 7 }, () => []);

  const weekdayValues = Object.values(DayOfWeek);

  openingHours.forEach((openingHour) => {
    const openingHourConverted = convertOpeningHour(openingHour);
    const index = weekdayValues.indexOf(openingHourConverted.weekday);

    initialValues[index].push(openingHourConverted);
  });

  return initialValues;
};
