@import "variables";
@import "../../styles/PolicyStyles.scss";

.topRow {
  min-height: 82px;
}

.flexWrapper {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + $spacing-2);
  margin-right: -$spacing-1;
  margin-left: -$spacing-1;
}

@mixin flexItem {
  padding-right: $spacing-1;
  padding-left: $spacing-1;
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
}

.flexItemSm {
  width: 16%;
  @include flexItem;
}

.flexItemMd {
  width: 20%;
  @include flexItem;
}

.flexItemLg {
  width: 22%;
  @include flexItem;
}
