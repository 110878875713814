import { Feature, hasUserPermission } from "@flixbus-phx/marketplace-common";
import { StationStatus } from "../../types/schema";

export default (status: StationStatus) => {
  switch (status) {
    case StationStatus.Published:
      return hasUserPermission(Feature.EDIT_STATION_PUBLISHED);
    case StationStatus.Approved:
      return hasUserPermission(Feature.EDIT_STATION_APPROVED);
    case StationStatus.Rejected:
      return hasUserPermission(Feature.EDIT_STATION_REJECTED);
    case StationStatus.InReview:
      return hasUserPermission(Feature.EDIT_STATION_INREVIEW);
    default:
      return false;
  }
};
