@import "src/shared/styles/variables";

.container {
  height: $timetable-station-box-height;
  padding: $spacing-2;
  border-top: $border;
  width: $timetable-trip-col-width;
}

.stationIsFirstOrLast {
  height: $timetable-station-box-height-FirstLast;
}

.stationIsCountryChange {
  border-top: 1px solid $button-primary-color;
}

.deleteButton {
  float: right;
}

.arrivalTime {
  padding-bottom: $spacing-2;
}

.arrivalTimezone {
  display: flex;
  justify-content: center;
}

.departureTimezone {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.departureTimezoneText {
  color: $grayscale-50-color;
}

.tooltip {
  font-size: $font-size-small;
  left: 100px;

  &::after {
    left: 55px;
  }
}

.tooltipOfFirst {
  left: 83px;

  &::after {
    left: 60px;
  }
}
