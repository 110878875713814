import { SelectGroup } from "@flixbus/honeycomb-react";
import * as React from "react";
import getWeekdayText from "../../../../shared/helpers/getWeekdayText/getWeekdayText";
import { DayOfWeek } from "../../../../shared/types/schema";
import { TripFragment } from "../../api/operations.generated";
import * as css from "./ServiceDays.scss";

type Props = {
  serviceDays: TripFragment["serviceDays"];
  onServiceDaysChange: (serviceDays: TripFragment["serviceDays"]) => void;
  disabled: boolean;
};

const ServiceDays: React.FC<Props> = ({ serviceDays, onServiceDaysChange, disabled }) => {
  // Required because of __typename property which breaks the update later
  const serviceDaysOptions = {
    monday: serviceDays.monday,
    tuesday: serviceDays.tuesday,
    wednesday: serviceDays.wednesday,
    thursday: serviceDays.thursday,
    friday: serviceDays.friday,
    saturday: serviceDays.saturday,
    sunday: serviceDays.sunday,
  };

  const serviceDaysOptionsArr = Object.entries(serviceDaysOptions);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as unknown as number;

    serviceDaysOptionsArr[value][1] = !serviceDaysOptionsArr[value][1];

    const serviceDaysOptionsUpdated = Object.fromEntries(
      serviceDaysOptionsArr
    ) as unknown as TripFragment["serviceDays"];

    onServiceDaysChange(serviceDaysOptionsUpdated);
  };

  const idPrefix = Math.random();

  const options = Object.values(DayOfWeek).map((day, index) => {
    return {
      id: `${idPrefix}-${day}`,
      value: index,
      name: day,
      label: getWeekdayText(day),
      defaultChecked: serviceDaysOptionsArr[index][1],
      onChange,
    };
  });

  return (
    <SelectGroup
      multi
      aria-label="Day of week"
      options={options}
      id="select-group"
      data-id="service-days"
      disabled={disabled}
      extraClasses={css.serviceDays}
    />
  );
};

export default ServiceDays;
