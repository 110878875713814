@import "variables";
@import "../../shared/styles/form-elements.scss";

.minSeatCapacity {
  width: 60%;
}

.fineprint {
  @include fineprint-below-input;
}

.circulation {
  width: 60%;
}
