import { Icon, IconArrowBigRight } from "@flixbus/honeycomb-icons-react";
import { Box, ListWrapperItem, ListWrapper } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { ForbiddenRelationsFormValues } from "../../types";
import DeleteForbiddenRelationsButton from "../deleteForbiddenRelationsButton/DeleteForbiddenRelationsButton";
import RelationStation from "../relationStation/RelationStation";
import * as css from "./ForbiddenRelationsList.scss";

export type LineForbiddenRelationsFormProps = {
  forbiddenRelations: Array<ForbiddenRelationsFormValues>;
  isDeleteAllowed: boolean;
  isUpdate: boolean;
};

const ForbiddenRelationsList: React.FC<LineForbiddenRelationsFormProps> = ({
  forbiddenRelations,
  isDeleteAllowed,
  isUpdate,
}) => {
  if (!forbiddenRelations.length) {
    return (
      <Box extraClasses={css.box}>
        <div className={css.boxNoResults}>
          <span>
            <FormattedMessage id="lineForm.forbiddenRelations.info.noSearchResults.firstPart" />
          </span>
          <span>
            <FormattedMessage id="lineForm.forbiddenRelations.info.noSearchResults.secondPart" />
          </span>
        </div>
      </Box>
    );
  }

  return (
    <div className={css.wrapper}>
      <ListWrapper extraClasses={css.listWrapper}>
        {forbiddenRelations.map((relation) => (
          <ListWrapperItem key={`${relation.from.id}-${relation.to.id}`}>
            <div className={css.listItemContent}>
              <div className={css.relation}>
                <RelationStation station={relation.from} />
              </div>
              <div className={css.arrowIconWrapper}>
                <Icon InlineIcon={IconArrowBigRight} size={4} />
              </div>
              <div className={css.relation}>
                <RelationStation station={relation.to} />
              </div>
              {isDeleteAllowed && (
                <DeleteForbiddenRelationsButton relation={relation} isUpdate={isUpdate} />
              )}
            </div>
          </ListWrapperItem>
        ))}
      </ListWrapper>
    </div>
  );
};

export default ForbiddenRelationsList;
