import { Popup } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import {
  importPostRequest,
  validationPostRequest,
} from "../../helper/restApiCalls/restApiCalls";
import {
  isImport200,
  isValidation200,
  isValidation400,
  isValidation422,
  Validation200,
  Validation422,
  ValidationResponseTypes,
} from "../../helper/typeguards/typeguards";
import {
  createFileDownload,
  downloadFile,
  removeFile,
} from "../../helper/uuidFileDownload/uuidFileDownload";
import validateFile from "../../helper/validateFile/validateFile";
import Initial from "../../ui/importPopupStates/initial/Initial";
import Uploaded from "../../ui/importPopupStates/uploaded/Uploaded";
import Validated200 from "../../ui/importPopupStates/validated200/Validated200";
import Validated400 from "../../ui/importPopupStates/validated400/Validated400";
import Validated422 from "../../ui/importPopupStates/validated422/Validated422";

export enum ImportPopupStates {
  INITIAL = 1,
  VALIDATING = 2,
  VALIDATED = 3,
  UPLOADING = 4,
  SUCCESS = 5,
}

export type ImportPopupProps = {
  type: "station" | "city";
  onClose: () => void;
};

const ImportPopup: React.FC<ImportPopupProps> = ({ type, onClose }) => {
  const [importState, setImportState] = React.useState<ImportPopupStates>(
    ImportPopupStates.INITIAL
  );

  const [file, setFile] = React.useState<File>();

  const [validationResult, setValidationResult] =
    React.useState<ValidationResponseTypes>();

  const handleValidation = async (files: FileList | null) => {
    if (files !== null) {
      const browserValidation = validateFile(files[0]);

      if (browserValidation.length) {
        setValidationResult(browserValidation);
        setImportState(ImportPopupStates.VALIDATED);
      } else {
        setImportState(ImportPopupStates.VALIDATING);

        validationPostRequest(files[0], type === "station").then((res) => {
          if (isValidation200(res)) {
            setFile(files[0]);
          }
          setValidationResult(res);
          setImportState(ImportPopupStates.VALIDATED);
        });
      }
    }
  };

  const handleImport = () => {
    if (file !== undefined) {
      setImportState(ImportPopupStates.UPLOADING);
      importPostRequest(file, type === "station").then((res) => {
        if (isImport200(res)) {
          res.blob().then((blob) => {
            createFileDownload(blob, file.name);
            setImportState(ImportPopupStates.SUCCESS);
          });
        } else {
          setValidationResult(res);
          setImportState(ImportPopupStates.VALIDATED);
        }
      });
    }
  };

  const resetPopup = () => {
    setImportState(ImportPopupStates.INITIAL);
    setFile(undefined);
    setValidationResult(undefined);
    removeFile();
  };

  const closePopup = () => {
    resetPopup();
    onClose();
  };

  return (
    <Popup active onClose={closePopup}>
      {[ImportPopupStates.INITIAL, ImportPopupStates.VALIDATING].includes(
        importState
      ) && (
        <>
          <Initial
            isUploading={importState === ImportPopupStates.VALIDATING}
            onUpload={handleValidation}
            onCancel={closePopup}
            type={type}
          />
        </>
      )}

      {[ImportPopupStates.VALIDATED, ImportPopupStates.UPLOADING].includes(importState) &&
        validationResult !== undefined && (
          <>
            {isValidation200(validationResult) && file && (
              <Validated200
                rows={validationResult.parsedDataRows}
                fileName={file.name}
                isLoading={importState === ImportPopupStates.UPLOADING}
                onBack={resetPopup}
                onConfirm={handleImport}
                type={type}
              />
            )}

            {isValidation422(validationResult) && (
              <Validated422
                errorRows={(validationResult as Validation422).errorsPerRow}
                onCancel={closePopup}
                onBack={resetPopup}
                type={type}
              />
            )}

            {isValidation400(validationResult) && (
              <Validated400
                errors={validationResult}
                onCancel={closePopup}
                onBack={resetPopup}
              />
            )}
          </>
        )}

      {importState === ImportPopupStates.SUCCESS && (
        <Uploaded
          uploadedElements={(validationResult as Validation200).parsedDataRows}
          onDownload={downloadFile}
          onClose={closePopup}
          type={type}
        />
      )}
    </Popup>
  );
};

export default ImportPopup;
