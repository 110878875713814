import { Infobox } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { ScheduleDetailFragment } from "../../api/operations.generated";
import * as css from "./ReadOnlyHint.scss";

type Props = {
  readOnly: ScheduleDetailFragment["readOnly"];
};

const ReadOnlyHint: React.FC<Props> = ({ readOnly }) => {
  if (readOnly) {
    return (
      <Infobox small extraClasses={css.infobox}>
        <FormattedMessage id="scheduleDetail.info.readOnly" />
      </Infobox>
    );
  }
  return <></>;
};

export default ReadOnlyHint;
