import { readURIParams } from "../readURIParams/readURIParams";

export type TrackingParameters = {
  channel: TrackingChannel | undefined;
};

export enum TrackingChannel {
  Mail,
}

export const readTrackingParams = (
  url: string = window.location.href
): TrackingParameters => {
  const uriParams = readURIParams(url);

  return {
    channel: uriParams.tracking_channel === "mail" ? TrackingChannel.Mail : undefined,
  };
};
