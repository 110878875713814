import { Icon, IconArrowBigRight, IconAttention } from "@flixbus/honeycomb-icons-react";
import { Infobox } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ConfirmationPopup from "../../../../shared/components/confirmationPopup/ConfirmationPopup";
import { ForbiddenRelationsFormValues } from "../../types";
import RelationStation from "../relationStation/RelationStation";
import * as css from "./DeleteForbiddenRelationsPopup.scss";

export type DeleteForbiddenRelationsPopupProps = {
  relation: ForbiddenRelationsFormValues;
  onConfirm: () => void;
  onCancel: () => void;
  isUpdate: boolean;
};

const DeleteForbiddenRelationsPopup: React.FC<DeleteForbiddenRelationsPopupProps> = ({
  relation,
  onConfirm,
  onCancel,
  isUpdate,
}) => {
  const { formatMessage } = useIntl();

  return (
    <ConfirmationPopup
      active
      titleIcon={IconAttention}
      titleText={formatMessage({ id: "lineForm.forbiddenRelations.delete.popupContent" })}
      onConfirm={onConfirm}
      onCancel={onCancel}
      confirmButtonText={formatMessage({ id: "general.confirmDelete" })}
      allowOverflow
    >
      <div data-id="popup-body" className={css.popupBody}>
        <div className={css.relation}>
          <div className={css.station}>
            <RelationStation station={relation.from} />
          </div>
          <div className={css.icon}>
            <Icon InlineIcon={IconArrowBigRight} />
          </div>
          <div className={css.station}>
            <RelationStation station={relation.to} />
          </div>
        </div>
        {isUpdate && (
          <Infobox appearance="warning" extraClasses={css.infoBox}>
            <FormattedMessage id="lineForm.forbiddenRelations.info.delete" />
          </Infobox>
        )}
      </div>
    </ConfirmationPopup>
  );
};

export default DeleteForbiddenRelationsPopup;
