import { TimeFormat } from "../../../../shared/components/timeInput/types";
import { convert12hTo24hFormat } from "../../../../shared/helpers/timeFormatter/timeConverter";

export default (timeFormat: TimeFormat, time?: string) => {
  return time
    ? timeFormat === TimeFormat.TWELVE_HOUR_CLOCK
      ? convert12hTo24hFormat(time)
      : time
    : undefined;
};
