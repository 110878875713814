import { NextLink, Operation } from "@apollo/client";
import { AuthenticationResult, InteractionRequiredAuthError } from "@azure/msal-browser";
import { useAccount } from "@azure/msal-react";
import { getCookie } from "../../helper/handleCookies/handleCookies";
import { loginRequest, msalInstance } from "../config/authConfig";
import Role from "../permissions/Role";

export const useUserName = () => {
  const account = useAccount();

  return account?.name || "";
};

export const logout = () => {
  window.localStorage.clear();
  msalInstance.logoutRedirect({
    postLogoutRedirectUri: "https://marketplace.flixbus.com/",
  });
};

export const buildAuthorizationHeader = (token: string) => {
  return {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
};
export const AUTH_ISSUER_CYPRESS_COOKIE_NAME = "auth-issuer-cypress";

export const isUnitTest = () => process.env.NODE_ENV === "test";

export const isTestEnv = () => {
  const cookieValue = getCookie(AUTH_ISSUER_CYPRESS_COOKIE_NAME);

  if (process.env.NODE_ENV !== "production") {
    if (cookieValue || isUnitTest()) {
      return true;
    }
    return false;
  }
  return false;
};

export const isExpired = (exp: number | undefined): boolean => {
  return typeof exp !== "number" || exp * 1000 < Date.now();
};

export const getToken = async (
  forceRefresh = false
): Promise<void | AuthenticationResult | undefined> => {
  return msalInstance
    .acquireTokenSilent({
      ...loginRequest,
      forceRefresh:
        forceRefresh || isExpired(msalInstance.getActiveAccount()?.idTokenClaims?.exp),
    })
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      // WE don't want that error to be spamming when we test the error case in jest
      if (!isTestEnv()) {
        // eslint-disable-next-line no-console
        console.error(error);
      }

      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return msalInstance.acquireTokenRedirect({
          ...loginRequest,
        });
      }
      return undefined;
    });
};

export const refreshToken = async (operation: Operation, forward: NextLink) => {
  const tokenResponse = await getToken(true);

  if (tokenResponse) {
    operation.setContext(buildAuthorizationHeader(tokenResponse.idToken));
    forward(operation);
  }
};

export const getRoles = (): Role[] | undefined =>
  msalInstance.getActiveAccount()?.idTokenClaims?.roles as Array<Role>;
