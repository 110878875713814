@import "src/shared/styles/variables";

.wrapper {
  position: relative;
}

.tag {
  color: $line-primary-color !important;
  position: absolute;
  right: 28px;
  top: -16px;
  overflow: visible;
}

.warning {
  color: $button-primary-color;
  margin-top: -2px;
  margin-left: -6px;
}

.tooltip {
  > div,
  span {
    cursor: help;
  }
}
