import { Checkbox } from "@flixbus/honeycomb-react";
import * as React from "react";
import { components, OptionProps as ReactSelectOptionProps } from "react-select";
import { SelectOption } from "../../../../helpers/reactSelect/types";
import * as css from "./MultiSelectOption.scss";

export type OptionProps = ReactSelectOptionProps<SelectOption, false>;

const MultiSelectOption: React.FC<OptionProps> = (props) => {
  const {
    data: { value, label },
    isSelected,
  } = props;

  return (
    <components.Option {...props}>
      <Checkbox
        id={value}
        value={value}
        label={label}
        defaultChecked={isSelected}
        small
        extraClasses={css.checkbox}
      />
    </components.Option>
  );
};

export default MultiSelectOption;
