import { formatMessage } from "@flixbus-phx/marketplace-common";
import * as Yup from "yup";
import { priceRegex } from "../../../../shared/helpers/priceInputFormatter/priceInputFormatter";
import areTimesOverlapping from "../../../../shared/helpers/timeComparator/areTimesOverlapping";
import getTimeFormatString from "../../../../shared/helpers/timeFormatter/getTimeFormatString";
import { timezoneData } from "../../../../shared/helpers/timezones/timezones";
import getUserPreferredTimeFormat from "../../../../shared/helpers/userPreferredTimeFormat/userPreferredTimeFormat";
import {
  DayOfWeek,
  IsoLanguage,
  ProofOfIdentity,
  PurposeOfContactField,
  TimeUnit,
} from "../../../../shared/types/schema";
import { CustomerServiceContactFormValue } from "../../types";
import getErrorsForOverlappingTimes from "../getErrorsForOverlappingTimes/getErrorsForOverlappingTimes";
import getRowsWithSameDaySelected from "../getRowsWithSameDaySelected/getRowsWithSameDaySelected";
import parseFromAndToTime from "../parseFromAndToTime/parseFromAndToTime";
import removeDuplicates from "../removeDuplicates/removeDuplicates";
import validateWorkingHours from "../validateWorkingHours/validateWorkingHours";

const timeUnit = () =>
  Yup.object().shape({
    value: Yup.number()
      .typeError(formatMessage("error.validation.mustBeNumber"))
      .integer(formatMessage("error.validation.mustBeNumber")),
    unit: Yup.mixed().oneOf(
      Object.values(TimeUnit),
      formatMessage("error.validation.noTimeUnitSelected")
    ),
  });

export default () => {
  return Yup.object().shape({
    checkInRequirements: Yup.object()
      .shape({
        checkInStartTime: timeUnit(),
        checkInEndTime: timeUnit(),
        checkInOptions: Yup.object().shape({
          website: Yup.object().shape({
            enabled: Yup.boolean(),
            url: Yup.string().when("enabled", {
              is: true,
              then: () =>
                Yup.string()
                  .required(formatMessage("error.validation.required"))
                  .url(formatMessage("error.validation.mustBeUrl"))
                  .max(
                    255,
                    formatMessage("error.validation.maxCharacters", {
                      numCharacters: 255,
                    })
                  ),
              otherwise: () => Yup.string(),
            }),
          }),
          shop: Yup.object().shape({
            enabled: Yup.boolean(),
          }),
          desk: Yup.object().shape({
            enabled: Yup.boolean(),
            currency: Yup.string(),
            fee: Yup.string().matches(
              priceRegex,
              formatMessage("error.validation.doesNotFitFormatX", {
                format: "--,--",
              })
            ),
            feeUnit: Yup.string(),
          }),
          selfServiceMachine: Yup.object().shape({
            enabled: Yup.boolean(),
          }),
        }),
        proofOfIdentityRequired: Yup.array().of(
          Yup.string().oneOf(Object.values(ProofOfIdentity))
        ),
      })
      .test({
        test({ checkInStartTime, checkInEndTime }) {
          if (
            checkInStartTime.unit &&
            checkInStartTime.value &&
            checkInEndTime.unit &&
            checkInEndTime.value
          ) {
            const startTime =
              checkInStartTime.unit === TimeUnit.Hours
                ? checkInStartTime.value * 60
                : checkInStartTime.value;
            const endTime =
              checkInEndTime.unit === TimeUnit.Hours
                ? checkInEndTime.value * 60
                : checkInEndTime.value;

            if (startTime <= endTime) {
              return this.createError({
                path: `checkInRequirements.checkInEndTime.value`,
                message: formatMessage("error.validation.mustBeAfterStartTime"),
              });
            }
            return true;
          }
          return true;
        },
      }),
    customerServiceInformation: Yup.object().shape({
      customerServiceContact: Yup.array().of(
        Yup.object().shape({
          purposeOfContact: Yup.string()
            .nullable()
            .oneOf(Object.values(PurposeOfContactField)),
          languagesSpoken: Yup.array().of(Yup.string().oneOf(Object.values(IsoLanguage))),
          hotlineNumber: Yup.string().matches(
            /^(\+)[1-9]([0-9]\s?){6,30}$/,
            formatMessage("error.validation.notAPhoneNumber")
          ),
          email: Yup.string().email(formatMessage("error.validation.mustBeEmail")),
          timeZone: Yup.string().oneOf(timezoneData),
          workingHours: Yup.array()
            .of(
              Yup.object().shape({
                workingDays: Yup.array().of(Yup.string().oneOf(Object.values(DayOfWeek))),
                from: Yup.string(),
                to: Yup.string(),
              })
            )
            .test({
              test(workingHours) {
                const timeFormat = getUserPreferredTimeFormat();
                const formatString = getTimeFormatString(timeFormat);

                let errors: Array<{ pathSuffix: string; message: string }> = [];

                workingHours?.forEach((workingHour, i) => {
                  const validationErrors = validateWorkingHours(
                    workingHour.from,
                    workingHour.to,
                    i,
                    timeFormat
                  );

                  if (validationErrors.length > 0) {
                    errors = errors.concat(validationErrors);
                  }
                });

                if (!errors.length) {
                  const rowsWithOverlappingTimes: Array<number> = [];

                  workingHours?.forEach((workingHour, i, totalWorkingHours) => {
                    if (workingHour.from && workingHour.to) {
                      const rowsWithSameDaySelected = getRowsWithSameDaySelected(
                        totalWorkingHours as CustomerServiceContactFormValue["workingHours"],
                        i
                      );

                      const uniqueRowsWithSameDaySelected = removeDuplicates(
                        rowsWithSameDaySelected
                      );

                      if (uniqueRowsWithSameDaySelected.length) {
                        const currentFrom = workingHours[i].from;
                        const currentTo = workingHours[i].to;

                        if (currentFrom && currentTo) {
                          const [currentFromDate, currentToDate] = parseFromAndToTime(
                            currentFrom,
                            currentTo,
                            formatString
                          );

                          uniqueRowsWithSameDaySelected.forEach((row) => {
                            const nextFrom = workingHours[row].from;
                            const nextTo = workingHours[row].to;

                            if (nextFrom && nextTo) {
                              const [nextFromDate, nextToDate] = parseFromAndToTime(
                                nextFrom,
                                nextTo,
                                formatString
                              );

                              if (
                                areTimesOverlapping(
                                  currentFromDate,
                                  currentToDate,
                                  nextFromDate,
                                  nextToDate
                                )
                              ) {
                                rowsWithOverlappingTimes.push(row);
                              }
                            }
                          });
                        }
                      }
                    }
                  });

                  const uniqueRowsWithOverlappingTimes = removeDuplicates(
                    rowsWithOverlappingTimes
                  );

                  if (uniqueRowsWithOverlappingTimes.length) {
                    errors = errors.concat(
                      getErrorsForOverlappingTimes(uniqueRowsWithOverlappingTimes)
                    );
                  }
                }

                if (errors.length) {
                  const formattedErrors = errors.map((error) => {
                    return this.createError({
                      path: `${this.path}${error.pathSuffix}`,
                      message: error.message,
                    });
                  });

                  return new Yup.ValidationError(formattedErrors);
                }

                return true;
              },
            }),
          holidayWorkingHours: Yup.object().shape({
            enabled: Yup.boolean(),
            workingHours: Yup.array()
              .of(
                Yup.object().shape({
                  from: Yup.string(),
                  to: Yup.string(),
                })
              )
              .test({
                test(workingHours) {
                  const timeFormat = getUserPreferredTimeFormat();
                  const formatString = getTimeFormatString(timeFormat);

                  let errors: Array<{ pathSuffix: string; message: string }> = [];

                  if (workingHours?.length) {
                    workingHours.forEach((workingHour, i) => {
                      const validationErrors = validateWorkingHours(
                        workingHour.from,
                        workingHour.to,
                        i,
                        timeFormat
                      );

                      if (validationErrors.length > 0) {
                        errors = errors.concat(validationErrors);
                      }
                    });

                    if (!errors.length && workingHours.length > 1) {
                      const rowsWithOverlappingTimes: Array<number> = [];

                      workingHours.forEach((currentWorkingHour, i, arr) => {
                        if (i < arr.length - 1) {
                          const currentFrom = currentWorkingHour.from;
                          const currentTo = currentWorkingHour.to;

                          if (currentFrom && currentTo) {
                            const [currentFromDate, currentToDate] = parseFromAndToTime(
                              currentFrom,
                              currentTo,
                              formatString
                            );

                            let nextIndex = i + 1;

                            while (nextIndex < arr.length) {
                              const nextFrom = workingHours[nextIndex].from;
                              const nextTo = workingHours[nextIndex].to;

                              if (nextFrom && nextTo) {
                                const [nextFromDate, nextToDate] = parseFromAndToTime(
                                  nextFrom,
                                  nextTo,
                                  formatString
                                );

                                if (
                                  areTimesOverlapping(
                                    currentFromDate,
                                    currentToDate,
                                    nextFromDate,
                                    nextToDate
                                  )
                                ) {
                                  rowsWithOverlappingTimes.push(nextIndex);
                                }
                              }
                              nextIndex++;
                            }
                          }
                        }
                      });

                      const uniqueRowsWithOverlappingTimes = removeDuplicates(
                        rowsWithOverlappingTimes
                      );

                      if (uniqueRowsWithOverlappingTimes.length) {
                        errors = errors.concat(
                          getErrorsForOverlappingTimes(uniqueRowsWithOverlappingTimes)
                        );
                      }
                    }
                  }

                  if (errors.length) {
                    const formattedErrors = errors.map((error) => {
                      return this.createError({
                        path: `${this.path}${error.pathSuffix}`,
                        message: error.message,
                      });
                    });

                    return new Yup.ValidationError(formattedErrors);
                  }

                  return true;
                },
              }),
          }),
        })
      ),
    }),
    termsAndConditions: Yup.object().shape({
      termsAndConditionsUrl: Yup.string()
        .url(formatMessage("error.validation.mustBeUrl"))
        .max(
          255,
          formatMessage("error.validation.maxCharacters", { numCharacters: 255 })
        ),
    }),
  });
};
