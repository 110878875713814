import * as React from "react";
import * as css from "./BrandsInputSelectTemplate.scss";

type Props = {
  SelectElement: JSX.Element;
  ButtonElement: JSX.Element;
};

const BrandsInputSelectTemplate: React.FC<Props> = ({ SelectElement, ButtonElement }) => {
  return (
    <div className={css.container}>
      <div className={css.select}>{SelectElement}</div>
      {ButtonElement}
    </div>
  );
};

export default BrandsInputSelectTemplate;
