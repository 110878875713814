import { Grid, GridCol } from "@flixbus/honeycomb-react";
import pin from "@flixbus-phx/marketplace-common/assets/map/green_pin.svg";
import { useField } from "formik2";
import { icon } from "leaflet";
import * as React from "react";
import { useIntl } from "react-intl";
import { Map, TileLayer, Marker } from "react-leaflet";
import FormikInput from "../../../../shared/components/formikInput/FormikInput";
import getPositionForLeaflet from "../../../../shared/helpers/getPositionForLeaflet/getPositionForLeaflet";
import isValidCoordinate from "../../../../shared/helpers/isValidCoordinate/isValidCoordinate";
import * as css from "./GeoCoordinatesInput.scss";
import "leaflet/dist/leaflet.css";

type Props = {
  readOnly?: boolean;
};

const GeoCoordinatesInput: React.FC<Props> = ({ readOnly = false }) => {
  const { formatMessage } = useIntl();

  const [, { value: latitude }] = useField<string>("latitude");
  const [, { value: longitude }] = useField<string>("longitude");

  const markerIcon = icon({
    iconUrl: pin,
    iconSize: [74, 89],
    iconAnchor: [37, 68],
  });

  const position = getPositionForLeaflet(latitude, longitude);

  return (
    <>
      <Grid>
        <GridCol size={6}>
          <FormikInput
            name="latitude"
            label={formatMessage({ id: "station.latitude" })}
            placeholder="48.366512"
            type="text"
            readOnly={readOnly}
            required
          />
        </GridCol>
        <GridCol size={6}>
          <FormikInput
            name="longitude"
            label={formatMessage({ id: "station.longitude" })}
            placeholder="10.894446"
            type="text"
            readOnly={readOnly}
            required
          />
        </GridCol>
      </Grid>
      <Map
        className={css.map}
        center={isValidCoordinate(position) ? position : { lat: 0, lng: 0 }}
        zoom={isValidCoordinate(position) ? 13 : 0}
        scrollWheelZoom={false}
      >
        <TileLayer
          url="https://d1oy2gi6prj6xv.cloudfront.net/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        {isValidCoordinate(position) && <Marker position={position} icon={markerIcon} />}
      </Map>
    </>
  );
};

export default GeoCoordinatesInput;
