import { Icon, IconClose } from "@flixbus/honeycomb-icons-react";
import * as React from "react";
import { components, ClearIndicatorProps } from "react-select";
import { SelectOption } from "../types";
import * as css from "./ClearIndicator.scss";

type IndicatorProps = ClearIndicatorProps<SelectOption, false>;

const ClearIndicator: React.FC<IndicatorProps> = (props) => {
  return (
    <components.ClearIndicator {...props}>
      <Icon InlineIcon={IconClose} extraClasses={css.icon} />
    </components.ClearIndicator>
  );
};

export default ClearIndicator;
