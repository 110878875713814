import {
  LuggagePolicy,
  PassengerPolicy,
  TimeUnit,
} from "../../../../shared/types/schema";
import { PolicyFormValues } from "../../types";
import {
  getInitialValuesForBookableLuggage,
  getInitialValuesForIncludedLuggage,
} from "../getInitialValuesForLuggage/getInitialValuesForLuggage";

const getInitialValuesForPolicy = (
  defaultValues?: LuggagePolicy & PassengerPolicy
): PolicyFormValues => ({
  luggageAllowance: {
    handLuggagePolicy: getInitialValuesForIncludedLuggage(
      defaultValues?.handLuggagePolicy
    ),
    checkInLuggagePolicy: getInitialValuesForIncludedLuggage(
      defaultValues?.checkInLuggagePolicy
    ),
    additionalLuggagePolicy: getInitialValuesForBookableLuggage(
      ["pointOfSale", "maxWeight", "maxDimension", "feePrice", "feeType"],
      defaultValues?.additionalLuggagePolicy
    ),
  },
  specialLuggageAndBuggies: {
    specialLuggagePolicy: getInitialValuesForBookableLuggage(
      [
        "pointOfSale",
        "bookableUntil",
        "maxWeight",
        "maxDimension",
        "feePrice",
        "feeType",
      ],
      defaultValues?.specialLuggagePolicy
    ),
    pushchairPolicy: getInitialValuesForBookableLuggage(
      ["pointOfSale", "bookableUntil", "feePrice", "feeType"],
      defaultValues?.pushchairPolicy
    ),
  },
  unaccompaniedMinors: {
    allowed: defaultValues?.unaccompaniedMinorPolicy?.allowed,
    unaccompaniedMinorSpecification: {
      interconnectionTravelAllowed: false,
      interconnectionTravel: undefined,
      nightTravelAllowed: false,
      nightTravel: undefined,
      internationalTravelAllowed: false,
      internationalTravel: undefined,
      ...defaultValues?.unaccompaniedMinorPolicy?.unaccompaniedMinorSpecification,
      minAgeYears: defaultValues?.unaccompaniedMinorPolicy
        ?.unaccompaniedMinorSpecification?.minAgeYears
        ? defaultValues?.unaccompaniedMinorPolicy?.unaccompaniedMinorSpecification
            ?.minAgeYears
        : ("" as unknown as number),
    },
  },
  passengerWithDisability: {
    supported: defaultValues?.passengerWithDisabilityPolicy?.supported,
    passengerWithDisabilitySpecification: {
      proofOfDisabilityRequired: false,
      supportingServices: [],
      ...defaultValues?.passengerWithDisabilityPolicy
        ?.passengerWithDisabilitySpecification,
      registrationUntil: {
        value: "" as unknown as number,
        unit: TimeUnit.Hours,
        ...defaultValues?.passengerWithDisabilityPolicy
          ?.passengerWithDisabilitySpecification?.registrationUntil,
      },
    },
  },
  bikesSurfboardsAndSkies: {
    bikePolicy: getInitialValuesForBookableLuggage(
      ["pointOfSale", "bookableUntil", "feePrice"],
      defaultValues?.bikePolicy
    ),
    surfboardPolicy: getInitialValuesForBookableLuggage(
      ["pointOfSale", "bookableUntil", "feePrice"],
      defaultValues?.surfboardPolicy
    ),
    skiEquipmentPolicy: getInitialValuesForBookableLuggage(
      ["pointOfSale", "bookableUntil", "feePrice"],
      defaultValues?.skiEquipmentPolicy
    ),
  },
  pets: {
    petPolicy: getInitialValuesForBookableLuggage(
      ["pointOfSale", "bookableUntil", "feePrice"],
      defaultValues?.petPolicy
    ),
  },
});

export default getInitialValuesForPolicy;
