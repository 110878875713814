import { Heading } from "@flixbus/honeycomb-react";
import { Form, Formik } from "formik2";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import CirculationSelect from "../../shared/components/circulationSelect/CirculationSelect";
import CustomFormRow from "../../shared/components/customFormRow/CustomFormRow";
import InfoText from "../../shared/components/infoText/InfoText";
import { PartnerEditableFields } from "../../shared/helpers/getEditableFormFields/types";
import { CapacityAndReportsInput } from "../../shared/types/schema";
import * as css from "./PartnerCapacityAndReportsForm.scss";
import validationSchema from "./helpers/validationSchema/validationSchema";
import BookingReportsSection from "./ui/bookingReportsSection/BookingReportsSection";
import CapacityDecreaseSelector from "./ui/capacityDecreaseSelector/CapacityDecreaseSelector";

export type PartnerCapacityAndReportsFormProps = {
  onSubmit: (values: CapacityAndReportsInput) => void;
  onCancel: () => void;
  defaultValues?: Partial<CapacityAndReportsInput>;
  editableFields?: PartnerEditableFields["capacityAndReportsInput"];
  partnerId: string;
};

const PartnerCapacityAndReportsForm: React.FC<PartnerCapacityAndReportsFormProps> = ({
  onSubmit,
  onCancel,
  defaultValues,
  editableFields,
  children,
  partnerId,
}) => {
  const { formatMessage } = useIntl();
  const initialValues: CapacityAndReportsInput = {
    dataPrivacyContractSigned: false,
    contactEmails: [],
    bookingInformationConfiguration: {
      bookingInformation: [],
      tripInformation: [],
      passengerInformation: [],
    },
    ...defaultValues,
    capacityDecreaseTimeFrameInHours: defaultValues?.capacityDecreaseTimeFrameInHours
      ? defaultValues.capacityDecreaseTimeFrameInHours
      : ("" as unknown as number),
    defaultCirculationId: defaultValues?.defaultCirculationId
      ? defaultValues.defaultCirculationId
      : "",
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={(values) => {
        onSubmit({
          ...values,
          capacityDecreaseTimeFrameInHours:
            // @ts-ignore even though TS claims that this can never be a string, it actually can because of the initial value of it
            values.capacityDecreaseTimeFrameInHours !== ""
              ? values.capacityDecreaseTimeFrameInHours
              : undefined,
        });
      }}
      onReset={onCancel}
    >
      <Form noValidate>
        <Heading size={4} extraClasses={css.heading} data-id="capacity-decrease-selector">
          <FormattedMessage id="partnerForm.capacity.headline" />
        </Heading>

        {editableFields?.defaultCirculationId ? (
          <CustomFormRow data-id="circulationCapacity-wrapper">
            <CirculationSelect partnerId={partnerId} extraClasses={css.circulation} />
          </CustomFormRow>
        ) : (
          <></>
        )}
        <CustomFormRow data-id="capacity-decrease-selector">
          <CapacityDecreaseSelector
            readOnly={!editableFields?.capacityDecreaseTimeFrameInHours}
          />
        </CustomFormRow>

        <CustomFormRow data-id="booking-information-configuration">
          {editableFields?.dataPrivacyContractSigned && (
            <>
              <Heading size={4} extraClasses={css.heading}>
                <FormattedMessage id="navigation.bookingInfo" />
                <br />
                <InfoText
                  text={formatMessage({
                    id: "partnerForm.bookingInfo.info.privacyContract",
                  })}
                />
              </Heading>
            </>
          )}
          <BookingReportsSection editableFields={editableFields} />
        </CustomFormRow>

        {children}
      </Form>
    </Formik>
  );
};

export default PartnerCapacityAndReportsForm;
