import { IconAttentionSolid } from "@flixbus/honeycomb-icons-react";
import { Text } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ConfirmationPopup from "../../shared/components/confirmationPopup/ConfirmationPopup";
import { NearbyStations } from "../../shared/hooks/useNearbyStations/useNearbyStations";
import * as css from "./NearbyStationPopup.scss";
import NearbyStationList from "./ui/nearbyStationList/NearbyStationList";
import NearbyStationMap from "./ui/nearbyStationMap/NearbyStationMap";

export type NearbyStationPopupProps = {
  onSubmit?: () => void;
  onCancel: () => void;
  confirmButtonText: string;
  stations: NearbyStations;
  confirmButtonLoading: boolean;
  latitude: string;
  longitude: string;
  isRenderedInsidePopup?: boolean;
};

const NearbyStationPopup: React.FC<NearbyStationPopupProps> = ({
  onSubmit,
  onCancel,
  confirmButtonText,
  stations,
  confirmButtonLoading,
  latitude,
  longitude,
  isRenderedInsidePopup,
}) => {
  const { formatMessage } = useIntl();

  return (
    <ConfirmationPopup
      active
      titleIcon={IconAttentionSolid}
      titleIconColor="warning"
      titleText={formatMessage({ id: "nearbyStations.popup.title" })}
      confirmButtonText={confirmButtonText}
      cancelButtonText={
        isRenderedInsidePopup
          ? formatMessage({ id: "general.back" })
          : formatMessage({ id: "general.close" })
      }
      onConfirm={onSubmit}
      onCancel={onCancel}
      confirmButtonLoading={confirmButtonLoading}
    >
      {isRenderedInsidePopup && (
        <style
          // Ugly hack to be able to render a popup inside a popup
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html:
              '[class^="hcr-popup__body"]:not([class^="hcr-popup__body"] [class^="hcr-popup__body"]) { height: 100% }',
          }}
        />
      )}

      <Text extraClasses={css.text}>
        <FormattedMessage id="nearbyStations.popup.info.top" />
        <br />
        <FormattedMessage id="nearbyStations.popup.info.bottom" />
      </Text>

      <NearbyStationMap stations={stations} latitude={latitude} longitude={longitude} />

      <NearbyStationList stations={stations} />
    </ConfirmationPopup>
  );
};

export default NearbyStationPopup;
