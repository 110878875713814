@import "variables";

.label {
  color: $content-secondary-color;
}

.info {
  position: relative;
  font-size: 14px;
  font-weight: 300;
  line-height: 17px;
  display: block;
  padding-top: 3px;
  color: $danger-dark-color;
}

.narrow {
  width: 50%;
}
