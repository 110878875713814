import { ReactiveVar, useReactiveVar } from "@apollo/client";

const useSelectedStation = (
  selectedStationVar: ReactiveVar<string>
): [string, (station: string) => void] => {
  const selectedStation = useReactiveVar(selectedStationVar);

  const changeSelectedStation = (station: string) => {
    selectedStationVar(station);
  };

  return [selectedStation, changeSelectedStation];
};

export default useSelectedStation;
