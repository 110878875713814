import { formatMessage } from "@flixbus-phx/marketplace-common";
import { ImportanceClass } from "../../../../shared/types/schema";

const importanceClassOptions = () => [
  {
    value: ImportanceClass.A,
    label: formatMessage("city.importanceClass.select.classA", {
      importanceClassA: ImportanceClass.A,
    }),
  },
  {
    value: ImportanceClass.B,
    label: formatMessage("city.importanceClass.select.classB", {
      importanceClassB: ImportanceClass.B,
    }),
  },
  {
    value: ImportanceClass.C,
    label: formatMessage("city.importanceClass.select.classC", {
      importanceClassC: ImportanceClass.C,
    }),
  },
];

export default importanceClassOptions;
