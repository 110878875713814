import * as React from "react";
import * as css from "./MoreSelectedTag.scss";

type Props = {
  numberOfItems: number;
};

const MoreSelectedTag: React.FC<Props> = ({ numberOfItems }) => {
  return (
    <div className={css.wrapper}>
      <span className={css.dots}>...</span>
      <div className={css.tag}>+ {numberOfItems}</div>
    </div>
  );
};

export default MoreSelectedTag;
