import { PolicyInput, PolicyType } from "../../../../shared/types/schema";
import { PolicyFormValues } from "../../types";
import {
  getSubmittableValuesForBookableLuggage,
  getSubmittableValuesForIncludedLuggage,
  getSubmittableValuesForPassengerWithDisability,
  getSubmittableValuesForUnaccompaniedMinor,
} from "../getSubmittableValuesForSubPolicy/getSubmittableValuesForSubPolicy";

const getSubmittableValuesForPolicy = (values: {
  type: PolicyType;
  policy: PolicyFormValues;
  additionalInformation: string;
}): PolicyInput => {
  return {
    type: values.type,
    luggagePolicy: {
      handLuggagePolicy: getSubmittableValuesForIncludedLuggage(
        values.policy.luggageAllowance.handLuggagePolicy
      ),
      checkInLuggagePolicy: getSubmittableValuesForIncludedLuggage(
        values.policy.luggageAllowance.checkInLuggagePolicy
      ),
      additionalLuggagePolicy: getSubmittableValuesForBookableLuggage(
        values.policy.luggageAllowance.additionalLuggagePolicy
      ),
      specialLuggagePolicy: getSubmittableValuesForBookableLuggage(
        values.policy.specialLuggageAndBuggies.specialLuggagePolicy
      ),
      pushchairPolicy: getSubmittableValuesForBookableLuggage(
        values.policy.specialLuggageAndBuggies.pushchairPolicy
      ),
      bikePolicy: getSubmittableValuesForBookableLuggage(
        values.policy.bikesSurfboardsAndSkies.bikePolicy
      ),
      surfboardPolicy: getSubmittableValuesForBookableLuggage(
        values.policy.bikesSurfboardsAndSkies.surfboardPolicy
      ),
      skiEquipmentPolicy: getSubmittableValuesForBookableLuggage(
        values.policy.bikesSurfboardsAndSkies.skiEquipmentPolicy
      ),
      petPolicy: getSubmittableValuesForBookableLuggage(values.policy.pets.petPolicy),
    },
    passengerPolicy: {
      unaccompaniedMinorPolicy: getSubmittableValuesForUnaccompaniedMinor(
        values.policy.unaccompaniedMinors
      ),
      passengerWithDisabilityPolicy: getSubmittableValuesForPassengerWithDisability(
        values.policy.passengerWithDisability
      ),
    },
    additionalInformation: values.additionalInformation || undefined,
  };
};

export default getSubmittableValuesForPolicy;
