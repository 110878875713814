import { mpcPathnames as pathnames } from "@flixbus-phx/marketplace-common";

const buildPath = {
  showPartnerDetail: (id: string): string =>
    pathnames.showPartnerDetail.replace(":partnerId", id),
  createLine: (partner: string): { pathname: string; search: string } => ({
    pathname: pathnames.createLine,
    search: `?partner=${partner}`,
  }),
  showLineDetail: (id: string): string => pathnames.showLineDetail.replace(":lineId", id),
  createSchedule: (line: string): { pathname: string; search: string } => ({
    pathname: pathnames.createSchedule,
    search: `?line=${line}`,
  }),
  showScheduleDetail: (id: string): string =>
    pathnames.showScheduleDetail.replace(":scheduleId", id),
};

export default buildPath;
