import { Input } from "@flixbus/honeycomb-react";
import * as React from "react";
import {
  addColonIfNeeded,
  addWhitespaceIfNeeded,
} from "../../helpers/timeFormatter/addSeparatorIfNeeded";
import { isPreValid12hFormat } from "../../helpers/timeValidator/twelveHourClockFormatValidator";
import { isPreValid24hFormat } from "../../helpers/timeValidator/twentyFourHourClockFormatValidator";
import * as css from "./TimeInput.scss";
import { TimeFormat, TimeFormatPlaceholder } from "./types";

export type TimeInputProps = {
  value: string;
  onValueChanged: (value: string) => void;
  disabled?: boolean;
  timeFormat?: TimeFormat;
  label?: string;
  required?: boolean;
  valid?: boolean;
  onBlur?: () => void;
  infoError?: string;
};

/**
 * Don't forget to add the <TimeFormatPopup /> at view-level, when using this component
 */

const TimeInput: React.FC<TimeInputProps> = ({
  value,
  onValueChanged,
  disabled = false,
  timeFormat = TimeFormat.TWENTY_FOUR_HOUR_CLOCK,
  label,
  required,
  valid,
  onBlur = () => {},
  infoError,
}) => {
  const [currentValue, setCurrentValue] = React.useState(value);
  const [placeholder, setPlaceholder] = React.useState("");

  React.useEffect(() => {
    setCurrentValue(value);

    if (timeFormat === TimeFormat.TWELVE_HOUR_CLOCK) {
      setPlaceholder(TimeFormatPlaceholder.TWELVE_HOUR_CLOCK);
    } else {
      setPlaceholder(TimeFormatPlaceholder.TWENTY_FOUR_HOUR_CLOCK);
    }
  }, [value, timeFormat]);

  const handle24hTimeInput = (changedValue: string) => {
    if (!isPreValid24hFormat(changedValue)) {
      return;
    }

    const newValue = `${changedValue}${addColonIfNeeded(currentValue, changedValue)}`;
    setCurrentValue(newValue);
  };

  const handle12hTimeInput = (changedValue: string) => {
    if (!isPreValid12hFormat(changedValue)) {
      return;
    }

    const newValue = `${changedValue}${addColonIfNeeded(
      currentValue,
      changedValue
    )}${addWhitespaceIfNeeded(currentValue, changedValue)}`;
    setCurrentValue(newValue);
  };

  const handleTimeInputChanged = (changedValue: string) => {
    if (changedValue === currentValue) {
      return;
    }

    if (timeFormat === TimeFormat.TWELVE_HOUR_CLOCK) {
      handle12hTimeInput(changedValue);
    } else {
      handle24hTimeInput(changedValue);
    }
  };

  const handleOnBlur = () => {
    let currentVal = currentValue;

    if (currentValue.length === 0) {
      if (timeFormat === TimeFormat.TWELVE_HOUR_CLOCK) {
        setPlaceholder(TimeFormatPlaceholder.TWELVE_HOUR_CLOCK);
      } else {
        setPlaceholder(TimeFormatPlaceholder.TWENTY_FOUR_HOUR_CLOCK);
      }
    } else if (timeFormat === TimeFormat.TWELVE_HOUR_CLOCK) {
      currentVal = currentVal.toUpperCase();
      setCurrentValue(currentVal);
    }

    onValueChanged(currentVal);
    onBlur();
  };

  const handleOnFocus = () => {
    setPlaceholder("");
  };

  return (
    <Input
      type="text"
      id={`time-input-${Math.random()}`}
      name="time-input"
      aria-label="time-input"
      value={currentValue}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        handleTimeInputChanged(event.target.value)
      }
      extraClasses={css.timeInput}
      placeholder={placeholder}
      disabled={disabled}
      onBlur={handleOnBlur}
      onFocus={handleOnFocus}
      label={label ? `${label}${required ? " *" : ""}` : label}
      required={required}
      valid={valid}
      infoError={infoError}
    />
  );
};

export default TimeInput;
