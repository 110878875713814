import { Fineprint } from "@flixbus/honeycomb-react";
import { localizeDayOfMonth, localizeWeekday } from "@flixbus-phx/marketplace-common";
import cx from "classnames";
import { isToday as dateFnsIsToday, isWeekend as dateFnsIsWeekend } from "date-fns";
import * as React from "react";
import * as css from "./DayHeaderCell.scss";

type Props = {
  currentDate: Date;
  cellWidth: number;
  colStart: number;
};

const DayHeaderCell: React.FC<Props> = ({ currentDate, cellWidth, colStart }) => {
  const isWeekend = dateFnsIsWeekend(currentDate);
  const isToday = dateFnsIsToday(currentDate);

  return (
    <div
      className={cx(css.scrollableColumn)}
      style={{
        width: `${cellWidth}px`,
        transform: `translateX(${colStart}px)`,
      }}
    >
      <div className={cx(css.cell, isWeekend && css.weekend, isToday && css.today)}>
        <Fineprint flushSpace>{localizeWeekday(currentDate)}</Fineprint>
        <div className={cx(css.dayOfMonth, isToday && css.today)}>
          {localizeDayOfMonth(currentDate)}
        </div>
      </div>
    </div>
  );
};

export default DayHeaderCell;
