@import "variables";
@import "../../helpers/styles/common-styles.scss";

.highlightedCell {
  grid-row: 1/2;
}

.noPeriodMessage {
  grid-column: 1 / -1;
  grid-row: 1 / 1;
  justify-self: center;
  align-self: center;
}
