import { Calendar } from "@flixbus/honeycomb-react";
import { add, startOfTomorrow } from "date-fns";
import * as React from "react";
import { days, months } from "../../helpers/calendarHelper/calendarHelper";
import { Maybe, SchedulePeriod } from "../../types/schema";
import * as css from "./PeriodCalendar.scss";

// Note: you should either use onStartDateSelected together with onEndDateSelected or only onPeriodSelected.
export type PeriodCalendarProps = {
  onCalendarClose: () => void;
  defaultStartDate?: Date;
  defaultEndDate?: Date;
  onStartDateSelected?: (date: Date) => void;
  onEndDateSelected?: (date: Date) => void;
  onPeriodSelected?: (startDate: Date, endDate: Date) => void;
  additionalReference?: React.RefObject<HTMLInputElement>;
  selectablePeriod?: Maybe<SchedulePeriod>;
};

const PeriodCalendar: React.FC<PeriodCalendarProps> = ({
  onCalendarClose,
  defaultStartDate = undefined,
  defaultEndDate = undefined,
  onStartDateSelected = () => {},
  onEndDateSelected = () => {},
  onPeriodSelected = () => {},
  additionalReference,
  selectablePeriod,
}) => {
  const [selectedStartDate, setSelectedStartDate] = React.useState(defaultStartDate);
  const [selectedEndDate, setSelectedEndDate] = React.useState(defaultEndDate);
  const [earliestSelectableDate, setEarliestSelectableDate] = React.useState(
    selectablePeriod?.start
  );

  React.useEffect(() => {
    if (
      selectedStartDate !== undefined &&
      selectedEndDate !== undefined &&
      selectedStartDate !== defaultStartDate &&
      selectedEndDate !== defaultEndDate
    ) {
      onPeriodSelected(selectedStartDate, selectedEndDate);
    }
    if (selectedStartDate !== undefined && selectedStartDate !== defaultStartDate) {
      onStartDateSelected(selectedStartDate);
    }
    if (selectedEndDate !== undefined && selectedEndDate !== defaultEndDate) {
      onEndDateSelected(selectedEndDate);
    }
  }, [selectedStartDate, selectedEndDate]);

  const handleSelect = (date: Date): void => {
    setEarliestSelectableDate(date);

    if (selectedStartDate === undefined) {
      setSelectedStartDate(date);
      return;
    }

    if (selectedEndDate === undefined) {
      setSelectedEndDate(date);
      return;
    }

    if (selectedStartDate !== undefined && selectedEndDate !== undefined) {
      setSelectedStartDate(date);
      setSelectedEndDate(undefined);
    }
  };

  const ref = React.useRef<HTMLInputElement>(null);

  const handleClickOutside: EventListener = (event) => {
    if (
      ref.current &&
      !ref.current.contains(event.target as Node) &&
      !additionalReference?.current?.contains(event.target as Node)
    ) {
      onCalendarClose();
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return (
    <div ref={ref} className={css.container}>
      <Calendar
        id="period-calendar"
        extraClasses={css.calendar}
        appearance="compact"
        startDate={
          earliestSelectableDate ? new Date(earliestSelectableDate) : startOfTomorrow()
        }
        endDate={
          selectablePeriod?.end
            ? new Date(selectablePeriod?.end)
            : add(startOfTomorrow(), { years: 3 })
        }
        startSelected={selectedStartDate}
        endSelected={selectedEndDate}
        handleSelect={(date: Date) => handleSelect(date)}
        days={days()}
        months={months()}
      />
    </div>
  );
};

export default PeriodCalendar;
