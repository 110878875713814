@import "variables";
@import "../../helper/styles/styles.scss";

.stickyColumn {
  position: sticky;
  left: 0;
  z-index: 1;
}

.scrollableColumn {
  @include scrollableColumn;
  z-index: 1;
  background-color: $bg-primary-color;
  height: $topHeaderHeight;
  border-left: $border-light;
  left: -1px;
}

.textCell {
  border-top: $border;
  border-bottom: $border;
  height: $topHeaderHeight;
  line-height: $topHeaderHeight;
  white-space: nowrap;
  padding-left: $spacing-1;
}
