import { Icon, IconInfo } from "@flixbus/honeycomb-icons-react";
import { Tooltip } from "@flixbus/honeycomb-react";
import * as React from "react";
import * as css from "./InfoTooltipLabel.scss";

type props = {
  labelText: string;
  tooltipContent: string | JSX.Element;
};

const InfoTooltipLabel: React.FC<props> = ({ labelText, tooltipContent }) => {
  return (
    <div>
      <span className={css.label}>{labelText}</span>
      <Tooltip
        id={`${labelText}-tooltip`}
        content={tooltipContent}
        openOnHover
        size="medium"
      >
        <Icon InlineIcon={IconInfo} size={3} />
      </Tooltip>
    </div>
  );
};

export default InfoTooltipLabel;
