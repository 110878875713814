import { formatMessage } from "@flixbus-phx/marketplace-common";
import { DayOfWeek } from "../../types/schema";

const getWeekdayText = (day: DayOfWeek): string => {
  switch (day) {
    case DayOfWeek.Monday:
      return formatMessage("general.monday");
    case DayOfWeek.Tuesday:
      return formatMessage("general.tuesday");
    case DayOfWeek.Wednesday:
      return formatMessage("general.wednesday");
    case DayOfWeek.Thursday:
      return formatMessage("general.thursday");
    case DayOfWeek.Friday:
      return formatMessage("general.friday");
    case DayOfWeek.Saturday:
      return formatMessage("general.saturday");
    case DayOfWeek.Sunday:
      return formatMessage("general.sunday");
    default:
      return formatMessage("general.notAvailable");
  }
};

export default getWeekdayText;
