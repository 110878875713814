// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// This file was automatically generated using npm run codegen
// If you want to alter this file please do so via the above mentioned command
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
import * as Types from "../../types/schema";

import { gql } from "@apollo/client";
export type PricingPeriodsFragment = {
  __typename?: "Schedule";
  pricingPeriods: Array<{
    __typename?: "PricingPeriod";
    direction: Types.PriceDirection;
    period?: { __typename?: "Period"; start: any; end: any } | null;
    relationMatrix: {
      __typename?: "RelationMatrix";
      rows: Array<{
        __typename?: "RelationMatrixRow";
        entries: Array<{
          __typename?: "RelationMatrixEntry";
          type: Types.RelationType;
          price?: number | null;
          from: { __typename?: "Station"; id: string };
          to: { __typename?: "Station"; id: string };
        }>;
      }>;
    };
  }>;
};

export const PricingPeriodsFragmentDoc = gql`
  fragment PricingPeriods on Schedule {
    pricingPeriods {
      direction
      period {
        start
        end
      }
      relationMatrix {
        rows {
          entries {
            from {
              id
            }
            to {
              id
            }
            type
            price
          }
        }
      }
    }
  }
`;
