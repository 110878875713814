import { Button, FormRow } from "@flixbus/honeycomb-react";
import cx from "classnames";
import { useFormikContext } from "formik2";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import * as css from "./StationFormButtons.scss";

export type StationFormButtonsProps = {
  submitButtonText: string;
  usedInPanel?: boolean;
  readOnly?: boolean;
  hideCloseButton?: boolean;
  submitLoading: boolean;
};

const StationFormButtons: React.FC<StationFormButtonsProps> = ({
  submitButtonText,
  usedInPanel = false,
  readOnly,
  hideCloseButton,
  submitLoading,
}) => {
  const { dirty } = useFormikContext();

  return (
    <FormRow>
      <div
        data-id="button-wrapper"
        className={cx(css.buttonWrapper, usedInPanel && css.panelButtonWrapper)}
      >
        {readOnly ? (
          hideCloseButton ? (
            <></>
          ) : (
            <Button
              type="reset"
              appearance="primary"
              extraClasses={cx(usedInPanel && css.panelButton)}
            >
              <FormattedMessage id="general.close" />
            </Button>
          )
        ) : (
          <>
            <Button type="reset" extraClasses={cx(usedInPanel && css.panelButton)}>
              <FormattedMessage id="general.cancel" />
            </Button>

            <Button
              type="submit"
              disabled={!dirty}
              appearance="primary"
              extraClasses={cx(usedInPanel && css.panelButton)}
              loading={submitLoading}
            >
              {submitButtonText}
            </Button>
          </>
        )}
      </div>
    </FormRow>
  );
};

export default StationFormButtons;
