import * as React from "react";
import { useIntl } from "react-intl";
import FormikCheckbox from "../../../shared/components/formikCheckbox/FormikCheckbox";
import { MeansOfTransport } from "../../../shared/types/schema";
import { SearchType } from "../../types";
import * as css from "../form_field_styles.scss";

type Props = {
  type: SearchType;
};

const MeansOfTransportFilter: React.FC<Props> = ({ type }) => {
  const { formatMessage } = useIntl();

  return (
    <div>
      <div className={css.checkbox}>
        <FormikCheckbox
          label={formatMessage({ id: `meansOfTransport.${MeansOfTransport.Bus}` })}
          small
          name="meansOfTransport.bus"
        />
      </div>
      <div className={css.checkbox}>
        <FormikCheckbox
          label={formatMessage({ id: `meansOfTransport.${MeansOfTransport.Train}` })}
          small
          name="meansOfTransport.train"
        />
      </div>
      <div className={css.checkbox}>
        <FormikCheckbox
          label={formatMessage({ id: `meansOfTransport.${MeansOfTransport.Ferry}` })}
          small
          name="meansOfTransport.ferry"
        />
      </div>
      <div className={css.checkbox}>
        <FormikCheckbox
          label={formatMessage({
            id: `meansOfTransport.${MeansOfTransport.MiniBus}`,
          })}
          small
          name="meansOfTransport.minibus"
        />
      </div>
      {type === SearchType.PARTNERS && (
        <div className={css.checkbox}>
          <FormikCheckbox
            label={formatMessage({ id: "general.unset" })}
            small
            name="meansOfTransport.unset"
          />
        </div>
      )}
    </div>
  );
};

export default MeansOfTransportFilter;
