import { formatPriceNumberInput } from "../../../../shared/helpers/priceInputFormatter/priceInputFormatter";
import {
  BookableLuggagePolicy,
  BookableLuggagePolicyInput,
  IncludedLuggagePolicy,
  LuggageFeeType,
  PointOfSaleType,
  TimeUnit,
  Maybe,
} from "../../../../shared/types/schema";
import { BookableLuggageFormValues, IncludedLuggageFormValues } from "../../types";

export const getInitialValuesForIncludedLuggage = (
  defaultValues?: Maybe<IncludedLuggagePolicy>
): IncludedLuggageFormValues => {
  const luggageSpecification = defaultValues?.luggageSpecification;

  return {
    inTicketIncluded: defaultValues?.inTicketIncluded,
    luggageSpecification: {
      maxWeight: {
        value: "" as unknown as number,
        ...luggageSpecification?.maxWeight,
      },
      maxDimension: {
        value: "",
        ...luggageSpecification?.maxDimension,
      },
      maxPieces: luggageSpecification?.maxPieces || ("" as unknown as number),
    },
  };
};

export const getInitialValuesForBookableLuggage = (
  luggageSpecificationConfiguration: Array<
    keyof NonNullable<BookableLuggagePolicy["luggageSpecification"]>
  >,
  defaultValues?: Maybe<BookableLuggagePolicy>
): BookableLuggageFormValues => {
  const luggageSpecification = defaultValues?.luggageSpecification;
  const initialValues = {
    bookable: defaultValues?.bookable,
    luggageSpecification: {
      pointOfSale: luggageSpecification?.pointOfSale || ("" as PointOfSaleType),
      bookableUntil: {
        value: "" as unknown as number,
        unit: TimeUnit.Hours,
        ...luggageSpecification?.bookableUntil,
      },
      maxWeight: {
        value: "" as unknown as number,
        ...luggageSpecification?.maxWeight,
      },
      maxDimension: {
        value: "",
        ...luggageSpecification?.maxDimension,
      },
      feePrice: {
        value: formatPriceNumberInput(luggageSpecification?.feePrice?.value),
      },
      feeType: luggageSpecification?.feeType || LuggageFeeType.PricePerPiece,
    },
  };

  (
    Object.keys(initialValues.luggageSpecification) as Array<
      keyof NonNullable<BookableLuggagePolicyInput["luggageSpecification"]>
    >
  ).forEach((key) => {
    if (!luggageSpecificationConfiguration.includes(key)) {
      delete initialValues.luggageSpecification[key];
    }
  });

  return initialValues;
};
