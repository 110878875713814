import * as React from "react";
import { useIntl } from "react-intl";
import * as css from "./UserRoleAssignmentWarning.scss";

type Props = {
  hasError: boolean;
};

const UserRoleAssignmentWarning: React.FC<Props> = ({ hasError }) => {
  const { formatMessage } = useIntl();
  return (
    <div className={css.wrapper}>
      {hasError
        ? formatMessage({ id: "general.defaultError" })
        : formatMessage({ id: "accessManagement.info.noUsers" })}
    </div>
  );
};

export default UserRoleAssignmentWarning;
