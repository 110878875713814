import * as React from "react";
import { FormattedMessage } from "react-intl";
import InterconnectionOperatorIcon from "../../../../shared/components/interconnectionOperatorIcon/InterconnectionOperatorIcon";
import * as css from "./InterconnectionTagText.scss";

type Props = {
  isWhitelist: boolean;
  numberOfOperators: number;
};

const InterconnectionTagText: React.FC<Props> = ({ isWhitelist, numberOfOperators }) => (
  <div className={css.wrapper}>
    <InterconnectionOperatorIcon isWhitelist={isWhitelist} />
    <span>
      <FormattedMessage
        id="partnerForm.interconnectionRules.tag.operators"
        values={{ numberOfOperators }}
      />
    </span>
  </div>
);

export default InterconnectionTagText;
