const getSubmittableUnitValues = <VT, UT>(
  input?: {
    value?: VT;
    unit?: UT;
  } | null
): { value: VT; unit: UT } | undefined => {
  if (input?.value && input?.unit) {
    return {
      value: input.value,
      unit: input.unit,
    };
  }

  return undefined;
};

export default getSubmittableUnitValues;
