import { PoliciesFormValues, PolicyFormValues } from "../../types";

export const isPolicyActive = (policy: PolicyFormValues) => {
  const { luggageAllowance, specialLuggageAndBuggies, bikesSurfboardsAndSkies, pets } =
    policy;

  if (
    luggageAllowance.handLuggagePolicy.inTicketIncluded ||
    luggageAllowance.checkInLuggagePolicy.inTicketIncluded ||
    luggageAllowance.additionalLuggagePolicy.bookable ||
    specialLuggageAndBuggies.specialLuggagePolicy.bookable ||
    specialLuggageAndBuggies.pushchairPolicy.bookable ||
    bikesSurfboardsAndSkies.bikePolicy.bookable ||
    bikesSurfboardsAndSkies.surfboardPolicy.bookable ||
    bikesSurfboardsAndSkies.skiEquipmentPolicy.bookable ||
    pets.petPolicy.bookable
  ) {
    return true;
  }

  return false;
};

export default (formValues: PoliciesFormValues["policies"]) => {
  if (formValues[0]?.policy && isPolicyActive(formValues[0].policy)) {
    return true;
  }

  if (formValues[1]?.policy && isPolicyActive(formValues[1].policy)) {
    return true;
  }

  return false;
};
