import { Icon, IconDelete } from "@flixbus/honeycomb-icons-react";
import { addNotification } from "@flixbus-phx/marketplace-common";
import { useField } from "formik2";
import * as React from "react";
import { useIntl } from "react-intl";
import { ForbiddenRelationsFormValues } from "../../types";
import DeleteForbiddenRelationsPopup from "../deleteForbiddenRelationsPopup/DeleteForbiddenRelationsPopup";
import * as css from "./DeleteForbiddenRelationsButton.scss";

type Props = {
  relation: ForbiddenRelationsFormValues;
  isUpdate: boolean;
};

const DeleteForbiddenRelationsButton: React.FC<Props> = ({ relation, isUpdate }) => {
  const [showDeletePopup, setShowDeletePopup] = React.useState(false);
  const { formatMessage } = useIntl();

  const [, meta, helper] =
    useField<Array<ForbiddenRelationsFormValues>>("forbiddenRelations");

  return (
    <div className={css.wrapper}>
      <Icon
        InlineIcon={IconDelete}
        extraClasses={css.deleteIcon}
        appearance="primary"
        size={4}
        onClick={() => {
          setShowDeletePopup(true);
        }}
      />

      {showDeletePopup && (
        <DeleteForbiddenRelationsPopup
          relation={relation}
          onConfirm={() => {
            const reducedRelations = meta.value.filter(
              (value) =>
                `${value.from.id}-${value.to.id}` !==
                `${relation.from.id}-${relation.to.id}`
            );

            if (isUpdate && meta.value.length > reducedRelations.length) {
              addNotification({
                message: formatMessage({
                  id: "lineForm.forbiddenRelations.notification.update",
                }),
              });
            }

            helper.setValue(reducedRelations);
            setShowDeletePopup(false);
          }}
          onCancel={() => setShowDeletePopup(false)}
          isUpdate={isUpdate}
        />
      )}
    </div>
  );
};

export default DeleteForbiddenRelationsButton;
