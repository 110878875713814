@import "variables";
@import "../../helpers/styles/vars.scss";

.containerCell {
  height: 100%;
}

.priceCell {
  line-height: $cell-height;
  text-align: center;
}

.readOnlyForbiddenRelationIcon {
  margin-bottom: 3px;
}

.priceInput {
  padding-top: $spacing-2;
  padding-right: $spacing-6;
  padding-left: $spacing-6;
}

.priceInputWithForbiddenRelation {
  display: flex;
  padding-top: $spacing-2;
  padding-right: $spacing-6;
  padding-left: $spacing-2;
}

.readOnlyForbiddenRelationPrice {
  display: flex;
  align-items: baseline;
  padding-right: 64px;
  padding-left: 36px;
}

.readOnlyPrice {
  display: flex;
  padding-right: 64px;
  padding-left: 64px;
}

.unserved {
  height: 60px;
  background: $grayscale-10-color;
  box-shadow: inset 0 0 30px 2px rgba(0, 0, 0, 0.15);
}
