@import "variables";

.box {
  display: inline-block;
}

.fileName {
  display: flex;
  column-gap: $spacing-half;
}

.icon {
  color: $icon-primary-color;
}
