import styles from '../../node_modules/@flixbus/honeycomb/dist/scss/helpers/_a11y.scss.js';
import HoneyBem from '../../packages/honey-bem/honey-bem.js';
import { appendStyles, CLIENT_ID_NAMESPACE, HCR_VERSION_SUFFIX } from '../../packages/injecss/injecss.js';

var srOnlyBem = HoneyBem.init('sr-only', styles);
var srOnlyFocusableBem = HoneyBem.init('sr-only-focusable', styles);
function a11yHelpers() {
  appendStyles("A11yHelpers__".concat(CLIENT_ID_NAMESPACE).concat(HCR_VERSION_SUFFIX), styles.raw);
  return {
    srOnly: srOnlyBem.b(),
    srOnlyFocusable: srOnlyFocusableBem.b()
  };
}

export { a11yHelpers as default };
