import { Icon, IconAttention } from "@flixbus/honeycomb-icons-react";
import { Tag, Tooltip } from "@flixbus/honeycomb-react";
import * as React from "react";
import { TripFragment } from "../../api/operations.generated";
import * as css from "./TimeToStationTag.scss";

type Props = {
  timeToStation: NonNullable<TripFragment["stationTimes"][0]["timeToStation"]>;
};

const TimeToStationTag: React.FC<Props> = ({ timeToStation }) => {
  return (
    <div className={css.wrapper}>
      <Tag extraClasses={css.tag} display="outlined">
        {timeToStation === "00:00" && (
          <>
            <Tooltip
              id="travel-time-hint"
              content="Please check if the travel time is valid"
              position="left"
              extraClasses={css.tooltip}
              openOnHover
              size="content-fit"
            >
              <Icon InlineIcon={IconAttention} extraClasses={css.warning} />
            </Tooltip>
            &nbsp;
          </>
        )}
        {timeToStation}
      </Tag>
    </div>
  );
};

export default TimeToStationTag;
