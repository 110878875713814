import { Checkbox } from "@flixbus/honeycomb-react";
import { Feature, hasUserPermission } from "@flixbus-phx/marketplace-common";
import { useField } from "formik2";
import * as React from "react";
import {
  BookingInformationField,
  PassengerInformationField,
  TripInformationField,
} from "../../../../shared/types/schema";
import {
  getLabelTextForBookingData,
  isDefaultValue,
} from "../../helpers/bookingDataSelectorHelper/bookingDataSelectorHelper";
import * as css from "./BookingDataSelectorColumn.scss";

type Props = {
  FieldEnum:
    | typeof BookingInformationField
    | typeof TripInformationField
    | typeof PassengerInformationField;
};

const BookingDataSelectorColumn: React.FC<Props> = ({ FieldEnum }) => {
  const name = `bookingInformationConfiguration.${
    FieldEnum === BookingInformationField
      ? "booking"
      : FieldEnum === TripInformationField
        ? "trip"
        : "passenger"
  }Information`;

  const [, meta, helper] = useField<Array<typeof FieldEnum>>(name);

  return (
    <>
      {Object.values(FieldEnum).map((field) => {
        const isChecked = meta.value.includes(field);

        // This field will be hidden until we have an API Partner that starts using PAX Booking Infos in MPO
        if (field === BookingInformationField.PartnerOrderId) {
          return <React.Fragment key={field}> </React.Fragment>;
        }

        // These fields will stay hidden until we implemented the logic for them in MPO
        if (
          !hasUserPermission(
            Feature.VIEW_ALL_PARTNER_PASSENGER_BOOKING_INFORMATION_FIELDS
          ) &&
          [
            BookingInformationField.PaidPrice,
            BookingInformationField.Invoice,
            BookingInformationField.BoardingPass,
            PassengerInformationField.CheckInStatus,
          ].includes(field)
        ) {
          return <React.Fragment key={field}> </React.Fragment>;
        }

        return (
          <Checkbox
            extraClasses={css.checkbox}
            label={getLabelTextForBookingData(field)}
            id={field}
            key={field}
            value={field}
            disabled={isDefaultValue(field)}
            checked={isChecked}
            onChange={() => {
              if (isChecked) {
                helper.setValue(meta.value.filter((value) => value !== field));
              } else {
                helper.setValue([...meta.value, field]);
              }
            }}
          />
        );
      })}
    </>
  );
};

export default BookingDataSelectorColumn;
