import { Infobox } from "@flixbus/honeycomb-react";
import { DefaultInfoAlert, legacyTranslate } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ConfirmationPopup from "../../../../shared/components/confirmationPopup/ConfirmationPopup";
import { Schedule } from "../../../../shared/types/schema";
import ImportPricingPopupBody from "../importPricingPopupBody/ImportPricingPopupBody";
import * as css from "./ImportPricingPopup.scss";

export type ImportPricingPopupProps = {
  scheduleId: Schedule["id"];
  scheduleHasPrices: boolean;
  onPricingImport: (
    destinationScheduleId: Schedule["id"],
    sourceScheduleId: Schedule["id"]
  ) => void;
  onCancel: () => void;
  hasError: boolean;
};

const ImportPricingPopup: React.FC<ImportPricingPopupProps> = ({
  scheduleId,
  scheduleHasPrices,
  onPricingImport,
  onCancel,
  hasError,
}) => {
  const { formatMessage } = useIntl();
  const [selectedSchedule, setSelectedSchedule] = React.useState<Schedule["id"]>();
  const [formValid, setFormValid] = React.useState<boolean>();

  return (
    <ConfirmationPopup
      active
      titleText={formatMessage({ id: "prices.import.popup.title" })}
      onConfirm={() => {
        if (selectedSchedule) {
          setFormValid(true);
          onPricingImport(scheduleId, selectedSchedule);
          return;
        }

        setFormValid(false);
      }}
      onCancel={onCancel}
    >
      <div className={css.popupBody}>
        {scheduleHasPrices && (
          <Infobox
            extraClasses={css.infoBox}
            appearance="warning"
            id="import-pricing-overwriting-warning"
          >
            <FormattedMessage id="prices.import.popup.info.valuesReplaced" />
          </Infobox>
        )}

        <ImportPricingPopupBody
          scheduleId={scheduleId}
          onScheduleSelected={(value) => setSelectedSchedule(value)}
        />

        {formValid === false && (
          <DefaultInfoAlert
            data-id="form-error"
            message={formatMessage({
              id: "prices.import.popup.info.scheduleSelectionNeeded",
            })}
            translate={legacyTranslate}
          />
        )}

        {hasError && (
          <DefaultInfoAlert
            data-id="import-has-error"
            message={formatMessage({ id: "general.somethingWentWrong" })}
            translate={legacyTranslate}
          />
        )}
      </div>
    </ConfirmationPopup>
  );
};

export default ImportPricingPopup;
