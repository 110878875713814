import cx from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";
import buildPath from "../../../../routes/buildPath/buildPath";
import { Schedule } from "../../../../shared/types/schema";
import ScheduleCellTooltip from "../scheduleCellTooltip/ScheduleCellTooltip";
import * as css from "./ScheduleCell.scss";

export type ScheduleCellProps = {
  rowStart: number;
  colStart: number;
  highlight: boolean;
  setHighlight: (highlight: boolean) => void;
  schedules: Array<Pick<Schedule, "id" | "name">>;
  isFirstOccurrence: boolean;
  cellWidth: number;
  isWeekend: boolean;
  isStart: boolean;
  isEnd: boolean;
  textBoxWidth: number;
  // Use this to resize this item when the accordion is opened to show schedules that are not on Sale.
  // Pass the amount of lines it has after opening. After closing you would need to pass 1 (as only 1 row exists)
  // onItemResize: (lines: number) => void;
};

const ScheduleCell: React.FC<ScheduleCellProps> = ({
  rowStart,
  colStart,
  highlight,
  setHighlight,
  schedules,
  isFirstOccurrence,
  cellWidth,
  isWeekend,
  isStart,
  isEnd,
  textBoxWidth,
}) => {
  const [showTooltip, setShowTooltip] = React.useState(false);

  const handleMouseEnter = () => {
    setHighlight(true);
  };

  const handleMouseLeave = () => {
    setHighlight(false);
  };

  const hasTooltip = schedules.length > 1;

  let zIndex = 0;
  if (isStart) zIndex++;
  if (showTooltip) zIndex++;

  return (
    <div
      className={cx(css.scrollableColumn, isWeekend && css.weekend)}
      style={{
        width: `${cellWidth}px`,
        transform: `translateX(${colStart}px) translateY(${rowStart}px)`,
        zIndex,
      }}
    >
      {schedules.length ? (
        <>
          <Link
            className={css.scheduleLink}
            to={buildPath.showScheduleDetail(schedules[0].id)}
          >
            <div
              className={cx(
                css.scheduleCell,
                isStart && css.start,
                isEnd && css.end,
                isFirstOccurrence && css.isFirst,
                highlight && css.highlight
              )}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            />
            {isStart && isFirstOccurrence && (
              <div
                className={cx(css.scheduleName, hasTooltip && css.hasTooltip)}
                style={{
                  width: `${textBoxWidth}px`,
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                {schedules[0].name}
              </div>
            )}
          </Link>
          {hasTooltip && (
            <ScheduleCellTooltip schedules={schedules} onToggle={setShowTooltip} />
          )}
        </>
      ) : null}
    </div>
  );
};

export default ScheduleCell;
