import { Textarea } from "@flixbus/honeycomb-react";
import cx from "classnames";
import { useField } from "formik2";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import isValid from "../../helpers/validateFormikInput/validateFormikInput";
import ReadOnlyFormField from "../readOnlyFormField/ReadOnlyFormField";
import * as css from "./FormikTextarea.scss";

type Props = {
  label: string;
  hideLabel?: boolean;
  name: string;
  placeholder?: string;
  readOnly?: boolean;
  infoText?: string;
  hasCounter?: boolean;
};

const FormikTextarea: React.FC<Props> = ({
  label,
  hideLabel,
  name,
  placeholder,
  readOnly,
  infoText,
  hasCounter,
}) => {
  const { formatMessage } = useIntl();
  const [field, meta] = useField<string>(name);

  if (readOnly) {
    return (
      <ReadOnlyFormField label={!hideLabel ? label : ""}>
        {meta.value || <FormattedMessage id="general.notAvailable" />}
      </ReadOnlyFormField>
    );
  }

  const MAX_LENGTH = 255;
  const maxCharactersExceeded = meta.value.length > MAX_LENGTH;
  const isValidInput = maxCharactersExceeded ? false : isValid(meta);

  return (
    <div className={css.wrapper}>
      <Textarea
        id={name}
        {...(hideLabel ? { "aria-label": label } : { label })}
        placeholder={placeholder}
        extraClasses={css.textarea}
        info={infoText}
        valid={isValidInput}
        infoError={
          maxCharactersExceeded
            ? formatMessage(
                { id: "error.validation.maxCharacters" },
                { numCharacters: 255 }
              )
            : meta.error
        }
        {...field}
      />
      {hasCounter && (
        <div data-id="counter" className={css.counterWrapper}>
          <div
            className={cx(isValidInput === false && css.error)}
          >{`${meta.value.length} / 255`}</div>
        </div>
      )}
    </div>
  );
};

export default FormikTextarea;
