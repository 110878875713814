import { Button, Heading } from "@flixbus/honeycomb-react";
import img from "@flixbus-phx/marketplace-common/assets/illustrations/magnifier.svg";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import * as css from "./DataTableNoResults.scss";

type Props = {
  onClearFilterSetting: () => void;
};

const DataTableNoResults: React.FC<Props> = ({ onClearFilterSetting }) => {
  return (
    <div className={css.container}>
      <img src={img} alt="" height="200px" />
      <Heading extraClasses={css.text} size={3}>
        <FormattedMessage id="search.noResults" />
      </Heading>

      <Button extraClasses={css.button} onClick={onClearFilterSetting}>
        <FormattedMessage id="filter.resetButton.text" />
      </Button>
    </div>
  );
};

export default DataTableNoResults;
