import { Grid, GridCol, Input, Skeleton } from "@flixbus/honeycomb-react";
import { useField } from "formik2";
import * as React from "react";
import { useIntl } from "react-intl";
import Autocomplete from "../../../../shared/components/autocomplete/Autocomplete";
import ReadOnlyFormField from "../../../../shared/components/readOnlyFormField/ReadOnlyFormField";
import SelectApiError from "../../../../shared/components/selectApiError/SelectApiError";
import { SelectOption } from "../../../../shared/helpers/reactSelect/types";
import isValid from "../../../../shared/helpers/validateFormikInput/validateFormikInput";
import { useFindAllLegalCompaniesLazyQuery } from "../../api/operations.generated";
import createLegalCompanyOptions from "../../helpers/createLegalCompanyOptions/createLegalCompanyOptions";
import NoConcessionOwnerIdInfo from "../../ui/noConcessionOwnerIdInfo/NoConcessionOwnerIdInfo";

type Props = {
  readOnlyName?: boolean;
  readOnlyConcessionOwnerId?: boolean;
  isConcessionOwnerIdShown?: boolean;
};

const NameAndConcessionOwnerIdInput: React.FC<Props> = ({
  readOnlyConcessionOwnerId,
  readOnlyName,
  isConcessionOwnerIdShown,
}) => {
  const { formatMessage } = useIntl();
  const [menuIsOpen, setMenuIsOpen] = React.useState(false);
  const [autocompleteOptions, setAutocompleteOptions] = React.useState<SelectOption[]>(
    []
  );

  const [fieldName, metaName, helperName] = useField<string>("name");
  const [, metaConcessionOwnerId, helperConcessionOwnerId] =
    useField<string>("concessionOwnerId");

  const selectedOption = metaConcessionOwnerId.value
    ? {
        value: metaConcessionOwnerId.value,
        label: `${metaName.initialValue} - ${metaConcessionOwnerId.value}`,
      }
    : undefined;

  const [
    queryLegalCompanies,
    { loading: isLegalCompanyQueryLoading, data: legalCompanies, error },
  ] = useFindAllLegalCompaniesLazyQuery({
    onCompleted: (data) => {
      if (data) {
        setAutocompleteOptions(
          createLegalCompanyOptions(
            data.findAllLegalCompanies,
            metaName.value,
            selectedOption
          )
        );
      }
    },
    // We want to fetch legal companies only from network to prevent someone from using the same legal company twice.
    // If we would get them from the cache, someone could create 2 partners in a row where both have the same legal company if he is not reloading his browser page in between.
    fetchPolicy: "network-only",
  });

  React.useEffect(() => {
    if (!readOnlyConcessionOwnerId) {
      queryLegalCompanies();
    }
  }, []);

  React.useEffect(() => {
    if (legalCompanies?.findAllLegalCompanies) {
      setAutocompleteOptions(
        createLegalCompanyOptions(
          legalCompanies.findAllLegalCompanies,
          metaName.value,
          selectedOption
        )
      );
    }
  }, [metaName.value]);

  const LABEL_CONCESSION_OWNER_ID = formatMessage({ id: "general.concessionOwnerId" });
  const LABEL_NAME = formatMessage({ id: "general.name" });

  if (isLegalCompanyQueryLoading) {
    return (
      <Grid>
        <GridCol size={6}>
          <Skeleton height="sm" width="sm" />
          <Skeleton height="lg" flushBottom />
        </GridCol>
        <GridCol size={6}>
          <Skeleton height="sm" width="sm" />
          <Skeleton height="lg" flushBottom />
        </GridCol>
      </Grid>
    );
  }

  return (
    <>
      <Grid>
        <GridCol size={6}>
          {readOnlyName ? (
            <ReadOnlyFormField label={LABEL_NAME}>{metaName.value}</ReadOnlyFormField>
          ) : (
            <Input
              id="name"
              label={`${LABEL_NAME} *`}
              type="text"
              placeholder={formatMessage({ id: "partner.name" })}
              required
              onFocus={() => setMenuIsOpen(true)}
              {...fieldName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (!metaName.initialValue) {
                  helperConcessionOwnerId.setValue("");
                }

                fieldName.onChange(e);
              }}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                setMenuIsOpen(false);
                fieldName.onBlur(e);
              }}
              valid={isValid(metaName)}
              infoError={metaName.error}
            />
          )}
        </GridCol>
        {isConcessionOwnerIdShown && (
          <GridCol size={6}>
            {readOnlyConcessionOwnerId ? (
              <ReadOnlyFormField label={LABEL_CONCESSION_OWNER_ID}>
                {metaConcessionOwnerId.value}
              </ReadOnlyFormField>
            ) : (
              <>
                {legalCompanies?.findAllLegalCompanies && (
                  <Autocomplete
                    label={LABEL_CONCESSION_OWNER_ID}
                    placeholder={LABEL_CONCESSION_OWNER_ID}
                    initialValue={
                      metaConcessionOwnerId.value
                        ? {
                            value: metaConcessionOwnerId.value,
                            label: `${metaName.value} - ${metaConcessionOwnerId.value}`,
                          }
                        : undefined
                    }
                    showValueOnly
                    options={autocompleteOptions}
                    onValueSelected={(selectedValue) => {
                      setMenuIsOpen(false);

                      if (selectedValue === null) {
                        const initialConcessionOwnerId =
                          metaConcessionOwnerId.initialValue;

                        if (initialConcessionOwnerId) {
                          const optionsWithThisConcessionOwnerId =
                            autocompleteOptions.filter(
                              (opt) => opt.value === initialConcessionOwnerId
                            );

                          if (optionsWithThisConcessionOwnerId.length === 0) {
                            setAutocompleteOptions([
                              ...autocompleteOptions,
                              {
                                value: initialConcessionOwnerId,
                                label: `${metaName.initialValue} - ${initialConcessionOwnerId}`,
                              },
                            ]);
                          }
                        }

                        helperConcessionOwnerId.setValue("");

                        return;
                      }

                      const partnerName = selectedValue.label.slice(
                        0,
                        -1 * (selectedValue.value.length + 3)
                      );

                      helperConcessionOwnerId.setValue(selectedValue.value);
                      helperName.setValue(partnerName);
                    }}
                    menuIsOpen={menuIsOpen}
                    onFocus={() => setMenuIsOpen(true)}
                    onBlur={() => setMenuIsOpen(false)}
                    isClearable
                    clearsOnChange={!metaName.initialValue}
                    noOptionsInfo={<NoConcessionOwnerIdInfo />}
                    startsOnInputValueLength={0}
                  />
                )}
                {error && (
                  <SelectApiError
                    label={formatMessage({ id: "general.concessionOwnerId" })}
                    info={formatMessage({ id: "error.errorOccured" })}
                  />
                )}
              </>
            )}
          </GridCol>
        )}
      </Grid>
    </>
  );
};

export default NameAndConcessionOwnerIdInput;
