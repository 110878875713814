import { useField } from "formik2";
import * as React from "react";
import { useIntl } from "react-intl";
import FormikCheckbox from "../../../../shared/components/formikCheckbox/FormikCheckbox";
import FormikInput from "../../../../shared/components/formikInput/FormikInput";
import { PartnerCustomerServiceAndPoliciesFormValues } from "../../types";
import * as css from "./CheckInWebsiteInput.scss";

type Props = {
  readOnly?: boolean;
};

const CheckInWebsiteInput: React.FC<Props> = ({ readOnly }) => {
  const { formatMessage } = useIntl();
  const [, meta] = useField<
    PartnerCustomerServiceAndPoliciesFormValues["checkInRequirements"]["checkInOptions"]["website"]
  >("checkInRequirements.checkInOptions.website");

  return (
    <>
      <FormikCheckbox
        name="checkInRequirements.checkInOptions.website.enabled"
        label={formatMessage({
          id: "partnerForm.checkInRequirements.checkInOptions.option.website",
        })}
        readOnly={readOnly}
      />

      {meta.value.enabled && (
        <div className={css.inputWrapper}>
          <FormikInput
            name="checkInRequirements.checkInOptions.website.url"
            label={formatMessage({
              id: "partnerForm.checkInRequirements.checkInOptions.website.inputHeading",
            })}
            placeholder="https://example.com"
            type="text"
            required
            readOnly={readOnly}
          />
        </div>
      )}
    </>
  );
};

export default CheckInWebsiteInput;
