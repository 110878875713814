import getSubmittableUnitValues from "../../../../shared/helpers/getSubmittableUnitValues/getSubmittableUnitValues";
import getSubmittableValues from "../../../../shared/helpers/getSubmittableValues/getSubmittableValues";
import {
  BookableLuggagePolicyInput,
  IncludedLuggagePolicyInput,
  TimeUnit,
  PassengerWithDisabilityPolicyInput,
  DisabilitySupportRegistrationType,
  UnaccompaniedMinorPolicyInput,
  PointOfSaleType,
} from "../../../../shared/types/schema";
import {
  BookableLuggageFormValues,
  IncludedLuggageFormValues,
  PassengerWithDisabilityFormValues,
  UnaccompaniedMinorFormValues,
} from "../../types";

export const getSubmittableValuesForIncludedLuggage = (
  values: IncludedLuggageFormValues
): IncludedLuggagePolicyInput | undefined => {
  if (values.inTicketIncluded === undefined) {
    return undefined;
  }

  if (values.inTicketIncluded === false) {
    return {
      inTicketIncluded: values.inTicketIncluded,
      luggageSpecification: undefined,
    };
  }

  return {
    inTicketIncluded: values.inTicketIncluded,
    luggageSpecification: {
      maxWeight: getSubmittableValues<number>(values.luggageSpecification.maxWeight),
      maxDimension: getSubmittableValues<string>(
        values.luggageSpecification.maxDimension
      ),
      maxPieces: values.luggageSpecification.maxPieces || undefined,
    },
  };
};

export const getSubmittableValuesForBookableLuggage = (
  values: BookableLuggageFormValues
): BookableLuggagePolicyInput | undefined => {
  if (values.bookable === undefined) {
    return undefined;
  }

  if (values.bookable === false) {
    return {
      luggageSpecification: undefined,
      bookable: values.bookable,
    };
  }

  return {
    luggageSpecification: {
      maxWeight: getSubmittableValues<number>(values.luggageSpecification.maxWeight),
      maxDimension: getSubmittableValues<string>(
        values.luggageSpecification.maxDimension
      ),
      feeType: values.luggageSpecification.feeType || undefined,
      feePrice: values.luggageSpecification.feePrice?.value
        ? {
            value: Number.parseFloat(
              values.luggageSpecification.feePrice.value.replace(",", ".")
            ),
          }
        : undefined,
      pointOfSale: values.luggageSpecification.pointOfSale || undefined,
      bookableUntil:
        values.luggageSpecification.pointOfSale === PointOfSaleType.CustomerService
          ? getSubmittableUnitValues<number, TimeUnit>(
              values.luggageSpecification.bookableUntil
            )
          : undefined,
    },
    bookable: values.bookable,
  };
};

export const getSubmittableValuesForUnaccompaniedMinor = (
  values: UnaccompaniedMinorFormValues
): UnaccompaniedMinorPolicyInput | undefined => {
  return values.allowed !== undefined
    ? {
        allowed: values.allowed,
        unaccompaniedMinorSpecification: values.allowed
          ? {
              ...values.unaccompaniedMinorSpecification,

              minAgeYears:
                values.unaccompaniedMinorSpecification.minAgeYears || undefined,
            }
          : undefined,
      }
    : undefined;
};

export const getSubmittableValuesForPassengerWithDisability = (
  values: PassengerWithDisabilityFormValues
): PassengerWithDisabilityPolicyInput | undefined => {
  if (values.supported === undefined) {
    return undefined;
  }

  if (values.supported === false) {
    return {
      supported: values.supported,
      passengerWithDisabilitySpecification: undefined,
    };
  }

  return {
    supported: values.supported,
    passengerWithDisabilitySpecification: {
      ...values.passengerWithDisabilitySpecification,
      registrationUntil:
        values.passengerWithDisabilitySpecification.registrationInstruction ===
        DisabilitySupportRegistrationType.ContactCustomerService
          ? getSubmittableUnitValues(
              values.passengerWithDisabilitySpecification.registrationUntil
            )
          : undefined,
      registrationInstruction:
        values.passengerWithDisabilitySpecification.registrationInstruction || undefined,
    },
  };
};
