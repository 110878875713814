import { Heading } from "@flixbus/honeycomb-react";
import {
  DefaultInfoAlert,
  Feature,
  hasUserPermission,
  legacyTranslate,
} from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Grid,
  GridBottom,
  GridTopLeft,
  GridTopRight,
} from "../../shared/components/grid/Grid";
import LoadingAnimation from "../../shared/components/loadingAnimation/LoadingAnimation";
import StatusFilter from "../../shared/components/statusFilter/StatusFilter";
import { LineStatus } from "../../shared/types/schema";
import { useLinesOfPartnersQuery } from "./api/operations.generated";
import LineAddButton from "./ui/lineAddButton/LineAddButton";
import NoLinesFoundMessage from "./ui/noLinesFoundMessage/NoLinesFoundMessage";
import PartnerLineListOverview from "./ui/partnerLineListOverview/PartnerLineListOverview";

type Props = {
  partnerId?: string;
};

const PartnerLineList: React.FC<Props> = ({ partnerId }) => {
  const [showInactiveLines, setShowInactiveLines] = React.useState(false);
  const { formatMessage } = useIntl();

  const { loading, error, data } = useLinesOfPartnersQuery({
    variables: partnerId ? { partnerId } : undefined,
    fetchPolicy: "cache-and-network",
  });

  if (loading) {
    return <LoadingAnimation />;
  }

  if (data && data.findPartner) {
    const allLines = data.findPartner.lines;
    const activeLines = allLines.filter((line) => line.status !== LineStatus.Inactive);
    const filteredLines = showInactiveLines ? allLines : activeLines;
    const hasInactiveLines = allLines.length !== activeLines.length;

    if (allLines.length === 0) {
      return <NoLinesFoundMessage partnerId={partnerId} />;
    }

    return (
      <Grid>
        <GridTopLeft>
          <Heading size={2} flushSpace>
            <FormattedMessage id="general.lines" />
          </Heading>
          <StatusFilter
            label={formatMessage({ id: "partnerDetail.lines.filter.label" })}
            checked={showInactiveLines}
            onChange={() => setShowInactiveLines((prevState) => !prevState)}
            disabled={!hasInactiveLines}
            tooltipText={formatMessage({
              id: "partnerDetail.lines.filter.disabled.tooltip",
            })}
          />
        </GridTopLeft>
        <GridTopRight>
          {hasUserPermission(Feature.CREATE_LINE) && (
            <LineAddButton partnerId={data.findPartner.id} />
          )}
        </GridTopRight>
        <GridBottom>
          {!showInactiveLines && filteredLines.length === 0 && (
            <NoLinesFoundMessage partnerId={partnerId} inactiveLinesExist />
          )}
          {(showInactiveLines || filteredLines.length !== 0) && (
            <PartnerLineListOverview lines={filteredLines} />
          )}
        </GridBottom>
      </Grid>
    );
  }

  if (error) {
    return <DefaultInfoAlert message={error.message} translate={legacyTranslate} />;
  }

  return <></>;
};
export default PartnerLineList;
