import { Fineprint } from "@flixbus/honeycomb-react";
import * as React from "react";
import {
  getOffsetWeekDaysSuffix,
  getPositiveSignum,
} from "../../helpers/offsetWeekdays/offsetWeekdays";
import * as css from "./OffsetWeekdaysLabel.scss";

export type OffsetWeekdaysLabelProps = {
  offsetWeekdays: number;
};

const OffsetWeekdaysLabel: React.FC<OffsetWeekdaysLabelProps> = ({ offsetWeekdays }) => {
  return (
    <Fineprint extraClasses={css.dayIndicator}>
      {`${getPositiveSignum(offsetWeekdays)}${offsetWeekdays} ${getOffsetWeekDaysSuffix(
        offsetWeekdays
      )}`}
    </Fineprint>
  );
};

export default OffsetWeekdaysLabel;
