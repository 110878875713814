import { TimeFormat } from "../../../../shared/components/timeInput/types";
import { convert12hTo24hFormat } from "../../../../shared/helpers/timeFormatter/timeConverter";
import getUserPreferredTimeFormat from "../../../../shared/helpers/userPreferredTimeFormat/userPreferredTimeFormat";
import { StationFormValues } from "../../types";

export default (transferInformation: StationFormValues["transferInformation"]) => {
  const timeFormat = getUserPreferredTimeFormat();

  const transferOpeningHours = transferInformation.isTransferStation
    ? transferInformation.transferOpeningHours.reduce((acc, val) => acc.concat(val), [])
    : undefined;

  if (transferOpeningHours && timeFormat === TimeFormat.TWELVE_HOUR_CLOCK) {
    return transferOpeningHours?.map((openingHour) => ({
      weekday: openingHour.weekday,
      start: convert12hTo24hFormat(openingHour.start),
      end: convert12hTo24hFormat(openingHour.end),
    }));
  }

  return transferOpeningHours;
};
