import { TimeFormat } from "../../../../shared/components/timeInput/types";
import { isValid12hFormat } from "../../../../shared/helpers/timeValidator/twelveHourClockFormatValidator";
import { isValid24hFormat } from "../../../../shared/helpers/timeValidator/twentyFourHourClockFormatValidator";

export default (time: string, timeFormat: TimeFormat, prevTime?: string | null) => {
  if (time === "") {
    return false;
  }

  if (time === prevTime) {
    return false;
  }

  if (timeFormat === TimeFormat.TWENTY_FOUR_HOUR_CLOCK && !isValid24hFormat(time)) {
    return false;
  }

  if (timeFormat === TimeFormat.TWELVE_HOUR_CLOCK && !isValid12hFormat(time)) {
    return false;
  }

  return true;
};
