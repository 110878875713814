@import "variables";

.header {
  min-width: $global-min-width;
  z-index: 1002;
  [class^="hci-icon"] {
    margin-right: 0;
  }
}

/* Remove padding for the lang switcher toggle if it is used the subnav */
[class^="hcr-header-nav-subnav__item"],
[class^="hcr-header-burger-menu-subnav__item"] {
  [class^="hcr-language-switcher__toggle"] {
    padding: 0 !important;
  }
}

.widgetMobileMenu {
  ul {
    li {
      margin-bottom: $spacing-2;

      &:last-child {
        margin-bottom: 0;
      }

      button {
        text-align: left;
      }
    }
  }
}
