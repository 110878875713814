import { SelectOption } from "../../../../helpers/reactSelect/types";

const getSelectedOptionsByValue = (
  availableOptions: Array<SelectOption>,
  values: Array<SelectOption["value"]> | undefined
) => {
  if (!values) {
    return undefined;
  }

  const selectedOptions: Array<SelectOption> = [];

  values.forEach((value) => {
    const opt = availableOptions.find((option) => option.value === value);

    if (opt && !selectedOptions.includes(opt)) {
      selectedOptions.push(opt);
    }
  });

  return selectedOptions.length > 0 ? selectedOptions : undefined;
};

export default getSelectedOptionsByValue;
