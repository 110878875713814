import { addNotification, NotificationType } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { useIntl } from "react-intl";
import CityForm from "../cityForm/CityForm";
import { FindAllCitiesDocument } from "../formikCitySelect/api/operations.generated";
import {
  NewCityMutation,
  NewCityMutationVariables,
  useNewCityMutation,
} from "./api/operations.generated";

export type CreateCityProps = {
  onCityCreated: (param: NewCityMutation["newCity"]) => void;
  onCancel: () => void;
  inputValue?: string;
  isNested?: boolean;
};

const CreateCity: React.FC<CreateCityProps> = ({
  onCityCreated,
  onCancel,
  inputValue,
  isNested,
}) => {
  const { formatMessage } = useIntl();

  const [createCity, { loading }] = useNewCityMutation({
    onCompleted: ({ newCity }) => {
      addNotification({
        message: formatMessage({ id: "city.create.notification.success" }),
        type: NotificationType.Success,
      });
      onCityCreated(newCity);
    },
    onError: () => {
      addNotification({
        message: `${formatMessage({
          id: "city.create.notification.failure",
        })} ${formatMessage({ id: "general.tryAgain" })}`,
        type: NotificationType.Danger,
      });
    },
    refetchQueries: [{ query: FindAllCitiesDocument }],
    awaitRefetchQueries: true,
  });

  return (
    <CityForm
      onCancel={onCancel}
      onSubmit={(values: NewCityMutationVariables) => {
        createCity({ variables: values });
      }}
      submitLoading={loading}
      cityName={inputValue}
      isNested={isNested}
    />
  );
};

export default CreateCity;
