import * as React from "react";
import { TimeFormat } from "../../../../shared/components/timeInput/types";
import { TripFragment } from "../../api/operations.generated";
import {
  HandleTripStationTimeDelete,
  HandleUpdateTripStationArrivalOffsetDays,
  HandleUpdateTripStationArrivalTime,
  HandleUpdateTripStationDepartureTime,
} from "../../types";
import StationTimeCell from "../../ui/stationTimeCell/StationTimeCell";

const generateStationTimeCellsArray = (
  trip: TripFragment,
  handleStationDepartureTimeChanged: HandleUpdateTripStationDepartureTime,
  handleStationArrivalTimeChanged: HandleUpdateTripStationArrivalTime,
  handleStationTimeDelete: HandleTripStationTimeDelete,
  handleOnStationArrivalOffsetDaysChanged: HandleUpdateTripStationArrivalOffsetDays,
  timeFormat: TimeFormat,
  readOnly: boolean
): Array<JSX.Element> => {
  const getPrevStationTime = (
    index: number,
    stationTimes: TripFragment["stationTimes"]
  ) => {
    for (let i = index - 1; i >= 0; i--) {
      if (stationTimes[i].departureTime) return stationTimes[i];
    }

    return undefined;
  };

  const showArrivalWeekdaysOffset = (
    stationTime: TripFragment["stationTimes"][number],
    prevStationTime?: TripFragment["stationTimes"][number]
  ) => {
    if (!stationTime.arrivalTime) return false;

    if (!prevStationTime) return false;

    return stationTime.arrivalOffsetWeekdays !== prevStationTime.departureOffsetWeekdays;
  };

  return trip.stationTimes.map((stationTime, index, arr) => (
    <StationTimeCell
      key={`${trip.id}-${stationTime.station.id}`}
      stationTime={stationTime}
      onStationArrivalTimeChanged={handleStationArrivalTimeChanged}
      onStationDepartureTimeChanged={handleStationDepartureTimeChanged}
      onStationTimeDelete={handleStationTimeDelete}
      onStationArrivalOffsetDaysChanged={handleOnStationArrivalOffsetDaysChanged}
      disabled={readOnly}
      showUtcOffset={
        index === 0 ||
        (index > 0 &&
          stationTime.station.timeZone.utcOffset !==
            arr[index - 1].station.timeZone.utcOffset)
      }
      timeFormat={timeFormat}
      isCountryChange={
        index > 0 &&
        stationTime.station.countryCode !== arr[index - 1].station.countryCode
      }
      isFirst={index === 0}
      isLast={index === trip.stationTimes.length - 1}
      showArrivalWeekdaysOffset={showArrivalWeekdaysOffset(
        stationTime,
        getPrevStationTime(index, arr)
      )}
      showDepartureWeekdaysOffset={
        stationTime.departureOffsetWeekdays > stationTime.arrivalOffsetWeekdays
      }
    />
  ));
};

export default generateStationTimeCellsArray;
