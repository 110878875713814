import { addDays, areIntervalsOverlapping, max, min, subDays } from "date-fns";
import { Interval } from "../../types";

export const mergeIntervalsSingleIteration = (intervals: Array<Interval>) => {
  return intervals.reduce((total: Array<Interval>, currentInterval) => {
    if (!total.length) {
      return [{ ...currentInterval }];
    }

    let newTotal: Array<Interval> = [...total];
    let wasMerged: boolean | undefined;

    total.forEach((t, i) => {
      if (
        areIntervalsOverlapping(
          t,
          {
            start: subDays(currentInterval.start, 1),
            end: addDays(currentInterval.end, 1),
          },
          { inclusive: true }
        )
      ) {
        const newInterval = {
          start: min([t.start, currentInterval.start]),
          end: max([t.end, currentInterval.end]),
        };

        const totalMerged = [...newTotal];
        totalMerged.splice(i, 1, newInterval);
        newTotal = totalMerged;
        wasMerged = true;
      }
    });

    if (wasMerged) {
      return newTotal;
    }

    return [...newTotal, { ...currentInterval }];
  }, []);
};

export const mergeIntervals = (intervals: Array<Interval>) => {
  let wasMerged: boolean | undefined;
  let mergedIntervals: Array<Interval> = [...intervals];

  do {
    const intervalsAfterIteration = mergeIntervalsSingleIteration(mergedIntervals);
    if (mergedIntervals.length > intervalsAfterIteration.length) {
      wasMerged = true;
      mergedIntervals = intervalsAfterIteration;
    } else {
      wasMerged = false;
    }
  } while (wasMerged);

  return mergedIntervals;
};
