import { useField } from "formik2";
import * as React from "react";
import { StationStatus } from "../../types/schema";
import StationSelect from "../stationSelect/StationSelect";

type Props = {
  label: string;
  name: string;
  createStationPossible?: boolean;
  disallowedValues?: Array<string>;
  disallowedStates?: Array<StationStatus>;
};

const FormikStationSelect: React.FC<Props> = ({
  label,
  name,
  createStationPossible,
  disallowedValues,
  disallowedStates,
}) => {
  const [, meta, helper] = useField(name);

  return (
    <StationSelect
      label={label}
      createStationPossible={createStationPossible}
      disallowedValues={disallowedValues}
      disallowedStates={disallowedStates}
      onStationSelected={(id) => helper.setValue(id)}
      error={!!(meta.error && meta.touched)}
      errorMessage={meta.error}
    />
  );
};

export default FormikStationSelect;
