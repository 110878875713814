import ReactUtils from '../../packages/react-utils/react-utils.js';
import 'react';

var headerChildrenValidator = function () {
  return function (_a) {
    var children = _a.children;
    if (children == null) {
      return new Error('\'children\' is required for \'Header\'.');
    }
    // Looks for unsupported children
    var supportedChildrenNames = ['HeaderBrand', 'HeaderBurgerMenu', 'HeaderNavigation', 'HeaderSkipLinks', 'HeaderWidgets'];
    var unsupportedChild = ReactUtils.mapRecursively(children, function (child) {
      return child;
    }).find(function (child) {
      var childName = child && ReactUtils.getElementName(child);
      if (child && !supportedChildrenNames.includes(ReactUtils.getUninjectedCompName(childName))) {
        return child;
      }
      return false;
    });
    if (unsupportedChild) {
      return new Error("'Header' only supports children of types ".concat(supportedChildrenNames.join(' | '), " but received ").concat(ReactUtils.getElementName(unsupportedChild), "."));
    }
    // Checks if HeaderBrand is present
    var headerBrand = ReactUtils.mapRecursively(children, function (child) {
      return child;
    }).filter(function (child) {
      var childName = child && ReactUtils.getElementName(child);
      return ReactUtils.getUninjectedCompName(childName) === 'HeaderBrand';
    });
    if (headerBrand.length === 0) {
      return new Error('\'HeaderBrand\' is required as a \'Header\' children.');
    }
    return null;
  };
};

export { headerChildrenValidator };
