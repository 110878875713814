import { compareAsc, endOfMonth, interval, startOfMonth } from "date-fns";
import {
  Calendar,
  CalendarIntervalType,
  CalendarSchedule,
  InputSchedule,
  OnSaleChunk,
} from "../types";
import growInterval from "./buildCalendar";

const buildDailyCalendar = (
  inputSchedules: Array<InputSchedule>
): Calendar<CalendarSchedule> => {
  const now = new Date();
  let boundary = interval(now, now);
  const rows = inputSchedules
    .map((inputSchedule) => {
      const chunks: Array<OnSaleChunk<CalendarSchedule>> = [];
      const processedSchedules = new Set<CalendarSchedule>();

      [inputSchedule, ...inputSchedule.conceptChanges].forEach((schedule) => {
        schedule.onSalePeriods.forEach((period) => {
          const isFirstOccurrence = !processedSchedules.has(schedule);
          if (isFirstOccurrence) processedSchedules.add(schedule);

          const normalizedInterval = interval(period.start, period.end);

          boundary = growInterval(boundary, normalizedInterval);

          chunks.push({
            uiPeriod: normalizedInterval,
            isFirstOccurrence,
            schedules: [schedule],
          });
        });
      });

      chunks.sort((a, b) => compareAsc(a.uiPeriod.start, b.uiPeriod.start));

      return {
        onSale: chunks,
      };
    })
    .filter((row) => row.onSale.length > 0);

  return {
    intervalType: CalendarIntervalType.DAILY,
    boundary: interval(startOfMonth(boundary.start), endOfMonth(boundary.end)),
    rows,
  };
};

export default buildDailyCalendar;
