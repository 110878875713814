import * as React from "react";
import * as css from "./Grid.scss";

export const Grid: React.FC = ({ children }) => {
  return <div className={css.grid}>{children}</div>;
};

export const GridTopLeft: React.FC = ({ children }) => {
  return <div className={css.topLeft}>{children}</div>;
};

export const GridTopRight: React.FC = ({ children }) => {
  return <div className={css.topRight}>{children}</div>;
};

export const GridBottom: React.FC = ({ children }) => {
  return <div className={css.bottom}>{children}</div>;
};
