import { Accordion, List, Tag } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { NearbyStations } from "../../../../shared/hooks/useNearbyStations/useNearbyStations";
import * as css from "./NearbyStationList.scss";

type Props = {
  stations: NearbyStations;
};

const NearbyStationList: React.FC<Props> = ({ stations }) => {
  const { formatMessage } = useIntl();
  return (
    <div className={css.wrapper}>
      {stations.map((station) => {
        return (
          <Accordion
            title={
              <>
                <Tag small display="outlined" extraClasses={css.tag}>
                  {station.code}
                </Tag>
                {station.name}
              </>
            }
            key={station.id}
          >
            <List>
              <li>
                <FormattedMessage
                  id="nearbyStations.stationDetail.timezone"
                  values={{
                    zoneId: station.timeZone.zoneId,
                    utcOffset: station.timeZone.utcOffset,
                  }}
                />
              </li>
              <li>
                <FormattedMessage
                  id="nearbyStations.stationDetail.city"
                  values={{
                    cityName: station.city?.name,
                  }}
                />
              </li>
              <li>
                <FormattedMessage
                  id="nearbyStations.stationDetail.country"
                  values={{
                    country: formatMessage({ id: `country.${station.countryCode}` }),
                  }}
                />
              </li>
              <li>
                <FormattedMessage
                  id="nearbyStations.stationDetail.latitude"
                  values={{
                    lat: station.latitude,
                  }}
                />
              </li>
              <li>
                <FormattedMessage
                  id="nearbyStations.stationDetail.longitude"
                  values={{
                    lon: station.longitude,
                  }}
                />
              </li>
            </List>
          </Accordion>
        );
      })}
    </div>
  );
};

export default NearbyStationList;
