import { Icon, IconInfo } from "@flixbus/honeycomb-icons-react";
import { Grid, GridCol, Checkbox, Fineprint } from "@flixbus/honeycomb-react";
import { useField } from "formik2";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import FormikTimeUnitInput from "../../../../shared/components/formikTimeUnitInput/FormikTimeUnitInput";
import Select from "../../../../shared/components/select/Select";
import { CutOffSalesRule, TimeUnit } from "../../../../shared/types/schema";
import { CutOffSalesDuration } from "../../types";
import ReadOnlyCutOffSalesSelector from "../readOnlyCutOffSalesSelector/ReadOnlyCutOffSalesSelector";
import * as css from "./CutOffSalesSelector.scss";
import {
  getCutOffSalesRule,
  cutOffSalesRuleOptions,
  cutOffSalesTimeOptions,
} from "./helpers/cutOffSalesSelectorHelpers/cutOffSalesSelectorHelpers";

export type CutOffSalesSelectorProps = {
  readOnly?: boolean;
};

const CutOffSalesSelector: React.FC<CutOffSalesSelectorProps> = ({
  readOnly = false,
}) => {
  const { formatMessage } = useIntl();

  const [, metaRule, helperRule] = useField<CutOffSalesRule>(
    "cutOffSales.cutOffSalesRule"
  );
  const [, metaTime, helperTime] = useField<CutOffSalesDuration["value"]>(
    "cutOffSales.cutOffSalesDuration.value"
  );
  const [, metaUnit, helperUnit] = useField<CutOffSalesDuration["unit"]>(
    "cutOffSales.cutOffSalesDuration.unit"
  );

  return (
    <>
      {!readOnly ? (
        <div>
          <Checkbox
            id="cut-off-sales"
            value="cut-off-sales"
            label={formatMessage({ id: "lineForm.cutOffSalesRule.checkbox.label" })}
            defaultChecked={metaRule.value !== CutOffSalesRule.None}
            onClick={() => {
              if (metaRule.value === CutOffSalesRule.None) {
                helperRule.setValue(CutOffSalesRule.BeforeDeparture);
                helperTime.setValue("" as unknown as number);
                helperUnit.setValue(TimeUnit.Minutes);
              } else {
                helperRule.setValue(CutOffSalesRule.None);
                helperTime.setValue(0);
                helperUnit.setValue(TimeUnit.Minutes);
              }
            }}
          />
          <div className={css.fineprint}>
            <Fineprint>
              <Icon InlineIcon={IconInfo} />
              <FormattedMessage id="lineForm.cutOffSalesRule.info" />
            </Fineprint>
          </div>
          {metaRule.value !== CutOffSalesRule.None && (
            <div className={css.options}>
              <Grid>
                <GridCol size={6} push={1}>
                  <Select
                    label={formatMessage({ id: "lineForm.cutOffSalesRule.select.label" })}
                    options={cutOffSalesRuleOptions()}
                    initialValue={cutOffSalesRuleOptions().find(
                      (rule) => rule.value === metaRule.value
                    )}
                    onSelect={(selectedOption) =>
                      helperRule.setValue(getCutOffSalesRule(selectedOption.value))
                    }
                  />
                </GridCol>
                <GridCol size={5}>
                  {metaRule.value === CutOffSalesRule.BeforeDeparture && (
                    <FormikTimeUnitInput
                      name="cutOffSales.cutOffSalesDuration"
                      label={formatMessage({
                        id: "lineForm.cutOffSalesRule.beforeDayOfDeparture.input.label",
                      })}
                      defaultUnit={TimeUnit.Minutes}
                      optionalUnit={TimeUnit.Hours}
                      required
                    />
                  )}
                  {metaRule.value === CutOffSalesRule.BeforeDayOfDeparture && (
                    <Select
                      label={`${formatMessage({
                        id: "lineForm.cutOffSalesRule.beforeDayOfDeparture.input.label",
                      })} *`}
                      options={cutOffSalesTimeOptions}
                      initialValue={cutOffSalesTimeOptions.find((option) => {
                        if (metaUnit.value === TimeUnit.Minutes) {
                          return option.value === (metaTime.value / 60).toString();
                        }
                        return option.value === metaTime.value?.toString();
                      })}
                      onSelect={(selectedValue) => {
                        helperTime.setValue(parseInt(selectedValue.value, 10));
                        helperUnit.setValue(TimeUnit.Hours);
                      }}
                      onBlur={() => helperTime.setTouched(true)}
                      errorText={
                        metaTime.error && metaTime.touched ? metaTime.error : undefined
                      }
                    />
                  )}
                </GridCol>
              </Grid>
            </div>
          )}
        </div>
      ) : (
        <ReadOnlyCutOffSalesSelector />
      )}
    </>
  );
};

export default CutOffSalesSelector;
