import { Icon, IconPlus } from "@flixbus/honeycomb-icons-react";
import { Button } from "@flixbus/honeycomb-react";
import { useField } from "formik2";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { BusinessDeveloperInput } from "../../../../shared/types/schema";
import BDInputRow from "../bdInputRow/BDInputRow";
import * as css from "./BDInput.scss";

type Props = {
  readOnly?: boolean;
};

const BDInput: React.FC<Props> = ({ readOnly = false }) => {
  const [, meta, helpers] = useField<Array<BusinessDeveloperInput>>("businessDevelopers");

  const hasTwoRows = meta.value.length === 2;

  return (
    <>
      <ol className={css.list}>
        <BDInputRow
          hasLabel
          index={0}
          onRemoveRow={() => helpers.setValue([meta.value[1]])}
          showRemoveRow={hasTwoRows}
          readOnly={readOnly}
        />

        {hasTwoRows && (
          <BDInputRow
            hasLabel={false}
            index={1}
            onRemoveRow={() => helpers.setValue([meta.value[0]])}
            showRemoveRow={hasTwoRows}
            readOnly={readOnly}
          />
        )}
      </ol>
      {!readOnly && (
        <Button
          extraClasses={css.button}
          disabled={hasTwoRows}
          onClick={() => helpers.setValue([...meta.value, { name: "", email: "" }])}
        >
          <Icon InlineIcon={IconPlus} />
          <FormattedMessage id="partnerForm.bdInput.button" />
        </Button>
      )}
    </>
  );
};

export default BDInput;
