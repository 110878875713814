@import "variables";

.infoIcon {
  color: $button-primary-color;
  margin-right: $spacing-2;
}

.infoText {
  margin-right: $spacing-4;
}
