import * as React from "react";
import { useIntl } from "react-intl";
import LineScheduleList from "../../../../schedule/lineScheduleList/LineScheduleList";
import { LinesOfPartnersQuery } from "../../api/operations.generated";
import Accordion from "../accordion/Accordion";
import LineListItem from "../lineListItem/LineListItem";
import * as css from "./LineListAccordion.scss";

type Props = {
  line: LinesOfPartnersQuery["findPartner"]["lines"][number];
};

const LineListAccordion: React.FC<Props> = ({ line }) => {
  const { formatMessage } = useIntl();
  return (
    <div className={css.lineAccordion} data-id="line-list-accordion">
      <Accordion
        headerSection={<LineListItem line={line} />}
        openAccordionText={formatMessage({
          id: "partnerDetail.lines.accordion.showSchedules",
        })}
        closeAccordionText={formatMessage({
          id: "partnerDetail.lines.accordion.hideSchedules",
        })}
      >
        <LineScheduleList lineId={line.id} showHeaderContent={false} />
      </Accordion>
    </div>
  );
};

export default LineListAccordion;
