import { useReactiveVar } from "@apollo/client";
import { DataTable, DataTableRow, Heading } from "@flixbus/honeycomb-react";
import {
  Feature,
  hasUserPermission,
  useEffectAfterMount,
} from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import DataTableError from "../../../../shared/components/dataTableError/DataTableError";
import DataTableLoading from "../../../../shared/components/dataTableLoading/DataTableLoading";
import DataTableNoResults from "../../../../shared/components/dataTableNoResult/DataTableNoResults";
import Pager from "../../../../shared/components/pager/Pager";
import { IsoCountry, PageSize } from "../../../../shared/types/schema";
import citiesFilterVar from "../../../../state/citiesFilter/citiesFilter";
import { useFilterCitiesQuery } from "../../api/operations.generated";
import { clearCitiesFilter } from "../../helper/clearFilter/clearFilter";
import * as css from "../../helper/styles/styles.scss";
import CreateEntityButton from "../../ui/createEntityButton/CreateEntityButton";
import { citiesTableHeaders } from "../../ui/tableHeader/TableHeader";
import TooltipFlag from "../../ui/tooltipFlag/TooltipFlag";
import CitiesFilter from "../citiesFilter/CitiesFilter";

const CitiesTable: React.FC = () => {
  const { formatMessage } = useIntl();
  const filter = useReactiveVar(citiesFilterVar);

  const [pageSize, setPageSize] = React.useState(PageSize.Twentyfive);

  const { data, loading, error, fetchMore } = useFilterCitiesQuery({
    variables: {
      pageSize,
      page: 0,
      ...filter,
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffectAfterMount(() => {
    fetchMore({ variables: {} });
  }, [filter.name, filter.country, filter.importanceClass, filter.published]);

  return (
    <>
      <div className={css.tableHeaderWrapper}>
        <Heading size={1}>
          <FormattedMessage id="general.cities" />
        </Heading>
        <CreateEntityButton
          type="city"
          onSubmit={() => fetchMore({ variables: {} })}
          isImportCreationPermitted={hasUserPermission(Feature.CREATE_CITY_IMPORT)}
          isManualCreationPermitted={hasUserPermission(Feature.CREATE_CITY)}
        />
      </div>

      <CitiesFilter />

      {error && <DataTableError />}

      {loading && (
        <DataTableLoading
          header={citiesTableHeaders}
          colCount={4}
          extraClasses={css.citiesTable}
        />
      )}

      {data?.filterCities && !loading && (
        <>
          {data.filterCities.items.length ? (
            <DataTable extraClasses={css.citiesTable} headers={citiesTableHeaders}>
              {data.filterCities.items.map((city) => (
                <DataTableRow key={city.id}>
                  <td>{city.name}</td>
                  <td>
                    <TooltipFlag
                      id={city.id}
                      countryCode={city.countryCode as IsoCountry}
                    />
                  </td>
                  <td>{city.importanceClass}</td>
                  <td>
                    {city.isPublished
                      ? formatMessage({ id: "general.yes" })
                      : formatMessage({ id: "general.no" })}
                  </td>
                </DataTableRow>
              ))}
            </DataTable>
          ) : (
            <DataTableNoResults onClearFilterSetting={clearCitiesFilter} />
          )}
        </>
      )}

      {data && data.filterCities.pageInfo.totalPages > 0 && (
        <Pager
          totalPages={data?.filterCities.pageInfo.totalPages}
          currentPage={data?.filterCities.pageInfo.currentPage}
          hasPrevious={data?.filterCities.pageInfo.hasPrevious}
          hasNext={data?.filterCities.pageInfo.hasNext}
          setPage={(newPage) => {
            fetchMore({ variables: { page: newPage } });
          }}
          total={data?.filterCities.total}
          pageSize={pageSize}
          setPageSize={(size) => {
            setPageSize(size);
            fetchMore({ variables: { pageSize: size } });
          }}
        />
      )}
    </>
  );
};

export default CitiesTable;
