.popup {
  z-index: 3000;
}

.popup [class^="hcr-popup__body"] {
  max-height: calc(100vh - 180px);
}

.isWide [class^="hcr-popup__body"] {
  width: 750px;
}

.allowOverflow [class^="hcr-popup__body"] {
  overflow-y: unset;
}

.contentLeft [class^="hcr-popup__content"] {
  text-align: left;
}
