import { Box } from "@flixbus/honeycomb-react";
import { Feature, hasUserPermission } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ScheduleStatus } from "../../../../shared/types/schema";
import { pricesInEditModeVar } from "../../../../state/reactiveVariables/reactiveVariables";
import usePricesInEditMode from "../../../../state/usePricesInEditMode/usePricesInEditMode";
import {
  FindScheduleForFooterQuery,
  ValidateScheduleQuery,
} from "../../api/operations.generated";
import InfoHint from "../infoHint/InfoHint";
import ScheduleFooterButton from "../scheduleFooterButton/ScheduleFooterButton";
import ScheduleValidationPopup from "../scheduleValidationPopup/ScheduleValidationPopup";
import * as css from "./ScheduleFooterBar.scss";

export type ScheduleFooterBarProps = {
  onSubmitForReview: () => void;
  onApprove: () => void;
  onReject: () => void;
  onSchedulePublish: () => void;
  onPricePublish: () => void;
  buttonLoading: boolean;
  schedule: FindScheduleForFooterQuery["findSchedule"];
  arePricesShown: boolean;
  onSwitchToPrices: () => void;
  validationData?: ValidateScheduleQuery["validateSchedule"];
};

const ScheduleFooterBar: React.FC<ScheduleFooterBarProps> = ({
  onSubmitForReview,
  onApprove,
  onReject,
  onSchedulePublish,
  onPricePublish,
  buttonLoading,
  schedule,
  arePricesShown,
  onSwitchToPrices,
  validationData,
}) => {
  const { formatMessage } = useIntl();
  const [validationPopupActive, setValidationPopupActive] = React.useState(false);

  const [pricesInEditMode] = usePricesInEditMode(pricesInEditModeVar);

  return (
    <div data-id="box-parent" className={css.boxWrapper}>
      <Box extraClasses={css.box}>
        {hasUserPermission(Feature.EDIT_SCHEDULE_SUBMIT_TO_REVIEW) && (
          <>
            {!arePricesShown && schedule.status === ScheduleStatus.WorkInProgress && (
              <ScheduleFooterButton
                data-id="switch-to-prices-button"
                onClick={onSwitchToPrices}
                appearance="primary"
              >
                <FormattedMessage id="schedule.footer.enterPrices.button.label" />
              </ScheduleFooterButton>
            )}
            {arePricesShown && schedule.status === ScheduleStatus.WorkInProgress && (
              <ScheduleFooterButton
                data-id="submit-for-review-button"
                onClick={() => {
                  onSubmitForReview();
                  setValidationPopupActive(true);
                }}
                appearance="primary"
                loading={buttonLoading}
                disabled={schedule.readOnly}
              >
                <FormattedMessage id="schedule.footer.submitForReview.button.label" />
              </ScheduleFooterButton>
            )}
          </>
        )}
        {hasUserPermission(Feature.EDIT_SCHEDULE_APPROVE_REJECT) &&
          schedule.status === ScheduleStatus.InReview && (
            <>
              <ScheduleFooterButton
                data-id="reject-button"
                onClick={onReject}
                appearance="tertiary"
                loading={buttonLoading}
                disabled={schedule.readOnly}
              >
                <FormattedMessage id="schedule.footer.reject.button.label" />
              </ScheduleFooterButton>
              <ScheduleFooterButton
                data-id="approve-button"
                onClick={() => {
                  onApprove();
                  setValidationPopupActive(true);
                }}
                appearance="primary"
                loading={buttonLoading}
                disabled={schedule.readOnly}
              >
                <FormattedMessage id="schedule.footer.approve.button.label" />
              </ScheduleFooterButton>
            </>
          )}
        {hasUserPermission(Feature.EDIT_SCHEDULE_PUBLISH) &&
          schedule.lastPublishFailed && (
            <>
              <InfoHint
                text={formatMessage({
                  id: "schedule.footer.publish.notification.failure",
                })}
              />
              <ScheduleFooterButton
                data-id="publish-button"
                onClick={onSchedulePublish}
                appearance="primary"
              >
                <FormattedMessage id="schedule.footer.republish.button.label" />
              </ScheduleFooterButton>
            </>
          )}
        {(schedule.status === ScheduleStatus.InGeneration ||
          (schedule.status === ScheduleStatus.Approved &&
            !schedule.lastPublishFailed)) && (
          <InfoHint
            text={formatMessage({ id: "schedule.footer.publish.info.inProgress" })}
          />
        )}
        {[ScheduleStatus.PricesPublished, ScheduleStatus.OnSale].includes(
          schedule.status
        ) &&
          hasUserPermission(Feature.EDIT_PRICING) && (
            <ScheduleFooterButton
              data-id="price-publish-button"
              onClick={() => {
                onPricePublish();
              }}
              appearance="primary"
              disabled={schedule.wasCreatedViaApi || pricesInEditMode}
              title={
                pricesInEditMode
                  ? formatMessage({ id: "prices.footer.publish.button.title" })
                  : undefined
              }
            >
              <FormattedMessage id="prices.footer.update.button.label" />
            </ScheduleFooterButton>
          )}
        {validationData && (
          <ScheduleValidationPopup
            active={!buttonLoading && !validationData?.isValid && validationPopupActive}
            onClose={() => setValidationPopupActive(false)}
            validationInfos={validationData.validationInfos}
          />
        )}
      </Box>
    </div>
  );
};

export default ScheduleFooterBar;
