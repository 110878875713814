import { SelectGroup } from "@flixbus/honeycomb-react";
import { useField } from "formik2";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import * as css from "./FormikYesNoSelect.scss";

type Props = {
  name: string;
  label: string;
  readOnly?: boolean;
  isCategoryTitle?: boolean;
};

const FormikYesNoSelect: React.FC<Props> = ({
  name,
  label,
  readOnly,
  isCategoryTitle,
}) => {
  const [, meta, helper] = useField(name);
  const { formatMessage } = useIntl();

  const getReadOnlyValue = () => {
    if (meta.value === true) return <FormattedMessage id="general.yes" />;
    if (meta.value === false) return <FormattedMessage id="general.no" />;
    return <FormattedMessage id="general.notAvailable" />;
  };

  const handleClick = (e: React.MouseEvent) => {
    if ((e.target as HTMLInputElement).checked) {
      helper.setValue(undefined);
    }
    helper.setTouched(true);
  };

  const handleChange = (value: boolean) => {
    helper.setValue(value);
    // https://github.com/formium/formik/issues/2083#issuecomment-571259235
    helper.setTouched(true, false);
  };

  const options = [
    {
      id: `${name}-yes`,
      value: `${name}-yes`,
      name: `${name}-yesNoSelect`,
      label: formatMessage({ id: "general.yes" }),
      onBlur: () => helper.setTouched(true),
      onClick: handleClick,
      onChange: () => handleChange(true),
      checked: meta.value === true,
    },
    {
      id: `${name}-no`,
      value: `${name}-no`,
      name: `${name}-yesNoSelect`,
      label: formatMessage({ id: "general.no" }),
      onBlur: () => helper.setTouched(true),
      onClick: handleClick,
      onChange: () => handleChange(false),
      checked: meta.value === false,
    },
  ];

  return (
    <div className={css.wrapper}>
      <div
        data-id="select-label"
        className={`${css.labelWrapper} ${isCategoryTitle && css.isCategoryTitle}`}
      >
        {label}
      </div>
      {readOnly ? (
        <>
          <div data-id="select-readOnly-value">{getReadOnlyValue()}</div>
        </>
      ) : (
        <SelectGroup
          aria-label={label}
          options={options}
          error={!!(meta.error && meta.touched)}
          infoError={meta.error}
        />
      )}
    </div>
  );
};

export default FormikYesNoSelect;
