import { Button } from "@flixbus/honeycomb-react";
import * as React from "react";
import { logout } from "../../../../../auth/utils/utils";
import { TranslateFunctionProp } from "../../../../../helper/types";

type Props = {
  primary: Boolean;
};

const LogoutButton: React.FC<Props & TranslateFunctionProp> = ({
  primary,
  translate,
}) => (
  <Button
    data-id="logout-button"
    appearance={primary ? "primary" : undefined}
    onClick={() => {
      logout();
    }}
  >
    {translate("navigation.logout")}
  </Button>
);

export default LogoutButton;
