import { Icon, IconArrowDown } from "@flixbus/honeycomb-icons-react";
import * as React from "react";
import { components, DropdownIndicatorProps } from "react-select";
import { SelectOption } from "../types";

export type IndicatorProps = DropdownIndicatorProps<SelectOption, false>;

const DropdownIndicator: React.FC<IndicatorProps> = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon InlineIcon={IconArrowDown} size={4} />
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;
