import { Icon, IconAttention } from "@flixbus/honeycomb-icons-react";
import * as React from "react";
import getScheduleStatusText from "../../helpers/getScheduleStatusText/getScheduleStatusText";
import { DisplayedScheduleStatus } from "../../types/schema";
import * as css from "./ScheduleStatusTagContent.scss";

export type Props = {
  status: DisplayedScheduleStatus;
};

const ScheduleStatusTagContent: React.FC<Props> = ({ status }) => {
  return (
    <div className={css.wrapper}>
      <span>{getScheduleStatusText(status)}</span>
      {status === DisplayedScheduleStatus.PartiallyOnSale && (
        <Icon InlineIcon={IconAttention} extraClasses={css.icon} />
      )}
    </div>
  );
};

export default ScheduleStatusTagContent;
