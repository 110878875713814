import { SchedulesOfLineQuery } from "./api/operations.generated";

export type ScheduleFromLineQuery = SchedulesOfLineQuery["findLine"]["schedules"][number];
export type ConceptChangeFromLineQuery = Omit<
  ScheduleFromLineQuery,
  "conceptChangeOf" | "conceptChanges"
> & { publishedDate?: string | null };

export type Interval = { start: Date; end: Date };

export type OverlapPosition = {
  colStart: number;
  colEnd: number;
  startDateInPast?: boolean;
  endDateInFuture?: boolean;
};

export enum CalendarIntervallType {
  YEAR,
  MONTH,
}
