import { formatMessage } from "@flixbus-phx/marketplace-common";
import {
  BookingInformationField,
  PassengerInformationField,
  TripInformationField,
} from "../../../../shared/types/schema";

export type BookingDataField =
  | BookingInformationField
  | TripInformationField
  | PassengerInformationField;

const translationKeys = new Map<BookingDataField, string>([
  [
    BookingInformationField.FlixOrderId,
    "partnerForm.bookingInfo.bookingInformation.flixOrderId",
  ],
  [
    BookingInformationField.PartnerOrderId,
    "partnerForm.bookingInfo.bookingInformation.partnerOrderId",
  ],
  [
    BookingInformationField.BookingDate,
    "partnerForm.bookingInfo.bookingInformation.bookingDate",
  ],
  [
    BookingInformationField.PaidPrice,
    "partnerForm.bookingInfo.bookingInformation.paidPrice",
  ],
  [
    BookingInformationField.BoardingPass,
    "partnerForm.bookingInfo.bookingInformation.boardingPass",
  ],
  [BookingInformationField.Invoice, "partnerForm.bookingInfo.bookingInformation.invoice"],

  [TripInformationField.LineCode, "partnerForm.bookingInfo.tripInformation.lineCode"],
  [TripInformationField.TravelDate, "partnerForm.bookingInfo.tripInformation.travelDate"],
  [
    TripInformationField.DepartureTime,
    "partnerForm.bookingInfo.tripInformation.departureTime",
  ],
  [
    TripInformationField.ArrivalTime,
    "partnerForm.bookingInfo.tripInformation.arrivalTime",
  ],
  [
    TripInformationField.DepartureAndArrivalStation,
    "partnerForm.bookingInfo.tripInformation.departureAndArrivalStation",
  ],
  [
    TripInformationField.InterconnectionTrip,
    "partnerForm.bookingInfo.tripInformation.interconnectionTrip",
  ],

  [PassengerInformationField.Name, "general.name"],
  [PassengerInformationField.Phone, "partnerForm.bookingInfo.passengerInformation.phone"],
  [PassengerInformationField.EMail, "general.email"],
  [
    PassengerInformationField.Nationality,
    "partnerForm.bookingInfo.passengerInformation.nationality",
  ],
  [
    PassengerInformationField.Gender,
    "partnerForm.bookingInfo.passengerInformation.gender",
  ],
  [
    PassengerInformationField.TicketType,
    "partnerForm.bookingInfo.passengerInformation.ticketType",
  ],
  [
    PassengerInformationField.Birthday,
    "partnerForm.bookingInfo.passengerInformation.birthday",
  ],
  [
    PassengerInformationField.IdentityDocument,
    "partnerForm.bookingInfo.passengerInformation.identityDocument",
  ],
  [
    PassengerInformationField.IdentityDocumentNumber,
    "partnerForm.bookingInfo.passengerInformation.identityDocumentNumber",
  ],
  [
    PassengerInformationField.SeatLabels,
    "partnerForm.bookingInfo.passengerInformation.seatLabels",
  ],
  [
    PassengerInformationField.Luggage,
    "partnerForm.bookingInfo.passengerInformation.luggage",
  ],
  [
    PassengerInformationField.Wheelchair,
    "partnerForm.bookingInfo.passengerInformation.wheelchair",
  ],
  [
    PassengerInformationField.CheckInStatus,
    "partnerForm.bookingInfo.passengerInformation.checkInStatus",
  ],
]);

export const getLabelTextForBookingData = (field: BookingDataField): string => {
  return formatMessage(
    translationKeys.get(field) || "partnerForm.bookingInfo.unknownField"
  );
};

export const defaultBookingValues: Array<BookingInformationField> = [
  BookingInformationField.FlixOrderId,
  BookingInformationField.BookingDate,
];
export const defaultTripValues: Array<TripInformationField> = [
  TripInformationField.LineCode,
  TripInformationField.TravelDate,
  TripInformationField.DepartureTime,
  TripInformationField.ArrivalTime,
  TripInformationField.DepartureAndArrivalStation,
];
export const defaultPassengerValues: Array<PassengerInformationField> = [
  PassengerInformationField.Name,
];

export const isDefaultValue = (field: BookingDataField): boolean => {
  return [
    ...defaultBookingValues,
    ...defaultTripValues,
    ...defaultPassengerValues,
  ].includes(field);
};
