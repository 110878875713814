import {
  Icon,
  IconEdit,
  IconNewTab,
  IconCheckmark,
  IconCrossed,
} from "@flixbus/honeycomb-icons-react";
import { Button } from "@flixbus/honeycomb-react";
import { Feature, hasUserPermission } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import hasEditStationPermission from "../../../../shared/helpers/hasEditStationPermission/hasEditStationPermission";
import { StationStatus } from "../../../../shared/types/schema";
import {
  useApproveStationMutation,
  useRejectStationMutation,
} from "../../api/operations.generated";
import StationRejectionPopup from "../../ui/stationRejectionPopup/StationRejectionPopup";
import * as css from "./StationActions.scss";

type Props = {
  stationId: string;
  status: StationStatus;
  onEditStation?: () => void;
  iconButton?: boolean;
};

const StationActions: React.FC<Props> = ({
  stationId,
  status,
  onEditStation,
  iconButton = false,
}) => {
  const [rejectStationPopupActive, setRejectStationPopupActive] = useState(false);

  const [approveStation] = useApproveStationMutation();
  const [rejectStation, { loading }] = useRejectStationMutation({
    onCompleted: () => setRejectStationPopupActive(false),
  });

  return (
    <div className={css.actionWrapper}>
      {hasUserPermission(Feature.EDIT_STATION_APPROVE_REJECT) &&
        status === StationStatus.InReview && (
          <>
            <Button
              extraClasses={css.rejectButton}
              display={iconButton ? "square" : "block"}
              aria-label="reject station"
              appearance={iconButton ? "tertiary" : "danger"}
              onClick={() => setRejectStationPopupActive(true)}
            >
              {iconButton && <Icon InlineIcon={IconCrossed} />}
              {!iconButton && <FormattedMessage id="button.label.reject" />}
            </Button>
            <Button
              display={iconButton ? "square" : "block"}
              aria-label="approve station"
              appearance={iconButton ? "tertiary" : "secondary"}
              onClick={() => {
                approveStation({
                  variables: {
                    id: stationId,
                  },
                });
              }}
            >
              {iconButton && <Icon InlineIcon={IconCheckmark} />}
              {!iconButton && <FormattedMessage id="button.label.approve" />}
            </Button>
          </>
        )}
      {onEditStation && (
        <Button
          display="square"
          aria-label="edit station"
          onClick={() => {
            onEditStation();
          }}
        >
          <Icon InlineIcon={hasEditStationPermission(status) ? IconEdit : IconNewTab} />
        </Button>
      )}
      <StationRejectionPopup
        stationId={stationId}
        active={rejectStationPopupActive}
        onConfirm={(values) =>
          rejectStation({
            variables: {
              id: stationId,
              ...values,
            },
          })
        }
        onCancel={() => setRejectStationPopupActive(false)}
        confirmButtonLoading={loading}
      />
    </div>
  );
};

export default StationActions;
