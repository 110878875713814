import { Skeleton } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { TripFragment } from "../../api/operations.generated";
import * as css from "./TripDurationCell.scss";

type Props = {
  totalTripDuration: TripFragment["totalTripDuration"];
  estimatedTripDuration: TripFragment["estimatedTripDuration"];
  isLoadingData: boolean;
};

const TripDurationCell: React.FC<Props> = ({
  totalTripDuration,
  estimatedTripDuration,
  isLoadingData,
}) => {
  return (
    <div className={css.wrapper}>
      <div className={css.totalTripDurationWrapper}>
        <div className={css.label}>
          <FormattedMessage id="trip.duration.entered" />:
        </div>
        <div className={css.duration} data-id="totalTripDuration">
          {isLoadingData ? (
            <Skeleton height="sm" width="sm" flushBottom />
          ) : (
            totalTripDuration
          )}
        </div>
      </div>
      <div className={css.estimatedTripDurationWrapper}>
        <div className={css.label}>
          <FormattedMessage id="trip.duration.estimated" />:
        </div>
        <div className={css.duration} data-id="estimatedTripDuration">
          {isLoadingData ? (
            <Skeleton height="sm" width="sm" flushBottom />
          ) : (
            estimatedTripDuration || <FormattedMessage id="general.notAvailable" />
          )}
        </div>
      </div>
    </div>
  );
};

export default TripDurationCell;
