import { makeVar } from "@apollo/client";
import { readURIParams } from "@flixbus-phx/marketplace-common";
import { IsoCountry, StationStatus } from "../../shared/types/schema";

export type StationsFilterVar = {
  name: string | null;
  code: string | null;
  city: string | null;
  country: IsoCountry | null;
  status: StationStatus | null;
};

export const getCountry = () => {
  const { country } = readURIParams(window.location.search);

  return Object.values(IsoCountry).includes(country as IsoCountry)
    ? (country as IsoCountry)
    : null;
};

export const getStatus = () => {
  const { status } = readURIParams(window.location.search);

  return Object.values(StationStatus).includes(status as StationStatus)
    ? (status as StationStatus)
    : null;
};

export const stationsFilterVar = makeVar<StationsFilterVar>({
  name: null,
  code: null,
  city: null,
  country: getCountry(),
  status: getStatus(),
});
