@import "../../helpers/styles/common-styles.scss";
@import "variables";

.link {
  color: $content-primary-color;
  text-decoration: none;
  cursor: pointer;
  display: block;
  @include tagOutline;

  &:visited {
    color: $content-primary-color;
  }
}
