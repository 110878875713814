import { eachWeekendOfMonth, isToday } from "date-fns";
import * as React from "react";
import { getCalendarPositionForDailyView } from "../../helpers/getCalendarPosition/getCalendarPosition";
import * as css from "./ScheduleCalendarWeekendCell.scss";

type Props = {
  selectedPeriod: Date;
};

const ScheduleCalendarWeekendCell: React.FC<Props> = ({ selectedPeriod }) => {
  return (
    <>
      {eachWeekendOfMonth(selectedPeriod).map((weekend) => {
        if (!isToday(weekend)) {
          return (
            <div
              className={css.weekend}
              key={weekend.toDateString()}
              style={getCalendarPositionForDailyView(weekend, weekend)}
            />
          );
        }
        return <></>;
      })}
    </>
  );
};

export default ScheduleCalendarWeekendCell;
