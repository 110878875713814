import { Icon } from "@flixbus/honeycomb-icons-react";
import { formatMessage } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { getLabelIcon } from "../../../../shared/helpers/getLabelForAmenity/getLabelForAmenity";
import {
  Amenity,
  CutOffSalesRule,
  ScheduleStatus,
  TimeUnit,
} from "../../../../shared/types/schema";
import { LineDetailFragment } from "../../api/operations.generated";

export const convertDurationToTime = (duration: number, unit: TimeUnit): string => {
  const durationInHours = unit === TimeUnit.Hours ? duration : duration / 60;

  if (Number.isNaN(durationInHours) || durationInHours < 1 || durationInHours > 24) {
    return "";
  }

  const time = (24 - durationInHours).toString();

  return time.padStart(2, "0").concat(":00");
};

type AmenitiesTagContentProps = {
  amenities: Array<Amenity>;
};

export const AmenitiesTagContent: React.FC<AmenitiesTagContentProps> = ({
  amenities,
}) => {
  const highlightedAmenities: Array<JSX.Element> = [];

  amenities.forEach((amenity) => {
    if (amenity.highlighted) {
      highlightedAmenities.push(
        <Icon key={amenity.amenityType} InlineIcon={getLabelIcon(amenity.amenityType)} />
      );
    }
  });

  if (highlightedAmenities.length === 0) {
    amenities.slice(0, 3).forEach((amenity) => {
      highlightedAmenities.push(
        <Icon key={amenity.amenityType} InlineIcon={getLabelIcon(amenity.amenityType)} />
      );
    });
  }

  const nonHighlightedAmenities = amenities.length - highlightedAmenities.length;

  return (
    <>
      {highlightedAmenities}
      {nonHighlightedAmenities > 0 && <span>&nbsp;+{nonHighlightedAmenities}</span>}
    </>
  );
};

export const generateLineDetailTags = (
  line: LineDetailFragment
): Array<string | JSX.Element> => {
  const tags = [];
  let activeSchedules = 0;

  line.schedules.forEach((schedule) => {
    if (schedule.status === ScheduleStatus.OnSale) {
      activeSchedules++;
    }
  });
  tags.push(formatMessage("lineDetail.tag.schedulesOnSale", { activeSchedules }));

  if (line.amenities.length) {
    tags.push(<AmenitiesTagContent amenities={line.amenities} />);
  }

  if (line.cutOffSales.cutOffSalesRule !== CutOffSalesRule.None) {
    if (line.cutOffSales.cutOffSalesRule === CutOffSalesRule.BeforeDeparture) {
      if (line.cutOffSales.cutOffSalesDurationInMinutes % 60 === 0) {
        tags.push(
          formatMessage("lineDetail.tag.salesCutOffInHours", {
            cutOffSalesInHours: line.cutOffSales.cutOffSalesDurationInMinutes / 60,
          })
        );
      } else {
        tags.push(
          formatMessage("lineDetail.tag.salesCutOffInMinutes", {
            cutOffSalesInMinutes: line.cutOffSales.cutOffSalesDurationInMinutes,
          })
        );
      }
    } else {
      tags.push(
        formatMessage("lineDetail.tag.salesCutOffAtTime", {
          cutOffSalesAtTime: convertDurationToTime(
            line.cutOffSales.cutOffSalesDurationInMinutes,
            TimeUnit.Minutes
          ),
        })
      );
    }
  }

  return tags;
};
