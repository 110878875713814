@import "variables";

.container {
  display: flex;
  justify-content: space-between;
}

.heading {
  display: inline-block;
  padding-bottom: 0;
  padding-top: $spacing-1;
}
