import { ProgressTracker, ProgressTrackerItem } from "@flixbus/honeycomb-react";
import * as React from "react";
import * as css from "./FormWizzard.scss";

export type FormWizzardProps<T, E, A = {}> = {
  steps: Array<
    {
      title: string;
      children: React.ReactNode;
      Form: React.JSXElementConstructor<any>;
      editableFields: E[keyof E];
      key: keyof T;
      defaultValues?: Partial<T[keyof T]>;
      additionallyRequiredFields?: { [Property in keyof T[keyof T]]: boolean };
      isCreate?: boolean;
      isLoadingData?: boolean;
      dataLoadingHasError?: boolean;
    } & A
  >;
  onSubmit: (values: T) => void;
  showStep?: number;
};

// eslint-disable-next-line react/function-component-definition
function FormWizzard<T, E, A = {}>({
  steps,
  onSubmit,
  showStep,
}: FormWizzardProps<T, E, A>): React.ReactElement | null {
  const [currentStep, setCurrentStep] = React.useState(0);
  const [formData, setFormData] = React.useState<Partial<T>>({
    [steps[currentStep].key]: steps[currentStep].defaultValues,
  } as Partial<T>);

  const CurrentForm = steps[currentStep];

  // Submit form if all steps are completed
  React.useEffect(() => {
    if (
      formData &&
      Object.keys(formData).length === steps.length &&
      currentStep === steps.length - 1
    ) {
      onSubmit(formData as T);
    }
  }, [formData]);

  // Set default values if they change
  React.useEffect(() => {
    if (CurrentForm.defaultValues && !formData[CurrentForm.key]) {
      setFormData((prevState) => ({
        ...prevState,
        [CurrentForm.key]: CurrentForm.defaultValues,
      }));
    }
  }, [CurrentForm.defaultValues]);

  // Force step to be rendered in error cases
  React.useEffect(() => {
    if (showStep !== undefined) {
      setCurrentStep(showStep);
    }
  }, [showStep]);

  const goStepForward = (values: Partial<T>) => {
    setFormData((prevState) => ({ ...prevState, [CurrentForm.key]: values }));

    if (currentStep < steps.length - 1) {
      setCurrentStep((prevState) => prevState + 1);
    }
  };

  const goStepBack = () => {
    if (currentStep > 0) {
      setCurrentStep((prevState) => prevState - 1);
    }
  };

  return (
    <>
      <ProgressTracker extraClasses={css.progressTracker}>
        {steps.map((step, index) => (
          <ProgressTrackerItem
            key={step.title}
            current={index === currentStep}
            completed={index < currentStep}
            completedSrText={index < currentStep ? "completed" : undefined}
          >
            {step.title}
          </ProgressTrackerItem>
        ))}
      </ProgressTracker>
      <div>
        <CurrentForm.Form
          {...CurrentForm}
          onSubmit={(values: Partial<T>) => {
            goStepForward(values);
          }}
          onCancel={goStepBack}
          defaultValues={formData ? formData[CurrentForm.key] : undefined}
          editableFields={CurrentForm.editableFields}
          additionallyRequiredFields={CurrentForm.additionallyRequiredFields}
        >
          {CurrentForm.children}
        </CurrentForm.Form>
      </div>
    </>
  );
}

export default FormWizzard;
