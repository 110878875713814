@import "variables";

.container {
  height: $timetable-station-box-height;
  width: $timetable-station-box-width;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-top: $border;

  &:hover .removeIcon {
    visibility: visible;
  }
}

.containerFirstLast {
  height: $timetable-station-box-height-FirstLast;
}

.stationCodeNameCentered {
  display: flex;
  justify-content: flex-start;
}

.stationCodeName {
  display: block;
  align-items: center;
  width: 230px;
  padding: 0 $spacing-2;
  cursor: pointer;
}

.stationCodeNameFirstLast {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.stationName {
  font-weight: bold;
}

.infoBox {
  display: flex;
  margin: 2px 8px 3px 0;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  bottom: 0;
}

.publishInfoIcon {
  color: $warning-color;
}

.icon {
  width: 20px;
  height: 20px;
  margin-left: 3px;
}

.removeIconContainer {
  height: 20px;
  width: 20px;
  visibility: hidden;
  position: absolute;
  right: $spacing-1;
  top: $spacing-1;
}

.removeIcon {
  cursor: pointer;
}

@mixin stationCellTag {
  position: absolute;
  top: -15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stationAddTag {
  @include stationCellTag;
  left: $spacing-3;
  cursor: pointer;
  color: $icon-primary-color;
}

.stationCountryCodeTag {
  @include stationCellTag;
  width: $spacing-8;
  left: calc((300px / 2) - #{$spacing-3});
  border-color: $button-primary-color;
  background: $button-primary-color;
  color: $button-label-color;
}

.stationIsCountryChange {
  border-top: 1px solid $button-primary-color;
}
