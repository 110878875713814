@import "variables";

.box {
  padding: $spacing-2;
  padding-bottom: 0;
}

.checkBox {
  font-weight: $font-weight-bold;
  margin-bottom: $spacing-2;
}
