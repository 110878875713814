import React__default__default from 'react';
import InteractiveElements from '../../packages/interactive-elements/index.js';

function useTabTrap(active, containerRef, targetRef, loading) {
  var isFirstRender = React__default__default.useRef(true);
  var _a = React__default__default.useState(),
    firstInteractiveElement = _a[0],
    setFirstInteractiveElement = _a[1];
  var _b = React__default__default.useState(),
    lastInteractiveElement = _b[0],
    setLastInteractiveElement = _b[1];
  var firstElementKeyListener = function (event) {
    if (event.shiftKey && event.key === 'Tab') {
      event.preventDefault();
      lastInteractiveElement === null || lastInteractiveElement === void 0 ? void 0 : lastInteractiveElement.focus();
    }
  };
  var lastElementKeyListener = function (event) {
    if (!event.shiftKey && event.key === 'Tab') {
      event.preventDefault();
      firstInteractiveElement === null || firstInteractiveElement === void 0 ? void 0 : firstInteractiveElement.focus();
    }
  };
  /* Set focus to target element when popup is closed, but is not the first render. */
  React__default__default.useEffect(function () {
    var _a;
    if (!active && isFirstRender.current === false) {
      (_a = targetRef === null || targetRef === void 0 ? void 0 : targetRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    }
  }, [active]);
  /* Set focus to first interactive element when popup is opened and finishes loading. */
  React__default__default.useEffect(function () {
    if (active && !loading && firstInteractiveElement) {
      firstInteractiveElement.focus();
    }
  }, [active, loading, firstInteractiveElement]);
  /* When component is not loading, find the first and last interactive elements and store them. */
  React__default__default.useEffect(function () {
    var _a;
    if (!loading) {
      var allInteractiveElements = (_a = containerRef === null || containerRef === void 0 ? void 0 : containerRef.current) === null || _a === void 0 ? void 0 : _a.querySelectorAll(InteractiveElements.join(','));
      var firstElement = allInteractiveElements === null || allInteractiveElements === void 0 ? void 0 : allInteractiveElements.item(0);
      var lastElement = allInteractiveElements === null || allInteractiveElements === void 0 ? void 0 : allInteractiveElements.item(allInteractiveElements.length - 1);
      setFirstInteractiveElement(firstElement);
      setLastInteractiveElement(lastElement);
    }
  }, [loading]);
  /* Add event listeners to first and last elements whenever they change. */
  React__default__default.useEffect(function () {
    firstInteractiveElement === null || firstInteractiveElement === void 0 ? void 0 : firstInteractiveElement.addEventListener('keydown', firstElementKeyListener);
    lastInteractiveElement === null || lastInteractiveElement === void 0 ? void 0 : lastInteractiveElement.addEventListener('keydown', lastElementKeyListener);
  }, [firstInteractiveElement, lastInteractiveElement]);
  /* Runs on first render to flag if should set focus to target element,
  and cleans event listeners on unmount. */
  React__default__default.useEffect(function () {
    isFirstRender.current = false;
    return function () {
      firstInteractiveElement === null || firstInteractiveElement === void 0 ? void 0 : firstInteractiveElement.removeEventListener('keydown', firstElementKeyListener);
      lastInteractiveElement === null || lastInteractiveElement === void 0 ? void 0 : lastInteractiveElement.removeEventListener('keydown', lastElementKeyListener);
    };
  }, []);
}

export { useTabTrap };
