import { Icon, IconPinSelect } from "@flixbus/honeycomb-icons-react";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import * as css from "../styles/label.scss";

const StandaloneOnlyText: React.FC = () => (
  <div className={css.wrapper}>
    <Icon InlineIcon={IconPinSelect} className={css.icon} />
    <span>
      <FormattedMessage id="partnerForm.inventoryRules.option.standalone" />
    </span>
  </div>
);

export default StandaloneOnlyText;
