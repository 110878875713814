import { Icon, IconClose } from "@flixbus/honeycomb-icons-react";
import { Tag } from "@flixbus/honeycomb-react";
import { localizeDate, parseDate } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import PeriodCalendar from "../../../../../../shared/components/periodCalendar/PeriodCalendar";
import { ScheduleDetailFragment } from "../../../../api/operations.generated";
import * as css from "./NonOperatingPeriodTag.scss";

export type NonOperatingPeriodTagProps = {
  periodStart: string;
  periodEnd: string;
  onRemovePeriod: () => void;
  onEditPeriod: (startDate: Date, endDate: Date) => void;
  readOnly: boolean;
  selectablePeriod: ScheduleDetailFragment["period"];
};

const NonOperatingPeriodTag: React.FC<NonOperatingPeriodTagProps> = ({
  periodStart,
  periodEnd,
  onRemovePeriod,
  onEditPeriod,
  readOnly,
  selectablePeriod,
}) => {
  const [showCalendar, setShowCalendar] = React.useState(false);
  const tagText =
    periodStart === periodEnd
      ? localizeDate(periodStart)
      : `${localizeDate(periodStart)} - ${localizeDate(periodEnd)}`;

  return (
    <>
      <Tag extraClasses={css.tag}>
        {!readOnly ? (
          <>
            <Icon
              InlineIcon={IconClose}
              extraClasses={css.closeIcon}
              onClick={onRemovePeriod}
            />
            <span
              className={css.tagTextActive}
              onClick={() => {
                setShowCalendar(true);
              }}
            >
              {tagText}
            </span>
          </>
        ) : (
          <span className={css.tagTextReadOnly}>{tagText}</span>
        )}
      </Tag>
      {showCalendar && (
        <PeriodCalendar
          defaultStartDate={parseDate(periodStart)}
          defaultEndDate={parseDate(periodEnd)}
          onCalendarClose={() => setShowCalendar(false)}
          onPeriodSelected={(startDate, endDate) => {
            onEditPeriod(startDate, endDate);
            setShowCalendar(false);
          }}
          selectablePeriod={selectablePeriod}
        />
      )}
    </>
  );
};

export default NonOperatingPeriodTag;
