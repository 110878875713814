import lineFilterVar, {
  defaultLineFilterValues,
} from "../../../state/lineFilter/lineFilter";
import partnerFilterVar, {
  defaultPartnerFilterValues,
} from "../../../state/partnerFilter/partnerFilter";

export const clearPartnerFilter = () => {
  partnerFilterVar(defaultPartnerFilterValues);
};

export const clearLineFilter = () => {
  lineFilterVar(defaultLineFilterValues);
};
