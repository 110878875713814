import { Tooltip } from "@flixbus/honeycomb-react";
import * as React from "react";
import { useIntl } from "react-intl";
import getCountryFlag from "../../../../shared/helpers/getCountryFlag/getCountryFlag";
import { IsoCountry } from "../../../../shared/types/schema";
import * as css from "./TooltipFlag.scss";

type Props = {
  id: string;
  countryCode?: IsoCountry;
};

const TooltipFlag: React.FC<Props> = ({ id, countryCode }) => {
  const { formatMessage } = useIntl();

  if (countryCode) {
    return (
      <div className={css.tooltip}>
        <Tooltip
          id={`tooltip-${id}`}
          content={formatMessage({ id: `country.${countryCode}` })}
          openOnHover
          size="content-fit"
        >
          <img
            src={getCountryFlag(countryCode as IsoCountry)}
            alt={formatMessage({ id: `country.${countryCode}` })}
            height="25px"
            width="25px"
            className={css.flag}
          />
        </Tooltip>
      </div>
    );
  }
  return null;
};

export default TooltipFlag;
