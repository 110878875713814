import { Icon, IconDocument, IconInfo } from "@flixbus/honeycomb-icons-react";
import { Box, Button } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ImportPopupButtonWrapper from "../../importPopupButtonWrapper/ImportPopupButtonWrapper";
import ImportPopupHeader from "../../importPopupHeader/ImportPopupHeader";
import * as css from "./Validated200.scss";

export type Validated200Props = {
  rows: number;
  fileName: string;
  onBack: () => void;
  onConfirm: () => void;
  isLoading: boolean;
  type: "station" | "city";
};

const Validated200: React.FC<Validated200Props> = ({
  rows,
  fileName,
  onBack,
  onConfirm,
  isLoading,
  type,
}) => {
  const { formatPlural } = useIntl();

  return (
    <>
      <ImportPopupHeader Icon={IconInfo} color="neutral">
        <FormattedMessage
          id={`importPopup.validation200.headline.${type}.${formatPlural(rows)}`}
          values={{ amount: rows }}
        />
      </ImportPopupHeader>

      <Box small extraClasses={css.box}>
        <div className={css.fileName}>
          <Icon extraClasses={css.icon} InlineIcon={IconDocument} size={4} />
          <span>{fileName}</span>
        </div>
      </Box>

      <ImportPopupButtonWrapper>
        <Button onClick={onBack}>
          <FormattedMessage id="general.back" />
        </Button>
        <Button appearance="primary" onClick={onConfirm} loading={isLoading}>
          <FormattedMessage id="general.confirm" />
        </Button>
      </ImportPopupButtonWrapper>
    </>
  );
};

export default Validated200;
