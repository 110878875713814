import { Icon, IconPublicSolid } from "@flixbus/honeycomb-icons-react";
import { Skeleton, Tag } from "@flixbus/honeycomb-react";
import * as React from "react";
import { BusinessRegion } from "../../../shared/types/schema";
import { useFindAllBusinessRegionsForFiltersQuery } from "../../api/operations.generated";
import * as css from "./BusinessRegionFilterTag.scss";

export type BusinessRegionFilterTagProps = {
  bizRegions: BusinessRegion["id"][];
  onClose: () => void;
};

const BusinessRegionFilterTag: React.FC<BusinessRegionFilterTagProps> = ({
  bizRegions,
  onClose,
}) => {
  const { data, loading } = useFindAllBusinessRegionsForFiltersQuery();

  if (loading) {
    return (
      <Tag display="subtle">
        <Skeleton flushBottom extraClasses={css.skeleton} />
      </Tag>
    );
  }

  if (data?.findAllBusinessRegions) {
    return (
      <Tag
        display="subtle"
        closeProps={{
          label: "Remove Filter",
          onClick: onClose,
        }}
      >
        <Icon extraClasses={css.icon} InlineIcon={IconPublicSolid} />
        <span className={css.text}>
          {bizRegions
            .map((bizRegion) => {
              const region = data.findAllBusinessRegions.find((r) => r.id === bizRegion);

              if (region) {
                return region.name;
              }
              return "";
            })
            .join(", ")}
        </span>
      </Tag>
    );
  }

  return <></>;
};

export default BusinessRegionFilterTag;
