import { useSearchParams } from "react-router-dom";
import { defaultPhraseLocale, phraseLocales } from "../../helper/locales/locales";
import { LocaleListItem } from "../../helper/locales/types";

export const LOCALE_KEY = "lang";

export const getLocaleFromSearchParam = (
  param: string | null
): Required<LocaleListItem> | undefined => {
  return phraseLocales.find((lang) => lang.locale === param);
};

export const getPreferredLocaleFromBrowser = (): Required<LocaleListItem> | undefined => {
  const browserLocale = phraseLocales.find(
    (lang) => lang.bcp47Locale === navigator.language
  );

  if (browserLocale) {
    return browserLocale;
  }

  return phraseLocales.find(
    (lang) => lang.bcp47Locale === navigator.language.split("-")[0]
  );
};

const useCurrentLocale = (): [Required<LocaleListItem>, (lang: string) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setLocale = (lang: string) => {
    setSearchParams(`${LOCALE_KEY}=${lang}`);
  };

  const localeFromSearchParam = getLocaleFromSearchParam(searchParams.get(LOCALE_KEY));
  const localeFromBrowser = getPreferredLocaleFromBrowser();

  if (localeFromSearchParam) {
    return [localeFromSearchParam, setLocale];
  }

  if (localeFromBrowser) {
    setLocale(localeFromBrowser.locale);
    return [localeFromBrowser, setLocale];
  }

  setLocale(defaultPhraseLocale.locale);
  return [defaultPhraseLocale, setLocale];
};

export default useCurrentLocale;
