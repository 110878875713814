import { PolicyFormValues, UnitTypes } from "../../types";
import {
  getInitialValuesForBookableLuggage,
  getInitialValuesForIncludedLuggage,
} from "../getInitialValuesForLuggage/getInitialValuesForLuggage";

export default (unitType: UnitTypes, values: PolicyFormValues): PolicyFormValues => {
  const clearedValues = JSON.parse(JSON.stringify(values));
  let resetedValue;

  switch (unitType) {
    case "weight":
      resetedValue = getInitialValuesForIncludedLuggage().luggageSpecification.maxWeight;
      clearedValues.luggageAllowance.handLuggagePolicy.luggageSpecification.maxWeight =
        resetedValue;
      clearedValues.luggageAllowance.checkInLuggagePolicy.luggageSpecification.maxWeight =
        resetedValue;
      clearedValues.luggageAllowance.additionalLuggagePolicy.luggageSpecification.maxWeight =
        resetedValue;
      clearedValues.specialLuggageAndBuggies.specialLuggagePolicy.luggageSpecification.maxWeight =
        resetedValue;
      break;
    case "length":
      resetedValue =
        getInitialValuesForIncludedLuggage().luggageSpecification.maxDimension;
      clearedValues.luggageAllowance.handLuggagePolicy.luggageSpecification.maxDimension =
        resetedValue;
      clearedValues.luggageAllowance.checkInLuggagePolicy.luggageSpecification.maxDimension =
        resetedValue;
      clearedValues.luggageAllowance.additionalLuggagePolicy.luggageSpecification.maxDimension =
        resetedValue;
      clearedValues.specialLuggageAndBuggies.specialLuggagePolicy.luggageSpecification.maxDimension =
        resetedValue;
      break;
    case "currency":
      resetedValue = getInitialValuesForBookableLuggage(["feePrice"]).luggageSpecification
        .feePrice;
      clearedValues.luggageAllowance.additionalLuggagePolicy.luggageSpecification.feePrice =
        resetedValue;
      clearedValues.specialLuggageAndBuggies.specialLuggagePolicy.luggageSpecification.feePrice =
        resetedValue;
      clearedValues.specialLuggageAndBuggies.pushchairPolicy.luggageSpecification.feePrice =
        resetedValue;
      clearedValues.bikesSurfboardsAndSkies.bikePolicy.luggageSpecification.feePrice =
        resetedValue;
      clearedValues.bikesSurfboardsAndSkies.surfboardPolicy.luggageSpecification.feePrice =
        resetedValue;
      clearedValues.bikesSurfboardsAndSkies.skiEquipmentPolicy.luggageSpecification.feePrice =
        resetedValue;
      clearedValues.pets.petPolicy.luggageSpecification.feePrice = resetedValue;
      break;
    default:
      break;
  }

  return clearedValues;
};
