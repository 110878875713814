import { Icon, IconInterconnection } from "@flixbus/honeycomb-icons-react";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import * as css from "../styles/label.scss";

const InterconnectionOnlyText: React.FC = () => (
  <div className={css.wrapper}>
    <Icon InlineIcon={IconInterconnection} className={css.icon} />
    <span>
      <FormattedMessage id="partnerForm.inventoryRules.option.iconly" />
    </span>
  </div>
);

export default InterconnectionOnlyText;
