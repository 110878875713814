@import "variables";

.container {
  position: absolute;
  width: 100%;
}

.calendar {
  position: absolute;
  z-index: 100;
  max-width: 557px;

  @include on-bp(xl) {
    div[class*="hcr-clndr-header__select"]:after {
      top: 20px;
    }
  }
}
