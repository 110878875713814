import { ScheduleFromLineQuery } from "../../types";
import conceptChangeComparator from "../conceptChangeComparator/conceptChangeComparator";
import scheduleComparator from "../scheduleComparator/scheduleComparator";

export default (schedules: Array<ScheduleFromLineQuery>) => {
  return schedules.sort(scheduleComparator).map((originalSchedule) => ({
    ...originalSchedule,
    conceptChanges: [...originalSchedule.conceptChanges].sort(conceptChangeComparator),
  }));
};
