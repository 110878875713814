@import "variables";
@import "../../helpers/styles/grid-vars.scss";

.topGrid {
  display: grid;
  grid-template-columns: $left-col-width $right-col-width;
  margin-top: $spacing-4;
}

.bottomGrid {
  margin-bottom: $spacing-8;
  margin-top: $spacing-2;
  display: grid;
  grid-template-columns: $left-col-width $right-col-width;
}

.colLeft,
.colRight {
  border: $border;
}

.colLeft {
  grid-column: 1 / 2;
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
}

.colRight {
  grid-column: 2 / 3;
  border-left: none;
  border-top-right-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
}

.cell {
  @include schedule-list-grid-cell;
}

.link {
  line-height: $row-height;
}
