@import "variables";

.tooltip {
  line-height: $line-height-primary;
}

.tag {
  display: inline-block;
}

.tagSmall {
  cursor: pointer;
  text-align: center;
  white-space: break-spaces;
  height: unset;
  margin-right: $spacing-2;
}
