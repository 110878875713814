.tableRow {
  cursor: pointer;
}

.table {
  // Code
  th:nth-child(1),
  td:nth-child(1) {
    width: 10%;;
  }

  // Description
  th:nth-child(2),
  td:nth-child(2) {
    width: 30%;
  }

  // Partner
  th:nth-child(3),
  td:nth-child(3) {
    width: 35%;
  }

  // Biz Region
  th:nth-child(4),
  td:nth-child(4) {
    width: 15%;
  }

  // Integration Type
  th:nth-child(5),
  td:nth-child(5) {
    width: 10%;
  }
}
