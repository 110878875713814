@import "variables";

.tag {
  margin-left: $spacing-2;
  margin-bottom: $spacing-1;
}

.tagTextActive {
  cursor: pointer;
}

.tagTextReadOnly {
  cursor: not-allowed;
}

.closeIcon {
  margin-bottom: 2px;
  margin-right: 4px;
  cursor: pointer;
}
