import { Icon, IconArrowUp, IconArrowDown } from "@flixbus/honeycomb-icons-react";
import { Box } from "@flixbus/honeycomb-react";
import cx from "classnames";
import * as React from "react";
import { useState } from "react";
import * as css from "./Accordion.scss";

type Props = {
  headerSection: JSX.Element;
  openAccordionText: string;
  closeAccordionText: string;
  extraClasses?: string;
};

const Accordion: React.FC<Props> = ({
  headerSection,
  openAccordionText,
  closeAccordionText,
  extraClasses,
  children,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Box extraClasses={cx(css.box, extraClasses)} highlighted>
      <div className={css.headerSection}>
        {headerSection}
        <div
          className={cx(css.buttonArrow, css.clickable)}
          onClick={() => setOpen((prevState) => !prevState)}
          data-id="accordionClickable"
        >
          {!open && (
            <>
              <span>{openAccordionText}</span>
              <Icon data-id="icon-arrow-down" InlineIcon={IconArrowDown} size={4} />
            </>
          )}
          {open && (
            <>
              <span>{closeAccordionText}</span>
              <Icon data-id="icon-arrow-up" InlineIcon={IconArrowUp} size={4} />
            </>
          )}
        </div>
      </div>
      {open && <div className={css.children}>{children}</div>}
    </Box>
  );
};

export default Accordion;
