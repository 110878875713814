function removeItemFormArray<T>(arr: Array<T>, value: T): Array<T> {
  const newArr = [...arr];
  const index = arr.indexOf(value);
  if (index > -1) {
    newArr.splice(index, 1);
  }
  return newArr;
}

export default removeItemFormArray;
