import {
  IconAntiVirus,
  IconUsb,
  IconBike,
  IconDog,
  IconEntertainment,
  IconPersonBusiness,
  IconQuestion,
  IconSnacks,
  IconSocket,
  IconThermometer,
  IconToilet,
  IconWifi,
  IconComponent,
} from "@flixbus/honeycomb-icons-react";
import { formatMessage } from "@flixbus-phx/marketplace-common";
import { AmenityType } from "../../types/schema";

export const getLabelText = (amenity: AmenityType): string => {
  switch (amenity) {
    case AmenityType.AirCondition:
      return formatMessage("amenity.airCondition");
    case AmenityType.Bikes:
      return formatMessage("amenity.bikes");
    case AmenityType.OnboardEntertainment:
      return formatMessage("amenity.onboardEntertainment");
    case AmenityType.Pets:
      return formatMessage("amenity.pets");
    case AmenityType.PowerSockets:
      return formatMessage("amenity.powerSockets");
    case AmenityType.SnacksDrinks:
      return formatMessage("amenity.snacksDrinks");
    case AmenityType.Toilet:
      return formatMessage("amenity.toilet");
    case AmenityType.Usb:
      return formatMessage("amenity.usb");
    case AmenityType.Wifi:
      return formatMessage("amenity.wifi");
    case AmenityType.Disinfected:
      return formatMessage("amenity.disinfected");
    case AmenityType.Stewards:
      return formatMessage("amenity.stewards");
    default:
      return formatMessage("amenity.placeholder");
  }
};

export const getLabelIcon = (amenity: AmenityType): IconComponent => {
  switch (amenity) {
    case AmenityType.AirCondition:
      return IconThermometer;
    case AmenityType.Bikes:
      return IconBike;
    case AmenityType.OnboardEntertainment:
      return IconEntertainment;
    case AmenityType.Pets:
      return IconDog;
    case AmenityType.PowerSockets:
      return IconSocket;
    case AmenityType.SnacksDrinks:
      return IconSnacks;
    case AmenityType.Toilet:
      return IconToilet;
    case AmenityType.Usb:
      return IconUsb;
    case AmenityType.Wifi:
      return IconWifi;
    case AmenityType.Disinfected:
      return IconAntiVirus;
    case AmenityType.Stewards:
      return IconPersonBusiness;
    default:
      return IconQuestion;
  }
};
