import { Button } from "@flixbus/honeycomb-react";
import * as React from "react";
import { commonPathnames } from "../../../../../helper";
import { TranslateFunctionProp } from "../../../../../helper/types";

type Props = {
  primary: Boolean;
};

const BackToMainPageButton: React.FC<Props & TranslateFunctionProp> = ({
  primary,
  translate,
}) => (
  <Button
    data-id="back-button"
    appearance={primary ? "primary" : undefined}
    href={commonPathnames.userHome}
    Elem="a"
  >
    {translate("home.button.text")}
  </Button>
);

export default BackToMainPageButton;
