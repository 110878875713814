import { LatLngLiteral } from "leaflet";

export default (coordinate: LatLngLiteral): boolean => {
  return (
    Number.isFinite(coordinate.lat) &&
    Math.abs(coordinate.lat) <= 90 &&
    Number.isFinite(coordinate.lng) &&
    Math.abs(coordinate.lng) <= 180
  );
};
