export enum LocaleRegion {
  EUROPE = "europe",
  AMERICAS = "americas",
}

export type LocaleListItem = {
  name: string;
  locale: string;
  flagName: string;
  // https://en.wikipedia.org/wiki/IETF_language_tag
  bcp47Locale: string;
  region?: LocaleRegion;
  // https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
  iso6391: string;
  // https://wiki.flix.tech/display/D8/List+of+language+codes+used+in+Drupal
  drupal: string;
};
export type LocaleList = Array<LocaleListItem>;
export type LocaleListPhrase = Array<Required<LocaleListItem>>;
