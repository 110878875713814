import { formatMessage } from "@flixbus-phx/marketplace-common";

export const days = () => [
  { short: formatMessage("general.monday"), long: "Monday" },
  { short: formatMessage("general.tuesday"), long: "Tuesday" },
  { short: formatMessage("general.wednesday"), long: "Wednesday" },
  { short: formatMessage("general.thursday"), long: "Thursday" },
  { short: formatMessage("general.friday"), long: "Friday" },
  { short: formatMessage("general.saturday"), long: "Saturday" },
  { short: formatMessage("general.sunday"), long: "Sunday" },
];

export const months = () => [
  formatMessage("general.january"),
  formatMessage("general.february"),
  formatMessage("general.march"),
  formatMessage("general.april"),
  formatMessage("general.may"),
  formatMessage("general.june"),
  formatMessage("general.july"),
  formatMessage("general.august"),
  formatMessage("general.september"),
  formatMessage("general.october"),
  formatMessage("general.november"),
  formatMessage("general.december"),
];
