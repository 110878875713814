import { isAfter, isBefore, NormalizedInterval } from "date-fns";

const growInterval = (
  intervalToGrow: NormalizedInterval,
  intervalToAdd: NormalizedInterval
): NormalizedInterval => {
  const grownInterval = intervalToGrow;

  if (isBefore(intervalToAdd.start, intervalToGrow.start)) {
    grownInterval.start = intervalToAdd.start;
  }

  if (isAfter(intervalToAdd.end, intervalToGrow.end)) {
    grownInterval.end = intervalToAdd.end;
  }

  return grownInterval;
};

export default growInterval;
