import { useReactiveVar } from "@apollo/client";
import { Heading } from "@flixbus/honeycomb-react";
import { Form, Formik } from "formik2";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { PartnerEditableFields } from "../../shared/helpers/getEditableFormFields/types";
import { BrandsInput } from "../../shared/types/schema";
import { selectedBrandVar } from "../../state/reactiveVariables/reactiveVariables";
import PoliciesPanel from "../policiesPanel/PoliciesPanel";
import * as css from "./PartnerBrandsAndPoliciesForm.scss";
import BrandsInputSelect from "./containers/brandsInputSelect/BrandsInputSelect";
import validationSchema from "./helpers/validationSchema/validationSchema";
import { Brand } from "./types";
import BrandsInputTable from "./ui/brandsInputTable/BrandsInputTable";

export type PartnerBrandsAndPoliciesFormProps = {
  onSubmit: (values: BrandsInput) => void;
  onCancel: () => void;
  defaultValues?: { brands: Array<Brand> };
  editableFields?: PartnerEditableFields["brandsInput"];
};

const PartnerBrandsAndPoliciesForm: React.FC<PartnerBrandsAndPoliciesFormProps> = ({
  onSubmit,
  onCancel,
  defaultValues,
  editableFields,
  children,
}) => {
  const selectedBrand = useReactiveVar(selectedBrandVar);

  const isPanelActive = selectedBrand !== "";
  const closePanel = () => {
    selectedBrandVar("");
  };

  const initialValues = {
    brands: defaultValues?.brands || [],
  };

  return (
    <>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values) => {
          onSubmit({ brands: values.brands.map((brand) => brand.id) });
        }}
        onReset={onCancel}
      >
        <Form noValidate>
          <div className={css.container}>
            <Heading extraClasses={css.heading} size={3}>
              <FormattedMessage id="partnerForm.brandsAndPolicies.headline" />
            </Heading>
            {editableFields?.editBrand && (
              <BrandsInputSelect isInUpdateFlow={!!defaultValues} />
            )}
          </div>
          <BrandsInputTable
            readOnly={!editableFields?.editBrand}
            isInUpdateFlow={!!defaultValues}
          />
          {children}
        </Form>
      </Formik>
      {isPanelActive && (
        <PoliciesPanel
          brandId={selectedBrand}
          onSubmit={closePanel}
          onClose={closePanel}
          readOnly={!editableFields?.policies}
        />
      )}
    </>
  );
};

export default PartnerBrandsAndPoliciesForm;
