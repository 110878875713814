@import "variables";
@import "../../helpers/styles/grid-vars.scss";
@import "../../helpers/styles/common-styles.scss";

$highlight-border-size: 2px;

.cell {
  @include schedule-calendar-grid-cell;
}

.clickableItem {
  cursor: pointer;
  &:hover {
    @include weekend;
  }
}

.item {
  justify-self: stretch;
  align-self: stretch;
  text-align: center;
  line-height: $row-height - $highlight-border-size;
  font-weight: $font-weight-bold;
}

.currentDate {
  @include highlighted-cell;
  border-bottom: 2px solid $secondary-brand-color;
}

.weekend {
  @include weekend;
}
