import { IconOfferSolid, Icon } from "@flixbus/honeycomb-icons-react";
import { Heading, Grid, GridCol } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import * as css from "./AddNewButton.scss";

export type AddNewButtonProps = {
  onButtonClick: () => void;
  position: number;
};

const AddNewButton: React.FC<AddNewButtonProps> = ({ onButtonClick, position }) => {
  return (
    <div
      className={css.textContainer}
      data-id="add-button-container"
      onClick={onButtonClick}
      style={{
        gridColumn: `${position} / ${position + 1}`,
      }}
    >
      <Grid>
        <GridCol size={12}>
          <div className={css.iconWrapper}>
            <Icon InlineIcon={IconOfferSolid} extraClasses={css.icon} />
          </div>
        </GridCol>

        <GridCol size={12}>
          <div className={css.textWrapper}>
            <Heading size={2} extraClasses={css.text}>
              <FormattedMessage id="trip.add.button.label" />
            </Heading>
          </div>
        </GridCol>
      </Grid>
    </div>
  );
};

export default AddNewButton;
