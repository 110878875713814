import { Icon, IconArrowBigTopRight } from "@flixbus/honeycomb-icons-react";
import { Tooltip } from "@flixbus/honeycomb-react";
import cx from "classnames";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import ScheduleStatusTagTooltip from "../../../../shared/components/scheduleStatusTagTooltip/ScheduleStatusTagTooltip";
import { ConceptChangeFromLineQuery, ScheduleFromLineQuery } from "../../types";
import ScheduleLink from "../scheduleLink/ScheduleLink";
import * as css from "./ScheduleListItem.scss";

type Props = {
  schedule: ScheduleFromLineQuery | ConceptChangeFromLineQuery;
  isConceptChange?: boolean;
};

const ScheduleListItem: React.FC<Props> = ({ schedule, isConceptChange }) => {
  return (
    <div data-id="schedule-list-item" className={css.cell}>
      <div className={css.link}>
        <ScheduleLink scheduleId={schedule.id}>
          <div
            className={cx(css.wrapper, isConceptChange && css.wrapperConceptChange)}
            data-id="schedule-list-item-content"
          >
            {isConceptChange && (
              <Icon extraClasses={css.arrow} InlineIcon={IconArrowBigTopRight} />
            )}

            <Tooltip
              id={schedule.id}
              data-id="schedule-name-tooltip"
              size="content-fit"
              extraClasses={css.tooltip}
              content={
                isConceptChange ? (
                  <div>
                    <b>
                      <FormattedMessage id="schedule.conceptChange.label" />
                    </b>
                    <br />
                    <span>{schedule.name}</span>
                  </div>
                ) : (
                  schedule.name
                )
              }
              openOnHover
            >
              <div className={css.scheduleName}>{schedule.name}</div>
            </Tooltip>

            <ScheduleStatusTagTooltip
              scheduleId={schedule.id}
              displayedScheduleStatus={schedule.displayedScheduleStatus}
              isScheduleOutdated={schedule.isOutdated}
              isTagSmall
            />
          </div>
        </ScheduleLink>
      </div>
    </div>
  );
};

export default ScheduleListItem;
