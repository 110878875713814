import * as React from "react";
import { Currency, RelationType } from "../../../../shared/types/schema";
import { RelationMatrixEntryFromQuery } from "../../types";
import DefaultCell, { DefaultCellProps } from "../defaultCell/DefaultCell";
import ForbiddenRelationIcon from "../forbiddenRelationIcon/ForbiddenRelationIcon";
import PriceInput from "../priceInput/PriceInput";
import ReadOnlyPriceText from "../readOnlyPriceText/ReadOnlyPriceText";
import * as css from "./PriceCell.scss";

export type PriceCellProps = {
  lineId: string;
  price: RelationMatrixEntryFromQuery["price"];
  type: RelationMatrixEntryFromQuery["type"];
  currency: Currency;
  onEnter: () => void;
  onLeave: () => void;
  onChange: (value: number | null) => void;
  readOnly: boolean;
  isPriceInvalid: boolean;
} & DefaultCellProps;

const PriceCell: React.FC<PriceCellProps> = ({
  lineId,
  price,
  type,
  currency,
  onEnter,
  onLeave,
  onChange,
  readOnly,
  isPriceInvalid,
  ...defaultCellProps
}) => {
  const handlePriceChange = (value: number | null) => {
    onChange(value);
  };

  const getCellContent = () => {
    if (readOnly) {
      switch (type) {
        case RelationType.Forbidden:
        case RelationType.Regular:
          return (
            <div
              className={
                type === RelationType.Forbidden
                  ? css.readOnlyForbiddenRelationPrice
                  : css.readOnlyPrice
              }
            >
              {type === RelationType.Forbidden && (
                <ForbiddenRelationIcon
                  row={defaultCellProps.row}
                  lineId={lineId}
                  extraClasses={css.readOnlyForbiddenRelationIcon}
                />
              )}
              <div>
                <ReadOnlyPriceText currency={currency} price={price} />
              </div>
            </div>
          );
        case RelationType.Unserved:
          return <div className={css.unserved} />;
        default:
          return <></>;
      }
    } else {
      switch (type) {
        case RelationType.Forbidden:
        case RelationType.Regular:
          return (
            <div
              className={
                type === RelationType.Forbidden
                  ? css.priceInputWithForbiddenRelation
                  : css.priceInput
              }
            >
              {type === RelationType.Forbidden && (
                <ForbiddenRelationIcon row={defaultCellProps.row} lineId={lineId} />
              )}
              <PriceInput
                value={price}
                onValueChanged={handlePriceChange}
                currency={currency}
                isPriceInvalid={isPriceInvalid}
                onFocus={onEnter}
                onBlur={onLeave}
              />
            </div>
          );
        case RelationType.Unserved:
          return <div className={css.unserved} />;
        default:
          return <></>;
      }
    }
  };

  return (
    <DefaultCell {...defaultCellProps}>
      <div className={css.containerCell} onMouseEnter={onEnter} onMouseLeave={onLeave}>
        <div className={css.priceCell}>{getCellContent()}</div>
      </div>
    </DefaultCell>
  );
};

export default React.memo(PriceCell);
