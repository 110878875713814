import * as React from "react";
import FormikCheckbox from "../../../shared/components/formikCheckbox/FormikCheckbox";
import * as css from "../form_field_styles.scss";

const AccessPackageFilter: React.FC = () => (
  <div>
    <div className={css.checkbox}>
      <FormikCheckbox label="Pro" small name="accessPackage.pro" />
    </div>
    <div className={css.checkbox}>
      <FormikCheckbox label="Light" small name="accessPackage.light" />
    </div>
    <div className={css.checkbox}>
      <FormikCheckbox label="View" small name="accessPackage.view" />
    </div>
  </div>
);

export default AccessPackageFilter;
