.tableRow {
  cursor: pointer;
}

.table {
  // Name
  th:nth-child(1),
  td:nth-child(1) {
    width: auto;
  }

  // Biz Region
  th:nth-child(2),
  td:nth-child(2) {
    width: 15%;
  }

  // Integration Type
  th:nth-child(3),
  td:nth-child(3) {
    width: 10%;
  }

  // Partner Tags
  th:nth-child(4),
  td:nth-child(4) {
    width: 30%;
  }
}

.tag {
  margin: 3px;
}
