import { Tag } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { LineStatus } from "../../../../shared/types/schema";

type Props = {
  lineStatus: LineStatus;
};

const LineStatusTag: React.FC<Props> = ({ lineStatus }) => {
  switch (lineStatus) {
    case LineStatus.Draft:
      return (
        <Tag appearance="success" display="outlined" small>
          <FormattedMessage id="line.status.draft" />
        </Tag>
      );
    case LineStatus.Active:
      return (
        <Tag appearance="success" small>
          <FormattedMessage id="line.status.active" />
        </Tag>
      );
    case LineStatus.Inactive:
      return (
        <Tag small>
          <FormattedMessage id="line.status.inactive" />
        </Tag>
      );
    default:
      return <></>;
  }
};

export default LineStatusTag;
