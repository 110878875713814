import { parse } from "date-fns";
import { TimeFormat } from "../../../../shared/components/timeInput/types";
import areTimesOverlapping from "../../../../shared/helpers/timeComparator/areTimesOverlapping";
import getTimeFormatString from "../../../../shared/helpers/timeFormatter/getTimeFormatString";

export default (
  currentIndex: number,
  currentStart: Date,
  currentEnd: Date,
  openingHoursPerDay: Array<{
    weekday?: string | undefined;
    start?: string | undefined;
    end?: string | undefined;
  }>,
  timeFormat: TimeFormat
) => {
  const overlappingEntries: Array<number> = [];

  let nextIndex = currentIndex + 1;

  while (nextIndex < openingHoursPerDay.length) {
    const nextEntryStart = openingHoursPerDay[nextIndex].start;
    const nextEntryEnd = openingHoursPerDay[nextIndex].end;

    if (nextEntryStart && nextEntryEnd) {
      const formatString = getTimeFormatString(timeFormat);
      const nextEntryStartDate = parse(
        nextEntryStart,
        formatString,
        new Date(Date.now())
      );
      const nextEntryEndDate = parse(nextEntryEnd, formatString, new Date(Date.now()));

      if (
        areTimesOverlapping(
          currentStart,
          currentEnd,
          nextEntryStartDate,
          nextEntryEndDate
        )
      ) {
        overlappingEntries.push(nextIndex);
      }
    }
    nextIndex++;
  }

  return overlappingEntries;
};
