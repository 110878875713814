import * as React from "react";
import { useParams } from "react-router-dom";
import LineDetail from "../../line/lineDetail/LineDetail";
import LineScheduleList from "../../schedule/lineScheduleList/LineScheduleList";
import Breadcrumbs from "../../shared/components/breadcrumbs/Breadcrumbs";
import Layout from "../../ui/Layout";

const ShowLineDetail: React.FC = () => {
  const { lineId } = useParams();

  return (
    <>
      <Layout>
        <Breadcrumbs lineId={lineId!} />
      </Layout>
      <Layout useFullscreen noTopPadding clearLeft>
        <LineDetail lineId={lineId!} />
      </Layout>
      <Layout noTopPadding>
        <LineScheduleList showHeaderContent lineId={lineId!} />
      </Layout>
    </>
  );
};

export default ShowLineDetail;
