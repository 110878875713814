import { Icon, IconArrowBigRight, IconSwitch } from "@flixbus/honeycomb-icons-react";
import { Tooltip } from "@flixbus/honeycomb-react";
import cx from "classnames";
import * as React from "react";
import { PriceDirection, Station } from "../../../../shared/types/schema";
import DefaultCell, { DefaultCellProps } from "../defaultCell/DefaultCell";
import * as css from "./StationCell.scss";

type Props = {
  name: Station["name"];
  stationCode: Station["code"];
  highlight: boolean;
  isVertical: boolean;
  priceDirection?: PriceDirection;
} & DefaultCellProps;

const StationCell: React.FC<Props> = ({
  name,
  stationCode,
  highlight,
  isVertical,
  priceDirection,
  ...defaultCellProps
}) => {
  return (
    <DefaultCell {...defaultCellProps}>
      <div className={css.wrapper}>
        <Tooltip
          id="station-content-tooltip"
          content={`${stationCode} - ${name}`}
          position={isVertical ? undefined : "bottom"} // position undefined will per default render the tooltip on the right
          openOnHover
          size="content-fit"
        >
          <div className={highlight ? css.highlight : ""} data-id="tooltip-child">
            {!isVertical && highlight && (
              <div className={css.icon}>
                {priceDirection === PriceDirection.UniDirectional ? (
                  <Icon size={4} InlineIcon={IconArrowBigRight} />
                ) : (
                  <Icon size={4} InlineIcon={IconSwitch} />
                )}
              </div>
            )}
            <div
              className={cx(
                css.text,
                isVertical ? css.vertical : css.horizontal,
                !isVertical && highlight && css.highlightedText
              )}
              data-id="station-text"
            >
              <b>{name}</b>
            </div>
          </div>
        </Tooltip>
      </div>
    </DefaultCell>
  );
};

export default React.memo(StationCell);
