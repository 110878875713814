import { Tooltip } from "@flixbus/honeycomb-react";
import cx from "classnames";
import * as React from "react";
import { useIntl } from "react-intl";
import { DisplayedScheduleStatus, Schedule } from "../../types/schema";
import ScheduleStatusTag from "../scheduleStatusTag/ScheduleStatusTag";
import ScheduleStatusTagContent from "../scheduleStatusTagContent/ScheduleStatusTagContent";
import * as css from "./ScheduleStatusTagTooltip.scss";

export type Props = {
  scheduleId: Schedule["id"];
  displayedScheduleStatus: DisplayedScheduleStatus;
  isScheduleOutdated: boolean;
  isTagSmall?: boolean;
};

const ScheduleStatusTagTooltip: React.FC<Props> = ({
  scheduleId,
  displayedScheduleStatus,
  isScheduleOutdated,
  isTagSmall = false,
}) => {
  const { formatMessage } = useIntl();
  return (
    <Tooltip
      id={`schedule-${scheduleId}-status-tooltip`}
      active={
        displayedScheduleStatus === DisplayedScheduleStatus.PartiallyOnSale
          ? undefined
          : false
      }
      content={formatMessage({ id: "scheduleStatus.tag.tooltip.text" })}
      openOnHover
      extraClasses={css.tooltip}
    >
      <div>
        <ScheduleStatusTag
          status={displayedScheduleStatus}
          isOutdated={isScheduleOutdated}
          isSmall={isTagSmall}
          extraClasses={cx(isTagSmall ? css.tagSmall : css.tag)}
        >
          <ScheduleStatusTagContent status={displayedScheduleStatus} />
        </ScheduleStatusTag>
      </div>
    </Tooltip>
  );
};

export default ScheduleStatusTagTooltip;
