import { Feature, hasUserPermission } from "@flixbus-phx/marketplace-common";
import { LineEditableFields } from "../types";

const getEditableLineFields = (
  lineIsReadOnly: boolean,
  lineIsDeletable: boolean,
  isCreation: boolean
): LineEditableFields => {
  return {
    generalSettings: {
      networkDescription:
        !lineIsReadOnly && hasUserPermission(Feature.EDIT_LINE_DESCRIPTION_UNPUBLISHED),
      cooperationType: {
        isEditable:
          !lineIsReadOnly &&
          hasUserPermission(Feature.EDIT_LINE_CONTRACT_MODEL_UNPUBLISHED),
        isShown: hasUserPermission(Feature.EDIT_LINE_CONTRACT_MODEL_UNPUBLISHED),
      },
      brandId: hasUserPermission(Feature.EDIT_LINE_BRAND),
      cutOffSales: {
        isEditable: hasUserPermission(Feature.EDIT_LINE_CUT_OFF_SALES),
        isShown: hasUserPermission(Feature.EDIT_LINE_CUT_OFF_SALES) || !isCreation,
      },
      meansOfTransport:
        hasUserPermission(Feature.EDIT_LINE_MEANS_OF_TRANSPORT_UNPUBLISHED) &&
        !lineIsReadOnly,
      customNetworkCode:
        hasUserPermission(Feature.EDIT_LINE_CODE_UNPUBLISHED) &&
        !lineIsReadOnly &&
        isCreation,
    },
    capacityAndAmenities: {
      amenities: hasUserPermission(Feature.EDIT_LINE_AMENITIES),
      defaultCirculationId: hasUserPermission(Feature.EDIT_LINE_DEFAULT_CIRCULATION),
      minSeatCapacity: hasUserPermission(Feature.EDIT_LINE_MINIMUM_SEAT_CAPACITY),
    },
    forbiddenRelations: {
      forbiddenRelationsAdd: hasUserPermission(Feature.EDIT_LINE_FORBIDDEN_RELATIONS_ADD),
      forbiddenRelationsDelete: hasUserPermission(
        Feature.EDIT_LINE_FORBIDDEN_RELATIONS_DELETE
      ),
    },
    submitButton: true,
    deleteButton: {
      isEditable: lineIsDeletable && hasUserPermission(Feature.DELETE_LINE_UNPUBLISHED),
      isShown: hasUserPermission(Feature.DELETE_LINE_UNPUBLISHED),
    },
  };
};

export default getEditableLineFields;
