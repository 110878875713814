import cx from "classnames";
import * as React from "react";
import { getCalendarPositionForMonthlyView } from "../../helpers/getCalendarPosition/getCalendarPosition";
import { OverlapPosition } from "../../types";
import * as css from "./Overlap.scss";

type Props = {
  position: OverlapPosition;
};

const Overlap: React.FC<Props> = ({ position }) => {
  return (
    <div
      data-id="overlap"
      className={cx(
        css.wrapper,
        css.overlap,
        position.startDateInPast && css.cutOffLeft,
        position.endDateInFuture && css.cutOffRight
      )}
      style={getCalendarPositionForMonthlyView(position.colStart, position.colEnd)}
    />
  );
};

export default Overlap;
