import { makeVar } from "@apollo/client";
import { ImportanceClass, IsoCountry } from "../../shared/types/schema";

export type CitiesFilterVar = {
  name: string | null;
  country: IsoCountry | null;
  importanceClass: ImportanceClass | null;
  published: boolean | null;
};

const citiesFilterVar = makeVar<CitiesFilterVar>({
  name: null,
  country: null,
  importanceClass: null,
  published: null,
});

export default citiesFilterVar;
