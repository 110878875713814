import { Icon, IconDelete, IconAttention } from "@flixbus/honeycomb-icons-react";
import {
  Button,
  DataTable,
  DataTableHead,
  DataTableRow,
  Heading,
  Tooltip,
} from "@flixbus/honeycomb-react";
import { localizeDate } from "@flixbus-phx/marketplace-common";
import { useField } from "formik2";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ConfirmationPopup from "../../../../shared/components/confirmationPopup/ConfirmationPopup";
import { selectedBrandVar } from "../../../../state/reactiveVariables/reactiveVariables";
import createNotificationForBrandUpdate from "../../helpers/createNotificationForBrandUpdate/createNotificationForBrandUpdate";
import { Brand } from "../../types";
import * as css from "./BrandsInputTable.scss";

type Props = {
  readOnly: boolean;
  isInUpdateFlow: boolean;
};

const BrandsInputTable: React.FC<Props> = ({ readOnly, isInUpdateFlow }) => {
  const { formatMessage } = useIntl();
  const [, meta, helper] = useField<Array<Brand>>("brands");
  const [brandForRemoval, setBrandForRemoval] = React.useState<Brand["id"]>("");

  const headerCells = [
    formatMessage({ id: "partnerForm.brandsAndPolicies.tableHeader.name" }),
    formatMessage({ id: "partnerForm.brandsAndPolicies.tableHeader.created" }),
    formatMessage({ id: "general.updated" }),
    formatMessage({ id: "general.policies" }),
    formatMessage({ id: "general.action" }),
  ];

  const selectBrand = (brandId: Brand["id"]) => {
    selectedBrandVar(brandId);
  };

  // Removes Action Column if Component is read only
  if (readOnly) {
    headerCells.pop();
  }

  const rows = meta.value.map((brand) => {
    const removeButton = (
      <Button
        display="square"
        aria-label={formatMessage({ id: "partnerForm.brandsAndPolicies.brand.remove" })}
        disabled={!brand.canBeRemovedFromPartner}
        onClick={() => setBrandForRemoval(brand.id)}
      >
        <Icon InlineIcon={IconDelete} />
      </Button>
    );

    const row = {
      key: brand.id,
      cells: [
        brand.name,
        brand.created ? localizeDate(new Date(brand.created)) : "",
        brand.updated ? localizeDate(new Date(brand.updated)) : "",
        <Button
          onClick={() => {
            selectBrand(brand.id);
          }}
        >
          <FormattedMessage id="partnerForm.brandsAndPolicies.viewPolicies" />
        </Button>,
        brand.canBeRemovedFromPartner ? (
          removeButton
        ) : (
          <Tooltip
            id="remove-brand-tooltip"
            openOnHover
            position="left"
            wrapAround="block"
            danger
            content={
              <div>
                <b>
                  <FormattedMessage id="partnerForm.brandsAndPolicies.brand.canNotBeRemovedTooltip" />
                </b>
              </div>
            }
          >
            <div>{removeButton}</div>
          </Tooltip>
        ),
      ],
    };

    // Removes Action Column if Component is read only
    if (readOnly) {
      row.cells.pop();
    }

    return row;
  });

  return (
    <div className={css.container}>
      {meta.value.length ? (
        <DataTable
          headers={
            <DataTableHead>
              {headerCells.map((cell, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <th key={index}>{cell}</th>
              ))}
            </DataTableHead>
          }
          extraClasses={css.dataTable}
        >
          {rows.map((row) => (
            <DataTableRow key={row.key}>
              {row.cells.map((cell, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <td key={index}>{cell}</td>
              ))}
            </DataTableRow>
          ))}
        </DataTable>
      ) : (
        <div className={css.noResultsTable}>
          <Heading size={3} extraClasses={css.heading}>
            <FormattedMessage id="partnerForm.brandsAndPolicies.noBrands.title" />
          </Heading>
          <p className={css.noBrandsText}>
            <FormattedMessage id="partnerForm.brandsAndPolicies.noBrands.text" />
          </p>
        </div>
      )}
      <ConfirmationPopup
        active={!!brandForRemoval.length}
        titleIcon={IconAttention}
        titleText={formatMessage({
          id: "partnerForm.brandsAndPolicies.confirmationPopup.titleText",
        })}
        confirmButtonText={formatMessage({
          id: "partnerForm.brandsAndPolicies.confirmationPopup.buttonText",
        })}
        onCancel={() => setBrandForRemoval("")}
        onConfirm={() => {
          helper.setValue(meta.value.filter((brand) => brand.id !== brandForRemoval));
          createNotificationForBrandUpdate(isInUpdateFlow, true);
          setBrandForRemoval("");
        }}
      >
        <></>
      </ConfirmationPopup>
    </div>
  );
};

export default BrandsInputTable;
