@import "variables";

.popupBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
}

.directionWrapper {
  width: 75%;
}

.dividerWrapper {
  width: 100%;
  padding-top: $spacing-3;
  padding-bottom: $spacing-3;
}

.relationSelectWrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 47% 6% 47%;

  > :first-child {
    padding-right: $spacing-2;
  }

  > :last-child {
    padding-left: $spacing-2;
  }
}

.directionIconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 27px;
}

.icon {
  width: 24px !important;
  height: 24px !important;
}
