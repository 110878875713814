import {
  addNotification,
  formatErrorMessage,
  NotificationType,
} from "@flixbus-phx/marketplace-common";
import * as React from "react";
import getUserPreferredTimeFormat from "../../../../shared/helpers/userPreferredTimeFormat/userPreferredTimeFormat";
import {
  FindScheduleWithTripsAndStationsQuery,
  TripFragment,
  UpdateTripMutationVariables,
  useCopyTripMutation,
  useDeleteTripMutation,
  useDeleteTripStationTimeMutation,
  useShiftTripMutation,
  useUpdateTripMutation,
  useUpdateTripStationArrivalOffsetDaysMutation,
  useUpdateTripStationArrivalTimeMutation,
  useUpdateTripStationDepartureTimeMutation,
} from "../../api/operations.generated";
import {
  addTripToCache,
  deleteTripFromCache,
} from "../../helpers/cacheHandler/cacheHandler";
import generateStationTimeCellsArray from "../../helpers/generateStationTimeCellsArray/generateStationTimeCellsArray";
import {
  optimisticResponseForUpdateTripStationArrivalTime,
  optimisticResponseForDeleteStationTime,
} from "../../helpers/optimisticResponse/optimisticResponse";
import {
  HandleTripStationTimeDelete,
  HandleUpdateTripStationArrivalOffsetDays,
  HandleUpdateTripStationArrivalTime,
  HandleUpdateTripStationDepartureTime,
} from "../../types";
import TimetableColumn from "../../ui/timetableColumn/TimetableColumn";
import TripDurationCell from "../../ui/tripDurationCell/TripDurationCell";
import TripHeader from "../../ui/tripHeader/TripHeader";
import * as css from "./TripColumn.scss";

type Props = {
  scheduleId: FindScheduleWithTripsAndStationsQuery["findSchedule"]["id"];
  trip: TripFragment;
  columnPosition: number;
  readOnly: boolean;
};

const TripColumn: React.FC<Props> = ({ scheduleId, trip, columnPosition, readOnly }) => {
  const [updateTrip] = useUpdateTripMutation({
    onError: (error) => {
      addNotification({
        message: `Sorry, we are unable to update Trip: ${formatErrorMessage(
          error.message
        )}`,
        type: NotificationType.Danger,
      });
    },
    ignoreResults: true,
  });
  const handleTripChange = (
    key: keyof UpdateTripMutationVariables,
    value: TripFragment["serviceDays"] | TripFragment["number"]
  ) => {
    updateTrip({
      variables: { id: trip.id, [key]: value },
    });
  };

  const [copyTrip] = useCopyTripMutation({
    update: (cache, { data }) => {
      addTripToCache(cache, data?.copyTrip, scheduleId);
    },
    variables: { id: trip.id },
    onError: (error) => {
      addNotification({
        message: `Sorry, we are unable to copy the trip: ${formatErrorMessage(
          error.message
        )}`,
        type: NotificationType.Danger,
      });
    },
  });

  const [deleteTrip] = useDeleteTripMutation({
    update: (cache, { data }) => {
      deleteTripFromCache(cache, data, scheduleId, trip.id);
    },
    variables: { id: trip.id },
    onError: (error) => {
      addNotification({
        message: `Sorry, we are unable to delete the Trip: ${formatErrorMessage(
          error.message
        )}`,
        type: NotificationType.Danger,
      });
    },
  });

  const [shiftTrip] = useShiftTripMutation({
    onError: (error) => {
      addNotification({
        message: `Sorry, we are unable to shift time of this Trip: ${formatErrorMessage(
          error.message
        )}`,
        type: NotificationType.Danger,
      });
    },
  });
  const handleTripShift = (departureTime: string) => {
    shiftTrip({
      variables: { id: trip.id, departureTime },
    });
  };

  const [updateTripStationArrivalTime, { loading: updateTripStationArrivalTimeLoading }] =
    useUpdateTripStationArrivalTimeMutation({
      onError: (error) => {
        addNotification({
          message: `Sorry, we are unable to change the arrival time: ${formatErrorMessage(
            error.message
          )}`,
          type: NotificationType.Danger,
        });
      },
    });

  const handleTripStationArrivalTimeChange: HandleUpdateTripStationArrivalTime = (
    stationId,
    time
  ) => {
    updateTripStationArrivalTime({
      variables: { tripId: trip.id, stationId, arrivalTime: time },
      optimisticResponse: optimisticResponseForUpdateTripStationArrivalTime(
        trip,
        stationId,
        time
      ),
    });
  };

  const [
    updateTripStationDepartureTime,
    { loading: updateTripStationDepartureTimeLoading },
  ] = useUpdateTripStationDepartureTimeMutation({
    onError: (error) => {
      addNotification({
        message: `Sorry, we are unable to change departure time: ${formatErrorMessage(
          error.message
        )}`,
        type: NotificationType.Danger,
      });
    },
  });
  const handleTripStationDepartureTimeChange: HandleUpdateTripStationDepartureTime = (
    stationId,
    time
  ) => {
    updateTripStationDepartureTime({
      variables: { tripId: trip.id, stationId, departureTime: time },
    });
  };

  const [
    updateTripStationArrivalOffsetDaysMutation,
    { loading: updateTripStationArrivalOffsetDaysLoading },
  ] = useUpdateTripStationArrivalOffsetDaysMutation({
    onError: (error) => {
      addNotification({
        message: `Sorry, we are unable to move Arrival to the next day: ${formatErrorMessage(
          error.message
        )}`,
        type: NotificationType.Danger,
      });
    },
  });
  const handleUpdateTripStationArrivalOffsetDaysMutation: HandleUpdateTripStationArrivalOffsetDays =
    (stationId, offset) => {
      updateTripStationArrivalOffsetDaysMutation({
        variables: { tripId: trip.id, stationId, arrivalOffsetDays: offset },
      });
    };

  const [deleteTripStationTime, { loading: deleteTripStationTimeLoading }] =
    useDeleteTripStationTimeMutation({
      onError: (error) => {
        addNotification({
          message: `Sorry, we are unable to remove entries for this station: ${formatErrorMessage(
            error.message
          )}`,
          type: NotificationType.Danger,
        });
      },
    });

  const handleTripStationTimeDelete: HandleTripStationTimeDelete = (stationId) => {
    deleteTripStationTime({
      variables: { tripId: trip.id, stationId },
      optimisticResponse: optimisticResponseForDeleteStationTime(stationId, trip),
    });
  };

  const getTripDeparture = (): string => {
    const stationTime = trip.stationTimes.find((st) => st.departureTime != null);

    return (stationTime && stationTime.departureTime) || "";
  };

  const timeFormat = getUserPreferredTimeFormat();

  return (
    <TimetableColumn
      content={[
        <TripHeader
          tripNumber={trip.number}
          tripDepartureTime={getTripDeparture()}
          serviceDays={trip.serviceDays}
          readOnly={readOnly}
          onDeleteTrip={deleteTrip}
          onCopyTrip={copyTrip}
          onShiftTrip={handleTripShift}
          onServiceDaysChanged={(value) => handleTripChange("serviceDays", value)}
          onNumberChanged={(value) => handleTripChange("number", value)}
        />,
        ...generateStationTimeCellsArray(
          trip,
          handleTripStationDepartureTimeChange,
          handleTripStationArrivalTimeChange,
          handleTripStationTimeDelete,
          handleUpdateTripStationArrivalOffsetDaysMutation,
          timeFormat,
          readOnly
        ),
        <TripDurationCell
          totalTripDuration={trip.totalTripDuration}
          estimatedTripDuration={trip.estimatedTripDuration}
          isLoadingData={
            updateTripStationDepartureTimeLoading ||
            updateTripStationArrivalTimeLoading ||
            updateTripStationArrivalOffsetDaysLoading ||
            deleteTripStationTimeLoading
          }
        />,
      ]}
      colPosition={columnPosition}
      rowPosition={1}
      extraClasses={css.trip}
      childClasses={css.cells}
    />
  );
};

export default TripColumn;
