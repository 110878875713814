import { DataTable, DataTableRow, Heading } from "@flixbus/honeycomb-react";
import { hasUserPermission, Feature } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { AccessPackage, Partner, User } from "../../shared/types/schema";
import { useFindUsersQuery } from "./api/operations.generated";
import tableHeader from "./helper/tableHeader/tableHeader";
import UserRoleAssignmentForm from "./ui/userRoleAssignmentForm/UserRoleAssignmentForm";
import UserRoleAssignmentLoading from "./ui/userRoleAssignmentLoading/UserRoleAssignmentLoading";
import UserRoleAssignmentWarning from "./ui/userRoleAssignmentWarning/UserRoleAssignmentWarning";
import UserRoleTags from "./ui/userRoleTags/UserRoleTags";

type Props = {
  partnerId?: Partner["id"];
  accessPackage: AccessPackage;
  isEditable: boolean;
};

const UserRoleAssignment: React.FC<Props> = ({
  partnerId,
  accessPackage,
  isEditable,
  children,
}) => {
  const { formatMessage } = useIntl();
  const [selectedUser, setSelectedUser] = React.useState<User>();

  const { data, loading, error } = useFindUsersQuery({
    variables: partnerId ? { partnerId } : undefined,
  });

  const getContent = () => {
    if (loading) {
      return <UserRoleAssignmentLoading />;
    }

    if (error) {
      return <UserRoleAssignmentWarning hasError />;
    }

    if (data) {
      if (data.findUsers.length) {
        const hasPermissionToView = hasUserPermission(Feature.VIEW_PARTNER_TEST_USERS);
        const usersDisplayed = data.findUsers.filter((user) => {
          return hasPermissionToView || !user.displayName.startsWith("PHX");
        });

        const rows = usersDisplayed.map((user) => {
          return {
            key: user.id,
            onClick: () => {
              if (isEditable) {
                setSelectedUser(user);
              }
            },
            cells: [
              user.displayName,
              user.mail,
              accessPackage === AccessPackage.View ? (
                formatMessage({ id: "accessManagement.viewOnly" })
              ) : (
                <UserRoleTags roles={user.roles} />
              ),
            ],
            style: { cursor: isEditable ? "pointer" : "auto" },
          };
        });

        return (
          <>
            <DataTable headers={tableHeader}>
              {rows.map((row) => (
                <DataTableRow key={row.key} onClick={row.onClick} style={row.style}>
                  {row.cells.map((cell, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <td key={index}>{cell}</td>
                  ))}
                </DataTableRow>
              ))}
            </DataTable>
            {selectedUser !== undefined && accessPackage !== AccessPackage.View && (
              <UserRoleAssignmentForm
                closePopup={() => setSelectedUser(undefined)}
                userId={selectedUser.id}
                userName={selectedUser.displayName}
                userMail={selectedUser.mail}
                defaultValues={selectedUser.roles}
                accessPackage={accessPackage}
              />
            )}
          </>
        );
      }
      return <UserRoleAssignmentWarning hasError={false} />;
    }

    return <></>;
  };

  return (
    <>
      <Heading size={3}>
        <FormattedMessage id="partnerForm.tab.team" />
      </Heading>

      {getContent()}

      {children}
    </>
  );
};

export default UserRoleAssignment;
