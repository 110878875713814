import { Validation400, Validation400ErrorReasons } from "../typeguards/typeguards";

// 1MB in Bytes
export const MAX_FILE_SIZE = 1048576;

export default (file: File): Validation400 => {
  const errors: Validation400 = [];

  if (file.type !== "text/csv") {
    errors.push(Validation400ErrorReasons.INCORRECT_FILE_TYPE);
  }

  if (file.size > MAX_FILE_SIZE) {
    errors.push(Validation400ErrorReasons.INCORRECT_FILE_SIZE);
  }

  return errors;
};
