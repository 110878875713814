import { formatMessage } from "@flixbus-phx/marketplace-common";
import { LengthUnit, TimeUnit, WeightUnit } from "../../types/schema";

const getUnitText = (unit: LengthUnit | TimeUnit | WeightUnit): string => {
  switch (unit) {
    case TimeUnit.Minutes:
      return formatMessage("unit.minutes");
    case TimeUnit.Hours:
      return formatMessage("general.hours");
    case TimeUnit.Days:
      return formatMessage("general.days");
    case WeightUnit.Kilograms:
      return formatMessage("unit.kilograms");
    case WeightUnit.Pounds:
      return formatMessage("unit.pounds");
    case LengthUnit.Centimeters:
      return formatMessage("unit.centimeters");
    case LengthUnit.Inches:
      return formatMessage("unit.inches");
    default:
      return formatMessage("general.notAvailable");
  }
};

export default getUnitText;
