@import "variables";

.subHeadline {
  color: $grayscale-90-color;
}

.fieldset {
  margin-top: $spacing-2;
  margin-bottom: $spacing-4;
}
