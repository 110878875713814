import { useField } from "formik2";
import * as React from "react";
import { useIntl } from "react-intl";
import { AmenityInput, AmenityType } from "../../../../shared/types/schema";
import AmenitiesSelectorRow from "../amenitiesSelectorRow/AmenitiesSelectorRow";
import * as css from "./AmenitiesSelector.scss";

type Props = {
  readOnly?: boolean;
};

// FIXME: Replace with Honeycomb ChoiceWrapper the next time you touch this file!
const AmenitiesSelector: React.FC<Props> = ({ readOnly }) => {
  const { formatMessage } = useIntl();
  const [, meta, helper] = useField<Array<AmenityInput>>("amenities");
  const [highlightCount, setHighlightCount] = React.useState(0);

  React.useEffect(() => {
    setHighlightCount(
      meta.value.filter((element) => element.highlighted === true).length
    );
  }, [meta]);

  const handleAmenitySelect = (amenity: AmenityType, select: boolean) => {
    if (select) {
      helper.setValue([...meta.value, { amenityType: amenity, highlighted: false }]);
    } else {
      helper.setValue(meta.value.filter((element) => element.amenityType !== amenity));
    }
  };

  const handleAmenityHighlight = (amenity: AmenityType, highlight: boolean) => {
    const newValues = [
      ...meta.value.filter((element) => element.amenityType !== amenity),
      { amenityType: amenity, highlighted: highlight },
    ];
    if (highlight) {
      if (highlightCount < 3) {
        helper.setValue(newValues);
      }
    } else {
      helper.setValue(newValues);
    }
  };

  return (
    <div>
      <div className={css.highlightCounter}>
        {`${formatMessage({ id: "lineForm.amenities.promoted" })} (${highlightCount}/3)`}
      </div>
      <div className={css.wrapper}>
        {Object.values(AmenityType).map((amenity) => (
          <AmenitiesSelectorRow
            key={amenity}
            amenity={amenity}
            readOnly={readOnly}
            isSelected={!!meta.value.find((element) => element.amenityType === amenity)}
            isHighlighted={
              !!meta.value.find((element) => element.amenityType === amenity)?.highlighted
            }
            hideHighlightIcon={highlightCount >= 3}
            onSelect={(select) => {
              handleAmenitySelect(amenity, select);
            }}
            onHighlight={(highlight) => {
              handleAmenityHighlight(amenity, highlight);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default AmenitiesSelector;
