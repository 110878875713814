@import "variables";

.checkboxWrapper {
  height: $spacing-6;
  display: flex;
  align-items: center;
}

.checkbox {
  color: $grayscale-90-color;
}

.checkboxReadonly {
  [class^="hcr-checkbox__label"] {
    opacity: unset;
    &::before,
    &::after {
      opacity: 0.5;
    }
  }
}
