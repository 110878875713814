import ReactUtils from '../../../packages/react-utils/react-utils.js';
import 'react';

var headerUserWidgetSubMenuValidator = function () {
  return function (_a) {
    var children = _a.children,
      subMenu = _a.subMenu;
    if (subMenu) {
      if (!children || children && !children.trim()) {
        return new Error('A \'children\' text is required when a subMenu is present at \'HeaderUserWidget\' component.');
      }
      if (!ReactUtils.isOfType(subMenu, 'HeaderSubNavigation')) {
        return new Error("The 'subMenu' prop at 'HeaderUserWidget' only accepts a\n        'HeaderSubNavigation' component but received '".concat(ReactUtils.getElementName(subMenu), "'."));
      }
    }
    return null;
  };
};

export { headerUserWidgetSubMenuValidator };
