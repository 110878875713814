import {
  Feature,
  formatMessage,
  hasUserPermission,
  mpcPathnames as pathnames,
} from "@flixbus-phx/marketplace-common";
import buildPath from "../../../../../routes/buildPath/buildPath";
import { FindScheduleQuery, LineFragment } from "../../api/operations.generated";
import { BreadcrumbContent } from "../../types";

export const getLineBreadcrumbContent = (line: LineFragment): BreadcrumbContent => {
  const breadcrumbData = [];
  breadcrumbData[0] = {
    content: hasUserPermission(Feature.VIEW_PARTNER_LIST)
      ? `${formatMessage("general.partner")}: ${line.partner.name}`
      : formatMessage("general.home"),
    path: hasUserPermission(Feature.VIEW_PARTNER_LIST)
      ? buildPath.showPartnerDetail(line.partner.id)
      : pathnames.userHome,
  };
  breadcrumbData[1] = {
    content: `${formatMessage("general.line")}: ${line.networkCode}`,
    path: buildPath.showLineDetail(line.id),
  };
  return breadcrumbData;
};

export const getScheduleBreadcrumbContent = (
  schedule: FindScheduleQuery["findSchedule"]
): BreadcrumbContent => {
  return [
    ...getLineBreadcrumbContent(schedule.line),
    {
      content: `${formatMessage("general.schedule")}: ${schedule.name}`,
      path: buildPath.showScheduleDetail(schedule.id),
    },
  ];
};
