@import "variables";

.cancelButton {
  text-align: right;
  margin-top: $spacing-8;
  margin-bottom: $spacing-4;
}

.nonFormikButtons {
  margin-top: $spacing-8;
  margin-bottom: $spacing-4;
  display: flex;
  justify-content: flex-end;
}

.nonFormikDeleteButton {
  flex-grow: 2;
}
