import * as React from "react";
import CitiesAndStationsTab from "../../station/citiesAndStationsTab/CitiesAndStationsTab";
import Layout from "../../ui/Layout";

const ShowCitiesAndStations: React.FC = () => {
  return (
    <Layout>
      <CitiesAndStationsTab />
    </Layout>
  );
};

export default ShowCitiesAndStations;
