import cx from "classnames";
import * as React from "react";
import * as css from "./DefaultCell.scss";

export type DefaultCellProps = {
  row: number;
  column: number;
  removeBorderBottom?: boolean;
  removeBorderLeft?: boolean;
  horizontalSticky?: boolean;
  verticalSticky?: boolean;
};

const DefaultCell: React.FC<DefaultCellProps> = ({
  row,
  column,
  removeBorderBottom,
  removeBorderLeft,
  children,
  horizontalSticky,
  verticalSticky,
}) => {
  return (
    <div
      className={cx(
        css.cell,
        row % 2 === 0 ? css.greyCell : css.whiteCell,
        removeBorderBottom && css.removeBorderBottom,
        removeBorderLeft && css.removeBorderLeft,
        horizontalSticky && css.horizontalSticky,
        verticalSticky && css.verticalSticky
      )}
      style={{ gridColumn: `${column} / ${column + 1}`, gridRow: `${row} / ${row + 1}` }}
    >
      {children}
    </div>
  );
};

export default React.memo(DefaultCell);
