import {
  eachDayOfInterval,
  eachMonthOfInterval,
  endOfMonth,
  endOfYear,
  startOfMonth,
  startOfYear,
} from "date-fns";
import { CalendarIntervallType } from "../../types";

export default (period: Date, intervalType: CalendarIntervallType) => {
  return intervalType === CalendarIntervallType.YEAR
    ? eachMonthOfInterval({
        start: startOfYear(period),
        end: endOfYear(period),
      })
    : eachDayOfInterval({
        start: startOfMonth(period),
        end: endOfMonth(period),
      });
};
