import * as React from "react";
import StationCellLabel from "../../ui/stationCellLabel/StationCellLabel";

const generateStationCellLabelsArray = (numberOfLabels: number): Array<JSX.Element> => {
  const stationTimeCellLabels: Array<JSX.Element> = [];

  for (let i = 0; i < numberOfLabels; i++) {
    stationTimeCellLabels.push(
      <StationCellLabel
        showArrival={i > 0}
        showDeparture={i === 0 || i < numberOfLabels - 1}
      />
    );
  }

  return stationTimeCellLabels;
};

export default generateStationCellLabelsArray;
