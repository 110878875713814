import * as React from "react";
import sortSchedules from "../../helpers/sortSchedules/sortSchedules";
import { CalendarIntervallType, ScheduleFromLineQuery } from "../../types";
import ScheduleCalendar from "../scheduleCalendar/ScheduleCalendar";
import ScheduleCalendarControl from "../scheduleCalendarControl/ScheduleCalendarControl";
import ScheduleCalendarPeriodSelect from "../scheduleCalendarPeriodSelect/ScheduleCalendarPeriodSelect";
import ScheduleListItem from "../scheduleListItem/ScheduleListItem";
import * as css from "./ScheduleOverview.scss";

type Props = {
  schedules: Array<ScheduleFromLineQuery>;
};

const ScheduleOverview: React.FC<Props> = ({ schedules }) => {
  const [selectedPeriod, setSelectedPeriod] = React.useState(new Date());
  const [selectedIntervalType, setSelectedIntervalType] = React.useState(
    CalendarIntervallType.YEAR
  );

  const sortedSchedules = sortSchedules(schedules);

  return (
    <>
      <div className={css.topGrid}>
        <ScheduleCalendarPeriodSelect
          selectedIntervalType={selectedIntervalType}
          onIntervallTypeChange={(selection) => setSelectedIntervalType(selection)}
        />
        <ScheduleCalendarControl
          onPeriodChange={(date) => setSelectedPeriod(date)}
          selectedPeriod={selectedPeriod}
          selectedIntervalType={selectedIntervalType}
        />
      </div>
      <div className={css.bottomGrid} data-id="schedule-list">
        <div className={css.colLeft}>
          <div className={css.cell} />

          {sortedSchedules.map((schedule) => {
            return (
              <React.Fragment key={schedule.id}>
                <ScheduleListItem schedule={schedule} />

                {schedule.conceptChanges.map((conceptChange) => {
                  return (
                    <ScheduleListItem
                      key={conceptChange.id}
                      schedule={conceptChange}
                      isConceptChange
                    />
                  );
                })}
              </React.Fragment>
            );
          })}
        </div>
        <div className={css.colRight}>
          <ScheduleCalendar
            onPeriodChange={(date) => {
              setSelectedIntervalType(CalendarIntervallType.MONTH);
              setSelectedPeriod(date);
            }}
            originalSchedules={sortedSchedules}
            selectedIntervalType={selectedIntervalType}
            selectedPeriod={selectedPeriod}
          />
        </div>
      </div>
    </>
  );
};

export default ScheduleOverview;
