import { FormRow } from "@flixbus/honeycomb-react";
import cx from "classnames";
import * as React from "react";
import * as css from "./CustomFormRow.scss";

type Props = {
  small?: boolean;
};

const CustomFormRow: React.FC<Props> = ({ children, small }) => {
  return (
    <FormRow extraClasses={cx(small ? css.formRowSmall : css.formRow)}>
      {children}
    </FormRow>
  );
};

export default CustomFormRow;
