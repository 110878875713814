import { Button } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import * as css from "./ExchangeStationButton.scss";

type Props = {
  originalStationId: string;
  exchangeStationId: string;
  scheduleStationIds: Array<string>;
  scheduleReadOnly: boolean;
  onOpenPopup: () => void;
};

const ExchangeStationButton: React.FC<Props> = ({
  originalStationId,
  exchangeStationId,
  scheduleStationIds,
  scheduleReadOnly,
  onOpenPopup,
}) => {
  return (
    <div className={css.buttonWrapper}>
      {!scheduleReadOnly &&
        exchangeStationId !== originalStationId &&
        scheduleStationIds.includes(originalStationId) &&
        !scheduleStationIds.includes(exchangeStationId) && (
          <Button
            appearance="tertiary"
            onClick={() => onOpenPopup()}
            extraClasses={css.button}
          >
            <FormattedMessage id="station.detailPanel.cityStations.exchange.button.label" />
          </Button>
        )}
    </div>
  );
};

export default ExchangeStationButton;
