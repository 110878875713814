// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// This file was automatically generated using npm run codegen
// If you want to alter this file please do so via the above mentioned command
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
import * as Types from "../../../types/schema";

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
const defaultOptions = {} as const;
export type FindNearbyStationsQueryVariables = Types.Exact<{
  latitude: Types.Scalars["String"]["input"];
  longitude: Types.Scalars["String"]["input"];
}>;

export type FindNearbyStationsQuery = {
  __typename?: "Query";
  findNearbyStations: Array<{
    __typename?: "Station";
    id: string;
    name: string;
    code?: string | null;
    countryCode?: string | null;
    latitude?: string | null;
    longitude?: string | null;
    timeZone: { __typename?: "StationTimeZone"; zoneId: string; utcOffset: string };
    city?: { __typename?: "City"; id: string; name: string } | null;
  }>;
};

export const FindNearbyStationsDocument = gql`
  query FindNearbyStations($latitude: String!, $longitude: String!) {
    findNearbyStations(latitude: $latitude, longitude: $longitude) {
      id
      name
      code
      timeZone {
        zoneId
        utcOffset
      }
      city {
        id
        name
      }
      countryCode
      latitude
      longitude
    }
  }
`;

/**
 * __useFindNearbyStationsQuery__
 *
 * To run a query within a React component, call `useFindNearbyStationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindNearbyStationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindNearbyStationsQuery({
 *   variables: {
 *      latitude: // value for 'latitude'
 *      longitude: // value for 'longitude'
 *   },
 * });
 */
export function useFindNearbyStationsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FindNearbyStationsQuery,
    FindNearbyStationsQueryVariables
  > &
    ({ variables: FindNearbyStationsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FindNearbyStationsQuery,
    FindNearbyStationsQueryVariables
  >(FindNearbyStationsDocument, options);
}
export function useFindNearbyStationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FindNearbyStationsQuery,
    FindNearbyStationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FindNearbyStationsQuery,
    FindNearbyStationsQueryVariables
  >(FindNearbyStationsDocument, options);
}
export function useFindNearbyStationsSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    FindNearbyStationsQuery,
    FindNearbyStationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    FindNearbyStationsQuery,
    FindNearbyStationsQueryVariables
  >(FindNearbyStationsDocument, options);
}
export type FindNearbyStationsQueryHookResult = ReturnType<
  typeof useFindNearbyStationsQuery
>;
export type FindNearbyStationsLazyQueryHookResult = ReturnType<
  typeof useFindNearbyStationsLazyQuery
>;
export type FindNearbyStationsSuspenseQueryHookResult = ReturnType<
  typeof useFindNearbyStationsSuspenseQuery
>;
