import { useField } from "formik2";
import * as React from "react";
import { SelectOption } from "../../helpers/reactSelect/types";
import Autocomplete from "../autocomplete/Autocomplete";
import ReadOnlyFormField from "../readOnlyFormField/ReadOnlyFormField";
import getOptionLabel from "./helpers/getOptionLabel/getOptionLabel";

export type FormikAutocompleteProps = {
  name: string;
  label: string;
  placeholder?: string;
  options: Array<SelectOption>;
  noOptionsAction?: { action: () => void; message: string };
  onValueSelected?: (value: SelectOption["value"]) => void;
  initialValue?: SelectOption;
  readOnly?: boolean;
  filterOption?: (option: SelectOption, inputValue: string) => boolean;
  startsOnInputValueLength?: number;
  required?: boolean;
  onInputChange?: (value: string) => void;
};

const FormikAutocomplete: React.FC<FormikAutocompleteProps> = ({
  name,
  label,
  placeholder,
  options,
  noOptionsAction,
  onValueSelected,
  initialValue,
  readOnly,
  filterOption,
  startsOnInputValueLength,
  required,
  onInputChange,
}) => {
  const [, meta, helper] = useField(name);

  if (readOnly) {
    return (
      <ReadOnlyFormField label={label}>
        {getOptionLabel(meta.value, options)}
      </ReadOnlyFormField>
    );
  }

  return (
    <Autocomplete
      label={`${label}${required ? " *" : ""}`}
      placeholder={placeholder}
      options={options}
      onValueSelected={(selectedOption) => {
        if (onValueSelected) {
          onValueSelected(selectedOption.value);
        }
        helper.setValue(selectedOption.value);
      }}
      noOptionsAction={noOptionsAction}
      initialValue={initialValue || options.find((option) => option.value === meta.value)}
      onBlur={() => helper.setTouched(true)}
      error={!!(meta.error && meta.touched)}
      errorMessage={meta.error}
      filterOption={filterOption}
      startsOnInputValueLength={startsOnInputValueLength}
      onInputChange={onInputChange}
    />
  );
};

export default FormikAutocomplete;
