import {
  IconBike,
  IconChild,
  IconDog,
  IconLuggage,
  IconStroller,
  IconWheelchair,
} from "@flixbus/honeycomb-icons-react";
import { Divider } from "@flixbus/honeycomb-react";
import * as React from "react";
import { useIntl } from "react-intl";
import FormikAccordion from "../../../../shared/components/formikAccordion/FormikAccordion";
import FormikTextarea from "../../../../shared/components/formikTextarea/FormikTextarea";
import { PoliciesFormValues } from "../../types";
import BookableLuggageInputs from "../bookableLuggageInputs/BookableLuggageInputs";
import IncludedLuggageInputs from "../includedLuggageInputs/IncludedLuggageInputs";
import PassengerWithDisabilityForm from "../passengerWithDisabilityForm/PassengerWithDisabilityForm";
import UnaccompaniedMinorsForm from "../unaccompaniedMinorsForm/UnaccompaniedMinorsForm";
import * as css from "./PolicyInput.scss";

export type PolicyInputProps = {
  policyIndex: number;
  isEmpty?: boolean;
  readOnly?: boolean;
};

const PolicyInput: React.FC<PolicyInputProps> = ({
  policyIndex,
  isEmpty = false,
  readOnly,
}) => {
  const { formatMessage } = useIntl();
  const [allAccordionsOpen, setAllAccordionsOpen] = React.useState<boolean>();

  const handleToggle = (prevState: boolean) => {
    if (prevState) {
      setAllAccordionsOpen(false);
    }
  };

  return (
    <>
      <Divider />
      <FormikAccordion<PoliciesFormValues>
        title={formatMessage({ id: "lineForm.luggageAllowance.headline" })}
        name="policies"
        icon={IconLuggage}
        openInitially={allAccordionsOpen || isEmpty}
        onToggle={handleToggle}
      >
        <IncludedLuggageInputs
          name={`policies[${policyIndex}].policy.luggageAllowance.handLuggagePolicy`}
          label={formatMessage({ id: "lineForm.handLuggagePolicy.label" })}
          readOnly={readOnly}
        />
        <IncludedLuggageInputs
          name={`policies[${policyIndex}].policy.luggageAllowance.checkInLuggagePolicy`}
          label={formatMessage({ id: "lineForm.checkInLuggagePolicy.label" })}
          readOnly={readOnly}
        />
        <BookableLuggageInputs
          name={`policies[${policyIndex}].policy.luggageAllowance.additionalLuggagePolicy`}
          label={formatMessage({ id: "lineForm.additionalLuggagePolicy.label" })}
          readOnly={readOnly}
          isLastInList
        />
      </FormikAccordion>
      <FormikAccordion<PoliciesFormValues>
        title={formatMessage({ id: "lineForm.specialLuggageAndBuggies.headline" })}
        name="policies"
        icon={IconStroller}
        openInitially={allAccordionsOpen}
        onToggle={handleToggle}
      >
        <BookableLuggageInputs
          name={`policies[${policyIndex}].policy.specialLuggageAndBuggies.specialLuggagePolicy`}
          label={formatMessage({ id: "lineForm.specialLuggagePolicy.label" })}
          readOnly={readOnly}
        />
        <BookableLuggageInputs
          name={`policies[${policyIndex}].policy.specialLuggageAndBuggies.pushchairPolicy`}
          label={formatMessage({ id: "lineForm.pushchairPolicy.label" })}
          readOnly={readOnly}
          isLastInList
        />
      </FormikAccordion>
      <FormikAccordion<PoliciesFormValues>
        title={formatMessage({ id: "policies.youngTravelers.headline" })}
        name="policies"
        icon={IconChild}
        openInitially={allAccordionsOpen}
        onToggle={handleToggle}
      >
        <UnaccompaniedMinorsForm policyIndex={policyIndex} readOnly={readOnly} />
      </FormikAccordion>
      <FormikAccordion<PoliciesFormValues>
        title={formatMessage({ id: "lineForm.passengerWithDisabilityPolicy.headline" })}
        name="policies"
        icon={IconWheelchair}
        openInitially={allAccordionsOpen}
        onToggle={handleToggle}
      >
        <PassengerWithDisabilityForm policyIndex={policyIndex} readOnly={readOnly} />
      </FormikAccordion>
      <FormikAccordion<PoliciesFormValues>
        title={formatMessage({ id: "lineForm.bikesSurfboardsAndSkies.headline" })}
        name="policies"
        icon={IconBike}
        openInitially={allAccordionsOpen}
        onToggle={handleToggle}
      >
        <BookableLuggageInputs
          name={`policies[${policyIndex}].policy.bikesSurfboardsAndSkies.bikePolicy`}
          label={formatMessage({ id: "lineForm.bikePolicy.label" })}
          readOnly={readOnly}
        />
        <BookableLuggageInputs
          name={`policies[${policyIndex}].policy.bikesSurfboardsAndSkies.surfboardPolicy`}
          label={formatMessage({ id: "lineForm.surfboardPolicy.label" })}
          readOnly={readOnly}
        />
        <BookableLuggageInputs
          name={`policies[${policyIndex}].policy.bikesSurfboardsAndSkies.skiEquipmentPolicy`}
          label={formatMessage({ id: "lineForm.skiEquipmentPolicy.label" })}
          readOnly={readOnly}
          isLastInList
        />
      </FormikAccordion>
      <FormikAccordion<PoliciesFormValues>
        title={formatMessage({ id: "lineForm.pets.headline" })}
        name="policies"
        icon={IconDog}
        openInitially={allAccordionsOpen}
        onToggle={handleToggle}
      >
        <BookableLuggageInputs
          name={`policies[${policyIndex}].policy.pets.petPolicy`}
          label={formatMessage({ id: "lineForm.petPolicy.label" })}
          readOnly={readOnly}
          isLastInList
        />
      </FormikAccordion>

      <div className={css.textareaWrapper}>
        <FormikTextarea
          label={formatMessage({ id: "policies.additionalInformation.label" })}
          name={`policies[${policyIndex}].additionalInformation`}
          placeholder={formatMessage({ id: "general.optionalField" })}
          readOnly={readOnly}
          infoText={formatMessage({ id: "policies.additionalInformation.info" })}
          hasCounter
        />
      </div>
    </>
  );
};

export default PolicyInput;
