import * as React from "react";
import {
  Currency,
  PriceDirection,
  ScheduleDirection,
} from "../../../../shared/types/schema";
import { priceInvalidForScheduleVar } from "../../../../state/reactiveVariables/reactiveVariables";
import usePriceInvalidForSchedule from "../../../../state/usePriceInvalidForSchedule/usePriceInvalidForSchedule";
import { FindPricingQuery } from "../../api/operations.generated";
import isPriceCellReadOnly from "../../helpers/isPriceCellReadOnly/isPriceCellReadOnly";
import { RelationMatrixFromQuery } from "../../types";
import DefaultCell from "../../ui/defaultCell/DefaultCell";
import PriceCell from "../../ui/priceCell/PriceCell";
import StationCell from "../../ui/stationCell/StationCell";
import * as css from "./PriceTable.scss";

export type PriceTableProps = {
  scheduleId: string;
  lineId: string;
  stations: FindPricingQuery["findSchedule"]["stations"];
  scheduleDirection: ScheduleDirection;
  currency: Currency;
  priceDirection: PriceDirection;
  relationMatrix: RelationMatrixFromQuery;
  onPriceChange: (row: number, col: number, value: number | null) => void;
  readOnly: boolean;
};

const PriceTable: React.FC<PriceTableProps> = ({
  scheduleId,
  lineId,
  stations,
  scheduleDirection,
  currency,
  priceDirection,
  relationMatrix,
  onPriceChange,
  readOnly,
}) => {
  const [highlightedRow, setHighlightedRow] = React.useState<number>();
  const [highlightedColumn, setHighlightedColumn] = React.useState<number>();

  const [priceInvalidForSchedule] = usePriceInvalidForSchedule(
    priceInvalidForScheduleVar
  );

  return (
    <div className={css.grid}>
      <DefaultCell
        column={1}
        row={1}
        removeBorderBottom
        horizontalSticky
        verticalSticky
      />
      {stations.map((station, index, arr) => {
        return (
          <StationCell
            data-id="station-cell-vertical"
            key={station.id}
            column={1}
            row={index + 2}
            removeBorderBottom={index + 1 < arr.length}
            horizontalSticky
            name={station.name}
            stationCode={station.code}
            highlight={highlightedRow === index + 2}
            isVertical
          />
        );
      })}
      {stations.map((station, index) => {
        return (
          <StationCell
            data-id="station-cell-horizontal"
            key={station.id}
            column={index + 2}
            row={1}
            removeBorderBottom
            removeBorderLeft
            verticalSticky
            name={station.name}
            stationCode={station.code}
            highlight={highlightedColumn === index + 1}
            isVertical={false}
            priceDirection={priceDirection}
          />
        );
      })}
      {relationMatrix.rows.map((row, rowIndex, rowArr) => {
        return row.entries.map((cell, colIndex) => {
          return (
            <PriceCell
              lineId={lineId}
              data-id="price-cell"
              key={`${cell.from.id}-${cell.to.id}`}
              row={rowIndex + 2}
              column={colIndex + 2}
              removeBorderBottom={rowIndex + 1 < rowArr.length}
              removeBorderLeft
              price={cell.price}
              type={cell.type}
              currency={currency}
              onEnter={React.useCallback(() => {
                setHighlightedRow(rowIndex + 2);
                setHighlightedColumn(colIndex + 1);
              }, [])}
              onLeave={React.useCallback(() => {
                setHighlightedRow(undefined);
                setHighlightedColumn(undefined);
              }, [])}
              onChange={React.useCallback(
                (value) => onPriceChange(rowIndex, colIndex, value),
                [priceDirection]
              )}
              readOnly={isPriceCellReadOnly(
                rowIndex,
                colIndex,
                readOnly,
                priceDirection,
                scheduleDirection
              )}
              isPriceInvalid={priceInvalidForSchedule === scheduleId}
            />
          );
        });
      })}
    </div>
  );
};

export default PriceTable;
