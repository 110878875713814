import { Icon, IconOffer } from "@flixbus/honeycomb-icons-react";
import { Skeleton, Tag, Tooltip } from "@flixbus/honeycomb-react";
import {
  NotificationType,
  addNotification,
  formatErrorMessage,
  removeItemFormArray,
  hasUserPermission,
  Feature,
} from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { JSX } from "react";
import { useIntl } from "react-intl";
import * as skeletonCss from "../../../../shared/components/detailSummarySkeleton/DetailSummarySkeleton.scss";
import translatePartnerTags from "../../../../shared/helpers/translatePartnerTags/translatePartnerTags";
import { Partner, PartnerTag } from "../../../../shared/types/schema";
import { useUpdatePartnerMutation } from "../../api/operations.generated";
import AddCustomPartnerTagPopup from "../addCustomPartnerTagPopup/AddCustomPartnerTagPopup";
import * as css from "./CustomPartnerTags.scss";

type Props = {
  partnerId: Partner["id"];
  readOnly: boolean;
  tags: Partner["tags"];
};

const tagsVisible = 7;

const CustomPartnerTags: React.FC<Props> = ({ partnerId, readOnly, tags }) => {
  const { formatMessage } = useIntl();
  const [addTagPopupActive, setAddTagPopupActive] = React.useState(false);
  const canRemoveTags = !readOnly && hasUserPermission(Feature.EDIT_PARTNER_TAGS);
  const canAddTags = canRemoveTags && tags.length < Object.keys(PartnerTag).length;

  const [updatePartner, { loading }] = useUpdatePartnerMutation({
    onError: (error) =>
      addNotification({
        message: `Partner update failed: ${formatErrorMessage(error.message)}`,
        type: NotificationType.Danger,
      }),
    onCompleted: () => {
      addNotification({
        message: formatMessage({ id: "partnerDetail.submit.notification.success" }),
      });
    },
  });

  const removeTag = (tag: PartnerTag) => {
    updatePartner({
      variables: {
        id: partnerId,
        partnerTagsInput: {
          partnerTags: removeItemFormArray(tags, tag),
        },
      },
    });
  };

  const addTags = (newTags: PartnerTag[]) => {
    updatePartner({
      variables: {
        id: partnerId,
        partnerTagsInput: {
          partnerTags: tags.concat(newTags),
        },
      },
    });
  };

  if (!tags) return null;

  const visibleElements: JSX.Element[] = [];
  const hiddenTagElements: JSX.Element[] = [];
  let hidden = false;

  tags.forEach((tag, index) => {
    if (index === tagsVisible) {
      hidden = true;
      visibleElements.push(
        <Tooltip
          id="tooltip-partner-tags"
          content={<div className={css.tagTooltipContainer}>{hiddenTagElements}</div>}
          position="bottom"
          openOnHover
          size="content-fit"
        >
          <Tag display="outlined" key="more">
            &hellip;
          </Tag>
        </Tooltip>
      );
    }

    (hidden ? hiddenTagElements : visibleElements).push(
      <>
        <Tag
          display="outlined"
          key={tag}
          closeProps={
            canRemoveTags
              ? {
                  label: "Remove Tag",
                  onClick: () => removeTag(tag),
                }
              : undefined
          }
          extraClasses={css.tag}
        >
          {loading ? (
            <Skeleton flushBottom extraClasses={skeletonCss.skeleton} />
          ) : (
            translatePartnerTags(tag)
          )}
        </Tag>
      </>
    );
  });

  return (
    <>
      {visibleElements}
      {canAddTags && (
        <>
          <Tag
            data-testid="add-tag-button"
            Elem="button"
            onClick={() => setAddTagPopupActive(true)}
          >
            <Icon InlineIcon={IconOffer} />
            {formatMessage({ id: "partnerDetail.tags.add" })}
          </Tag>

          <AddCustomPartnerTagPopup
            active={addTagPopupActive}
            onPopupClose={() => setAddTagPopupActive(false)}
            onSubmit={(newTags) => {
              setAddTagPopupActive(false);
              addTags(newTags);
            }}
            options={Object.values(PartnerTag)
              .filter((tag) => !tags.includes(tag))
              .map((tag) => ({
                value: tag as PartnerTag,
                label: translatePartnerTags(tag as PartnerTag),
              }))}
          />
        </>
      )}
    </>
  );
};

export default CustomPartnerTags;
