.forceUppercase input {
  text-transform: uppercase;
}

.list {
  margin-bottom: 0;
  li {
    white-space: nowrap;
  }
}
