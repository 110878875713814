import { Icon, IconArrowBigRight, IconSwitch } from "@flixbus/honeycomb-icons-react";
import { Divider } from "@flixbus/honeycomb-react";
import * as React from "react";
import { useIntl } from "react-intl";
import ConfirmationPopup from "../../../../shared/components/confirmationPopup/ConfirmationPopup";
import RelationDirectionSelect from "../../../../shared/components/relationDirectionSelect/RelationDirectionSelect";
import StationSelect from "../../../../shared/components/stationSelect/StationSelect";
import { PriceDirection } from "../../../../shared/types/schema";
import getNewForbiddenRelations from "../../helpers/getNewForbiddenRelations/getNewForbiddenRelations";
import { ForbiddenRelationsFormValues, ForbiddenRelationStation } from "../../types";
import * as css from "./AddForbiddenRelationsPopup.scss";

export type AddForbiddenRelationsPopupProps = {
  onConfirm: (relations: Array<ForbiddenRelationsFormValues>) => void;
  onCancel: () => void;
};

const AddForbiddenRelationsPopup: React.FC<AddForbiddenRelationsPopupProps> = ({
  onConfirm,
  onCancel,
}) => {
  const { formatMessage } = useIntl();
  const [direction, setDirection] = React.useState(PriceDirection.BiDirectional);
  const [fromStation, setFromStation] = React.useState<ForbiddenRelationStation>();
  const [toStation, setToStation] = React.useState<ForbiddenRelationStation>();
  const [errorFromStation, setErrorFromStation] = React.useState(false);
  const [errorToStation, setErrorToStation] = React.useState(false);

  return (
    <ConfirmationPopup
      active
      titleText={formatMessage({ id: "lineForm.forbiddenRelations.label.add" })}
      onConfirm={() => {
        onConfirm(getNewForbiddenRelations(direction, fromStation, toStation));
      }}
      onCancel={onCancel}
      confirmButtonText={formatMessage({ id: "general.add" })}
      confirmButtonDisabled={!fromStation || !toStation}
      allowOverflow
      isWide
    >
      <div data-id="popup-body" className={css.popupBody}>
        <div className={css.directionWrapper}>
          <RelationDirectionSelect
            label={formatMessage({ id: "general.direction" })}
            value={direction}
            onSelect={(value: PriceDirection) => setDirection(value)}
          />
        </div>

        <div className={css.dividerWrapper}>
          <Divider />
        </div>

        <div className={css.relationSelectWrapper}>
          <div>
            <StationSelect
              data-id="from-station-select"
              label={formatMessage({ id: "general.station" })}
              onStationSelected={(id, data) => {
                if (data) {
                  setFromStation({
                    id,
                    code: data.code,
                    name: data.label,
                  });
                  setErrorFromStation(false);
                } else {
                  setErrorFromStation(true);
                }
              }}
              onFocus={() => {
                if (fromStation) {
                  setFromStation(undefined);
                }
                setErrorFromStation(false);
              }}
              disallowedValues={toStation ? [toStation.id] : undefined}
              createStationPossible={false}
              error={errorFromStation}
              errorMessage={formatMessage({ id: "error.errorOccured" })}
            />
          </div>

          <div data-id="direction-indicator" className={css.directionIconWrapper}>
            {direction === PriceDirection.UniDirectional ? (
              <Icon InlineIcon={IconArrowBigRight} extraClasses={css.icon} size={3} />
            ) : (
              <Icon InlineIcon={IconSwitch} extraClasses={css.icon} />
            )}
          </div>

          <div>
            <StationSelect
              data-id="to-station-select"
              label={formatMessage({ id: "general.station" })}
              onStationSelected={(id, data) => {
                if (data) {
                  setToStation({
                    id,
                    code: data.code,
                    name: data.label,
                  });
                  setErrorToStation(false);
                } else {
                  setErrorToStation(true);
                }
              }}
              onFocus={() => {
                if (toStation) {
                  setToStation(undefined);
                }
                setErrorToStation(false);
              }}
              disallowedValues={fromStation ? [fromStation.id] : undefined}
              createStationPossible={false}
              error={errorToStation}
              errorMessage={formatMessage({ id: "error.errorOccured" })}
            />
          </div>
        </div>
      </div>
    </ConfirmationPopup>
  );
};

export default AddForbiddenRelationsPopup;
