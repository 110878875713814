import { Button } from "@flixbus/honeycomb-react";
import * as React from "react";
import { useIntl } from "react-intl";
import ConfirmationPopup from "../confirmationPopup/ConfirmationPopup";
import * as css from "./DeleteButton.scss";

export type DeleteButtonProps = {
  onDelete: () => void;
  buttonText: string;
  buttonTitle: string;
  popupTitle: string;
  popupText: string;
  disabled?: boolean;
};

const DeleteButton: React.FC<DeleteButtonProps> = ({
  onDelete,
  buttonText,
  buttonTitle,
  popupTitle,
  popupText,
  disabled = false,
}) => {
  const [popupActive, setPopupActive] = React.useState(false);
  const { formatMessage } = useIntl();

  return (
    <>
      <Button
        extraClasses={!disabled ? css.button : undefined}
        onClick={() => setPopupActive(true)}
        disabled={disabled}
        title={disabled ? buttonTitle : undefined}
        data-id="delete-button"
      >
        {buttonText}
      </Button>
      <ConfirmationPopup
        active={popupActive}
        titleText={popupTitle}
        confirmButtonText={formatMessage({ id: "general.delete" })}
        onConfirm={() => {
          onDelete();
          setPopupActive(false);
        }}
        onCancel={() => setPopupActive(false)}
      >
        <div>{popupText}</div>
      </ConfirmationPopup>
    </>
  );
};

export default DeleteButton;
