import {
  AccessPackage,
  MeansOfTransport,
  PartnerFilterInput,
} from "../../../shared/types/schema";
import { PartnerFilterFormSubmittableValues, PartnerFilterFormValues } from "../../types";

export const createInitialValues = (
  defaultValues?: PartnerFilterFormSubmittableValues
): PartnerFilterFormValues => {
  return {
    businessRegions: defaultValues?.businessRegions || [],
    apiPartner: {
      api: !!defaultValues?.apiPartner?.includes(true),
      manual: !!defaultValues?.apiPartner?.includes(false),
    },
    meansOfTransport: {
      bus: !!defaultValues?.meansOfTransport?.includes(MeansOfTransport.Bus),
      train: !!defaultValues?.meansOfTransport?.includes(MeansOfTransport.Train),
      ferry: !!defaultValues?.meansOfTransport?.includes(MeansOfTransport.Ferry),
      minibus: !!defaultValues?.meansOfTransport?.includes(MeansOfTransport.MiniBus),
      unset: !!defaultValues?.meansOfTransport?.includes(null),
    },
    partnerTags: defaultValues?.partnerTags || [],
    accessPackage: {
      pro: !!defaultValues?.accessPackage?.includes(AccessPackage.Pro),
      light: !!defaultValues?.accessPackage?.includes(AccessPackage.Light),
      view: !!defaultValues?.accessPackage?.includes(AccessPackage.View),
    },
    businessDeveloper: defaultValues?.businessDeveloper || "",
    autoOnSaleConfig: {
      normalSchedule: {
        activated: !!defaultValues?.autoOnSaleConfig.normalSchedule?.includes(true),
        deactivated: !!defaultValues?.autoOnSaleConfig.normalSchedule?.includes(false),
      },
      conceptChangeSchedule: {
        activated:
          !!defaultValues?.autoOnSaleConfig.conceptChangeSchedule?.includes(true),
        deactivated:
          !!defaultValues?.autoOnSaleConfig.conceptChangeSchedule?.includes(false),
      },
    },
  };
};

export const createSubmittableValues = (
  values: PartnerFilterFormValues
): PartnerFilterFormSubmittableValues => {
  const getBooleanValues = (on: boolean | null, off: boolean | null) => {
    if (on === true && off === true) {
      return [true, false];
    }
    if (on === true && off === false) {
      return [true];
    }
    if (on === false && off === true) {
      return [false];
    }
    return null;
  };

  const accessPackage = [
    values.accessPackage.pro ? AccessPackage.Pro : undefined,
    values.accessPackage.light ? AccessPackage.Light : undefined,
    values.accessPackage.view ? AccessPackage.View : undefined,
  ].filter(
    (val) => val !== undefined
  ) as PartnerFilterFormSubmittableValues["accessPackage"];

  const meansOfTransport = [
    values.meansOfTransport.bus ? MeansOfTransport.Bus : undefined,
    values.meansOfTransport.train ? MeansOfTransport.Train : undefined,
    values.meansOfTransport.ferry ? MeansOfTransport.Ferry : undefined,
    values.meansOfTransport.minibus ? MeansOfTransport.MiniBus : undefined,
    values.meansOfTransport.unset ? null : undefined,
  ].filter(
    (val) => val !== undefined
  ) as PartnerFilterFormSubmittableValues["meansOfTransport"];

  return {
    businessRegions: values.businessRegions.length ? values.businessRegions : null,
    apiPartner: getBooleanValues(values.apiPartner.api, values.apiPartner.manual),
    meansOfTransport: meansOfTransport?.length ? meansOfTransport : null,
    accessPackage: accessPackage?.length ? accessPackage : null,
    businessDeveloper: values.businessDeveloper.length ? values.businessDeveloper : null,
    autoOnSaleConfig: {
      normalSchedule: getBooleanValues(
        values.autoOnSaleConfig.normalSchedule.activated,
        values.autoOnSaleConfig.normalSchedule.deactivated
      ),
      conceptChangeSchedule: getBooleanValues(
        values.autoOnSaleConfig.conceptChangeSchedule.activated,
        values.autoOnSaleConfig.conceptChangeSchedule.deactivated
      ),
    },
    partnerTags: values.partnerTags.length ? values.partnerTags : null,
  };
};

export const createQueryVariables = (
  values: PartnerFilterFormSubmittableValues
): PartnerFilterInput => {
  const getBooleanValues = (valuesArr: Array<boolean> | null): boolean | null => {
    if (valuesArr === null) {
      return null;
    }
    if (valuesArr.includes(true) && valuesArr.includes(false)) {
      return null;
    }
    return valuesArr.includes(true);
  };

  return {
    ...values,
    apiPartner: getBooleanValues(values.apiPartner),
    autoOnSaleConfig: {
      normalSchedule: getBooleanValues(values.autoOnSaleConfig.normalSchedule),
      conceptChangeSchedule: getBooleanValues(
        values.autoOnSaleConfig.conceptChangeSchedule
      ),
    },
  };
};
