// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// This file was automatically generated using npm run codegen
// If you want to alter this file please do so via the above mentioned command
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
import * as Types from "../../../shared/types/schema";

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
const defaultOptions = {} as const;
export type NewStationMutationVariables = Types.Exact<{
  name: Types.Scalars["String"]["input"];
  code?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  latitude: Types.Scalars["String"]["input"];
  longitude: Types.Scalars["String"]["input"];
  zipCode: Types.Scalars["String"]["input"];
  addressCity: Types.Scalars["String"]["input"];
  addressLines:
    | Array<Types.Scalars["String"]["input"]>
    | Types.Scalars["String"]["input"];
  countryCode: Types.IsoCountry;
  timezone: Types.Scalars["String"]["input"];
  cityId: Types.Scalars["ID"]["input"];
  transferInformation: Types.StationTransferInformationInput;
}>;

export type NewStationMutation = {
  __typename?: "Mutation";
  newStation: {
    __typename?: "Station";
    code?: string | null;
    value: string;
    label: string;
  };
};

export const NewStationDocument = gql`
  mutation NewStation(
    $name: String!
    $code: String
    $latitude: String!
    $longitude: String!
    $zipCode: String!
    $addressCity: String!
    $addressLines: [String!]!
    $countryCode: ISOCountry!
    $timezone: String!
    $cityId: ID!
    $transferInformation: StationTransferInformationInput!
  ) {
    newStation(
      name: $name
      code: $code
      latitude: $latitude
      longitude: $longitude
      zipCode: $zipCode
      addressCity: $addressCity
      addressLines: $addressLines
      timezone: $timezone
      countryCode: $countryCode
      cityId: $cityId
      transferInformation: $transferInformation
    ) {
      value: id
      label: name
      code
    }
  }
`;

/**
 * __useNewStationMutation__
 *
 * To run a mutation, you first call `useNewStationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewStationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newStationMutation, { data, loading, error }] = useNewStationMutation({
 *   variables: {
 *      name: // value for 'name'
 *      code: // value for 'code'
 *      latitude: // value for 'latitude'
 *      longitude: // value for 'longitude'
 *      zipCode: // value for 'zipCode'
 *      addressCity: // value for 'addressCity'
 *      addressLines: // value for 'addressLines'
 *      countryCode: // value for 'countryCode'
 *      timezone: // value for 'timezone'
 *      cityId: // value for 'cityId'
 *      transferInformation: // value for 'transferInformation'
 *   },
 * });
 */
export function useNewStationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    NewStationMutation,
    NewStationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<NewStationMutation, NewStationMutationVariables>(
    NewStationDocument,
    options
  );
}
export type NewStationMutationHookResult = ReturnType<typeof useNewStationMutation>;
