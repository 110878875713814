import * as React from "react";
import ClearFilterButton from "../../container/clearFilterButton/ClearFilterButton";
import SearchBarFilterInput from "../../container/searchBarFilterInput/SearchBarFilterInput";
import SearchBarFilterMenu from "../../container/searchBarFilterMenu/SearchBarFilterMenu";
import { SearchType } from "../../types";
import * as css from "./SearchBar.scss";

type Props = {
  type: SearchType;
};

const SearchBar: React.FC<Props> = ({ type }) => {
  return (
    <div className={css.wrapper} data-id="search-bar">
      <SearchBarFilterInput type={type} />
      <SearchBarFilterMenu type={type} />
      <ClearFilterButton type={type} />
    </div>
  );
};

export default SearchBar;
