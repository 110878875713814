import { FieldMetaProps } from "formik2";

const isValid = (meta: FieldMetaProps<string | number | undefined>) => {
  if (!meta?.touched) return undefined;

  if (meta.error) return false;

  if (
    typeof meta.value === "string" &&
    (meta.value === "" || meta.value.trim().length === 0)
  )
    return undefined;

  return true;
};

export default isValid;
