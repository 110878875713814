import { DisplayedScheduleStatus } from "../../../../shared/types/schema";
import { SchedulesOfLineQuery } from "../../api/operations.generated";

const inactiveStates = [
  DisplayedScheduleStatus.OutOfSale,
  DisplayedScheduleStatus.Archived,
];

const isActive = (status: DisplayedScheduleStatus) => !inactiveStates.includes(status);

export default (schedules: SchedulesOfLineQuery["findLine"]["schedules"]) =>
  schedules.filter((schedule) => {
    return (
      isActive(schedule.displayedScheduleStatus) ||
      schedule.conceptChanges.some((conceptChange) =>
        isActive(conceptChange.displayedScheduleStatus)
      )
    );
  });
