/* eslint-disable @typescript-eslint/no-unused-vars */
// FIXME: Remove eslint-disable (the line above) with PHX-2728
import { Icon, IconPlus } from "@flixbus/honeycomb-icons-react";
import { Button } from "@flixbus/honeycomb-react";
import {
  Feature,
  hasUserPermission,
  localizeDate,
} from "@flixbus-phx/marketplace-common";
import { isSameDay } from "date-fns";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import Chip from "../../../../shared/components/chip/Chip";
import { PricingPeriod } from "../../../../shared/types/schema";
import * as css from "./PeriodChips.scss";

export type PeriodChipsProps = {
  periods: NonNullable<PricingPeriod["period"]>[];
  selectedPeriod: NonNullable<PricingPeriod["period"]>["start"] | null;
  onPeriodSelect: (period: NonNullable<PricingPeriod["period"]>["start"] | null) => void;
  readOnly: boolean;
};

const PeriodChips: React.FC<PeriodChipsProps> = ({
  periods,
  selectedPeriod,
  onPeriodSelect,
  readOnly,
}) => {
  return (
    <div className={css.wrapper}>
      <Chip isHighlighted={selectedPeriod === null} onClick={() => onPeriodSelect(null)}>
        <FormattedMessage id="pricing.period.default" />
      </Chip>
      {[...periods]
        .sort((a, b) => {
          return new Date(a.start).getTime() - new Date(b.start).getTime();
        })
        .map((period) => {
          const start = new Date(period.start);
          const end = new Date(period.end);
          const isHighlighted = selectedPeriod === period.start;
          let periodString;

          if (isSameDay(start, end)) {
            periodString = localizeDate(start);
          } else {
            periodString = `${localizeDate(start)} - ${localizeDate(end)}`;
          }

          return (
            <Chip
              key={period.start}
              isHighlighted={isHighlighted}
              onClick={() => onPeriodSelect(period.start)}
              // FIXME: Uncomment with PHX-2728
              // onDelete={
              //   isHighlighted && hasUserPermission(Feature.EDIT_PRICING)
              //     ? () => {}
              //     : undefined
              // }
            >
              {periodString}
            </Chip>
          );
        })}
      {/* FIXME: Uncomment with PHX-2728 */}
      {/* {hasUserPermission(Feature.EDIT_PRICING) && !readOnly && (
        <Button appearance="link" Elem="a" extraClasses={css.addPeriodButton}>
          <Icon InlineIcon={IconPlus} /> <FormattedMessage id="pricing.period.add" />
        </Button>
      )} */}
    </div>
  );
};

export default PeriodChips;
