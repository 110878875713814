import * as Yup from "yup";
import dateSchema from "./dateSchema";

export default () =>
  Yup.object().shape({
    name: Yup.string(),
    period: Yup.object().shape({
      start: dateSchema,
      end: dateSchema,
    }),
  });
