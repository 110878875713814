@import "src/shared/styles/variables";

.container {
  width: $timetable-trip-col-width;
  padding: $spacing-4 $spacing-2;
  height: 143px;
}

.buttonGroup {
  text-align: right;
}

.serviceDays {
  padding-top: $spacing-4;
}
