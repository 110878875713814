import { Icon, IconOffer } from "@flixbus/honeycomb-icons-react";
import { Button } from "@flixbus/honeycomb-react";
import cx from "classnames";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { TripDirection } from "../../../../shared/types/schema";
import { FindScheduleWithTripsAndStationsQuery } from "../../api/operations.generated";
import StationColumn from "../../containers/stationColumn/StationColumn";
import TripTable from "../../containers/tripTable/TripTable";
import generateStationCellLabelsArray from "../../helpers/generateStationCellLabelsArray/generateStationCellLabelsArray";
import TimetableColumn from "../timetableColumn/TimetableColumn";
import * as css from "./TimetableOverview.scss";

export type TimetableOverviewProps = {
  schedule: FindScheduleWithTripsAndStationsQuery["findSchedule"];
  onAddReturnSchedule: () => void;
  readOnly: boolean;
};

const TimetableOverview: React.FC<TimetableOverviewProps> = ({
  schedule,
  onAddReturnSchedule,
  readOnly,
}) => {
  const { formatMessage } = useIntl();

  const outwardTrips = schedule.trips.filter(
    (trip) => trip.direction === TripDirection.Outward
  );
  const returnTrips = schedule.trips.filter(
    (trip) => trip.direction === TripDirection.Return
  );

  const stationCellLabels = generateStationCellLabelsArray(schedule.stations.length);

  return (
    <>
      <div className={css.outwardContainer} data-id="schedule-outward">
        <div className={css.wrapper}>
          <StationColumn
            headline={formatMessage({ id: "timetable.outward.headline" })}
            scheduleId={schedule.id}
            stationSequence={schedule.stations}
            readOnly={readOnly}
          />
          {outwardTrips.length > 0 && (
            <TimetableColumn
              data-id="station-cell-labels-outward"
              content={stationCellLabels}
              colPosition={2}
              rowPosition={1}
              extraClasses={css.stationCellLabel}
              childClasses={css.stationCellLabelCells}
            />
          )}
        </div>
        <div className={cx(css.wrapper, css.scrollableWrapper)}>
          <TripTable
            scheduleId={schedule.id}
            trips={outwardTrips}
            direction={TripDirection.Outward}
            readOnly={readOnly}
          />
        </div>
      </div>
      {returnTrips.length === 0 && !readOnly && (
        <Button
          data-id="add-return-schedule"
          onClick={onAddReturnSchedule}
          size="lg"
          appearance="secondary"
          extraClasses={css.addReturnScheduleButton}
        >
          <Icon InlineIcon={IconOffer} />
          <FormattedMessage id="timetable.return.add.button.label" />
        </Button>
      )}
      {returnTrips.length > 0 && (
        <div className={css.returnContainer} data-id="schedule-return">
          <div className={css.wrapper}>
            <StationColumn
              headline={formatMessage({ id: "timetable.return.headline" })}
              scheduleId={schedule.id}
              stationSequence={schedule.stations.slice().reverse()}
              readOnly
            />
            {returnTrips.length > 0 && (
              <TimetableColumn
                data-id="station-cell-labels-return"
                content={stationCellLabels}
                colPosition={2}
                rowPosition={1}
                extraClasses={css.stationCellLabel}
                childClasses={css.stationCellLabelCells}
              />
            )}
          </div>
          <div className={cx(css.wrapper, css.scrollableWrapper)}>
            <TripTable
              scheduleId={schedule.id}
              trips={returnTrips}
              direction={TripDirection.Return}
              readOnly={readOnly}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default TimetableOverview;
