import { Icon, IconCheckmark, IconClose } from "@flixbus/honeycomb-icons-react";
import { Button } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import * as css from "./ToggleStateButton.scss";

export type ToggleStateButtonProps = {
  isInEditMode: boolean;
  onToggle: () => void;
  disabled?: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  isSubmitLoading: boolean;
};

const ToggleStateButton: React.FC<ToggleStateButtonProps> = ({
  isInEditMode,
  onToggle,
  disabled = false,
  onCancel,
  onSubmit,
  isSubmitLoading,
}) => {
  if (!isInEditMode) {
    return (
      <Button
        data-id="enter-edit-mode-button"
        appearance="secondary"
        onClick={onToggle}
        disabled={disabled}
        extraClasses={css.button}
      >
        <FormattedMessage id="prices.edit.button.label" />
      </Button>
    );
  }

  return (
    <div className={css.buttonGroup}>
      <Button
        data-id="cancel-editing-button"
        appearance="danger"
        display="square"
        onClick={onCancel}
        aria-label="cancel"
        extraClasses={css.button}
      >
        <Icon InlineIcon={IconClose} />
      </Button>
      <Button
        data-id="save-editing-button"
        appearance="tertiary"
        display="square"
        onClick={onSubmit}
        aria-label="save"
        loading={isSubmitLoading}
        extraClasses={css.button}
      >
        <Icon InlineIcon={IconCheckmark} />
      </Button>
    </div>
  );
};

export default ToggleStateButton;
