import { formatMessage } from "@flixbus-phx/marketplace-common";
import * as Yup from "yup";
import {
  CooperationType,
  CutOffSalesRule,
  Line,
  MeansOfTransport,
  TimeUnit,
} from "../../../../shared/types/schema";
import { LineCodeOption } from "../../ui/lineCodeInput/LineCodeInput";

export default (isCreation: boolean, duplicateCode?: Line["networkCode"]) => {
  const cutOffSalesUnitSchema = Yup.string().oneOf([TimeUnit.Minutes, TimeUnit.Hours]);

  return Yup.object({
    networkDescription: Yup.string()
      .trim()
      .required(formatMessage("error.validation.required")),
    cooperationType: Yup.string()
      .required(formatMessage("error.validation.required"))
      .oneOf([CooperationType.S, CooperationType.M]),
    cutOffSales: Yup.object({
      cutOffSalesRule: Yup.string()
        .required(formatMessage("error.validation.required"))
        .oneOf([
          CutOffSalesRule.None,
          CutOffSalesRule.BeforeDeparture,
          CutOffSalesRule.BeforeDayOfDeparture,
        ]),
      cutOffSalesDuration: Yup.string().when("cutOffSalesRule", {
        is: CutOffSalesRule.None,
        then: () =>
          Yup.object({
            value: Yup.number().oneOf([0]).optional(),
            unit: cutOffSalesUnitSchema,
          }),
        otherwise: () =>
          Yup.object({
            value: Yup.number().when("unit", {
              is: TimeUnit.Minutes,
              then: () =>
                Yup.number()
                  .min(1, formatMessage("error.validation.equalOrGreaterThanOne"))
                  .max(
                    2880,
                    formatMessage("error.validation.equalOrLessThanX", { value: "2880" })
                  )
                  .integer(formatMessage("error.validation.numberNoDecimals"))
                  .typeError(formatMessage("error.validation.mustBeNumber"))
                  .required(formatMessage("error.validation.required")),
              otherwise: () =>
                Yup.number()
                  .min(1, formatMessage("error.validation.equalOrGreaterThanOne"))
                  .max(
                    48,
                    formatMessage("error.validation.equalOrLessThanX", { value: "48" })
                  )
                  .integer(formatMessage("error.validation.numberNoDecimals"))
                  .typeError(formatMessage("error.validation.mustBeNumber"))
                  .required(formatMessage("error.validation.required")),
            }),
            unit: cutOffSalesUnitSchema,
          }),
      }),
    }),
    brandId: Yup.number().required(formatMessage("error.validation.required")),
    meansOfTransport: Yup.mixed().oneOf([...Object.values(MeansOfTransport)]),
    customNetworkCode: Yup.string()
      .uppercase()
      .when("networkCodeType", {
        is: (val: LineCodeOption) => val === LineCodeOption.Auto,
        then: () => Yup.string().nullable(),
        otherwise: () =>
          Yup.string()
            .max(
              isCreation ? 8 : 10,
              formatMessage("lineForm.lineCode.input.tooltip.firstLine")
            )
            .required(formatMessage("lineForm.lineCode.input.error.required"))
            .test({
              name: "is-not-duplicate",
              message: formatMessage("lineForm.lineCode.input.error.duplicate"),
              test: (value) => value !== duplicateCode || duplicateCode === undefined,
            })
            .test({
              name: "matches-format",
              message: formatMessage("lineForm.lineCode.input.error.format"),
              test: (value) =>
                value === undefined ||
                !isCreation ||
                /^[A-Z0-9\-_#:"'<>/.!]+$/.test(value),
            }),
      }),
  });
};
