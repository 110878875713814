import { Accordion } from "@flixbus/honeycomb-react";
import * as React from "react";
import ExchangeStation from "../../../exchangeStation/ExchangeStation";
import { FindStationsInScheduleAndCityQuery } from "../../api/operations.generated";
import StationTab from "../../containers/stationTab/StationTab";
import * as css from "./CityStationsAccordion.scss";

export type Stations = NonNullable<
  FindStationsInScheduleAndCityQuery["findStation"]["city"]
>["stations"];

type Props = {
  scheduleId: string;
  originalStationId: string;
  originalStationName: string;
  stations: Stations;
  scheduleStationIds: Array<string>;
  scheduleReadOnly: boolean;
  onPanelClose: () => void;
};

const CityStationsAccordion: React.FC<Props> = ({
  scheduleId,
  originalStationId,
  originalStationName,
  stations,
  scheduleStationIds,
  scheduleReadOnly,
  onPanelClose,
}) => {
  const [visibleItem, setVisibleItem] = React.useState<Stations[number]["id"]>();

  return (
    <>
      {stations.map((station) => {
        return (
          <Accordion
            onToggle={(e: React.SyntheticEvent) => {
              if (e.currentTarget.hasAttribute("open")) {
                setVisibleItem(station.id);
              }
            }}
            key={station.id}
            title={station.name}
            open={station.id === visibleItem}
          >
            {station.id === visibleItem ? (
              <div className={css.accordionBody}>
                <StationTab
                  stationId={station.id}
                  onPanelClose={onPanelClose}
                  hideFormCloseButton
                />
                <ExchangeStation
                  scheduleId={scheduleId}
                  originalStationId={originalStationId}
                  originalStationName={originalStationName}
                  exchangeStationId={station.id}
                  exchangeStationName={station.name}
                  scheduleStationIds={scheduleStationIds}
                  scheduleReadOnly={scheduleReadOnly}
                />
              </div>
            ) : (
              <></>
            )}
          </Accordion>
        );
      })}
    </>
  );
};

export default CityStationsAccordion;
