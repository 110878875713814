@import "variables";
@import "../../helper/styles/styles.scss";

.scrollableColumn {
  @include scrollableColumn;
  height: $contentCellHeight;
  padding-top: calc(($contentCellHeight - $scheduleCellHeight) / 2);

  border-right: $border-light;
  border-bottom: $border;
}

.weekend {
  @include weekendCell;
}

.scheduleLink {
  text-decoration: inherit;
  color: inherit;
}

.scheduleCell {
  height: $scheduleCellHeight;
  background: $onSaleColor;
  border-top: $onSaleBorder;
  border-bottom: $onSaleBorder;

  // to overwrite parent border
  position: relative;
  margin: -1px;

  &.start {
    border-top-left-radius: $border-radius-lg;
    border-bottom-left-radius: $border-radius-lg;
    border-left: $onSaleBorder;
  }

  &.isFirst {
    border-left-width: 8px;
  }

  &.end {
    border-top-right-radius: $border-radius-lg;
    border-bottom-right-radius: $border-radius-lg;
    border-right: $onSaleBorder;
  }

  &.highlight {
    // FIXME: Needs to be changes as soon as Dainn decided on a highligt design
    border-color: $focus-outline-color;
  }
}

.scheduleName {
  position: relative;
  top: -27px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  left: $spacing-2;

  &.hasTooltip {
    padding-right: 25px;
  }
}
