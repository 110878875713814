import { useReactiveVar } from "@apollo/client";
import * as React from "react";
import FilterMenu from "../../../shared/components/filterMenu/FilterMenu";
import lineFilterVar from "../../../state/lineFilter/lineFilter";
import partnerFilterVar from "../../../state/partnerFilter/partnerFilter";
import { SearchType } from "../../types";
import LineFilterForm from "../../ui/lineFilterForm/LineFilterForm";
import PartnerFilterForm from "../../ui/partnerFilterForm/PartnerFilterForm";

type Props = {
  type: SearchType;
};
const SearchBarFilterMenu: React.FC<Props> = ({ type }) => {
  const partnerFilters = useReactiveVar(partnerFilterVar);
  const lineFilters = useReactiveVar(lineFilterVar);
  const [showFilters, setShowFilters] = React.useState(false);

  return (
    <FilterMenu showBox={showFilters} handleBoxVisibility={setShowFilters}>
      <>
        {type === SearchType.PARTNERS && (
          <PartnerFilterForm
            defaultValues={partnerFilters}
            onReset={() => setShowFilters(false)}
            onSubmit={(values) => {
              partnerFilterVar({ ...partnerFilters, ...values });
              setShowFilters(false);
            }}
          />
        )}
        {type === SearchType.LINES && (
          <LineFilterForm
            defaultValues={lineFilters}
            onReset={() => setShowFilters(false)}
            onSubmit={(values) => {
              lineFilterVar({ ...lineFilters, ...values });
              setShowFilters(false);
            }}
          />
        )}
      </>
    </FilterMenu>
  );
};

export default SearchBarFilterMenu;
