@import "variables";

.form {
  width: calc(100% + 156px);
  display: flex;
  justify-content: space-between;
}

.formButtons {
  align-self: flex-end;
}

.formButton {
  min-width: unset;
}

.formRow {
  display: flex;
  column-gap: $spacing-1;
  &:not(:last-child) {
    margin-bottom: $spacing-1;
  }
}

.input {
  width: 300px;
}

.plusButton {
  margin-left: 0 !important;
}
