import * as React from "react";
import debounce from "../../helper/debounce/debounce";

export const viewportContext = React.createContext<number | undefined>(undefined);

export const useViewportContext = () => React.useContext(viewportContext);

const ViewportProvider: React.FC = ({ children }) => {
  const [width, setWidth] = React.useState(window.innerWidth);

  const handleWindowResize = debounce(
    () => setWidth(window.innerWidth),
    process.env.NODE_ENV === "development" ? 0 : 200
  );

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return <viewportContext.Provider value={width}>{children}</viewportContext.Provider>;
};

export default ViewportProvider;
