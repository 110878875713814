import { Icon, IconArrowRight } from "@flixbus/honeycomb-icons-react";
import { Skeleton } from "@flixbus/honeycomb-react";
import * as React from "react";
import * as css from "./BreadcrumbsSkeleton.scss";

const BreadcrumbsSkeleton: React.FC = () => (
  <div className={css.wrapper}>
    <div className={css.skeletonItem}>
      <Skeleton height="sm" extraClasses={css.skeleton} />
    </div>
    <div className={css.skeletonDivider}>
      <Icon InlineIcon={IconArrowRight} />
    </div>
    <div className={css.skeletonItem}>
      <Skeleton height="sm" extraClasses={css.skeleton} />
    </div>
  </div>
);

export default BreadcrumbsSkeleton;
