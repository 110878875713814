@import "variables";

.nameSkeleton {
  height: 46px;
}

.buttonSkeleton {
  width: $spacing-6;
  float: right;
}
