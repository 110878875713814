import { useReactiveVar } from "@apollo/client";
import { DataTable, DataTableRow } from "@flixbus/honeycomb-react";
import { useEffectAfterMount } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import buildPath from "../../../routes/buildPath/buildPath";
import DataTableError from "../../../shared/components/dataTableError/DataTableError";
import DataTableLoading from "../../../shared/components/dataTableLoading/DataTableLoading";
import DataTableNoResults from "../../../shared/components/dataTableNoResult/DataTableNoResults";
import Pager from "../../../shared/components/pager/Pager";
import { PageSize } from "../../../shared/types/schema";
import lineFilterVar from "../../../state/lineFilter/lineFilter";
import { useFilterLinesQuery } from "../../api/operations.generated";
import { clearLineFilter } from "../../helper/clearFilter/clearFilter";
import { lineTableHeaders } from "../../helper/tableHeader/tableHeader";
import ResultCount from "../../ui/resultCount/ResultCount";
import * as css from "./LineTable.scss";

const LineTable: React.FC = () => {
  const lineFilter = useReactiveVar(lineFilterVar);
  const navigate = useNavigate();
  const [pageSize, setPageSize] = React.useState(PageSize.Twentyfive);

  const { data, loading, error, fetchMore } = useFilterLinesQuery({
    variables: {
      filter: lineFilter,
      pageSize,
      page: 0,
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffectAfterMount(() => {
    fetchMore({ variables: {} });
  }, [lineFilter.searchTerm, lineFilter.businessRegions, lineFilter.meansOfTransport]);

  if (loading) {
    return (
      <>
        <ResultCount type="line" loading />
        <DataTableLoading
          header={lineTableHeaders}
          colCount={5}
          extraClasses={css.table}
        />
      </>
    );
  }

  if (error) {
    return <DataTableError />;
  }

  if (data && data.filterLines.items.length) {
    const lines = data.filterLines.items;
    const { total, pageInfo } = data.filterLines;

    return (
      <div data-id="line-table">
        <ResultCount amount={total} type="line" loading={false} />
        <DataTable headers={lineTableHeaders} extraClasses={css.table}>
          {lines.map((line) => (
            <DataTableRow
              extraClasses={css.tableRow}
              key={line.id}
              onClick={() => navigate(buildPath.showLineDetail(line.id))}
            >
              <td>{line.networkCode}</td>
              <td>{line.networkDescription}</td>
              <td>{line.partner.name}</td>
              <td>{line.partner.businessRegion.name}</td>
              <td>
                <FormattedMessage id={`meansOfTransport.${line.meansOfTransport}`} />
              </td>
            </DataTableRow>
          ))}
        </DataTable>
        {pageInfo.totalPages > 0 && (
          <Pager
            totalPages={pageInfo.totalPages}
            currentPage={pageInfo.currentPage}
            hasPrevious={pageInfo.hasPrevious}
            hasNext={pageInfo.hasNext}
            setPage={(newPage) => {
              fetchMore({ variables: { page: newPage } });
            }}
            total={total}
            pageSize={pageSize}
            setPageSize={(size) => {
              setPageSize(size);
              fetchMore({ variables: { pageSize: size } });
            }}
          />
        )}
      </div>
    );
  }

  return <DataTableNoResults onClearFilterSetting={clearLineFilter} />;
};

export default LineTable;
