@import "variables";

.headline {
  display: inline;
  margin-right: $spacing-2;
}

.button {
  margin-bottom: $spacing-4;
}

.formRow {
  padding-bottom: 24px;
}
