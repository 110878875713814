import { useField } from "formik2";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { SelectOption } from "../../helpers/reactSelect/types";
import ReadOnlyFormField from "../readOnlyFormField/ReadOnlyFormField";
import Select from "../select/Select";

export type FormikSelectProps = {
  label?: string;
  placeholder?: string;
  name: string;
  required?: boolean;
  options: Array<SelectOption>;
  readOnly?: boolean;
  isClearable?: boolean;
  infoText?: string;
};

const FormikSelect: React.FC<FormikSelectProps> = ({
  label,
  placeholder,
  name,
  required,
  options,
  readOnly,
  isClearable,
  infoText,
}) => {
  const [, meta, helper] = useField(name);

  if (readOnly) {
    return (
      <ReadOnlyFormField label={label}>
        {options.find((option) => option.value === meta.value)?.label || (
          <FormattedMessage id="general.notAvailable" />
        )}
      </ReadOnlyFormField>
    );
  }

  return (
    <Select
      label={label && `${label}${required ? " *" : ""}`}
      initialValue={options.find((option) => option.value === meta.value)}
      placeholder={placeholder}
      options={options}
      onSelect={(selectedValue) => {
        helper.setTouched(true);
        helper.setValue(selectedValue?.value);
      }}
      onClear={isClearable ? () => helper.setValue("") : undefined}
      onBlur={() => helper.setTouched(true)}
      errorText={meta.error && meta.touched ? meta.error : undefined}
      infoText={infoText}
    />
  );
};

export default FormikSelect;
