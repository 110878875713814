import { formatMessage } from "@flixbus-phx/marketplace-common";
import * as Yup from "yup";
import { ImportanceClass } from "../../../../shared/types/schema";

export default () =>
  Yup.object().shape({
    name: Yup.string()
      .max(28, formatMessage("error.validation.equalOrLessThanX", { value: "28" }))
      .required(formatMessage("error.validation.required")),
    countryCode: Yup.string()
      .max(2, formatMessage("error.validation.noValidCountry"))
      .required(formatMessage("error.validation.required")),
    importanceClass: Yup.mixed()
      .oneOf(
        [ImportanceClass.A, ImportanceClass.B, ImportanceClass.C],
        formatMessage("error.validation.noValidImportanceClass", {
          ImportanceClassA: ImportanceClass.A,
          ImportanceClassB: ImportanceClass.B,
          ImportanceClassC: ImportanceClass.C,
        })
      )
      .required(formatMessage("error.validation.required")),
  });
