const getSubmittableValues = <T>(
  input?: {
    value?: T;
  } | null
): { value: T } | undefined => {
  if (input?.value) {
    return {
      value: input.value,
    };
  }

  return undefined;
};

export default getSubmittableValues;
