import { format } from "date-fns";
import { ScheduleDetailFragment } from "../../../api/operations.generated";

export type NonOperatingPeriods = ScheduleDetailFragment["nonOperatingPeriods"];

export const cleanPeriodsOfTypename = (
  dirtyPeriods: NonOperatingPeriods
): NonOperatingPeriods => {
  const cleanPeriods = [...dirtyPeriods];
  return cleanPeriods.map((period) => {
    const newPeriod = { ...period };
    delete newPeriod.__typename;
    return newPeriod;
  });
};

export const removePeriod = (
  periods: NonOperatingPeriods,
  index: number
): NonOperatingPeriods => {
  const newPeriods = [...periods];
  newPeriods.splice(index, 1);
  return cleanPeriodsOfTypename(newPeriods);
};

export const addPeriod = (
  periods: NonOperatingPeriods,
  startDate: Date,
  endDate: Date
): NonOperatingPeriods => {
  const newPeriods = [...periods];
  newPeriods.push({
    start: format(startDate, "yyyy-MM-dd"),
    end: format(endDate, "yyyy-MM-dd"),
  });
  return cleanPeriodsOfTypename(newPeriods);
};

export const editPeriod = (
  periods: NonOperatingPeriods,
  startDate: Date,
  endDate: Date,
  index: number
): NonOperatingPeriods => {
  const newPeriods = [...periods];
  if (index < newPeriods.length) {
    newPeriods[index] = {
      start: format(startDate, "yyyy-MM-dd"),
      end: format(endDate, "yyyy-MM-dd"),
    };
  }
  return cleanPeriodsOfTypename(newPeriods);
};
