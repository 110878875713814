@import "variables";

.buttonWrapper {
  display: flex;
  height: 100%;
  padding-top: calc(#{$spacing-4} + 2px);
}

.button {
  color: $icon-primary-color;
}
