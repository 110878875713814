import { Brand } from "../../types";

export default (ids: Array<Brand["id"]>, brands: Array<Brand>): Array<Brand> => {
  return ids.reduce((result: Array<Brand>, id) => {
    const matchingBrand = brands.find((a) => a.id === id);

    if (matchingBrand) {
      result.push(matchingBrand);
    }
    return result;
  }, []);
};
