import { Icon, IconInfo } from "@flixbus/honeycomb-icons-react";
import { Text } from "@flixbus/honeycomb-react";
import * as React from "react";
import * as css from "./InfoText.scss";

type InfoTextProps = {
  text: string;
};
const InfoText: React.FC<InfoTextProps> = ({ text }) => {
  return (
    <Text small extraClasses={css.infoText}>
      <Icon InlineIcon={IconInfo} extraClasses={css.icon} />
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: text }} />
    </Text>
  );
};

export default InfoText;
