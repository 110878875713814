import { localizeMonth } from "@flixbus-phx/marketplace-common";
import cx from "classnames";
import { isThisMonth, format, isToday, isWeekend } from "date-fns";
import * as React from "react";
import getGridTemplateColumns from "../../helpers/getGridTemplateColumns/getGridTemplateColumns";
import getIntervalForPeriod from "../../helpers/getIntervalForPeriod/getIntervalForPeriod";
import { CalendarIntervallType } from "../../types";
import * as css from "./ScheduleCalendarDateRow.scss";

type Props = {
  selectedPeriod: Date;
  selectedIntervalType: CalendarIntervallType;
  onPeriodChange: (date: Date) => void;
};

const ScheduleCalendarDateRow: React.FC<Props> = ({
  selectedPeriod,
  selectedIntervalType,
  onPeriodChange,
}) => {
  const isSelectedIntervalYear = selectedIntervalType === CalendarIntervallType.YEAR;

  const interval = getIntervalForPeriod(selectedPeriod, selectedIntervalType);
  const gridTemplateColumns = getGridTemplateColumns(interval);

  return (
    <div className={css.cell} style={gridTemplateColumns}>
      {interval.map((item) => {
        return (
          <div
            className={cx(
              css.item,
              isSelectedIntervalYear && isThisMonth(item) && css.currentDate,
              !isSelectedIntervalYear && isToday(item) && css.currentDate,
              !isSelectedIntervalYear && !isToday(item) && isWeekend(item) && css.weekend,
              isSelectedIntervalYear && css.clickableItem
            )}
            key={item.toString()}
            onClick={() => {
              if (isSelectedIntervalYear) {
                onPeriodChange(item);
              }
            }}
          >
            {isSelectedIntervalYear ? localizeMonth(item) : format(item, "d")}
          </div>
        );
      })}
    </div>
  );
};

export default ScheduleCalendarDateRow;
