import { formatMessage } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import InterconnectionOnlyText from "../../../../shared/components/inventoryRestrictionLabels/interconnectionOnlyText/InterconnectionOnlyText";
import StandaloneOnlyText from "../../../../shared/components/inventoryRestrictionLabels/standaloneOnlyText/StandaloneOnlyText";
import WarningTagText from "../../../../shared/components/warningTagText/WarningTagText";
import {
  InterconnectionRuleType,
  InventoryRestriction,
} from "../../../../shared/types/schema";
import {
  FindMobilityPartnerDetailsQuery,
  PartnerFragment,
} from "../../api/operations.generated";
import InterconnectionTagText from "../../ui/interconnectionTagText/InterconnectionTagText";

export const concessionOwnerIdMissingText = () =>
  formatMessage("partnerDetail.tag.noConcessionOwnerId");

const generatePartnerDetailTags = (
  partner: PartnerFragment,
  mobilityPartner?: FindMobilityPartnerDetailsQuery["findMobilityPartner"]
): Array<string | JSX.Element> => {
  const tags = [];

  if (partner.capacityDecreaseTimeFrameInHours) {
    tags.push(
      formatMessage("partnerDetail.tag.capacityDecreaseRule", {
        capacityDecreaseTimeFrameInHours: partner.capacityDecreaseTimeFrameInHours,
      })
    );
  }

  if (!partner.concessionOwnerId) {
    tags.push(<WarningTagText text={concessionOwnerIdMissingText()} />);
  }

  if (partner.apiSettings?.apiPartner) {
    tags.push(formatMessage("partner.apiPartner.label"));
  }

  if (mobilityPartner) {
    const { inventoryRestriction } = mobilityPartner;
    const { interconnectionRule } = mobilityPartner;
    const numberOfOperators = interconnectionRule?.operators?.length;

    if (inventoryRestriction === InventoryRestriction.StandaloneOnly) {
      tags.push(<StandaloneOnlyText />);
    }

    if (inventoryRestriction === InventoryRestriction.InterconnectionOnly) {
      tags.push(<InterconnectionOnlyText />);
    }

    if (
      [InventoryRestriction.None, InventoryRestriction.InterconnectionOnly].includes(
        inventoryRestriction
      ) &&
      interconnectionRule &&
      [InterconnectionRuleType.Whitelist, InterconnectionRuleType.Blacklist].includes(
        interconnectionRule.type
      ) &&
      numberOfOperators
    ) {
      tags.push(
        <InterconnectionTagText
          isWhitelist={interconnectionRule.type === InterconnectionRuleType.Whitelist}
          numberOfOperators={numberOfOperators}
        />
      );
    }
  }

  return tags;
};

export default generatePartnerDetailTags;
