import { Spinner } from "@flixbus/honeycomb-react";
import * as React from "react";
import * as css from "./LoadingAnimation.scss";

const LoadingAnimation: React.FC = () => {
  return (
    <div className={css.loading}>
      <Spinner />
    </div>
  );
};

export default LoadingAnimation;
