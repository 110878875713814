import { Select } from "@flixbus/honeycomb-react";
import * as React from "react";
import removeSpacesAndSpecialCharacters from "../../helpers/removeSpacesAndSpecialCharacters/removeSpacesAndSpecialCharacters";

type Props = {
  label?: string;
  info?: string;
};

const SelectApiError: React.FC<Props> = ({ label, info }) => {
  return (
    <Select
      id={`select-${removeSpacesAndSpecialCharacters(label) || Math.random()}`}
      label={label || ""}
      aria-label="select-error"
      valid={false}
      info={info}
    >
      <></>
    </Select>
  );
};

export default SelectApiError;
