import { NormalizedInterval } from "date-fns";
import { OriginalSchedulesOfLineQuery } from "../api/operations.generated";

export enum CalendarIntervalType {
  DAILY = "DAILY",
  MONTHLY = "MONTHLY",
}

export type InputSchedule = OriginalSchedulesOfLineQuery["findOriginalSchedules"][number];

export type CalendarSchedule = Omit<InputSchedule, "conceptChanges">;

export type OnSaleChunk<S extends CalendarSchedule> = {
  uiPeriod: {
    start: Date;
    end: Date;
  };
  isFirstOccurrence: boolean;
  schedules: Array<S>;
};

export type CalendarRow<S extends CalendarSchedule> = {
  onSale: Array<OnSaleChunk<S>>;
};

export type Calendar<S extends CalendarSchedule> = {
  intervalType: CalendarIntervalType;
  boundary: NormalizedInterval;
  rows: Array<CalendarRow<S>>;
};
