import { Button } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import buildPath from "../../../../routes/buildPath/buildPath";

type LineAddButtonProps = {
  partnerId: string;
};

const LineAddButton: React.FC<LineAddButtonProps> = ({ partnerId }) => {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    const createLinePath = buildPath.createLine(partnerId);
    navigate(createLinePath.pathname + createLinePath.search);
  };

  return (
    <Button appearance="primary" onClick={handleButtonClick} data-id="add-line-button">
      <FormattedMessage id="partnerDetail.lines.addLine.button" />
    </Button>
  );
};

export default LineAddButton;
