import { PopupSection } from "@flixbus/honeycomb-react";
import { Popup } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import CreateCity from "../../../city/createCity/CreateCity";
import CreateStation from "../../../station/createStation/CreateStation";
import { City, Station } from "../../types/schema";

export type CreateEntityPopupProps = {
  onEntityCreated: (value: Station["id"] | City["id"]) => void;
  onClose: () => void;
  inputValue?: string;
  type: "station" | "city";
};

const CreateEntityPopup: React.FC<CreateEntityPopupProps> = ({
  onEntityCreated,
  onClose,
  inputValue,
  type,
}) => {
  return (
    <Popup active onClose={onClose} isLeft>
      <PopupSection type="title">
        {type === "station" ? (
          <div>
            <FormattedMessage id="station.create" />
          </div>
        ) : (
          <div>
            <FormattedMessage id="navigation.createNewCity" />
          </div>
        )}
      </PopupSection>
      <PopupSection type="content">
        {type === "station" ? (
          <CreateStation
            onStationCreated={({ value }) => {
              onEntityCreated(value);
            }}
            onCancel={onClose}
            inputValue={inputValue}
          />
        ) : (
          <CreateCity
            onCityCreated={({ id }) => {
              onEntityCreated(id);
            }}
            onCancel={onClose}
            inputValue={inputValue}
          />
        )}
      </PopupSection>
    </Popup>
  );
};

export default CreateEntityPopup;
