import { RelationMatrixEntry } from "../../types/schema";

export const priceRegex: RegExp = /^([0-9]*|[0-9]+[.|,]|[0-9]+[.|,][0-9]{1,2})$/;

export const formatPriceInput = (priceInput: string): string => {
  if (!priceRegex.test(priceInput)) {
    return priceInput;
  }

  let valueToUpdate = priceInput;
  if (/^([0-9]+)$/.test(priceInput)) {
    valueToUpdate += ",00";
  }

  if (/^([0-9]+[.|,])$/.test(priceInput)) {
    valueToUpdate += "00";
  }

  if (/^([0-9]+[.|,][0-9])$/.test(priceInput)) {
    valueToUpdate += "0";
  }

  return valueToUpdate.replace(".", ",");
};

export const formatPriceNumberInput = (
  priceInput?: RelationMatrixEntry["price"]
): string => {
  // For priceInput = 0 it needs to be handled like this and not with if(priceInput) because if(0) is false :)
  if (priceInput !== undefined && priceInput !== null) {
    return formatPriceInput(priceInput.toString().replace(".", ","));
  }
  return "";
};
