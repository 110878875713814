// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// This file was automatically generated using npm run codegen
// If you want to alter this file please do so via the above mentioned command
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
import * as Types from "../../types/schema";

import { gql } from "@apollo/client";
export type IncludedLuggagePolicyDetailFragment = {
  __typename?: "IncludedLuggagePolicy";
  inTicketIncluded: boolean;
  luggageSpecification?: {
    __typename?: "IncludedLuggageSpecification";
    maxPieces?: number | null;
    maxWeight?: { __typename?: "WeightEntry"; value: number } | null;
    maxDimension?: { __typename?: "DimensionEntry"; value: string } | null;
  } | null;
};

export type PolicyDetailFragment = {
  __typename?: "Policy";
  luggagePolicy?: {
    __typename?: "LuggagePolicy";
    handLuggagePolicy?: {
      __typename?: "IncludedLuggagePolicy";
      inTicketIncluded: boolean;
      luggageSpecification?: {
        __typename?: "IncludedLuggageSpecification";
        maxPieces?: number | null;
        maxWeight?: { __typename?: "WeightEntry"; value: number } | null;
        maxDimension?: { __typename?: "DimensionEntry"; value: string } | null;
      } | null;
    } | null;
    checkInLuggagePolicy?: {
      __typename?: "IncludedLuggagePolicy";
      inTicketIncluded: boolean;
      luggageSpecification?: {
        __typename?: "IncludedLuggageSpecification";
        maxPieces?: number | null;
        maxWeight?: { __typename?: "WeightEntry"; value: number } | null;
        maxDimension?: { __typename?: "DimensionEntry"; value: string } | null;
      } | null;
    } | null;
    additionalLuggagePolicy?: {
      __typename?: "BookableLuggagePolicy";
      bookable: boolean;
      luggageSpecification?: {
        __typename?: "BookableLuggageSpecification";
        feeType?: Types.LuggageFeeType | null;
        pointOfSale?: Types.PointOfSaleType | null;
        maxWeight?: { __typename?: "WeightEntry"; value: number } | null;
        maxDimension?: { __typename?: "DimensionEntry"; value: string } | null;
        feePrice?: { __typename?: "MoneyEntry"; value: number } | null;
      } | null;
    } | null;
    specialLuggagePolicy?: {
      __typename?: "BookableLuggagePolicy";
      bookable: boolean;
      luggageSpecification?: {
        __typename?: "BookableLuggageSpecification";
        feeType?: Types.LuggageFeeType | null;
        pointOfSale?: Types.PointOfSaleType | null;
        maxWeight?: { __typename?: "WeightEntry"; value: number } | null;
        maxDimension?: { __typename?: "DimensionEntry"; value: string } | null;
        feePrice?: { __typename?: "MoneyEntry"; value: number } | null;
        bookableUntil?: {
          __typename?: "TimeEntry";
          value: number;
          unit: Types.TimeUnit;
        } | null;
      } | null;
    } | null;
    pushchairPolicy?: {
      __typename?: "BookableLuggagePolicy";
      bookable: boolean;
      luggageSpecification?: {
        __typename?: "BookableLuggageSpecification";
        feeType?: Types.LuggageFeeType | null;
        pointOfSale?: Types.PointOfSaleType | null;
        feePrice?: { __typename?: "MoneyEntry"; value: number } | null;
        bookableUntil?: {
          __typename?: "TimeEntry";
          value: number;
          unit: Types.TimeUnit;
        } | null;
      } | null;
    } | null;
    bikePolicy?: {
      __typename?: "BookableLuggagePolicy";
      bookable: boolean;
      luggageSpecification?: {
        __typename?: "BookableLuggageSpecification";
        pointOfSale?: Types.PointOfSaleType | null;
        feePrice?: { __typename?: "MoneyEntry"; value: number } | null;
        bookableUntil?: {
          __typename?: "TimeEntry";
          value: number;
          unit: Types.TimeUnit;
        } | null;
      } | null;
    } | null;
    surfboardPolicy?: {
      __typename?: "BookableLuggagePolicy";
      bookable: boolean;
      luggageSpecification?: {
        __typename?: "BookableLuggageSpecification";
        pointOfSale?: Types.PointOfSaleType | null;
        feePrice?: { __typename?: "MoneyEntry"; value: number } | null;
        bookableUntil?: {
          __typename?: "TimeEntry";
          value: number;
          unit: Types.TimeUnit;
        } | null;
      } | null;
    } | null;
    skiEquipmentPolicy?: {
      __typename?: "BookableLuggagePolicy";
      bookable: boolean;
      luggageSpecification?: {
        __typename?: "BookableLuggageSpecification";
        pointOfSale?: Types.PointOfSaleType | null;
        feePrice?: { __typename?: "MoneyEntry"; value: number } | null;
        bookableUntil?: {
          __typename?: "TimeEntry";
          value: number;
          unit: Types.TimeUnit;
        } | null;
      } | null;
    } | null;
    petPolicy?: {
      __typename?: "BookableLuggagePolicy";
      bookable: boolean;
      luggageSpecification?: {
        __typename?: "BookableLuggageSpecification";
        pointOfSale?: Types.PointOfSaleType | null;
        feePrice?: { __typename?: "MoneyEntry"; value: number } | null;
        bookableUntil?: {
          __typename?: "TimeEntry";
          value: number;
          unit: Types.TimeUnit;
        } | null;
      } | null;
    } | null;
  } | null;
  passengerPolicy?: {
    __typename?: "PassengerPolicy";
    unaccompaniedMinorPolicy?: {
      __typename?: "UnaccompaniedMinorPolicy";
      allowed: boolean;
      unaccompaniedMinorSpecification?: {
        __typename?: "UnaccompaniedMinorSpecification";
        minAgeYears?: number | null;
        internationalTravelAllowed: boolean;
        nightTravelAllowed: boolean;
        interconnectionTravelAllowed: boolean;
        internationalTravel?: {
          __typename?: "UnaccompaniedMinorAllowed";
          parentalConsentRequired?: boolean | null;
        } | null;
        nightTravel?: {
          __typename?: "UnaccompaniedMinorAllowed";
          parentalConsentRequired?: boolean | null;
        } | null;
        interconnectionTravel?: {
          __typename?: "UnaccompaniedMinorAllowed";
          parentalConsentRequired?: boolean | null;
        } | null;
      } | null;
    } | null;
    passengerWithDisabilityPolicy?: {
      __typename?: "PassengerWithDisabilityPolicy";
      supported: boolean;
      passengerWithDisabilitySpecification?: {
        __typename?: "PassengerWithDisabilitySpecification";
        proofOfDisabilityRequired?: boolean | null;
        registrationInstruction?: Types.DisabilitySupportRegistrationType | null;
        supportingServices: Array<Types.DisabilitySupportType>;
        registrationUntil?: {
          __typename?: "TimeEntry";
          value: number;
          unit: Types.TimeUnit;
        } | null;
      } | null;
    } | null;
  } | null;
};

export const IncludedLuggagePolicyDetailFragmentDoc = gql`
  fragment IncludedLuggagePolicyDetail on IncludedLuggagePolicy {
    inTicketIncluded
    luggageSpecification {
      maxWeight {
        value
      }
      maxDimension {
        value
      }
      maxPieces
    }
  }
`;
export const PolicyDetailFragmentDoc = gql`
  fragment PolicyDetail on Policy {
    luggagePolicy {
      handLuggagePolicy {
        ...IncludedLuggagePolicyDetail
      }
      checkInLuggagePolicy {
        ...IncludedLuggagePolicyDetail
      }
      additionalLuggagePolicy {
        bookable
        luggageSpecification {
          maxWeight {
            value
          }
          maxDimension {
            value
          }
          feeType
          feePrice {
            value
          }
          pointOfSale
        }
      }
      specialLuggagePolicy {
        bookable
        luggageSpecification {
          maxWeight {
            value
          }
          maxDimension {
            value
          }
          feeType
          feePrice {
            value
          }
          pointOfSale
          bookableUntil {
            value
            unit
          }
        }
      }
      pushchairPolicy {
        bookable
        luggageSpecification {
          feeType
          feePrice {
            value
          }
          pointOfSale
          bookableUntil {
            value
            unit
          }
        }
      }
      bikePolicy {
        bookable
        luggageSpecification {
          feePrice {
            value
          }
          pointOfSale
          bookableUntil {
            value
            unit
          }
        }
      }
      surfboardPolicy {
        bookable
        luggageSpecification {
          feePrice {
            value
          }
          pointOfSale
          bookableUntil {
            value
            unit
          }
        }
      }
      skiEquipmentPolicy {
        bookable
        luggageSpecification {
          feePrice {
            value
          }
          pointOfSale
          bookableUntil {
            value
            unit
          }
        }
      }
      petPolicy {
        bookable
        luggageSpecification {
          feePrice {
            value
          }
          pointOfSale
          bookableUntil {
            value
            unit
          }
        }
      }
    }
    passengerPolicy {
      unaccompaniedMinorPolicy {
        allowed
        unaccompaniedMinorSpecification {
          minAgeYears
          internationalTravelAllowed
          internationalTravel {
            parentalConsentRequired
          }
          nightTravelAllowed
          nightTravel {
            parentalConsentRequired
          }
          interconnectionTravelAllowed
          interconnectionTravel {
            parentalConsentRequired
          }
        }
      }
      passengerWithDisabilityPolicy {
        supported
        passengerWithDisabilitySpecification {
          proofOfDisabilityRequired
          registrationInstruction
          registrationUntil {
            value
            unit
          }
          supportingServices
        }
      }
    }
  }
  ${IncludedLuggagePolicyDetailFragmentDoc}
`;
