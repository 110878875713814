import { Infobox } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import * as css from "./NoConcessionOwnerIdInfo.scss";

const NoConcessionOwnerIdInfo: React.FC = () => {
  return (
    <Infobox appearance="warning" extraClasses={css.infobox}>
      <p className={css.infoHeader}>
        <FormattedMessage id="partnerForm.concessionOwnerId.info.headline" />
      </p>
      <p>
        <FormattedMessage id="partnerForm.concessionOwnerId.info.message" />
      </p>
    </Infobox>
  );
};

export default NoConcessionOwnerIdInfo;
