// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// This file was automatically generated using npm run codegen
// If you want to alter this file please do so via the above mentioned command
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
import * as Types from "../../../types/schema";

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
const defaultOptions = {} as const;
export type LineFragment = {
  __typename?: "Line";
  id: string;
  networkCode: string;
  partner: { __typename?: "Partner"; id: string; name: string };
};

export type FindLineQueryVariables = Types.Exact<{
  lineId: Types.Scalars["ID"]["input"];
}>;

export type FindLineQuery = {
  __typename?: "Query";
  findLine: {
    __typename?: "Line";
    id: string;
    networkCode: string;
    partner: { __typename?: "Partner"; id: string; name: string };
  };
};

export type FindScheduleQueryVariables = Types.Exact<{
  scheduleId: Types.Scalars["ID"]["input"];
}>;

export type FindScheduleQuery = {
  __typename?: "Query";
  findSchedule: {
    __typename?: "Schedule";
    id: string;
    name: string;
    line: {
      __typename?: "Line";
      id: string;
      networkCode: string;
      partner: { __typename?: "Partner"; id: string; name: string };
    };
  };
};

export const LineFragmentDoc = gql`
  fragment LINE on Line {
    id
    networkCode
    partner {
      id
      name
    }
  }
`;
export const FindLineDocument = gql`
  query findLine($lineId: ID!) {
    findLine(id: $lineId) {
      ...LINE
    }
  }
  ${LineFragmentDoc}
`;

/**
 * __useFindLineQuery__
 *
 * To run a query within a React component, call `useFindLineQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindLineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindLineQuery({
 *   variables: {
 *      lineId: // value for 'lineId'
 *   },
 * });
 */
export function useFindLineQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<FindLineQuery, FindLineQueryVariables> &
    ({ variables: FindLineQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<FindLineQuery, FindLineQueryVariables>(
    FindLineDocument,
    options
  );
}
export function useFindLineLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FindLineQuery,
    FindLineQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<FindLineQuery, FindLineQueryVariables>(
    FindLineDocument,
    options
  );
}
export function useFindLineSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    FindLineQuery,
    FindLineQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<FindLineQuery, FindLineQueryVariables>(
    FindLineDocument,
    options
  );
}
export type FindLineQueryHookResult = ReturnType<typeof useFindLineQuery>;
export type FindLineLazyQueryHookResult = ReturnType<typeof useFindLineLazyQuery>;
export type FindLineSuspenseQueryHookResult = ReturnType<typeof useFindLineSuspenseQuery>;
export const FindScheduleDocument = gql`
  query findSchedule($scheduleId: ID!) {
    findSchedule(id: $scheduleId) {
      id
      name
      line {
        ...LINE
      }
    }
  }
  ${LineFragmentDoc}
`;

/**
 * __useFindScheduleQuery__
 *
 * To run a query within a React component, call `useFindScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindScheduleQuery({
 *   variables: {
 *      scheduleId: // value for 'scheduleId'
 *   },
 * });
 */
export function useFindScheduleQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FindScheduleQuery,
    FindScheduleQueryVariables
  > &
    ({ variables: FindScheduleQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<FindScheduleQuery, FindScheduleQueryVariables>(
    FindScheduleDocument,
    options
  );
}
export function useFindScheduleLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FindScheduleQuery,
    FindScheduleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<FindScheduleQuery, FindScheduleQueryVariables>(
    FindScheduleDocument,
    options
  );
}
export function useFindScheduleSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    FindScheduleQuery,
    FindScheduleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<FindScheduleQuery, FindScheduleQueryVariables>(
    FindScheduleDocument,
    options
  );
}
export type FindScheduleQueryHookResult = ReturnType<typeof useFindScheduleQuery>;
export type FindScheduleLazyQueryHookResult = ReturnType<typeof useFindScheduleLazyQuery>;
export type FindScheduleSuspenseQueryHookResult = ReturnType<
  typeof useFindScheduleSuspenseQuery
>;
