@import "variables";

.chip {
  padding: 3px $spacing-2;
  border-radius: 9px;
  height: 30px;
  background-color: $bg-secondary-color;
  color: $content-secondary-color;
  cursor: pointer;
}

.highlighted {
  background-color: $highlight-color;
  color: $ui-primary-dark-color;
  font-weight: $font-weight-bold;
}

.icon {
  cursor: pointer;
  fill: $icon-primary-color !important;
  margin-bottom: 3px;
  margin-left: $spacing-half;
}
