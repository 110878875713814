import { Button } from "@flixbus/honeycomb-react";
import * as React from "react";
import { TranslateFunctionProp } from "../../../../../helper/types";

type Props = {
  primary: Boolean;
};

const RetryButton: React.FC<Props & TranslateFunctionProp> = ({ primary, translate }) => (
  <Button
    data-id="retry-button"
    appearance={primary ? "primary" : undefined}
    onClick={() => {
      window.localStorage.clear();
      window.location.reload();
    }}
  >
    {translate("retry.button.text")}
  </Button>
);

export default RetryButton;
