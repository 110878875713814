import { useReactiveVar } from "@apollo/client";
import * as React from "react";
import { useIntl } from "react-intl";
import FilterInput from "../../../shared/components/filterInput/FilterInput";
import lineFilterVar from "../../../state/lineFilter/lineFilter";
import partnerFilterVar from "../../../state/partnerFilter/partnerFilter";
import { SearchType } from "../../types";
import * as css from "./SearchBarFilterInput.scss";

type Props = {
  type: SearchType;
};

const SearchBarFilterInput: React.FC<Props> = ({ type }) => {
  const { formatMessage } = useIntl();
  const partnerFilters = useReactiveVar(partnerFilterVar);
  const lineFilters = useReactiveVar(lineFilterVar);

  return (
    <div className={css.wrapper}>
      <FilterInput
        label={formatMessage({ id: "searchPage.filterInput.label" })}
        value={
          type === SearchType.PARTNERS
            ? partnerFilters.searchTerm
            : lineFilters.searchTerm
        }
        onChange={(searchTerm) => {
          if (type === SearchType.PARTNERS) {
            partnerFilterVar({ ...partnerFilters, searchTerm });
          }
          if (type === SearchType.LINES) {
            lineFilterVar({ ...lineFilters, searchTerm });
          }
        }}
      />
    </div>
  );
};

export default SearchBarFilterInput;
