import {
  addNotification,
  formatErrorMessage,
  NotificationType,
} from "@flixbus-phx/marketplace-common";
import * as React from "react";
import AddNewButton from "../../../../shared/components/addNewButton/AddNewButton";
import { TripDirection } from "../../../../shared/types/schema";
import {
  FindScheduleWithTripsAndStationsQuery,
  TripFragment,
  useAddTripToScheduleMutation,
} from "../../api/operations.generated";
import { addTripToCache } from "../../helpers/cacheHandler/cacheHandler";
import TripColumn from "../tripColumn/TripColumn";

type Props = {
  trips: Array<TripFragment>;
  scheduleId: FindScheduleWithTripsAndStationsQuery["findSchedule"]["id"];
  direction: TripDirection;
  readOnly: boolean;
};
const TripTable: React.FC<Props> = ({ scheduleId, direction, readOnly, trips }) => {
  const [addTrip] = useAddTripToScheduleMutation({
    update: (cache, { data }) => {
      addTripToCache(cache, data?.newTrip, scheduleId);
    },
    variables: { scheduleId, direction },
    onError: (error) => {
      addNotification({
        message: `Adding trip failed: ${formatErrorMessage(error.message)}`,
        type: NotificationType.Danger,
      });
    },
  });

  return (
    <>
      {trips.map((trip: TripFragment, index: number): JSX.Element => {
        return (
          <TripColumn
            key={trip.id}
            scheduleId={scheduleId}
            trip={trip}
            columnPosition={index + 1}
            readOnly={readOnly}
          />
        );
      })}
      {!readOnly && <AddNewButton onButtonClick={addTrip} position={trips.length + 1} />}
    </>
  );
};

export default TripTable;
