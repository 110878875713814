@import "variables";

.wrapper {
  display: flex;
}

.unit {
  margin-right: $spacing-4;
}

.currencySelector {
  width: 120px;
}
