import { Box, Grid, GridCol, Skeleton, Tag } from "@flixbus/honeycomb-react";
import cx from "classnames";
import * as React from "react";
import * as css from "./DetailSummarySkeleton.scss";

type Props = {
  addTopMargin?: boolean;
};

const DetailSummarySkeleton: React.FC<Props> = ({ addTopMargin }) => (
  <div className={cx(css.skeletonWrapper, addTopMargin && css.addTopMargin)}>
    <div className={css.skeletonContainer}>
      <Grid>
        <GridCol size={5}>
          <Box highlighted extraClasses={css.box}>
            <Grid>
              <GridCol size={1}>
                <Skeleton height="lg" width="lg" />
              </GridCol>
              <GridCol size={11}>
                <Skeleton height="lg" width="sm" />
                <Skeleton height="sm" width="md" flushBottom />
              </GridCol>
            </Grid>
          </Box>
        </GridCol>

        <GridCol size={6}>
          <div className={css.tagSkeletonContainer}>
            <Tag display="outlined" extraClasses={css.tag}>
              <Skeleton flushBottom extraClasses={css.skeleton} />
            </Tag>
          </div>
        </GridCol>
      </Grid>
    </div>
  </div>
);

export default DetailSummarySkeleton;
