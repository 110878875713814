@import "variables";

.selectGroup {
  margin-bottom: $spacing-3;

  ul {
    width: 100%;
  }

  li {
    flex-grow: 1;
  }
}
