import { Icon, IconInfo } from "@flixbus/honeycomb-icons-react";
import { Fineprint } from "@flixbus/honeycomb-react";
import { useField } from "formik2";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ReadOnlyFormField from "../../../../shared/components/readOnlyFormField/ReadOnlyFormField";
import { CutOffSalesRule, TimeUnit } from "../../../../shared/types/schema";
import { convertDurationToTime } from "../../../lineDetail/helpers/lineDetailHelper/lineDetailHelper";
import { CutOffSalesDuration } from "../../types";

const ReadOnlyCutOffSalesSelector: React.FC = () => {
  const [, metaRule] = useField<CutOffSalesRule>("cutOffSales.cutOffSalesRule");
  const { formatMessage } = useIntl();

  const [, metaTime] = useField<CutOffSalesDuration["value"]>(
    "cutOffSales.cutOffSalesDuration.value"
  );

  const [, metaUnit] = useField<CutOffSalesDuration["unit"]>(
    "cutOffSales.cutOffSalesDuration.unit"
  );
  const getFieldText = () => {
    switch (metaRule.value) {
      case CutOffSalesRule.BeforeDeparture:
        if (metaUnit.value === TimeUnit.Hours) {
          return formatMessage(
            { id: "lineDetail.tag.salesCutOffInHours" },
            {
              cutOffSalesInHours: metaTime.value,
            }
          );
        }
        return formatMessage(
          { id: "lineDetail.tag.salesCutOffInMinutes" },
          {
            cutOffSalesInMinutes: metaTime.value,
          }
        );
      case CutOffSalesRule.BeforeDayOfDeparture:
        return formatMessage(
          { id: "lineDetail.tag.salesCutOffAtTime" },
          {
            cutOffSalesAtTime: convertDurationToTime(metaTime.value, metaUnit.value),
          }
        );
      default:
        return formatMessage({ id: "lineDetail.readOnly.cutOffSalesRule.notSet" });
    }
  };
  return (
    <>
      <ReadOnlyFormField
        label={formatMessage({ id: "lineForm.cutOffSalesRule.checkbox.label" })}
      >
        {getFieldText()}
      </ReadOnlyFormField>
      <Fineprint>
        <Icon InlineIcon={IconInfo} />
        <FormattedMessage id="lineForm.cutOffSalesRule.info" />
      </Fineprint>
    </>
  );
};

export default ReadOnlyCutOffSalesSelector;
