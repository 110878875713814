import * as React from "react";
import { useParams } from "react-router-dom";
import LineDetail from "../../line/lineDetail/LineDetail";
import ScheduleCalendar from "../../schedule/scheduleCalendar/ScheduleCalendar";
import ScheduleCalendarHeader from "../../schedule/scheduleCalendar/ui/scheduleCalendarHeader/ScheduleCalendarHeader";
import Breadcrumbs from "../../shared/components/breadcrumbs/Breadcrumbs";
import Layout from "../../ui/Layout";

const ShowLineDetailV2: React.FC = () => {
  const params = useParams();
  const lineId = params.lineId!;

  return (
    <>
      <Layout>
        <Breadcrumbs lineId={lineId} />
      </Layout>
      <Layout useFullscreen noTopPadding clearLeft>
        <LineDetail lineId={lineId} />
      </Layout>
      <Layout noTopPadding>
        <ScheduleCalendarHeader lineId={lineId} />
        <ScheduleCalendar lineId={lineId} />
      </Layout>
    </>
  );
};

export default ShowLineDetailV2;
