/**
 * Validates ProgressTrackerItem completedSrText prop.
 */
var progressTrackerItemCompletedValidation = function () {
  return function (_a) {
    var completed = _a.completed,
      completedSrText = _a.completedSrText;
    if (completed && !(completedSrText === null || completedSrText === void 0 ? void 0 : completedSrText.trim())) {
      return new Error('ProgressTrackerItem component requires a screen reader friendly text as `completedSrText` when the item is marked as `completed`.');
    }
    return null;
  };
};

export { progressTrackerItemCompletedValidation };
