import { Icon, IconClose } from "@flixbus/honeycomb-icons-react";
import { Button, Input } from "@flixbus/honeycomb-react";
import { debounce } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { useIntl } from "react-intl";
import * as css from "./FilterInput.scss";

type Props = {
  label: string;
  value: string | null;
  onChange: (value: string | null) => void;
};

const debounced = debounce((f: Function) => f(), 300);

const FilterInput: React.FC<Props> = ({ label, value, onChange }) => {
  const { formatMessage } = useIntl();
  const [inputValue, setInputValue] = React.useState(value !== null ? value : "");

  React.useEffect(() => {
    if (inputValue !== value) {
      setInputValue(value !== null ? value : "");
    }
  }, [value]);

  const getClearIcon = () => {
    if (inputValue !== "") {
      return (
        <Button
          aria-label="Clear input"
          onClick={() => {
            setInputValue("");
            onChange(null);
          }}
        >
          <Icon InlineIcon={IconClose} />
        </Button>
      );
    }

    return undefined;
  };

  return (
    <Input
      id={label}
      label={label}
      placeholder={formatMessage({ id: "input.placeholder.search" })}
      value={inputValue}
      onChange={({ target }) => {
        setInputValue(target.value);
        debounced(() => onChange(target.value));
      }}
      extraClasses={css.input}
      iconRight={getClearIcon()}
    />
  );
};

export default FilterInput;
