import {
  Icon,
  IconArrowDown,
  IconArrowUp,
  IconComponent,
} from "@flixbus/honeycomb-icons-react";
import { Heading } from "@flixbus/honeycomb-react";
import { useFormikContext } from "formik2";
import * as React from "react";
import * as css from "./FormikAccordion.scss";

type Props<T> = {
  title: string;
  name: keyof T;
  icon?: IconComponent;
  children?: React.ReactNode;
  openInitially?: boolean;
  onToggle?: (prevState: boolean) => void;
};

// eslint-disable-next-line react/function-component-definition
function FormikAccordion<T>({
  title,
  name,
  icon,
  children,
  openInitially = false,
  onToggle,
}: Props<T>): React.ReactElement | null {
  const [open, setOpen] = React.useState(openInitially);

  const { submitCount, errors: errorsFormik } = useFormikContext<T>();

  const errors = errorsFormik && errorsFormik[name];

  React.useEffect(() => {
    if (errors && Object.keys(errors).length !== 0 && submitCount > 0) {
      setOpen(true);
    }
  }, [submitCount]);

  React.useEffect(() => {
    if (openInitially) {
      setOpen(openInitially);
    }
  }, [openInitially]);

  return (
    <div className={css.accordion}>
      <div
        data-id="accordion-header-section"
        className={css.headerSection}
        onClick={() => {
          if (onToggle) {
            onToggle(open);
          }

          setOpen((prevState) => !prevState);
        }}
      >
        {icon && (
          <Icon
            data-id="icon-policy"
            InlineIcon={icon}
            size={4}
            extraClasses={css.headerIcon}
          />
        )}
        <div className={css.headerSectionContent}>
          <Heading size={4} extraClasses={css.heading}>
            {title}
          </Heading>
        </div>
        {open ? (
          <div>
            <Icon data-id="icon-arrow-up" InlineIcon={IconArrowUp} size={4} />
          </div>
        ) : (
          <div>
            <Icon data-id="icon-arrow-down" InlineIcon={IconArrowDown} size={4} />
          </div>
        )}
      </div>
      {open && <div className={css.children}>{children}</div>}
    </div>
  );
}

export default FormikAccordion;
