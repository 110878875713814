import { FormRow, PopupSection } from "@flixbus/honeycomb-react";
import { Popup } from "@flixbus-phx/marketplace-common";
import { Form, Formik } from "formik2";
import * as React from "react";
import FormikButtons from "../../../../shared/components/formikButtons/FormikButtons";
import FormikInput from "../../../../shared/components/formikInput/FormikInput";
import FormikSelect from "../../../../shared/components/formikSelect/FormikSelect";
import { ScheduleStatus } from "../../../../shared/types/schema";
import scheduleStatusSelectOptions from "../../helpers/scheduleStatusSelectOptions/scheduleStatusSelectOptions";
import validationSchema from "../../helpers/validationSchema/validationSchema";
import * as css from "./ScheduleStatusChangePopup.scss";

export type ScheduleStatusChangePopupProps = {
  active: boolean;
  onPopupClose: () => void;
  onSubmit: (status: ScheduleStatus, comment: string) => void;
};

const ScheduleStatusChangePopup: React.FC<ScheduleStatusChangePopupProps> = ({
  active,
  onPopupClose,
  onSubmit,
}) => {
  return (
    <Popup active={active} onClose={onPopupClose} isWide isLeft>
      <PopupSection type="title">Change Schedule Status</PopupSection>
      <PopupSection type="content" extraClasses={css.content}>
        {active && (
          <Formik
            validationSchema={validationSchema}
            initialValues={{ newStatus: "" as unknown as ScheduleStatus, comment: "" }}
            onSubmit={(values) => {
              onSubmit(values.newStatus, values.comment);
            }}
            onReset={onPopupClose}
          >
            <Form noValidate>
              <FormRow>
                <FormikSelect
                  label="Status"
                  name="newStatus"
                  options={scheduleStatusSelectOptions()}
                  required
                  placeholder="Select a status"
                />
              </FormRow>

              <FormRow>
                <FormikInput
                  name="comment"
                  type="text"
                  label="Comment"
                  placeholder="Why did you change the status?"
                  required
                />
              </FormRow>

              <FormikButtons
                submitButtonText="Change Status"
                submitButtonAppearance="primary"
                resetButtonText="Cancel"
                resetButtonAppearance="tertiary"
              />
            </Form>
          </Formik>
        )}
      </PopupSection>
    </Popup>
  );
};

export default ScheduleStatusChangePopup;
