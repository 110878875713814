import * as React from "react";
import { useParams } from "react-router-dom";
import PartnerLineList from "../../line/partnerLineList/PartnerLineList";
import PartnerDetail from "../../partner/partnerDetail/PartnerDetail";
import Layout from "../../ui/Layout";

const ShowPartnerDetail: React.FC = () => {
  const { partnerId } = useParams();

  return (
    <>
      <Layout useFullscreen clearLeft>
        <PartnerDetail partnerId={partnerId!} />
      </Layout>
      <Layout noTopPadding>
        <PartnerLineList partnerId={partnerId!} />
      </Layout>
    </>
  );
};

export default ShowPartnerDetail;
