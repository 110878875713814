@import "variables";

.wrapper {
  margin-top: $spacing-2;
  margin-left: calc(30px + #{$spacing-2});
}

.feePriceWrapper {
  display: flex;
  flex-direction: row;
  column-gap: $spacing-2;
  margin-bottom: $spacing-2;
}

.selectWrapper {
  width: 96px;
}

.inputWrapper {
  width: 186px;
}
