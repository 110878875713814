import getIsoCountryFromString from "../../../../shared/helpers/getIsoCountryFromString/getIsoCountryFromString";
import { DayOfWeek, IsoCountry } from "../../../../shared/types/schema";
import { StationFormDefaultValues, StationFormValues } from "../../types";
import convertOpeningHour from "../convertOpeningHour/convertOpeningHour";
import getOpeningHoursInitialValues from "../getOpeningHoursInitialValues/getOpeningHoursInitialValues";

export default (
  stationName?: string,
  defaultValues?: StationFormDefaultValues
): StationFormValues => ({
  name: defaultValues?.name || stationName || "",
  timezone: defaultValues?.timeZone.zoneId || "",
  shortName: defaultValues?.shortName || "",
  code: defaultValues?.code || "",
  latitude: defaultValues?.latitude || "",
  longitude: defaultValues?.longitude || "",
  countryCode: defaultValues?.countryCode
    ? getIsoCountryFromString(defaultValues.countryCode)
    : ("" as IsoCountry),
  cityId: defaultValues?.city?.id || "",
  addressLines: defaultValues?.addressLines || [""],
  zipCode: defaultValues?.zipCode || "",
  addressCity: defaultValues?.addressCity || "",
  transferInformation: {
    isTransferStation: defaultValues?.transferInformation
      ? defaultValues.transferInformation.isTransferStation
      : false,
    minimumTransferMinutes: defaultValues?.transferInformation
      ? defaultValues.transferInformation.minimumTransferMinutes
      : 60,
    transferOpeningHours: defaultValues?.transferInformation
      ? getOpeningHoursInitialValues(
          defaultValues.transferInformation.transferOpeningHours
        )
      : Object.values(DayOfWeek).map((weekday: DayOfWeek) => [
          convertOpeningHour({ weekday, start: "00:00", end: "23:59" }),
        ]),
  },
});
