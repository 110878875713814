import { Icon, IconComponent } from "@flixbus/honeycomb-icons-react";
import { Tag } from "@flixbus/honeycomb-react";
import * as React from "react";
import * as css from "./FilterTag.scss";

export type FilterTagProps = {
  LabelIcon: IconComponent;
  label: string;
  onClose: () => void;
};

const FilterTag: React.FC<FilterTagProps> = ({ LabelIcon, label, onClose }) => {
  return (
    <Tag
      display="subtle"
      closeProps={{
        label: "Remove Filter",
        onClick: onClose,
      }}
    >
      <Icon extraClasses={css.icon} InlineIcon={LabelIcon} />
      {label}
    </Tag>
  );
};

export default FilterTag;
