@import "variables";

.buttonWrapper {
  text-align: center;
  line-height: $input-height-desktop;
  font-weight: $font-weight-bold;
}

.buttonLeft {
  float: left;
}

.buttonRight {
  float: right;
}
