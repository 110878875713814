import { Box, Grid, GridCol, Heading } from "@flixbus/honeycomb-react";
import { Feature, hasUserPermission } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import EditableDatepicker from "../../../../shared/components/editableDatepicker/EditableDatepicker";
import InfoTooltipLabel from "../../../../shared/components/infoTooltipLabel/InfoTooltipLabel";
import Select from "../../../../shared/components/select/Select";
import createCirculationOption from "../../../../shared/helpers/reactSelect/createCirculationOption/createCirculationOption";
import {
  ScheduleDetailFragment,
  UpdateScheduleMutationVariables,
} from "../../api/operations.generated";
import NonOperatingPeriodInput from "../nonOperatingPeriodInput/NonOperatingPeriodInput";

export type ScheduleDetailBoxProps = {
  schedule: ScheduleDetailFragment;
  onHandleScheduleChange: (
    value:
      | NonNullable<ScheduleDetailFragment["circulation"]>["uuid"]
      | ScheduleDetailFragment["period"]
      | ScheduleDetailFragment["nonOperatingPeriods"]
      | null,
    key: keyof UpdateScheduleMutationVariables
  ) => void;
};

const ScheduleDetailBox: React.FC<ScheduleDetailBoxProps> = ({
  schedule,
  onHandleScheduleChange,
}) => {
  const { formatMessage } = useIntl();
  const capacityOptions = schedule.line.partner.circulations
    ? schedule.line.partner.circulations.map((circulation) =>
        createCirculationOption(circulation)
      )
    : [];

  return (
    <Box data-id="schedule-data">
      <Grid>
        <GridCol size={6}>
          <Heading size={4}>
            <span>
              <FormattedMessage id="schedule.period" />
            </span>
          </Heading>
          <EditableDatepicker
            value={schedule.period}
            onValueChange={(value) => onHandleScheduleChange(value, "period")}
            readOnly={
              schedule.readOnly ||
              !hasUserPermission(Feature.EDIT_SCHEDULE_PERIOD_UNPUBLISHED)
            }
          />
        </GridCol>

        <GridCol size={6}>
          <Heading size={4}>
            <InfoTooltipLabel
              labelText={formatMessage({ id: "scheduleDetail.capacity.label" })}
              tooltipContent={formatMessage({ id: "scheduleDetail.circulationId.info" })}
            />
          </Heading>
          <Select
            placeholder={formatMessage({
              id: "partnerDetail.defaultCapacity.placeholder",
            })}
            initialValue={capacityOptions.find(
              (option) => option.value === schedule.circulation?.uuid
            )}
            options={capacityOptions}
            onSelect={(selectedValue) =>
              onHandleScheduleChange(selectedValue.value, "circulationId")
            }
            isDisabled={
              schedule.readOnly ||
              !hasUserPermission(Feature.EDIT_SCHEDULE_INITIAL_CAPACITY_UNPUBLISHED)
            }
          />
        </GridCol>
      </Grid>
      <Grid>
        <GridCol size={12}>
          <NonOperatingPeriodInput
            periods={schedule.nonOperatingPeriods}
            onValueChanged={(value) =>
              onHandleScheduleChange(value, "nonOperatingPeriods")
            }
            schedulePeriod={schedule.period}
            readOnly={
              schedule.readOnly ||
              !hasUserPermission(Feature.EDIT_SCHEDULE_NON_OPERATIONAL_PERIOD_UNPUBLISHED)
            }
          />
        </GridCol>
      </Grid>
    </Box>
  );
};

export default ScheduleDetailBox;
