import { Skeleton } from "@flixbus/honeycomb-react";
import * as React from "react";
import { useIntl } from "react-intl";
import * as css from "./ResultCount.scss";

export type ResultCountProps = {
  amount?: number;
  type: "line" | "partner";
  loading: boolean;
};

const ResultCount: React.FC<ResultCountProps> = ({ amount, type, loading }) => {
  const { formatPlural, formatMessage } = useIntl();

  if (loading) {
    return (
      <div className={css.wrapper}>
        <Skeleton flushBottom extraClasses={css.skeleton} />
      </div>
    );
  }
  if (amount) {
    return (
      <div className={css.wrapper}>
        <span
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: formatMessage(
              { id: `searchPage.resultCount.${type}.${formatPlural(amount)}` },
              {
                amount,
              },
              { ignoreTag: true }
            ),
          }}
        />
      </div>
    );
  }

  return null;
};

export default ResultCount;
