import { ScheduleStatus } from "../../../../shared/types/schema";

const getIfCreateConceptChangeIsPossible = (
  scheduleStatus: ScheduleStatus,
  originalScheduleStatus?: ScheduleStatus
) => {
  const feasibleStates = [
    ScheduleStatus.Published,
    ScheduleStatus.Accepted,
    ScheduleStatus.PricesPublished,
    ScheduleStatus.OnSale,
    ScheduleStatus.OutOfSale,
  ];

  if (!originalScheduleStatus) {
    if (feasibleStates.includes(scheduleStatus)) {
      return true;
    }

    return false;
  }

  if (feasibleStates.includes(originalScheduleStatus)) {
    return true;
  }

  return false;
};

export default getIfCreateConceptChangeIsPossible;
