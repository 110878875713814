import { Link } from "@flixbus/honeycomb-react";
import * as React from "react";
import { Feature, hasUserPermission } from "../../auth";
import contactEmail from "../../helper/contact/contactEmail";
import { commonPathnames } from "../../helper/pathnames/pathnames";

type Props = {
  translate: (key: string) => string;
};

const ContactUsLink: React.FC<Props> = ({ translate }) => {
  const contactLink = hasUserPermission(Feature.USER_HAS_SERVICEDESK_ACCESS)
    ? commonPathnames.serviceDeskReportProblem
    : `mailto:${contactEmail}`;
  return (
    <Link href={contactLink} target="_blank" rel="noreferrer">
      {translate("general.contactUs")}
    </Link>
  );
};

export default ContactUsLink;
