import { useReactiveVar } from "@apollo/client";
import { Icon, IconClose } from "@flixbus/honeycomb-icons-react";
import { Button } from "@flixbus/honeycomb-react";
import { readURIParams } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import FilterInput from "../../../../shared/components/filterInput/FilterInput";
import { StationStatus } from "../../../../shared/types/schema";
import { selectedStationVar } from "../../../../state/reactiveVariables/reactiveVariables";
import { stationsFilterVar } from "../../../../state/stationsFilter/stationsFilter";
import useSelectedStation from "../../../../state/useSelectedStation/useSelectedStation";
import { clearStationsFilter } from "../../helper/clearFilter/clearFilter";
import * as css from "../../helper/styles/styles.scss";
import FilterCountrySelect from "../../ui/filterCountrySelect/FilterCountrySelect";
import FilterSelect from "../../ui/filterSelect/FilterSelect";
import FilterCitySelect from "../filterCitySelect/FilterCitySelect";

const StationsFilter: React.FC = () => {
  const { formatMessage } = useIntl();
  const filter = useReactiveVar(stationsFilterVar);

  const uriParams = readURIParams(window.location.href);
  const stationId = uriParams.station_id;
  const stationName = uriParams.station_name;

  const [, changeSelectedStation] = useSelectedStation(selectedStationVar);

  React.useEffect(() => {
    if (stationId) {
      changeSelectedStation(stationId);
    }
    if (stationName) {
      stationsFilterVar({ ...filter, name: stationName });
    }
  }, [stationId, stationName]);

  return (
    <div className={css.filterContainer}>
      <div className={css.filterWrapper}>
        <FilterInput
          label={formatMessage({ id: "general.name" })}
          value={filter.name}
          onChange={(name) => {
            stationsFilterVar({ ...filter, name });
          }}
        />
      </div>
      <div className={css.filterWrapper}>
        <FilterInput
          label={formatMessage({ id: "station.code" })}
          value={filter.code}
          onChange={(code) => {
            stationsFilterVar({ ...filter, code });
          }}
        />
      </div>
      <div className={css.filterWrapper}>
        <FilterCitySelect
          selected={filter.city}
          onChange={(city) => {
            stationsFilterVar({ ...filter, city });
          }}
        />
      </div>
      <div className={css.filterWrapper}>
        <FilterCountrySelect
          selected={filter.country}
          onChange={(country) => {
            stationsFilterVar({ ...filter, country });
          }}
        />
      </div>
      <div className={css.filterWrapper}>
        <FilterSelect
          data-id="status-filter"
          label={formatMessage({ id: "general.status" })}
          options={Object.values(StationStatus).map((status) => {
            return {
              value: status,
              label: formatMessage({ id: `stationStatus.${status}` }),
            };
          })}
          selected={filter.status !== null ? filter.status : null}
          onChange={(status) => {
            stationsFilterVar({
              ...filter,
              status: status === null ? null : (status as StationStatus),
            });
          }}
        />
      </div>
      <div className={css.filterButtonWrapper}>
        <Button onClick={clearStationsFilter}>
          <Icon InlineIcon={IconClose} />
          <FormattedMessage id="filter.resetButton.text" />
        </Button>
      </div>
    </div>
  );
};

export default StationsFilter;
