/* eslint react/no-danger: 0 */
import { IconRefresh } from "@flixbus/honeycomb-icons-react";
import { Fieldset, Radio, Text } from "@flixbus/honeycomb-react";
import { isEnumValue } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { useIntl } from "react-intl";
import getUnitText from "../../helpers/getUnitText/getUnitText";
import { Currency, LengthUnit, WeightUnit } from "../../types/schema";
import ConfirmationPopup from "../confirmationPopup/ConfirmationPopup";
import * as css from "./UnitChangePopup.scss";

export type UnitChangePopupUnits = Currency | LengthUnit | WeightUnit;

export type UnitChangePopupProps = {
  currentUnit?: UnitChangePopupUnits;
  newUnit: UnitChangePopupUnits;
  onCancel: () => void;
  onConfirm: (resetValues: boolean) => void;
  infoText?: string;
};

const UnitChangePopup: React.FC<UnitChangePopupProps> = ({
  currentUnit,
  newUnit,
  onCancel,
  onConfirm,
  infoText,
}) => {
  const { formatMessage } = useIntl();

  const [resetValues, setResetValues] = React.useState(false);

  return (
    <ConfirmationPopup
      active
      titleIcon={IconRefresh}
      titleText={
        isEnumValue(Currency, currentUnit)
          ? formatMessage(
              { id: "unitChange.popup.title.currency" },
              { currentUnit, newUnit }
            )
          : isEnumValue(WeightUnit, currentUnit)
            ? formatMessage(
                { id: "unitChange.popup.title.weight" },
                {
                  currentUnit: getUnitText(currentUnit as WeightUnit),
                  newUnit: getUnitText(newUnit as WeightUnit),
                }
              )
            : formatMessage(
                { id: "unitChange.popup.title.length" },
                {
                  currentUnit: getUnitText(currentUnit as LengthUnit),
                  newUnit: getUnitText(newUnit as LengthUnit),
                }
              )
      }
      onCancel={onCancel}
      onConfirm={() => onConfirm(resetValues)}
    >
      <Fieldset extraClasses={css.fieldset}>
        {infoText && (
          <Text data-id="infoText" extraClasses={css.infoText}>
            {infoText}
          </Text>
        )}
        <Radio
          label={
            <div
              dangerouslySetInnerHTML={{
                __html: isEnumValue(Currency, currentUnit)
                  ? formatMessage(
                      { id: "unitChange.popup.radio.keepValues.currency" },
                      {},
                      { ignoreTag: true }
                    )
                  : isEnumValue(LengthUnit, currentUnit)
                    ? formatMessage(
                        { id: "unitChange.popup.radio.keepValues.length" },
                        {},
                        { ignoreTag: true }
                      )
                    : formatMessage(
                        { id: "unitChange.popup.radio.keepValues.weight" },
                        {},
                        { ignoreTag: true }
                      ),
              }}
            />
          }
          id="keepValues"
          name="keepValues"
          value="keepValues"
          checked={!resetValues}
          onChange={() => setResetValues(false)}
        />
        <Radio
          label={
            <div
              dangerouslySetInnerHTML={{
                __html: isEnumValue(Currency, currentUnit)
                  ? formatMessage(
                      { id: "unitChange.popup.radio.resetValues.currency" },
                      {},
                      { ignoreTag: true }
                    )
                  : isEnumValue(LengthUnit, currentUnit)
                    ? formatMessage(
                        { id: "unitChange.popup.radio.resetValues.length" },
                        {},
                        { ignoreTag: true }
                      )
                    : formatMessage(
                        { id: "unitChange.popup.radio.resetValues.weight" },
                        {},
                        { ignoreTag: true }
                      ),
              }}
            />
          }
          id="resetValues"
          name="resetValues"
          value="resetValues"
          checked={resetValues}
          onChange={() => setResetValues(true)}
        />
      </Fieldset>
    </ConfirmationPopup>
  );
};

export default UnitChangePopup;
