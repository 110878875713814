import { Icon, IconPlus } from "@flixbus/honeycomb-icons-react";
import { Tag, Heading } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import PeriodCalendar from "../../../../shared/components/periodCalendar/PeriodCalendar";
import { ScheduleDetailFragment } from "../../api/operations.generated";
import * as css from "./NonOperatingPeriodInput.scss";
import {
  removePeriod,
  addPeriod,
  editPeriod,
  NonOperatingPeriods,
} from "./helpers/nonOperatingPeriodHelpers";
import NonOperatingPeriodTag from "./ui/nonOperatingPeriodTag/NonOperatingPeriodTag";

export type NonOperatingPeriodInputProps = {
  periods: NonOperatingPeriods;
  schedulePeriod: ScheduleDetailFragment["period"];
  onValueChanged: (periods: NonOperatingPeriods) => void;
  readOnly: boolean;
};

const NonOperatingPeriodInput: React.FC<NonOperatingPeriodInputProps> = ({
  periods,
  onValueChanged,
  schedulePeriod,
  readOnly,
}) => {
  const [showCalendar, setShowCalendar] = React.useState(false);

  return (
    <>
      <Heading size={4}>
        <span>
          <FormattedMessage id="scheduleDetail.NonOperationalDays.label" />
        </span>
      </Heading>
      <div className={css.tagContainer}>
        {periods.map((period, index) => {
          return (
            <NonOperatingPeriodTag
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              periodStart={period.start}
              periodEnd={period.end}
              onRemovePeriod={() => onValueChanged(removePeriod(periods, index))}
              onEditPeriod={(startDate, endDate) =>
                onValueChanged(editPeriod(periods, startDate, endDate, index))
              }
              readOnly={readOnly}
              selectablePeriod={schedulePeriod}
            />
          );
        })}
        <Tag
          appearance="success"
          disabled={readOnly}
          onClick={() => !readOnly && setShowCalendar(true)}
          extraClasses={`${css.addNewTag} ${!readOnly && css.addNewActive}`}
        >
          <Icon InlineIcon={IconPlus} extraClasses={css.plusIcon} />
          <FormattedMessage id="scheduleDetail.NonOperationalDays.tag.label" />
        </Tag>
      </div>
      {showCalendar && (
        <PeriodCalendar
          onCalendarClose={() => setShowCalendar(false)}
          onPeriodSelected={(startDate, endDate) => {
            onValueChanged(addPeriod(periods, startDate, endDate));
            setShowCalendar(false);
          }}
          selectablePeriod={schedulePeriod}
        />
      )}
    </>
  );
};

export default NonOperatingPeriodInput;
