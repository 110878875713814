// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// This file was automatically generated using npm run codegen
// If you want to alter this file please do so via the above mentioned command
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
import * as Types from "../../../shared/types/schema";

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
const defaultOptions = {} as const;
export type ScheduleNameAndActionsFragment = {
  __typename?: "Schedule";
  id: string;
  name: string;
  readOnly: boolean;
  status: Types.ScheduleStatus;
  displayedScheduleStatus: Types.DisplayedScheduleStatus;
  isOutdated: boolean;
  line: { __typename?: "Line"; id: string };
  conceptChangeOf?: {
    __typename?: "Schedule";
    id: string;
    status: Types.ScheduleStatus;
  } | null;
};

export type FindScheduleNameQueryVariables = Types.Exact<{
  id: Types.Scalars["ID"]["input"];
}>;

export type FindScheduleNameQuery = {
  __typename?: "Query";
  findSchedule: {
    __typename?: "Schedule";
    id: string;
    name: string;
    readOnly: boolean;
    status: Types.ScheduleStatus;
    displayedScheduleStatus: Types.DisplayedScheduleStatus;
    isOutdated: boolean;
    line: { __typename?: "Line"; id: string };
    conceptChangeOf?: {
      __typename?: "Schedule";
      id: string;
      status: Types.ScheduleStatus;
    } | null;
  };
};

export type UpdateScheduleNameMutationVariables = Types.Exact<{
  id: Types.Scalars["ID"]["input"];
  name?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
}>;

export type UpdateScheduleNameMutation = {
  __typename?: "Mutation";
  updateSchedule: {
    __typename?: "Schedule";
    id: string;
    name: string;
    readOnly: boolean;
    status: Types.ScheduleStatus;
    displayedScheduleStatus: Types.DisplayedScheduleStatus;
    isOutdated: boolean;
    line: { __typename?: "Line"; id: string };
    conceptChangeOf?: {
      __typename?: "Schedule";
      id: string;
      status: Types.ScheduleStatus;
    } | null;
  };
};

export type FindScheduleStatusHistoryQueryVariables = Types.Exact<{
  scheduleId: Types.Scalars["ID"]["input"];
}>;

export type FindScheduleStatusHistoryQuery = {
  __typename?: "Query";
  findSchedule: {
    __typename?: "Schedule";
    id: string;
    statusHistory: Array<{
      __typename?: "ScheduleStatusHistoryEntry";
      status: Types.ScheduleStatus;
      description?: string | null;
      created: string;
      createdBy: string;
    }>;
  };
};

export type CopyScheduleMutationVariables = Types.Exact<{
  id: Types.Scalars["ID"]["input"];
}>;

export type CopyScheduleMutation = {
  __typename?: "Mutation";
  copySchedule: { __typename?: "Schedule"; id: string };
};

export type DeleteScheduleMutationVariables = Types.Exact<{
  id: Types.Scalars["ID"]["input"];
}>;

export type DeleteScheduleMutation = { __typename?: "Mutation"; deleteSchedule: boolean };

export type CreateConceptChangeMutationVariables = Types.Exact<{
  id: Types.Scalars["ID"]["input"];
}>;

export type CreateConceptChangeMutation = {
  __typename?: "Mutation";
  createConceptChange: { __typename?: "Schedule"; id: string };
};

export type ChangeScheduleStatusMutationVariables = Types.Exact<{
  scheduleId: Types.Scalars["ID"]["input"];
  newStatus: Types.ScheduleStatus;
  comment: Types.Scalars["String"]["input"];
}>;

export type ChangeScheduleStatusMutation = {
  __typename?: "Mutation";
  changeStatus: { __typename?: "Schedule"; id: string; status: Types.ScheduleStatus };
};

export type ActivateOnSaleMutationVariables = Types.Exact<{
  scheduleId: Types.Scalars["ID"]["input"];
}>;

export type ActivateOnSaleMutation = { __typename?: "Mutation"; activateOnSale: boolean };

export type ArchiveScheduleMutationVariables = Types.Exact<{
  scheduleId: Types.Scalars["ID"]["input"];
}>;

export type ArchiveScheduleMutation = {
  __typename?: "Mutation";
  archiveSchedule: boolean;
};

export const ScheduleNameAndActionsFragmentDoc = gql`
  fragment ScheduleNameAndActions on Schedule {
    id
    name
    readOnly
    line {
      id
    }
    status
    displayedScheduleStatus
    isOutdated
    conceptChangeOf {
      id
      status
    }
  }
`;
export const FindScheduleNameDocument = gql`
  query FindScheduleName($id: ID!) {
    findSchedule(id: $id) {
      ...ScheduleNameAndActions
    }
  }
  ${ScheduleNameAndActionsFragmentDoc}
`;

/**
 * __useFindScheduleNameQuery__
 *
 * To run a query within a React component, call `useFindScheduleNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindScheduleNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindScheduleNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindScheduleNameQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FindScheduleNameQuery,
    FindScheduleNameQueryVariables
  > &
    ({ variables: FindScheduleNameQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<FindScheduleNameQuery, FindScheduleNameQueryVariables>(
    FindScheduleNameDocument,
    options
  );
}
export function useFindScheduleNameLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FindScheduleNameQuery,
    FindScheduleNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FindScheduleNameQuery,
    FindScheduleNameQueryVariables
  >(FindScheduleNameDocument, options);
}
export function useFindScheduleNameSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    FindScheduleNameQuery,
    FindScheduleNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    FindScheduleNameQuery,
    FindScheduleNameQueryVariables
  >(FindScheduleNameDocument, options);
}
export type FindScheduleNameQueryHookResult = ReturnType<typeof useFindScheduleNameQuery>;
export type FindScheduleNameLazyQueryHookResult = ReturnType<
  typeof useFindScheduleNameLazyQuery
>;
export type FindScheduleNameSuspenseQueryHookResult = ReturnType<
  typeof useFindScheduleNameSuspenseQuery
>;
export const UpdateScheduleNameDocument = gql`
  mutation UpdateScheduleName($id: ID!, $name: String) {
    updateSchedule(id: $id, name: $name) {
      ...ScheduleNameAndActions
    }
  }
  ${ScheduleNameAndActionsFragmentDoc}
`;

/**
 * __useUpdateScheduleNameMutation__
 *
 * To run a mutation, you first call `useUpdateScheduleNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScheduleNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScheduleNameMutation, { data, loading, error }] = useUpdateScheduleNameMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateScheduleNameMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateScheduleNameMutation,
    UpdateScheduleNameMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateScheduleNameMutation,
    UpdateScheduleNameMutationVariables
  >(UpdateScheduleNameDocument, options);
}
export type UpdateScheduleNameMutationHookResult = ReturnType<
  typeof useUpdateScheduleNameMutation
>;
export const FindScheduleStatusHistoryDocument = gql`
  query findScheduleStatusHistory($scheduleId: ID!) {
    findSchedule(id: $scheduleId) {
      id
      statusHistory {
        status
        description
        created
        createdBy
      }
    }
  }
`;

/**
 * __useFindScheduleStatusHistoryQuery__
 *
 * To run a query within a React component, call `useFindScheduleStatusHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindScheduleStatusHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindScheduleStatusHistoryQuery({
 *   variables: {
 *      scheduleId: // value for 'scheduleId'
 *   },
 * });
 */
export function useFindScheduleStatusHistoryQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FindScheduleStatusHistoryQuery,
    FindScheduleStatusHistoryQueryVariables
  > &
    (
      | { variables: FindScheduleStatusHistoryQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FindScheduleStatusHistoryQuery,
    FindScheduleStatusHistoryQueryVariables
  >(FindScheduleStatusHistoryDocument, options);
}
export function useFindScheduleStatusHistoryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FindScheduleStatusHistoryQuery,
    FindScheduleStatusHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FindScheduleStatusHistoryQuery,
    FindScheduleStatusHistoryQueryVariables
  >(FindScheduleStatusHistoryDocument, options);
}
export function useFindScheduleStatusHistorySuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    FindScheduleStatusHistoryQuery,
    FindScheduleStatusHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    FindScheduleStatusHistoryQuery,
    FindScheduleStatusHistoryQueryVariables
  >(FindScheduleStatusHistoryDocument, options);
}
export type FindScheduleStatusHistoryQueryHookResult = ReturnType<
  typeof useFindScheduleStatusHistoryQuery
>;
export type FindScheduleStatusHistoryLazyQueryHookResult = ReturnType<
  typeof useFindScheduleStatusHistoryLazyQuery
>;
export type FindScheduleStatusHistorySuspenseQueryHookResult = ReturnType<
  typeof useFindScheduleStatusHistorySuspenseQuery
>;
export const CopyScheduleDocument = gql`
  mutation CopySchedule($id: ID!) {
    copySchedule(id: $id) {
      id
    }
  }
`;

/**
 * __useCopyScheduleMutation__
 *
 * To run a mutation, you first call `useCopyScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyScheduleMutation, { data, loading, error }] = useCopyScheduleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCopyScheduleMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CopyScheduleMutation,
    CopyScheduleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CopyScheduleMutation,
    CopyScheduleMutationVariables
  >(CopyScheduleDocument, options);
}
export type CopyScheduleMutationHookResult = ReturnType<typeof useCopyScheduleMutation>;
export const DeleteScheduleDocument = gql`
  mutation DeleteSchedule($id: ID!) {
    deleteSchedule(id: $id)
  }
`;

/**
 * __useDeleteScheduleMutation__
 *
 * To run a mutation, you first call `useDeleteScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScheduleMutation, { data, loading, error }] = useDeleteScheduleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteScheduleMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteScheduleMutation,
    DeleteScheduleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteScheduleMutation,
    DeleteScheduleMutationVariables
  >(DeleteScheduleDocument, options);
}
export type DeleteScheduleMutationHookResult = ReturnType<
  typeof useDeleteScheduleMutation
>;
export const CreateConceptChangeDocument = gql`
  mutation CreateConceptChange($id: ID!) {
    createConceptChange(id: $id) {
      id
    }
  }
`;

/**
 * __useCreateConceptChangeMutation__
 *
 * To run a mutation, you first call `useCreateConceptChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConceptChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConceptChangeMutation, { data, loading, error }] = useCreateConceptChangeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCreateConceptChangeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateConceptChangeMutation,
    CreateConceptChangeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateConceptChangeMutation,
    CreateConceptChangeMutationVariables
  >(CreateConceptChangeDocument, options);
}
export type CreateConceptChangeMutationHookResult = ReturnType<
  typeof useCreateConceptChangeMutation
>;
export const ChangeScheduleStatusDocument = gql`
  mutation changeScheduleStatus(
    $scheduleId: ID!
    $newStatus: ScheduleStatus!
    $comment: String!
  ) {
    changeStatus(scheduleId: $scheduleId, newStatus: $newStatus, comment: $comment) {
      id
      status
    }
  }
`;

/**
 * __useChangeScheduleStatusMutation__
 *
 * To run a mutation, you first call `useChangeScheduleStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeScheduleStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeScheduleStatusMutation, { data, loading, error }] = useChangeScheduleStatusMutation({
 *   variables: {
 *      scheduleId: // value for 'scheduleId'
 *      newStatus: // value for 'newStatus'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useChangeScheduleStatusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ChangeScheduleStatusMutation,
    ChangeScheduleStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ChangeScheduleStatusMutation,
    ChangeScheduleStatusMutationVariables
  >(ChangeScheduleStatusDocument, options);
}
export type ChangeScheduleStatusMutationHookResult = ReturnType<
  typeof useChangeScheduleStatusMutation
>;
export const ActivateOnSaleDocument = gql`
  mutation ActivateOnSale($scheduleId: ID!) {
    activateOnSale(scheduleId: $scheduleId)
  }
`;

/**
 * __useActivateOnSaleMutation__
 *
 * To run a mutation, you first call `useActivateOnSaleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateOnSaleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateOnSaleMutation, { data, loading, error }] = useActivateOnSaleMutation({
 *   variables: {
 *      scheduleId: // value for 'scheduleId'
 *   },
 * });
 */
export function useActivateOnSaleMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ActivateOnSaleMutation,
    ActivateOnSaleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ActivateOnSaleMutation,
    ActivateOnSaleMutationVariables
  >(ActivateOnSaleDocument, options);
}
export type ActivateOnSaleMutationHookResult = ReturnType<
  typeof useActivateOnSaleMutation
>;
export const ArchiveScheduleDocument = gql`
  mutation ArchiveSchedule($scheduleId: ID!) {
    archiveSchedule(scheduleId: $scheduleId)
  }
`;

/**
 * __useArchiveScheduleMutation__
 *
 * To run a mutation, you first call `useArchiveScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveScheduleMutation, { data, loading, error }] = useArchiveScheduleMutation({
 *   variables: {
 *      scheduleId: // value for 'scheduleId'
 *   },
 * });
 */
export function useArchiveScheduleMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ArchiveScheduleMutation,
    ArchiveScheduleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ArchiveScheduleMutation,
    ArchiveScheduleMutationVariables
  >(ArchiveScheduleDocument, options);
}
export type ArchiveScheduleMutationHookResult = ReturnType<
  typeof useArchiveScheduleMutation
>;
