import { Input, Grid, GridCol, Fineprint } from "@flixbus/honeycomb-react";
import { localizeDate } from "@flixbus-phx/marketplace-common";
import cx from "classnames";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { SchedulePeriod } from "../../types/schema";
import PeriodCalendar from "../periodCalendar/PeriodCalendar";
import * as css from "./Datepicker.scss";

type Props = {
  leftLabel: string;
  leftId: string;
  rightLabel: string;
  rightId: string;
  initialValues?: { start?: Date; end?: Date };
  onValuesSet: (newValues: { start?: Date; end?: Date }) => void;
  isRequired?: boolean;
  hasError?: boolean;
  selectablePeriod?: SchedulePeriod;
  readOnly?: boolean;
  isEditable?: boolean;
};

const Datepicker: React.FC<Props> = ({
  leftLabel,
  leftId,
  rightLabel,
  rightId,
  initialValues,
  onValuesSet,
  isRequired,
  hasError,
  selectablePeriod,
  readOnly,
  isEditable,
}) => {
  const [selectedStartDate, setSelectedStartDate] = React.useState(initialValues?.start);
  const [selectedEndDate, setSelectedEndDate] = React.useState(initialValues?.end);

  const [showCalendar, setShowCalendar] = React.useState(false);

  const ref = React.useRef<HTMLInputElement>(null);

  const handleInputFocus = (): void => {
    if (!readOnly) {
      setShowCalendar(true);
    }
  };

  React.useEffect(() => {
    onValuesSet({ start: selectedStartDate, end: selectedEndDate });
  }, [selectedStartDate, selectedEndDate]);

  return (
    <div ref={ref}>
      <Grid>
        <GridCol size={6}>
          <Input
            label={`${leftLabel}${isRequired ? " *" : ""}`}
            placeholder={localizeDate("2021-01-01")}
            id={leftId}
            onFocus={handleInputFocus}
            value={selectedStartDate !== undefined ? localizeDate(selectedStartDate) : ""}
            required={isRequired}
            readOnly
            valid={hasError ? false : undefined}
          />
        </GridCol>
        <GridCol size={6}>
          <Input
            label={`${rightLabel}${isRequired ? " *" : ""}`}
            placeholder={localizeDate("2021-06-30")}
            id={rightId}
            onFocus={handleInputFocus}
            value={selectedEndDate !== undefined ? localizeDate(selectedEndDate) : ""}
            required={isRequired}
            readOnly
            valid={hasError ? false : undefined}
            extraClasses={cx(isEditable ? css.editableInput : "")}
          />
        </GridCol>
      </Grid>
      <Grid>
        <GridCol size={6}>
          <Fineprint extraClasses={css.errorMessage}>
            {hasError ? <FormattedMessage id="error.validation.fieldsRequired" /> : ""}
          </Fineprint>
        </GridCol>
      </Grid>
      <Grid>
        <GridCol size={12}>
          {showCalendar && (
            <div className={css.calendarWrapper}>
              <PeriodCalendar
                defaultStartDate={selectedStartDate || initialValues?.start}
                defaultEndDate={selectedEndDate || initialValues?.end}
                onStartDateSelected={(date) => setSelectedStartDate(date)}
                onEndDateSelected={(date) => {
                  setSelectedEndDate(date);
                  setShowCalendar(false);
                }}
                onCalendarClose={() => setShowCalendar(false)}
                additionalReference={ref}
                selectablePeriod={selectablePeriod}
              />
            </div>
          )}
        </GridCol>
      </Grid>
    </div>
  );
};

export default Datepicker;
