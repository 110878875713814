export const addColonIfNeeded = (currentValue: string, changedValue: string): string => {
  if (
    changedValue.length === 2 &&
    currentValue.length !== 3 &&
    changedValue.indexOf(":") === -1
  ) {
    return ":";
  }
  return "";
};

export const addWhitespaceIfNeeded = (
  currentValue: string,
  changedValue: string
): string => {
  if (
    changedValue.length === 5 &&
    currentValue.length !== 6 &&
    changedValue.indexOf(" ") === -1
  ) {
    return " ";
  }
  return "";
};
