import cx from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";
import buildPath from "../../../../routes/buildPath/buildPath";
import { SchedulesOfLineQuery } from "../../api/operations.generated";
import * as css from "./SchedulePeriodTagLink.scss";

type Props = {
  scheduleId: SchedulesOfLineQuery["findLine"]["schedules"][number]["id"];
  startDateInPast: boolean;
  endDateInPast: boolean;
  startDateInFuture: boolean;
  endDateInFuture: boolean;
  schedulePeriodTagPosition: {
    gridColumn: string;
  };
};

const SchedulePeriodTagLink: React.FC<Props> = ({
  scheduleId,
  startDateInPast,
  endDateInPast,
  startDateInFuture,
  endDateInFuture,
  schedulePeriodTagPosition,
}) => {
  return (
    <div
      data-id="link"
      style={schedulePeriodTagPosition}
      className={cx(
        css.wrapper,
        startDateInPast && endDateInPast && css.periodInPast,
        startDateInFuture && endDateInFuture && css.periodInFuture
      )}
    >
      <Link
        to={buildPath.showScheduleDetail(scheduleId)}
        className={cx(
          css.link,
          startDateInPast && css.cutOffLeft,
          endDateInFuture && css.cutOffRight
        )}
      />
    </div>
  );
};

export default SchedulePeriodTagLink;
