import { Icon, IconPlus } from "@flixbus/honeycomb-icons-react";
import { Button } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import buildPath from "../../../../routes/buildPath/buildPath";

type Props = {
  lineId: string;
};

const ScheduleAddButton: React.FC<Props> = ({ lineId }) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    const createSchedulePath = buildPath.createSchedule(lineId);
    navigate(createSchedulePath.pathname + createSchedulePath.search);
  };

  return (
    <Button
      appearance="primary"
      onClick={handleButtonClick}
      data-id="add-schedule-button"
    >
      <Icon InlineIcon={IconPlus} />
      <FormattedMessage id="lineDetail.schedules.addSchedule.button" />
    </Button>
  );
};

export default ScheduleAddButton;
