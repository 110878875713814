import { Heading } from "@flixbus/honeycomb-react";
import { Feature, hasUserPermission } from "@flixbus-phx/marketplace-common";
import image from "@flixbus-phx/marketplace-common/assets/illustrations/passenger-waiting.svg";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import LineAddButton from "../lineAddButton/LineAddButton";
import * as css from "./NoLinesFoundMessage.scss";

type Props = {
  partnerId?: string;
  inactiveLinesExist?: boolean;
};

const NoLinesFoundMessage: React.FC<Props> = ({
  partnerId,
  inactiveLinesExist = false,
}) => {
  return (
    <div className={css.wrapper}>
      {hasUserPermission(Feature.CREATE_LINE) && !inactiveLinesExist && (
        <>
          <div className={css.header}>
            <Heading size={3} flushSpace>
              <FormattedMessage id="partnerDetail.lines.info.noLinesFound" />
            </Heading>
          </div>
          {partnerId && (
            <div className={css.button}>
              <LineAddButton partnerId={partnerId} />
            </div>
          )}
        </>
      )}
      {inactiveLinesExist && (
        <>
          <div className={css.header}>
            <Heading size={3} flushSpace>
              <FormattedMessage id="partnerDetail.lines.info.onlyInactiveLinesFound" />
            </Heading>
          </div>
        </>
      )}
      <img src={image} alt="" width="350" height="287" />
    </div>
  );
};

export default NoLinesFoundMessage;
