export enum ErrorTypes {
  e401 = "401",
  e403 = "403",
  e404 = "404",
  e500 = "500",
}

export enum ErrorMessages {
  e403 = "Application has thrown an Authorization Error",
  e404 = "Application has thrown a Not Found Error",
  e500 = "Application has thrown a Network Error",
}
