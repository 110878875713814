import {
  addNotification,
  formatErrorMessage,
  NotificationType,
} from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { useIntl } from "react-intl";
import StationForm from "../stationForm/StationForm";
import StationFormButtons from "../stationFormButtons/StationFormButtons";
import {
  NewStationMutation,
  NewStationMutationVariables,
  useNewStationMutation,
} from "./api/operations.generated";

export type CreateStationProps = {
  onStationCreated: (param: NewStationMutation["newStation"]) => void;
  onCancel: () => void;
  inputValue?: string;
};

const CreateStation: React.FC<CreateStationProps> = ({
  onStationCreated,
  onCancel,
  inputValue,
}) => {
  const { formatMessage } = useIntl();

  const [addStation, { loading }] = useNewStationMutation({
    onCompleted: (data) => {
      addNotification({
        message: formatMessage({ id: "station.create.notification.success" }),
        type: NotificationType.Success,
      });
      onStationCreated(data.newStation);
    },
    onError: (error) =>
      addNotification({
        message: `Creation of station failed: ${formatErrorMessage(error.message)}`,
        type: NotificationType.Danger,
      }),
  });

  const handleCreateStation = (values: NewStationMutationVariables): void => {
    addStation({
      variables: values,
    });
  };

  return (
    <StationForm
      onSubmit={handleCreateStation}
      onCancel={onCancel}
      stationName={inputValue}
      submitLoading={loading}
    >
      <StationFormButtons
        submitLoading={loading}
        submitButtonText={formatMessage({ id: "station.create" })}
      />
    </StationForm>
  );
};

export default CreateStation;
