import { TimeFormat } from "../../../../shared/components/timeInput/types";
import getSubmittableTimeValue from "../getSubmittableTimeValue/getSubmittableTimeValue";

const getSubmittableHolidayWorkingHours = (
  holidayWorkingHours: {
    enabled: boolean;
    workingHours: Array<{ from: string; to: string }>;
  },
  timeFormat: TimeFormat
) => {
  if (holidayWorkingHours.enabled) {
    const transformedWorkingHours = holidayWorkingHours.workingHours.map(
      (workingHour) => {
        return {
          from: getSubmittableTimeValue(timeFormat, workingHour.from),
          to: getSubmittableTimeValue(timeFormat, workingHour.to),
        };
      }
    );

    const filteredWorkingHours = transformedWorkingHours.filter(
      (workingHour) => workingHour.from || workingHour.to
    );

    if (!filteredWorkingHours.length) {
      return [];
    }

    return filteredWorkingHours;
  }

  return [];
};

export default getSubmittableHolidayWorkingHours;
