@import "variables";

.container {
  text-align: left;
}

.noOptionInfo {
  overflow: hidden;
  white-space: nowrap;
  padding: $spacing-1 $spacing-2;
}
